import React from 'react'

import { Colors } from '../../constants'

import { Icon } from './Icon'

export function SuccessIcon({ className }: { className?: string }) {
  return (
    <Icon size="24" viewBox="0 0 24 24" fill="none" className={className}>
      <path
        d="M9.66667 3.66679H10.3333V4.33346C10.3333 4.70159 10.6318 5.00012 11 5.00012C11.3682 5.00012 11.6667 4.70159 11.6667 4.33346V3.66679H12.3333C12.7015 3.66679 13 3.36826 13 3.00012C13 2.63199 12.7015 2.33346 12.3333 2.33346H11.6667V1.66679C11.6667 1.29866 11.3682 1.00012 11 1.00012C10.6318 1.00012 10.3333 1.29866 10.3333 1.66679V2.33346H9.66667C9.29847 2.33346 9 2.63199 9 3.00012C9 3.36832 9.29847 3.66679 9.66667 3.66679Z"
        fill={Colors.Black[900]}
        className="blackPart"
      />
      <path
        d="M23.3333 17.3335H22.6667V16.6668C22.6667 16.2987 22.3681 16.0001 22 16.0001C21.6319 16.0001 21.3333 16.2987 21.3333 16.6668V17.3335H20.6667C20.2985 17.3335 20 17.632 20 18.0001C20 18.3683 20.2985 18.6668 20.6667 18.6668H21.3333V19.3335C21.3333 19.7016 21.6319 20.0001 22 20.0001C22.3681 20.0001 22.6667 19.7016 22.6667 19.3335V18.6668H23.3333C23.7015 18.6668 24 18.3683 24 18.0001C24 17.632 23.7015 17.3335 23.3333 17.3335Z"
        fill={Colors.Blue[500]}
        className="primaryPart"
      />
      <path
        d="M5.30469 4.48157C6.08009 4.48157 6.71094 3.85073 6.71094 3.07532C6.71094 2.29991 6.08009 1.66907 5.30469 1.66907C4.52928 1.66907 3.89844 2.29991 3.89844 3.07532C3.89844 3.85073 4.52928 4.48157 5.30469 4.48157ZM5.30469 2.60657C5.56316 2.60657 5.77344 2.81685 5.77344 3.07532C5.77344 3.33379 5.56316 3.54407 5.30469 3.54407C5.04622 3.54407 4.83594 3.33379 4.83594 3.07532C4.83594 2.8169 5.04622 2.60657 5.30469 2.60657Z"
        fill={Colors.Blue[500]}
        className="primaryPart"
      />
      <path
        d="M21.0557 13.7842C21.0557 13.526 20.8451 13.3154 20.5869 13.3154C20.3287 13.3154 20.1182 13.526 20.1182 13.7842C20.1182 14.0424 20.3287 14.2529 20.5869 14.2529C20.8451 14.2529 21.0557 14.0423 21.0557 13.7842Z"
        fill={Colors.Black[900]}
        className="blackPart"
      />
      <path
        d="M15.7119 4.4563C15.9701 4.4563 16.1807 4.24574 16.1807 3.98755C16.1807 3.72936 15.9701 3.5188 15.7119 3.5188C15.4537 3.5188 15.2432 3.72936 15.2432 3.98755C15.2432 4.24569 15.4538 4.4563 15.7119 4.4563Z"
        fill={Colors.Black[900]}
        className="blackPart"
      />
      <path
        d="M21.2446 5.00634C20.9284 5.05486 20.7088 5.36839 20.7542 5.70674C20.7707 5.83134 20.7843 5.9524 20.7963 6.07185C20.9338 7.4375 20.7691 8.4727 20.2938 9.22662C19.7767 10.0466 18.818 10.5276 17.9083 10.4233C17.6957 10.3988 17.4864 10.3426 17.2864 10.2589C17.323 10.1914 17.3569 10.1222 17.3879 10.0518C17.5552 9.67265 17.6246 9.23629 17.5975 8.80316C17.5767 8.4727 17.4999 8.14435 17.3667 7.84436C17.1009 7.246 16.6533 6.83689 16.1062 6.69229C15.5027 6.53287 14.8372 6.9623 14.6226 7.64948C14.4826 8.09792 14.5195 8.61085 14.7323 9.17391C14.909 9.64186 15.1679 10.0644 15.4867 10.4232C15.1988 10.5874 14.8603 10.7067 14.4882 10.7696C14.1727 10.8229 13.9573 11.1399 14.0072 11.4776C14.052 11.7825 14.298 12 14.5776 12C14.6076 12 14.638 11.9976 14.6684 11.9924C15.3595 11.8756 15.977 11.6049 16.467 11.2192C16.8742 11.4498 17.321 11.6009 17.7851 11.6541C17.9115 11.6686 18.0377 11.6757 18.1639 11.6757C19.3799 11.6757 20.5622 11.0136 21.2523 9.91912C21.937 8.83314 22.1485 7.39785 21.8989 5.53136C21.8536 5.19301 21.5604 4.95782 21.2446 5.00634ZM15.7195 8.04183C15.743 7.96671 15.8079 7.9082 15.8397 7.89723C16.1082 7.9738 16.257 8.22914 16.3222 8.37599C16.4826 8.73691 16.4904 9.18729 16.3422 9.52338C16.3362 9.53708 16.3299 9.55079 16.3234 9.56433C16.1031 9.31657 15.9242 9.02706 15.8044 8.70982C15.6993 8.4316 15.67 8.20061 15.7195 8.04183Z"
        fill={Colors.Black[900]}
        className="blackPart"
      />
      <path
        d="M18.9031 14.9377C18.9031 14.4492 18.5117 14.0533 18.0288 14.0533C17.5458 14.0533 17.1543 14.4492 17.1543 14.9377C17.1543 15.4261 17.5458 15.822 18.0288 15.822C18.5117 15.822 18.9031 15.4261 18.9031 14.9377Z"
        fill={Colors.Black[900]}
        className="blackPart"
      />
      <path
        d="M12.6541 8.4223C12.6541 7.93396 12.2626 7.53809 11.7796 7.53809C11.2968 7.53809 10.9053 7.93396 10.9053 8.4223C10.9053 8.91064 11.2968 9.30652 11.7796 9.30652C12.2626 9.30652 12.6541 8.91064 12.6541 8.4223Z"
        fill={Colors.Black[900]}
        className="blackPart"
      />
      <path
        d="M20.2109 3.8891C20.2109 3.40076 19.8193 3.00488 19.3364 3.00488C18.8534 3.00488 18.4619 3.40076 18.4619 3.8891C18.4619 4.37762 18.8534 4.7735 19.3364 4.7735C19.8193 4.7735 20.2109 4.37762 20.2109 3.8891Z"
        fill={Colors.Black[900]}
        className="blackPart"
      />
      <path
        d="M7.08999 6.49108C6.82742 6.20489 6.38265 6.18566 6.09646 6.44805C5.81027 6.71062 5.79122 7.15538 6.05361 7.44158L6.32937 7.74224L0.164758 22.034C-0.0600956 22.5556 0.0413448 23.1374 0.429528 23.5521C0.703088 23.8441 1.06838 23.9996 1.44778 23.9996C1.6069 23.9996 1.76876 23.9721 1.92696 23.9159L16.4348 18.7591L16.636 18.9785C16.7748 19.1297 16.9643 19.2063 17.1544 19.2063C17.3243 19.2063 17.4944 19.1451 17.6295 19.0213C17.9157 18.7588 17.9348 18.314 17.6724 18.028L7.08999 6.49108ZM6.52438 20.7894L4.41024 15.7419L6.37606 11.1846L9.89663 19.5908L6.52438 20.7894ZM1.45602 22.5908L3.6368 17.5351L5.19723 21.2611L1.45602 22.5908ZM11.2236 19.1191L7.1495 9.39146L7.36776 8.88573L15.3944 17.6365L11.2236 19.1191Z"
        fill={Colors.Black[900]}
        className="blackPart"
      />
    </Icon>
  )
}
