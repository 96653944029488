import React from 'react'

export function SubscanSymbol() {
  return (
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor">
        <path
          d="m13.8 3.21c0.733-0.733 0.733-1.92 0-2.66-0.733-0.733-1.92-0.733-2.66 0l-10.6 10.6c-0.733 0.733-0.733 1.92 0 2.66l5.31 5.31v2.08e-4c0.733 0.733 1.92 0.733 2.66 0l9.29-9.29 2.66 2.66-9.29 9.29c-0.733 0.733-0.733 1.92 0 2.66 0.733 0.733 1.92 0.733 2.66 0l10.6-10.6c0.733-0.733 0.733-1.92 0-2.66l-5.31-5.31c-0.733-0.733-1.92-0.733-2.66 0l-9.29 9.29-2.66-2.66 9.29-9.29z"
          opacity=".2"
        />
        <path d="m3.21 11.2c0.733 0.733 0.733 1.92 0 2.66-0.733 0.734-1.92 0.734-2.66 0-0.733-0.733-0.733-1.92 0-2.66 0.733-0.734 1.92-0.734 2.66 0" />
        <path d="m8.52 5.86c0.733 0.734 0.733 1.92 0 2.66-0.733 0.733-1.92 0.733-2.66 0-0.733-0.733-0.733-1.92 0-2.66 0.733-0.734 1.92-0.734 2.66-2.08e-4" />
        <path d="m13.8 0.55c0.733 0.734 0.733 1.92 0 2.66-0.733 0.733-1.92 0.733-2.66 0-0.733-0.733-0.733-1.92 0-2.66 0.733-0.733 1.92-0.733 2.66 0" />
        <path d="m8.52 16.5c0.733 0.733 0.733 1.92 0 2.66-0.733 0.733-1.92 0.733-2.66 0-0.733-0.733-0.733-1.92 0-2.66 0.733-0.733 1.92-0.733 2.66 0" />
        <path d="m13.8 11.2c0.733 0.733 0.733 1.92 0 2.66-0.733 0.734-1.92 0.734-2.66 0-0.733-0.733-0.733-1.92 0-2.66 0.733-0.734 1.92-0.734 2.66 0" />
        <path d="m19.1 5.86c0.733 0.734 0.733 1.92 0 2.66-0.733 0.733-1.92 0.733-2.66 0-0.733-0.733-0.733-1.92 0-2.66 0.733-0.734 1.92-0.734 2.66-2.08e-4" />
        <path d="m13.8 21.8c0.733 0.733 0.733 1.92 0 2.66-0.733 0.733-1.92 0.733-2.66 0-0.733-0.733-0.733-1.92 0-2.66 0.733-0.733 1.92-0.733 2.66 0" />
        <path d="m19.1 16.5c0.733 0.733 0.733 1.92 0 2.66-0.733 0.734-1.92 0.734-2.66 2.08e-4 -0.733-0.733-0.733-1.92 0-2.66 0.733-0.733 1.92-0.733 2.66 0" />
        <path d="m24.5 11.2c0.733 0.733 0.733 1.92 0 2.66-0.733 0.734-1.92 0.734-2.66 0-0.733-0.733-0.733-1.92 0-2.66 0.733-0.734 1.92-0.734 2.66 0" />
      </g>
    </svg>
  )
}
