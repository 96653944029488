import React from 'react'

import { Icon } from './Icon'

export const JoystreamLogo = () => (
  <Icon size="40" viewBox="0 0 40 40" preserveAspectRatio="xMidYMid meet" fill="none" color="currentColor">
    <rect width="40" height="40" rx="20" fill="#4038FF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4952 9H24.4642V18.2472C24.4642 20.4999 23.6195 22.5555 22.2334 24.1207C22.4047 23.3707 22.4952 22.5908 22.4952 21.7916V9ZM16.0871 25.1223V25.1243C15.1292 26.3053 13.6731 27.0677 12.04 27.0902L12.6296 25.1223H16.0871ZM17.2593 21.7828V21.5857H13.6679L13.0801 23.5533H16.956C17.1531 22.9991 17.2593 22.4039 17.2593 21.7828ZM26.0314 9.00049H28.0003V14.7105C28.0003 16.9632 27.1557 19.0187 25.7695 20.584C25.9408 19.834 26.0314 19.054 26.0314 18.2549V9.00049ZM20.8443 21.8008V9.00098H18.8747V21.8008C18.8747 25.6187 15.7674 28.6916 11.9839 28.6916H11.5878L11 30.6592H11.9839C16.8666 30.6592 20.8443 26.6875 20.8443 21.8008Z"
      fill="white"
    />
  </Icon>
)
