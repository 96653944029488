export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: string
  /** GraphQL representation of BigInt */
  BigInt: string
  /** GraphQL representation of Bytes */
  Bytes: any
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any
}

export type AmendConstitutionProposalDetails = {
  __typename: 'AmendConstitutionProposalDetails'
  /** New (proposed) constitution text (md-formatted) */
  text: Scalars['String']
}

export type AnnouncingPeriodStartedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'AnnouncingPeriodStartedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type AnnouncingPeriodStartedEventConnection = {
  __typename: 'AnnouncingPeriodStartedEventConnection'
  edges: Array<AnnouncingPeriodStartedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type AnnouncingPeriodStartedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type AnnouncingPeriodStartedEventEdge = {
  __typename: 'AnnouncingPeriodStartedEventEdge'
  cursor: Scalars['String']
  node: AnnouncingPeriodStartedEvent
}

export enum AnnouncingPeriodStartedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type AnnouncingPeriodStartedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type AnnouncingPeriodStartedEventWhereInput = {
  AND?: InputMaybe<Array<AnnouncingPeriodStartedEventWhereInput>>
  NOT?: InputMaybe<Array<AnnouncingPeriodStartedEventWhereInput>>
  OR?: InputMaybe<Array<AnnouncingPeriodStartedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type AnnouncingPeriodStartedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ApplicationFormQuestion = BaseGraphQlObject & {
  __typename: 'ApplicationFormQuestion'
  applicationformquestionanswerquestion?: Maybe<Array<ApplicationFormQuestionAnswer>>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Index of the question */
  index: Scalars['Int']
  openingMetadata: WorkingGroupOpeningMetadata
  openingMetadataId: Scalars['String']
  /** The question itself */
  question?: Maybe<Scalars['String']>
  /** Type of the question (UI answer input type) */
  type: ApplicationFormQuestionType
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type ApplicationFormQuestionAnswer = BaseGraphQlObject & {
  __typename: 'ApplicationFormQuestionAnswer'
  /** Applicant's answer */
  answer: Scalars['String']
  application: WorkingGroupApplication
  applicationId: Scalars['String']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  question: ApplicationFormQuestion
  questionId: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type ApplicationFormQuestionAnswerConnection = {
  __typename: 'ApplicationFormQuestionAnswerConnection'
  edges: Array<ApplicationFormQuestionAnswerEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ApplicationFormQuestionAnswerCreateInput = {
  answer: Scalars['String']
  application: Scalars['ID']
  question: Scalars['ID']
}

export type ApplicationFormQuestionAnswerEdge = {
  __typename: 'ApplicationFormQuestionAnswerEdge'
  cursor: Scalars['String']
  node: ApplicationFormQuestionAnswer
}

export enum ApplicationFormQuestionAnswerOrderByInput {
  AnswerAsc = 'answer_ASC',
  AnswerDesc = 'answer_DESC',
  ApplicationAsc = 'application_ASC',
  ApplicationDesc = 'application_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  QuestionAsc = 'question_ASC',
  QuestionDesc = 'question_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ApplicationFormQuestionAnswerUpdateInput = {
  answer?: InputMaybe<Scalars['String']>
  application?: InputMaybe<Scalars['ID']>
  question?: InputMaybe<Scalars['ID']>
}

export type ApplicationFormQuestionAnswerWhereInput = {
  AND?: InputMaybe<Array<ApplicationFormQuestionAnswerWhereInput>>
  NOT?: InputMaybe<Array<ApplicationFormQuestionAnswerWhereInput>>
  OR?: InputMaybe<Array<ApplicationFormQuestionAnswerWhereInput>>
  answer_contains?: InputMaybe<Scalars['String']>
  answer_endsWith?: InputMaybe<Scalars['String']>
  answer_eq?: InputMaybe<Scalars['String']>
  answer_in?: InputMaybe<Array<Scalars['String']>>
  answer_startsWith?: InputMaybe<Scalars['String']>
  application?: InputMaybe<WorkingGroupApplicationWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  question?: InputMaybe<ApplicationFormQuestionWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ApplicationFormQuestionAnswerWhereUniqueInput = {
  id: Scalars['ID']
}

export type ApplicationFormQuestionConnection = {
  __typename: 'ApplicationFormQuestionConnection'
  edges: Array<ApplicationFormQuestionEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ApplicationFormQuestionCreateInput = {
  index: Scalars['Float']
  openingMetadata: Scalars['ID']
  question?: InputMaybe<Scalars['String']>
  type: ApplicationFormQuestionType
}

export type ApplicationFormQuestionEdge = {
  __typename: 'ApplicationFormQuestionEdge'
  cursor: Scalars['String']
  node: ApplicationFormQuestion
}

export enum ApplicationFormQuestionOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IndexAsc = 'index_ASC',
  IndexDesc = 'index_DESC',
  OpeningMetadataAsc = 'openingMetadata_ASC',
  OpeningMetadataDesc = 'openingMetadata_DESC',
  QuestionAsc = 'question_ASC',
  QuestionDesc = 'question_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export enum ApplicationFormQuestionType {
  Text = 'TEXT',
  Textarea = 'TEXTAREA',
}

export type ApplicationFormQuestionUpdateInput = {
  index?: InputMaybe<Scalars['Float']>
  openingMetadata?: InputMaybe<Scalars['ID']>
  question?: InputMaybe<Scalars['String']>
  type?: InputMaybe<ApplicationFormQuestionType>
}

export type ApplicationFormQuestionWhereInput = {
  AND?: InputMaybe<Array<ApplicationFormQuestionWhereInput>>
  NOT?: InputMaybe<Array<ApplicationFormQuestionWhereInput>>
  OR?: InputMaybe<Array<ApplicationFormQuestionWhereInput>>
  applicationformquestionanswerquestion_every?: InputMaybe<ApplicationFormQuestionAnswerWhereInput>
  applicationformquestionanswerquestion_none?: InputMaybe<ApplicationFormQuestionAnswerWhereInput>
  applicationformquestionanswerquestion_some?: InputMaybe<ApplicationFormQuestionAnswerWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  index_eq?: InputMaybe<Scalars['Int']>
  index_gt?: InputMaybe<Scalars['Int']>
  index_gte?: InputMaybe<Scalars['Int']>
  index_in?: InputMaybe<Array<Scalars['Int']>>
  index_lt?: InputMaybe<Scalars['Int']>
  index_lte?: InputMaybe<Scalars['Int']>
  openingMetadata?: InputMaybe<WorkingGroupOpeningMetadataWhereInput>
  question_contains?: InputMaybe<Scalars['String']>
  question_endsWith?: InputMaybe<Scalars['String']>
  question_eq?: InputMaybe<Scalars['String']>
  question_in?: InputMaybe<Array<Scalars['String']>>
  question_startsWith?: InputMaybe<Scalars['String']>
  type_eq?: InputMaybe<ApplicationFormQuestionType>
  type_in?: InputMaybe<Array<ApplicationFormQuestionType>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ApplicationFormQuestionWhereUniqueInput = {
  id: Scalars['ID']
}

export type ApplicationStatusAccepted = {
  __typename: 'ApplicationStatusAccepted'
  /** Related OpeningFilled event */
  openingFilledEvent?: Maybe<OpeningFilledEvent>
}

export type ApplicationStatusCancelled = {
  __typename: 'ApplicationStatusCancelled'
  /** Related OpeningCanceled event */
  openingCanceledEvent?: Maybe<OpeningCanceledEvent>
}

export type ApplicationStatusPending = {
  __typename: 'ApplicationStatusPending'
  phantom?: Maybe<Scalars['Int']>
}

export type ApplicationStatusRejected = {
  __typename: 'ApplicationStatusRejected'
  /** Related OpeningFilled event */
  openingFilledEvent?: Maybe<OpeningFilledEvent>
}

export type ApplicationStatusWithdrawn = {
  __typename: 'ApplicationStatusWithdrawn'
  /** Related ApplicationWithdrawn event */
  applicationWithdrawnEvent?: Maybe<ApplicationWithdrawnEvent>
}

export type ApplicationWithdrawnEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ApplicationWithdrawnEvent'
    application: WorkingGroupApplication
    applicationId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ApplicationWithdrawnEventConnection = {
  __typename: 'ApplicationWithdrawnEventConnection'
  edges: Array<ApplicationWithdrawnEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ApplicationWithdrawnEventCreateInput = {
  application: Scalars['ID']
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type ApplicationWithdrawnEventEdge = {
  __typename: 'ApplicationWithdrawnEventEdge'
  cursor: Scalars['String']
  node: ApplicationWithdrawnEvent
}

export enum ApplicationWithdrawnEventOrderByInput {
  ApplicationAsc = 'application_ASC',
  ApplicationDesc = 'application_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ApplicationWithdrawnEventUpdateInput = {
  application?: InputMaybe<Scalars['ID']>
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type ApplicationWithdrawnEventWhereInput = {
  AND?: InputMaybe<Array<ApplicationWithdrawnEventWhereInput>>
  NOT?: InputMaybe<Array<ApplicationWithdrawnEventWhereInput>>
  OR?: InputMaybe<Array<ApplicationWithdrawnEventWhereInput>>
  application?: InputMaybe<WorkingGroupApplicationWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ApplicationWithdrawnEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type AppliedOnOpeningEvent = BaseGraphQlObject &
  Event & {
    __typename: 'AppliedOnOpeningEvent'
    application: WorkingGroupApplication
    applicationId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    opening: WorkingGroupOpening
    openingId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type AppliedOnOpeningEventConnection = {
  __typename: 'AppliedOnOpeningEventConnection'
  edges: Array<AppliedOnOpeningEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type AppliedOnOpeningEventCreateInput = {
  application: Scalars['ID']
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  opening: Scalars['ID']
}

export type AppliedOnOpeningEventEdge = {
  __typename: 'AppliedOnOpeningEventEdge'
  cursor: Scalars['String']
  node: AppliedOnOpeningEvent
}

export enum AppliedOnOpeningEventOrderByInput {
  ApplicationAsc = 'application_ASC',
  ApplicationDesc = 'application_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OpeningAsc = 'opening_ASC',
  OpeningDesc = 'opening_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type AppliedOnOpeningEventUpdateInput = {
  application?: InputMaybe<Scalars['ID']>
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  opening?: InputMaybe<Scalars['ID']>
}

export type AppliedOnOpeningEventWhereInput = {
  AND?: InputMaybe<Array<AppliedOnOpeningEventWhereInput>>
  NOT?: InputMaybe<Array<AppliedOnOpeningEventWhereInput>>
  OR?: InputMaybe<Array<AppliedOnOpeningEventWhereInput>>
  application?: InputMaybe<WorkingGroupApplicationWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  opening?: InputMaybe<WorkingGroupOpeningWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type AppliedOnOpeningEventWhereUniqueInput = {
  id: Scalars['ID']
}

/** Represents NFT auction */
export type Auction = BaseGraphQlObject & {
  __typename: 'Auction'
  /** The type of auction */
  auctionType: AuctionType
  bids: Array<Bid>
  /** Whether auction can be completed instantly */
  buyNowPrice?: Maybe<Scalars['BigInt']>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** Block when auction ended */
  endedAtBlock?: Maybe<Scalars['Int']>
  englishauctionstartedeventauction?: Maybe<Array<EnglishAuctionStartedEvent>>
  id: Scalars['ID']
  initialOwner?: Maybe<Membership>
  initialOwnerId?: Maybe<Scalars['String']>
  /** Is auction canceled */
  isCanceled: Scalars['Boolean']
  /** Is auction completed */
  isCompleted: Scalars['Boolean']
  nft: OwnedNft
  nftId: Scalars['String']
  openauctionstartedeventauction?: Maybe<Array<OpenAuctionStartedEvent>>
  ownednfttransactionalStatusAuction?: Maybe<Array<OwnedNft>>
  /** Auction starting price */
  startingPrice: Scalars['BigInt']
  /** Block when auction starts */
  startsAtBlock: Scalars['Int']
  topBid?: Maybe<Bid>
  topBidId?: Maybe<Scalars['String']>
  transactionalstatusupdatetransactionalStatusAuction?: Maybe<Array<TransactionalStatusUpdate>>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  whitelistedMembers: Array<Membership>
  winningMember?: Maybe<Membership>
  winningMemberId?: Maybe<Scalars['String']>
}

export type AuctionBidCanceledEvent = BaseGraphQlObject &
  Event & {
    __typename: 'AuctionBidCanceledEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    member: Membership
    memberId: Scalars['String']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
  }

export type AuctionBidCanceledEventConnection = {
  __typename: 'AuctionBidCanceledEventConnection'
  edges: Array<AuctionBidCanceledEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type AuctionBidCanceledEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  member: Scalars['ID']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video: Scalars['ID']
}

export type AuctionBidCanceledEventEdge = {
  __typename: 'AuctionBidCanceledEventEdge'
  cursor: Scalars['String']
  node: AuctionBidCanceledEvent
}

export enum AuctionBidCanceledEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type AuctionBidCanceledEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  member?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video?: InputMaybe<Scalars['ID']>
}

export type AuctionBidCanceledEventWhereInput = {
  AND?: InputMaybe<Array<AuctionBidCanceledEventWhereInput>>
  NOT?: InputMaybe<Array<AuctionBidCanceledEventWhereInput>>
  OR?: InputMaybe<Array<AuctionBidCanceledEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  member?: InputMaybe<MembershipWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type AuctionBidCanceledEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type AuctionBidMadeEvent = BaseGraphQlObject &
  Event & {
    __typename: 'AuctionBidMadeEvent'
    /** Bid made. */
    bidAmount: Scalars['BigInt']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    member: Membership
    memberId: Scalars['String']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    previousTopBid?: Maybe<Bid>
    previousTopBidId?: Maybe<Scalars['String']>
    previousTopBidder?: Maybe<Membership>
    previousTopBidderId?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
  }

export type AuctionBidMadeEventConnection = {
  __typename: 'AuctionBidMadeEventConnection'
  edges: Array<AuctionBidMadeEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type AuctionBidMadeEventCreateInput = {
  bidAmount: Scalars['String']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  member: Scalars['ID']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  previousTopBid?: InputMaybe<Scalars['ID']>
  previousTopBidder?: InputMaybe<Scalars['ID']>
  video: Scalars['ID']
}

export type AuctionBidMadeEventEdge = {
  __typename: 'AuctionBidMadeEventEdge'
  cursor: Scalars['String']
  node: AuctionBidMadeEvent
}

export enum AuctionBidMadeEventOrderByInput {
  BidAmountAsc = 'bidAmount_ASC',
  BidAmountDesc = 'bidAmount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  PreviousTopBidAsc = 'previousTopBid_ASC',
  PreviousTopBidDesc = 'previousTopBid_DESC',
  PreviousTopBidderAsc = 'previousTopBidder_ASC',
  PreviousTopBidderDesc = 'previousTopBidder_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type AuctionBidMadeEventUpdateInput = {
  bidAmount?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  member?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  previousTopBid?: InputMaybe<Scalars['ID']>
  previousTopBidder?: InputMaybe<Scalars['ID']>
  video?: InputMaybe<Scalars['ID']>
}

export type AuctionBidMadeEventWhereInput = {
  AND?: InputMaybe<Array<AuctionBidMadeEventWhereInput>>
  NOT?: InputMaybe<Array<AuctionBidMadeEventWhereInput>>
  OR?: InputMaybe<Array<AuctionBidMadeEventWhereInput>>
  bidAmount_eq?: InputMaybe<Scalars['BigInt']>
  bidAmount_gt?: InputMaybe<Scalars['BigInt']>
  bidAmount_gte?: InputMaybe<Scalars['BigInt']>
  bidAmount_in?: InputMaybe<Array<Scalars['BigInt']>>
  bidAmount_lt?: InputMaybe<Scalars['BigInt']>
  bidAmount_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  member?: InputMaybe<MembershipWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  previousTopBid?: InputMaybe<BidWhereInput>
  previousTopBidder?: InputMaybe<MembershipWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type AuctionBidMadeEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type AuctionCanceledEvent = BaseGraphQlObject &
  Event & {
    __typename: 'AuctionCanceledEvent'
    /** Content actor canceling the event. */
    contentActor: ContentActor
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
  }

export type AuctionCanceledEventConnection = {
  __typename: 'AuctionCanceledEventConnection'
  edges: Array<AuctionCanceledEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type AuctionCanceledEventCreateInput = {
  contentActor: Scalars['JSONObject']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video: Scalars['ID']
}

export type AuctionCanceledEventEdge = {
  __typename: 'AuctionCanceledEventEdge'
  cursor: Scalars['String']
  node: AuctionCanceledEvent
}

export enum AuctionCanceledEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type AuctionCanceledEventUpdateInput = {
  contentActor?: InputMaybe<Scalars['JSONObject']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video?: InputMaybe<Scalars['ID']>
}

export type AuctionCanceledEventWhereInput = {
  AND?: InputMaybe<Array<AuctionCanceledEventWhereInput>>
  NOT?: InputMaybe<Array<AuctionCanceledEventWhereInput>>
  OR?: InputMaybe<Array<AuctionCanceledEventWhereInput>>
  contentActor_json?: InputMaybe<Scalars['JSONObject']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type AuctionCanceledEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type AuctionConnection = {
  __typename: 'AuctionConnection'
  edges: Array<AuctionEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type AuctionCreateInput = {
  auctionType: Scalars['JSONObject']
  buyNowPrice?: InputMaybe<Scalars['String']>
  endedAtBlock?: InputMaybe<Scalars['Float']>
  initialOwner?: InputMaybe<Scalars['ID']>
  isCanceled: Scalars['Boolean']
  isCompleted: Scalars['Boolean']
  nft: Scalars['ID']
  startingPrice: Scalars['String']
  startsAtBlock: Scalars['Float']
  topBid?: InputMaybe<Scalars['ID']>
  winningMember?: InputMaybe<Scalars['ID']>
}

export type AuctionEdge = {
  __typename: 'AuctionEdge'
  cursor: Scalars['String']
  node: Auction
}

export enum AuctionOrderByInput {
  BuyNowPriceAsc = 'buyNowPrice_ASC',
  BuyNowPriceDesc = 'buyNowPrice_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EndedAtBlockAsc = 'endedAtBlock_ASC',
  EndedAtBlockDesc = 'endedAtBlock_DESC',
  InitialOwnerAsc = 'initialOwner_ASC',
  InitialOwnerDesc = 'initialOwner_DESC',
  IsCanceledAsc = 'isCanceled_ASC',
  IsCanceledDesc = 'isCanceled_DESC',
  IsCompletedAsc = 'isCompleted_ASC',
  IsCompletedDesc = 'isCompleted_DESC',
  NftAsc = 'nft_ASC',
  NftDesc = 'nft_DESC',
  StartingPriceAsc = 'startingPrice_ASC',
  StartingPriceDesc = 'startingPrice_DESC',
  StartsAtBlockAsc = 'startsAtBlock_ASC',
  StartsAtBlockDesc = 'startsAtBlock_DESC',
  TopBidAsc = 'topBid_ASC',
  TopBidDesc = 'topBid_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WinningMemberAsc = 'winningMember_ASC',
  WinningMemberDesc = 'winningMember_DESC',
}

export type AuctionType = AuctionTypeEnglish | AuctionTypeOpen

export type AuctionTypeEnglish = {
  __typename: 'AuctionTypeEnglish'
  /** English auction duration */
  duration: Scalars['Int']
  /** Auction extension time */
  extensionPeriod: Scalars['Int']
  /** Minimal step between auction bids */
  minimalBidStep: Scalars['Float']
  /** Block when auction is supposed to end */
  plannedEndAtBlock: Scalars['Int']
}

export type AuctionTypeOpen = {
  __typename: 'AuctionTypeOpen'
  /** Auction bid lock duration */
  bidLockDuration: Scalars['Int']
}

export type AuctionUpdateInput = {
  auctionType?: InputMaybe<Scalars['JSONObject']>
  buyNowPrice?: InputMaybe<Scalars['String']>
  endedAtBlock?: InputMaybe<Scalars['Float']>
  initialOwner?: InputMaybe<Scalars['ID']>
  isCanceled?: InputMaybe<Scalars['Boolean']>
  isCompleted?: InputMaybe<Scalars['Boolean']>
  nft?: InputMaybe<Scalars['ID']>
  startingPrice?: InputMaybe<Scalars['String']>
  startsAtBlock?: InputMaybe<Scalars['Float']>
  topBid?: InputMaybe<Scalars['ID']>
  winningMember?: InputMaybe<Scalars['ID']>
}

export type AuctionWhereInput = {
  AND?: InputMaybe<Array<AuctionWhereInput>>
  NOT?: InputMaybe<Array<AuctionWhereInput>>
  OR?: InputMaybe<Array<AuctionWhereInput>>
  auctionType_json?: InputMaybe<Scalars['JSONObject']>
  bids_every?: InputMaybe<BidWhereInput>
  bids_none?: InputMaybe<BidWhereInput>
  bids_some?: InputMaybe<BidWhereInput>
  buyNowPrice_eq?: InputMaybe<Scalars['BigInt']>
  buyNowPrice_gt?: InputMaybe<Scalars['BigInt']>
  buyNowPrice_gte?: InputMaybe<Scalars['BigInt']>
  buyNowPrice_in?: InputMaybe<Array<Scalars['BigInt']>>
  buyNowPrice_lt?: InputMaybe<Scalars['BigInt']>
  buyNowPrice_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  endedAtBlock_eq?: InputMaybe<Scalars['Int']>
  endedAtBlock_gt?: InputMaybe<Scalars['Int']>
  endedAtBlock_gte?: InputMaybe<Scalars['Int']>
  endedAtBlock_in?: InputMaybe<Array<Scalars['Int']>>
  endedAtBlock_lt?: InputMaybe<Scalars['Int']>
  endedAtBlock_lte?: InputMaybe<Scalars['Int']>
  englishauctionstartedeventauction_every?: InputMaybe<EnglishAuctionStartedEventWhereInput>
  englishauctionstartedeventauction_none?: InputMaybe<EnglishAuctionStartedEventWhereInput>
  englishauctionstartedeventauction_some?: InputMaybe<EnglishAuctionStartedEventWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  initialOwner?: InputMaybe<MembershipWhereInput>
  isCanceled_eq?: InputMaybe<Scalars['Boolean']>
  isCanceled_in?: InputMaybe<Array<Scalars['Boolean']>>
  isCompleted_eq?: InputMaybe<Scalars['Boolean']>
  isCompleted_in?: InputMaybe<Array<Scalars['Boolean']>>
  nft?: InputMaybe<OwnedNftWhereInput>
  openauctionstartedeventauction_every?: InputMaybe<OpenAuctionStartedEventWhereInput>
  openauctionstartedeventauction_none?: InputMaybe<OpenAuctionStartedEventWhereInput>
  openauctionstartedeventauction_some?: InputMaybe<OpenAuctionStartedEventWhereInput>
  ownednfttransactionalStatusAuction_every?: InputMaybe<OwnedNftWhereInput>
  ownednfttransactionalStatusAuction_none?: InputMaybe<OwnedNftWhereInput>
  ownednfttransactionalStatusAuction_some?: InputMaybe<OwnedNftWhereInput>
  startingPrice_eq?: InputMaybe<Scalars['BigInt']>
  startingPrice_gt?: InputMaybe<Scalars['BigInt']>
  startingPrice_gte?: InputMaybe<Scalars['BigInt']>
  startingPrice_in?: InputMaybe<Array<Scalars['BigInt']>>
  startingPrice_lt?: InputMaybe<Scalars['BigInt']>
  startingPrice_lte?: InputMaybe<Scalars['BigInt']>
  startsAtBlock_eq?: InputMaybe<Scalars['Int']>
  startsAtBlock_gt?: InputMaybe<Scalars['Int']>
  startsAtBlock_gte?: InputMaybe<Scalars['Int']>
  startsAtBlock_in?: InputMaybe<Array<Scalars['Int']>>
  startsAtBlock_lt?: InputMaybe<Scalars['Int']>
  startsAtBlock_lte?: InputMaybe<Scalars['Int']>
  topBid?: InputMaybe<BidWhereInput>
  transactionalstatusupdatetransactionalStatusAuction_every?: InputMaybe<TransactionalStatusUpdateWhereInput>
  transactionalstatusupdatetransactionalStatusAuction_none?: InputMaybe<TransactionalStatusUpdateWhereInput>
  transactionalstatusupdatetransactionalStatusAuction_some?: InputMaybe<TransactionalStatusUpdateWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  whitelistedMembers_every?: InputMaybe<MembershipWhereInput>
  whitelistedMembers_none?: InputMaybe<MembershipWhereInput>
  whitelistedMembers_some?: InputMaybe<MembershipWhereInput>
  winningMember?: InputMaybe<MembershipWhereInput>
}

export type AuctionWhereUniqueInput = {
  id: Scalars['ID']
}

export type Avatar = AvatarObject | AvatarUri

export type AvatarObject = {
  __typename: 'AvatarObject'
  /** The avatar data object */
  avatarObject?: Maybe<StorageDataObject>
}

export type AvatarUri = {
  __typename: 'AvatarUri'
  /** The avatar URL */
  avatarUri: Scalars['String']
}

export type BaseGraphQlObject = {
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type BaseModel = BaseGraphQlObject & {
  __typename: 'BaseModel'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type BaseModelUuid = BaseGraphQlObject & {
  __typename: 'BaseModelUUID'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type BaseWhereInput = {
  createdAt_eq?: InputMaybe<Scalars['String']>
  createdAt_gt?: InputMaybe<Scalars['String']>
  createdAt_gte?: InputMaybe<Scalars['String']>
  createdAt_lt?: InputMaybe<Scalars['String']>
  createdAt_lte?: InputMaybe<Scalars['String']>
  createdById_eq?: InputMaybe<Scalars['String']>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['String']>
  deletedAt_gt?: InputMaybe<Scalars['String']>
  deletedAt_gte?: InputMaybe<Scalars['String']>
  deletedAt_lt?: InputMaybe<Scalars['String']>
  deletedAt_lte?: InputMaybe<Scalars['String']>
  deletedById_eq?: InputMaybe<Scalars['String']>
  id_eq?: InputMaybe<Scalars['String']>
  id_in?: InputMaybe<Array<Scalars['String']>>
  updatedAt_eq?: InputMaybe<Scalars['String']>
  updatedAt_gt?: InputMaybe<Scalars['String']>
  updatedAt_gte?: InputMaybe<Scalars['String']>
  updatedAt_lt?: InputMaybe<Scalars['String']>
  updatedAt_lte?: InputMaybe<Scalars['String']>
  updatedById_eq?: InputMaybe<Scalars['String']>
}

/** Represents bid in NFT auction */
export type Bid = BaseGraphQlObject & {
  __typename: 'Bid'
  /** Amount bidded */
  amount: Scalars['BigInt']
  auction: Auction
  auctionId: Scalars['String']
  auctionTopBid?: Maybe<Auction>
  auctionbidmadeeventpreviousTopBid?: Maybe<Array<AuctionBidMadeEvent>>
  bidder: Membership
  bidderId: Scalars['String']
  bidmadecompletingauctioneventpreviousTopBid?: Maybe<Array<BidMadeCompletingAuctionEvent>>
  bidmadecompletingauctioneventwinningBid?: Maybe<Array<BidMadeCompletingAuctionEvent>>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  /** Block in which the bid was placed */
  createdInBlock: Scalars['Int']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  englishauctionsettledeventwinningBid?: Maybe<Array<EnglishAuctionSettledEvent>>
  id: Scalars['ID']
  /** Index of event in block where bid was made. */
  indexInBlock: Scalars['Int']
  /** Sign for canceled bid */
  isCanceled: Scalars['Boolean']
  nft: OwnedNft
  nftId: Scalars['String']
  openauctionbidacceptedeventwinningBid?: Maybe<Array<OpenAuctionBidAcceptedEvent>>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type BidConnection = {
  __typename: 'BidConnection'
  edges: Array<BidEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BidCreateInput = {
  amount: Scalars['String']
  auction: Scalars['ID']
  bidder: Scalars['ID']
  createdInBlock: Scalars['Float']
  indexInBlock: Scalars['Float']
  isCanceled: Scalars['Boolean']
  nft: Scalars['ID']
}

export type BidEdge = {
  __typename: 'BidEdge'
  cursor: Scalars['String']
  node: Bid
}

export type BidMadeCompletingAuctionEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BidMadeCompletingAuctionEvent'
    bidders: Array<Membership>
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    member: Membership
    memberId: Scalars['String']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    previousTopBid?: Maybe<Bid>
    previousTopBidId?: Maybe<Scalars['String']>
    previousTopBidder?: Maybe<Membership>
    previousTopBidderId?: Maybe<Scalars['String']>
    /** Price for which the NFT was bought */
    price: Scalars['BigInt']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
    winningBid: Bid
    winningBidId: Scalars['String']
  }

export type BidMadeCompletingAuctionEventConnection = {
  __typename: 'BidMadeCompletingAuctionEventConnection'
  edges: Array<BidMadeCompletingAuctionEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BidMadeCompletingAuctionEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  member: Scalars['ID']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  previousTopBid?: InputMaybe<Scalars['ID']>
  previousTopBidder?: InputMaybe<Scalars['ID']>
  price: Scalars['String']
  video: Scalars['ID']
  winningBid: Scalars['ID']
}

export type BidMadeCompletingAuctionEventEdge = {
  __typename: 'BidMadeCompletingAuctionEventEdge'
  cursor: Scalars['String']
  node: BidMadeCompletingAuctionEvent
}

export enum BidMadeCompletingAuctionEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  PreviousTopBidAsc = 'previousTopBid_ASC',
  PreviousTopBidDesc = 'previousTopBid_DESC',
  PreviousTopBidderAsc = 'previousTopBidder_ASC',
  PreviousTopBidderDesc = 'previousTopBidder_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
  WinningBidAsc = 'winningBid_ASC',
  WinningBidDesc = 'winningBid_DESC',
}

export type BidMadeCompletingAuctionEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  member?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  previousTopBid?: InputMaybe<Scalars['ID']>
  previousTopBidder?: InputMaybe<Scalars['ID']>
  price?: InputMaybe<Scalars['String']>
  video?: InputMaybe<Scalars['ID']>
  winningBid?: InputMaybe<Scalars['ID']>
}

export type BidMadeCompletingAuctionEventWhereInput = {
  AND?: InputMaybe<Array<BidMadeCompletingAuctionEventWhereInput>>
  NOT?: InputMaybe<Array<BidMadeCompletingAuctionEventWhereInput>>
  OR?: InputMaybe<Array<BidMadeCompletingAuctionEventWhereInput>>
  bidders_every?: InputMaybe<MembershipWhereInput>
  bidders_none?: InputMaybe<MembershipWhereInput>
  bidders_some?: InputMaybe<MembershipWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  member?: InputMaybe<MembershipWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  previousTopBid?: InputMaybe<BidWhereInput>
  previousTopBidder?: InputMaybe<MembershipWhereInput>
  price_eq?: InputMaybe<Scalars['BigInt']>
  price_gt?: InputMaybe<Scalars['BigInt']>
  price_gte?: InputMaybe<Scalars['BigInt']>
  price_in?: InputMaybe<Array<Scalars['BigInt']>>
  price_lt?: InputMaybe<Scalars['BigInt']>
  price_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
  winningBid?: InputMaybe<BidWhereInput>
}

export type BidMadeCompletingAuctionEventWhereUniqueInput = {
  id: Scalars['ID']
}

export enum BidOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  AuctionAsc = 'auction_ASC',
  AuctionDesc = 'auction_DESC',
  BidderAsc = 'bidder_ASC',
  BidderDesc = 'bidder_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CreatedInBlockAsc = 'createdInBlock_ASC',
  CreatedInBlockDesc = 'createdInBlock_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  IsCanceledAsc = 'isCanceled_ASC',
  IsCanceledDesc = 'isCanceled_DESC',
  NftAsc = 'nft_ASC',
  NftDesc = 'nft_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BidUpdateInput = {
  amount?: InputMaybe<Scalars['String']>
  auction?: InputMaybe<Scalars['ID']>
  bidder?: InputMaybe<Scalars['ID']>
  createdInBlock?: InputMaybe<Scalars['Float']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  isCanceled?: InputMaybe<Scalars['Boolean']>
  nft?: InputMaybe<Scalars['ID']>
}

export type BidWhereInput = {
  AND?: InputMaybe<Array<BidWhereInput>>
  NOT?: InputMaybe<Array<BidWhereInput>>
  OR?: InputMaybe<Array<BidWhereInput>>
  amount_eq?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  auction?: InputMaybe<AuctionWhereInput>
  auctionTopBid?: InputMaybe<AuctionWhereInput>
  auctionbidmadeeventpreviousTopBid_every?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctionbidmadeeventpreviousTopBid_none?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctionbidmadeeventpreviousTopBid_some?: InputMaybe<AuctionBidMadeEventWhereInput>
  bidder?: InputMaybe<MembershipWhereInput>
  bidmadecompletingauctioneventpreviousTopBid_every?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventpreviousTopBid_none?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventpreviousTopBid_some?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventwinningBid_every?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventwinningBid_none?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventwinningBid_some?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  createdInBlock_eq?: InputMaybe<Scalars['Int']>
  createdInBlock_gt?: InputMaybe<Scalars['Int']>
  createdInBlock_gte?: InputMaybe<Scalars['Int']>
  createdInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  createdInBlock_lt?: InputMaybe<Scalars['Int']>
  createdInBlock_lte?: InputMaybe<Scalars['Int']>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  englishauctionsettledeventwinningBid_every?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  englishauctionsettledeventwinningBid_none?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  englishauctionsettledeventwinningBid_some?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  isCanceled_eq?: InputMaybe<Scalars['Boolean']>
  isCanceled_in?: InputMaybe<Array<Scalars['Boolean']>>
  nft?: InputMaybe<OwnedNftWhereInput>
  openauctionbidacceptedeventwinningBid_every?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  openauctionbidacceptedeventwinningBid_none?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  openauctionbidacceptedeventwinningBid_some?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BidWhereUniqueInput = {
  id: Scalars['ID']
}

export type Bounty = BaseGraphQlObject & {
  __typename: 'Bounty'
  /** Bounty image uri */
  bannerImageUri?: Maybe<Scalars['String']>
  bountycreatorcherrywithdrawaleventbounty?: Maybe<Array<BountyCreatorCherryWithdrawalEvent>>
  canceledEvent?: Maybe<BountyCanceledEvent>
  /** Amount of funding provided by the creator */
  cherry: Scalars['BigInt']
  contributions?: Maybe<Array<BountyContribution>>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  createdInEvent: BountyCreatedEvent
  creator?: Maybe<Membership>
  creatorId?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** Bounty description */
  description?: Maybe<Scalars['String']>
  discussionThread?: Maybe<ForumThread>
  discussionThreadId?: Maybe<Scalars['String']>
  /** Stake minimum amount required to submit work entry to the bounty */
  entrantStake: Scalars['BigInt']
  entrantWhitelist?: Maybe<BountyEntrantWhitelist>
  entrantWhitelistId?: Maybe<Scalars['String']>
  entries?: Maybe<Array<BountyEntry>>
  /** Bounty funding type */
  fundingType: BountyFundingType
  id: Scalars['ID']
  /** If true the bounty lifecycle ended and its state will not change anymore */
  isTerminated: Scalars['Boolean']
  /** Number of block from end of work period until oracle can no longer decide winners */
  judgingPeriod: Scalars['Int']
  judgment?: Maybe<OracleJudgmentSubmittedEvent>
  maxFundingReachedEvent?: Maybe<BountyMaxFundingReachedEvent>
  oracle?: Maybe<Membership>
  oracleId?: Maybe<Scalars['String']>
  removedInEvent?: Maybe<BountyRemovedEvent>
  /** Current bounty stage */
  stage: BountyStage
  /** Bounty title */
  title?: Maybe<Scalars['String']>
  /** Total amount once contributed to the bounty (excluding the cherry) */
  totalFunding: Scalars['BigInt']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  vetoedEvent?: Maybe<BountyVetoedEvent>
  /** Number of blocks from end of funding period until people can no longer submit bounty submissions */
  workPeriod: Scalars['Int']
  worksubmittedeventbounty?: Maybe<Array<WorkSubmittedEvent>>
}

export type BountyCanceledEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BountyCanceledEvent'
    bounty: Bounty
    bountyId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BountyCanceledEventConnection = {
  __typename: 'BountyCanceledEventConnection'
  edges: Array<BountyCanceledEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BountyCanceledEventCreateInput = {
  bounty: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type BountyCanceledEventEdge = {
  __typename: 'BountyCanceledEventEdge'
  cursor: Scalars['String']
  node: BountyCanceledEvent
}

export enum BountyCanceledEventOrderByInput {
  BountyAsc = 'bounty_ASC',
  BountyDesc = 'bounty_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BountyCanceledEventUpdateInput = {
  bounty?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type BountyCanceledEventWhereInput = {
  AND?: InputMaybe<Array<BountyCanceledEventWhereInput>>
  NOT?: InputMaybe<Array<BountyCanceledEventWhereInput>>
  OR?: InputMaybe<Array<BountyCanceledEventWhereInput>>
  bounty?: InputMaybe<BountyWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BountyCanceledEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type BountyConnection = {
  __typename: 'BountyConnection'
  edges: Array<BountyEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BountyContribution = BaseGraphQlObject & {
  __typename: 'BountyContribution'
  /** Amount of the contribution */
  amount: Scalars['BigInt']
  bounty: Bounty
  bountyFundedEvents: Array<BountyFundedEvent>
  bountyId: Scalars['String']
  contributor?: Maybe<Membership>
  /** The id of the contributor */
  contributorId?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  withdrawnInEvent?: Maybe<BountyFundingWithdrawalEvent>
}

export type BountyContributionConnection = {
  __typename: 'BountyContributionConnection'
  edges: Array<BountyContributionEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BountyContributionCreateInput = {
  amount: Scalars['String']
  bounty: Scalars['ID']
  contributor?: InputMaybe<Scalars['ID']>
  contributorId?: InputMaybe<Scalars['String']>
}

export type BountyContributionEdge = {
  __typename: 'BountyContributionEdge'
  cursor: Scalars['String']
  node: BountyContribution
}

export enum BountyContributionOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  BountyAsc = 'bounty_ASC',
  BountyDesc = 'bounty_DESC',
  ContributorIdAsc = 'contributorId_ASC',
  ContributorIdDesc = 'contributorId_DESC',
  ContributorAsc = 'contributor_ASC',
  ContributorDesc = 'contributor_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BountyContributionUpdateInput = {
  amount?: InputMaybe<Scalars['String']>
  bounty?: InputMaybe<Scalars['ID']>
  contributor?: InputMaybe<Scalars['ID']>
  contributorId?: InputMaybe<Scalars['String']>
}

export type BountyContributionWhereInput = {
  AND?: InputMaybe<Array<BountyContributionWhereInput>>
  NOT?: InputMaybe<Array<BountyContributionWhereInput>>
  OR?: InputMaybe<Array<BountyContributionWhereInput>>
  amount_eq?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  bounty?: InputMaybe<BountyWhereInput>
  bountyFundedEvents_every?: InputMaybe<BountyFundedEventWhereInput>
  bountyFundedEvents_none?: InputMaybe<BountyFundedEventWhereInput>
  bountyFundedEvents_some?: InputMaybe<BountyFundedEventWhereInput>
  contributor?: InputMaybe<MembershipWhereInput>
  contributorId_contains?: InputMaybe<Scalars['String']>
  contributorId_endsWith?: InputMaybe<Scalars['String']>
  contributorId_eq?: InputMaybe<Scalars['String']>
  contributorId_in?: InputMaybe<Array<Scalars['String']>>
  contributorId_startsWith?: InputMaybe<Scalars['String']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  withdrawnInEvent?: InputMaybe<BountyFundingWithdrawalEventWhereInput>
}

export type BountyContributionWhereUniqueInput = {
  id: Scalars['ID']
}

export type BountyCreateInput = {
  bannerImageUri?: InputMaybe<Scalars['String']>
  cherry: Scalars['String']
  creator?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  discussionThread?: InputMaybe<Scalars['ID']>
  entrantStake: Scalars['String']
  entrantWhitelist?: InputMaybe<Scalars['ID']>
  fundingType: Scalars['JSONObject']
  isTerminated: Scalars['Boolean']
  judgingPeriod: Scalars['Float']
  oracle?: InputMaybe<Scalars['ID']>
  stage: BountyStage
  title?: InputMaybe<Scalars['String']>
  totalFunding: Scalars['String']
  workPeriod: Scalars['Float']
}

export type BountyCreatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BountyCreatedEvent'
    bounty: Bounty
    bountyId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BountyCreatedEventConnection = {
  __typename: 'BountyCreatedEventConnection'
  edges: Array<BountyCreatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BountyCreatedEventCreateInput = {
  bounty: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type BountyCreatedEventEdge = {
  __typename: 'BountyCreatedEventEdge'
  cursor: Scalars['String']
  node: BountyCreatedEvent
}

export enum BountyCreatedEventOrderByInput {
  BountyAsc = 'bounty_ASC',
  BountyDesc = 'bounty_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BountyCreatedEventUpdateInput = {
  bounty?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type BountyCreatedEventWhereInput = {
  AND?: InputMaybe<Array<BountyCreatedEventWhereInput>>
  NOT?: InputMaybe<Array<BountyCreatedEventWhereInput>>
  OR?: InputMaybe<Array<BountyCreatedEventWhereInput>>
  bounty?: InputMaybe<BountyWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BountyCreatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type BountyCreatorCherryWithdrawalEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BountyCreatorCherryWithdrawalEvent'
    bounty: Bounty
    bountyId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BountyCreatorCherryWithdrawalEventConnection = {
  __typename: 'BountyCreatorCherryWithdrawalEventConnection'
  edges: Array<BountyCreatorCherryWithdrawalEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BountyCreatorCherryWithdrawalEventCreateInput = {
  bounty: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type BountyCreatorCherryWithdrawalEventEdge = {
  __typename: 'BountyCreatorCherryWithdrawalEventEdge'
  cursor: Scalars['String']
  node: BountyCreatorCherryWithdrawalEvent
}

export enum BountyCreatorCherryWithdrawalEventOrderByInput {
  BountyAsc = 'bounty_ASC',
  BountyDesc = 'bounty_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BountyCreatorCherryWithdrawalEventUpdateInput = {
  bounty?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type BountyCreatorCherryWithdrawalEventWhereInput = {
  AND?: InputMaybe<Array<BountyCreatorCherryWithdrawalEventWhereInput>>
  NOT?: InputMaybe<Array<BountyCreatorCherryWithdrawalEventWhereInput>>
  OR?: InputMaybe<Array<BountyCreatorCherryWithdrawalEventWhereInput>>
  bounty?: InputMaybe<BountyWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BountyCreatorCherryWithdrawalEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type BountyEdge = {
  __typename: 'BountyEdge'
  cursor: Scalars['String']
  node: Bounty
}

export type BountyEntrantWhitelist = BaseGraphQlObject & {
  __typename: 'BountyEntrantWhitelist'
  bountyentrantWhitelist?: Maybe<Array<Bounty>>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  members: Array<Membership>
  phantom?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type BountyEntrantWhitelistConnection = {
  __typename: 'BountyEntrantWhitelistConnection'
  edges: Array<BountyEntrantWhitelistEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BountyEntrantWhitelistCreateInput = {
  phantom?: InputMaybe<Scalars['Float']>
}

export type BountyEntrantWhitelistEdge = {
  __typename: 'BountyEntrantWhitelistEdge'
  cursor: Scalars['String']
  node: BountyEntrantWhitelist
}

export enum BountyEntrantWhitelistOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  PhantomAsc = 'phantom_ASC',
  PhantomDesc = 'phantom_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BountyEntrantWhitelistUpdateInput = {
  phantom?: InputMaybe<Scalars['Float']>
}

export type BountyEntrantWhitelistWhereInput = {
  AND?: InputMaybe<Array<BountyEntrantWhitelistWhereInput>>
  NOT?: InputMaybe<Array<BountyEntrantWhitelistWhereInput>>
  OR?: InputMaybe<Array<BountyEntrantWhitelistWhereInput>>
  bountyentrantWhitelist_every?: InputMaybe<BountyWhereInput>
  bountyentrantWhitelist_none?: InputMaybe<BountyWhereInput>
  bountyentrantWhitelist_some?: InputMaybe<BountyWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  members_every?: InputMaybe<MembershipWhereInput>
  members_none?: InputMaybe<MembershipWhereInput>
  members_some?: InputMaybe<MembershipWhereInput>
  phantom_eq?: InputMaybe<Scalars['Int']>
  phantom_gt?: InputMaybe<Scalars['Int']>
  phantom_gte?: InputMaybe<Scalars['Int']>
  phantom_in?: InputMaybe<Array<Scalars['Int']>>
  phantom_lt?: InputMaybe<Scalars['Int']>
  phantom_lte?: InputMaybe<Scalars['Int']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BountyEntrantWhitelistWhereUniqueInput = {
  id: Scalars['ID']
}

export type BountyEntry = BaseGraphQlObject & {
  __typename: 'BountyEntry'
  announcedInEvent: WorkEntryAnnouncedEvent
  bounty: Bounty
  bountyId: Scalars['String']
  cashedOutInEvent?: Maybe<WorkEntrantFundsWithdrawnEvent>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  slashedInEvent?: Maybe<WorkEntrySlashedEvent>
  /** Staking account with the work entry stake */
  stakingAccount?: Maybe<Scalars['String']>
  /** Work entry status */
  status: BountyEntryStatus
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  withdrawnInEvent?: Maybe<WorkEntryWithdrawnEvent>
  /** Whether at least one work has been submitted */
  workSubmitted: Scalars['Boolean']
  worker: Membership
  workerId: Scalars['String']
  works?: Maybe<Array<WorkSubmittedEvent>>
}

export type BountyEntryConnection = {
  __typename: 'BountyEntryConnection'
  edges: Array<BountyEntryEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BountyEntryCreateInput = {
  bounty: Scalars['ID']
  stakingAccount?: InputMaybe<Scalars['String']>
  status: Scalars['JSONObject']
  workSubmitted: Scalars['Boolean']
  worker: Scalars['ID']
}

export type BountyEntryEdge = {
  __typename: 'BountyEntryEdge'
  cursor: Scalars['String']
  node: BountyEntry
}

export enum BountyEntryOrderByInput {
  BountyAsc = 'bounty_ASC',
  BountyDesc = 'bounty_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  StakingAccountAsc = 'stakingAccount_ASC',
  StakingAccountDesc = 'stakingAccount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkSubmittedAsc = 'workSubmitted_ASC',
  WorkSubmittedDesc = 'workSubmitted_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type BountyEntryStatus =
  | BountyEntryStatusPassed
  | BountyEntryStatusRejected
  | BountyEntryStatusWinner
  | BountyEntryStatusWithdrawn
  | BountyEntryStatusWorking

export type BountyEntryStatusPassed = {
  __typename: 'BountyEntryStatusPassed'
  dummy?: Maybe<Scalars['Int']>
}

export type BountyEntryStatusRejected = {
  __typename: 'BountyEntryStatusRejected'
  dummy?: Maybe<Scalars['Int']>
}

export type BountyEntryStatusWinner = {
  __typename: 'BountyEntryStatusWinner'
  reward: Scalars['Float']
}

export type BountyEntryStatusWithdrawn = {
  __typename: 'BountyEntryStatusWithdrawn'
  dummy?: Maybe<Scalars['Int']>
}

export type BountyEntryStatusWorking = {
  __typename: 'BountyEntryStatusWorking'
  dummy?: Maybe<Scalars['Int']>
}

export type BountyEntryUpdateInput = {
  bounty?: InputMaybe<Scalars['ID']>
  stakingAccount?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['JSONObject']>
  workSubmitted?: InputMaybe<Scalars['Boolean']>
  worker?: InputMaybe<Scalars['ID']>
}

export type BountyEntryWhereInput = {
  AND?: InputMaybe<Array<BountyEntryWhereInput>>
  NOT?: InputMaybe<Array<BountyEntryWhereInput>>
  OR?: InputMaybe<Array<BountyEntryWhereInput>>
  announcedInEvent?: InputMaybe<WorkEntryAnnouncedEventWhereInput>
  bounty?: InputMaybe<BountyWhereInput>
  cashedOutInEvent?: InputMaybe<WorkEntrantFundsWithdrawnEventWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  slashedInEvent?: InputMaybe<WorkEntrySlashedEventWhereInput>
  stakingAccount_contains?: InputMaybe<Scalars['String']>
  stakingAccount_endsWith?: InputMaybe<Scalars['String']>
  stakingAccount_eq?: InputMaybe<Scalars['String']>
  stakingAccount_in?: InputMaybe<Array<Scalars['String']>>
  stakingAccount_startsWith?: InputMaybe<Scalars['String']>
  status_json?: InputMaybe<Scalars['JSONObject']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  withdrawnInEvent?: InputMaybe<WorkEntryWithdrawnEventWhereInput>
  workSubmitted_eq?: InputMaybe<Scalars['Boolean']>
  workSubmitted_in?: InputMaybe<Array<Scalars['Boolean']>>
  worker?: InputMaybe<MembershipWhereInput>
  works_every?: InputMaybe<WorkSubmittedEventWhereInput>
  works_none?: InputMaybe<WorkSubmittedEventWhereInput>
  works_some?: InputMaybe<WorkSubmittedEventWhereInput>
}

export type BountyEntryWhereUniqueInput = {
  id: Scalars['ID']
}

export type BountyFundedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BountyFundedEvent'
    contribution: BountyContribution
    contributionId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BountyFundedEventConnection = {
  __typename: 'BountyFundedEventConnection'
  edges: Array<BountyFundedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BountyFundedEventCreateInput = {
  contribution: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type BountyFundedEventEdge = {
  __typename: 'BountyFundedEventEdge'
  cursor: Scalars['String']
  node: BountyFundedEvent
}

export enum BountyFundedEventOrderByInput {
  ContributionAsc = 'contribution_ASC',
  ContributionDesc = 'contribution_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BountyFundedEventUpdateInput = {
  contribution?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type BountyFundedEventWhereInput = {
  AND?: InputMaybe<Array<BountyFundedEventWhereInput>>
  NOT?: InputMaybe<Array<BountyFundedEventWhereInput>>
  OR?: InputMaybe<Array<BountyFundedEventWhereInput>>
  contribution?: InputMaybe<BountyContributionWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BountyFundedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type BountyFundingLimited = {
  __typename: 'BountyFundingLimited'
  /** Maximum allowed funding period */
  fundingPeriod: Scalars['Int']
  /** Upper boundary for a bounty funding */
  maxFundingAmount: Scalars['Float']
  /** Minimum amount of funds for a successful bounty */
  minFundingAmount: Scalars['Float']
}

export type BountyFundingPerpetual = {
  __typename: 'BountyFundingPerpetual'
  /** Desired funding */
  target: Scalars['Float']
}

export type BountyFundingType = BountyFundingLimited | BountyFundingPerpetual

export type BountyFundingWithdrawalEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BountyFundingWithdrawalEvent'
    contribution: BountyContribution
    contributionId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BountyFundingWithdrawalEventConnection = {
  __typename: 'BountyFundingWithdrawalEventConnection'
  edges: Array<BountyFundingWithdrawalEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BountyFundingWithdrawalEventCreateInput = {
  contribution: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type BountyFundingWithdrawalEventEdge = {
  __typename: 'BountyFundingWithdrawalEventEdge'
  cursor: Scalars['String']
  node: BountyFundingWithdrawalEvent
}

export enum BountyFundingWithdrawalEventOrderByInput {
  ContributionAsc = 'contribution_ASC',
  ContributionDesc = 'contribution_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BountyFundingWithdrawalEventUpdateInput = {
  contribution?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type BountyFundingWithdrawalEventWhereInput = {
  AND?: InputMaybe<Array<BountyFundingWithdrawalEventWhereInput>>
  NOT?: InputMaybe<Array<BountyFundingWithdrawalEventWhereInput>>
  OR?: InputMaybe<Array<BountyFundingWithdrawalEventWhereInput>>
  contribution?: InputMaybe<BountyContributionWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BountyFundingWithdrawalEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type BountyMaxFundingReachedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BountyMaxFundingReachedEvent'
    bounty: Bounty
    bountyId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BountyMaxFundingReachedEventConnection = {
  __typename: 'BountyMaxFundingReachedEventConnection'
  edges: Array<BountyMaxFundingReachedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BountyMaxFundingReachedEventCreateInput = {
  bounty: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type BountyMaxFundingReachedEventEdge = {
  __typename: 'BountyMaxFundingReachedEventEdge'
  cursor: Scalars['String']
  node: BountyMaxFundingReachedEvent
}

export enum BountyMaxFundingReachedEventOrderByInput {
  BountyAsc = 'bounty_ASC',
  BountyDesc = 'bounty_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BountyMaxFundingReachedEventUpdateInput = {
  bounty?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type BountyMaxFundingReachedEventWhereInput = {
  AND?: InputMaybe<Array<BountyMaxFundingReachedEventWhereInput>>
  NOT?: InputMaybe<Array<BountyMaxFundingReachedEventWhereInput>>
  OR?: InputMaybe<Array<BountyMaxFundingReachedEventWhereInput>>
  bounty?: InputMaybe<BountyWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BountyMaxFundingReachedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export enum BountyOrderByInput {
  BannerImageUriAsc = 'bannerImageUri_ASC',
  BannerImageUriDesc = 'bannerImageUri_DESC',
  CherryAsc = 'cherry_ASC',
  CherryDesc = 'cherry_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CreatorAsc = 'creator_ASC',
  CreatorDesc = 'creator_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DiscussionThreadAsc = 'discussionThread_ASC',
  DiscussionThreadDesc = 'discussionThread_DESC',
  EntrantStakeAsc = 'entrantStake_ASC',
  EntrantStakeDesc = 'entrantStake_DESC',
  EntrantWhitelistAsc = 'entrantWhitelist_ASC',
  EntrantWhitelistDesc = 'entrantWhitelist_DESC',
  IsTerminatedAsc = 'isTerminated_ASC',
  IsTerminatedDesc = 'isTerminated_DESC',
  JudgingPeriodAsc = 'judgingPeriod_ASC',
  JudgingPeriodDesc = 'judgingPeriod_DESC',
  OracleAsc = 'oracle_ASC',
  OracleDesc = 'oracle_DESC',
  StageAsc = 'stage_ASC',
  StageDesc = 'stage_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TotalFundingAsc = 'totalFunding_ASC',
  TotalFundingDesc = 'totalFunding_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkPeriodAsc = 'workPeriod_ASC',
  WorkPeriodDesc = 'workPeriod_DESC',
}

export type BountyRemovedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BountyRemovedEvent'
    bounty: Bounty
    bountyId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BountyRemovedEventConnection = {
  __typename: 'BountyRemovedEventConnection'
  edges: Array<BountyRemovedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BountyRemovedEventCreateInput = {
  bounty: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type BountyRemovedEventEdge = {
  __typename: 'BountyRemovedEventEdge'
  cursor: Scalars['String']
  node: BountyRemovedEvent
}

export enum BountyRemovedEventOrderByInput {
  BountyAsc = 'bounty_ASC',
  BountyDesc = 'bounty_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BountyRemovedEventUpdateInput = {
  bounty?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type BountyRemovedEventWhereInput = {
  AND?: InputMaybe<Array<BountyRemovedEventWhereInput>>
  NOT?: InputMaybe<Array<BountyRemovedEventWhereInput>>
  OR?: InputMaybe<Array<BountyRemovedEventWhereInput>>
  bounty?: InputMaybe<BountyWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BountyRemovedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export enum BountyStage {
  Expired = 'Expired',
  Failed = 'Failed',
  Funding = 'Funding',
  Judgment = 'Judgment',
  Successful = 'Successful',
  WorkSubmission = 'WorkSubmission',
}

export type BountyUpdateInput = {
  bannerImageUri?: InputMaybe<Scalars['String']>
  cherry?: InputMaybe<Scalars['String']>
  creator?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  discussionThread?: InputMaybe<Scalars['ID']>
  entrantStake?: InputMaybe<Scalars['String']>
  entrantWhitelist?: InputMaybe<Scalars['ID']>
  fundingType?: InputMaybe<Scalars['JSONObject']>
  isTerminated?: InputMaybe<Scalars['Boolean']>
  judgingPeriod?: InputMaybe<Scalars['Float']>
  oracle?: InputMaybe<Scalars['ID']>
  stage?: InputMaybe<BountyStage>
  title?: InputMaybe<Scalars['String']>
  totalFunding?: InputMaybe<Scalars['String']>
  workPeriod?: InputMaybe<Scalars['Float']>
}

export type BountyVetoedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BountyVetoedEvent'
    bounty: Bounty
    bountyId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BountyVetoedEventConnection = {
  __typename: 'BountyVetoedEventConnection'
  edges: Array<BountyVetoedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BountyVetoedEventCreateInput = {
  bounty: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type BountyVetoedEventEdge = {
  __typename: 'BountyVetoedEventEdge'
  cursor: Scalars['String']
  node: BountyVetoedEvent
}

export enum BountyVetoedEventOrderByInput {
  BountyAsc = 'bounty_ASC',
  BountyDesc = 'bounty_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BountyVetoedEventUpdateInput = {
  bounty?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type BountyVetoedEventWhereInput = {
  AND?: InputMaybe<Array<BountyVetoedEventWhereInput>>
  NOT?: InputMaybe<Array<BountyVetoedEventWhereInput>>
  OR?: InputMaybe<Array<BountyVetoedEventWhereInput>>
  bounty?: InputMaybe<BountyWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BountyVetoedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type BountyWhereInput = {
  AND?: InputMaybe<Array<BountyWhereInput>>
  NOT?: InputMaybe<Array<BountyWhereInput>>
  OR?: InputMaybe<Array<BountyWhereInput>>
  bannerImageUri_contains?: InputMaybe<Scalars['String']>
  bannerImageUri_endsWith?: InputMaybe<Scalars['String']>
  bannerImageUri_eq?: InputMaybe<Scalars['String']>
  bannerImageUri_in?: InputMaybe<Array<Scalars['String']>>
  bannerImageUri_startsWith?: InputMaybe<Scalars['String']>
  bountycreatorcherrywithdrawaleventbounty_every?: InputMaybe<BountyCreatorCherryWithdrawalEventWhereInput>
  bountycreatorcherrywithdrawaleventbounty_none?: InputMaybe<BountyCreatorCherryWithdrawalEventWhereInput>
  bountycreatorcherrywithdrawaleventbounty_some?: InputMaybe<BountyCreatorCherryWithdrawalEventWhereInput>
  canceledEvent?: InputMaybe<BountyCanceledEventWhereInput>
  cherry_eq?: InputMaybe<Scalars['BigInt']>
  cherry_gt?: InputMaybe<Scalars['BigInt']>
  cherry_gte?: InputMaybe<Scalars['BigInt']>
  cherry_in?: InputMaybe<Array<Scalars['BigInt']>>
  cherry_lt?: InputMaybe<Scalars['BigInt']>
  cherry_lte?: InputMaybe<Scalars['BigInt']>
  contributions_every?: InputMaybe<BountyContributionWhereInput>
  contributions_none?: InputMaybe<BountyContributionWhereInput>
  contributions_some?: InputMaybe<BountyContributionWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  createdInEvent?: InputMaybe<BountyCreatedEventWhereInput>
  creator?: InputMaybe<MembershipWhereInput>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  description_contains?: InputMaybe<Scalars['String']>
  description_endsWith?: InputMaybe<Scalars['String']>
  description_eq?: InputMaybe<Scalars['String']>
  description_in?: InputMaybe<Array<Scalars['String']>>
  description_startsWith?: InputMaybe<Scalars['String']>
  discussionThread?: InputMaybe<ForumThreadWhereInput>
  entrantStake_eq?: InputMaybe<Scalars['BigInt']>
  entrantStake_gt?: InputMaybe<Scalars['BigInt']>
  entrantStake_gte?: InputMaybe<Scalars['BigInt']>
  entrantStake_in?: InputMaybe<Array<Scalars['BigInt']>>
  entrantStake_lt?: InputMaybe<Scalars['BigInt']>
  entrantStake_lte?: InputMaybe<Scalars['BigInt']>
  entrantWhitelist?: InputMaybe<BountyEntrantWhitelistWhereInput>
  entries_every?: InputMaybe<BountyEntryWhereInput>
  entries_none?: InputMaybe<BountyEntryWhereInput>
  entries_some?: InputMaybe<BountyEntryWhereInput>
  fundingType_json?: InputMaybe<Scalars['JSONObject']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  isTerminated_eq?: InputMaybe<Scalars['Boolean']>
  isTerminated_in?: InputMaybe<Array<Scalars['Boolean']>>
  judgingPeriod_eq?: InputMaybe<Scalars['Int']>
  judgingPeriod_gt?: InputMaybe<Scalars['Int']>
  judgingPeriod_gte?: InputMaybe<Scalars['Int']>
  judgingPeriod_in?: InputMaybe<Array<Scalars['Int']>>
  judgingPeriod_lt?: InputMaybe<Scalars['Int']>
  judgingPeriod_lte?: InputMaybe<Scalars['Int']>
  judgment?: InputMaybe<OracleJudgmentSubmittedEventWhereInput>
  maxFundingReachedEvent?: InputMaybe<BountyMaxFundingReachedEventWhereInput>
  oracle?: InputMaybe<MembershipWhereInput>
  removedInEvent?: InputMaybe<BountyRemovedEventWhereInput>
  stage_eq?: InputMaybe<BountyStage>
  stage_in?: InputMaybe<Array<BountyStage>>
  title_contains?: InputMaybe<Scalars['String']>
  title_endsWith?: InputMaybe<Scalars['String']>
  title_eq?: InputMaybe<Scalars['String']>
  title_in?: InputMaybe<Array<Scalars['String']>>
  title_startsWith?: InputMaybe<Scalars['String']>
  totalFunding_eq?: InputMaybe<Scalars['BigInt']>
  totalFunding_gt?: InputMaybe<Scalars['BigInt']>
  totalFunding_gte?: InputMaybe<Scalars['BigInt']>
  totalFunding_in?: InputMaybe<Array<Scalars['BigInt']>>
  totalFunding_lt?: InputMaybe<Scalars['BigInt']>
  totalFunding_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  vetoedEvent?: InputMaybe<BountyVetoedEventWhereInput>
  workPeriod_eq?: InputMaybe<Scalars['Int']>
  workPeriod_gt?: InputMaybe<Scalars['Int']>
  workPeriod_gte?: InputMaybe<Scalars['Int']>
  workPeriod_in?: InputMaybe<Array<Scalars['Int']>>
  workPeriod_lt?: InputMaybe<Scalars['Int']>
  workPeriod_lte?: InputMaybe<Scalars['Int']>
  worksubmittedeventbounty_every?: InputMaybe<WorkSubmittedEventWhereInput>
  worksubmittedeventbounty_none?: InputMaybe<WorkSubmittedEventWhereInput>
  worksubmittedeventbounty_some?: InputMaybe<WorkSubmittedEventWhereInput>
}

export type BountyWhereUniqueInput = {
  id: Scalars['ID']
}

export type BudgetBalanceSetEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BudgetBalanceSetEvent'
    /** Budget balance that has been set. */
    balance: Scalars['BigInt']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BudgetBalanceSetEventConnection = {
  __typename: 'BudgetBalanceSetEventConnection'
  edges: Array<BudgetBalanceSetEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BudgetBalanceSetEventCreateInput = {
  balance: Scalars['String']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type BudgetBalanceSetEventEdge = {
  __typename: 'BudgetBalanceSetEventEdge'
  cursor: Scalars['String']
  node: BudgetBalanceSetEvent
}

export enum BudgetBalanceSetEventOrderByInput {
  BalanceAsc = 'balance_ASC',
  BalanceDesc = 'balance_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BudgetBalanceSetEventUpdateInput = {
  balance?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type BudgetBalanceSetEventWhereInput = {
  AND?: InputMaybe<Array<BudgetBalanceSetEventWhereInput>>
  NOT?: InputMaybe<Array<BudgetBalanceSetEventWhereInput>>
  OR?: InputMaybe<Array<BudgetBalanceSetEventWhereInput>>
  balance_eq?: InputMaybe<Scalars['BigInt']>
  balance_gt?: InputMaybe<Scalars['BigInt']>
  balance_gte?: InputMaybe<Scalars['BigInt']>
  balance_in?: InputMaybe<Array<Scalars['BigInt']>>
  balance_lt?: InputMaybe<Scalars['BigInt']>
  balance_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BudgetBalanceSetEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type BudgetIncrementUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BudgetIncrementUpdatedEvent'
    /** Amount that is added to the budget each time it's refilled. */
    amount: Scalars['BigInt']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BudgetIncrementUpdatedEventConnection = {
  __typename: 'BudgetIncrementUpdatedEventConnection'
  edges: Array<BudgetIncrementUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BudgetIncrementUpdatedEventCreateInput = {
  amount: Scalars['String']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type BudgetIncrementUpdatedEventEdge = {
  __typename: 'BudgetIncrementUpdatedEventEdge'
  cursor: Scalars['String']
  node: BudgetIncrementUpdatedEvent
}

export enum BudgetIncrementUpdatedEventOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BudgetIncrementUpdatedEventUpdateInput = {
  amount?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type BudgetIncrementUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<BudgetIncrementUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<BudgetIncrementUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<BudgetIncrementUpdatedEventWhereInput>>
  amount_eq?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BudgetIncrementUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type BudgetRefillEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BudgetRefillEvent'
    /** Balance that has been refilled. */
    balance: Scalars['BigInt']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BudgetRefillEventConnection = {
  __typename: 'BudgetRefillEventConnection'
  edges: Array<BudgetRefillEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BudgetRefillEventCreateInput = {
  balance: Scalars['String']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type BudgetRefillEventEdge = {
  __typename: 'BudgetRefillEventEdge'
  cursor: Scalars['String']
  node: BudgetRefillEvent
}

export enum BudgetRefillEventOrderByInput {
  BalanceAsc = 'balance_ASC',
  BalanceDesc = 'balance_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BudgetRefillEventUpdateInput = {
  balance?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type BudgetRefillEventWhereInput = {
  AND?: InputMaybe<Array<BudgetRefillEventWhereInput>>
  NOT?: InputMaybe<Array<BudgetRefillEventWhereInput>>
  OR?: InputMaybe<Array<BudgetRefillEventWhereInput>>
  balance_eq?: InputMaybe<Scalars['BigInt']>
  balance_gt?: InputMaybe<Scalars['BigInt']>
  balance_gte?: InputMaybe<Scalars['BigInt']>
  balance_in?: InputMaybe<Array<Scalars['BigInt']>>
  balance_lt?: InputMaybe<Scalars['BigInt']>
  balance_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BudgetRefillEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type BudgetRefillPlannedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BudgetRefillPlannedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    nextRefillInBlock: Scalars['Int']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BudgetRefillPlannedEventConnection = {
  __typename: 'BudgetRefillPlannedEventConnection'
  edges: Array<BudgetRefillPlannedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BudgetRefillPlannedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  nextRefillInBlock: Scalars['Float']
}

export type BudgetRefillPlannedEventEdge = {
  __typename: 'BudgetRefillPlannedEventEdge'
  cursor: Scalars['String']
  node: BudgetRefillPlannedEvent
}

export enum BudgetRefillPlannedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NextRefillInBlockAsc = 'nextRefillInBlock_ASC',
  NextRefillInBlockDesc = 'nextRefillInBlock_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BudgetRefillPlannedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  nextRefillInBlock?: InputMaybe<Scalars['Float']>
}

export type BudgetRefillPlannedEventWhereInput = {
  AND?: InputMaybe<Array<BudgetRefillPlannedEventWhereInput>>
  NOT?: InputMaybe<Array<BudgetRefillPlannedEventWhereInput>>
  OR?: InputMaybe<Array<BudgetRefillPlannedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  nextRefillInBlock_eq?: InputMaybe<Scalars['Int']>
  nextRefillInBlock_gt?: InputMaybe<Scalars['Int']>
  nextRefillInBlock_gte?: InputMaybe<Scalars['Int']>
  nextRefillInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  nextRefillInBlock_lt?: InputMaybe<Scalars['Int']>
  nextRefillInBlock_lte?: InputMaybe<Scalars['Int']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BudgetRefillPlannedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type BudgetSetEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BudgetSetEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** New working group budget */
    newBudget: Scalars['BigInt']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BudgetSetEventConnection = {
  __typename: 'BudgetSetEventConnection'
  edges: Array<BudgetSetEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BudgetSetEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newBudget: Scalars['String']
}

export type BudgetSetEventEdge = {
  __typename: 'BudgetSetEventEdge'
  cursor: Scalars['String']
  node: BudgetSetEvent
}

export enum BudgetSetEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewBudgetAsc = 'newBudget_ASC',
  NewBudgetDesc = 'newBudget_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BudgetSetEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newBudget?: InputMaybe<Scalars['String']>
}

export type BudgetSetEventWhereInput = {
  AND?: InputMaybe<Array<BudgetSetEventWhereInput>>
  NOT?: InputMaybe<Array<BudgetSetEventWhereInput>>
  OR?: InputMaybe<Array<BudgetSetEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newBudget_eq?: InputMaybe<Scalars['BigInt']>
  newBudget_gt?: InputMaybe<Scalars['BigInt']>
  newBudget_gte?: InputMaybe<Scalars['BigInt']>
  newBudget_in?: InputMaybe<Array<Scalars['BigInt']>>
  newBudget_lt?: InputMaybe<Scalars['BigInt']>
  newBudget_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BudgetSetEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type BudgetSpendingEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BudgetSpendingEvent'
    /** Amount beeing spent */
    amount: Scalars['BigInt']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Optional rationale */
    rationale?: Maybe<Scalars['String']>
    /** Reciever account address */
    reciever: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BudgetSpendingEventConnection = {
  __typename: 'BudgetSpendingEventConnection'
  edges: Array<BudgetSpendingEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BudgetSpendingEventCreateInput = {
  amount: Scalars['String']
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  rationale?: InputMaybe<Scalars['String']>
  reciever: Scalars['String']
}

export type BudgetSpendingEventEdge = {
  __typename: 'BudgetSpendingEventEdge'
  cursor: Scalars['String']
  node: BudgetSpendingEvent
}

export enum BudgetSpendingEventOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  RecieverAsc = 'reciever_ASC',
  RecieverDesc = 'reciever_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BudgetSpendingEventUpdateInput = {
  amount?: InputMaybe<Scalars['String']>
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  rationale?: InputMaybe<Scalars['String']>
  reciever?: InputMaybe<Scalars['String']>
}

export type BudgetSpendingEventWhereInput = {
  AND?: InputMaybe<Array<BudgetSpendingEventWhereInput>>
  NOT?: InputMaybe<Array<BudgetSpendingEventWhereInput>>
  OR?: InputMaybe<Array<BudgetSpendingEventWhereInput>>
  amount_eq?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  reciever_contains?: InputMaybe<Scalars['String']>
  reciever_endsWith?: InputMaybe<Scalars['String']>
  reciever_eq?: InputMaybe<Scalars['String']>
  reciever_in?: InputMaybe<Array<Scalars['String']>>
  reciever_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BudgetSpendingEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type BudgetUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BudgetUpdatedEvent'
    /** Amount substracted from / added to the current budget */
    budgetChangeAmount: Scalars['BigInt']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type BudgetUpdatedEventConnection = {
  __typename: 'BudgetUpdatedEventConnection'
  edges: Array<BudgetUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BudgetUpdatedEventCreateInput = {
  budgetChangeAmount: Scalars['String']
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type BudgetUpdatedEventEdge = {
  __typename: 'BudgetUpdatedEventEdge'
  cursor: Scalars['String']
  node: BudgetUpdatedEvent
}

export enum BudgetUpdatedEventOrderByInput {
  BudgetChangeAmountAsc = 'budgetChangeAmount_ASC',
  BudgetChangeAmountDesc = 'budgetChangeAmount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BudgetUpdatedEventUpdateInput = {
  budgetChangeAmount?: InputMaybe<Scalars['String']>
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type BudgetUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<BudgetUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<BudgetUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<BudgetUpdatedEventWhereInput>>
  budgetChangeAmount_eq?: InputMaybe<Scalars['BigInt']>
  budgetChangeAmount_gt?: InputMaybe<Scalars['BigInt']>
  budgetChangeAmount_gte?: InputMaybe<Scalars['BigInt']>
  budgetChangeAmount_in?: InputMaybe<Array<Scalars['BigInt']>>
  budgetChangeAmount_lt?: InputMaybe<Scalars['BigInt']>
  budgetChangeAmount_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type BudgetUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type BuyNowCanceledEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BuyNowCanceledEvent'
    /** Content actor acting as NFT owner. */
    contentActor: ContentActor
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
  }

export type BuyNowCanceledEventConnection = {
  __typename: 'BuyNowCanceledEventConnection'
  edges: Array<BuyNowCanceledEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BuyNowCanceledEventCreateInput = {
  contentActor: Scalars['JSONObject']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video: Scalars['ID']
}

export type BuyNowCanceledEventEdge = {
  __typename: 'BuyNowCanceledEventEdge'
  cursor: Scalars['String']
  node: BuyNowCanceledEvent
}

export enum BuyNowCanceledEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type BuyNowCanceledEventUpdateInput = {
  contentActor?: InputMaybe<Scalars['JSONObject']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video?: InputMaybe<Scalars['ID']>
}

export type BuyNowCanceledEventWhereInput = {
  AND?: InputMaybe<Array<BuyNowCanceledEventWhereInput>>
  NOT?: InputMaybe<Array<BuyNowCanceledEventWhereInput>>
  OR?: InputMaybe<Array<BuyNowCanceledEventWhereInput>>
  contentActor_json?: InputMaybe<Scalars['JSONObject']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type BuyNowCanceledEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type BuyNowPriceUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'BuyNowPriceUpdatedEvent'
    /** Content actor acting as NFT owner. */
    contentActor: ContentActor
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in. */
    network: Network
    /** New buy-now price. */
    newPrice: Scalars['BigInt']
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
  }

export type BuyNowPriceUpdatedEventConnection = {
  __typename: 'BuyNowPriceUpdatedEventConnection'
  edges: Array<BuyNowPriceUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type BuyNowPriceUpdatedEventCreateInput = {
  contentActor: Scalars['JSONObject']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newPrice: Scalars['String']
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video: Scalars['ID']
}

export type BuyNowPriceUpdatedEventEdge = {
  __typename: 'BuyNowPriceUpdatedEventEdge'
  cursor: Scalars['String']
  node: BuyNowPriceUpdatedEvent
}

export enum BuyNowPriceUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewPriceAsc = 'newPrice_ASC',
  NewPriceDesc = 'newPrice_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type BuyNowPriceUpdatedEventUpdateInput = {
  contentActor?: InputMaybe<Scalars['JSONObject']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newPrice?: InputMaybe<Scalars['String']>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video?: InputMaybe<Scalars['ID']>
}

export type BuyNowPriceUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<BuyNowPriceUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<BuyNowPriceUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<BuyNowPriceUpdatedEventWhereInput>>
  contentActor_json?: InputMaybe<Scalars['JSONObject']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newPrice_eq?: InputMaybe<Scalars['BigInt']>
  newPrice_gt?: InputMaybe<Scalars['BigInt']>
  newPrice_gte?: InputMaybe<Scalars['BigInt']>
  newPrice_in?: InputMaybe<Array<Scalars['BigInt']>>
  newPrice_lt?: InputMaybe<Scalars['BigInt']>
  newPrice_lte?: InputMaybe<Scalars['BigInt']>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type BuyNowPriceUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type CancelWorkingGroupLeadOpeningProposalDetails = {
  __typename: 'CancelWorkingGroupLeadOpeningProposalDetails'
  /** Opening to be cancelled */
  opening?: Maybe<WorkingGroupOpening>
}

export type CandidacyNoteMetadata = BaseGraphQlObject & {
  __typename: 'CandidacyNoteMetadata'
  /** Image uri of candidate's banner. */
  bannerImageUri?: Maybe<Scalars['String']>
  /** Candidate program in form of bullet points. Takes array with one empty string [''] as deletion request. */
  bulletPoints: Array<Scalars['String']>
  candidacynoteseteventnoteMetadata?: Maybe<Array<CandidacyNoteSetEvent>>
  candidatenoteMetadata?: Maybe<Array<Candidate>>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** Candidacy description (Markdown-formatted). */
  description?: Maybe<Scalars['String']>
  /** Candidacy header text. */
  header?: Maybe<Scalars['String']>
  id: Scalars['ID']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type CandidacyNoteMetadataConnection = {
  __typename: 'CandidacyNoteMetadataConnection'
  edges: Array<CandidacyNoteMetadataEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CandidacyNoteMetadataCreateInput = {
  bannerImageUri?: InputMaybe<Scalars['String']>
  bulletPoints: Array<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  header?: InputMaybe<Scalars['String']>
}

export type CandidacyNoteMetadataEdge = {
  __typename: 'CandidacyNoteMetadataEdge'
  cursor: Scalars['String']
  node: CandidacyNoteMetadata
}

export enum CandidacyNoteMetadataOrderByInput {
  BannerImageUriAsc = 'bannerImageUri_ASC',
  BannerImageUriDesc = 'bannerImageUri_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CandidacyNoteMetadataUpdateInput = {
  bannerImageUri?: InputMaybe<Scalars['String']>
  bulletPoints?: InputMaybe<Array<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  header?: InputMaybe<Scalars['String']>
}

export type CandidacyNoteMetadataWhereInput = {
  AND?: InputMaybe<Array<CandidacyNoteMetadataWhereInput>>
  NOT?: InputMaybe<Array<CandidacyNoteMetadataWhereInput>>
  OR?: InputMaybe<Array<CandidacyNoteMetadataWhereInput>>
  bannerImageUri_contains?: InputMaybe<Scalars['String']>
  bannerImageUri_endsWith?: InputMaybe<Scalars['String']>
  bannerImageUri_eq?: InputMaybe<Scalars['String']>
  bannerImageUri_in?: InputMaybe<Array<Scalars['String']>>
  bannerImageUri_startsWith?: InputMaybe<Scalars['String']>
  bulletPoints_containsAll?: InputMaybe<Array<Scalars['String']>>
  bulletPoints_containsAny?: InputMaybe<Array<Scalars['String']>>
  bulletPoints_containsNone?: InputMaybe<Array<Scalars['String']>>
  candidacynoteseteventnoteMetadata_every?: InputMaybe<CandidacyNoteSetEventWhereInput>
  candidacynoteseteventnoteMetadata_none?: InputMaybe<CandidacyNoteSetEventWhereInput>
  candidacynoteseteventnoteMetadata_some?: InputMaybe<CandidacyNoteSetEventWhereInput>
  candidatenoteMetadata_every?: InputMaybe<CandidateWhereInput>
  candidatenoteMetadata_none?: InputMaybe<CandidateWhereInput>
  candidatenoteMetadata_some?: InputMaybe<CandidateWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  description_contains?: InputMaybe<Scalars['String']>
  description_endsWith?: InputMaybe<Scalars['String']>
  description_eq?: InputMaybe<Scalars['String']>
  description_in?: InputMaybe<Array<Scalars['String']>>
  description_startsWith?: InputMaybe<Scalars['String']>
  header_contains?: InputMaybe<Scalars['String']>
  header_endsWith?: InputMaybe<Scalars['String']>
  header_eq?: InputMaybe<Scalars['String']>
  header_in?: InputMaybe<Array<Scalars['String']>>
  header_startsWith?: InputMaybe<Scalars['String']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CandidacyNoteMetadataWhereUniqueInput = {
  id: Scalars['ID']
}

export type CandidacyNoteSetEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CandidacyNoteSetEvent'
    candidate: Candidate
    candidateId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    noteMetadata: CandidacyNoteMetadata
    noteMetadataId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type CandidacyNoteSetEventConnection = {
  __typename: 'CandidacyNoteSetEventConnection'
  edges: Array<CandidacyNoteSetEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CandidacyNoteSetEventCreateInput = {
  candidate: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  noteMetadata: Scalars['ID']
}

export type CandidacyNoteSetEventEdge = {
  __typename: 'CandidacyNoteSetEventEdge'
  cursor: Scalars['String']
  node: CandidacyNoteSetEvent
}

export enum CandidacyNoteSetEventOrderByInput {
  CandidateAsc = 'candidate_ASC',
  CandidateDesc = 'candidate_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NoteMetadataAsc = 'noteMetadata_ASC',
  NoteMetadataDesc = 'noteMetadata_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CandidacyNoteSetEventUpdateInput = {
  candidate?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  noteMetadata?: InputMaybe<Scalars['ID']>
}

export type CandidacyNoteSetEventWhereInput = {
  AND?: InputMaybe<Array<CandidacyNoteSetEventWhereInput>>
  NOT?: InputMaybe<Array<CandidacyNoteSetEventWhereInput>>
  OR?: InputMaybe<Array<CandidacyNoteSetEventWhereInput>>
  candidate?: InputMaybe<CandidateWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  noteMetadata?: InputMaybe<CandidacyNoteMetadataWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CandidacyNoteSetEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type CandidacyStakeReleaseEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CandidacyStakeReleaseEvent'
    candidate: Candidate
    candidateId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type CandidacyStakeReleaseEventConnection = {
  __typename: 'CandidacyStakeReleaseEventConnection'
  edges: Array<CandidacyStakeReleaseEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CandidacyStakeReleaseEventCreateInput = {
  candidate: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type CandidacyStakeReleaseEventEdge = {
  __typename: 'CandidacyStakeReleaseEventEdge'
  cursor: Scalars['String']
  node: CandidacyStakeReleaseEvent
}

export enum CandidacyStakeReleaseEventOrderByInput {
  CandidateAsc = 'candidate_ASC',
  CandidateDesc = 'candidate_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CandidacyStakeReleaseEventUpdateInput = {
  candidate?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type CandidacyStakeReleaseEventWhereInput = {
  AND?: InputMaybe<Array<CandidacyStakeReleaseEventWhereInput>>
  NOT?: InputMaybe<Array<CandidacyStakeReleaseEventWhereInput>>
  OR?: InputMaybe<Array<CandidacyStakeReleaseEventWhereInput>>
  candidate?: InputMaybe<CandidateWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CandidacyStakeReleaseEventWhereUniqueInput = {
  id: Scalars['ID']
}

export enum CandidacyStatus {
  Active = 'ACTIVE',
  Elected = 'ELECTED',
  Failed = 'FAILED',
  Withdrawn = 'WITHDRAWN',
}

export type CandidacyWithdrawEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CandidacyWithdrawEvent'
    candidate: Candidate
    candidateId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type CandidacyWithdrawEventConnection = {
  __typename: 'CandidacyWithdrawEventConnection'
  edges: Array<CandidacyWithdrawEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CandidacyWithdrawEventCreateInput = {
  candidate: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type CandidacyWithdrawEventEdge = {
  __typename: 'CandidacyWithdrawEventEdge'
  cursor: Scalars['String']
  node: CandidacyWithdrawEvent
}

export enum CandidacyWithdrawEventOrderByInput {
  CandidateAsc = 'candidate_ASC',
  CandidateDesc = 'candidate_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CandidacyWithdrawEventUpdateInput = {
  candidate?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type CandidacyWithdrawEventWhereInput = {
  AND?: InputMaybe<Array<CandidacyWithdrawEventWhereInput>>
  NOT?: InputMaybe<Array<CandidacyWithdrawEventWhereInput>>
  OR?: InputMaybe<Array<CandidacyWithdrawEventWhereInput>>
  candidate?: InputMaybe<CandidateWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CandidacyWithdrawEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type Candidate = BaseGraphQlObject & {
  __typename: 'Candidate'
  candidacynoteseteventcandidate?: Maybe<Array<CandidacyNoteSetEvent>>
  candidacystakereleaseeventcandidate?: Maybe<Array<CandidacyStakeReleaseEvent>>
  candidacywithdraweventcandidate?: Maybe<Array<CandidacyWithdrawEvent>>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  electionRound: ElectionRound
  electionRoundId: Scalars['String']
  id: Scalars['ID']
  /** Block in which the last vote was received. */
  lastVoteReceivedAtBlock?: Maybe<Scalars['BigInt']>
  /** Event number in block in which the last vote was received. */
  lastVoteReceivedAtEventNumber?: Maybe<Scalars['Int']>
  member: Membership
  memberId: Scalars['String']
  newcandidateeventcandidate?: Maybe<Array<NewCandidateEvent>>
  noteMetadata: CandidacyNoteMetadata
  noteMetadataId: Scalars['String']
  /** Account that will receive rewards if candidate's elected to the council. */
  rewardAccountId: Scalars['String']
  /** Stake locked for the candidacy. */
  stake: Scalars['BigInt']
  /** Reflects if the stake is still locked for candidacy or has been already released by the member. */
  stakeLocked: Scalars['Boolean']
  /** Account used for staking currency needed for the candidacy. */
  stakingAccountId: Scalars['String']
  /** Current candidate status */
  status: CandidacyStatus
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  /** Sum of power of all votes received. */
  votePower: Scalars['BigInt']
  votesReceived: Array<CastVote>
}

export type CandidateConnection = {
  __typename: 'CandidateConnection'
  edges: Array<CandidateEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CandidateCreateInput = {
  electionRound: Scalars['ID']
  lastVoteReceivedAtBlock?: InputMaybe<Scalars['String']>
  lastVoteReceivedAtEventNumber?: InputMaybe<Scalars['Float']>
  member: Scalars['ID']
  noteMetadata: Scalars['ID']
  rewardAccountId: Scalars['String']
  stake: Scalars['String']
  stakeLocked: Scalars['Boolean']
  stakingAccountId: Scalars['String']
  status: CandidacyStatus
  votePower: Scalars['String']
}

export type CandidateEdge = {
  __typename: 'CandidateEdge'
  cursor: Scalars['String']
  node: Candidate
}

export enum CandidateOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ElectionRoundAsc = 'electionRound_ASC',
  ElectionRoundDesc = 'electionRound_DESC',
  LastVoteReceivedAtBlockAsc = 'lastVoteReceivedAtBlock_ASC',
  LastVoteReceivedAtBlockDesc = 'lastVoteReceivedAtBlock_DESC',
  LastVoteReceivedAtEventNumberAsc = 'lastVoteReceivedAtEventNumber_ASC',
  LastVoteReceivedAtEventNumberDesc = 'lastVoteReceivedAtEventNumber_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  NoteMetadataAsc = 'noteMetadata_ASC',
  NoteMetadataDesc = 'noteMetadata_DESC',
  RewardAccountIdAsc = 'rewardAccountId_ASC',
  RewardAccountIdDesc = 'rewardAccountId_DESC',
  StakeLockedAsc = 'stakeLocked_ASC',
  StakeLockedDesc = 'stakeLocked_DESC',
  StakeAsc = 'stake_ASC',
  StakeDesc = 'stake_DESC',
  StakingAccountIdAsc = 'stakingAccountId_ASC',
  StakingAccountIdDesc = 'stakingAccountId_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VotePowerAsc = 'votePower_ASC',
  VotePowerDesc = 'votePower_DESC',
}

export type CandidateUpdateInput = {
  electionRound?: InputMaybe<Scalars['ID']>
  lastVoteReceivedAtBlock?: InputMaybe<Scalars['String']>
  lastVoteReceivedAtEventNumber?: InputMaybe<Scalars['Float']>
  member?: InputMaybe<Scalars['ID']>
  noteMetadata?: InputMaybe<Scalars['ID']>
  rewardAccountId?: InputMaybe<Scalars['String']>
  stake?: InputMaybe<Scalars['String']>
  stakeLocked?: InputMaybe<Scalars['Boolean']>
  stakingAccountId?: InputMaybe<Scalars['String']>
  status?: InputMaybe<CandidacyStatus>
  votePower?: InputMaybe<Scalars['String']>
}

export type CandidateWhereInput = {
  AND?: InputMaybe<Array<CandidateWhereInput>>
  NOT?: InputMaybe<Array<CandidateWhereInput>>
  OR?: InputMaybe<Array<CandidateWhereInput>>
  candidacynoteseteventcandidate_every?: InputMaybe<CandidacyNoteSetEventWhereInput>
  candidacynoteseteventcandidate_none?: InputMaybe<CandidacyNoteSetEventWhereInput>
  candidacynoteseteventcandidate_some?: InputMaybe<CandidacyNoteSetEventWhereInput>
  candidacystakereleaseeventcandidate_every?: InputMaybe<CandidacyStakeReleaseEventWhereInput>
  candidacystakereleaseeventcandidate_none?: InputMaybe<CandidacyStakeReleaseEventWhereInput>
  candidacystakereleaseeventcandidate_some?: InputMaybe<CandidacyStakeReleaseEventWhereInput>
  candidacywithdraweventcandidate_every?: InputMaybe<CandidacyWithdrawEventWhereInput>
  candidacywithdraweventcandidate_none?: InputMaybe<CandidacyWithdrawEventWhereInput>
  candidacywithdraweventcandidate_some?: InputMaybe<CandidacyWithdrawEventWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  electionRound?: InputMaybe<ElectionRoundWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  lastVoteReceivedAtBlock_eq?: InputMaybe<Scalars['BigInt']>
  lastVoteReceivedAtBlock_gt?: InputMaybe<Scalars['BigInt']>
  lastVoteReceivedAtBlock_gte?: InputMaybe<Scalars['BigInt']>
  lastVoteReceivedAtBlock_in?: InputMaybe<Array<Scalars['BigInt']>>
  lastVoteReceivedAtBlock_lt?: InputMaybe<Scalars['BigInt']>
  lastVoteReceivedAtBlock_lte?: InputMaybe<Scalars['BigInt']>
  lastVoteReceivedAtEventNumber_eq?: InputMaybe<Scalars['Int']>
  lastVoteReceivedAtEventNumber_gt?: InputMaybe<Scalars['Int']>
  lastVoteReceivedAtEventNumber_gte?: InputMaybe<Scalars['Int']>
  lastVoteReceivedAtEventNumber_in?: InputMaybe<Array<Scalars['Int']>>
  lastVoteReceivedAtEventNumber_lt?: InputMaybe<Scalars['Int']>
  lastVoteReceivedAtEventNumber_lte?: InputMaybe<Scalars['Int']>
  member?: InputMaybe<MembershipWhereInput>
  newcandidateeventcandidate_every?: InputMaybe<NewCandidateEventWhereInput>
  newcandidateeventcandidate_none?: InputMaybe<NewCandidateEventWhereInput>
  newcandidateeventcandidate_some?: InputMaybe<NewCandidateEventWhereInput>
  noteMetadata?: InputMaybe<CandidacyNoteMetadataWhereInput>
  rewardAccountId_contains?: InputMaybe<Scalars['String']>
  rewardAccountId_endsWith?: InputMaybe<Scalars['String']>
  rewardAccountId_eq?: InputMaybe<Scalars['String']>
  rewardAccountId_in?: InputMaybe<Array<Scalars['String']>>
  rewardAccountId_startsWith?: InputMaybe<Scalars['String']>
  stakeLocked_eq?: InputMaybe<Scalars['Boolean']>
  stakeLocked_in?: InputMaybe<Array<Scalars['Boolean']>>
  stake_eq?: InputMaybe<Scalars['BigInt']>
  stake_gt?: InputMaybe<Scalars['BigInt']>
  stake_gte?: InputMaybe<Scalars['BigInt']>
  stake_in?: InputMaybe<Array<Scalars['BigInt']>>
  stake_lt?: InputMaybe<Scalars['BigInt']>
  stake_lte?: InputMaybe<Scalars['BigInt']>
  stakingAccountId_contains?: InputMaybe<Scalars['String']>
  stakingAccountId_endsWith?: InputMaybe<Scalars['String']>
  stakingAccountId_eq?: InputMaybe<Scalars['String']>
  stakingAccountId_in?: InputMaybe<Array<Scalars['String']>>
  stakingAccountId_startsWith?: InputMaybe<Scalars['String']>
  status_eq?: InputMaybe<CandidacyStatus>
  status_in?: InputMaybe<Array<CandidacyStatus>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  votePower_eq?: InputMaybe<Scalars['BigInt']>
  votePower_gt?: InputMaybe<Scalars['BigInt']>
  votePower_gte?: InputMaybe<Scalars['BigInt']>
  votePower_in?: InputMaybe<Array<Scalars['BigInt']>>
  votePower_lt?: InputMaybe<Scalars['BigInt']>
  votePower_lte?: InputMaybe<Scalars['BigInt']>
  votesReceived_every?: InputMaybe<CastVoteWhereInput>
  votesReceived_none?: InputMaybe<CastVoteWhereInput>
  votesReceived_some?: InputMaybe<CastVoteWhereInput>
}

export type CandidateWhereUniqueInput = {
  id: Scalars['ID']
}

export type CastVote = BaseGraphQlObject & {
  __typename: 'CastVote'
  /** Account that cast the vote. */
  castBy: Scalars['String']
  /** Hashed vote that was casted before being revealed. Hex format. */
  commitment: Scalars['String']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  electionRound: ElectionRound
  electionRoundId: Scalars['String']
  id: Scalars['ID']
  /** Stake used to back up the vote. */
  stake: Scalars['BigInt']
  /** Reflects if the stake is still locked for candidacy or has been already released by the member. */
  stakeLocked: Scalars['Boolean']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  voteFor?: Maybe<Candidate>
  voteForId?: Maybe<Scalars['String']>
  /** Vote's power. */
  votePower: Scalars['BigInt']
  votecasteventcastVote?: Maybe<Array<VoteCastEvent>>
  voterevealedeventcastVote?: Maybe<Array<VoteRevealedEvent>>
}

export type CastVoteConnection = {
  __typename: 'CastVoteConnection'
  edges: Array<CastVoteEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CastVoteCreateInput = {
  castBy: Scalars['String']
  commitment: Scalars['String']
  electionRound: Scalars['ID']
  stake: Scalars['String']
  stakeLocked: Scalars['Boolean']
  voteFor?: InputMaybe<Scalars['ID']>
  votePower: Scalars['String']
}

export type CastVoteEdge = {
  __typename: 'CastVoteEdge'
  cursor: Scalars['String']
  node: CastVote
}

export enum CastVoteOrderByInput {
  CastByAsc = 'castBy_ASC',
  CastByDesc = 'castBy_DESC',
  CommitmentAsc = 'commitment_ASC',
  CommitmentDesc = 'commitment_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ElectionRoundAsc = 'electionRound_ASC',
  ElectionRoundDesc = 'electionRound_DESC',
  StakeLockedAsc = 'stakeLocked_ASC',
  StakeLockedDesc = 'stakeLocked_DESC',
  StakeAsc = 'stake_ASC',
  StakeDesc = 'stake_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VoteForAsc = 'voteFor_ASC',
  VoteForDesc = 'voteFor_DESC',
  VotePowerAsc = 'votePower_ASC',
  VotePowerDesc = 'votePower_DESC',
}

export type CastVoteUpdateInput = {
  castBy?: InputMaybe<Scalars['String']>
  commitment?: InputMaybe<Scalars['String']>
  electionRound?: InputMaybe<Scalars['ID']>
  stake?: InputMaybe<Scalars['String']>
  stakeLocked?: InputMaybe<Scalars['Boolean']>
  voteFor?: InputMaybe<Scalars['ID']>
  votePower?: InputMaybe<Scalars['String']>
}

export type CastVoteWhereInput = {
  AND?: InputMaybe<Array<CastVoteWhereInput>>
  NOT?: InputMaybe<Array<CastVoteWhereInput>>
  OR?: InputMaybe<Array<CastVoteWhereInput>>
  castBy_contains?: InputMaybe<Scalars['String']>
  castBy_endsWith?: InputMaybe<Scalars['String']>
  castBy_eq?: InputMaybe<Scalars['String']>
  castBy_in?: InputMaybe<Array<Scalars['String']>>
  castBy_startsWith?: InputMaybe<Scalars['String']>
  commitment_contains?: InputMaybe<Scalars['String']>
  commitment_endsWith?: InputMaybe<Scalars['String']>
  commitment_eq?: InputMaybe<Scalars['String']>
  commitment_in?: InputMaybe<Array<Scalars['String']>>
  commitment_startsWith?: InputMaybe<Scalars['String']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  electionRound?: InputMaybe<ElectionRoundWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  stakeLocked_eq?: InputMaybe<Scalars['Boolean']>
  stakeLocked_in?: InputMaybe<Array<Scalars['Boolean']>>
  stake_eq?: InputMaybe<Scalars['BigInt']>
  stake_gt?: InputMaybe<Scalars['BigInt']>
  stake_gte?: InputMaybe<Scalars['BigInt']>
  stake_in?: InputMaybe<Array<Scalars['BigInt']>>
  stake_lt?: InputMaybe<Scalars['BigInt']>
  stake_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  voteFor?: InputMaybe<CandidateWhereInput>
  votePower_eq?: InputMaybe<Scalars['BigInt']>
  votePower_gt?: InputMaybe<Scalars['BigInt']>
  votePower_gte?: InputMaybe<Scalars['BigInt']>
  votePower_in?: InputMaybe<Array<Scalars['BigInt']>>
  votePower_lt?: InputMaybe<Scalars['BigInt']>
  votePower_lte?: InputMaybe<Scalars['BigInt']>
  votecasteventcastVote_every?: InputMaybe<VoteCastEventWhereInput>
  votecasteventcastVote_none?: InputMaybe<VoteCastEventWhereInput>
  votecasteventcastVote_some?: InputMaybe<VoteCastEventWhereInput>
  voterevealedeventcastVote_every?: InputMaybe<VoteRevealedEventWhereInput>
  voterevealedeventcastVote_none?: InputMaybe<VoteRevealedEventWhereInput>
  voterevealedeventcastVote_some?: InputMaybe<VoteRevealedEventWhereInput>
}

export type CastVoteWhereUniqueInput = {
  id: Scalars['ID']
}

export type CategoryArchivalStatusUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CategoryArchivalStatusUpdatedEvent'
    actor: Worker
    actorId: Scalars['String']
    category: ForumCategory
    categoryId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** The new archival status of the category (true = archived) */
    newArchivalStatus: Scalars['Boolean']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type CategoryArchivalStatusUpdatedEventConnection = {
  __typename: 'CategoryArchivalStatusUpdatedEventConnection'
  edges: Array<CategoryArchivalStatusUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CategoryArchivalStatusUpdatedEventCreateInput = {
  actor: Scalars['ID']
  category: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newArchivalStatus: Scalars['Boolean']
}

export type CategoryArchivalStatusUpdatedEventEdge = {
  __typename: 'CategoryArchivalStatusUpdatedEventEdge'
  cursor: Scalars['String']
  node: CategoryArchivalStatusUpdatedEvent
}

export enum CategoryArchivalStatusUpdatedEventOrderByInput {
  ActorAsc = 'actor_ASC',
  ActorDesc = 'actor_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewArchivalStatusAsc = 'newArchivalStatus_ASC',
  NewArchivalStatusDesc = 'newArchivalStatus_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CategoryArchivalStatusUpdatedEventUpdateInput = {
  actor?: InputMaybe<Scalars['ID']>
  category?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newArchivalStatus?: InputMaybe<Scalars['Boolean']>
}

export type CategoryArchivalStatusUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<CategoryArchivalStatusUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<CategoryArchivalStatusUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<CategoryArchivalStatusUpdatedEventWhereInput>>
  actor?: InputMaybe<WorkerWhereInput>
  category?: InputMaybe<ForumCategoryWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newArchivalStatus_eq?: InputMaybe<Scalars['Boolean']>
  newArchivalStatus_in?: InputMaybe<Array<Scalars['Boolean']>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CategoryArchivalStatusUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type CategoryCreatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CategoryCreatedEvent'
    category: ForumCategory
    categoryId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type CategoryCreatedEventConnection = {
  __typename: 'CategoryCreatedEventConnection'
  edges: Array<CategoryCreatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CategoryCreatedEventCreateInput = {
  category: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type CategoryCreatedEventEdge = {
  __typename: 'CategoryCreatedEventEdge'
  cursor: Scalars['String']
  node: CategoryCreatedEvent
}

export enum CategoryCreatedEventOrderByInput {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CategoryCreatedEventUpdateInput = {
  category?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type CategoryCreatedEventWhereInput = {
  AND?: InputMaybe<Array<CategoryCreatedEventWhereInput>>
  NOT?: InputMaybe<Array<CategoryCreatedEventWhereInput>>
  OR?: InputMaybe<Array<CategoryCreatedEventWhereInput>>
  category?: InputMaybe<ForumCategoryWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CategoryCreatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type CategoryDeletedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CategoryDeletedEvent'
    actor: Worker
    actorId: Scalars['String']
    category: ForumCategory
    categoryId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type CategoryDeletedEventConnection = {
  __typename: 'CategoryDeletedEventConnection'
  edges: Array<CategoryDeletedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CategoryDeletedEventCreateInput = {
  actor: Scalars['ID']
  category: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type CategoryDeletedEventEdge = {
  __typename: 'CategoryDeletedEventEdge'
  cursor: Scalars['String']
  node: CategoryDeletedEvent
}

export enum CategoryDeletedEventOrderByInput {
  ActorAsc = 'actor_ASC',
  ActorDesc = 'actor_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CategoryDeletedEventUpdateInput = {
  actor?: InputMaybe<Scalars['ID']>
  category?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type CategoryDeletedEventWhereInput = {
  AND?: InputMaybe<Array<CategoryDeletedEventWhereInput>>
  NOT?: InputMaybe<Array<CategoryDeletedEventWhereInput>>
  OR?: InputMaybe<Array<CategoryDeletedEventWhereInput>>
  actor?: InputMaybe<WorkerWhereInput>
  category?: InputMaybe<ForumCategoryWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CategoryDeletedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type CategoryMembershipOfModeratorUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CategoryMembershipOfModeratorUpdatedEvent'
    category: ForumCategory
    categoryId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    moderator: Worker
    moderatorId: Scalars['String']
    /** Network the block was produced in */
    network: Network
    /** The flag indicating whether the permissions to moderate the category are granted or revoked */
    newCanModerateValue: Scalars['Boolean']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type CategoryMembershipOfModeratorUpdatedEventConnection = {
  __typename: 'CategoryMembershipOfModeratorUpdatedEventConnection'
  edges: Array<CategoryMembershipOfModeratorUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CategoryMembershipOfModeratorUpdatedEventCreateInput = {
  category: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  moderator: Scalars['ID']
  network: Network
  newCanModerateValue: Scalars['Boolean']
}

export type CategoryMembershipOfModeratorUpdatedEventEdge = {
  __typename: 'CategoryMembershipOfModeratorUpdatedEventEdge'
  cursor: Scalars['String']
  node: CategoryMembershipOfModeratorUpdatedEvent
}

export enum CategoryMembershipOfModeratorUpdatedEventOrderByInput {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  ModeratorAsc = 'moderator_ASC',
  ModeratorDesc = 'moderator_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewCanModerateValueAsc = 'newCanModerateValue_ASC',
  NewCanModerateValueDesc = 'newCanModerateValue_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CategoryMembershipOfModeratorUpdatedEventUpdateInput = {
  category?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  moderator?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
  newCanModerateValue?: InputMaybe<Scalars['Boolean']>
}

export type CategoryMembershipOfModeratorUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<CategoryMembershipOfModeratorUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<CategoryMembershipOfModeratorUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<CategoryMembershipOfModeratorUpdatedEventWhereInput>>
  category?: InputMaybe<ForumCategoryWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  moderator?: InputMaybe<WorkerWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newCanModerateValue_eq?: InputMaybe<Scalars['Boolean']>
  newCanModerateValue_in?: InputMaybe<Array<Scalars['Boolean']>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CategoryMembershipOfModeratorUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type CategoryStatus = CategoryStatusActive | CategoryStatusArchived | CategoryStatusRemoved

export type CategoryStatusActive = {
  __typename: 'CategoryStatusActive'
  phantom?: Maybe<Scalars['Int']>
}

export type CategoryStatusArchived = {
  __typename: 'CategoryStatusArchived'
  /** Event the category was archived in */
  categoryArchivalStatusUpdatedEvent?: Maybe<CategoryArchivalStatusUpdatedEvent>
}

export type CategoryStatusRemoved = {
  __typename: 'CategoryStatusRemoved'
  /** Event the category was deleted in */
  categoryDeletedEvent?: Maybe<CategoryDeletedEvent>
}

export type CategoryStickyThreadUpdateEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CategoryStickyThreadUpdateEvent'
    actor: Worker
    actorId: Scalars['String']
    category: ForumCategory
    categoryId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    newStickyThreads: Array<ForumThread>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type CategoryStickyThreadUpdateEventConnection = {
  __typename: 'CategoryStickyThreadUpdateEventConnection'
  edges: Array<CategoryStickyThreadUpdateEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CategoryStickyThreadUpdateEventCreateInput = {
  actor: Scalars['ID']
  category: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type CategoryStickyThreadUpdateEventEdge = {
  __typename: 'CategoryStickyThreadUpdateEventEdge'
  cursor: Scalars['String']
  node: CategoryStickyThreadUpdateEvent
}

export enum CategoryStickyThreadUpdateEventOrderByInput {
  ActorAsc = 'actor_ASC',
  ActorDesc = 'actor_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CategoryStickyThreadUpdateEventUpdateInput = {
  actor?: InputMaybe<Scalars['ID']>
  category?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type CategoryStickyThreadUpdateEventWhereInput = {
  AND?: InputMaybe<Array<CategoryStickyThreadUpdateEventWhereInput>>
  NOT?: InputMaybe<Array<CategoryStickyThreadUpdateEventWhereInput>>
  OR?: InputMaybe<Array<CategoryStickyThreadUpdateEventWhereInput>>
  actor?: InputMaybe<WorkerWhereInput>
  category?: InputMaybe<ForumCategoryWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newStickyThreads_every?: InputMaybe<ForumThreadWhereInput>
  newStickyThreads_none?: InputMaybe<ForumThreadWhereInput>
  newStickyThreads_some?: InputMaybe<ForumThreadWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CategoryStickyThreadUpdateEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type Channel = BaseGraphQlObject & {
  __typename: 'Channel'
  /** Count of channel's videos with an uploaded asset that are public and not censored. */
  activeVideosCounter: Scalars['Int']
  avatarPhoto?: Maybe<StorageDataObject>
  avatarPhotoId?: Maybe<Scalars['String']>
  bannedMembers: Array<Membership>
  channelNftCollectors: Array<ChannelNftCollectors>
  /** Value of channel state bloat bond fee paid by channel creator */
  channelStateBloatBond: Scalars['BigInt']
  channelfundswithdrawneventchannel?: Maybe<Array<ChannelFundsWithdrawnEvent>>
  channelpaymentmadeeventpayeeChannel?: Maybe<Array<ChannelPaymentMadeEvent>>
  channelrewardclaimedandwithdrawneventchannel?: Maybe<Array<ChannelRewardClaimedAndWithdrawnEvent>>
  claimedRewards: Array<ChannelRewardClaimedEvent>
  collaborators: Array<Collaborator>
  commentcreatedeventvideoChannel?: Maybe<Array<CommentCreatedEvent>>
  commentdeletedeventvideoChannel?: Maybe<Array<CommentDeletedEvent>>
  commentmoderatedeventvideoChannel?: Maybe<Array<CommentModeratedEvent>>
  commentpinnedeventvideoChannel?: Maybe<Array<CommentPinnedEvent>>
  commentreactedeventvideoChannel?: Maybe<Array<CommentReactedEvent>>
  commenttextupdatedeventvideoChannel?: Maybe<Array<CommentTextUpdatedEvent>>
  coverPhoto?: Maybe<StorageDataObject>
  coverPhotoId?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  /** Number of the block the channel was created in */
  createdInBlock: Scalars['Int']
  /** Cumulative rewards claimed by this channel */
  cumulativeRewardClaimed?: Maybe<Scalars['BigInt']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** The description of a Channel */
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** Flag signaling whether a channel is censored. */
  isCensored: Scalars['Boolean']
  /** Flag signaling whether a channel is public. */
  isPublic?: Maybe<Scalars['Boolean']>
  language?: Maybe<Language>
  languageId?: Maybe<Scalars['String']>
  memberbannedfromchanneleventchannel?: Maybe<Array<MemberBannedFromChannelEvent>>
  ownednftcreatorChannel?: Maybe<Array<OwnedNft>>
  ownerCuratorGroup?: Maybe<CuratorGroup>
  ownerCuratorGroupId?: Maybe<Scalars['String']>
  ownerMember?: Maybe<Membership>
  ownerMemberId?: Maybe<Scalars['String']>
  /** Channel's privilege level */
  privilegeLevel?: Maybe<Scalars['Int']>
  /** Channel's reward account, storing the income from the nft sales and channel payouts. */
  rewardAccount: Scalars['String']
  /** The title of the Channel */
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  videoreactedeventvideoChannel?: Maybe<Array<VideoReactedEvent>>
  videos: Array<Video>
}

export type ChannelAssetsDeletedByModeratorEvent = BaseGraphQlObject & {
  __typename: 'ChannelAssetsDeletedByModeratorEvent'
  /** Actor that deleted the channel assets. */
  actor: ContentActor
  /** ID  of the deleted video */
  assetIds: Array<Scalars['Int']>
  /** Channel whose assets are deleted */
  channelId: Scalars['Int']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Blocknumber of the block in which the event was emitted. */
  inBlock: Scalars['Int']
  /** Hash of the extrinsic which caused the event to be emitted. */
  inExtrinsic?: Maybe<Scalars['String']>
  /** Index of event in block from which it was emitted. */
  indexInBlock: Scalars['Int']
  /** Network the block was produced in. */
  network: Network
  /** why the channel assets were deleted */
  rationale: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type ChannelAssetsDeletedByModeratorEventConnection = {
  __typename: 'ChannelAssetsDeletedByModeratorEventConnection'
  edges: Array<ChannelAssetsDeletedByModeratorEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ChannelAssetsDeletedByModeratorEventCreateInput = {
  actor: Scalars['JSONObject']
  assetIds: Array<Scalars['Int']>
  channelId: Scalars['Float']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  rationale: Scalars['String']
}

export type ChannelAssetsDeletedByModeratorEventEdge = {
  __typename: 'ChannelAssetsDeletedByModeratorEventEdge'
  cursor: Scalars['String']
  node: ChannelAssetsDeletedByModeratorEvent
}

export enum ChannelAssetsDeletedByModeratorEventOrderByInput {
  ChannelIdAsc = 'channelId_ASC',
  ChannelIdDesc = 'channelId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ChannelAssetsDeletedByModeratorEventUpdateInput = {
  actor?: InputMaybe<Scalars['JSONObject']>
  assetIds?: InputMaybe<Array<Scalars['Int']>>
  channelId?: InputMaybe<Scalars['Float']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  rationale?: InputMaybe<Scalars['String']>
}

export type ChannelAssetsDeletedByModeratorEventWhereInput = {
  AND?: InputMaybe<Array<ChannelAssetsDeletedByModeratorEventWhereInput>>
  NOT?: InputMaybe<Array<ChannelAssetsDeletedByModeratorEventWhereInput>>
  OR?: InputMaybe<Array<ChannelAssetsDeletedByModeratorEventWhereInput>>
  actor_json?: InputMaybe<Scalars['JSONObject']>
  assetIds_containsAll?: InputMaybe<Array<Scalars['Int']>>
  assetIds_containsAny?: InputMaybe<Array<Scalars['Int']>>
  assetIds_containsNone?: InputMaybe<Array<Scalars['Int']>>
  channelId_eq?: InputMaybe<Scalars['Int']>
  channelId_gt?: InputMaybe<Scalars['Int']>
  channelId_gte?: InputMaybe<Scalars['Int']>
  channelId_in?: InputMaybe<Array<Scalars['Int']>>
  channelId_lt?: InputMaybe<Scalars['Int']>
  channelId_lte?: InputMaybe<Scalars['Int']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ChannelAssetsDeletedByModeratorEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ChannelConnection = {
  __typename: 'ChannelConnection'
  edges: Array<ChannelEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ChannelCreateInput = {
  activeVideosCounter: Scalars['Float']
  avatarPhoto?: InputMaybe<Scalars['ID']>
  channelStateBloatBond: Scalars['String']
  coverPhoto?: InputMaybe<Scalars['ID']>
  createdInBlock: Scalars['Float']
  cumulativeRewardClaimed?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  isCensored: Scalars['Boolean']
  isPublic?: InputMaybe<Scalars['Boolean']>
  language?: InputMaybe<Scalars['ID']>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  privilegeLevel?: InputMaybe<Scalars['Float']>
  rewardAccount: Scalars['String']
  title?: InputMaybe<Scalars['String']>
}

export type ChannelDeletedByModeratorEvent = BaseGraphQlObject & {
  __typename: 'ChannelDeletedByModeratorEvent'
  /** Actor that deleted the video. */
  actor: ContentActor
  /** ID  of the deleted channel */
  channelId: Scalars['Int']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Blocknumber of the block in which the event was emitted. */
  inBlock: Scalars['Int']
  /** Hash of the extrinsic which caused the event to be emitted. */
  inExtrinsic?: Maybe<Scalars['String']>
  /** Index of event in block from which it was emitted. */
  indexInBlock: Scalars['Int']
  /** Network the block was produced in. */
  network: Network
  /** Why the video was deleted */
  rationale: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type ChannelDeletedByModeratorEventConnection = {
  __typename: 'ChannelDeletedByModeratorEventConnection'
  edges: Array<ChannelDeletedByModeratorEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ChannelDeletedByModeratorEventCreateInput = {
  actor: Scalars['JSONObject']
  channelId: Scalars['Float']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  rationale: Scalars['String']
}

export type ChannelDeletedByModeratorEventEdge = {
  __typename: 'ChannelDeletedByModeratorEventEdge'
  cursor: Scalars['String']
  node: ChannelDeletedByModeratorEvent
}

export enum ChannelDeletedByModeratorEventOrderByInput {
  ChannelIdAsc = 'channelId_ASC',
  ChannelIdDesc = 'channelId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ChannelDeletedByModeratorEventUpdateInput = {
  actor?: InputMaybe<Scalars['JSONObject']>
  channelId?: InputMaybe<Scalars['Float']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  rationale?: InputMaybe<Scalars['String']>
}

export type ChannelDeletedByModeratorEventWhereInput = {
  AND?: InputMaybe<Array<ChannelDeletedByModeratorEventWhereInput>>
  NOT?: InputMaybe<Array<ChannelDeletedByModeratorEventWhereInput>>
  OR?: InputMaybe<Array<ChannelDeletedByModeratorEventWhereInput>>
  actor_json?: InputMaybe<Scalars['JSONObject']>
  channelId_eq?: InputMaybe<Scalars['Int']>
  channelId_gt?: InputMaybe<Scalars['Int']>
  channelId_gte?: InputMaybe<Scalars['Int']>
  channelId_in?: InputMaybe<Array<Scalars['Int']>>
  channelId_lt?: InputMaybe<Scalars['Int']>
  channelId_lte?: InputMaybe<Scalars['Int']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ChannelDeletedByModeratorEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ChannelEdge = {
  __typename: 'ChannelEdge'
  cursor: Scalars['String']
  node: Channel
}

export type ChannelFundsWithdrawnEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ChannelFundsWithdrawnEvent'
    /** Destination account ID */
    account: Scalars['String']
    /** Content actor */
    actor: ContentActor
    /** Reward amount claimed */
    amount: Scalars['BigInt']
    channel: Channel
    channelId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in. */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ChannelFundsWithdrawnEventConnection = {
  __typename: 'ChannelFundsWithdrawnEventConnection'
  edges: Array<ChannelFundsWithdrawnEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ChannelFundsWithdrawnEventCreateInput = {
  account: Scalars['String']
  actor: Scalars['JSONObject']
  amount: Scalars['String']
  channel: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type ChannelFundsWithdrawnEventEdge = {
  __typename: 'ChannelFundsWithdrawnEventEdge'
  cursor: Scalars['String']
  node: ChannelFundsWithdrawnEvent
}

export enum ChannelFundsWithdrawnEventOrderByInput {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  ChannelAsc = 'channel_ASC',
  ChannelDesc = 'channel_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ChannelFundsWithdrawnEventUpdateInput = {
  account?: InputMaybe<Scalars['String']>
  actor?: InputMaybe<Scalars['JSONObject']>
  amount?: InputMaybe<Scalars['String']>
  channel?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type ChannelFundsWithdrawnEventWhereInput = {
  AND?: InputMaybe<Array<ChannelFundsWithdrawnEventWhereInput>>
  NOT?: InputMaybe<Array<ChannelFundsWithdrawnEventWhereInput>>
  OR?: InputMaybe<Array<ChannelFundsWithdrawnEventWhereInput>>
  account_contains?: InputMaybe<Scalars['String']>
  account_endsWith?: InputMaybe<Scalars['String']>
  account_eq?: InputMaybe<Scalars['String']>
  account_in?: InputMaybe<Array<Scalars['String']>>
  account_startsWith?: InputMaybe<Scalars['String']>
  actor_json?: InputMaybe<Scalars['JSONObject']>
  amount_eq?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  channel?: InputMaybe<ChannelWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ChannelFundsWithdrawnEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ChannelNftCollectors = BaseGraphQlObject & {
  __typename: 'ChannelNftCollectors'
  /** Amount of NFTs owned in the channel */
  amount: Scalars['Int']
  channel: Channel
  channelId: Scalars['String']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  curatorGroup?: Maybe<CuratorGroup>
  curatorGroupId?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Time of last NFT amount increase */
  lastIncreaseAt: Scalars['DateTime']
  member?: Maybe<Membership>
  memberId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type ChannelNftCollectorsConnection = {
  __typename: 'ChannelNftCollectorsConnection'
  edges: Array<ChannelNftCollectorsEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ChannelNftCollectorsCreateInput = {
  amount: Scalars['Float']
  channel: Scalars['ID']
  curatorGroup?: InputMaybe<Scalars['ID']>
  lastIncreaseAt: Scalars['DateTime']
  member?: InputMaybe<Scalars['ID']>
}

export type ChannelNftCollectorsEdge = {
  __typename: 'ChannelNftCollectorsEdge'
  cursor: Scalars['String']
  node: ChannelNftCollectors
}

export enum ChannelNftCollectorsOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  ChannelAsc = 'channel_ASC',
  ChannelDesc = 'channel_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CuratorGroupAsc = 'curatorGroup_ASC',
  CuratorGroupDesc = 'curatorGroup_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  LastIncreaseAtAsc = 'lastIncreaseAt_ASC',
  LastIncreaseAtDesc = 'lastIncreaseAt_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ChannelNftCollectorsUpdateInput = {
  amount?: InputMaybe<Scalars['Float']>
  channel?: InputMaybe<Scalars['ID']>
  curatorGroup?: InputMaybe<Scalars['ID']>
  lastIncreaseAt?: InputMaybe<Scalars['DateTime']>
  member?: InputMaybe<Scalars['ID']>
}

export type ChannelNftCollectorsWhereInput = {
  AND?: InputMaybe<Array<ChannelNftCollectorsWhereInput>>
  NOT?: InputMaybe<Array<ChannelNftCollectorsWhereInput>>
  OR?: InputMaybe<Array<ChannelNftCollectorsWhereInput>>
  amount_eq?: InputMaybe<Scalars['Int']>
  amount_gt?: InputMaybe<Scalars['Int']>
  amount_gte?: InputMaybe<Scalars['Int']>
  amount_in?: InputMaybe<Array<Scalars['Int']>>
  amount_lt?: InputMaybe<Scalars['Int']>
  amount_lte?: InputMaybe<Scalars['Int']>
  channel?: InputMaybe<ChannelWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  curatorGroup?: InputMaybe<CuratorGroupWhereInput>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  lastIncreaseAt_eq?: InputMaybe<Scalars['DateTime']>
  lastIncreaseAt_gt?: InputMaybe<Scalars['DateTime']>
  lastIncreaseAt_gte?: InputMaybe<Scalars['DateTime']>
  lastIncreaseAt_lt?: InputMaybe<Scalars['DateTime']>
  lastIncreaseAt_lte?: InputMaybe<Scalars['DateTime']>
  member?: InputMaybe<MembershipWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ChannelNftCollectorsWhereUniqueInput = {
  id: Scalars['ID']
}

export enum ChannelOrderByInput {
  ActiveVideosCounterAsc = 'activeVideosCounter_ASC',
  ActiveVideosCounterDesc = 'activeVideosCounter_DESC',
  AvatarPhotoAsc = 'avatarPhoto_ASC',
  AvatarPhotoDesc = 'avatarPhoto_DESC',
  ChannelStateBloatBondAsc = 'channelStateBloatBond_ASC',
  ChannelStateBloatBondDesc = 'channelStateBloatBond_DESC',
  CoverPhotoAsc = 'coverPhoto_ASC',
  CoverPhotoDesc = 'coverPhoto_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CreatedInBlockAsc = 'createdInBlock_ASC',
  CreatedInBlockDesc = 'createdInBlock_DESC',
  CumulativeRewardClaimedAsc = 'cumulativeRewardClaimed_ASC',
  CumulativeRewardClaimedDesc = 'cumulativeRewardClaimed_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsCensoredAsc = 'isCensored_ASC',
  IsCensoredDesc = 'isCensored_DESC',
  IsPublicAsc = 'isPublic_ASC',
  IsPublicDesc = 'isPublic_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  PrivilegeLevelAsc = 'privilegeLevel_ASC',
  PrivilegeLevelDesc = 'privilegeLevel_DESC',
  RewardAccountAsc = 'rewardAccount_ASC',
  RewardAccountDesc = 'rewardAccount_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Direct channel payment by any member by-passing the council payouts */
export type ChannelPaymentMadeEvent = BaseGraphQlObject & {
  __typename: 'ChannelPaymentMadeEvent'
  /** Amount of the payment */
  amount: Scalars['BigInt']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Blocknumber of the block in which the event was emitted. */
  inBlock: Scalars['Int']
  /** Hash of the extrinsic which caused the event to be emitted. */
  inExtrinsic?: Maybe<Scalars['String']>
  /** Index of event in block from which it was emitted. */
  indexInBlock: Scalars['Int']
  /** Network the block was produced in. */
  network: Network
  payeeChannel?: Maybe<Channel>
  payeeChannelId?: Maybe<Scalars['String']>
  payer: Membership
  payerId: Scalars['String']
  /** Payment and payee context */
  paymentContext?: Maybe<PaymentContext>
  /** Reason of the payment */
  rationale?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type ChannelPaymentMadeEventConnection = {
  __typename: 'ChannelPaymentMadeEventConnection'
  edges: Array<ChannelPaymentMadeEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ChannelPaymentMadeEventCreateInput = {
  amount: Scalars['String']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  payeeChannel?: InputMaybe<Scalars['ID']>
  payer: Scalars['ID']
  paymentContext: Scalars['JSONObject']
  rationale?: InputMaybe<Scalars['String']>
}

export type ChannelPaymentMadeEventEdge = {
  __typename: 'ChannelPaymentMadeEventEdge'
  cursor: Scalars['String']
  node: ChannelPaymentMadeEvent
}

export enum ChannelPaymentMadeEventOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PayeeChannelAsc = 'payeeChannel_ASC',
  PayeeChannelDesc = 'payeeChannel_DESC',
  PayerAsc = 'payer_ASC',
  PayerDesc = 'payer_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ChannelPaymentMadeEventUpdateInput = {
  amount?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  payeeChannel?: InputMaybe<Scalars['ID']>
  payer?: InputMaybe<Scalars['ID']>
  paymentContext?: InputMaybe<Scalars['JSONObject']>
  rationale?: InputMaybe<Scalars['String']>
}

export type ChannelPaymentMadeEventWhereInput = {
  AND?: InputMaybe<Array<ChannelPaymentMadeEventWhereInput>>
  NOT?: InputMaybe<Array<ChannelPaymentMadeEventWhereInput>>
  OR?: InputMaybe<Array<ChannelPaymentMadeEventWhereInput>>
  amount_eq?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  payeeChannel?: InputMaybe<ChannelWhereInput>
  payer?: InputMaybe<MembershipWhereInput>
  paymentContext_json?: InputMaybe<Scalars['JSONObject']>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ChannelPaymentMadeEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ChannelPayoutsUpdatedEvent = BaseGraphQlObject & {
  __typename: 'ChannelPayoutsUpdatedEvent'
  /** Can channel cashout the rewards */
  channelCashoutsEnabled?: Maybe<Scalars['Boolean']>
  /** Merkle root of the channel payouts */
  commitment?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Blocknumber of the block in which the event was emitted. */
  inBlock: Scalars['Int']
  /** Hash of the extrinsic which caused the event to be emitted. */
  inExtrinsic?: Maybe<Scalars['String']>
  /** Index of event in block from which it was emitted. */
  indexInBlock: Scalars['Int']
  /** Is the commitment valid. Most recent commitment would be considered valid */
  isCommitmentValid: Scalars['Boolean']
  /** Maximum amount of channel reward cashout allowed at a time */
  maxCashoutAllowed?: Maybe<Scalars['BigInt']>
  /** Minimum amount of channel reward cashout allowed at a time */
  minCashoutAllowed?: Maybe<Scalars['BigInt']>
  /** Network the block was produced in. */
  network: Network
  payloadDataObject: StorageDataObject
  payloadDataObjectId: Scalars['String']
  /** Hash of the serialized channel payouts payload */
  payloadHash?: Maybe<Scalars['String']>
  /** Size of the serialized channel payouts payload */
  payloadSize?: Maybe<Scalars['BigInt']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type ChannelPayoutsUpdatedEventConnection = {
  __typename: 'ChannelPayoutsUpdatedEventConnection'
  edges: Array<ChannelPayoutsUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ChannelPayoutsUpdatedEventCreateInput = {
  channelCashoutsEnabled?: InputMaybe<Scalars['Boolean']>
  commitment?: InputMaybe<Scalars['String']>
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  isCommitmentValid: Scalars['Boolean']
  maxCashoutAllowed?: InputMaybe<Scalars['String']>
  minCashoutAllowed?: InputMaybe<Scalars['String']>
  network: Network
  payloadDataObject: Scalars['ID']
  payloadHash?: InputMaybe<Scalars['String']>
  payloadSize?: InputMaybe<Scalars['String']>
}

export type ChannelPayoutsUpdatedEventEdge = {
  __typename: 'ChannelPayoutsUpdatedEventEdge'
  cursor: Scalars['String']
  node: ChannelPayoutsUpdatedEvent
}

export enum ChannelPayoutsUpdatedEventOrderByInput {
  ChannelCashoutsEnabledAsc = 'channelCashoutsEnabled_ASC',
  ChannelCashoutsEnabledDesc = 'channelCashoutsEnabled_DESC',
  CommitmentAsc = 'commitment_ASC',
  CommitmentDesc = 'commitment_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  IsCommitmentValidAsc = 'isCommitmentValid_ASC',
  IsCommitmentValidDesc = 'isCommitmentValid_DESC',
  MaxCashoutAllowedAsc = 'maxCashoutAllowed_ASC',
  MaxCashoutAllowedDesc = 'maxCashoutAllowed_DESC',
  MinCashoutAllowedAsc = 'minCashoutAllowed_ASC',
  MinCashoutAllowedDesc = 'minCashoutAllowed_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PayloadDataObjectAsc = 'payloadDataObject_ASC',
  PayloadDataObjectDesc = 'payloadDataObject_DESC',
  PayloadHashAsc = 'payloadHash_ASC',
  PayloadHashDesc = 'payloadHash_DESC',
  PayloadSizeAsc = 'payloadSize_ASC',
  PayloadSizeDesc = 'payloadSize_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ChannelPayoutsUpdatedEventUpdateInput = {
  channelCashoutsEnabled?: InputMaybe<Scalars['Boolean']>
  commitment?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  isCommitmentValid?: InputMaybe<Scalars['Boolean']>
  maxCashoutAllowed?: InputMaybe<Scalars['String']>
  minCashoutAllowed?: InputMaybe<Scalars['String']>
  network?: InputMaybe<Network>
  payloadDataObject?: InputMaybe<Scalars['ID']>
  payloadHash?: InputMaybe<Scalars['String']>
  payloadSize?: InputMaybe<Scalars['String']>
}

export type ChannelPayoutsUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<ChannelPayoutsUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<ChannelPayoutsUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<ChannelPayoutsUpdatedEventWhereInput>>
  channelCashoutsEnabled_eq?: InputMaybe<Scalars['Boolean']>
  channelCashoutsEnabled_in?: InputMaybe<Array<Scalars['Boolean']>>
  commitment_contains?: InputMaybe<Scalars['String']>
  commitment_endsWith?: InputMaybe<Scalars['String']>
  commitment_eq?: InputMaybe<Scalars['String']>
  commitment_in?: InputMaybe<Array<Scalars['String']>>
  commitment_startsWith?: InputMaybe<Scalars['String']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  isCommitmentValid_eq?: InputMaybe<Scalars['Boolean']>
  isCommitmentValid_in?: InputMaybe<Array<Scalars['Boolean']>>
  maxCashoutAllowed_eq?: InputMaybe<Scalars['BigInt']>
  maxCashoutAllowed_gt?: InputMaybe<Scalars['BigInt']>
  maxCashoutAllowed_gte?: InputMaybe<Scalars['BigInt']>
  maxCashoutAllowed_in?: InputMaybe<Array<Scalars['BigInt']>>
  maxCashoutAllowed_lt?: InputMaybe<Scalars['BigInt']>
  maxCashoutAllowed_lte?: InputMaybe<Scalars['BigInt']>
  minCashoutAllowed_eq?: InputMaybe<Scalars['BigInt']>
  minCashoutAllowed_gt?: InputMaybe<Scalars['BigInt']>
  minCashoutAllowed_gte?: InputMaybe<Scalars['BigInt']>
  minCashoutAllowed_in?: InputMaybe<Array<Scalars['BigInt']>>
  minCashoutAllowed_lt?: InputMaybe<Scalars['BigInt']>
  minCashoutAllowed_lte?: InputMaybe<Scalars['BigInt']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  payloadDataObject?: InputMaybe<StorageDataObjectWhereInput>
  payloadHash_contains?: InputMaybe<Scalars['String']>
  payloadHash_endsWith?: InputMaybe<Scalars['String']>
  payloadHash_eq?: InputMaybe<Scalars['String']>
  payloadHash_in?: InputMaybe<Array<Scalars['String']>>
  payloadHash_startsWith?: InputMaybe<Scalars['String']>
  payloadSize_eq?: InputMaybe<Scalars['BigInt']>
  payloadSize_gt?: InputMaybe<Scalars['BigInt']>
  payloadSize_gte?: InputMaybe<Scalars['BigInt']>
  payloadSize_in?: InputMaybe<Array<Scalars['BigInt']>>
  payloadSize_lt?: InputMaybe<Scalars['BigInt']>
  payloadSize_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ChannelPayoutsUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ChannelRewardClaimedAndWithdrawnEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ChannelRewardClaimedAndWithdrawnEvent'
    /** Destination account ID */
    account: Scalars['String']
    /** Content actor */
    actor: ContentActor
    /** Reward amount claimed */
    amount: Scalars['BigInt']
    channel: Channel
    channelId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in. */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ChannelRewardClaimedAndWithdrawnEventConnection = {
  __typename: 'ChannelRewardClaimedAndWithdrawnEventConnection'
  edges: Array<ChannelRewardClaimedAndWithdrawnEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ChannelRewardClaimedAndWithdrawnEventCreateInput = {
  account: Scalars['String']
  actor: Scalars['JSONObject']
  amount: Scalars['String']
  channel: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type ChannelRewardClaimedAndWithdrawnEventEdge = {
  __typename: 'ChannelRewardClaimedAndWithdrawnEventEdge'
  cursor: Scalars['String']
  node: ChannelRewardClaimedAndWithdrawnEvent
}

export enum ChannelRewardClaimedAndWithdrawnEventOrderByInput {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  ChannelAsc = 'channel_ASC',
  ChannelDesc = 'channel_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ChannelRewardClaimedAndWithdrawnEventUpdateInput = {
  account?: InputMaybe<Scalars['String']>
  actor?: InputMaybe<Scalars['JSONObject']>
  amount?: InputMaybe<Scalars['String']>
  channel?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type ChannelRewardClaimedAndWithdrawnEventWhereInput = {
  AND?: InputMaybe<Array<ChannelRewardClaimedAndWithdrawnEventWhereInput>>
  NOT?: InputMaybe<Array<ChannelRewardClaimedAndWithdrawnEventWhereInput>>
  OR?: InputMaybe<Array<ChannelRewardClaimedAndWithdrawnEventWhereInput>>
  account_contains?: InputMaybe<Scalars['String']>
  account_endsWith?: InputMaybe<Scalars['String']>
  account_eq?: InputMaybe<Scalars['String']>
  account_in?: InputMaybe<Array<Scalars['String']>>
  account_startsWith?: InputMaybe<Scalars['String']>
  actor_json?: InputMaybe<Scalars['JSONObject']>
  amount_eq?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  channel?: InputMaybe<ChannelWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ChannelRewardClaimedAndWithdrawnEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ChannelRewardClaimedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ChannelRewardClaimedEvent'
    /** Reward amount claimed */
    amount: Scalars['BigInt']
    channel: Channel
    channelId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in. */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ChannelRewardClaimedEventConnection = {
  __typename: 'ChannelRewardClaimedEventConnection'
  edges: Array<ChannelRewardClaimedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ChannelRewardClaimedEventCreateInput = {
  amount: Scalars['String']
  channel: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type ChannelRewardClaimedEventEdge = {
  __typename: 'ChannelRewardClaimedEventEdge'
  cursor: Scalars['String']
  node: ChannelRewardClaimedEvent
}

export enum ChannelRewardClaimedEventOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  ChannelAsc = 'channel_ASC',
  ChannelDesc = 'channel_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ChannelRewardClaimedEventUpdateInput = {
  amount?: InputMaybe<Scalars['String']>
  channel?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type ChannelRewardClaimedEventWhereInput = {
  AND?: InputMaybe<Array<ChannelRewardClaimedEventWhereInput>>
  NOT?: InputMaybe<Array<ChannelRewardClaimedEventWhereInput>>
  OR?: InputMaybe<Array<ChannelRewardClaimedEventWhereInput>>
  amount_eq?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  channel?: InputMaybe<ChannelWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ChannelRewardClaimedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ChannelUpdateInput = {
  activeVideosCounter?: InputMaybe<Scalars['Float']>
  avatarPhoto?: InputMaybe<Scalars['ID']>
  channelStateBloatBond?: InputMaybe<Scalars['String']>
  coverPhoto?: InputMaybe<Scalars['ID']>
  createdInBlock?: InputMaybe<Scalars['Float']>
  cumulativeRewardClaimed?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  isCensored?: InputMaybe<Scalars['Boolean']>
  isPublic?: InputMaybe<Scalars['Boolean']>
  language?: InputMaybe<Scalars['ID']>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  privilegeLevel?: InputMaybe<Scalars['Float']>
  rewardAccount?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type ChannelVisibilitySetByModeratorEvent = BaseGraphQlObject & {
  __typename: 'ChannelVisibilitySetByModeratorEvent'
  /** Actor that deleted the channel assets. */
  actor: ContentActor
  /** Channel whose visibility/censorship status is changed */
  channelId: Scalars['Int']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Blocknumber of the block in which the event was emitted. */
  inBlock: Scalars['Int']
  /** Hash of the extrinsic which caused the event to be emitted. */
  inExtrinsic?: Maybe<Scalars['String']>
  /** Index of event in block from which it was emitted. */
  indexInBlock: Scalars['Int']
  /** Is channel being censored/hidden (yes if true) */
  isHidden: Scalars['Boolean']
  /** Network the block was produced in. */
  network: Network
  /** Why channel's visibility status was set */
  rationale: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type ChannelVisibilitySetByModeratorEventConnection = {
  __typename: 'ChannelVisibilitySetByModeratorEventConnection'
  edges: Array<ChannelVisibilitySetByModeratorEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ChannelVisibilitySetByModeratorEventCreateInput = {
  actor: Scalars['JSONObject']
  channelId: Scalars['Float']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  isHidden: Scalars['Boolean']
  network: Network
  rationale: Scalars['String']
}

export type ChannelVisibilitySetByModeratorEventEdge = {
  __typename: 'ChannelVisibilitySetByModeratorEventEdge'
  cursor: Scalars['String']
  node: ChannelVisibilitySetByModeratorEvent
}

export enum ChannelVisibilitySetByModeratorEventOrderByInput {
  ChannelIdAsc = 'channelId_ASC',
  ChannelIdDesc = 'channelId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ChannelVisibilitySetByModeratorEventUpdateInput = {
  actor?: InputMaybe<Scalars['JSONObject']>
  channelId?: InputMaybe<Scalars['Float']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  isHidden?: InputMaybe<Scalars['Boolean']>
  network?: InputMaybe<Network>
  rationale?: InputMaybe<Scalars['String']>
}

export type ChannelVisibilitySetByModeratorEventWhereInput = {
  AND?: InputMaybe<Array<ChannelVisibilitySetByModeratorEventWhereInput>>
  NOT?: InputMaybe<Array<ChannelVisibilitySetByModeratorEventWhereInput>>
  OR?: InputMaybe<Array<ChannelVisibilitySetByModeratorEventWhereInput>>
  actor_json?: InputMaybe<Scalars['JSONObject']>
  channelId_eq?: InputMaybe<Scalars['Int']>
  channelId_gt?: InputMaybe<Scalars['Int']>
  channelId_gte?: InputMaybe<Scalars['Int']>
  channelId_in?: InputMaybe<Array<Scalars['Int']>>
  channelId_lt?: InputMaybe<Scalars['Int']>
  channelId_lte?: InputMaybe<Scalars['Int']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  isHidden_eq?: InputMaybe<Scalars['Boolean']>
  isHidden_in?: InputMaybe<Array<Scalars['Boolean']>>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ChannelVisibilitySetByModeratorEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ChannelWhereInput = {
  AND?: InputMaybe<Array<ChannelWhereInput>>
  NOT?: InputMaybe<Array<ChannelWhereInput>>
  OR?: InputMaybe<Array<ChannelWhereInput>>
  activeVideosCounter_eq?: InputMaybe<Scalars['Int']>
  activeVideosCounter_gt?: InputMaybe<Scalars['Int']>
  activeVideosCounter_gte?: InputMaybe<Scalars['Int']>
  activeVideosCounter_in?: InputMaybe<Array<Scalars['Int']>>
  activeVideosCounter_lt?: InputMaybe<Scalars['Int']>
  activeVideosCounter_lte?: InputMaybe<Scalars['Int']>
  avatarPhoto?: InputMaybe<StorageDataObjectWhereInput>
  bannedMembers_every?: InputMaybe<MembershipWhereInput>
  bannedMembers_none?: InputMaybe<MembershipWhereInput>
  bannedMembers_some?: InputMaybe<MembershipWhereInput>
  channelNftCollectors_every?: InputMaybe<ChannelNftCollectorsWhereInput>
  channelNftCollectors_none?: InputMaybe<ChannelNftCollectorsWhereInput>
  channelNftCollectors_some?: InputMaybe<ChannelNftCollectorsWhereInput>
  channelStateBloatBond_eq?: InputMaybe<Scalars['BigInt']>
  channelStateBloatBond_gt?: InputMaybe<Scalars['BigInt']>
  channelStateBloatBond_gte?: InputMaybe<Scalars['BigInt']>
  channelStateBloatBond_in?: InputMaybe<Array<Scalars['BigInt']>>
  channelStateBloatBond_lt?: InputMaybe<Scalars['BigInt']>
  channelStateBloatBond_lte?: InputMaybe<Scalars['BigInt']>
  channelfundswithdrawneventchannel_every?: InputMaybe<ChannelFundsWithdrawnEventWhereInput>
  channelfundswithdrawneventchannel_none?: InputMaybe<ChannelFundsWithdrawnEventWhereInput>
  channelfundswithdrawneventchannel_some?: InputMaybe<ChannelFundsWithdrawnEventWhereInput>
  channelpaymentmadeeventpayeeChannel_every?: InputMaybe<ChannelPaymentMadeEventWhereInput>
  channelpaymentmadeeventpayeeChannel_none?: InputMaybe<ChannelPaymentMadeEventWhereInput>
  channelpaymentmadeeventpayeeChannel_some?: InputMaybe<ChannelPaymentMadeEventWhereInput>
  channelrewardclaimedandwithdrawneventchannel_every?: InputMaybe<ChannelRewardClaimedAndWithdrawnEventWhereInput>
  channelrewardclaimedandwithdrawneventchannel_none?: InputMaybe<ChannelRewardClaimedAndWithdrawnEventWhereInput>
  channelrewardclaimedandwithdrawneventchannel_some?: InputMaybe<ChannelRewardClaimedAndWithdrawnEventWhereInput>
  claimedRewards_every?: InputMaybe<ChannelRewardClaimedEventWhereInput>
  claimedRewards_none?: InputMaybe<ChannelRewardClaimedEventWhereInput>
  claimedRewards_some?: InputMaybe<ChannelRewardClaimedEventWhereInput>
  collaborators_every?: InputMaybe<CollaboratorWhereInput>
  collaborators_none?: InputMaybe<CollaboratorWhereInput>
  collaborators_some?: InputMaybe<CollaboratorWhereInput>
  commentcreatedeventvideoChannel_every?: InputMaybe<CommentCreatedEventWhereInput>
  commentcreatedeventvideoChannel_none?: InputMaybe<CommentCreatedEventWhereInput>
  commentcreatedeventvideoChannel_some?: InputMaybe<CommentCreatedEventWhereInput>
  commentdeletedeventvideoChannel_every?: InputMaybe<CommentDeletedEventWhereInput>
  commentdeletedeventvideoChannel_none?: InputMaybe<CommentDeletedEventWhereInput>
  commentdeletedeventvideoChannel_some?: InputMaybe<CommentDeletedEventWhereInput>
  commentmoderatedeventvideoChannel_every?: InputMaybe<CommentModeratedEventWhereInput>
  commentmoderatedeventvideoChannel_none?: InputMaybe<CommentModeratedEventWhereInput>
  commentmoderatedeventvideoChannel_some?: InputMaybe<CommentModeratedEventWhereInput>
  commentpinnedeventvideoChannel_every?: InputMaybe<CommentPinnedEventWhereInput>
  commentpinnedeventvideoChannel_none?: InputMaybe<CommentPinnedEventWhereInput>
  commentpinnedeventvideoChannel_some?: InputMaybe<CommentPinnedEventWhereInput>
  commentreactedeventvideoChannel_every?: InputMaybe<CommentReactedEventWhereInput>
  commentreactedeventvideoChannel_none?: InputMaybe<CommentReactedEventWhereInput>
  commentreactedeventvideoChannel_some?: InputMaybe<CommentReactedEventWhereInput>
  commenttextupdatedeventvideoChannel_every?: InputMaybe<CommentTextUpdatedEventWhereInput>
  commenttextupdatedeventvideoChannel_none?: InputMaybe<CommentTextUpdatedEventWhereInput>
  commenttextupdatedeventvideoChannel_some?: InputMaybe<CommentTextUpdatedEventWhereInput>
  coverPhoto?: InputMaybe<StorageDataObjectWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  createdInBlock_eq?: InputMaybe<Scalars['Int']>
  createdInBlock_gt?: InputMaybe<Scalars['Int']>
  createdInBlock_gte?: InputMaybe<Scalars['Int']>
  createdInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  createdInBlock_lt?: InputMaybe<Scalars['Int']>
  createdInBlock_lte?: InputMaybe<Scalars['Int']>
  cumulativeRewardClaimed_eq?: InputMaybe<Scalars['BigInt']>
  cumulativeRewardClaimed_gt?: InputMaybe<Scalars['BigInt']>
  cumulativeRewardClaimed_gte?: InputMaybe<Scalars['BigInt']>
  cumulativeRewardClaimed_in?: InputMaybe<Array<Scalars['BigInt']>>
  cumulativeRewardClaimed_lt?: InputMaybe<Scalars['BigInt']>
  cumulativeRewardClaimed_lte?: InputMaybe<Scalars['BigInt']>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  description_contains?: InputMaybe<Scalars['String']>
  description_endsWith?: InputMaybe<Scalars['String']>
  description_eq?: InputMaybe<Scalars['String']>
  description_in?: InputMaybe<Array<Scalars['String']>>
  description_startsWith?: InputMaybe<Scalars['String']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  isCensored_eq?: InputMaybe<Scalars['Boolean']>
  isCensored_in?: InputMaybe<Array<Scalars['Boolean']>>
  isPublic_eq?: InputMaybe<Scalars['Boolean']>
  isPublic_in?: InputMaybe<Array<Scalars['Boolean']>>
  language?: InputMaybe<LanguageWhereInput>
  memberbannedfromchanneleventchannel_every?: InputMaybe<MemberBannedFromChannelEventWhereInput>
  memberbannedfromchanneleventchannel_none?: InputMaybe<MemberBannedFromChannelEventWhereInput>
  memberbannedfromchanneleventchannel_some?: InputMaybe<MemberBannedFromChannelEventWhereInput>
  ownednftcreatorChannel_every?: InputMaybe<OwnedNftWhereInput>
  ownednftcreatorChannel_none?: InputMaybe<OwnedNftWhereInput>
  ownednftcreatorChannel_some?: InputMaybe<OwnedNftWhereInput>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  privilegeLevel_eq?: InputMaybe<Scalars['Int']>
  privilegeLevel_gt?: InputMaybe<Scalars['Int']>
  privilegeLevel_gte?: InputMaybe<Scalars['Int']>
  privilegeLevel_in?: InputMaybe<Array<Scalars['Int']>>
  privilegeLevel_lt?: InputMaybe<Scalars['Int']>
  privilegeLevel_lte?: InputMaybe<Scalars['Int']>
  rewardAccount_contains?: InputMaybe<Scalars['String']>
  rewardAccount_endsWith?: InputMaybe<Scalars['String']>
  rewardAccount_eq?: InputMaybe<Scalars['String']>
  rewardAccount_in?: InputMaybe<Array<Scalars['String']>>
  rewardAccount_startsWith?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_endsWith?: InputMaybe<Scalars['String']>
  title_eq?: InputMaybe<Scalars['String']>
  title_in?: InputMaybe<Array<Scalars['String']>>
  title_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  videoreactedeventvideoChannel_every?: InputMaybe<VideoReactedEventWhereInput>
  videoreactedeventvideoChannel_none?: InputMaybe<VideoReactedEventWhereInput>
  videoreactedeventvideoChannel_some?: InputMaybe<VideoReactedEventWhereInput>
  videos_every?: InputMaybe<VideoWhereInput>
  videos_none?: InputMaybe<VideoWhereInput>
  videos_some?: InputMaybe<VideoWhereInput>
}

export type ChannelWhereUniqueInput = {
  id: Scalars['ID']
}

export type Collaborator = BaseGraphQlObject & {
  __typename: 'Collaborator'
  channel: Channel
  channelId: Scalars['String']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  member: Membership
  memberId: Scalars['String']
  /** List of member's permissions */
  permissions: Array<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type CollaboratorConnection = {
  __typename: 'CollaboratorConnection'
  edges: Array<CollaboratorEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CollaboratorCreateInput = {
  channel: Scalars['ID']
  member: Scalars['ID']
  permissions: Array<Scalars['String']>
}

export type CollaboratorEdge = {
  __typename: 'CollaboratorEdge'
  cursor: Scalars['String']
  node: Collaborator
}

export enum CollaboratorOrderByInput {
  ChannelAsc = 'channel_ASC',
  ChannelDesc = 'channel_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CollaboratorUpdateInput = {
  channel?: InputMaybe<Scalars['ID']>
  member?: InputMaybe<Scalars['ID']>
  permissions?: InputMaybe<Array<Scalars['String']>>
}

export type CollaboratorWhereInput = {
  AND?: InputMaybe<Array<CollaboratorWhereInput>>
  NOT?: InputMaybe<Array<CollaboratorWhereInput>>
  OR?: InputMaybe<Array<CollaboratorWhereInput>>
  channel?: InputMaybe<ChannelWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  member?: InputMaybe<MembershipWhereInput>
  permissions_containsAll?: InputMaybe<Array<Scalars['String']>>
  permissions_containsAny?: InputMaybe<Array<Scalars['String']>>
  permissions_containsNone?: InputMaybe<Array<Scalars['String']>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CollaboratorWhereUniqueInput = {
  id: Scalars['ID']
}

export type Comment = BaseGraphQlObject & {
  __typename: 'Comment'
  author: Membership
  authorId: Scalars['String']
  commentcreatedeventcomment?: Maybe<Array<CommentCreatedEvent>>
  commentparentComment?: Maybe<Array<Comment>>
  commentpinnedeventcomment?: Maybe<Array<CommentPinnedEvent>>
  commentreactedeventcomment?: Maybe<Array<CommentReactedEvent>>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  deletedInEvent?: Maybe<CommentDeletedEvent>
  edits: Array<CommentTextUpdatedEvent>
  id: Scalars['ID']
  /** Whether comment has been edited or not */
  isEdited: Scalars['Boolean']
  moderatedInEvent?: Maybe<CommentModeratedEvent>
  parentComment?: Maybe<Comment>
  parentCommentId?: Maybe<Scalars['String']>
  reactions: Array<CommentReaction>
  /** Sum of replies and reactions */
  reactionsAndRepliesCount: Scalars['Int']
  /** Total number of reactions to this comment */
  reactionsCount: Scalars['Int']
  reactionsCountByReactionId: Array<CommentReactionsCountByReactionId>
  /** How many comments has replied to this comment */
  repliesCount: Scalars['Int']
  /** Status of the comment; either it is visible, deleted, or moderated (deleted by moderator) */
  status: CommentStatus
  /** Comment text */
  text: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  video: Video
  videoId: Scalars['String']
}

export type CommentConnection = {
  __typename: 'CommentConnection'
  edges: Array<CommentEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CommentCreateInput = {
  author: Scalars['ID']
  isEdited: Scalars['Boolean']
  parentComment?: InputMaybe<Scalars['ID']>
  reactionsAndRepliesCount: Scalars['Float']
  reactionsCount: Scalars['Float']
  repliesCount: Scalars['Float']
  status: CommentStatus
  text: Scalars['String']
  video: Scalars['ID']
}

export type CommentCreatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CommentCreatedEvent'
    comment: Comment
    commentId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    parentCommentAuthor?: Maybe<Membership>
    parentCommentAuthorId?: Maybe<Scalars['String']>
    /** Comment's original text */
    text: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoChannel: Channel
    videoChannelId: Scalars['String']
    videoId: Scalars['String']
  }

export type CommentCreatedEventConnection = {
  __typename: 'CommentCreatedEventConnection'
  edges: Array<CommentCreatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CommentCreatedEventCreateInput = {
  comment: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  parentCommentAuthor?: InputMaybe<Scalars['ID']>
  text: Scalars['String']
  video: Scalars['ID']
  videoChannel: Scalars['ID']
}

export type CommentCreatedEventEdge = {
  __typename: 'CommentCreatedEventEdge'
  cursor: Scalars['String']
  node: CommentCreatedEvent
}

export enum CommentCreatedEventOrderByInput {
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ParentCommentAuthorAsc = 'parentCommentAuthor_ASC',
  ParentCommentAuthorDesc = 'parentCommentAuthor_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoChannelAsc = 'videoChannel_ASC',
  VideoChannelDesc = 'videoChannel_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type CommentCreatedEventUpdateInput = {
  comment?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  parentCommentAuthor?: InputMaybe<Scalars['ID']>
  text?: InputMaybe<Scalars['String']>
  video?: InputMaybe<Scalars['ID']>
  videoChannel?: InputMaybe<Scalars['ID']>
}

export type CommentCreatedEventWhereInput = {
  AND?: InputMaybe<Array<CommentCreatedEventWhereInput>>
  NOT?: InputMaybe<Array<CommentCreatedEventWhereInput>>
  OR?: InputMaybe<Array<CommentCreatedEventWhereInput>>
  comment?: InputMaybe<CommentWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  parentCommentAuthor?: InputMaybe<MembershipWhereInput>
  text_contains?: InputMaybe<Scalars['String']>
  text_endsWith?: InputMaybe<Scalars['String']>
  text_eq?: InputMaybe<Scalars['String']>
  text_in?: InputMaybe<Array<Scalars['String']>>
  text_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
  videoChannel?: InputMaybe<ChannelWhereInput>
}

export type CommentCreatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type CommentDeletedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CommentDeletedEvent'
    comment: Comment
    commentId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoChannel: Channel
    videoChannelId: Scalars['String']
    videoId: Scalars['String']
  }

export type CommentDeletedEventConnection = {
  __typename: 'CommentDeletedEventConnection'
  edges: Array<CommentDeletedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CommentDeletedEventCreateInput = {
  comment: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  video: Scalars['ID']
  videoChannel: Scalars['ID']
}

export type CommentDeletedEventEdge = {
  __typename: 'CommentDeletedEventEdge'
  cursor: Scalars['String']
  node: CommentDeletedEvent
}

export enum CommentDeletedEventOrderByInput {
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoChannelAsc = 'videoChannel_ASC',
  VideoChannelDesc = 'videoChannel_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type CommentDeletedEventUpdateInput = {
  comment?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  video?: InputMaybe<Scalars['ID']>
  videoChannel?: InputMaybe<Scalars['ID']>
}

export type CommentDeletedEventWhereInput = {
  AND?: InputMaybe<Array<CommentDeletedEventWhereInput>>
  NOT?: InputMaybe<Array<CommentDeletedEventWhereInput>>
  OR?: InputMaybe<Array<CommentDeletedEventWhereInput>>
  comment?: InputMaybe<CommentWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
  videoChannel?: InputMaybe<ChannelWhereInput>
}

export type CommentDeletedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type CommentEdge = {
  __typename: 'CommentEdge'
  cursor: Scalars['String']
  node: Comment
}

export type CommentModeratedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CommentModeratedEvent'
    /** The actor responsible for the removal of comment */
    actor: ContentActor
    comment: Comment
    commentId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Comment deletion rationale */
    rationale: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoChannel: Channel
    videoChannelId: Scalars['String']
    videoId: Scalars['String']
  }

export type CommentModeratedEventConnection = {
  __typename: 'CommentModeratedEventConnection'
  edges: Array<CommentModeratedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CommentModeratedEventCreateInput = {
  actor: Scalars['JSONObject']
  comment: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  rationale: Scalars['String']
  video: Scalars['ID']
  videoChannel: Scalars['ID']
}

export type CommentModeratedEventEdge = {
  __typename: 'CommentModeratedEventEdge'
  cursor: Scalars['String']
  node: CommentModeratedEvent
}

export enum CommentModeratedEventOrderByInput {
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoChannelAsc = 'videoChannel_ASC',
  VideoChannelDesc = 'videoChannel_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type CommentModeratedEventUpdateInput = {
  actor?: InputMaybe<Scalars['JSONObject']>
  comment?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  rationale?: InputMaybe<Scalars['String']>
  video?: InputMaybe<Scalars['ID']>
  videoChannel?: InputMaybe<Scalars['ID']>
}

export type CommentModeratedEventWhereInput = {
  AND?: InputMaybe<Array<CommentModeratedEventWhereInput>>
  NOT?: InputMaybe<Array<CommentModeratedEventWhereInput>>
  OR?: InputMaybe<Array<CommentModeratedEventWhereInput>>
  actor_json?: InputMaybe<Scalars['JSONObject']>
  comment?: InputMaybe<CommentWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
  videoChannel?: InputMaybe<ChannelWhereInput>
}

export type CommentModeratedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export enum CommentOrderByInput {
  AuthorAsc = 'author_ASC',
  AuthorDesc = 'author_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IsEditedAsc = 'isEdited_ASC',
  IsEditedDesc = 'isEdited_DESC',
  ParentCommentAsc = 'parentComment_ASC',
  ParentCommentDesc = 'parentComment_DESC',
  ReactionsAndRepliesCountAsc = 'reactionsAndRepliesCount_ASC',
  ReactionsAndRepliesCountDesc = 'reactionsAndRepliesCount_DESC',
  ReactionsCountAsc = 'reactionsCount_ASC',
  ReactionsCountDesc = 'reactionsCount_DESC',
  RepliesCountAsc = 'repliesCount_ASC',
  RepliesCountDesc = 'repliesCount_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type CommentPinnedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CommentPinnedEvent'
    /** The action performed. true if comment is pinned, otherwise false. */
    action: Scalars['Boolean']
    comment: Comment
    commentId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoChannel: Channel
    videoChannelId: Scalars['String']
    videoId: Scalars['String']
  }

export type CommentPinnedEventConnection = {
  __typename: 'CommentPinnedEventConnection'
  edges: Array<CommentPinnedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CommentPinnedEventCreateInput = {
  action: Scalars['Boolean']
  comment: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  video: Scalars['ID']
  videoChannel: Scalars['ID']
}

export type CommentPinnedEventEdge = {
  __typename: 'CommentPinnedEventEdge'
  cursor: Scalars['String']
  node: CommentPinnedEvent
}

export enum CommentPinnedEventOrderByInput {
  ActionAsc = 'action_ASC',
  ActionDesc = 'action_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoChannelAsc = 'videoChannel_ASC',
  VideoChannelDesc = 'videoChannel_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type CommentPinnedEventUpdateInput = {
  action?: InputMaybe<Scalars['Boolean']>
  comment?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  video?: InputMaybe<Scalars['ID']>
  videoChannel?: InputMaybe<Scalars['ID']>
}

export type CommentPinnedEventWhereInput = {
  AND?: InputMaybe<Array<CommentPinnedEventWhereInput>>
  NOT?: InputMaybe<Array<CommentPinnedEventWhereInput>>
  OR?: InputMaybe<Array<CommentPinnedEventWhereInput>>
  action_eq?: InputMaybe<Scalars['Boolean']>
  action_in?: InputMaybe<Array<Scalars['Boolean']>>
  comment?: InputMaybe<CommentWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
  videoChannel?: InputMaybe<ChannelWhereInput>
}

export type CommentPinnedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type CommentReactedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CommentReactedEvent'
    comment: Comment
    commentId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    reactingMember: Membership
    reactingMemberId: Scalars['String']
    /** The reaction id */
    reactionResult: Scalars['Int']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoChannel: Channel
    videoChannelId: Scalars['String']
    videoId: Scalars['String']
  }

export type CommentReactedEventConnection = {
  __typename: 'CommentReactedEventConnection'
  edges: Array<CommentReactedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CommentReactedEventCreateInput = {
  comment: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  reactingMember: Scalars['ID']
  reactionResult: Scalars['Float']
  video: Scalars['ID']
  videoChannel: Scalars['ID']
}

export type CommentReactedEventEdge = {
  __typename: 'CommentReactedEventEdge'
  cursor: Scalars['String']
  node: CommentReactedEvent
}

export enum CommentReactedEventOrderByInput {
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ReactingMemberAsc = 'reactingMember_ASC',
  ReactingMemberDesc = 'reactingMember_DESC',
  ReactionResultAsc = 'reactionResult_ASC',
  ReactionResultDesc = 'reactionResult_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoChannelAsc = 'videoChannel_ASC',
  VideoChannelDesc = 'videoChannel_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type CommentReactedEventUpdateInput = {
  comment?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  reactingMember?: InputMaybe<Scalars['ID']>
  reactionResult?: InputMaybe<Scalars['Float']>
  video?: InputMaybe<Scalars['ID']>
  videoChannel?: InputMaybe<Scalars['ID']>
}

export type CommentReactedEventWhereInput = {
  AND?: InputMaybe<Array<CommentReactedEventWhereInput>>
  NOT?: InputMaybe<Array<CommentReactedEventWhereInput>>
  OR?: InputMaybe<Array<CommentReactedEventWhereInput>>
  comment?: InputMaybe<CommentWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  reactingMember?: InputMaybe<MembershipWhereInput>
  reactionResult_eq?: InputMaybe<Scalars['Int']>
  reactionResult_gt?: InputMaybe<Scalars['Int']>
  reactionResult_gte?: InputMaybe<Scalars['Int']>
  reactionResult_in?: InputMaybe<Array<Scalars['Int']>>
  reactionResult_lt?: InputMaybe<Scalars['Int']>
  reactionResult_lte?: InputMaybe<Scalars['Int']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
  videoChannel?: InputMaybe<ChannelWhereInput>
}

export type CommentReactedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type CommentReaction = BaseGraphQlObject & {
  __typename: 'CommentReaction'
  comment: Comment
  commentId: Scalars['String']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  member: Membership
  /** ID of the member that reacted */
  memberId: Scalars['String']
  /** The Reaction id */
  reactionId: Scalars['Int']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  video: Video
  videoId: Scalars['String']
}

export type CommentReactionConnection = {
  __typename: 'CommentReactionConnection'
  edges: Array<CommentReactionEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CommentReactionCreateInput = {
  comment: Scalars['ID']
  member: Scalars['ID']
  memberId: Scalars['String']
  reactionId: Scalars['Float']
  video: Scalars['ID']
}

export type CommentReactionEdge = {
  __typename: 'CommentReactionEdge'
  cursor: Scalars['String']
  node: CommentReaction
}

export enum CommentReactionOrderByInput {
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  MemberIdAsc = 'memberId_ASC',
  MemberIdDesc = 'memberId_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  ReactionIdAsc = 'reactionId_ASC',
  ReactionIdDesc = 'reactionId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type CommentReactionUpdateInput = {
  comment?: InputMaybe<Scalars['ID']>
  member?: InputMaybe<Scalars['ID']>
  memberId?: InputMaybe<Scalars['String']>
  reactionId?: InputMaybe<Scalars['Float']>
  video?: InputMaybe<Scalars['ID']>
}

export type CommentReactionWhereInput = {
  AND?: InputMaybe<Array<CommentReactionWhereInput>>
  NOT?: InputMaybe<Array<CommentReactionWhereInput>>
  OR?: InputMaybe<Array<CommentReactionWhereInput>>
  comment?: InputMaybe<CommentWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  member?: InputMaybe<MembershipWhereInput>
  memberId_contains?: InputMaybe<Scalars['String']>
  memberId_endsWith?: InputMaybe<Scalars['String']>
  memberId_eq?: InputMaybe<Scalars['String']>
  memberId_in?: InputMaybe<Array<Scalars['String']>>
  memberId_startsWith?: InputMaybe<Scalars['String']>
  reactionId_eq?: InputMaybe<Scalars['Int']>
  reactionId_gt?: InputMaybe<Scalars['Int']>
  reactionId_gte?: InputMaybe<Scalars['Int']>
  reactionId_in?: InputMaybe<Array<Scalars['Int']>>
  reactionId_lt?: InputMaybe<Scalars['Int']>
  reactionId_lte?: InputMaybe<Scalars['Int']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type CommentReactionWhereUniqueInput = {
  id: Scalars['ID']
}

export type CommentReactionsCountByReactionId = BaseGraphQlObject & {
  __typename: 'CommentReactionsCountByReactionId'
  comment: Comment
  commentId: Scalars['String']
  /** No of times the comment has been reacted with given reaction Id */
  count: Scalars['Int']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** The reaction id */
  reactionId: Scalars['Int']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  video: Video
  videoId: Scalars['String']
}

export type CommentReactionsCountByReactionIdConnection = {
  __typename: 'CommentReactionsCountByReactionIdConnection'
  edges: Array<CommentReactionsCountByReactionIdEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CommentReactionsCountByReactionIdCreateInput = {
  comment: Scalars['ID']
  count: Scalars['Float']
  reactionId: Scalars['Float']
  video: Scalars['ID']
}

export type CommentReactionsCountByReactionIdEdge = {
  __typename: 'CommentReactionsCountByReactionIdEdge'
  cursor: Scalars['String']
  node: CommentReactionsCountByReactionId
}

export enum CommentReactionsCountByReactionIdOrderByInput {
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  CountAsc = 'count_ASC',
  CountDesc = 'count_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ReactionIdAsc = 'reactionId_ASC',
  ReactionIdDesc = 'reactionId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type CommentReactionsCountByReactionIdUpdateInput = {
  comment?: InputMaybe<Scalars['ID']>
  count?: InputMaybe<Scalars['Float']>
  reactionId?: InputMaybe<Scalars['Float']>
  video?: InputMaybe<Scalars['ID']>
}

export type CommentReactionsCountByReactionIdWhereInput = {
  AND?: InputMaybe<Array<CommentReactionsCountByReactionIdWhereInput>>
  NOT?: InputMaybe<Array<CommentReactionsCountByReactionIdWhereInput>>
  OR?: InputMaybe<Array<CommentReactionsCountByReactionIdWhereInput>>
  comment?: InputMaybe<CommentWhereInput>
  count_eq?: InputMaybe<Scalars['Int']>
  count_gt?: InputMaybe<Scalars['Int']>
  count_gte?: InputMaybe<Scalars['Int']>
  count_in?: InputMaybe<Array<Scalars['Int']>>
  count_lt?: InputMaybe<Scalars['Int']>
  count_lte?: InputMaybe<Scalars['Int']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  reactionId_eq?: InputMaybe<Scalars['Int']>
  reactionId_gt?: InputMaybe<Scalars['Int']>
  reactionId_gte?: InputMaybe<Scalars['Int']>
  reactionId_in?: InputMaybe<Array<Scalars['Int']>>
  reactionId_lt?: InputMaybe<Scalars['Int']>
  reactionId_lte?: InputMaybe<Scalars['Int']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type CommentReactionsCountByReactionIdWhereUniqueInput = {
  id: Scalars['ID']
}

export enum CommentStatus {
  Deleted = 'DELETED',
  Moderated = 'MODERATED',
  Visible = 'VISIBLE',
}

export type CommentTextFtsOutput = {
  __typename: 'CommentTextFTSOutput'
  highlight: Scalars['String']
  isTypeOf: Scalars['String']
  item: CommentTextSearchResult
  rank: Scalars['Float']
}

export type CommentTextSearchResult = Comment

export type CommentTextUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CommentTextUpdatedEvent'
    comment: Comment
    commentId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** New comment text */
    newText: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoChannel: Channel
    videoChannelId: Scalars['String']
    videoId: Scalars['String']
  }

export type CommentTextUpdatedEventConnection = {
  __typename: 'CommentTextUpdatedEventConnection'
  edges: Array<CommentTextUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CommentTextUpdatedEventCreateInput = {
  comment: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newText: Scalars['String']
  video: Scalars['ID']
  videoChannel: Scalars['ID']
}

export type CommentTextUpdatedEventEdge = {
  __typename: 'CommentTextUpdatedEventEdge'
  cursor: Scalars['String']
  node: CommentTextUpdatedEvent
}

export enum CommentTextUpdatedEventOrderByInput {
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewTextAsc = 'newText_ASC',
  NewTextDesc = 'newText_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoChannelAsc = 'videoChannel_ASC',
  VideoChannelDesc = 'videoChannel_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type CommentTextUpdatedEventUpdateInput = {
  comment?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newText?: InputMaybe<Scalars['String']>
  video?: InputMaybe<Scalars['ID']>
  videoChannel?: InputMaybe<Scalars['ID']>
}

export type CommentTextUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<CommentTextUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<CommentTextUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<CommentTextUpdatedEventWhereInput>>
  comment?: InputMaybe<CommentWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newText_contains?: InputMaybe<Scalars['String']>
  newText_endsWith?: InputMaybe<Scalars['String']>
  newText_eq?: InputMaybe<Scalars['String']>
  newText_in?: InputMaybe<Array<Scalars['String']>>
  newText_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
  videoChannel?: InputMaybe<ChannelWhereInput>
}

export type CommentTextUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type CommentUpdateInput = {
  author?: InputMaybe<Scalars['ID']>
  isEdited?: InputMaybe<Scalars['Boolean']>
  parentComment?: InputMaybe<Scalars['ID']>
  reactionsAndRepliesCount?: InputMaybe<Scalars['Float']>
  reactionsCount?: InputMaybe<Scalars['Float']>
  repliesCount?: InputMaybe<Scalars['Float']>
  status?: InputMaybe<CommentStatus>
  text?: InputMaybe<Scalars['String']>
  video?: InputMaybe<Scalars['ID']>
}

export type CommentWhereInput = {
  AND?: InputMaybe<Array<CommentWhereInput>>
  NOT?: InputMaybe<Array<CommentWhereInput>>
  OR?: InputMaybe<Array<CommentWhereInput>>
  author?: InputMaybe<MembershipWhereInput>
  commentcreatedeventcomment_every?: InputMaybe<CommentCreatedEventWhereInput>
  commentcreatedeventcomment_none?: InputMaybe<CommentCreatedEventWhereInput>
  commentcreatedeventcomment_some?: InputMaybe<CommentCreatedEventWhereInput>
  commentparentComment_every?: InputMaybe<CommentWhereInput>
  commentparentComment_none?: InputMaybe<CommentWhereInput>
  commentparentComment_some?: InputMaybe<CommentWhereInput>
  commentpinnedeventcomment_every?: InputMaybe<CommentPinnedEventWhereInput>
  commentpinnedeventcomment_none?: InputMaybe<CommentPinnedEventWhereInput>
  commentpinnedeventcomment_some?: InputMaybe<CommentPinnedEventWhereInput>
  commentreactedeventcomment_every?: InputMaybe<CommentReactedEventWhereInput>
  commentreactedeventcomment_none?: InputMaybe<CommentReactedEventWhereInput>
  commentreactedeventcomment_some?: InputMaybe<CommentReactedEventWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedInEvent?: InputMaybe<CommentDeletedEventWhereInput>
  edits_every?: InputMaybe<CommentTextUpdatedEventWhereInput>
  edits_none?: InputMaybe<CommentTextUpdatedEventWhereInput>
  edits_some?: InputMaybe<CommentTextUpdatedEventWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  isEdited_eq?: InputMaybe<Scalars['Boolean']>
  isEdited_in?: InputMaybe<Array<Scalars['Boolean']>>
  moderatedInEvent?: InputMaybe<CommentModeratedEventWhereInput>
  parentComment?: InputMaybe<CommentWhereInput>
  reactionsAndRepliesCount_eq?: InputMaybe<Scalars['Int']>
  reactionsAndRepliesCount_gt?: InputMaybe<Scalars['Int']>
  reactionsAndRepliesCount_gte?: InputMaybe<Scalars['Int']>
  reactionsAndRepliesCount_in?: InputMaybe<Array<Scalars['Int']>>
  reactionsAndRepliesCount_lt?: InputMaybe<Scalars['Int']>
  reactionsAndRepliesCount_lte?: InputMaybe<Scalars['Int']>
  reactionsCountByReactionId_every?: InputMaybe<CommentReactionsCountByReactionIdWhereInput>
  reactionsCountByReactionId_none?: InputMaybe<CommentReactionsCountByReactionIdWhereInput>
  reactionsCountByReactionId_some?: InputMaybe<CommentReactionsCountByReactionIdWhereInput>
  reactionsCount_eq?: InputMaybe<Scalars['Int']>
  reactionsCount_gt?: InputMaybe<Scalars['Int']>
  reactionsCount_gte?: InputMaybe<Scalars['Int']>
  reactionsCount_in?: InputMaybe<Array<Scalars['Int']>>
  reactionsCount_lt?: InputMaybe<Scalars['Int']>
  reactionsCount_lte?: InputMaybe<Scalars['Int']>
  reactions_every?: InputMaybe<CommentReactionWhereInput>
  reactions_none?: InputMaybe<CommentReactionWhereInput>
  reactions_some?: InputMaybe<CommentReactionWhereInput>
  repliesCount_eq?: InputMaybe<Scalars['Int']>
  repliesCount_gt?: InputMaybe<Scalars['Int']>
  repliesCount_gte?: InputMaybe<Scalars['Int']>
  repliesCount_in?: InputMaybe<Array<Scalars['Int']>>
  repliesCount_lt?: InputMaybe<Scalars['Int']>
  repliesCount_lte?: InputMaybe<Scalars['Int']>
  status_eq?: InputMaybe<CommentStatus>
  status_in?: InputMaybe<Array<CommentStatus>>
  text_contains?: InputMaybe<Scalars['String']>
  text_endsWith?: InputMaybe<Scalars['String']>
  text_eq?: InputMaybe<Scalars['String']>
  text_in?: InputMaybe<Array<Scalars['String']>>
  text_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type CommentWhereUniqueInput = {
  id: Scalars['ID']
}

export type ContentActor = ContentActorCurator | ContentActorLead | ContentActorMember

export type ContentActorCurator = {
  __typename: 'ContentActorCurator'
  curator?: Maybe<Curator>
  /** Type needs to have at least one non-relation entity. This value is not used. */
  dummy?: Maybe<Scalars['Int']>
}

export type ContentActorLead = {
  __typename: 'ContentActorLead'
  /** Type needs to have at least one non-relation entity. This value is not used. */
  dummy?: Maybe<Scalars['Int']>
}

export type ContentActorMember = {
  __typename: 'ContentActorMember'
  /** Type needs to have at least one non-relation entity. This value is not used. */
  dummy?: Maybe<Scalars['Int']>
  member?: Maybe<Membership>
}

export enum Continent {
  Af = 'AF',
  An = 'AN',
  As = 'AS',
  Eu = 'EU',
  Na = 'NA',
  Oc = 'OC',
  Sa = 'SA',
}

export type CouncilBudgetFundedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CouncilBudgetFundedEvent'
    /** Funding amount. */
    amount: Scalars['BigInt']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Funder member. */
    memberId: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Funding rationale */
    rationale: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type CouncilBudgetFundedEventConnection = {
  __typename: 'CouncilBudgetFundedEventConnection'
  edges: Array<CouncilBudgetFundedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CouncilBudgetFundedEventCreateInput = {
  amount: Scalars['String']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  memberId: Scalars['Float']
  network: Network
  rationale: Scalars['String']
}

export type CouncilBudgetFundedEventEdge = {
  __typename: 'CouncilBudgetFundedEventEdge'
  cursor: Scalars['String']
  node: CouncilBudgetFundedEvent
}

export enum CouncilBudgetFundedEventOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MemberIdAsc = 'memberId_ASC',
  MemberIdDesc = 'memberId_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CouncilBudgetFundedEventUpdateInput = {
  amount?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  memberId?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  rationale?: InputMaybe<Scalars['String']>
}

export type CouncilBudgetFundedEventWhereInput = {
  AND?: InputMaybe<Array<CouncilBudgetFundedEventWhereInput>>
  NOT?: InputMaybe<Array<CouncilBudgetFundedEventWhereInput>>
  OR?: InputMaybe<Array<CouncilBudgetFundedEventWhereInput>>
  amount_eq?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  memberId_eq?: InputMaybe<Scalars['Int']>
  memberId_gt?: InputMaybe<Scalars['Int']>
  memberId_gte?: InputMaybe<Scalars['Int']>
  memberId_in?: InputMaybe<Array<Scalars['Int']>>
  memberId_lt?: InputMaybe<Scalars['Int']>
  memberId_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CouncilBudgetFundedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type CouncilMember = BaseGraphQlObject & {
  __typename: 'CouncilMember'
  /** Amount of reward collected by this council member so far. */
  accumulatedReward: Scalars['BigInt']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  electedInCouncil: ElectedCouncil
  electedInCouncilId: Scalars['String']
  id: Scalars['ID']
  /** Block number in which council member received the last reward payment. */
  lastPaymentBlock: Scalars['BigInt']
  member: Membership
  memberId: Scalars['String']
  /** Account that will receive used for reward currency for council membership. */
  rewardAccountId: Scalars['String']
  rewardpaymenteventcouncilMember?: Maybe<Array<RewardPaymentEvent>>
  /** Stake used for the council membership. */
  stake: Scalars['BigInt']
  /** Account used for staking currency for council membership. */
  stakingAccountId: Scalars['String']
  /** Reward amount that should have been paid but couldn't be paid off due to insufficient budget. */
  unpaidReward: Scalars['BigInt']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type CouncilMemberConnection = {
  __typename: 'CouncilMemberConnection'
  edges: Array<CouncilMemberEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CouncilMemberCreateInput = {
  accumulatedReward: Scalars['String']
  electedInCouncil: Scalars['ID']
  lastPaymentBlock: Scalars['String']
  member: Scalars['ID']
  rewardAccountId: Scalars['String']
  stake: Scalars['String']
  stakingAccountId: Scalars['String']
  unpaidReward: Scalars['String']
}

export type CouncilMemberEdge = {
  __typename: 'CouncilMemberEdge'
  cursor: Scalars['String']
  node: CouncilMember
}

export enum CouncilMemberOrderByInput {
  AccumulatedRewardAsc = 'accumulatedReward_ASC',
  AccumulatedRewardDesc = 'accumulatedReward_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ElectedInCouncilAsc = 'electedInCouncil_ASC',
  ElectedInCouncilDesc = 'electedInCouncil_DESC',
  LastPaymentBlockAsc = 'lastPaymentBlock_ASC',
  LastPaymentBlockDesc = 'lastPaymentBlock_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  RewardAccountIdAsc = 'rewardAccountId_ASC',
  RewardAccountIdDesc = 'rewardAccountId_DESC',
  StakeAsc = 'stake_ASC',
  StakeDesc = 'stake_DESC',
  StakingAccountIdAsc = 'stakingAccountId_ASC',
  StakingAccountIdDesc = 'stakingAccountId_DESC',
  UnpaidRewardAsc = 'unpaidReward_ASC',
  UnpaidRewardDesc = 'unpaidReward_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CouncilMemberUpdateInput = {
  accumulatedReward?: InputMaybe<Scalars['String']>
  electedInCouncil?: InputMaybe<Scalars['ID']>
  lastPaymentBlock?: InputMaybe<Scalars['String']>
  member?: InputMaybe<Scalars['ID']>
  rewardAccountId?: InputMaybe<Scalars['String']>
  stake?: InputMaybe<Scalars['String']>
  stakingAccountId?: InputMaybe<Scalars['String']>
  unpaidReward?: InputMaybe<Scalars['String']>
}

export type CouncilMemberWhereInput = {
  AND?: InputMaybe<Array<CouncilMemberWhereInput>>
  NOT?: InputMaybe<Array<CouncilMemberWhereInput>>
  OR?: InputMaybe<Array<CouncilMemberWhereInput>>
  accumulatedReward_eq?: InputMaybe<Scalars['BigInt']>
  accumulatedReward_gt?: InputMaybe<Scalars['BigInt']>
  accumulatedReward_gte?: InputMaybe<Scalars['BigInt']>
  accumulatedReward_in?: InputMaybe<Array<Scalars['BigInt']>>
  accumulatedReward_lt?: InputMaybe<Scalars['BigInt']>
  accumulatedReward_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  electedInCouncil?: InputMaybe<ElectedCouncilWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  lastPaymentBlock_eq?: InputMaybe<Scalars['BigInt']>
  lastPaymentBlock_gt?: InputMaybe<Scalars['BigInt']>
  lastPaymentBlock_gte?: InputMaybe<Scalars['BigInt']>
  lastPaymentBlock_in?: InputMaybe<Array<Scalars['BigInt']>>
  lastPaymentBlock_lt?: InputMaybe<Scalars['BigInt']>
  lastPaymentBlock_lte?: InputMaybe<Scalars['BigInt']>
  member?: InputMaybe<MembershipWhereInput>
  rewardAccountId_contains?: InputMaybe<Scalars['String']>
  rewardAccountId_endsWith?: InputMaybe<Scalars['String']>
  rewardAccountId_eq?: InputMaybe<Scalars['String']>
  rewardAccountId_in?: InputMaybe<Array<Scalars['String']>>
  rewardAccountId_startsWith?: InputMaybe<Scalars['String']>
  rewardpaymenteventcouncilMember_every?: InputMaybe<RewardPaymentEventWhereInput>
  rewardpaymenteventcouncilMember_none?: InputMaybe<RewardPaymentEventWhereInput>
  rewardpaymenteventcouncilMember_some?: InputMaybe<RewardPaymentEventWhereInput>
  stake_eq?: InputMaybe<Scalars['BigInt']>
  stake_gt?: InputMaybe<Scalars['BigInt']>
  stake_gte?: InputMaybe<Scalars['BigInt']>
  stake_in?: InputMaybe<Array<Scalars['BigInt']>>
  stake_lt?: InputMaybe<Scalars['BigInt']>
  stake_lte?: InputMaybe<Scalars['BigInt']>
  stakingAccountId_contains?: InputMaybe<Scalars['String']>
  stakingAccountId_endsWith?: InputMaybe<Scalars['String']>
  stakingAccountId_eq?: InputMaybe<Scalars['String']>
  stakingAccountId_in?: InputMaybe<Array<Scalars['String']>>
  stakingAccountId_startsWith?: InputMaybe<Scalars['String']>
  unpaidReward_eq?: InputMaybe<Scalars['BigInt']>
  unpaidReward_gt?: InputMaybe<Scalars['BigInt']>
  unpaidReward_gte?: InputMaybe<Scalars['BigInt']>
  unpaidReward_in?: InputMaybe<Array<Scalars['BigInt']>>
  unpaidReward_lt?: InputMaybe<Scalars['BigInt']>
  unpaidReward_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CouncilMemberWhereUniqueInput = {
  id: Scalars['ID']
}

export type CouncilStage = CouncilStageAnnouncing | CouncilStageElection | CouncilStageIdle | VariantNone

export type CouncilStageAnnouncing = {
  __typename: 'CouncilStageAnnouncing'
  /** Number of candidates aspiring to be elected as council members. */
  candidatesCount: Scalars['Float']
  /** Block number at which the stage ends */
  endsAt: Scalars['Int']
}

export type CouncilStageElection = {
  __typename: 'CouncilStageElection'
  /** Number of candidates aspiring to be elected as council members. */
  candidatesCount: Scalars['Float']
}

export type CouncilStageIdle = {
  __typename: 'CouncilStageIdle'
  /** Block number at which the stage ends */
  endsAt: Scalars['Int']
}

export type CouncilStageUpdate = BaseGraphQlObject & {
  __typename: 'CouncilStageUpdate'
  /** Block number at which change happened. */
  changedAt: Scalars['BigInt']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  electedCouncil?: Maybe<ElectedCouncil>
  electedCouncilId?: Maybe<Scalars['String']>
  /** Election not completed due to insufficient candidates or winners. */
  electionProblem?: Maybe<ElectionProblem>
  id: Scalars['ID']
  /** The new stage council got into. */
  stage: CouncilStage
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type CouncilStageUpdateConnection = {
  __typename: 'CouncilStageUpdateConnection'
  edges: Array<CouncilStageUpdateEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CouncilStageUpdateCreateInput = {
  changedAt: Scalars['String']
  electedCouncil?: InputMaybe<Scalars['ID']>
  electionProblem?: InputMaybe<ElectionProblem>
  stage: Scalars['JSONObject']
}

export type CouncilStageUpdateEdge = {
  __typename: 'CouncilStageUpdateEdge'
  cursor: Scalars['String']
  node: CouncilStageUpdate
}

export enum CouncilStageUpdateOrderByInput {
  ChangedAtAsc = 'changedAt_ASC',
  ChangedAtDesc = 'changedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ElectedCouncilAsc = 'electedCouncil_ASC',
  ElectedCouncilDesc = 'electedCouncil_DESC',
  ElectionProblemAsc = 'electionProblem_ASC',
  ElectionProblemDesc = 'electionProblem_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CouncilStageUpdateUpdateInput = {
  changedAt?: InputMaybe<Scalars['String']>
  electedCouncil?: InputMaybe<Scalars['ID']>
  electionProblem?: InputMaybe<ElectionProblem>
  stage?: InputMaybe<Scalars['JSONObject']>
}

export type CouncilStageUpdateWhereInput = {
  AND?: InputMaybe<Array<CouncilStageUpdateWhereInput>>
  NOT?: InputMaybe<Array<CouncilStageUpdateWhereInput>>
  OR?: InputMaybe<Array<CouncilStageUpdateWhereInput>>
  changedAt_eq?: InputMaybe<Scalars['BigInt']>
  changedAt_gt?: InputMaybe<Scalars['BigInt']>
  changedAt_gte?: InputMaybe<Scalars['BigInt']>
  changedAt_in?: InputMaybe<Array<Scalars['BigInt']>>
  changedAt_lt?: InputMaybe<Scalars['BigInt']>
  changedAt_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  electedCouncil?: InputMaybe<ElectedCouncilWhereInput>
  electionProblem_eq?: InputMaybe<ElectionProblem>
  electionProblem_in?: InputMaybe<Array<ElectionProblem>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  stage_json?: InputMaybe<Scalars['JSONObject']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CouncilStageUpdateWhereUniqueInput = {
  id: Scalars['ID']
}

export type CouncilorRewardUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'CouncilorRewardUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** New reward amount paid each reward period. */
    rewardAmount: Scalars['BigInt']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type CouncilorRewardUpdatedEventConnection = {
  __typename: 'CouncilorRewardUpdatedEventConnection'
  edges: Array<CouncilorRewardUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CouncilorRewardUpdatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  rewardAmount: Scalars['String']
}

export type CouncilorRewardUpdatedEventEdge = {
  __typename: 'CouncilorRewardUpdatedEventEdge'
  cursor: Scalars['String']
  node: CouncilorRewardUpdatedEvent
}

export enum CouncilorRewardUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RewardAmountAsc = 'rewardAmount_ASC',
  RewardAmountDesc = 'rewardAmount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CouncilorRewardUpdatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  rewardAmount?: InputMaybe<Scalars['String']>
}

export type CouncilorRewardUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<CouncilorRewardUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<CouncilorRewardUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<CouncilorRewardUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rewardAmount_eq?: InputMaybe<Scalars['BigInt']>
  rewardAmount_gt?: InputMaybe<Scalars['BigInt']>
  rewardAmount_gte?: InputMaybe<Scalars['BigInt']>
  rewardAmount_in?: InputMaybe<Array<Scalars['BigInt']>>
  rewardAmount_lt?: InputMaybe<Scalars['BigInt']>
  rewardAmount_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CouncilorRewardUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type CreateWorkingGroupLeadOpeningProposalDetails = {
  __typename: 'CreateWorkingGroupLeadOpeningProposalDetails'
  /** Related working group */
  group?: Maybe<WorkingGroup>
  /** The opening metadata */
  metadata?: Maybe<WorkingGroupOpeningMetadata>
  /** Initial workers' reward per block */
  rewardPerBlock: Scalars['Float']
  /** Min. application / role stake amount */
  stakeAmount: Scalars['Float']
  /** Role stake unstaking period in blocks */
  unstakingPeriod: Scalars['Int']
}

export type Curator = BaseGraphQlObject & {
  __typename: 'Curator'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  curatorGroups: Array<CuratorAgentPermissions>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** Type needs to have at least one non-relation entity. This value is not used. */
  dummy?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type CuratorAgentPermissions = BaseGraphQlObject & {
  __typename: 'CuratorAgentPermissions'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  curator: Curator
  curatorGroup: CuratorGroup
  curatorGroupId: Scalars['String']
  curatorId: Scalars['String']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** List of member's permissions */
  permissions: Array<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type CuratorAgentPermissionsConnection = {
  __typename: 'CuratorAgentPermissionsConnection'
  edges: Array<CuratorAgentPermissionsEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CuratorAgentPermissionsCreateInput = {
  curator: Scalars['ID']
  curatorGroup: Scalars['ID']
  permissions: Array<Scalars['String']>
}

export type CuratorAgentPermissionsEdge = {
  __typename: 'CuratorAgentPermissionsEdge'
  cursor: Scalars['String']
  node: CuratorAgentPermissions
}

export enum CuratorAgentPermissionsOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CuratorGroupAsc = 'curatorGroup_ASC',
  CuratorGroupDesc = 'curatorGroup_DESC',
  CuratorAsc = 'curator_ASC',
  CuratorDesc = 'curator_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CuratorAgentPermissionsUpdateInput = {
  curator?: InputMaybe<Scalars['ID']>
  curatorGroup?: InputMaybe<Scalars['ID']>
  permissions?: InputMaybe<Array<Scalars['String']>>
}

export type CuratorAgentPermissionsWhereInput = {
  AND?: InputMaybe<Array<CuratorAgentPermissionsWhereInput>>
  NOT?: InputMaybe<Array<CuratorAgentPermissionsWhereInput>>
  OR?: InputMaybe<Array<CuratorAgentPermissionsWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  curator?: InputMaybe<CuratorWhereInput>
  curatorGroup?: InputMaybe<CuratorGroupWhereInput>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  permissions_containsAll?: InputMaybe<Array<Scalars['String']>>
  permissions_containsAny?: InputMaybe<Array<Scalars['String']>>
  permissions_containsNone?: InputMaybe<Array<Scalars['String']>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CuratorAgentPermissionsWhereUniqueInput = {
  id: Scalars['ID']
}

export type CuratorConnection = {
  __typename: 'CuratorConnection'
  edges: Array<CuratorEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CuratorCreateInput = {
  dummy?: InputMaybe<Scalars['Float']>
}

export type CuratorEdge = {
  __typename: 'CuratorEdge'
  cursor: Scalars['String']
  node: Curator
}

export type CuratorGroup = BaseGraphQlObject & {
  __typename: 'CuratorGroup'
  auctionbidcanceledeventownerCuratorGroup?: Maybe<Array<AuctionBidCanceledEvent>>
  auctionbidmadeeventownerCuratorGroup?: Maybe<Array<AuctionBidMadeEvent>>
  auctioncanceledeventownerCuratorGroup?: Maybe<Array<AuctionCanceledEvent>>
  bidmadecompletingauctioneventownerCuratorGroup?: Maybe<Array<BidMadeCompletingAuctionEvent>>
  buynowcanceledeventownerCuratorGroup?: Maybe<Array<BuyNowCanceledEvent>>
  buynowpriceupdatedeventownerCuratorGroup?: Maybe<Array<BuyNowPriceUpdatedEvent>>
  channels: Array<Channel>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  curators: Array<CuratorAgentPermissions>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  englishauctionsettledeventownerCuratorGroup?: Maybe<Array<EnglishAuctionSettledEvent>>
  englishauctionstartedeventownerCuratorGroup?: Maybe<Array<EnglishAuctionStartedEvent>>
  id: Scalars['ID']
  /** Is group active or not */
  isActive: Scalars['Boolean']
  nftCollectorInChannels: Array<ChannelNftCollectors>
  nftboughteventownerCuratorGroup?: Maybe<Array<NftBoughtEvent>>
  nftissuedeventownerCuratorGroup?: Maybe<Array<NftIssuedEvent>>
  nftsellordermadeeventownerCuratorGroup?: Maybe<Array<NftSellOrderMadeEvent>>
  nftslingedbacktotheoriginalartisteventownerCuratorGroup?: Maybe<Array<NftSlingedBackToTheOriginalArtistEvent>>
  offeracceptedeventownerCuratorGroup?: Maybe<Array<OfferAcceptedEvent>>
  offercanceledeventownerCuratorGroup?: Maybe<Array<OfferCanceledEvent>>
  offerstartedeventownerCuratorGroup?: Maybe<Array<OfferStartedEvent>>
  openauctionbidacceptedeventownerCuratorGroup?: Maybe<Array<OpenAuctionBidAcceptedEvent>>
  openauctionstartedeventownerCuratorGroup?: Maybe<Array<OpenAuctionStartedEvent>>
  ownednftownerCuratorGroup?: Maybe<Array<OwnedNft>>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type CuratorGroupConnection = {
  __typename: 'CuratorGroupConnection'
  edges: Array<CuratorGroupEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type CuratorGroupCreateInput = {
  isActive: Scalars['Boolean']
}

export type CuratorGroupEdge = {
  __typename: 'CuratorGroupEdge'
  cursor: Scalars['String']
  node: CuratorGroup
}

export enum CuratorGroupOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CuratorGroupUpdateInput = {
  isActive?: InputMaybe<Scalars['Boolean']>
}

export type CuratorGroupWhereInput = {
  AND?: InputMaybe<Array<CuratorGroupWhereInput>>
  NOT?: InputMaybe<Array<CuratorGroupWhereInput>>
  OR?: InputMaybe<Array<CuratorGroupWhereInput>>
  auctionbidcanceledeventownerCuratorGroup_every?: InputMaybe<AuctionBidCanceledEventWhereInput>
  auctionbidcanceledeventownerCuratorGroup_none?: InputMaybe<AuctionBidCanceledEventWhereInput>
  auctionbidcanceledeventownerCuratorGroup_some?: InputMaybe<AuctionBidCanceledEventWhereInput>
  auctionbidmadeeventownerCuratorGroup_every?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctionbidmadeeventownerCuratorGroup_none?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctionbidmadeeventownerCuratorGroup_some?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctioncanceledeventownerCuratorGroup_every?: InputMaybe<AuctionCanceledEventWhereInput>
  auctioncanceledeventownerCuratorGroup_none?: InputMaybe<AuctionCanceledEventWhereInput>
  auctioncanceledeventownerCuratorGroup_some?: InputMaybe<AuctionCanceledEventWhereInput>
  bidmadecompletingauctioneventownerCuratorGroup_every?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventownerCuratorGroup_none?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventownerCuratorGroup_some?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  buynowcanceledeventownerCuratorGroup_every?: InputMaybe<BuyNowCanceledEventWhereInput>
  buynowcanceledeventownerCuratorGroup_none?: InputMaybe<BuyNowCanceledEventWhereInput>
  buynowcanceledeventownerCuratorGroup_some?: InputMaybe<BuyNowCanceledEventWhereInput>
  buynowpriceupdatedeventownerCuratorGroup_every?: InputMaybe<BuyNowPriceUpdatedEventWhereInput>
  buynowpriceupdatedeventownerCuratorGroup_none?: InputMaybe<BuyNowPriceUpdatedEventWhereInput>
  buynowpriceupdatedeventownerCuratorGroup_some?: InputMaybe<BuyNowPriceUpdatedEventWhereInput>
  channels_every?: InputMaybe<ChannelWhereInput>
  channels_none?: InputMaybe<ChannelWhereInput>
  channels_some?: InputMaybe<ChannelWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  curators_every?: InputMaybe<CuratorAgentPermissionsWhereInput>
  curators_none?: InputMaybe<CuratorAgentPermissionsWhereInput>
  curators_some?: InputMaybe<CuratorAgentPermissionsWhereInput>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  englishauctionsettledeventownerCuratorGroup_every?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  englishauctionsettledeventownerCuratorGroup_none?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  englishauctionsettledeventownerCuratorGroup_some?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  englishauctionstartedeventownerCuratorGroup_every?: InputMaybe<EnglishAuctionStartedEventWhereInput>
  englishauctionstartedeventownerCuratorGroup_none?: InputMaybe<EnglishAuctionStartedEventWhereInput>
  englishauctionstartedeventownerCuratorGroup_some?: InputMaybe<EnglishAuctionStartedEventWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  isActive_eq?: InputMaybe<Scalars['Boolean']>
  isActive_in?: InputMaybe<Array<Scalars['Boolean']>>
  nftCollectorInChannels_every?: InputMaybe<ChannelNftCollectorsWhereInput>
  nftCollectorInChannels_none?: InputMaybe<ChannelNftCollectorsWhereInput>
  nftCollectorInChannels_some?: InputMaybe<ChannelNftCollectorsWhereInput>
  nftboughteventownerCuratorGroup_every?: InputMaybe<NftBoughtEventWhereInput>
  nftboughteventownerCuratorGroup_none?: InputMaybe<NftBoughtEventWhereInput>
  nftboughteventownerCuratorGroup_some?: InputMaybe<NftBoughtEventWhereInput>
  nftissuedeventownerCuratorGroup_every?: InputMaybe<NftIssuedEventWhereInput>
  nftissuedeventownerCuratorGroup_none?: InputMaybe<NftIssuedEventWhereInput>
  nftissuedeventownerCuratorGroup_some?: InputMaybe<NftIssuedEventWhereInput>
  nftsellordermadeeventownerCuratorGroup_every?: InputMaybe<NftSellOrderMadeEventWhereInput>
  nftsellordermadeeventownerCuratorGroup_none?: InputMaybe<NftSellOrderMadeEventWhereInput>
  nftsellordermadeeventownerCuratorGroup_some?: InputMaybe<NftSellOrderMadeEventWhereInput>
  nftslingedbacktotheoriginalartisteventownerCuratorGroup_every?: InputMaybe<NftSlingedBackToTheOriginalArtistEventWhereInput>
  nftslingedbacktotheoriginalartisteventownerCuratorGroup_none?: InputMaybe<NftSlingedBackToTheOriginalArtistEventWhereInput>
  nftslingedbacktotheoriginalartisteventownerCuratorGroup_some?: InputMaybe<NftSlingedBackToTheOriginalArtistEventWhereInput>
  offeracceptedeventownerCuratorGroup_every?: InputMaybe<OfferAcceptedEventWhereInput>
  offeracceptedeventownerCuratorGroup_none?: InputMaybe<OfferAcceptedEventWhereInput>
  offeracceptedeventownerCuratorGroup_some?: InputMaybe<OfferAcceptedEventWhereInput>
  offercanceledeventownerCuratorGroup_every?: InputMaybe<OfferCanceledEventWhereInput>
  offercanceledeventownerCuratorGroup_none?: InputMaybe<OfferCanceledEventWhereInput>
  offercanceledeventownerCuratorGroup_some?: InputMaybe<OfferCanceledEventWhereInput>
  offerstartedeventownerCuratorGroup_every?: InputMaybe<OfferStartedEventWhereInput>
  offerstartedeventownerCuratorGroup_none?: InputMaybe<OfferStartedEventWhereInput>
  offerstartedeventownerCuratorGroup_some?: InputMaybe<OfferStartedEventWhereInput>
  openauctionbidacceptedeventownerCuratorGroup_every?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  openauctionbidacceptedeventownerCuratorGroup_none?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  openauctionbidacceptedeventownerCuratorGroup_some?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  openauctionstartedeventownerCuratorGroup_every?: InputMaybe<OpenAuctionStartedEventWhereInput>
  openauctionstartedeventownerCuratorGroup_none?: InputMaybe<OpenAuctionStartedEventWhereInput>
  openauctionstartedeventownerCuratorGroup_some?: InputMaybe<OpenAuctionStartedEventWhereInput>
  ownednftownerCuratorGroup_every?: InputMaybe<OwnedNftWhereInput>
  ownednftownerCuratorGroup_none?: InputMaybe<OwnedNftWhereInput>
  ownednftownerCuratorGroup_some?: InputMaybe<OwnedNftWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CuratorGroupWhereUniqueInput = {
  id: Scalars['ID']
}

export enum CuratorOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DummyAsc = 'dummy_ASC',
  DummyDesc = 'dummy_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CuratorUpdateInput = {
  dummy?: InputMaybe<Scalars['Float']>
}

export type CuratorWhereInput = {
  AND?: InputMaybe<Array<CuratorWhereInput>>
  NOT?: InputMaybe<Array<CuratorWhereInput>>
  OR?: InputMaybe<Array<CuratorWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  curatorGroups_every?: InputMaybe<CuratorAgentPermissionsWhereInput>
  curatorGroups_none?: InputMaybe<CuratorAgentPermissionsWhereInput>
  curatorGroups_some?: InputMaybe<CuratorAgentPermissionsWhereInput>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  dummy_eq?: InputMaybe<Scalars['Int']>
  dummy_gt?: InputMaybe<Scalars['Int']>
  dummy_gte?: InputMaybe<Scalars['Int']>
  dummy_in?: InputMaybe<Array<Scalars['Int']>>
  dummy_lt?: InputMaybe<Scalars['Int']>
  dummy_lte?: InputMaybe<Scalars['Int']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type CuratorWhereUniqueInput = {
  id: Scalars['ID']
}

export type DataObjectType =
  | DataObjectTypeChannelAvatar
  | DataObjectTypeChannelCoverPhoto
  | DataObjectTypeChannelPayoutsPayload
  | DataObjectTypeUnknown
  | DataObjectTypeVideoMedia
  | DataObjectTypeVideoSubtitle
  | DataObjectTypeVideoThumbnail

export type DataObjectTypeChannelAvatar = {
  __typename: 'DataObjectTypeChannelAvatar'
  /** Related channel entity */
  channel?: Maybe<Channel>
}

export type DataObjectTypeChannelCoverPhoto = {
  __typename: 'DataObjectTypeChannelCoverPhoto'
  /** Related channel entity */
  channel?: Maybe<Channel>
}

export type DataObjectTypeChannelPayoutsPayload = {
  __typename: 'DataObjectTypeChannelPayoutsPayload'
  phantom?: Maybe<Scalars['Int']>
}

export type DataObjectTypeUnknown = {
  __typename: 'DataObjectTypeUnknown'
  phantom?: Maybe<Scalars['Int']>
}

export type DataObjectTypeVideoMedia = {
  __typename: 'DataObjectTypeVideoMedia'
  /** Related video entity */
  video?: Maybe<Video>
}

export type DataObjectTypeVideoSubtitle = {
  __typename: 'DataObjectTypeVideoSubtitle'
  /** Related subtitle entity */
  subtitle?: Maybe<VideoSubtitle>
  /** Related video entity */
  video?: Maybe<Video>
}

export type DataObjectTypeVideoThumbnail = {
  __typename: 'DataObjectTypeVideoThumbnail'
  /** Related video entity */
  video?: Maybe<Video>
}

export type DecreaseWorkingGroupLeadStakeProposalDetails = {
  __typename: 'DecreaseWorkingGroupLeadStakeProposalDetails'
  /** Amount to decrease the stake by */
  amount: Scalars['Float']
  /** The lead that should be affected */
  lead?: Maybe<Worker>
}

export type DeleteResponse = {
  id: Scalars['ID']
}

export type DistributionBucket = BaseGraphQlObject & {
  __typename: 'DistributionBucket'
  /** Whether the bucket is accepting any new bags */
  acceptingNewBags: Scalars['Boolean']
  bags: Array<StorageBag>
  /** Bucket index within the family */
  bucketIndex: Scalars['Int']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** Whether the bucket is currently distributing content */
  distributing: Scalars['Boolean']
  family: DistributionBucketFamily
  familyId: Scalars['String']
  id: Scalars['ID']
  operators: Array<DistributionBucketOperator>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type DistributionBucketConnection = {
  __typename: 'DistributionBucketConnection'
  edges: Array<DistributionBucketEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type DistributionBucketCreateInput = {
  acceptingNewBags: Scalars['Boolean']
  bucketIndex: Scalars['Float']
  distributing: Scalars['Boolean']
  family: Scalars['ID']
}

export type DistributionBucketEdge = {
  __typename: 'DistributionBucketEdge'
  cursor: Scalars['String']
  node: DistributionBucket
}

export type DistributionBucketFamily = BaseGraphQlObject & {
  __typename: 'DistributionBucketFamily'
  buckets: Array<DistributionBucket>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  metadata?: Maybe<DistributionBucketFamilyMetadata>
  metadataId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type DistributionBucketFamilyConnection = {
  __typename: 'DistributionBucketFamilyConnection'
  edges: Array<DistributionBucketFamilyEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type DistributionBucketFamilyCreateInput = {
  metadata?: InputMaybe<Scalars['ID']>
}

export type DistributionBucketFamilyEdge = {
  __typename: 'DistributionBucketFamilyEdge'
  cursor: Scalars['String']
  node: DistributionBucketFamily
}

export type DistributionBucketFamilyGeographicArea = BaseGraphQlObject & {
  __typename: 'DistributionBucketFamilyGeographicArea'
  /** Geographical area (continent / country / subdivision) */
  area: GeographicalArea
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  distributionBucketFamilyMetadata: DistributionBucketFamilyMetadata
  distributionBucketFamilyMetadataId: Scalars['String']
  id: Scalars['ID']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type DistributionBucketFamilyGeographicAreaConnection = {
  __typename: 'DistributionBucketFamilyGeographicAreaConnection'
  edges: Array<DistributionBucketFamilyGeographicAreaEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type DistributionBucketFamilyGeographicAreaCreateInput = {
  area: Scalars['JSONObject']
  distributionBucketFamilyMetadata: Scalars['ID']
}

export type DistributionBucketFamilyGeographicAreaEdge = {
  __typename: 'DistributionBucketFamilyGeographicAreaEdge'
  cursor: Scalars['String']
  node: DistributionBucketFamilyGeographicArea
}

export enum DistributionBucketFamilyGeographicAreaOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DistributionBucketFamilyMetadataAsc = 'distributionBucketFamilyMetadata_ASC',
  DistributionBucketFamilyMetadataDesc = 'distributionBucketFamilyMetadata_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type DistributionBucketFamilyGeographicAreaUpdateInput = {
  area?: InputMaybe<Scalars['JSONObject']>
  distributionBucketFamilyMetadata?: InputMaybe<Scalars['ID']>
}

export type DistributionBucketFamilyGeographicAreaWhereInput = {
  AND?: InputMaybe<Array<DistributionBucketFamilyGeographicAreaWhereInput>>
  NOT?: InputMaybe<Array<DistributionBucketFamilyGeographicAreaWhereInput>>
  OR?: InputMaybe<Array<DistributionBucketFamilyGeographicAreaWhereInput>>
  area_json?: InputMaybe<Scalars['JSONObject']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  distributionBucketFamilyMetadata?: InputMaybe<DistributionBucketFamilyMetadataWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type DistributionBucketFamilyGeographicAreaWhereUniqueInput = {
  id: Scalars['ID']
}

export type DistributionBucketFamilyMetadata = BaseGraphQlObject & {
  __typename: 'DistributionBucketFamilyMetadata'
  areas: Array<DistributionBucketFamilyGeographicArea>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** Optional, more specific description of the region covered by the family */
  description?: Maybe<Scalars['String']>
  distributionbucketfamilymetadata?: Maybe<Array<DistributionBucketFamily>>
  id: Scalars['ID']
  /** List of targets (hosts/ips) best suited latency measurements for the family */
  latencyTestTargets?: Maybe<Array<Scalars['String']>>
  /** Name of the geographical region covered by the family (ie.: us-east-1) */
  region?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type DistributionBucketFamilyMetadataConnection = {
  __typename: 'DistributionBucketFamilyMetadataConnection'
  edges: Array<DistributionBucketFamilyMetadataEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type DistributionBucketFamilyMetadataCreateInput = {
  description?: InputMaybe<Scalars['String']>
  latencyTestTargets?: InputMaybe<Array<Scalars['String']>>
  region?: InputMaybe<Scalars['String']>
}

export type DistributionBucketFamilyMetadataEdge = {
  __typename: 'DistributionBucketFamilyMetadataEdge'
  cursor: Scalars['String']
  node: DistributionBucketFamilyMetadata
}

export enum DistributionBucketFamilyMetadataOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type DistributionBucketFamilyMetadataUpdateInput = {
  description?: InputMaybe<Scalars['String']>
  latencyTestTargets?: InputMaybe<Array<Scalars['String']>>
  region?: InputMaybe<Scalars['String']>
}

export type DistributionBucketFamilyMetadataWhereInput = {
  AND?: InputMaybe<Array<DistributionBucketFamilyMetadataWhereInput>>
  NOT?: InputMaybe<Array<DistributionBucketFamilyMetadataWhereInput>>
  OR?: InputMaybe<Array<DistributionBucketFamilyMetadataWhereInput>>
  areas_every?: InputMaybe<DistributionBucketFamilyGeographicAreaWhereInput>
  areas_none?: InputMaybe<DistributionBucketFamilyGeographicAreaWhereInput>
  areas_some?: InputMaybe<DistributionBucketFamilyGeographicAreaWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  description_contains?: InputMaybe<Scalars['String']>
  description_endsWith?: InputMaybe<Scalars['String']>
  description_eq?: InputMaybe<Scalars['String']>
  description_in?: InputMaybe<Array<Scalars['String']>>
  description_startsWith?: InputMaybe<Scalars['String']>
  distributionbucketfamilymetadata_every?: InputMaybe<DistributionBucketFamilyWhereInput>
  distributionbucketfamilymetadata_none?: InputMaybe<DistributionBucketFamilyWhereInput>
  distributionbucketfamilymetadata_some?: InputMaybe<DistributionBucketFamilyWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  latencyTestTargets_containsAll?: InputMaybe<Array<Scalars['String']>>
  latencyTestTargets_containsAny?: InputMaybe<Array<Scalars['String']>>
  latencyTestTargets_containsNone?: InputMaybe<Array<Scalars['String']>>
  region_contains?: InputMaybe<Scalars['String']>
  region_endsWith?: InputMaybe<Scalars['String']>
  region_eq?: InputMaybe<Scalars['String']>
  region_in?: InputMaybe<Array<Scalars['String']>>
  region_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type DistributionBucketFamilyMetadataWhereUniqueInput = {
  id: Scalars['ID']
}

export enum DistributionBucketFamilyOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type DistributionBucketFamilyUpdateInput = {
  metadata?: InputMaybe<Scalars['ID']>
}

export type DistributionBucketFamilyWhereInput = {
  AND?: InputMaybe<Array<DistributionBucketFamilyWhereInput>>
  NOT?: InputMaybe<Array<DistributionBucketFamilyWhereInput>>
  OR?: InputMaybe<Array<DistributionBucketFamilyWhereInput>>
  buckets_every?: InputMaybe<DistributionBucketWhereInput>
  buckets_none?: InputMaybe<DistributionBucketWhereInput>
  buckets_some?: InputMaybe<DistributionBucketWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  metadata?: InputMaybe<DistributionBucketFamilyMetadataWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type DistributionBucketFamilyWhereUniqueInput = {
  id: Scalars['ID']
}

export type DistributionBucketOperator = BaseGraphQlObject & {
  __typename: 'DistributionBucketOperator'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  distributionBucket: DistributionBucket
  distributionBucketId: Scalars['String']
  id: Scalars['ID']
  metadata?: Maybe<DistributionBucketOperatorMetadata>
  metadataId?: Maybe<Scalars['String']>
  /** Current operator status */
  status: DistributionBucketOperatorStatus
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  /** ID of the distribution group worker */
  workerId: Scalars['Int']
}

export type DistributionBucketOperatorConnection = {
  __typename: 'DistributionBucketOperatorConnection'
  edges: Array<DistributionBucketOperatorEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type DistributionBucketOperatorCreateInput = {
  distributionBucket: Scalars['ID']
  metadata?: InputMaybe<Scalars['ID']>
  status: DistributionBucketOperatorStatus
  workerId: Scalars['Float']
}

export type DistributionBucketOperatorEdge = {
  __typename: 'DistributionBucketOperatorEdge'
  cursor: Scalars['String']
  node: DistributionBucketOperator
}

export type DistributionBucketOperatorMetadata = BaseGraphQlObject & {
  __typename: 'DistributionBucketOperatorMetadata'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  distributionbucketoperatormetadata?: Maybe<Array<DistributionBucketOperator>>
  /** Additional information about the node/operator */
  extra?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** Root distributor node api endpoint */
  nodeEndpoint?: Maybe<Scalars['String']>
  nodeLocation?: Maybe<NodeLocationMetadata>
  nodeLocationId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type DistributionBucketOperatorMetadataConnection = {
  __typename: 'DistributionBucketOperatorMetadataConnection'
  edges: Array<DistributionBucketOperatorMetadataEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type DistributionBucketOperatorMetadataCreateInput = {
  extra?: InputMaybe<Scalars['String']>
  nodeEndpoint?: InputMaybe<Scalars['String']>
  nodeLocation?: InputMaybe<Scalars['ID']>
}

export type DistributionBucketOperatorMetadataEdge = {
  __typename: 'DistributionBucketOperatorMetadataEdge'
  cursor: Scalars['String']
  node: DistributionBucketOperatorMetadata
}

export enum DistributionBucketOperatorMetadataOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ExtraAsc = 'extra_ASC',
  ExtraDesc = 'extra_DESC',
  NodeEndpointAsc = 'nodeEndpoint_ASC',
  NodeEndpointDesc = 'nodeEndpoint_DESC',
  NodeLocationAsc = 'nodeLocation_ASC',
  NodeLocationDesc = 'nodeLocation_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type DistributionBucketOperatorMetadataUpdateInput = {
  extra?: InputMaybe<Scalars['String']>
  nodeEndpoint?: InputMaybe<Scalars['String']>
  nodeLocation?: InputMaybe<Scalars['ID']>
}

export type DistributionBucketOperatorMetadataWhereInput = {
  AND?: InputMaybe<Array<DistributionBucketOperatorMetadataWhereInput>>
  NOT?: InputMaybe<Array<DistributionBucketOperatorMetadataWhereInput>>
  OR?: InputMaybe<Array<DistributionBucketOperatorMetadataWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  distributionbucketoperatormetadata_every?: InputMaybe<DistributionBucketOperatorWhereInput>
  distributionbucketoperatormetadata_none?: InputMaybe<DistributionBucketOperatorWhereInput>
  distributionbucketoperatormetadata_some?: InputMaybe<DistributionBucketOperatorWhereInput>
  extra_contains?: InputMaybe<Scalars['String']>
  extra_endsWith?: InputMaybe<Scalars['String']>
  extra_eq?: InputMaybe<Scalars['String']>
  extra_in?: InputMaybe<Array<Scalars['String']>>
  extra_startsWith?: InputMaybe<Scalars['String']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  nodeEndpoint_contains?: InputMaybe<Scalars['String']>
  nodeEndpoint_endsWith?: InputMaybe<Scalars['String']>
  nodeEndpoint_eq?: InputMaybe<Scalars['String']>
  nodeEndpoint_in?: InputMaybe<Array<Scalars['String']>>
  nodeEndpoint_startsWith?: InputMaybe<Scalars['String']>
  nodeLocation?: InputMaybe<NodeLocationMetadataWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type DistributionBucketOperatorMetadataWhereUniqueInput = {
  id: Scalars['ID']
}

export enum DistributionBucketOperatorOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DistributionBucketAsc = 'distributionBucket_ASC',
  DistributionBucketDesc = 'distributionBucket_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerIdAsc = 'workerId_ASC',
  WorkerIdDesc = 'workerId_DESC',
}

export enum DistributionBucketOperatorStatus {
  Active = 'ACTIVE',
  Invited = 'INVITED',
}

export type DistributionBucketOperatorUpdateInput = {
  distributionBucket?: InputMaybe<Scalars['ID']>
  metadata?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<DistributionBucketOperatorStatus>
  workerId?: InputMaybe<Scalars['Float']>
}

export type DistributionBucketOperatorWhereInput = {
  AND?: InputMaybe<Array<DistributionBucketOperatorWhereInput>>
  NOT?: InputMaybe<Array<DistributionBucketOperatorWhereInput>>
  OR?: InputMaybe<Array<DistributionBucketOperatorWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  distributionBucket?: InputMaybe<DistributionBucketWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  metadata?: InputMaybe<DistributionBucketOperatorMetadataWhereInput>
  status_eq?: InputMaybe<DistributionBucketOperatorStatus>
  status_in?: InputMaybe<Array<DistributionBucketOperatorStatus>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  workerId_eq?: InputMaybe<Scalars['Int']>
  workerId_gt?: InputMaybe<Scalars['Int']>
  workerId_gte?: InputMaybe<Scalars['Int']>
  workerId_in?: InputMaybe<Array<Scalars['Int']>>
  workerId_lt?: InputMaybe<Scalars['Int']>
  workerId_lte?: InputMaybe<Scalars['Int']>
}

export type DistributionBucketOperatorWhereUniqueInput = {
  id: Scalars['ID']
}

export enum DistributionBucketOrderByInput {
  AcceptingNewBagsAsc = 'acceptingNewBags_ASC',
  AcceptingNewBagsDesc = 'acceptingNewBags_DESC',
  BucketIndexAsc = 'bucketIndex_ASC',
  BucketIndexDesc = 'bucketIndex_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DistributingAsc = 'distributing_ASC',
  DistributingDesc = 'distributing_DESC',
  FamilyAsc = 'family_ASC',
  FamilyDesc = 'family_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type DistributionBucketUpdateInput = {
  acceptingNewBags?: InputMaybe<Scalars['Boolean']>
  bucketIndex?: InputMaybe<Scalars['Float']>
  distributing?: InputMaybe<Scalars['Boolean']>
  family?: InputMaybe<Scalars['ID']>
}

export type DistributionBucketWhereInput = {
  AND?: InputMaybe<Array<DistributionBucketWhereInput>>
  NOT?: InputMaybe<Array<DistributionBucketWhereInput>>
  OR?: InputMaybe<Array<DistributionBucketWhereInput>>
  acceptingNewBags_eq?: InputMaybe<Scalars['Boolean']>
  acceptingNewBags_in?: InputMaybe<Array<Scalars['Boolean']>>
  bags_every?: InputMaybe<StorageBagWhereInput>
  bags_none?: InputMaybe<StorageBagWhereInput>
  bags_some?: InputMaybe<StorageBagWhereInput>
  bucketIndex_eq?: InputMaybe<Scalars['Int']>
  bucketIndex_gt?: InputMaybe<Scalars['Int']>
  bucketIndex_gte?: InputMaybe<Scalars['Int']>
  bucketIndex_in?: InputMaybe<Array<Scalars['Int']>>
  bucketIndex_lt?: InputMaybe<Scalars['Int']>
  bucketIndex_lte?: InputMaybe<Scalars['Int']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  distributing_eq?: InputMaybe<Scalars['Boolean']>
  distributing_in?: InputMaybe<Array<Scalars['Boolean']>>
  family?: InputMaybe<DistributionBucketFamilyWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  operators_every?: InputMaybe<DistributionBucketOperatorWhereInput>
  operators_none?: InputMaybe<DistributionBucketOperatorWhereInput>
  operators_some?: InputMaybe<DistributionBucketOperatorWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type DistributionBucketWhereUniqueInput = {
  id: Scalars['ID']
}

export type ElectedCouncil = BaseGraphQlObject & {
  __typename: 'ElectedCouncil'
  councilElections: Array<ElectionRound>
  councilMembers: Array<CouncilMember>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** Block number at which the council was elected. */
  electedAtBlock: Scalars['Int']
  /** Network running at the time of election. */
  electedAtNetwork: Network
  /** Time at which the council was elected. */
  electedAtTime: Scalars['DateTime']
  /** Block number at which the council reign ended and a new council was elected. */
  endedAtBlock?: Maybe<Scalars['Int']>
  /** Network running at the time of resignation. */
  endedAtNetwork?: Maybe<Network>
  /** Time at which the council reign ended and a new council was elected. */
  endedAtTime?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  /** Sign if council is already resigned. */
  isResigned: Scalars['Boolean']
  newcouncilelectedeventelectedCouncil?: Maybe<Array<NewCouncilElectedEvent>>
  nextCouncilElections: Array<ElectionRound>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  updates: Array<CouncilStageUpdate>
  version: Scalars['Int']
}

export type ElectedCouncilConnection = {
  __typename: 'ElectedCouncilConnection'
  edges: Array<ElectedCouncilEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ElectedCouncilCreateInput = {
  electedAtBlock: Scalars['Float']
  electedAtNetwork: Network
  electedAtTime: Scalars['DateTime']
  endedAtBlock?: InputMaybe<Scalars['Float']>
  endedAtNetwork?: InputMaybe<Network>
  endedAtTime?: InputMaybe<Scalars['DateTime']>
  isResigned: Scalars['Boolean']
}

export type ElectedCouncilEdge = {
  __typename: 'ElectedCouncilEdge'
  cursor: Scalars['String']
  node: ElectedCouncil
}

export enum ElectedCouncilOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ElectedAtBlockAsc = 'electedAtBlock_ASC',
  ElectedAtBlockDesc = 'electedAtBlock_DESC',
  ElectedAtNetworkAsc = 'electedAtNetwork_ASC',
  ElectedAtNetworkDesc = 'electedAtNetwork_DESC',
  ElectedAtTimeAsc = 'electedAtTime_ASC',
  ElectedAtTimeDesc = 'electedAtTime_DESC',
  EndedAtBlockAsc = 'endedAtBlock_ASC',
  EndedAtBlockDesc = 'endedAtBlock_DESC',
  EndedAtNetworkAsc = 'endedAtNetwork_ASC',
  EndedAtNetworkDesc = 'endedAtNetwork_DESC',
  EndedAtTimeAsc = 'endedAtTime_ASC',
  EndedAtTimeDesc = 'endedAtTime_DESC',
  IsResignedAsc = 'isResigned_ASC',
  IsResignedDesc = 'isResigned_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ElectedCouncilUpdateInput = {
  electedAtBlock?: InputMaybe<Scalars['Float']>
  electedAtNetwork?: InputMaybe<Network>
  electedAtTime?: InputMaybe<Scalars['DateTime']>
  endedAtBlock?: InputMaybe<Scalars['Float']>
  endedAtNetwork?: InputMaybe<Network>
  endedAtTime?: InputMaybe<Scalars['DateTime']>
  isResigned?: InputMaybe<Scalars['Boolean']>
}

export type ElectedCouncilWhereInput = {
  AND?: InputMaybe<Array<ElectedCouncilWhereInput>>
  NOT?: InputMaybe<Array<ElectedCouncilWhereInput>>
  OR?: InputMaybe<Array<ElectedCouncilWhereInput>>
  councilElections_every?: InputMaybe<ElectionRoundWhereInput>
  councilElections_none?: InputMaybe<ElectionRoundWhereInput>
  councilElections_some?: InputMaybe<ElectionRoundWhereInput>
  councilMembers_every?: InputMaybe<CouncilMemberWhereInput>
  councilMembers_none?: InputMaybe<CouncilMemberWhereInput>
  councilMembers_some?: InputMaybe<CouncilMemberWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  electedAtBlock_eq?: InputMaybe<Scalars['Int']>
  electedAtBlock_gt?: InputMaybe<Scalars['Int']>
  electedAtBlock_gte?: InputMaybe<Scalars['Int']>
  electedAtBlock_in?: InputMaybe<Array<Scalars['Int']>>
  electedAtBlock_lt?: InputMaybe<Scalars['Int']>
  electedAtBlock_lte?: InputMaybe<Scalars['Int']>
  electedAtNetwork_eq?: InputMaybe<Network>
  electedAtNetwork_in?: InputMaybe<Array<Network>>
  electedAtTime_eq?: InputMaybe<Scalars['DateTime']>
  electedAtTime_gt?: InputMaybe<Scalars['DateTime']>
  electedAtTime_gte?: InputMaybe<Scalars['DateTime']>
  electedAtTime_lt?: InputMaybe<Scalars['DateTime']>
  electedAtTime_lte?: InputMaybe<Scalars['DateTime']>
  endedAtBlock_eq?: InputMaybe<Scalars['Int']>
  endedAtBlock_gt?: InputMaybe<Scalars['Int']>
  endedAtBlock_gte?: InputMaybe<Scalars['Int']>
  endedAtBlock_in?: InputMaybe<Array<Scalars['Int']>>
  endedAtBlock_lt?: InputMaybe<Scalars['Int']>
  endedAtBlock_lte?: InputMaybe<Scalars['Int']>
  endedAtNetwork_eq?: InputMaybe<Network>
  endedAtNetwork_in?: InputMaybe<Array<Network>>
  endedAtTime_eq?: InputMaybe<Scalars['DateTime']>
  endedAtTime_gt?: InputMaybe<Scalars['DateTime']>
  endedAtTime_gte?: InputMaybe<Scalars['DateTime']>
  endedAtTime_lt?: InputMaybe<Scalars['DateTime']>
  endedAtTime_lte?: InputMaybe<Scalars['DateTime']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  isResigned_eq?: InputMaybe<Scalars['Boolean']>
  isResigned_in?: InputMaybe<Array<Scalars['Boolean']>>
  newcouncilelectedeventelectedCouncil_every?: InputMaybe<NewCouncilElectedEventWhereInput>
  newcouncilelectedeventelectedCouncil_none?: InputMaybe<NewCouncilElectedEventWhereInput>
  newcouncilelectedeventelectedCouncil_some?: InputMaybe<NewCouncilElectedEventWhereInput>
  nextCouncilElections_every?: InputMaybe<ElectionRoundWhereInput>
  nextCouncilElections_none?: InputMaybe<ElectionRoundWhereInput>
  nextCouncilElections_some?: InputMaybe<ElectionRoundWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  updates_every?: InputMaybe<CouncilStageUpdateWhereInput>
  updates_none?: InputMaybe<CouncilStageUpdateWhereInput>
  updates_some?: InputMaybe<CouncilStageUpdateWhereInput>
}

export type ElectedCouncilWhereUniqueInput = {
  id: Scalars['ID']
}

export enum ElectionProblem {
  NewCouncilNotElected = 'NEW_COUNCIL_NOT_ELECTED',
  NotEnoughCandidates = 'NOT_ENOUGH_CANDIDATES',
}

export type ElectionRound = BaseGraphQlObject & {
  __typename: 'ElectionRound'
  candidates: Array<Candidate>
  castVotes: Array<CastVote>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  /** Election cycle ID. */
  cycleId: Scalars['Int']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  electedCouncil: ElectedCouncil
  electedCouncilId: Scalars['String']
  /** Block number at which the election ended. */
  endedAtBlock?: Maybe<Scalars['Int']>
  /** Network running at the time the election ended. */
  endedAtNetwork?: Maybe<Network>
  /** Time at which the election ended. */
  endedAtTime?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  /** Sign if election has already finished. */
  isFinished: Scalars['Boolean']
  newcandidateeventelectionRound?: Maybe<Array<NewCandidateEvent>>
  nextElectedCouncil?: Maybe<ElectedCouncil>
  nextElectedCouncilId?: Maybe<Scalars['String']>
  referendumStageRevealing?: Maybe<ReferendumStageRevealing>
  referendumStageVoting?: Maybe<ReferendumStageVoting>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type ElectionRoundConnection = {
  __typename: 'ElectionRoundConnection'
  edges: Array<ElectionRoundEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ElectionRoundCreateInput = {
  cycleId: Scalars['Float']
  electedCouncil: Scalars['ID']
  endedAtBlock?: InputMaybe<Scalars['Float']>
  endedAtNetwork?: InputMaybe<Network>
  endedAtTime?: InputMaybe<Scalars['DateTime']>
  isFinished: Scalars['Boolean']
  nextElectedCouncil?: InputMaybe<Scalars['ID']>
}

export type ElectionRoundEdge = {
  __typename: 'ElectionRoundEdge'
  cursor: Scalars['String']
  node: ElectionRound
}

export enum ElectionRoundOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CycleIdAsc = 'cycleId_ASC',
  CycleIdDesc = 'cycleId_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ElectedCouncilAsc = 'electedCouncil_ASC',
  ElectedCouncilDesc = 'electedCouncil_DESC',
  EndedAtBlockAsc = 'endedAtBlock_ASC',
  EndedAtBlockDesc = 'endedAtBlock_DESC',
  EndedAtNetworkAsc = 'endedAtNetwork_ASC',
  EndedAtNetworkDesc = 'endedAtNetwork_DESC',
  EndedAtTimeAsc = 'endedAtTime_ASC',
  EndedAtTimeDesc = 'endedAtTime_DESC',
  IsFinishedAsc = 'isFinished_ASC',
  IsFinishedDesc = 'isFinished_DESC',
  NextElectedCouncilAsc = 'nextElectedCouncil_ASC',
  NextElectedCouncilDesc = 'nextElectedCouncil_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ElectionRoundUpdateInput = {
  cycleId?: InputMaybe<Scalars['Float']>
  electedCouncil?: InputMaybe<Scalars['ID']>
  endedAtBlock?: InputMaybe<Scalars['Float']>
  endedAtNetwork?: InputMaybe<Network>
  endedAtTime?: InputMaybe<Scalars['DateTime']>
  isFinished?: InputMaybe<Scalars['Boolean']>
  nextElectedCouncil?: InputMaybe<Scalars['ID']>
}

export type ElectionRoundWhereInput = {
  AND?: InputMaybe<Array<ElectionRoundWhereInput>>
  NOT?: InputMaybe<Array<ElectionRoundWhereInput>>
  OR?: InputMaybe<Array<ElectionRoundWhereInput>>
  candidates_every?: InputMaybe<CandidateWhereInput>
  candidates_none?: InputMaybe<CandidateWhereInput>
  candidates_some?: InputMaybe<CandidateWhereInput>
  castVotes_every?: InputMaybe<CastVoteWhereInput>
  castVotes_none?: InputMaybe<CastVoteWhereInput>
  castVotes_some?: InputMaybe<CastVoteWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  cycleId_eq?: InputMaybe<Scalars['Int']>
  cycleId_gt?: InputMaybe<Scalars['Int']>
  cycleId_gte?: InputMaybe<Scalars['Int']>
  cycleId_in?: InputMaybe<Array<Scalars['Int']>>
  cycleId_lt?: InputMaybe<Scalars['Int']>
  cycleId_lte?: InputMaybe<Scalars['Int']>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  electedCouncil?: InputMaybe<ElectedCouncilWhereInput>
  endedAtBlock_eq?: InputMaybe<Scalars['Int']>
  endedAtBlock_gt?: InputMaybe<Scalars['Int']>
  endedAtBlock_gte?: InputMaybe<Scalars['Int']>
  endedAtBlock_in?: InputMaybe<Array<Scalars['Int']>>
  endedAtBlock_lt?: InputMaybe<Scalars['Int']>
  endedAtBlock_lte?: InputMaybe<Scalars['Int']>
  endedAtNetwork_eq?: InputMaybe<Network>
  endedAtNetwork_in?: InputMaybe<Array<Network>>
  endedAtTime_eq?: InputMaybe<Scalars['DateTime']>
  endedAtTime_gt?: InputMaybe<Scalars['DateTime']>
  endedAtTime_gte?: InputMaybe<Scalars['DateTime']>
  endedAtTime_lt?: InputMaybe<Scalars['DateTime']>
  endedAtTime_lte?: InputMaybe<Scalars['DateTime']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  isFinished_eq?: InputMaybe<Scalars['Boolean']>
  isFinished_in?: InputMaybe<Array<Scalars['Boolean']>>
  newcandidateeventelectionRound_every?: InputMaybe<NewCandidateEventWhereInput>
  newcandidateeventelectionRound_none?: InputMaybe<NewCandidateEventWhereInput>
  newcandidateeventelectionRound_some?: InputMaybe<NewCandidateEventWhereInput>
  nextElectedCouncil?: InputMaybe<ElectedCouncilWhereInput>
  referendumStageRevealing?: InputMaybe<ReferendumStageRevealingWhereInput>
  referendumStageVoting?: InputMaybe<ReferendumStageVotingWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ElectionRoundWhereUniqueInput = {
  id: Scalars['ID']
}

export type EnglishAuctionSettledEvent = BaseGraphQlObject &
  Event & {
    __typename: 'EnglishAuctionSettledEvent'
    bidders: Array<Membership>
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
    winner: Membership
    winnerId: Scalars['String']
    winningBid: Bid
    winningBidId: Scalars['String']
  }

export type EnglishAuctionSettledEventConnection = {
  __typename: 'EnglishAuctionSettledEventConnection'
  edges: Array<EnglishAuctionSettledEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type EnglishAuctionSettledEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video: Scalars['ID']
  winner: Scalars['ID']
  winningBid: Scalars['ID']
}

export type EnglishAuctionSettledEventEdge = {
  __typename: 'EnglishAuctionSettledEventEdge'
  cursor: Scalars['String']
  node: EnglishAuctionSettledEvent
}

export enum EnglishAuctionSettledEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
  WinnerAsc = 'winner_ASC',
  WinnerDesc = 'winner_DESC',
  WinningBidAsc = 'winningBid_ASC',
  WinningBidDesc = 'winningBid_DESC',
}

export type EnglishAuctionSettledEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video?: InputMaybe<Scalars['ID']>
  winner?: InputMaybe<Scalars['ID']>
  winningBid?: InputMaybe<Scalars['ID']>
}

export type EnglishAuctionSettledEventWhereInput = {
  AND?: InputMaybe<Array<EnglishAuctionSettledEventWhereInput>>
  NOT?: InputMaybe<Array<EnglishAuctionSettledEventWhereInput>>
  OR?: InputMaybe<Array<EnglishAuctionSettledEventWhereInput>>
  bidders_every?: InputMaybe<MembershipWhereInput>
  bidders_none?: InputMaybe<MembershipWhereInput>
  bidders_some?: InputMaybe<MembershipWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
  winner?: InputMaybe<MembershipWhereInput>
  winningBid?: InputMaybe<BidWhereInput>
}

export type EnglishAuctionSettledEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type EnglishAuctionStartedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'EnglishAuctionStartedEvent'
    /** Actor that started this auction. */
    actor: ContentActor
    auction: Auction
    auctionId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
  }

export type EnglishAuctionStartedEventConnection = {
  __typename: 'EnglishAuctionStartedEventConnection'
  edges: Array<EnglishAuctionStartedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type EnglishAuctionStartedEventCreateInput = {
  actor: Scalars['JSONObject']
  auction: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video: Scalars['ID']
}

export type EnglishAuctionStartedEventEdge = {
  __typename: 'EnglishAuctionStartedEventEdge'
  cursor: Scalars['String']
  node: EnglishAuctionStartedEvent
}

export enum EnglishAuctionStartedEventOrderByInput {
  AuctionAsc = 'auction_ASC',
  AuctionDesc = 'auction_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type EnglishAuctionStartedEventUpdateInput = {
  actor?: InputMaybe<Scalars['JSONObject']>
  auction?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video?: InputMaybe<Scalars['ID']>
}

export type EnglishAuctionStartedEventWhereInput = {
  AND?: InputMaybe<Array<EnglishAuctionStartedEventWhereInput>>
  NOT?: InputMaybe<Array<EnglishAuctionStartedEventWhereInput>>
  OR?: InputMaybe<Array<EnglishAuctionStartedEventWhereInput>>
  actor_json?: InputMaybe<Scalars['JSONObject']>
  auction?: InputMaybe<AuctionWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type EnglishAuctionStartedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type EntitySubscription = {
  __typename: 'EntitySubscription'
  entityId: Scalars['String']
  id?: Maybe<Scalars['Int']>
  kind: EntitySubscriptionKind
  status: EntitySubscriptionStatus
}

export enum EntitySubscriptionKind {
  ForumCategoryEntityPost = 'FORUM_CATEGORY_ENTITY_POST',
  ForumCategoryEntityThread = 'FORUM_CATEGORY_ENTITY_THREAD',
  ForumThreadEntityPost = 'FORUM_THREAD_ENTITY_POST',
}

export enum EntitySubscriptionStatus {
  Default = 'DEFAULT',
  Mute = 'MUTE',
  Watch = 'WATCH',
}

export type Event = {
  /** Blocknumber of the block in which the event was emitted. */
  inBlock: Scalars['Int']
  /** Hash of the extrinsic which caused the event to be emitted */
  inExtrinsic?: Maybe<Scalars['String']>
  /** Index of event in block from which it was emitted. */
  indexInBlock: Scalars['Int']
  /** Network the block was produced in */
  network: Network
  /** Filtering options for interface implementers */
  type?: Maybe<EventTypeOptions>
}

export type EventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  type?: InputMaybe<EventTypeOptions>
}

export enum EventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export enum EventTypeOptions {
  AnnouncingPeriodStartedEvent = 'AnnouncingPeriodStartedEvent',
  ApplicationWithdrawnEvent = 'ApplicationWithdrawnEvent',
  AppliedOnOpeningEvent = 'AppliedOnOpeningEvent',
  AuctionBidCanceledEvent = 'AuctionBidCanceledEvent',
  AuctionBidMadeEvent = 'AuctionBidMadeEvent',
  AuctionCanceledEvent = 'AuctionCanceledEvent',
  BidMadeCompletingAuctionEvent = 'BidMadeCompletingAuctionEvent',
  BountyCanceledEvent = 'BountyCanceledEvent',
  BountyCreatedEvent = 'BountyCreatedEvent',
  BountyCreatorCherryWithdrawalEvent = 'BountyCreatorCherryWithdrawalEvent',
  BountyFundedEvent = 'BountyFundedEvent',
  BountyFundingWithdrawalEvent = 'BountyFundingWithdrawalEvent',
  BountyMaxFundingReachedEvent = 'BountyMaxFundingReachedEvent',
  BountyRemovedEvent = 'BountyRemovedEvent',
  BountyVetoedEvent = 'BountyVetoedEvent',
  BudgetBalanceSetEvent = 'BudgetBalanceSetEvent',
  BudgetIncrementUpdatedEvent = 'BudgetIncrementUpdatedEvent',
  BudgetRefillEvent = 'BudgetRefillEvent',
  BudgetRefillPlannedEvent = 'BudgetRefillPlannedEvent',
  BudgetSetEvent = 'BudgetSetEvent',
  BudgetSpendingEvent = 'BudgetSpendingEvent',
  BudgetUpdatedEvent = 'BudgetUpdatedEvent',
  BuyNowCanceledEvent = 'BuyNowCanceledEvent',
  BuyNowPriceUpdatedEvent = 'BuyNowPriceUpdatedEvent',
  CandidacyNoteSetEvent = 'CandidacyNoteSetEvent',
  CandidacyStakeReleaseEvent = 'CandidacyStakeReleaseEvent',
  CandidacyWithdrawEvent = 'CandidacyWithdrawEvent',
  CategoryArchivalStatusUpdatedEvent = 'CategoryArchivalStatusUpdatedEvent',
  CategoryCreatedEvent = 'CategoryCreatedEvent',
  CategoryDeletedEvent = 'CategoryDeletedEvent',
  CategoryMembershipOfModeratorUpdatedEvent = 'CategoryMembershipOfModeratorUpdatedEvent',
  CategoryStickyThreadUpdateEvent = 'CategoryStickyThreadUpdateEvent',
  ChannelFundsWithdrawnEvent = 'ChannelFundsWithdrawnEvent',
  ChannelRewardClaimedAndWithdrawnEvent = 'ChannelRewardClaimedAndWithdrawnEvent',
  ChannelRewardClaimedEvent = 'ChannelRewardClaimedEvent',
  CommentCreatedEvent = 'CommentCreatedEvent',
  CommentDeletedEvent = 'CommentDeletedEvent',
  CommentModeratedEvent = 'CommentModeratedEvent',
  CommentPinnedEvent = 'CommentPinnedEvent',
  CommentReactedEvent = 'CommentReactedEvent',
  CommentTextUpdatedEvent = 'CommentTextUpdatedEvent',
  CouncilBudgetFundedEvent = 'CouncilBudgetFundedEvent',
  CouncilorRewardUpdatedEvent = 'CouncilorRewardUpdatedEvent',
  EnglishAuctionSettledEvent = 'EnglishAuctionSettledEvent',
  EnglishAuctionStartedEvent = 'EnglishAuctionStartedEvent',
  InitialInvitationBalanceUpdatedEvent = 'InitialInvitationBalanceUpdatedEvent',
  InitialInvitationCountUpdatedEvent = 'InitialInvitationCountUpdatedEvent',
  InvitesTransferredEvent = 'InvitesTransferredEvent',
  LeaderInvitationQuotaUpdatedEvent = 'LeaderInvitationQuotaUpdatedEvent',
  LeaderSetEvent = 'LeaderSetEvent',
  LeaderUnsetEvent = 'LeaderUnsetEvent',
  MemberAccountsUpdatedEvent = 'MemberAccountsUpdatedEvent',
  MemberBannedFromChannelEvent = 'MemberBannedFromChannelEvent',
  MemberCreatedEvent = 'MemberCreatedEvent',
  MemberInvitedEvent = 'MemberInvitedEvent',
  MemberProfileUpdatedEvent = 'MemberProfileUpdatedEvent',
  MemberVerificationStatusUpdatedEvent = 'MemberVerificationStatusUpdatedEvent',
  MembershipBoughtEvent = 'MembershipBoughtEvent',
  MembershipGiftedEvent = 'MembershipGiftedEvent',
  MembershipPriceUpdatedEvent = 'MembershipPriceUpdatedEvent',
  MetaprotocolTransactionStatusEvent = 'MetaprotocolTransactionStatusEvent',
  NewCandidateEvent = 'NewCandidateEvent',
  NewCouncilElectedEvent = 'NewCouncilElectedEvent',
  NewCouncilNotElectedEvent = 'NewCouncilNotElectedEvent',
  NewMissedRewardLevelReachedEvent = 'NewMissedRewardLevelReachedEvent',
  NftBoughtEvent = 'NftBoughtEvent',
  NftIssuedEvent = 'NftIssuedEvent',
  NftSlingedBackToTheOriginalArtistEvent = 'NftSlingedBackToTheOriginalArtistEvent',
  NotEnoughCandidatesEvent = 'NotEnoughCandidatesEvent',
  OfferAcceptedEvent = 'OfferAcceptedEvent',
  OfferCanceledEvent = 'OfferCanceledEvent',
  OfferStartedEvent = 'OfferStartedEvent',
  OpenAuctionBidAcceptedEvent = 'OpenAuctionBidAcceptedEvent',
  OpenAuctionStartedEvent = 'OpenAuctionStartedEvent',
  OpeningAddedEvent = 'OpeningAddedEvent',
  OpeningCanceledEvent = 'OpeningCanceledEvent',
  OpeningFilledEvent = 'OpeningFilledEvent',
  OracleJudgmentSubmittedEvent = 'OracleJudgmentSubmittedEvent',
  PostAddedEvent = 'PostAddedEvent',
  PostDeletedEvent = 'PostDeletedEvent',
  PostModeratedEvent = 'PostModeratedEvent',
  PostTextUpdatedEvent = 'PostTextUpdatedEvent',
  ProposalCancelledEvent = 'ProposalCancelledEvent',
  ProposalCreatedEvent = 'ProposalCreatedEvent',
  ProposalDecisionMadeEvent = 'ProposalDecisionMadeEvent',
  ProposalDiscussionPostCreatedEvent = 'ProposalDiscussionPostCreatedEvent',
  ProposalDiscussionPostDeletedEvent = 'ProposalDiscussionPostDeletedEvent',
  ProposalDiscussionPostUpdatedEvent = 'ProposalDiscussionPostUpdatedEvent',
  ProposalDiscussionThreadModeChangedEvent = 'ProposalDiscussionThreadModeChangedEvent',
  ProposalExecutedEvent = 'ProposalExecutedEvent',
  ProposalStatusUpdatedEvent = 'ProposalStatusUpdatedEvent',
  ProposalVotedEvent = 'ProposalVotedEvent',
  ReferendumFinishedEvent = 'ReferendumFinishedEvent',
  ReferendumStartedEvent = 'ReferendumStartedEvent',
  ReferendumStartedForcefullyEvent = 'ReferendumStartedForcefullyEvent',
  ReferralCutUpdatedEvent = 'ReferralCutUpdatedEvent',
  RequestFundedEvent = 'RequestFundedEvent',
  RevealingStageStartedEvent = 'RevealingStageStartedEvent',
  RewardPaidEvent = 'RewardPaidEvent',
  RewardPaymentEvent = 'RewardPaymentEvent',
  StakeDecreasedEvent = 'StakeDecreasedEvent',
  StakeIncreasedEvent = 'StakeIncreasedEvent',
  StakeReleasedEvent = 'StakeReleasedEvent',
  StakeSlashedEvent = 'StakeSlashedEvent',
  StakingAccountAddedEvent = 'StakingAccountAddedEvent',
  StakingAccountConfirmedEvent = 'StakingAccountConfirmedEvent',
  StakingAccountRemovedEvent = 'StakingAccountRemovedEvent',
  StatusTextChangedEvent = 'StatusTextChangedEvent',
  TerminatedLeaderEvent = 'TerminatedLeaderEvent',
  TerminatedWorkerEvent = 'TerminatedWorkerEvent',
  ThreadCreatedEvent = 'ThreadCreatedEvent',
  ThreadDeletedEvent = 'ThreadDeletedEvent',
  ThreadMetadataUpdatedEvent = 'ThreadMetadataUpdatedEvent',
  ThreadModeratedEvent = 'ThreadModeratedEvent',
  ThreadMovedEvent = 'ThreadMovedEvent',
  VideoReactedEvent = 'VideoReactedEvent',
  VideoReactionsPreferenceEvent = 'VideoReactionsPreferenceEvent',
  VoteCastEvent = 'VoteCastEvent',
  VoteRevealedEvent = 'VoteRevealedEvent',
  VotingPeriodStartedEvent = 'VotingPeriodStartedEvent',
  WorkEntrantFundsWithdrawnEvent = 'WorkEntrantFundsWithdrawnEvent',
  WorkEntryAnnouncedEvent = 'WorkEntryAnnouncedEvent',
  WorkEntrySlashedEvent = 'WorkEntrySlashedEvent',
  WorkEntryWithdrawnEvent = 'WorkEntryWithdrawnEvent',
  WorkSubmittedEvent = 'WorkSubmittedEvent',
  WorkerExitedEvent = 'WorkerExitedEvent',
  WorkerRewardAccountUpdatedEvent = 'WorkerRewardAccountUpdatedEvent',
  WorkerRewardAmountUpdatedEvent = 'WorkerRewardAmountUpdatedEvent',
  WorkerRoleAccountUpdatedEvent = 'WorkerRoleAccountUpdatedEvent',
  WorkerStartedLeavingEvent = 'WorkerStartedLeavingEvent',
}

export type EventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  type?: InputMaybe<EventTypeOptions>
}

export type EventWhereInput = {
  AND?: InputMaybe<Array<EventWhereInput>>
  NOT?: InputMaybe<Array<EventWhereInput>>
  OR?: InputMaybe<Array<EventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  type_eq?: InputMaybe<EventTypeOptions>
  type_in?: InputMaybe<Array<EventTypeOptions>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type EventWhereUniqueInput = {
  id: Scalars['ID']
}

export type FillWorkingGroupLeadOpeningProposalDetails = {
  __typename: 'FillWorkingGroupLeadOpeningProposalDetails'
  /** Selected successful application */
  application?: Maybe<WorkingGroupApplication>
  /** Lead opening to to be filled */
  opening?: Maybe<WorkingGroupOpening>
}

export type ForumCategory = BaseGraphQlObject & {
  __typename: 'ForumCategory'
  categoryarchivalstatusupdatedeventcategory?: Maybe<Array<CategoryArchivalStatusUpdatedEvent>>
  categorydeletedeventcategory?: Maybe<Array<CategoryDeletedEvent>>
  categorymembershipofmoderatorupdatedeventcategory?: Maybe<Array<CategoryMembershipOfModeratorUpdatedEvent>>
  categorystickythreadupdateeventcategory?: Maybe<Array<CategoryStickyThreadUpdateEvent>>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  createdInEvent: CategoryCreatedEvent
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** Category description */
  description: Scalars['String']
  forumcategoryparent?: Maybe<Array<ForumCategory>>
  id: Scalars['ID']
  moderators: Array<Worker>
  parent?: Maybe<ForumCategory>
  parentId?: Maybe<Scalars['String']>
  /** Current category status */
  status: CategoryStatus
  threadmovedeventnewCategory?: Maybe<Array<ThreadMovedEvent>>
  threadmovedeventoldCategory?: Maybe<Array<ThreadMovedEvent>>
  threads: Array<ForumThread>
  /** Category title */
  title: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type ForumCategoryConnection = {
  __typename: 'ForumCategoryConnection'
  edges: Array<ForumCategoryEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ForumCategoryCreateInput = {
  description: Scalars['String']
  parent?: InputMaybe<Scalars['ID']>
  status: Scalars['JSONObject']
  title: Scalars['String']
}

export type ForumCategoryEdge = {
  __typename: 'ForumCategoryEdge'
  cursor: Scalars['String']
  node: ForumCategory
}

export enum ForumCategoryOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ParentAsc = 'parent_ASC',
  ParentDesc = 'parent_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ForumCategoryUpdateInput = {
  description?: InputMaybe<Scalars['String']>
  parent?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<Scalars['JSONObject']>
  title?: InputMaybe<Scalars['String']>
}

export type ForumCategoryWhereInput = {
  AND?: InputMaybe<Array<ForumCategoryWhereInput>>
  NOT?: InputMaybe<Array<ForumCategoryWhereInput>>
  OR?: InputMaybe<Array<ForumCategoryWhereInput>>
  categoryarchivalstatusupdatedeventcategory_every?: InputMaybe<CategoryArchivalStatusUpdatedEventWhereInput>
  categoryarchivalstatusupdatedeventcategory_none?: InputMaybe<CategoryArchivalStatusUpdatedEventWhereInput>
  categoryarchivalstatusupdatedeventcategory_some?: InputMaybe<CategoryArchivalStatusUpdatedEventWhereInput>
  categorydeletedeventcategory_every?: InputMaybe<CategoryDeletedEventWhereInput>
  categorydeletedeventcategory_none?: InputMaybe<CategoryDeletedEventWhereInput>
  categorydeletedeventcategory_some?: InputMaybe<CategoryDeletedEventWhereInput>
  categorymembershipofmoderatorupdatedeventcategory_every?: InputMaybe<CategoryMembershipOfModeratorUpdatedEventWhereInput>
  categorymembershipofmoderatorupdatedeventcategory_none?: InputMaybe<CategoryMembershipOfModeratorUpdatedEventWhereInput>
  categorymembershipofmoderatorupdatedeventcategory_some?: InputMaybe<CategoryMembershipOfModeratorUpdatedEventWhereInput>
  categorystickythreadupdateeventcategory_every?: InputMaybe<CategoryStickyThreadUpdateEventWhereInput>
  categorystickythreadupdateeventcategory_none?: InputMaybe<CategoryStickyThreadUpdateEventWhereInput>
  categorystickythreadupdateeventcategory_some?: InputMaybe<CategoryStickyThreadUpdateEventWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  createdInEvent?: InputMaybe<CategoryCreatedEventWhereInput>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  description_contains?: InputMaybe<Scalars['String']>
  description_endsWith?: InputMaybe<Scalars['String']>
  description_eq?: InputMaybe<Scalars['String']>
  description_in?: InputMaybe<Array<Scalars['String']>>
  description_startsWith?: InputMaybe<Scalars['String']>
  forumcategoryparent_every?: InputMaybe<ForumCategoryWhereInput>
  forumcategoryparent_none?: InputMaybe<ForumCategoryWhereInput>
  forumcategoryparent_some?: InputMaybe<ForumCategoryWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  moderators_every?: InputMaybe<WorkerWhereInput>
  moderators_none?: InputMaybe<WorkerWhereInput>
  moderators_some?: InputMaybe<WorkerWhereInput>
  parent?: InputMaybe<ForumCategoryWhereInput>
  status_json?: InputMaybe<Scalars['JSONObject']>
  threadmovedeventnewCategory_every?: InputMaybe<ThreadMovedEventWhereInput>
  threadmovedeventnewCategory_none?: InputMaybe<ThreadMovedEventWhereInput>
  threadmovedeventnewCategory_some?: InputMaybe<ThreadMovedEventWhereInput>
  threadmovedeventoldCategory_every?: InputMaybe<ThreadMovedEventWhereInput>
  threadmovedeventoldCategory_none?: InputMaybe<ThreadMovedEventWhereInput>
  threadmovedeventoldCategory_some?: InputMaybe<ThreadMovedEventWhereInput>
  threads_every?: InputMaybe<ForumThreadWhereInput>
  threads_none?: InputMaybe<ForumThreadWhereInput>
  threads_some?: InputMaybe<ForumThreadWhereInput>
  title_contains?: InputMaybe<Scalars['String']>
  title_endsWith?: InputMaybe<Scalars['String']>
  title_eq?: InputMaybe<Scalars['String']>
  title_in?: InputMaybe<Array<Scalars['String']>>
  title_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ForumCategoryWhereUniqueInput = {
  id: Scalars['ID']
}

export type ForumPost = BaseGraphQlObject & {
  __typename: 'ForumPost'
  author: Membership
  authorId: Scalars['String']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  deletedInEvent?: Maybe<PostDeletedEvent>
  deletedInEventId?: Maybe<Scalars['String']>
  edits: Array<PostTextUpdatedEvent>
  forumpostrepliesTo?: Maybe<Array<ForumPost>>
  forumthreadinitialPost?: Maybe<Array<ForumThread>>
  id: Scalars['ID']
  /** True if the post is either Active or Locked */
  isVisible: Scalars['Boolean']
  /** The origin of the post (either thread creation event or regular PostAdded event) */
  origin: PostOrigin
  postaddedeventpost?: Maybe<Array<PostAddedEvent>>
  postmoderatedeventpost?: Maybe<Array<PostModeratedEvent>>
  repliesTo?: Maybe<ForumPost>
  repliesToId?: Maybe<Scalars['String']>
  /** Current post status */
  status: PostStatus
  /** Content of the post (md-formatted) */
  text: Scalars['String']
  thread: ForumThread
  threadId: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type ForumPostConnection = {
  __typename: 'ForumPostConnection'
  edges: Array<ForumPostEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ForumPostCreateInput = {
  author: Scalars['ID']
  deletedInEvent?: InputMaybe<Scalars['ID']>
  isVisible: Scalars['Boolean']
  origin: Scalars['JSONObject']
  repliesTo?: InputMaybe<Scalars['ID']>
  status: Scalars['JSONObject']
  text: Scalars['String']
  thread: Scalars['ID']
}

export type ForumPostEdge = {
  __typename: 'ForumPostEdge'
  cursor: Scalars['String']
  node: ForumPost
}

export enum ForumPostOrderByInput {
  AuthorAsc = 'author_ASC',
  AuthorDesc = 'author_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DeletedInEventAsc = 'deletedInEvent_ASC',
  DeletedInEventDesc = 'deletedInEvent_DESC',
  IsVisibleAsc = 'isVisible_ASC',
  IsVisibleDesc = 'isVisible_DESC',
  RepliesToAsc = 'repliesTo_ASC',
  RepliesToDesc = 'repliesTo_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  ThreadAsc = 'thread_ASC',
  ThreadDesc = 'thread_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ForumPostUpdateInput = {
  author?: InputMaybe<Scalars['ID']>
  deletedInEvent?: InputMaybe<Scalars['ID']>
  isVisible?: InputMaybe<Scalars['Boolean']>
  origin?: InputMaybe<Scalars['JSONObject']>
  repliesTo?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<Scalars['JSONObject']>
  text?: InputMaybe<Scalars['String']>
  thread?: InputMaybe<Scalars['ID']>
}

export type ForumPostWhereInput = {
  AND?: InputMaybe<Array<ForumPostWhereInput>>
  NOT?: InputMaybe<Array<ForumPostWhereInput>>
  OR?: InputMaybe<Array<ForumPostWhereInput>>
  author?: InputMaybe<MembershipWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedInEvent?: InputMaybe<PostDeletedEventWhereInput>
  edits_every?: InputMaybe<PostTextUpdatedEventWhereInput>
  edits_none?: InputMaybe<PostTextUpdatedEventWhereInput>
  edits_some?: InputMaybe<PostTextUpdatedEventWhereInput>
  forumpostrepliesTo_every?: InputMaybe<ForumPostWhereInput>
  forumpostrepliesTo_none?: InputMaybe<ForumPostWhereInput>
  forumpostrepliesTo_some?: InputMaybe<ForumPostWhereInput>
  forumthreadinitialPost_every?: InputMaybe<ForumThreadWhereInput>
  forumthreadinitialPost_none?: InputMaybe<ForumThreadWhereInput>
  forumthreadinitialPost_some?: InputMaybe<ForumThreadWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  isVisible_eq?: InputMaybe<Scalars['Boolean']>
  isVisible_in?: InputMaybe<Array<Scalars['Boolean']>>
  origin_json?: InputMaybe<Scalars['JSONObject']>
  postaddedeventpost_every?: InputMaybe<PostAddedEventWhereInput>
  postaddedeventpost_none?: InputMaybe<PostAddedEventWhereInput>
  postaddedeventpost_some?: InputMaybe<PostAddedEventWhereInput>
  postmoderatedeventpost_every?: InputMaybe<PostModeratedEventWhereInput>
  postmoderatedeventpost_none?: InputMaybe<PostModeratedEventWhereInput>
  postmoderatedeventpost_some?: InputMaybe<PostModeratedEventWhereInput>
  repliesTo?: InputMaybe<ForumPostWhereInput>
  status_json?: InputMaybe<Scalars['JSONObject']>
  text_contains?: InputMaybe<Scalars['String']>
  text_endsWith?: InputMaybe<Scalars['String']>
  text_eq?: InputMaybe<Scalars['String']>
  text_in?: InputMaybe<Array<Scalars['String']>>
  text_startsWith?: InputMaybe<Scalars['String']>
  thread?: InputMaybe<ForumThreadWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ForumPostWhereUniqueInput = {
  id: Scalars['ID']
}

export type ForumThread = BaseGraphQlObject & {
  __typename: 'ForumThread'
  author: Membership
  authorId: Scalars['String']
  bountydiscussionThread?: Maybe<Array<Bounty>>
  category: ForumCategory
  categoryId: Scalars['String']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  createdInEvent: ThreadCreatedEvent
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  initialPost?: Maybe<ForumPost>
  initialPostId?: Maybe<Scalars['String']>
  /** Whether the thread is sticky in the category */
  isSticky: Scalars['Boolean']
  /** True if the thread is either Active or Locked */
  isVisible: Scalars['Boolean']
  madeStickyInEvents: Array<CategoryStickyThreadUpdateEvent>
  metadataUpdates: Array<ThreadMetadataUpdatedEvent>
  movedInEvents: Array<ThreadMovedEvent>
  posts: Array<ForumPost>
  /** Current thread status */
  status: ThreadStatus
  tags: Array<ForumThreadTag>
  threaddeletedeventthread?: Maybe<Array<ThreadDeletedEvent>>
  threadmoderatedeventthread?: Maybe<Array<ThreadModeratedEvent>>
  /** Thread title */
  title: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  /** Number of non-deleted posts in the thread */
  visiblePostsCount: Scalars['Int']
}

export type ForumThreadConnection = {
  __typename: 'ForumThreadConnection'
  edges: Array<ForumThreadEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ForumThreadCreateInput = {
  author: Scalars['ID']
  category: Scalars['ID']
  initialPost?: InputMaybe<Scalars['ID']>
  isSticky: Scalars['Boolean']
  isVisible: Scalars['Boolean']
  status: Scalars['JSONObject']
  title: Scalars['String']
  visiblePostsCount: Scalars['Float']
}

export type ForumThreadEdge = {
  __typename: 'ForumThreadEdge'
  cursor: Scalars['String']
  node: ForumThread
}

export enum ForumThreadOrderByInput {
  AuthorAsc = 'author_ASC',
  AuthorDesc = 'author_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InitialPostAsc = 'initialPost_ASC',
  InitialPostDesc = 'initialPost_DESC',
  IsStickyAsc = 'isSticky_ASC',
  IsStickyDesc = 'isSticky_DESC',
  IsVisibleAsc = 'isVisible_ASC',
  IsVisibleDesc = 'isVisible_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VisiblePostsCountAsc = 'visiblePostsCount_ASC',
  VisiblePostsCountDesc = 'visiblePostsCount_DESC',
}

export type ForumThreadTag = BaseGraphQlObject & {
  __typename: 'ForumThreadTag'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  threads: Array<ForumThread>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  /** Number of non-removed threads currently assigned to the tag */
  visibleThreadsCount: Scalars['Int']
}

export type ForumThreadTagConnection = {
  __typename: 'ForumThreadTagConnection'
  edges: Array<ForumThreadTagEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ForumThreadTagCreateInput = {
  visibleThreadsCount: Scalars['Float']
}

export type ForumThreadTagEdge = {
  __typename: 'ForumThreadTagEdge'
  cursor: Scalars['String']
  node: ForumThreadTag
}

export enum ForumThreadTagOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VisibleThreadsCountAsc = 'visibleThreadsCount_ASC',
  VisibleThreadsCountDesc = 'visibleThreadsCount_DESC',
}

export type ForumThreadTagUpdateInput = {
  visibleThreadsCount?: InputMaybe<Scalars['Float']>
}

export type ForumThreadTagWhereInput = {
  AND?: InputMaybe<Array<ForumThreadTagWhereInput>>
  NOT?: InputMaybe<Array<ForumThreadTagWhereInput>>
  OR?: InputMaybe<Array<ForumThreadTagWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  threads_every?: InputMaybe<ForumThreadWhereInput>
  threads_none?: InputMaybe<ForumThreadWhereInput>
  threads_some?: InputMaybe<ForumThreadWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  visibleThreadsCount_eq?: InputMaybe<Scalars['Int']>
  visibleThreadsCount_gt?: InputMaybe<Scalars['Int']>
  visibleThreadsCount_gte?: InputMaybe<Scalars['Int']>
  visibleThreadsCount_in?: InputMaybe<Array<Scalars['Int']>>
  visibleThreadsCount_lt?: InputMaybe<Scalars['Int']>
  visibleThreadsCount_lte?: InputMaybe<Scalars['Int']>
}

export type ForumThreadTagWhereUniqueInput = {
  id: Scalars['ID']
}

export type ForumThreadUpdateInput = {
  author?: InputMaybe<Scalars['ID']>
  category?: InputMaybe<Scalars['ID']>
  initialPost?: InputMaybe<Scalars['ID']>
  isSticky?: InputMaybe<Scalars['Boolean']>
  isVisible?: InputMaybe<Scalars['Boolean']>
  status?: InputMaybe<Scalars['JSONObject']>
  title?: InputMaybe<Scalars['String']>
  visiblePostsCount?: InputMaybe<Scalars['Float']>
}

export type ForumThreadWhereInput = {
  AND?: InputMaybe<Array<ForumThreadWhereInput>>
  NOT?: InputMaybe<Array<ForumThreadWhereInput>>
  OR?: InputMaybe<Array<ForumThreadWhereInput>>
  author?: InputMaybe<MembershipWhereInput>
  bountydiscussionThread_every?: InputMaybe<BountyWhereInput>
  bountydiscussionThread_none?: InputMaybe<BountyWhereInput>
  bountydiscussionThread_some?: InputMaybe<BountyWhereInput>
  category?: InputMaybe<ForumCategoryWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  createdInEvent?: InputMaybe<ThreadCreatedEventWhereInput>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  initialPost?: InputMaybe<ForumPostWhereInput>
  isSticky_eq?: InputMaybe<Scalars['Boolean']>
  isSticky_in?: InputMaybe<Array<Scalars['Boolean']>>
  isVisible_eq?: InputMaybe<Scalars['Boolean']>
  isVisible_in?: InputMaybe<Array<Scalars['Boolean']>>
  madeStickyInEvents_every?: InputMaybe<CategoryStickyThreadUpdateEventWhereInput>
  madeStickyInEvents_none?: InputMaybe<CategoryStickyThreadUpdateEventWhereInput>
  madeStickyInEvents_some?: InputMaybe<CategoryStickyThreadUpdateEventWhereInput>
  metadataUpdates_every?: InputMaybe<ThreadMetadataUpdatedEventWhereInput>
  metadataUpdates_none?: InputMaybe<ThreadMetadataUpdatedEventWhereInput>
  metadataUpdates_some?: InputMaybe<ThreadMetadataUpdatedEventWhereInput>
  movedInEvents_every?: InputMaybe<ThreadMovedEventWhereInput>
  movedInEvents_none?: InputMaybe<ThreadMovedEventWhereInput>
  movedInEvents_some?: InputMaybe<ThreadMovedEventWhereInput>
  posts_every?: InputMaybe<ForumPostWhereInput>
  posts_none?: InputMaybe<ForumPostWhereInput>
  posts_some?: InputMaybe<ForumPostWhereInput>
  status_json?: InputMaybe<Scalars['JSONObject']>
  tags_every?: InputMaybe<ForumThreadTagWhereInput>
  tags_none?: InputMaybe<ForumThreadTagWhereInput>
  tags_some?: InputMaybe<ForumThreadTagWhereInput>
  threaddeletedeventthread_every?: InputMaybe<ThreadDeletedEventWhereInput>
  threaddeletedeventthread_none?: InputMaybe<ThreadDeletedEventWhereInput>
  threaddeletedeventthread_some?: InputMaybe<ThreadDeletedEventWhereInput>
  threadmoderatedeventthread_every?: InputMaybe<ThreadModeratedEventWhereInput>
  threadmoderatedeventthread_none?: InputMaybe<ThreadModeratedEventWhereInput>
  threadmoderatedeventthread_some?: InputMaybe<ThreadModeratedEventWhereInput>
  title_contains?: InputMaybe<Scalars['String']>
  title_endsWith?: InputMaybe<Scalars['String']>
  title_eq?: InputMaybe<Scalars['String']>
  title_in?: InputMaybe<Array<Scalars['String']>>
  title_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  visiblePostsCount_eq?: InputMaybe<Scalars['Int']>
  visiblePostsCount_gt?: InputMaybe<Scalars['Int']>
  visiblePostsCount_gte?: InputMaybe<Scalars['Int']>
  visiblePostsCount_in?: InputMaybe<Array<Scalars['Int']>>
  visiblePostsCount_lt?: InputMaybe<Scalars['Int']>
  visiblePostsCount_lte?: InputMaybe<Scalars['Int']>
}

export type ForumThreadWhereUniqueInput = {
  id: Scalars['ID']
}

export type FundingRequestDestination = BaseGraphQlObject & {
  __typename: 'FundingRequestDestination'
  /** Destination account */
  account: Scalars['String']
  /** Amount of funds requested */
  amount: Scalars['BigInt']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  list: FundingRequestDestinationsList
  listId: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type FundingRequestDestinationConnection = {
  __typename: 'FundingRequestDestinationConnection'
  edges: Array<FundingRequestDestinationEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type FundingRequestDestinationCreateInput = {
  account: Scalars['String']
  amount: Scalars['String']
  list: Scalars['ID']
}

export type FundingRequestDestinationEdge = {
  __typename: 'FundingRequestDestinationEdge'
  cursor: Scalars['String']
  node: FundingRequestDestination
}

export enum FundingRequestDestinationOrderByInput {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ListAsc = 'list_ASC',
  ListDesc = 'list_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type FundingRequestDestinationUpdateInput = {
  account?: InputMaybe<Scalars['String']>
  amount?: InputMaybe<Scalars['String']>
  list?: InputMaybe<Scalars['ID']>
}

export type FundingRequestDestinationWhereInput = {
  AND?: InputMaybe<Array<FundingRequestDestinationWhereInput>>
  NOT?: InputMaybe<Array<FundingRequestDestinationWhereInput>>
  OR?: InputMaybe<Array<FundingRequestDestinationWhereInput>>
  account_contains?: InputMaybe<Scalars['String']>
  account_endsWith?: InputMaybe<Scalars['String']>
  account_eq?: InputMaybe<Scalars['String']>
  account_in?: InputMaybe<Array<Scalars['String']>>
  account_startsWith?: InputMaybe<Scalars['String']>
  amount_eq?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  list?: InputMaybe<FundingRequestDestinationsListWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type FundingRequestDestinationWhereUniqueInput = {
  id: Scalars['ID']
}

export type FundingRequestDestinationsList = BaseGraphQlObject & {
  __typename: 'FundingRequestDestinationsList'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  destinations: Array<FundingRequestDestination>
  id: Scalars['ID']
  phantom?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type FundingRequestDestinationsListConnection = {
  __typename: 'FundingRequestDestinationsListConnection'
  edges: Array<FundingRequestDestinationsListEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type FundingRequestDestinationsListCreateInput = {
  phantom?: InputMaybe<Scalars['Float']>
}

export type FundingRequestDestinationsListEdge = {
  __typename: 'FundingRequestDestinationsListEdge'
  cursor: Scalars['String']
  node: FundingRequestDestinationsList
}

export enum FundingRequestDestinationsListOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  PhantomAsc = 'phantom_ASC',
  PhantomDesc = 'phantom_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type FundingRequestDestinationsListUpdateInput = {
  phantom?: InputMaybe<Scalars['Float']>
}

export type FundingRequestDestinationsListWhereInput = {
  AND?: InputMaybe<Array<FundingRequestDestinationsListWhereInput>>
  NOT?: InputMaybe<Array<FundingRequestDestinationsListWhereInput>>
  OR?: InputMaybe<Array<FundingRequestDestinationsListWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  destinations_every?: InputMaybe<FundingRequestDestinationWhereInput>
  destinations_none?: InputMaybe<FundingRequestDestinationWhereInput>
  destinations_some?: InputMaybe<FundingRequestDestinationWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  phantom_eq?: InputMaybe<Scalars['Int']>
  phantom_gt?: InputMaybe<Scalars['Int']>
  phantom_gte?: InputMaybe<Scalars['Int']>
  phantom_in?: InputMaybe<Array<Scalars['Int']>>
  phantom_lt?: InputMaybe<Scalars['Int']>
  phantom_lte?: InputMaybe<Scalars['Int']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type FundingRequestDestinationsListWhereUniqueInput = {
  id: Scalars['ID']
}

export type FundingRequestProposalDetails = {
  __typename: 'FundingRequestProposalDetails'
  /** Related list of funding request destinations */
  destinationsList?: Maybe<FundingRequestDestinationsList>
}

export type GeneralSubscription = {
  __typename: 'GeneralSubscription'
  id?: Maybe<Scalars['Int']>
  kind: GeneralSubscriptionKind
  shouldNotify: Scalars['Boolean']
  shouldNotifyByEmail: Scalars['Boolean']
}

export type GeneralSubscriptionInput = {
  kind: GeneralSubscriptionKind
  shouldNotify?: InputMaybe<Scalars['Boolean']>
  shouldNotifyByEmail?: InputMaybe<Scalars['Boolean']>
}

export enum GeneralSubscriptionKind {
  ElectionAnnouncingStarted = 'ELECTION_ANNOUNCING_STARTED',
  ElectionRevealingStarted = 'ELECTION_REVEALING_STARTED',
  ElectionVotingStarted = 'ELECTION_VOTING_STARTED',
  ForumPostAll = 'FORUM_POST_ALL',
  ForumPostMention = 'FORUM_POST_MENTION',
  ForumPostReply = 'FORUM_POST_REPLY',
  ForumThreadAll = 'FORUM_THREAD_ALL',
  ForumThreadContributor = 'FORUM_THREAD_CONTRIBUTOR',
  ForumThreadCreator = 'FORUM_THREAD_CREATOR',
  ForumThreadMention = 'FORUM_THREAD_MENTION',
}

export type GeoCoordinates = BaseGraphQlObject & {
  __typename: 'GeoCoordinates'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  nodelocationmetadatacoordinates?: Maybe<Array<NodeLocationMetadata>>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type GeoCoordinatesConnection = {
  __typename: 'GeoCoordinatesConnection'
  edges: Array<GeoCoordinatesEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type GeoCoordinatesCreateInput = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type GeoCoordinatesEdge = {
  __typename: 'GeoCoordinatesEdge'
  cursor: Scalars['String']
  node: GeoCoordinates
}

export enum GeoCoordinatesOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  LatitudeAsc = 'latitude_ASC',
  LatitudeDesc = 'latitude_DESC',
  LongitudeAsc = 'longitude_ASC',
  LongitudeDesc = 'longitude_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type GeoCoordinatesUpdateInput = {
  latitude?: InputMaybe<Scalars['Float']>
  longitude?: InputMaybe<Scalars['Float']>
}

export type GeoCoordinatesWhereInput = {
  AND?: InputMaybe<Array<GeoCoordinatesWhereInput>>
  NOT?: InputMaybe<Array<GeoCoordinatesWhereInput>>
  OR?: InputMaybe<Array<GeoCoordinatesWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  latitude_eq?: InputMaybe<Scalars['Float']>
  latitude_gt?: InputMaybe<Scalars['Float']>
  latitude_gte?: InputMaybe<Scalars['Float']>
  latitude_in?: InputMaybe<Array<Scalars['Float']>>
  latitude_lt?: InputMaybe<Scalars['Float']>
  latitude_lte?: InputMaybe<Scalars['Float']>
  longitude_eq?: InputMaybe<Scalars['Float']>
  longitude_gt?: InputMaybe<Scalars['Float']>
  longitude_gte?: InputMaybe<Scalars['Float']>
  longitude_in?: InputMaybe<Array<Scalars['Float']>>
  longitude_lt?: InputMaybe<Scalars['Float']>
  longitude_lte?: InputMaybe<Scalars['Float']>
  nodelocationmetadatacoordinates_every?: InputMaybe<NodeLocationMetadataWhereInput>
  nodelocationmetadatacoordinates_none?: InputMaybe<NodeLocationMetadataWhereInput>
  nodelocationmetadatacoordinates_some?: InputMaybe<NodeLocationMetadataWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type GeoCoordinatesWhereUniqueInput = {
  id: Scalars['ID']
}

export type GeographicalArea = GeographicalAreaContinent | GeographicalAreaCountry | GeographicalAreaSubdivistion

export type GeographicalAreaContinent = {
  __typename: 'GeographicalAreaContinent'
  code?: Maybe<Continent>
}

export type GeographicalAreaContinentCreateInput = {
  code?: InputMaybe<Continent>
}

export type GeographicalAreaContinentUpdateInput = {
  code?: InputMaybe<Continent>
}

export type GeographicalAreaContinentWhereInput = {
  AND?: InputMaybe<Array<GeographicalAreaContinentWhereInput>>
  NOT?: InputMaybe<Array<GeographicalAreaContinentWhereInput>>
  OR?: InputMaybe<Array<GeographicalAreaContinentWhereInput>>
  code_eq?: InputMaybe<Continent>
  code_in?: InputMaybe<Array<Continent>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type GeographicalAreaContinentWhereUniqueInput = {
  id: Scalars['ID']
}

export type GeographicalAreaCountry = {
  __typename: 'GeographicalAreaCountry'
  /** ISO 3166-1 alpha-2 country code */
  code?: Maybe<Scalars['String']>
}

export type GeographicalAreaSubdivistion = {
  __typename: 'GeographicalAreaSubdivistion'
  /** ISO 3166-2 subdivision code */
  code?: Maybe<Scalars['String']>
}

export type InitialInvitationBalanceUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'InitialInvitationBalanceUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** New initial invitation balance. */
    newInitialBalance: Scalars['BigInt']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type InitialInvitationBalanceUpdatedEventConnection = {
  __typename: 'InitialInvitationBalanceUpdatedEventConnection'
  edges: Array<InitialInvitationBalanceUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type InitialInvitationBalanceUpdatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newInitialBalance: Scalars['String']
}

export type InitialInvitationBalanceUpdatedEventEdge = {
  __typename: 'InitialInvitationBalanceUpdatedEventEdge'
  cursor: Scalars['String']
  node: InitialInvitationBalanceUpdatedEvent
}

export enum InitialInvitationBalanceUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewInitialBalanceAsc = 'newInitialBalance_ASC',
  NewInitialBalanceDesc = 'newInitialBalance_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type InitialInvitationBalanceUpdatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newInitialBalance?: InputMaybe<Scalars['String']>
}

export type InitialInvitationBalanceUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<InitialInvitationBalanceUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<InitialInvitationBalanceUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<InitialInvitationBalanceUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newInitialBalance_eq?: InputMaybe<Scalars['BigInt']>
  newInitialBalance_gt?: InputMaybe<Scalars['BigInt']>
  newInitialBalance_gte?: InputMaybe<Scalars['BigInt']>
  newInitialBalance_in?: InputMaybe<Array<Scalars['BigInt']>>
  newInitialBalance_lt?: InputMaybe<Scalars['BigInt']>
  newInitialBalance_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type InitialInvitationBalanceUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type InitialInvitationCountUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'InitialInvitationCountUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** New initial invitation count for members. */
    newInitialInvitationCount: Scalars['Int']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type InitialInvitationCountUpdatedEventConnection = {
  __typename: 'InitialInvitationCountUpdatedEventConnection'
  edges: Array<InitialInvitationCountUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type InitialInvitationCountUpdatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newInitialInvitationCount: Scalars['Float']
}

export type InitialInvitationCountUpdatedEventEdge = {
  __typename: 'InitialInvitationCountUpdatedEventEdge'
  cursor: Scalars['String']
  node: InitialInvitationCountUpdatedEvent
}

export enum InitialInvitationCountUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewInitialInvitationCountAsc = 'newInitialInvitationCount_ASC',
  NewInitialInvitationCountDesc = 'newInitialInvitationCount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type InitialInvitationCountUpdatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newInitialInvitationCount?: InputMaybe<Scalars['Float']>
}

export type InitialInvitationCountUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<InitialInvitationCountUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<InitialInvitationCountUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<InitialInvitationCountUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newInitialInvitationCount_eq?: InputMaybe<Scalars['Int']>
  newInitialInvitationCount_gt?: InputMaybe<Scalars['Int']>
  newInitialInvitationCount_gte?: InputMaybe<Scalars['Int']>
  newInitialInvitationCount_in?: InputMaybe<Array<Scalars['Int']>>
  newInitialInvitationCount_lt?: InputMaybe<Scalars['Int']>
  newInitialInvitationCount_lte?: InputMaybe<Scalars['Int']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type InitialInvitationCountUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type InvalidActionMetadata = {
  __typename: 'InvalidActionMetadata'
  /** Reason why the action metadata was considered invalid */
  reason: Scalars['String']
}

export type InvitesTransferredEvent = BaseGraphQlObject &
  Event & {
    __typename: 'InvitesTransferredEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Number of invites transferred. */
    numberOfInvites: Scalars['Int']
    sourceMember: Membership
    sourceMemberId: Scalars['String']
    targetMember: Membership
    targetMemberId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type InvitesTransferredEventConnection = {
  __typename: 'InvitesTransferredEventConnection'
  edges: Array<InvitesTransferredEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type InvitesTransferredEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  numberOfInvites: Scalars['Float']
  sourceMember: Scalars['ID']
  targetMember: Scalars['ID']
}

export type InvitesTransferredEventEdge = {
  __typename: 'InvitesTransferredEventEdge'
  cursor: Scalars['String']
  node: InvitesTransferredEvent
}

export enum InvitesTransferredEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NumberOfInvitesAsc = 'numberOfInvites_ASC',
  NumberOfInvitesDesc = 'numberOfInvites_DESC',
  SourceMemberAsc = 'sourceMember_ASC',
  SourceMemberDesc = 'sourceMember_DESC',
  TargetMemberAsc = 'targetMember_ASC',
  TargetMemberDesc = 'targetMember_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type InvitesTransferredEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  numberOfInvites?: InputMaybe<Scalars['Float']>
  sourceMember?: InputMaybe<Scalars['ID']>
  targetMember?: InputMaybe<Scalars['ID']>
}

export type InvitesTransferredEventWhereInput = {
  AND?: InputMaybe<Array<InvitesTransferredEventWhereInput>>
  NOT?: InputMaybe<Array<InvitesTransferredEventWhereInput>>
  OR?: InputMaybe<Array<InvitesTransferredEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  numberOfInvites_eq?: InputMaybe<Scalars['Int']>
  numberOfInvites_gt?: InputMaybe<Scalars['Int']>
  numberOfInvites_gte?: InputMaybe<Scalars['Int']>
  numberOfInvites_in?: InputMaybe<Array<Scalars['Int']>>
  numberOfInvites_lt?: InputMaybe<Scalars['Int']>
  numberOfInvites_lte?: InputMaybe<Scalars['Int']>
  sourceMember?: InputMaybe<MembershipWhereInput>
  targetMember?: InputMaybe<MembershipWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type InvitesTransferredEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type Language = BaseGraphQlObject & {
  __typename: 'Language'
  channellanguage?: Maybe<Array<Channel>>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  createdInBlock: Scalars['Int']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Language identifier ISO 639-1 */
  iso: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  videolanguage?: Maybe<Array<Video>>
  videosubtitlelanguage?: Maybe<Array<VideoSubtitle>>
}

export type LanguageConnection = {
  __typename: 'LanguageConnection'
  edges: Array<LanguageEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type LanguageCreateInput = {
  createdInBlock: Scalars['Float']
  iso: Scalars['String']
}

export type LanguageEdge = {
  __typename: 'LanguageEdge'
  cursor: Scalars['String']
  node: Language
}

export enum LanguageOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CreatedInBlockAsc = 'createdInBlock_ASC',
  CreatedInBlockDesc = 'createdInBlock_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IsoAsc = 'iso_ASC',
  IsoDesc = 'iso_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type LanguageUpdateInput = {
  createdInBlock?: InputMaybe<Scalars['Float']>
  iso?: InputMaybe<Scalars['String']>
}

export type LanguageWhereInput = {
  AND?: InputMaybe<Array<LanguageWhereInput>>
  NOT?: InputMaybe<Array<LanguageWhereInput>>
  OR?: InputMaybe<Array<LanguageWhereInput>>
  channellanguage_every?: InputMaybe<ChannelWhereInput>
  channellanguage_none?: InputMaybe<ChannelWhereInput>
  channellanguage_some?: InputMaybe<ChannelWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  createdInBlock_eq?: InputMaybe<Scalars['Int']>
  createdInBlock_gt?: InputMaybe<Scalars['Int']>
  createdInBlock_gte?: InputMaybe<Scalars['Int']>
  createdInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  createdInBlock_lt?: InputMaybe<Scalars['Int']>
  createdInBlock_lte?: InputMaybe<Scalars['Int']>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  iso_contains?: InputMaybe<Scalars['String']>
  iso_endsWith?: InputMaybe<Scalars['String']>
  iso_eq?: InputMaybe<Scalars['String']>
  iso_in?: InputMaybe<Array<Scalars['String']>>
  iso_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  videolanguage_every?: InputMaybe<VideoWhereInput>
  videolanguage_none?: InputMaybe<VideoWhereInput>
  videolanguage_some?: InputMaybe<VideoWhereInput>
  videosubtitlelanguage_every?: InputMaybe<VideoSubtitleWhereInput>
  videosubtitlelanguage_none?: InputMaybe<VideoSubtitleWhereInput>
  videosubtitlelanguage_some?: InputMaybe<VideoSubtitleWhereInput>
}

export type LanguageWhereUniqueInput = {
  id: Scalars['ID']
}

export type LeaderInvitationQuotaUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'LeaderInvitationQuotaUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** New quota. */
    newInvitationQuota: Scalars['Int']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type LeaderInvitationQuotaUpdatedEventConnection = {
  __typename: 'LeaderInvitationQuotaUpdatedEventConnection'
  edges: Array<LeaderInvitationQuotaUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type LeaderInvitationQuotaUpdatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newInvitationQuota: Scalars['Float']
}

export type LeaderInvitationQuotaUpdatedEventEdge = {
  __typename: 'LeaderInvitationQuotaUpdatedEventEdge'
  cursor: Scalars['String']
  node: LeaderInvitationQuotaUpdatedEvent
}

export enum LeaderInvitationQuotaUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewInvitationQuotaAsc = 'newInvitationQuota_ASC',
  NewInvitationQuotaDesc = 'newInvitationQuota_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type LeaderInvitationQuotaUpdatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newInvitationQuota?: InputMaybe<Scalars['Float']>
}

export type LeaderInvitationQuotaUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<LeaderInvitationQuotaUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<LeaderInvitationQuotaUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<LeaderInvitationQuotaUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newInvitationQuota_eq?: InputMaybe<Scalars['Int']>
  newInvitationQuota_gt?: InputMaybe<Scalars['Int']>
  newInvitationQuota_gte?: InputMaybe<Scalars['Int']>
  newInvitationQuota_in?: InputMaybe<Array<Scalars['Int']>>
  newInvitationQuota_lt?: InputMaybe<Scalars['Int']>
  newInvitationQuota_lte?: InputMaybe<Scalars['Int']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type LeaderInvitationQuotaUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type LeaderSetEvent = BaseGraphQlObject &
  Event & {
    __typename: 'LeaderSetEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    worker?: Maybe<Worker>
    workerId?: Maybe<Scalars['String']>
  }

export type LeaderSetEventConnection = {
  __typename: 'LeaderSetEventConnection'
  edges: Array<LeaderSetEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type LeaderSetEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  worker?: InputMaybe<Scalars['ID']>
}

export type LeaderSetEventEdge = {
  __typename: 'LeaderSetEventEdge'
  cursor: Scalars['String']
  node: LeaderSetEvent
}

export enum LeaderSetEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type LeaderSetEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  worker?: InputMaybe<Scalars['ID']>
}

export type LeaderSetEventWhereInput = {
  AND?: InputMaybe<Array<LeaderSetEventWhereInput>>
  NOT?: InputMaybe<Array<LeaderSetEventWhereInput>>
  OR?: InputMaybe<Array<LeaderSetEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  worker?: InputMaybe<WorkerWhereInput>
}

export type LeaderSetEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type LeaderUnsetEvent = BaseGraphQlObject &
  Event & {
    __typename: 'LeaderUnsetEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    leader: Worker
    leaderId: Scalars['String']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type LeaderUnsetEventConnection = {
  __typename: 'LeaderUnsetEventConnection'
  edges: Array<LeaderUnsetEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type LeaderUnsetEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  leader: Scalars['ID']
  network: Network
}

export type LeaderUnsetEventEdge = {
  __typename: 'LeaderUnsetEventEdge'
  cursor: Scalars['String']
  node: LeaderUnsetEvent
}

export enum LeaderUnsetEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  LeaderAsc = 'leader_ASC',
  LeaderDesc = 'leader_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type LeaderUnsetEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  leader?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
}

export type LeaderUnsetEventWhereInput = {
  AND?: InputMaybe<Array<LeaderUnsetEventWhereInput>>
  NOT?: InputMaybe<Array<LeaderUnsetEventWhereInput>>
  OR?: InputMaybe<Array<LeaderUnsetEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  leader?: InputMaybe<WorkerWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type LeaderUnsetEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type License = BaseGraphQlObject & {
  __typename: 'License'
  /** Attribution (if required by the license) */
  attribution?: Maybe<Scalars['String']>
  /** License code defined by Joystream */
  code?: Maybe<Scalars['Int']>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  /** Custom license content */
  customText?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  videolicense?: Maybe<Array<Video>>
}

export type LicenseConnection = {
  __typename: 'LicenseConnection'
  edges: Array<LicenseEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type LicenseCreateInput = {
  attribution?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['Float']>
  customText?: InputMaybe<Scalars['String']>
}

export type LicenseEdge = {
  __typename: 'LicenseEdge'
  cursor: Scalars['String']
  node: License
}

export enum LicenseOrderByInput {
  AttributionAsc = 'attribution_ASC',
  AttributionDesc = 'attribution_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CustomTextAsc = 'customText_ASC',
  CustomTextDesc = 'customText_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type LicenseUpdateInput = {
  attribution?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['Float']>
  customText?: InputMaybe<Scalars['String']>
}

export type LicenseWhereInput = {
  AND?: InputMaybe<Array<LicenseWhereInput>>
  NOT?: InputMaybe<Array<LicenseWhereInput>>
  OR?: InputMaybe<Array<LicenseWhereInput>>
  attribution_contains?: InputMaybe<Scalars['String']>
  attribution_endsWith?: InputMaybe<Scalars['String']>
  attribution_eq?: InputMaybe<Scalars['String']>
  attribution_in?: InputMaybe<Array<Scalars['String']>>
  attribution_startsWith?: InputMaybe<Scalars['String']>
  code_eq?: InputMaybe<Scalars['Int']>
  code_gt?: InputMaybe<Scalars['Int']>
  code_gte?: InputMaybe<Scalars['Int']>
  code_in?: InputMaybe<Array<Scalars['Int']>>
  code_lt?: InputMaybe<Scalars['Int']>
  code_lte?: InputMaybe<Scalars['Int']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  customText_contains?: InputMaybe<Scalars['String']>
  customText_endsWith?: InputMaybe<Scalars['String']>
  customText_eq?: InputMaybe<Scalars['String']>
  customText_in?: InputMaybe<Array<Scalars['String']>>
  customText_startsWith?: InputMaybe<Scalars['String']>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  videolicense_every?: InputMaybe<VideoWhereInput>
  videolicense_none?: InputMaybe<VideoWhereInput>
  videolicense_some?: InputMaybe<VideoWhereInput>
}

export type LicenseWhereUniqueInput = {
  id: Scalars['ID']
}

export type Member = {
  __typename: 'Member'
  email?: Maybe<Scalars['String']>
  id: Scalars['Int']
  name: Scalars['String']
  receiveEmails: Scalars['Boolean']
  unverifiedEmail?: Maybe<Scalars['String']>
}

export type MemberAccountsUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'MemberAccountsUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    member: Membership
    memberId: Scalars['String']
    /** Network the block was produced in */
    network: Network
    /** New member controller in SS58 encoding. Null means no new value was provided. */
    newControllerAccount?: Maybe<Scalars['String']>
    /** New member root account in SS58 encoding. Null means no new value was provided. */
    newRootAccount?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type MemberAccountsUpdatedEventConnection = {
  __typename: 'MemberAccountsUpdatedEventConnection'
  edges: Array<MemberAccountsUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type MemberAccountsUpdatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  member: Scalars['ID']
  network: Network
  newControllerAccount?: InputMaybe<Scalars['String']>
  newRootAccount?: InputMaybe<Scalars['String']>
}

export type MemberAccountsUpdatedEventEdge = {
  __typename: 'MemberAccountsUpdatedEventEdge'
  cursor: Scalars['String']
  node: MemberAccountsUpdatedEvent
}

export enum MemberAccountsUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewControllerAccountAsc = 'newControllerAccount_ASC',
  NewControllerAccountDesc = 'newControllerAccount_DESC',
  NewRootAccountAsc = 'newRootAccount_ASC',
  NewRootAccountDesc = 'newRootAccount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MemberAccountsUpdatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  member?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
  newControllerAccount?: InputMaybe<Scalars['String']>
  newRootAccount?: InputMaybe<Scalars['String']>
}

export type MemberAccountsUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<MemberAccountsUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<MemberAccountsUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<MemberAccountsUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  member?: InputMaybe<MembershipWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newControllerAccount_contains?: InputMaybe<Scalars['String']>
  newControllerAccount_endsWith?: InputMaybe<Scalars['String']>
  newControllerAccount_eq?: InputMaybe<Scalars['String']>
  newControllerAccount_in?: InputMaybe<Array<Scalars['String']>>
  newControllerAccount_startsWith?: InputMaybe<Scalars['String']>
  newRootAccount_contains?: InputMaybe<Scalars['String']>
  newRootAccount_endsWith?: InputMaybe<Scalars['String']>
  newRootAccount_eq?: InputMaybe<Scalars['String']>
  newRootAccount_in?: InputMaybe<Array<Scalars['String']>>
  newRootAccount_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type MemberAccountsUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type MemberBannedFromChannelEvent = BaseGraphQlObject &
  Event & {
    __typename: 'MemberBannedFromChannelEvent'
    /** The action performed. true if member is banned, false if member is unbanned */
    action: Scalars['Boolean']
    channel: Channel
    channelId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    member: Membership
    memberId: Scalars['String']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type MemberBannedFromChannelEventConnection = {
  __typename: 'MemberBannedFromChannelEventConnection'
  edges: Array<MemberBannedFromChannelEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type MemberBannedFromChannelEventCreateInput = {
  action: Scalars['Boolean']
  channel: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  member: Scalars['ID']
  network: Network
}

export type MemberBannedFromChannelEventEdge = {
  __typename: 'MemberBannedFromChannelEventEdge'
  cursor: Scalars['String']
  node: MemberBannedFromChannelEvent
}

export enum MemberBannedFromChannelEventOrderByInput {
  ActionAsc = 'action_ASC',
  ActionDesc = 'action_DESC',
  ChannelAsc = 'channel_ASC',
  ChannelDesc = 'channel_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MemberBannedFromChannelEventUpdateInput = {
  action?: InputMaybe<Scalars['Boolean']>
  channel?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  member?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
}

export type MemberBannedFromChannelEventWhereInput = {
  AND?: InputMaybe<Array<MemberBannedFromChannelEventWhereInput>>
  NOT?: InputMaybe<Array<MemberBannedFromChannelEventWhereInput>>
  OR?: InputMaybe<Array<MemberBannedFromChannelEventWhereInput>>
  action_eq?: InputMaybe<Scalars['Boolean']>
  action_in?: InputMaybe<Array<Scalars['Boolean']>>
  channel?: InputMaybe<ChannelWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  member?: InputMaybe<MembershipWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type MemberBannedFromChannelEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type MemberCreatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'MemberCreatedEvent'
    /** New member controller in SS58 encoding. */
    controllerAccount: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    /** New member handle. */
    handle: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Whether member is founding member. */
    isFoundingMember: Scalars['Boolean']
    metadata: MemberMetadata
    metadataId: Scalars['String']
    /** Network the block was produced in */
    network: Network
    newMember: Membership
    newMemberId: Scalars['String']
    /** New member root account in SS58 encoding. */
    rootAccount: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type MemberCreatedEventConnection = {
  __typename: 'MemberCreatedEventConnection'
  edges: Array<MemberCreatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type MemberCreatedEventCreateInput = {
  controllerAccount: Scalars['String']
  handle: Scalars['String']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  isFoundingMember: Scalars['Boolean']
  metadata: Scalars['ID']
  network: Network
  newMember: Scalars['ID']
  rootAccount: Scalars['String']
}

export type MemberCreatedEventEdge = {
  __typename: 'MemberCreatedEventEdge'
  cursor: Scalars['String']
  node: MemberCreatedEvent
}

export enum MemberCreatedEventOrderByInput {
  ControllerAccountAsc = 'controllerAccount_ASC',
  ControllerAccountDesc = 'controllerAccount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  IsFoundingMemberAsc = 'isFoundingMember_ASC',
  IsFoundingMemberDesc = 'isFoundingMember_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewMemberAsc = 'newMember_ASC',
  NewMemberDesc = 'newMember_DESC',
  RootAccountAsc = 'rootAccount_ASC',
  RootAccountDesc = 'rootAccount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MemberCreatedEventUpdateInput = {
  controllerAccount?: InputMaybe<Scalars['String']>
  handle?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  isFoundingMember?: InputMaybe<Scalars['Boolean']>
  metadata?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
  newMember?: InputMaybe<Scalars['ID']>
  rootAccount?: InputMaybe<Scalars['String']>
}

export type MemberCreatedEventWhereInput = {
  AND?: InputMaybe<Array<MemberCreatedEventWhereInput>>
  NOT?: InputMaybe<Array<MemberCreatedEventWhereInput>>
  OR?: InputMaybe<Array<MemberCreatedEventWhereInput>>
  controllerAccount_contains?: InputMaybe<Scalars['String']>
  controllerAccount_endsWith?: InputMaybe<Scalars['String']>
  controllerAccount_eq?: InputMaybe<Scalars['String']>
  controllerAccount_in?: InputMaybe<Array<Scalars['String']>>
  controllerAccount_startsWith?: InputMaybe<Scalars['String']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  handle_contains?: InputMaybe<Scalars['String']>
  handle_endsWith?: InputMaybe<Scalars['String']>
  handle_eq?: InputMaybe<Scalars['String']>
  handle_in?: InputMaybe<Array<Scalars['String']>>
  handle_startsWith?: InputMaybe<Scalars['String']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  isFoundingMember_eq?: InputMaybe<Scalars['Boolean']>
  isFoundingMember_in?: InputMaybe<Array<Scalars['Boolean']>>
  metadata?: InputMaybe<MemberMetadataWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newMember?: InputMaybe<MembershipWhereInput>
  rootAccount_contains?: InputMaybe<Scalars['String']>
  rootAccount_endsWith?: InputMaybe<Scalars['String']>
  rootAccount_eq?: InputMaybe<Scalars['String']>
  rootAccount_in?: InputMaybe<Array<Scalars['String']>>
  rootAccount_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type MemberCreatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type MemberInvitedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'MemberInvitedEvent'
    /** New member controller in SS58 encoding. */
    controllerAccount: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    /** New member handle. */
    handle: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    invitingMember: Membership
    invitingMemberId: Scalars['String']
    metadata: MemberMetadata
    metadataId: Scalars['String']
    /** Network the block was produced in */
    network: Network
    newMember: Membership
    newMemberId: Scalars['String']
    /** New member root account in SS58 encoding. */
    rootAccount: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type MemberInvitedEventConnection = {
  __typename: 'MemberInvitedEventConnection'
  edges: Array<MemberInvitedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type MemberInvitedEventCreateInput = {
  controllerAccount: Scalars['String']
  handle: Scalars['String']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  invitingMember: Scalars['ID']
  metadata: Scalars['ID']
  network: Network
  newMember: Scalars['ID']
  rootAccount: Scalars['String']
}

export type MemberInvitedEventEdge = {
  __typename: 'MemberInvitedEventEdge'
  cursor: Scalars['String']
  node: MemberInvitedEvent
}

export enum MemberInvitedEventOrderByInput {
  ControllerAccountAsc = 'controllerAccount_ASC',
  ControllerAccountDesc = 'controllerAccount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  InvitingMemberAsc = 'invitingMember_ASC',
  InvitingMemberDesc = 'invitingMember_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewMemberAsc = 'newMember_ASC',
  NewMemberDesc = 'newMember_DESC',
  RootAccountAsc = 'rootAccount_ASC',
  RootAccountDesc = 'rootAccount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MemberInvitedEventUpdateInput = {
  controllerAccount?: InputMaybe<Scalars['String']>
  handle?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  invitingMember?: InputMaybe<Scalars['ID']>
  metadata?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
  newMember?: InputMaybe<Scalars['ID']>
  rootAccount?: InputMaybe<Scalars['String']>
}

export type MemberInvitedEventWhereInput = {
  AND?: InputMaybe<Array<MemberInvitedEventWhereInput>>
  NOT?: InputMaybe<Array<MemberInvitedEventWhereInput>>
  OR?: InputMaybe<Array<MemberInvitedEventWhereInput>>
  controllerAccount_contains?: InputMaybe<Scalars['String']>
  controllerAccount_endsWith?: InputMaybe<Scalars['String']>
  controllerAccount_eq?: InputMaybe<Scalars['String']>
  controllerAccount_in?: InputMaybe<Array<Scalars['String']>>
  controllerAccount_startsWith?: InputMaybe<Scalars['String']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  handle_contains?: InputMaybe<Scalars['String']>
  handle_endsWith?: InputMaybe<Scalars['String']>
  handle_eq?: InputMaybe<Scalars['String']>
  handle_in?: InputMaybe<Array<Scalars['String']>>
  handle_startsWith?: InputMaybe<Scalars['String']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  invitingMember?: InputMaybe<MembershipWhereInput>
  metadata?: InputMaybe<MemberMetadataWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newMember?: InputMaybe<MembershipWhereInput>
  rootAccount_contains?: InputMaybe<Scalars['String']>
  rootAccount_endsWith?: InputMaybe<Scalars['String']>
  rootAccount_eq?: InputMaybe<Scalars['String']>
  rootAccount_in?: InputMaybe<Array<Scalars['String']>>
  rootAccount_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type MemberInvitedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type MemberMetadata = BaseGraphQlObject & {
  __typename: 'MemberMetadata'
  /** Short text chosen by member to share information about themselves */
  about?: Maybe<Scalars['String']>
  /** Avatar data object */
  avatar?: Maybe<Avatar>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  externalResources?: Maybe<Array<MembershipExternalResource>>
  id: Scalars['ID']
  isVerifiedValidator?: Maybe<Scalars['Boolean']>
  member?: Maybe<Membership>
  membercreatedeventmetadata?: Maybe<Array<MemberCreatedEvent>>
  memberinvitedeventmetadata?: Maybe<Array<MemberInvitedEvent>>
  memberprofileupdatedeventnewMetadata?: Maybe<Array<MemberProfileUpdatedEvent>>
  membershipboughteventmetadata?: Maybe<Array<MembershipBoughtEvent>>
  membershipgiftedeventmetadata?: Maybe<Array<MembershipGiftedEvent>>
  /** Member's name */
  name?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type MemberMetadataConnection = {
  __typename: 'MemberMetadataConnection'
  edges: Array<MemberMetadataEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type MemberMetadataCreateInput = {
  about?: InputMaybe<Scalars['String']>
  avatar: Scalars['JSONObject']
  isVerifiedValidator?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
}

export type MemberMetadataEdge = {
  __typename: 'MemberMetadataEdge'
  cursor: Scalars['String']
  node: MemberMetadata
}

export enum MemberMetadataOrderByInput {
  AboutAsc = 'about_ASC',
  AboutDesc = 'about_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IsVerifiedValidatorAsc = 'isVerifiedValidator_ASC',
  IsVerifiedValidatorDesc = 'isVerifiedValidator_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MemberMetadataUpdateInput = {
  about?: InputMaybe<Scalars['String']>
  avatar?: InputMaybe<Scalars['JSONObject']>
  isVerifiedValidator?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
}

export type MemberMetadataWhereInput = {
  AND?: InputMaybe<Array<MemberMetadataWhereInput>>
  NOT?: InputMaybe<Array<MemberMetadataWhereInput>>
  OR?: InputMaybe<Array<MemberMetadataWhereInput>>
  about_contains?: InputMaybe<Scalars['String']>
  about_endsWith?: InputMaybe<Scalars['String']>
  about_eq?: InputMaybe<Scalars['String']>
  about_in?: InputMaybe<Array<Scalars['String']>>
  about_startsWith?: InputMaybe<Scalars['String']>
  avatar_json?: InputMaybe<Scalars['JSONObject']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  externalResources_every?: InputMaybe<MembershipExternalResourceWhereInput>
  externalResources_none?: InputMaybe<MembershipExternalResourceWhereInput>
  externalResources_some?: InputMaybe<MembershipExternalResourceWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  isVerifiedValidator_eq?: InputMaybe<Scalars['Boolean']>
  isVerifiedValidator_in?: InputMaybe<Array<Scalars['Boolean']>>
  member?: InputMaybe<MembershipWhereInput>
  membercreatedeventmetadata_every?: InputMaybe<MemberCreatedEventWhereInput>
  membercreatedeventmetadata_none?: InputMaybe<MemberCreatedEventWhereInput>
  membercreatedeventmetadata_some?: InputMaybe<MemberCreatedEventWhereInput>
  memberinvitedeventmetadata_every?: InputMaybe<MemberInvitedEventWhereInput>
  memberinvitedeventmetadata_none?: InputMaybe<MemberInvitedEventWhereInput>
  memberinvitedeventmetadata_some?: InputMaybe<MemberInvitedEventWhereInput>
  memberprofileupdatedeventnewMetadata_every?: InputMaybe<MemberProfileUpdatedEventWhereInput>
  memberprofileupdatedeventnewMetadata_none?: InputMaybe<MemberProfileUpdatedEventWhereInput>
  memberprofileupdatedeventnewMetadata_some?: InputMaybe<MemberProfileUpdatedEventWhereInput>
  membershipboughteventmetadata_every?: InputMaybe<MembershipBoughtEventWhereInput>
  membershipboughteventmetadata_none?: InputMaybe<MembershipBoughtEventWhereInput>
  membershipboughteventmetadata_some?: InputMaybe<MembershipBoughtEventWhereInput>
  membershipgiftedeventmetadata_every?: InputMaybe<MembershipGiftedEventWhereInput>
  membershipgiftedeventmetadata_none?: InputMaybe<MembershipGiftedEventWhereInput>
  membershipgiftedeventmetadata_some?: InputMaybe<MembershipGiftedEventWhereInput>
  name_contains?: InputMaybe<Scalars['String']>
  name_endsWith?: InputMaybe<Scalars['String']>
  name_eq?: InputMaybe<Scalars['String']>
  name_in?: InputMaybe<Array<Scalars['String']>>
  name_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type MemberMetadataWhereUniqueInput = {
  id: Scalars['ID']
}

export type MemberProfileUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'MemberProfileUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    member: Membership
    memberId: Scalars['String']
    /** Network the block was produced in */
    network: Network
    /** New member handle. Null means no new value was provided. */
    newHandle?: Maybe<Scalars['String']>
    newMetadata: MemberMetadata
    newMetadataId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type MemberProfileUpdatedEventConnection = {
  __typename: 'MemberProfileUpdatedEventConnection'
  edges: Array<MemberProfileUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type MemberProfileUpdatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  member: Scalars['ID']
  network: Network
  newHandle?: InputMaybe<Scalars['String']>
  newMetadata: Scalars['ID']
}

export type MemberProfileUpdatedEventEdge = {
  __typename: 'MemberProfileUpdatedEventEdge'
  cursor: Scalars['String']
  node: MemberProfileUpdatedEvent
}

export enum MemberProfileUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewHandleAsc = 'newHandle_ASC',
  NewHandleDesc = 'newHandle_DESC',
  NewMetadataAsc = 'newMetadata_ASC',
  NewMetadataDesc = 'newMetadata_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MemberProfileUpdatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  member?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
  newHandle?: InputMaybe<Scalars['String']>
  newMetadata?: InputMaybe<Scalars['ID']>
}

export type MemberProfileUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<MemberProfileUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<MemberProfileUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<MemberProfileUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  member?: InputMaybe<MembershipWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newHandle_contains?: InputMaybe<Scalars['String']>
  newHandle_endsWith?: InputMaybe<Scalars['String']>
  newHandle_eq?: InputMaybe<Scalars['String']>
  newHandle_in?: InputMaybe<Array<Scalars['String']>>
  newHandle_startsWith?: InputMaybe<Scalars['String']>
  newMetadata?: InputMaybe<MemberMetadataWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type MemberProfileUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type MemberVerificationStatusUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'MemberVerificationStatusUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** New status. */
    isVerified: Scalars['Boolean']
    member: Membership
    memberId: Scalars['String']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    worker: Worker
    workerId: Scalars['String']
  }

export type MemberVerificationStatusUpdatedEventConnection = {
  __typename: 'MemberVerificationStatusUpdatedEventConnection'
  edges: Array<MemberVerificationStatusUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type MemberVerificationStatusUpdatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  isVerified: Scalars['Boolean']
  member: Scalars['ID']
  network: Network
  worker: Scalars['ID']
}

export type MemberVerificationStatusUpdatedEventEdge = {
  __typename: 'MemberVerificationStatusUpdatedEventEdge'
  cursor: Scalars['String']
  node: MemberVerificationStatusUpdatedEvent
}

export enum MemberVerificationStatusUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  IsVerifiedAsc = 'isVerified_ASC',
  IsVerifiedDesc = 'isVerified_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type MemberVerificationStatusUpdatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  isVerified?: InputMaybe<Scalars['Boolean']>
  member?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
  worker?: InputMaybe<Scalars['ID']>
}

export type MemberVerificationStatusUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<MemberVerificationStatusUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<MemberVerificationStatusUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<MemberVerificationStatusUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  isVerified_eq?: InputMaybe<Scalars['Boolean']>
  isVerified_in?: InputMaybe<Array<Scalars['Boolean']>>
  member?: InputMaybe<MembershipWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  worker?: InputMaybe<WorkerWhereInput>
}

export type MemberVerificationStatusUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type MembersByHandleFtsOutput = {
  __typename: 'MembersByHandleFTSOutput'
  highlight: Scalars['String']
  isTypeOf: Scalars['String']
  item: MembersByHandleSearchResult
  rank: Scalars['Float']
}

export type MembersByHandleSearchResult = Membership

/** Stored information about a registered user */
export type Membership = BaseGraphQlObject & {
  __typename: 'Membership'
  auctionbidcanceledeventmember?: Maybe<Array<AuctionBidCanceledEvent>>
  auctionbidcanceledeventownerMember?: Maybe<Array<AuctionBidCanceledEvent>>
  auctionbidmadeeventmember?: Maybe<Array<AuctionBidMadeEvent>>
  auctionbidmadeeventownerMember?: Maybe<Array<AuctionBidMadeEvent>>
  auctionbidmadeeventpreviousTopBidder?: Maybe<Array<AuctionBidMadeEvent>>
  auctioncanceledeventownerMember?: Maybe<Array<AuctionCanceledEvent>>
  auctioninitialOwner?: Maybe<Array<Auction>>
  auctionwinningMember?: Maybe<Array<Auction>>
  bidbidder?: Maybe<Array<Bid>>
  bidmadecompletingauctioneventmember?: Maybe<Array<BidMadeCompletingAuctionEvent>>
  bidmadecompletingauctioneventownerMember?: Maybe<Array<BidMadeCompletingAuctionEvent>>
  bidmadecompletingauctioneventpreviousTopBidder?: Maybe<Array<BidMadeCompletingAuctionEvent>>
  /** Staking accounts bounded to membership. */
  boundAccounts: Array<Scalars['String']>
  bountycontributioncontributor?: Maybe<Array<BountyContribution>>
  bountycreator?: Maybe<Array<Bounty>>
  bountyentryworker?: Maybe<Array<BountyEntry>>
  bountyoracle?: Maybe<Array<Bounty>>
  buynowcanceledeventownerMember?: Maybe<Array<BuyNowCanceledEvent>>
  buynowpriceupdatedeventownerMember?: Maybe<Array<BuyNowPriceUpdatedEvent>>
  channelpaymentmadeeventpayer?: Maybe<Array<ChannelPaymentMadeEvent>>
  channels: Array<Channel>
  collaboratorInChannels: Array<Collaborator>
  commentauthor?: Maybe<Array<Comment>>
  commentcreatedeventparentCommentAuthor?: Maybe<Array<CommentCreatedEvent>>
  commentreactedeventreactingMember?: Maybe<Array<CommentReactedEvent>>
  commentreactionmember?: Maybe<Array<CommentReaction>>
  /** Member's controller account id */
  controllerAccount: Scalars['String']
  councilCandidacies: Array<Candidate>
  councilMembers: Array<CouncilMember>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  englishauctionsettledeventownerMember?: Maybe<Array<EnglishAuctionSettledEvent>>
  englishauctionsettledeventwinner?: Maybe<Array<EnglishAuctionSettledEvent>>
  englishauctionstartedeventownerMember?: Maybe<Array<EnglishAuctionStartedEvent>>
  /** How the member was registered */
  entry: MembershipEntryMethod
  externalResources?: Maybe<Array<MembershipExternalResource>>
  forumpostauthor?: Maybe<Array<ForumPost>>
  forumthreadauthor?: Maybe<Array<ForumThread>>
  /** The unique handle chosen by member */
  handle: Scalars['String']
  id: Scalars['ID']
  /** Current count of invites left to send. */
  inviteCount: Scalars['Int']
  invitedBy?: Maybe<Membership>
  invitedById?: Maybe<Scalars['String']>
  invitees: Array<Membership>
  invitestransferredeventsourceMember?: Maybe<Array<InvitesTransferredEvent>>
  invitestransferredeventtargetMember?: Maybe<Array<InvitesTransferredEvent>>
  /** Whether member is elected in the current council. */
  isCouncilMember: Scalars['Boolean']
  /** Whether member is founding member. */
  isFoundingMember: Scalars['Boolean']
  /** Whether member has been verified by membership working group. */
  isVerified: Scalars['Boolean']
  memberBannedFromChannels: Array<Channel>
  memberBidMadeCompletingAuctionEvents: Array<BidMadeCompletingAuctionEvent>
  memberEnglishAuctionSettledEvents: Array<EnglishAuctionSettledEvent>
  memberOpenAuctionAcceptedBidEvents: Array<OpenAuctionBidAcceptedEvent>
  memberaccountsupdatedeventmember?: Maybe<Array<MemberAccountsUpdatedEvent>>
  memberbannedfromchanneleventmember?: Maybe<Array<MemberBannedFromChannelEvent>>
  membercreatedeventnewMember?: Maybe<Array<MemberCreatedEvent>>
  memberinvitedeventinvitingMember?: Maybe<Array<MemberInvitedEvent>>
  memberinvitedeventnewMember?: Maybe<Array<MemberInvitedEvent>>
  memberprofileupdatedeventmember?: Maybe<Array<MemberProfileUpdatedEvent>>
  membershipboughteventnewMember?: Maybe<Array<MembershipBoughtEvent>>
  membershipboughteventreferrer?: Maybe<Array<MembershipBoughtEvent>>
  membershipgiftedeventnewMember?: Maybe<Array<MembershipGiftedEvent>>
  memberverificationstatusupdatedeventmember?: Maybe<Array<MemberVerificationStatusUpdatedEvent>>
  metadata: MemberMetadata
  metadataId: Scalars['String']
  nftCollectorInChannels: Array<ChannelNftCollectors>
  nftboughteventmember?: Maybe<Array<NftBoughtEvent>>
  nftboughteventownerMember?: Maybe<Array<NftBoughtEvent>>
  nftissuedeventownerMember?: Maybe<Array<NftIssuedEvent>>
  nftsellordermadeeventownerMember?: Maybe<Array<NftSellOrderMadeEvent>>
  nftslingedbacktotheoriginalartisteventownerMember?: Maybe<Array<NftSlingedBackToTheOriginalArtistEvent>>
  offeracceptedeventownerMember?: Maybe<Array<OfferAcceptedEvent>>
  offercanceledeventownerMember?: Maybe<Array<OfferCanceledEvent>>
  offerstartedeventmember?: Maybe<Array<OfferStartedEvent>>
  offerstartedeventownerMember?: Maybe<Array<OfferStartedEvent>>
  openauctionbidacceptedeventownerMember?: Maybe<Array<OpenAuctionBidAcceptedEvent>>
  openauctionbidacceptedeventwinningBidder?: Maybe<Array<OpenAuctionBidAcceptedEvent>>
  openauctionstartedeventownerMember?: Maybe<Array<OpenAuctionStartedEvent>>
  ownedNfts: Array<OwnedNft>
  postdeletedeventactor?: Maybe<Array<PostDeletedEvent>>
  proposalcreator?: Maybe<Array<Proposal>>
  proposaldiscussionpostauthor?: Maybe<Array<ProposalDiscussionPost>>
  proposaldiscussionpostdeletedeventactor?: Maybe<Array<ProposalDiscussionPostDeletedEvent>>
  proposaldiscussionthreadmodechangedeventactor?: Maybe<Array<ProposalDiscussionThreadModeChangedEvent>>
  proposalvotedeventvoter?: Maybe<Array<ProposalVotedEvent>>
  referredBy?: Maybe<Membership>
  referredById?: Maybe<Scalars['String']>
  referredMembers: Array<Membership>
  roles: Array<Worker>
  /** Member's root account id */
  rootAccount: Scalars['String']
  stakingaccountaddedeventmember?: Maybe<Array<StakingAccountAddedEvent>>
  stakingaccountconfirmedeventmember?: Maybe<Array<StakingAccountConfirmedEvent>>
  stakingaccountremovedeventmember?: Maybe<Array<StakingAccountRemovedEvent>>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  videoreactedeventreactingMember?: Maybe<Array<VideoReactedEvent>>
  videoreactionmember?: Maybe<Array<VideoReaction>>
  whitelistedIn: Array<ProposalDiscussionWhitelist>
  whitelistedInAuctions: Array<Auction>
  whitelistedInBounties: Array<BountyEntrantWhitelist>
  workinggroupapplicationapplicant?: Maybe<Array<WorkingGroupApplication>>
}

export type MembershipBoughtEvent = BaseGraphQlObject &
  Event & {
    __typename: 'MembershipBoughtEvent'
    /** New member controller in SS58 encoding. */
    controllerAccount: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    /** New member handle. */
    handle: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    metadata: MemberMetadata
    metadataId: Scalars['String']
    /** Network the block was produced in */
    network: Network
    newMember: Membership
    newMemberId: Scalars['String']
    referrer?: Maybe<Membership>
    referrerId?: Maybe<Scalars['String']>
    /** New member root account in SS58 encoding. */
    rootAccount: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type MembershipBoughtEventConnection = {
  __typename: 'MembershipBoughtEventConnection'
  edges: Array<MembershipBoughtEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type MembershipBoughtEventCreateInput = {
  controllerAccount: Scalars['String']
  handle: Scalars['String']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  metadata: Scalars['ID']
  network: Network
  newMember: Scalars['ID']
  referrer?: InputMaybe<Scalars['ID']>
  rootAccount: Scalars['String']
}

export type MembershipBoughtEventEdge = {
  __typename: 'MembershipBoughtEventEdge'
  cursor: Scalars['String']
  node: MembershipBoughtEvent
}

export enum MembershipBoughtEventOrderByInput {
  ControllerAccountAsc = 'controllerAccount_ASC',
  ControllerAccountDesc = 'controllerAccount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewMemberAsc = 'newMember_ASC',
  NewMemberDesc = 'newMember_DESC',
  ReferrerAsc = 'referrer_ASC',
  ReferrerDesc = 'referrer_DESC',
  RootAccountAsc = 'rootAccount_ASC',
  RootAccountDesc = 'rootAccount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MembershipBoughtEventUpdateInput = {
  controllerAccount?: InputMaybe<Scalars['String']>
  handle?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  metadata?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
  newMember?: InputMaybe<Scalars['ID']>
  referrer?: InputMaybe<Scalars['ID']>
  rootAccount?: InputMaybe<Scalars['String']>
}

export type MembershipBoughtEventWhereInput = {
  AND?: InputMaybe<Array<MembershipBoughtEventWhereInput>>
  NOT?: InputMaybe<Array<MembershipBoughtEventWhereInput>>
  OR?: InputMaybe<Array<MembershipBoughtEventWhereInput>>
  controllerAccount_contains?: InputMaybe<Scalars['String']>
  controllerAccount_endsWith?: InputMaybe<Scalars['String']>
  controllerAccount_eq?: InputMaybe<Scalars['String']>
  controllerAccount_in?: InputMaybe<Array<Scalars['String']>>
  controllerAccount_startsWith?: InputMaybe<Scalars['String']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  handle_contains?: InputMaybe<Scalars['String']>
  handle_endsWith?: InputMaybe<Scalars['String']>
  handle_eq?: InputMaybe<Scalars['String']>
  handle_in?: InputMaybe<Array<Scalars['String']>>
  handle_startsWith?: InputMaybe<Scalars['String']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  metadata?: InputMaybe<MemberMetadataWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newMember?: InputMaybe<MembershipWhereInput>
  referrer?: InputMaybe<MembershipWhereInput>
  rootAccount_contains?: InputMaybe<Scalars['String']>
  rootAccount_endsWith?: InputMaybe<Scalars['String']>
  rootAccount_eq?: InputMaybe<Scalars['String']>
  rootAccount_in?: InputMaybe<Array<Scalars['String']>>
  rootAccount_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type MembershipBoughtEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type MembershipConnection = {
  __typename: 'MembershipConnection'
  edges: Array<MembershipEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type MembershipCreateInput = {
  boundAccounts: Array<Scalars['String']>
  controllerAccount: Scalars['String']
  entry: Scalars['JSONObject']
  handle: Scalars['String']
  inviteCount: Scalars['Float']
  invitedBy?: InputMaybe<Scalars['ID']>
  isCouncilMember: Scalars['Boolean']
  isFoundingMember: Scalars['Boolean']
  isVerified: Scalars['Boolean']
  metadata: Scalars['ID']
  referredBy?: InputMaybe<Scalars['ID']>
  rootAccount: Scalars['String']
}

export type MembershipEdge = {
  __typename: 'MembershipEdge'
  cursor: Scalars['String']
  node: Membership
}

export type MembershipEntryGenesis = {
  __typename: 'MembershipEntryGenesis'
  phantom?: Maybe<Scalars['Int']>
}

export type MembershipEntryGifted = {
  __typename: 'MembershipEntryGifted'
  /** The event the member was gifted in */
  membershipGiftedEvent?: Maybe<MembershipGiftedEvent>
}

export type MembershipEntryInvited = {
  __typename: 'MembershipEntryInvited'
  /** The event the member was invited in */
  memberInvitedEvent?: Maybe<MemberInvitedEvent>
}

export type MembershipEntryMemberCreated = {
  __typename: 'MembershipEntryMemberCreated'
  /** The event the member was created in */
  memberCreatedEvent?: Maybe<MemberCreatedEvent>
}

export type MembershipEntryMethod =
  | MembershipEntryGenesis
  | MembershipEntryGifted
  | MembershipEntryInvited
  | MembershipEntryMemberCreated
  | MembershipEntryPaid

export type MembershipEntryPaid = {
  __typename: 'MembershipEntryPaid'
  /** The event the membership was bought in */
  membershipBoughtEvent?: Maybe<MembershipBoughtEvent>
}

export type MembershipExternalResource = BaseGraphQlObject & {
  __typename: 'MembershipExternalResource'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  member?: Maybe<Membership>
  memberId?: Maybe<Scalars['String']>
  memberMetadata: MemberMetadata
  memberMetadataId: Scalars['String']
  type: MembershipExternalResourceType
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  value: Scalars['String']
  version: Scalars['Int']
}

export type MembershipExternalResourceConnection = {
  __typename: 'MembershipExternalResourceConnection'
  edges: Array<MembershipExternalResourceEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type MembershipExternalResourceCreateInput = {
  member?: InputMaybe<Scalars['ID']>
  memberMetadata: Scalars['ID']
  type: MembershipExternalResourceType
  value: Scalars['String']
}

export type MembershipExternalResourceEdge = {
  __typename: 'MembershipExternalResourceEdge'
  cursor: Scalars['String']
  node: MembershipExternalResource
}

export enum MembershipExternalResourceOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  MemberMetadataAsc = 'memberMetadata_ASC',
  MemberMetadataDesc = 'memberMetadata_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export enum MembershipExternalResourceType {
  Discord = 'DISCORD',
  Email = 'EMAIL',
  Facebook = 'FACEBOOK',
  Hyperlink = 'HYPERLINK',
  Irc = 'IRC',
  Linkedin = 'LINKEDIN',
  Matrix = 'MATRIX',
  Telegram = 'TELEGRAM',
  Twitter = 'TWITTER',
  Wechat = 'WECHAT',
  Whatsapp = 'WHATSAPP',
  Youtube = 'YOUTUBE',
}

export type MembershipExternalResourceUpdateInput = {
  member?: InputMaybe<Scalars['ID']>
  memberMetadata?: InputMaybe<Scalars['ID']>
  type?: InputMaybe<MembershipExternalResourceType>
  value?: InputMaybe<Scalars['String']>
}

export type MembershipExternalResourceWhereInput = {
  AND?: InputMaybe<Array<MembershipExternalResourceWhereInput>>
  NOT?: InputMaybe<Array<MembershipExternalResourceWhereInput>>
  OR?: InputMaybe<Array<MembershipExternalResourceWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  member?: InputMaybe<MembershipWhereInput>
  memberMetadata?: InputMaybe<MemberMetadataWhereInput>
  type_eq?: InputMaybe<MembershipExternalResourceType>
  type_in?: InputMaybe<Array<MembershipExternalResourceType>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  value_contains?: InputMaybe<Scalars['String']>
  value_endsWith?: InputMaybe<Scalars['String']>
  value_eq?: InputMaybe<Scalars['String']>
  value_in?: InputMaybe<Array<Scalars['String']>>
  value_startsWith?: InputMaybe<Scalars['String']>
}

export type MembershipExternalResourceWhereUniqueInput = {
  id: Scalars['ID']
}

export type MembershipGiftedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'MembershipGiftedEvent'
    /** New member controller in SS58 encoding. */
    controllerAccount: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    /** New member handle. */
    handle: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    metadata: MemberMetadata
    metadataId: Scalars['String']
    /** Network the block was produced in */
    network: Network
    newMember: Membership
    newMemberId: Scalars['String']
    /** New member root account in SS58 encoding. */
    rootAccount: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type MembershipGiftedEventConnection = {
  __typename: 'MembershipGiftedEventConnection'
  edges: Array<MembershipGiftedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type MembershipGiftedEventCreateInput = {
  controllerAccount: Scalars['String']
  handle: Scalars['String']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  metadata: Scalars['ID']
  network: Network
  newMember: Scalars['ID']
  rootAccount: Scalars['String']
}

export type MembershipGiftedEventEdge = {
  __typename: 'MembershipGiftedEventEdge'
  cursor: Scalars['String']
  node: MembershipGiftedEvent
}

export enum MembershipGiftedEventOrderByInput {
  ControllerAccountAsc = 'controllerAccount_ASC',
  ControllerAccountDesc = 'controllerAccount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewMemberAsc = 'newMember_ASC',
  NewMemberDesc = 'newMember_DESC',
  RootAccountAsc = 'rootAccount_ASC',
  RootAccountDesc = 'rootAccount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MembershipGiftedEventUpdateInput = {
  controllerAccount?: InputMaybe<Scalars['String']>
  handle?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  metadata?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
  newMember?: InputMaybe<Scalars['ID']>
  rootAccount?: InputMaybe<Scalars['String']>
}

export type MembershipGiftedEventWhereInput = {
  AND?: InputMaybe<Array<MembershipGiftedEventWhereInput>>
  NOT?: InputMaybe<Array<MembershipGiftedEventWhereInput>>
  OR?: InputMaybe<Array<MembershipGiftedEventWhereInput>>
  controllerAccount_contains?: InputMaybe<Scalars['String']>
  controllerAccount_endsWith?: InputMaybe<Scalars['String']>
  controllerAccount_eq?: InputMaybe<Scalars['String']>
  controllerAccount_in?: InputMaybe<Array<Scalars['String']>>
  controllerAccount_startsWith?: InputMaybe<Scalars['String']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  handle_contains?: InputMaybe<Scalars['String']>
  handle_endsWith?: InputMaybe<Scalars['String']>
  handle_eq?: InputMaybe<Scalars['String']>
  handle_in?: InputMaybe<Array<Scalars['String']>>
  handle_startsWith?: InputMaybe<Scalars['String']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  metadata?: InputMaybe<MemberMetadataWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newMember?: InputMaybe<MembershipWhereInput>
  rootAccount_contains?: InputMaybe<Scalars['String']>
  rootAccount_endsWith?: InputMaybe<Scalars['String']>
  rootAccount_eq?: InputMaybe<Scalars['String']>
  rootAccount_in?: InputMaybe<Array<Scalars['String']>>
  rootAccount_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type MembershipGiftedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export enum MembershipOrderByInput {
  ControllerAccountAsc = 'controllerAccount_ASC',
  ControllerAccountDesc = 'controllerAccount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  InviteCountAsc = 'inviteCount_ASC',
  InviteCountDesc = 'inviteCount_DESC',
  InvitedByAsc = 'invitedBy_ASC',
  InvitedByDesc = 'invitedBy_DESC',
  IsCouncilMemberAsc = 'isCouncilMember_ASC',
  IsCouncilMemberDesc = 'isCouncilMember_DESC',
  IsFoundingMemberAsc = 'isFoundingMember_ASC',
  IsFoundingMemberDesc = 'isFoundingMember_DESC',
  IsVerifiedAsc = 'isVerified_ASC',
  IsVerifiedDesc = 'isVerified_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  ReferredByAsc = 'referredBy_ASC',
  ReferredByDesc = 'referredBy_DESC',
  RootAccountAsc = 'rootAccount_ASC',
  RootAccountDesc = 'rootAccount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MembershipPriceUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'MembershipPriceUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** The new membership price. */
    newPrice: Scalars['BigInt']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type MembershipPriceUpdatedEventConnection = {
  __typename: 'MembershipPriceUpdatedEventConnection'
  edges: Array<MembershipPriceUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type MembershipPriceUpdatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newPrice: Scalars['String']
}

export type MembershipPriceUpdatedEventEdge = {
  __typename: 'MembershipPriceUpdatedEventEdge'
  cursor: Scalars['String']
  node: MembershipPriceUpdatedEvent
}

export enum MembershipPriceUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewPriceAsc = 'newPrice_ASC',
  NewPriceDesc = 'newPrice_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MembershipPriceUpdatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newPrice?: InputMaybe<Scalars['String']>
}

export type MembershipPriceUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<MembershipPriceUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<MembershipPriceUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<MembershipPriceUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newPrice_eq?: InputMaybe<Scalars['BigInt']>
  newPrice_gt?: InputMaybe<Scalars['BigInt']>
  newPrice_gte?: InputMaybe<Scalars['BigInt']>
  newPrice_in?: InputMaybe<Array<Scalars['BigInt']>>
  newPrice_lt?: InputMaybe<Scalars['BigInt']>
  newPrice_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type MembershipPriceUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type MembershipUpdateInput = {
  boundAccounts?: InputMaybe<Array<Scalars['String']>>
  controllerAccount?: InputMaybe<Scalars['String']>
  entry?: InputMaybe<Scalars['JSONObject']>
  handle?: InputMaybe<Scalars['String']>
  inviteCount?: InputMaybe<Scalars['Float']>
  invitedBy?: InputMaybe<Scalars['ID']>
  isCouncilMember?: InputMaybe<Scalars['Boolean']>
  isFoundingMember?: InputMaybe<Scalars['Boolean']>
  isVerified?: InputMaybe<Scalars['Boolean']>
  metadata?: InputMaybe<Scalars['ID']>
  referredBy?: InputMaybe<Scalars['ID']>
  rootAccount?: InputMaybe<Scalars['String']>
}

export type MembershipWhereInput = {
  AND?: InputMaybe<Array<MembershipWhereInput>>
  NOT?: InputMaybe<Array<MembershipWhereInput>>
  OR?: InputMaybe<Array<MembershipWhereInput>>
  auctionbidcanceledeventmember_every?: InputMaybe<AuctionBidCanceledEventWhereInput>
  auctionbidcanceledeventmember_none?: InputMaybe<AuctionBidCanceledEventWhereInput>
  auctionbidcanceledeventmember_some?: InputMaybe<AuctionBidCanceledEventWhereInput>
  auctionbidcanceledeventownerMember_every?: InputMaybe<AuctionBidCanceledEventWhereInput>
  auctionbidcanceledeventownerMember_none?: InputMaybe<AuctionBidCanceledEventWhereInput>
  auctionbidcanceledeventownerMember_some?: InputMaybe<AuctionBidCanceledEventWhereInput>
  auctionbidmadeeventmember_every?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctionbidmadeeventmember_none?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctionbidmadeeventmember_some?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctionbidmadeeventownerMember_every?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctionbidmadeeventownerMember_none?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctionbidmadeeventownerMember_some?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctionbidmadeeventpreviousTopBidder_every?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctionbidmadeeventpreviousTopBidder_none?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctionbidmadeeventpreviousTopBidder_some?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctioncanceledeventownerMember_every?: InputMaybe<AuctionCanceledEventWhereInput>
  auctioncanceledeventownerMember_none?: InputMaybe<AuctionCanceledEventWhereInput>
  auctioncanceledeventownerMember_some?: InputMaybe<AuctionCanceledEventWhereInput>
  auctioninitialOwner_every?: InputMaybe<AuctionWhereInput>
  auctioninitialOwner_none?: InputMaybe<AuctionWhereInput>
  auctioninitialOwner_some?: InputMaybe<AuctionWhereInput>
  auctionwinningMember_every?: InputMaybe<AuctionWhereInput>
  auctionwinningMember_none?: InputMaybe<AuctionWhereInput>
  auctionwinningMember_some?: InputMaybe<AuctionWhereInput>
  bidbidder_every?: InputMaybe<BidWhereInput>
  bidbidder_none?: InputMaybe<BidWhereInput>
  bidbidder_some?: InputMaybe<BidWhereInput>
  bidmadecompletingauctioneventmember_every?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventmember_none?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventmember_some?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventownerMember_every?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventownerMember_none?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventownerMember_some?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventpreviousTopBidder_every?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventpreviousTopBidder_none?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventpreviousTopBidder_some?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  boundAccounts_containsAll?: InputMaybe<Array<Scalars['String']>>
  boundAccounts_containsAny?: InputMaybe<Array<Scalars['String']>>
  boundAccounts_containsNone?: InputMaybe<Array<Scalars['String']>>
  bountycontributioncontributor_every?: InputMaybe<BountyContributionWhereInput>
  bountycontributioncontributor_none?: InputMaybe<BountyContributionWhereInput>
  bountycontributioncontributor_some?: InputMaybe<BountyContributionWhereInput>
  bountycreator_every?: InputMaybe<BountyWhereInput>
  bountycreator_none?: InputMaybe<BountyWhereInput>
  bountycreator_some?: InputMaybe<BountyWhereInput>
  bountyentryworker_every?: InputMaybe<BountyEntryWhereInput>
  bountyentryworker_none?: InputMaybe<BountyEntryWhereInput>
  bountyentryworker_some?: InputMaybe<BountyEntryWhereInput>
  bountyoracle_every?: InputMaybe<BountyWhereInput>
  bountyoracle_none?: InputMaybe<BountyWhereInput>
  bountyoracle_some?: InputMaybe<BountyWhereInput>
  buynowcanceledeventownerMember_every?: InputMaybe<BuyNowCanceledEventWhereInput>
  buynowcanceledeventownerMember_none?: InputMaybe<BuyNowCanceledEventWhereInput>
  buynowcanceledeventownerMember_some?: InputMaybe<BuyNowCanceledEventWhereInput>
  buynowpriceupdatedeventownerMember_every?: InputMaybe<BuyNowPriceUpdatedEventWhereInput>
  buynowpriceupdatedeventownerMember_none?: InputMaybe<BuyNowPriceUpdatedEventWhereInput>
  buynowpriceupdatedeventownerMember_some?: InputMaybe<BuyNowPriceUpdatedEventWhereInput>
  channelpaymentmadeeventpayer_every?: InputMaybe<ChannelPaymentMadeEventWhereInput>
  channelpaymentmadeeventpayer_none?: InputMaybe<ChannelPaymentMadeEventWhereInput>
  channelpaymentmadeeventpayer_some?: InputMaybe<ChannelPaymentMadeEventWhereInput>
  channels_every?: InputMaybe<ChannelWhereInput>
  channels_none?: InputMaybe<ChannelWhereInput>
  channels_some?: InputMaybe<ChannelWhereInput>
  collaboratorInChannels_every?: InputMaybe<CollaboratorWhereInput>
  collaboratorInChannels_none?: InputMaybe<CollaboratorWhereInput>
  collaboratorInChannels_some?: InputMaybe<CollaboratorWhereInput>
  commentauthor_every?: InputMaybe<CommentWhereInput>
  commentauthor_none?: InputMaybe<CommentWhereInput>
  commentauthor_some?: InputMaybe<CommentWhereInput>
  commentcreatedeventparentCommentAuthor_every?: InputMaybe<CommentCreatedEventWhereInput>
  commentcreatedeventparentCommentAuthor_none?: InputMaybe<CommentCreatedEventWhereInput>
  commentcreatedeventparentCommentAuthor_some?: InputMaybe<CommentCreatedEventWhereInput>
  commentreactedeventreactingMember_every?: InputMaybe<CommentReactedEventWhereInput>
  commentreactedeventreactingMember_none?: InputMaybe<CommentReactedEventWhereInput>
  commentreactedeventreactingMember_some?: InputMaybe<CommentReactedEventWhereInput>
  commentreactionmember_every?: InputMaybe<CommentReactionWhereInput>
  commentreactionmember_none?: InputMaybe<CommentReactionWhereInput>
  commentreactionmember_some?: InputMaybe<CommentReactionWhereInput>
  controllerAccount_contains?: InputMaybe<Scalars['String']>
  controllerAccount_endsWith?: InputMaybe<Scalars['String']>
  controllerAccount_eq?: InputMaybe<Scalars['String']>
  controllerAccount_in?: InputMaybe<Array<Scalars['String']>>
  controllerAccount_startsWith?: InputMaybe<Scalars['String']>
  councilCandidacies_every?: InputMaybe<CandidateWhereInput>
  councilCandidacies_none?: InputMaybe<CandidateWhereInput>
  councilCandidacies_some?: InputMaybe<CandidateWhereInput>
  councilMembers_every?: InputMaybe<CouncilMemberWhereInput>
  councilMembers_none?: InputMaybe<CouncilMemberWhereInput>
  councilMembers_some?: InputMaybe<CouncilMemberWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  englishauctionsettledeventownerMember_every?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  englishauctionsettledeventownerMember_none?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  englishauctionsettledeventownerMember_some?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  englishauctionsettledeventwinner_every?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  englishauctionsettledeventwinner_none?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  englishauctionsettledeventwinner_some?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  englishauctionstartedeventownerMember_every?: InputMaybe<EnglishAuctionStartedEventWhereInput>
  englishauctionstartedeventownerMember_none?: InputMaybe<EnglishAuctionStartedEventWhereInput>
  englishauctionstartedeventownerMember_some?: InputMaybe<EnglishAuctionStartedEventWhereInput>
  entry_json?: InputMaybe<Scalars['JSONObject']>
  externalResources_every?: InputMaybe<MembershipExternalResourceWhereInput>
  externalResources_none?: InputMaybe<MembershipExternalResourceWhereInput>
  externalResources_some?: InputMaybe<MembershipExternalResourceWhereInput>
  forumpostauthor_every?: InputMaybe<ForumPostWhereInput>
  forumpostauthor_none?: InputMaybe<ForumPostWhereInput>
  forumpostauthor_some?: InputMaybe<ForumPostWhereInput>
  forumthreadauthor_every?: InputMaybe<ForumThreadWhereInput>
  forumthreadauthor_none?: InputMaybe<ForumThreadWhereInput>
  forumthreadauthor_some?: InputMaybe<ForumThreadWhereInput>
  handle_contains?: InputMaybe<Scalars['String']>
  handle_endsWith?: InputMaybe<Scalars['String']>
  handle_eq?: InputMaybe<Scalars['String']>
  handle_in?: InputMaybe<Array<Scalars['String']>>
  handle_startsWith?: InputMaybe<Scalars['String']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inviteCount_eq?: InputMaybe<Scalars['Int']>
  inviteCount_gt?: InputMaybe<Scalars['Int']>
  inviteCount_gte?: InputMaybe<Scalars['Int']>
  inviteCount_in?: InputMaybe<Array<Scalars['Int']>>
  inviteCount_lt?: InputMaybe<Scalars['Int']>
  inviteCount_lte?: InputMaybe<Scalars['Int']>
  invitedBy?: InputMaybe<MembershipWhereInput>
  invitees_every?: InputMaybe<MembershipWhereInput>
  invitees_none?: InputMaybe<MembershipWhereInput>
  invitees_some?: InputMaybe<MembershipWhereInput>
  invitestransferredeventsourceMember_every?: InputMaybe<InvitesTransferredEventWhereInput>
  invitestransferredeventsourceMember_none?: InputMaybe<InvitesTransferredEventWhereInput>
  invitestransferredeventsourceMember_some?: InputMaybe<InvitesTransferredEventWhereInput>
  invitestransferredeventtargetMember_every?: InputMaybe<InvitesTransferredEventWhereInput>
  invitestransferredeventtargetMember_none?: InputMaybe<InvitesTransferredEventWhereInput>
  invitestransferredeventtargetMember_some?: InputMaybe<InvitesTransferredEventWhereInput>
  isCouncilMember_eq?: InputMaybe<Scalars['Boolean']>
  isCouncilMember_in?: InputMaybe<Array<Scalars['Boolean']>>
  isFoundingMember_eq?: InputMaybe<Scalars['Boolean']>
  isFoundingMember_in?: InputMaybe<Array<Scalars['Boolean']>>
  isVerified_eq?: InputMaybe<Scalars['Boolean']>
  isVerified_in?: InputMaybe<Array<Scalars['Boolean']>>
  memberBannedFromChannels_every?: InputMaybe<ChannelWhereInput>
  memberBannedFromChannels_none?: InputMaybe<ChannelWhereInput>
  memberBannedFromChannels_some?: InputMaybe<ChannelWhereInput>
  memberBidMadeCompletingAuctionEvents_every?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  memberBidMadeCompletingAuctionEvents_none?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  memberBidMadeCompletingAuctionEvents_some?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  memberEnglishAuctionSettledEvents_every?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  memberEnglishAuctionSettledEvents_none?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  memberEnglishAuctionSettledEvents_some?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  memberOpenAuctionAcceptedBidEvents_every?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  memberOpenAuctionAcceptedBidEvents_none?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  memberOpenAuctionAcceptedBidEvents_some?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  memberaccountsupdatedeventmember_every?: InputMaybe<MemberAccountsUpdatedEventWhereInput>
  memberaccountsupdatedeventmember_none?: InputMaybe<MemberAccountsUpdatedEventWhereInput>
  memberaccountsupdatedeventmember_some?: InputMaybe<MemberAccountsUpdatedEventWhereInput>
  memberbannedfromchanneleventmember_every?: InputMaybe<MemberBannedFromChannelEventWhereInput>
  memberbannedfromchanneleventmember_none?: InputMaybe<MemberBannedFromChannelEventWhereInput>
  memberbannedfromchanneleventmember_some?: InputMaybe<MemberBannedFromChannelEventWhereInput>
  membercreatedeventnewMember_every?: InputMaybe<MemberCreatedEventWhereInput>
  membercreatedeventnewMember_none?: InputMaybe<MemberCreatedEventWhereInput>
  membercreatedeventnewMember_some?: InputMaybe<MemberCreatedEventWhereInput>
  memberinvitedeventinvitingMember_every?: InputMaybe<MemberInvitedEventWhereInput>
  memberinvitedeventinvitingMember_none?: InputMaybe<MemberInvitedEventWhereInput>
  memberinvitedeventinvitingMember_some?: InputMaybe<MemberInvitedEventWhereInput>
  memberinvitedeventnewMember_every?: InputMaybe<MemberInvitedEventWhereInput>
  memberinvitedeventnewMember_none?: InputMaybe<MemberInvitedEventWhereInput>
  memberinvitedeventnewMember_some?: InputMaybe<MemberInvitedEventWhereInput>
  memberprofileupdatedeventmember_every?: InputMaybe<MemberProfileUpdatedEventWhereInput>
  memberprofileupdatedeventmember_none?: InputMaybe<MemberProfileUpdatedEventWhereInput>
  memberprofileupdatedeventmember_some?: InputMaybe<MemberProfileUpdatedEventWhereInput>
  membershipboughteventnewMember_every?: InputMaybe<MembershipBoughtEventWhereInput>
  membershipboughteventnewMember_none?: InputMaybe<MembershipBoughtEventWhereInput>
  membershipboughteventnewMember_some?: InputMaybe<MembershipBoughtEventWhereInput>
  membershipboughteventreferrer_every?: InputMaybe<MembershipBoughtEventWhereInput>
  membershipboughteventreferrer_none?: InputMaybe<MembershipBoughtEventWhereInput>
  membershipboughteventreferrer_some?: InputMaybe<MembershipBoughtEventWhereInput>
  membershipgiftedeventnewMember_every?: InputMaybe<MembershipGiftedEventWhereInput>
  membershipgiftedeventnewMember_none?: InputMaybe<MembershipGiftedEventWhereInput>
  membershipgiftedeventnewMember_some?: InputMaybe<MembershipGiftedEventWhereInput>
  memberverificationstatusupdatedeventmember_every?: InputMaybe<MemberVerificationStatusUpdatedEventWhereInput>
  memberverificationstatusupdatedeventmember_none?: InputMaybe<MemberVerificationStatusUpdatedEventWhereInput>
  memberverificationstatusupdatedeventmember_some?: InputMaybe<MemberVerificationStatusUpdatedEventWhereInput>
  metadata?: InputMaybe<MemberMetadataWhereInput>
  nftCollectorInChannels_every?: InputMaybe<ChannelNftCollectorsWhereInput>
  nftCollectorInChannels_none?: InputMaybe<ChannelNftCollectorsWhereInput>
  nftCollectorInChannels_some?: InputMaybe<ChannelNftCollectorsWhereInput>
  nftboughteventmember_every?: InputMaybe<NftBoughtEventWhereInput>
  nftboughteventmember_none?: InputMaybe<NftBoughtEventWhereInput>
  nftboughteventmember_some?: InputMaybe<NftBoughtEventWhereInput>
  nftboughteventownerMember_every?: InputMaybe<NftBoughtEventWhereInput>
  nftboughteventownerMember_none?: InputMaybe<NftBoughtEventWhereInput>
  nftboughteventownerMember_some?: InputMaybe<NftBoughtEventWhereInput>
  nftissuedeventownerMember_every?: InputMaybe<NftIssuedEventWhereInput>
  nftissuedeventownerMember_none?: InputMaybe<NftIssuedEventWhereInput>
  nftissuedeventownerMember_some?: InputMaybe<NftIssuedEventWhereInput>
  nftsellordermadeeventownerMember_every?: InputMaybe<NftSellOrderMadeEventWhereInput>
  nftsellordermadeeventownerMember_none?: InputMaybe<NftSellOrderMadeEventWhereInput>
  nftsellordermadeeventownerMember_some?: InputMaybe<NftSellOrderMadeEventWhereInput>
  nftslingedbacktotheoriginalartisteventownerMember_every?: InputMaybe<NftSlingedBackToTheOriginalArtistEventWhereInput>
  nftslingedbacktotheoriginalartisteventownerMember_none?: InputMaybe<NftSlingedBackToTheOriginalArtistEventWhereInput>
  nftslingedbacktotheoriginalartisteventownerMember_some?: InputMaybe<NftSlingedBackToTheOriginalArtistEventWhereInput>
  offeracceptedeventownerMember_every?: InputMaybe<OfferAcceptedEventWhereInput>
  offeracceptedeventownerMember_none?: InputMaybe<OfferAcceptedEventWhereInput>
  offeracceptedeventownerMember_some?: InputMaybe<OfferAcceptedEventWhereInput>
  offercanceledeventownerMember_every?: InputMaybe<OfferCanceledEventWhereInput>
  offercanceledeventownerMember_none?: InputMaybe<OfferCanceledEventWhereInput>
  offercanceledeventownerMember_some?: InputMaybe<OfferCanceledEventWhereInput>
  offerstartedeventmember_every?: InputMaybe<OfferStartedEventWhereInput>
  offerstartedeventmember_none?: InputMaybe<OfferStartedEventWhereInput>
  offerstartedeventmember_some?: InputMaybe<OfferStartedEventWhereInput>
  offerstartedeventownerMember_every?: InputMaybe<OfferStartedEventWhereInput>
  offerstartedeventownerMember_none?: InputMaybe<OfferStartedEventWhereInput>
  offerstartedeventownerMember_some?: InputMaybe<OfferStartedEventWhereInput>
  openauctionbidacceptedeventownerMember_every?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  openauctionbidacceptedeventownerMember_none?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  openauctionbidacceptedeventownerMember_some?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  openauctionbidacceptedeventwinningBidder_every?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  openauctionbidacceptedeventwinningBidder_none?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  openauctionbidacceptedeventwinningBidder_some?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  openauctionstartedeventownerMember_every?: InputMaybe<OpenAuctionStartedEventWhereInput>
  openauctionstartedeventownerMember_none?: InputMaybe<OpenAuctionStartedEventWhereInput>
  openauctionstartedeventownerMember_some?: InputMaybe<OpenAuctionStartedEventWhereInput>
  ownedNfts_every?: InputMaybe<OwnedNftWhereInput>
  ownedNfts_none?: InputMaybe<OwnedNftWhereInput>
  ownedNfts_some?: InputMaybe<OwnedNftWhereInput>
  postdeletedeventactor_every?: InputMaybe<PostDeletedEventWhereInput>
  postdeletedeventactor_none?: InputMaybe<PostDeletedEventWhereInput>
  postdeletedeventactor_some?: InputMaybe<PostDeletedEventWhereInput>
  proposalcreator_every?: InputMaybe<ProposalWhereInput>
  proposalcreator_none?: InputMaybe<ProposalWhereInput>
  proposalcreator_some?: InputMaybe<ProposalWhereInput>
  proposaldiscussionpostauthor_every?: InputMaybe<ProposalDiscussionPostWhereInput>
  proposaldiscussionpostauthor_none?: InputMaybe<ProposalDiscussionPostWhereInput>
  proposaldiscussionpostauthor_some?: InputMaybe<ProposalDiscussionPostWhereInput>
  proposaldiscussionpostdeletedeventactor_every?: InputMaybe<ProposalDiscussionPostDeletedEventWhereInput>
  proposaldiscussionpostdeletedeventactor_none?: InputMaybe<ProposalDiscussionPostDeletedEventWhereInput>
  proposaldiscussionpostdeletedeventactor_some?: InputMaybe<ProposalDiscussionPostDeletedEventWhereInput>
  proposaldiscussionthreadmodechangedeventactor_every?: InputMaybe<ProposalDiscussionThreadModeChangedEventWhereInput>
  proposaldiscussionthreadmodechangedeventactor_none?: InputMaybe<ProposalDiscussionThreadModeChangedEventWhereInput>
  proposaldiscussionthreadmodechangedeventactor_some?: InputMaybe<ProposalDiscussionThreadModeChangedEventWhereInput>
  proposalvotedeventvoter_every?: InputMaybe<ProposalVotedEventWhereInput>
  proposalvotedeventvoter_none?: InputMaybe<ProposalVotedEventWhereInput>
  proposalvotedeventvoter_some?: InputMaybe<ProposalVotedEventWhereInput>
  referredBy?: InputMaybe<MembershipWhereInput>
  referredMembers_every?: InputMaybe<MembershipWhereInput>
  referredMembers_none?: InputMaybe<MembershipWhereInput>
  referredMembers_some?: InputMaybe<MembershipWhereInput>
  roles_every?: InputMaybe<WorkerWhereInput>
  roles_none?: InputMaybe<WorkerWhereInput>
  roles_some?: InputMaybe<WorkerWhereInput>
  rootAccount_contains?: InputMaybe<Scalars['String']>
  rootAccount_endsWith?: InputMaybe<Scalars['String']>
  rootAccount_eq?: InputMaybe<Scalars['String']>
  rootAccount_in?: InputMaybe<Array<Scalars['String']>>
  rootAccount_startsWith?: InputMaybe<Scalars['String']>
  stakingaccountaddedeventmember_every?: InputMaybe<StakingAccountAddedEventWhereInput>
  stakingaccountaddedeventmember_none?: InputMaybe<StakingAccountAddedEventWhereInput>
  stakingaccountaddedeventmember_some?: InputMaybe<StakingAccountAddedEventWhereInput>
  stakingaccountconfirmedeventmember_every?: InputMaybe<StakingAccountConfirmedEventWhereInput>
  stakingaccountconfirmedeventmember_none?: InputMaybe<StakingAccountConfirmedEventWhereInput>
  stakingaccountconfirmedeventmember_some?: InputMaybe<StakingAccountConfirmedEventWhereInput>
  stakingaccountremovedeventmember_every?: InputMaybe<StakingAccountRemovedEventWhereInput>
  stakingaccountremovedeventmember_none?: InputMaybe<StakingAccountRemovedEventWhereInput>
  stakingaccountremovedeventmember_some?: InputMaybe<StakingAccountRemovedEventWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  videoreactedeventreactingMember_every?: InputMaybe<VideoReactedEventWhereInput>
  videoreactedeventreactingMember_none?: InputMaybe<VideoReactedEventWhereInput>
  videoreactedeventreactingMember_some?: InputMaybe<VideoReactedEventWhereInput>
  videoreactionmember_every?: InputMaybe<VideoReactionWhereInput>
  videoreactionmember_none?: InputMaybe<VideoReactionWhereInput>
  videoreactionmember_some?: InputMaybe<VideoReactionWhereInput>
  whitelistedInAuctions_every?: InputMaybe<AuctionWhereInput>
  whitelistedInAuctions_none?: InputMaybe<AuctionWhereInput>
  whitelistedInAuctions_some?: InputMaybe<AuctionWhereInput>
  whitelistedInBounties_every?: InputMaybe<BountyEntrantWhitelistWhereInput>
  whitelistedInBounties_none?: InputMaybe<BountyEntrantWhitelistWhereInput>
  whitelistedInBounties_some?: InputMaybe<BountyEntrantWhitelistWhereInput>
  whitelistedIn_every?: InputMaybe<ProposalDiscussionWhitelistWhereInput>
  whitelistedIn_none?: InputMaybe<ProposalDiscussionWhitelistWhereInput>
  whitelistedIn_some?: InputMaybe<ProposalDiscussionWhitelistWhereInput>
  workinggroupapplicationapplicant_every?: InputMaybe<WorkingGroupApplicationWhereInput>
  workinggroupapplicationapplicant_none?: InputMaybe<WorkingGroupApplicationWhereInput>
  workinggroupapplicationapplicant_some?: InputMaybe<WorkingGroupApplicationWhereInput>
}

export type MembershipWhereUniqueInput = {
  handle?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
}

export type MetaprotocolTransactionErrored = {
  __typename: 'MetaprotocolTransactionErrored'
  message: Scalars['String']
}

export type MetaprotocolTransactionStatus = MetaprotocolTransactionErrored | MetaprotocolTransactionSuccessful

export type MetaprotocolTransactionStatusEvent = BaseGraphQlObject &
  Event & {
    __typename: 'MetaprotocolTransactionStatusEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** The status of metaprotocol action */
    status: MetaprotocolTransactionStatus
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type MetaprotocolTransactionStatusEventConnection = {
  __typename: 'MetaprotocolTransactionStatusEventConnection'
  edges: Array<MetaprotocolTransactionStatusEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type MetaprotocolTransactionStatusEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  status: Scalars['JSONObject']
}

export type MetaprotocolTransactionStatusEventEdge = {
  __typename: 'MetaprotocolTransactionStatusEventEdge'
  cursor: Scalars['String']
  node: MetaprotocolTransactionStatusEvent
}

export enum MetaprotocolTransactionStatusEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MetaprotocolTransactionStatusEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  status?: InputMaybe<Scalars['JSONObject']>
}

export type MetaprotocolTransactionStatusEventWhereInput = {
  AND?: InputMaybe<Array<MetaprotocolTransactionStatusEventWhereInput>>
  NOT?: InputMaybe<Array<MetaprotocolTransactionStatusEventWhereInput>>
  OR?: InputMaybe<Array<MetaprotocolTransactionStatusEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  status_json?: InputMaybe<Scalars['JSONObject']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type MetaprotocolTransactionStatusEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type MetaprotocolTransactionSuccessful = {
  __typename: 'MetaprotocolTransactionSuccessful'
  /** Payment made to channel by the member sending the remark action (if any) */
  channelPaid?: Maybe<Channel>
  /** ID of the created comment (if any) */
  commentCreated?: Maybe<Comment>
  /** ID of the deleted comment (if any) */
  commentDeleted?: Maybe<Comment>
  /** ID of the edited comment (if any) */
  commentEdited?: Maybe<Comment>
  /** ID of the moderated comment (if any) */
  commentModerated?: Maybe<Comment>
  /** Type needs to have at least one non-relation entity. This value is not used. */
  dummy?: Maybe<Scalars['Int']>
  /** ID of the created video category (if any) */
  videoCategoryCreated?: Maybe<Video>
  /** ID of the delete video category (if any) */
  videoCategoryDeleted?: Maybe<Video>
  /** ID of the updated video category (if any) */
  videoCategoryUpdated?: Maybe<Video>
}

export type Mutation = {
  __typename: 'Mutation'
  entitySubscription?: Maybe<EntitySubscription>
  generalSubscriptions?: Maybe<Array<Maybe<GeneralSubscription>>>
  signin?: Maybe<Scalars['String']>
  signup?: Maybe<Scalars['String']>
  updateMember?: Maybe<Member>
  verifyEmail?: Maybe<Member>
}

export type MutationEntitySubscriptionArgs = {
  entityId: Scalars['String']
  kind: EntitySubscriptionKind
  status: EntitySubscriptionStatus
}

export type MutationGeneralSubscriptionsArgs = {
  data?: InputMaybe<Array<InputMaybe<GeneralSubscriptionInput>>>
}

export type MutationSigninArgs = {
  memberId: Scalars['Int']
  signature: Scalars['String']
  timestamp: Scalars['BigInt']
}

export type MutationSignupArgs = {
  email?: InputMaybe<Scalars['String']>
  memberId: Scalars['Int']
  name: Scalars['String']
  signature: Scalars['String']
  timestamp: Scalars['BigInt']
}

export type MutationUpdateMemberArgs = {
  email?: InputMaybe<Scalars['String']>
  receiveEmails?: InputMaybe<Scalars['Boolean']>
}

export type MutationVerifyEmailArgs = {
  token: Scalars['String']
}

export enum Network {
  Alexandria = 'ALEXANDRIA',
  Babylon = 'BABYLON',
  Giza = 'GIZA',
  Olympia = 'OLYMPIA',
  Rome = 'ROME',
}

export type NewCandidateEvent = BaseGraphQlObject &
  Event & {
    __typename: 'NewCandidateEvent'
    /** Amount of currency to be staked for the candidacy. */
    balance: Scalars['BigInt']
    candidate: Candidate
    candidateId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    electionRound: ElectionRound
    electionRoundId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Candidate's account that will be recieving rewards if candidate's elected. */
    rewardAccount: Scalars['String']
    /** Candidate's account used to stake currency. */
    stakingAccount: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type NewCandidateEventConnection = {
  __typename: 'NewCandidateEventConnection'
  edges: Array<NewCandidateEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type NewCandidateEventCreateInput = {
  balance: Scalars['String']
  candidate: Scalars['ID']
  electionRound: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  rewardAccount: Scalars['String']
  stakingAccount: Scalars['String']
}

export type NewCandidateEventEdge = {
  __typename: 'NewCandidateEventEdge'
  cursor: Scalars['String']
  node: NewCandidateEvent
}

export enum NewCandidateEventOrderByInput {
  BalanceAsc = 'balance_ASC',
  BalanceDesc = 'balance_DESC',
  CandidateAsc = 'candidate_ASC',
  CandidateDesc = 'candidate_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ElectionRoundAsc = 'electionRound_ASC',
  ElectionRoundDesc = 'electionRound_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RewardAccountAsc = 'rewardAccount_ASC',
  RewardAccountDesc = 'rewardAccount_DESC',
  StakingAccountAsc = 'stakingAccount_ASC',
  StakingAccountDesc = 'stakingAccount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type NewCandidateEventUpdateInput = {
  balance?: InputMaybe<Scalars['String']>
  candidate?: InputMaybe<Scalars['ID']>
  electionRound?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  rewardAccount?: InputMaybe<Scalars['String']>
  stakingAccount?: InputMaybe<Scalars['String']>
}

export type NewCandidateEventWhereInput = {
  AND?: InputMaybe<Array<NewCandidateEventWhereInput>>
  NOT?: InputMaybe<Array<NewCandidateEventWhereInput>>
  OR?: InputMaybe<Array<NewCandidateEventWhereInput>>
  balance_eq?: InputMaybe<Scalars['BigInt']>
  balance_gt?: InputMaybe<Scalars['BigInt']>
  balance_gte?: InputMaybe<Scalars['BigInt']>
  balance_in?: InputMaybe<Array<Scalars['BigInt']>>
  balance_lt?: InputMaybe<Scalars['BigInt']>
  balance_lte?: InputMaybe<Scalars['BigInt']>
  candidate?: InputMaybe<CandidateWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  electionRound?: InputMaybe<ElectionRoundWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rewardAccount_contains?: InputMaybe<Scalars['String']>
  rewardAccount_endsWith?: InputMaybe<Scalars['String']>
  rewardAccount_eq?: InputMaybe<Scalars['String']>
  rewardAccount_in?: InputMaybe<Array<Scalars['String']>>
  rewardAccount_startsWith?: InputMaybe<Scalars['String']>
  stakingAccount_contains?: InputMaybe<Scalars['String']>
  stakingAccount_endsWith?: InputMaybe<Scalars['String']>
  stakingAccount_eq?: InputMaybe<Scalars['String']>
  stakingAccount_in?: InputMaybe<Array<Scalars['String']>>
  stakingAccount_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type NewCandidateEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type NewCouncilElectedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'NewCouncilElectedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    electedCouncil: ElectedCouncil
    electedCouncilId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type NewCouncilElectedEventConnection = {
  __typename: 'NewCouncilElectedEventConnection'
  edges: Array<NewCouncilElectedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type NewCouncilElectedEventCreateInput = {
  electedCouncil: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type NewCouncilElectedEventEdge = {
  __typename: 'NewCouncilElectedEventEdge'
  cursor: Scalars['String']
  node: NewCouncilElectedEvent
}

export enum NewCouncilElectedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ElectedCouncilAsc = 'electedCouncil_ASC',
  ElectedCouncilDesc = 'electedCouncil_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type NewCouncilElectedEventUpdateInput = {
  electedCouncil?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type NewCouncilElectedEventWhereInput = {
  AND?: InputMaybe<Array<NewCouncilElectedEventWhereInput>>
  NOT?: InputMaybe<Array<NewCouncilElectedEventWhereInput>>
  OR?: InputMaybe<Array<NewCouncilElectedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  electedCouncil?: InputMaybe<ElectedCouncilWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type NewCouncilElectedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type NewCouncilNotElectedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'NewCouncilNotElectedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type NewCouncilNotElectedEventConnection = {
  __typename: 'NewCouncilNotElectedEventConnection'
  edges: Array<NewCouncilNotElectedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type NewCouncilNotElectedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type NewCouncilNotElectedEventEdge = {
  __typename: 'NewCouncilNotElectedEventEdge'
  cursor: Scalars['String']
  node: NewCouncilNotElectedEvent
}

export enum NewCouncilNotElectedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type NewCouncilNotElectedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type NewCouncilNotElectedEventWhereInput = {
  AND?: InputMaybe<Array<NewCouncilNotElectedEventWhereInput>>
  NOT?: InputMaybe<Array<NewCouncilNotElectedEventWhereInput>>
  OR?: InputMaybe<Array<NewCouncilNotElectedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type NewCouncilNotElectedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type NewMissedRewardLevelReachedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'NewMissedRewardLevelReachedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** New missed reward amount */
    newMissedRewardAmount: Scalars['BigInt']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    worker: Worker
    workerId: Scalars['String']
  }

export type NewMissedRewardLevelReachedEventConnection = {
  __typename: 'NewMissedRewardLevelReachedEventConnection'
  edges: Array<NewMissedRewardLevelReachedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type NewMissedRewardLevelReachedEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newMissedRewardAmount: Scalars['String']
  worker: Scalars['ID']
}

export type NewMissedRewardLevelReachedEventEdge = {
  __typename: 'NewMissedRewardLevelReachedEventEdge'
  cursor: Scalars['String']
  node: NewMissedRewardLevelReachedEvent
}

export enum NewMissedRewardLevelReachedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewMissedRewardAmountAsc = 'newMissedRewardAmount_ASC',
  NewMissedRewardAmountDesc = 'newMissedRewardAmount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type NewMissedRewardLevelReachedEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newMissedRewardAmount?: InputMaybe<Scalars['String']>
  worker?: InputMaybe<Scalars['ID']>
}

export type NewMissedRewardLevelReachedEventWhereInput = {
  AND?: InputMaybe<Array<NewMissedRewardLevelReachedEventWhereInput>>
  NOT?: InputMaybe<Array<NewMissedRewardLevelReachedEventWhereInput>>
  OR?: InputMaybe<Array<NewMissedRewardLevelReachedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newMissedRewardAmount_eq?: InputMaybe<Scalars['BigInt']>
  newMissedRewardAmount_gt?: InputMaybe<Scalars['BigInt']>
  newMissedRewardAmount_gte?: InputMaybe<Scalars['BigInt']>
  newMissedRewardAmount_in?: InputMaybe<Array<Scalars['BigInt']>>
  newMissedRewardAmount_lt?: InputMaybe<Scalars['BigInt']>
  newMissedRewardAmount_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  worker?: InputMaybe<WorkerWhereInput>
}

export type NewMissedRewardLevelReachedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type NftBoughtEvent = BaseGraphQlObject &
  Event & {
    __typename: 'NftBoughtEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    member: Membership
    memberId: Scalars['String']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    /** Price for which NFT was bought */
    price: Scalars['BigInt']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
  }

export type NftBoughtEventConnection = {
  __typename: 'NftBoughtEventConnection'
  edges: Array<NftBoughtEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type NftBoughtEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  member: Scalars['ID']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  price: Scalars['String']
  video: Scalars['ID']
}

export type NftBoughtEventEdge = {
  __typename: 'NftBoughtEventEdge'
  cursor: Scalars['String']
  node: NftBoughtEvent
}

export enum NftBoughtEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type NftBoughtEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  member?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  price?: InputMaybe<Scalars['String']>
  video?: InputMaybe<Scalars['ID']>
}

export type NftBoughtEventWhereInput = {
  AND?: InputMaybe<Array<NftBoughtEventWhereInput>>
  NOT?: InputMaybe<Array<NftBoughtEventWhereInput>>
  OR?: InputMaybe<Array<NftBoughtEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  member?: InputMaybe<MembershipWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  price_eq?: InputMaybe<Scalars['BigInt']>
  price_gt?: InputMaybe<Scalars['BigInt']>
  price_gte?: InputMaybe<Scalars['BigInt']>
  price_in?: InputMaybe<Array<Scalars['BigInt']>>
  price_lt?: InputMaybe<Scalars['BigInt']>
  price_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type NftBoughtEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type NftIssuedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'NftIssuedEvent'
    /** Content actor that issued the NFT. */
    contentActor: ContentActor
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** NFT's metadata. */
    metadata: Scalars['String']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    /** Royalty for the NFT/video. */
    royalty?: Maybe<Scalars['Float']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoCategory?: Maybe<VideoCategory>
    videoCategoryId?: Maybe<Scalars['String']>
    videoId: Scalars['String']
  }

export type NftIssuedEventConnection = {
  __typename: 'NftIssuedEventConnection'
  edges: Array<NftIssuedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type NftIssuedEventCreateInput = {
  contentActor: Scalars['JSONObject']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  metadata: Scalars['String']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  royalty?: InputMaybe<Scalars['Float']>
  video: Scalars['ID']
  videoCategory?: InputMaybe<Scalars['ID']>
}

export type NftIssuedEventEdge = {
  __typename: 'NftIssuedEventEdge'
  cursor: Scalars['String']
  node: NftIssuedEvent
}

export enum NftIssuedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  RoyaltyAsc = 'royalty_ASC',
  RoyaltyDesc = 'royalty_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoCategoryAsc = 'videoCategory_ASC',
  VideoCategoryDesc = 'videoCategory_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type NftIssuedEventUpdateInput = {
  contentActor?: InputMaybe<Scalars['JSONObject']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  metadata?: InputMaybe<Scalars['String']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  royalty?: InputMaybe<Scalars['Float']>
  video?: InputMaybe<Scalars['ID']>
  videoCategory?: InputMaybe<Scalars['ID']>
}

export type NftIssuedEventWhereInput = {
  AND?: InputMaybe<Array<NftIssuedEventWhereInput>>
  NOT?: InputMaybe<Array<NftIssuedEventWhereInput>>
  OR?: InputMaybe<Array<NftIssuedEventWhereInput>>
  contentActor_json?: InputMaybe<Scalars['JSONObject']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  metadata_contains?: InputMaybe<Scalars['String']>
  metadata_endsWith?: InputMaybe<Scalars['String']>
  metadata_eq?: InputMaybe<Scalars['String']>
  metadata_in?: InputMaybe<Array<Scalars['String']>>
  metadata_startsWith?: InputMaybe<Scalars['String']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  royalty_eq?: InputMaybe<Scalars['Float']>
  royalty_gt?: InputMaybe<Scalars['Float']>
  royalty_gte?: InputMaybe<Scalars['Float']>
  royalty_in?: InputMaybe<Array<Scalars['Float']>>
  royalty_lt?: InputMaybe<Scalars['Float']>
  royalty_lte?: InputMaybe<Scalars['Float']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
  videoCategory?: InputMaybe<VideoCategoryWhereInput>
}

export type NftIssuedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type NftSellOrderMadeEvent = BaseGraphQlObject & {
  __typename: 'NftSellOrderMadeEvent'
  /** Content actor acting as NFT owner. */
  contentActor: ContentActor
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Blocknumber of the block in which the event was emitted. */
  inBlock: Scalars['Int']
  /** Hash of the extrinsic which caused the event to be emitted. */
  inExtrinsic?: Maybe<Scalars['String']>
  /** Index of event in block from which it was emitted. */
  indexInBlock: Scalars['Int']
  /** Network the block was produced in. */
  network: Network
  ownerCuratorGroup?: Maybe<CuratorGroup>
  ownerCuratorGroupId?: Maybe<Scalars['String']>
  ownerMember?: Maybe<Membership>
  ownerMemberId?: Maybe<Scalars['String']>
  /** Offer's price. */
  price: Scalars['BigInt']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  video: Video
  videoId: Scalars['String']
}

export type NftSellOrderMadeEventConnection = {
  __typename: 'NftSellOrderMadeEventConnection'
  edges: Array<NftSellOrderMadeEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type NftSellOrderMadeEventCreateInput = {
  contentActor: Scalars['JSONObject']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  price: Scalars['String']
  video: Scalars['ID']
}

export type NftSellOrderMadeEventEdge = {
  __typename: 'NftSellOrderMadeEventEdge'
  cursor: Scalars['String']
  node: NftSellOrderMadeEvent
}

export enum NftSellOrderMadeEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type NftSellOrderMadeEventUpdateInput = {
  contentActor?: InputMaybe<Scalars['JSONObject']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  price?: InputMaybe<Scalars['String']>
  video?: InputMaybe<Scalars['ID']>
}

export type NftSellOrderMadeEventWhereInput = {
  AND?: InputMaybe<Array<NftSellOrderMadeEventWhereInput>>
  NOT?: InputMaybe<Array<NftSellOrderMadeEventWhereInput>>
  OR?: InputMaybe<Array<NftSellOrderMadeEventWhereInput>>
  contentActor_json?: InputMaybe<Scalars['JSONObject']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  price_eq?: InputMaybe<Scalars['BigInt']>
  price_gt?: InputMaybe<Scalars['BigInt']>
  price_gte?: InputMaybe<Scalars['BigInt']>
  price_in?: InputMaybe<Array<Scalars['BigInt']>>
  price_lt?: InputMaybe<Scalars['BigInt']>
  price_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type NftSellOrderMadeEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type NftSlingedBackToTheOriginalArtistEvent = BaseGraphQlObject &
  Event & {
    __typename: 'NftSlingedBackToTheOriginalArtistEvent'
    /** Content actor who slung back the NFT. */
    contentActor: ContentActor
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
  }

export type NftSlingedBackToTheOriginalArtistEventConnection = {
  __typename: 'NftSlingedBackToTheOriginalArtistEventConnection'
  edges: Array<NftSlingedBackToTheOriginalArtistEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type NftSlingedBackToTheOriginalArtistEventCreateInput = {
  contentActor: Scalars['JSONObject']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video: Scalars['ID']
}

export type NftSlingedBackToTheOriginalArtistEventEdge = {
  __typename: 'NftSlingedBackToTheOriginalArtistEventEdge'
  cursor: Scalars['String']
  node: NftSlingedBackToTheOriginalArtistEvent
}

export enum NftSlingedBackToTheOriginalArtistEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type NftSlingedBackToTheOriginalArtistEventUpdateInput = {
  contentActor?: InputMaybe<Scalars['JSONObject']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video?: InputMaybe<Scalars['ID']>
}

export type NftSlingedBackToTheOriginalArtistEventWhereInput = {
  AND?: InputMaybe<Array<NftSlingedBackToTheOriginalArtistEventWhereInput>>
  NOT?: InputMaybe<Array<NftSlingedBackToTheOriginalArtistEventWhereInput>>
  OR?: InputMaybe<Array<NftSlingedBackToTheOriginalArtistEventWhereInput>>
  contentActor_json?: InputMaybe<Scalars['JSONObject']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type NftSlingedBackToTheOriginalArtistEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type NodeLocationMetadata = BaseGraphQlObject & {
  __typename: 'NodeLocationMetadata'
  /** City name */
  city?: Maybe<Scalars['String']>
  coordinates?: Maybe<GeoCoordinates>
  coordinatesId?: Maybe<Scalars['String']>
  /** ISO 3166-1 alpha-2 country code (2 letters) */
  countryCode?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  distributionbucketoperatormetadatanodeLocation?: Maybe<Array<DistributionBucketOperatorMetadata>>
  id: Scalars['ID']
  storagebucketoperatormetadatanodeLocation?: Maybe<Array<StorageBucketOperatorMetadata>>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type NodeLocationMetadataConnection = {
  __typename: 'NodeLocationMetadataConnection'
  edges: Array<NodeLocationMetadataEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type NodeLocationMetadataCreateInput = {
  city?: InputMaybe<Scalars['String']>
  coordinates?: InputMaybe<Scalars['ID']>
  countryCode?: InputMaybe<Scalars['String']>
}

export type NodeLocationMetadataEdge = {
  __typename: 'NodeLocationMetadataEdge'
  cursor: Scalars['String']
  node: NodeLocationMetadata
}

export enum NodeLocationMetadataOrderByInput {
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  CoordinatesAsc = 'coordinates_ASC',
  CoordinatesDesc = 'coordinates_DESC',
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type NodeLocationMetadataUpdateInput = {
  city?: InputMaybe<Scalars['String']>
  coordinates?: InputMaybe<Scalars['ID']>
  countryCode?: InputMaybe<Scalars['String']>
}

export type NodeLocationMetadataWhereInput = {
  AND?: InputMaybe<Array<NodeLocationMetadataWhereInput>>
  NOT?: InputMaybe<Array<NodeLocationMetadataWhereInput>>
  OR?: InputMaybe<Array<NodeLocationMetadataWhereInput>>
  city_contains?: InputMaybe<Scalars['String']>
  city_endsWith?: InputMaybe<Scalars['String']>
  city_eq?: InputMaybe<Scalars['String']>
  city_in?: InputMaybe<Array<Scalars['String']>>
  city_startsWith?: InputMaybe<Scalars['String']>
  coordinates?: InputMaybe<GeoCoordinatesWhereInput>
  countryCode_contains?: InputMaybe<Scalars['String']>
  countryCode_endsWith?: InputMaybe<Scalars['String']>
  countryCode_eq?: InputMaybe<Scalars['String']>
  countryCode_in?: InputMaybe<Array<Scalars['String']>>
  countryCode_startsWith?: InputMaybe<Scalars['String']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  distributionbucketoperatormetadatanodeLocation_every?: InputMaybe<DistributionBucketOperatorMetadataWhereInput>
  distributionbucketoperatormetadatanodeLocation_none?: InputMaybe<DistributionBucketOperatorMetadataWhereInput>
  distributionbucketoperatormetadatanodeLocation_some?: InputMaybe<DistributionBucketOperatorMetadataWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  storagebucketoperatormetadatanodeLocation_every?: InputMaybe<StorageBucketOperatorMetadataWhereInput>
  storagebucketoperatormetadatanodeLocation_none?: InputMaybe<StorageBucketOperatorMetadataWhereInput>
  storagebucketoperatormetadatanodeLocation_some?: InputMaybe<StorageBucketOperatorMetadataWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type NodeLocationMetadataWhereUniqueInput = {
  id: Scalars['ID']
}

export type NotEnoughCandidatesEvent = BaseGraphQlObject &
  Event & {
    __typename: 'NotEnoughCandidatesEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type NotEnoughCandidatesEventConnection = {
  __typename: 'NotEnoughCandidatesEventConnection'
  edges: Array<NotEnoughCandidatesEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type NotEnoughCandidatesEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type NotEnoughCandidatesEventEdge = {
  __typename: 'NotEnoughCandidatesEventEdge'
  cursor: Scalars['String']
  node: NotEnoughCandidatesEvent
}

export enum NotEnoughCandidatesEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type NotEnoughCandidatesEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type NotEnoughCandidatesEventWhereInput = {
  AND?: InputMaybe<Array<NotEnoughCandidatesEventWhereInput>>
  NOT?: InputMaybe<Array<NotEnoughCandidatesEventWhereInput>>
  OR?: InputMaybe<Array<NotEnoughCandidatesEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type NotEnoughCandidatesEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type Notification = {
  __typename: 'Notification'
  emailStatus: NotificationEmailStatus
  entityId?: Maybe<Scalars['String']>
  eventId: Scalars['String']
  id: Scalars['Int']
  isRead: Scalars['Boolean']
  kind: NotificationKind
}

export enum NotificationEmailStatus {
  Failed = 'FAILED',
  Ignored = 'IGNORED',
  Pending = 'PENDING',
  Sent = 'SENT',
}

export enum NotificationKind {
  ElectionAnnouncingStarted = 'ELECTION_ANNOUNCING_STARTED',
  ElectionRevealingStarted = 'ELECTION_REVEALING_STARTED',
  ElectionVotingStarted = 'ELECTION_VOTING_STARTED',
  ForumCategoryEntityPost = 'FORUM_CATEGORY_ENTITY_POST',
  ForumCategoryEntityThread = 'FORUM_CATEGORY_ENTITY_THREAD',
  ForumPostAll = 'FORUM_POST_ALL',
  ForumPostMention = 'FORUM_POST_MENTION',
  ForumPostReply = 'FORUM_POST_REPLY',
  ForumThreadAll = 'FORUM_THREAD_ALL',
  ForumThreadContributor = 'FORUM_THREAD_CONTRIBUTOR',
  ForumThreadCreator = 'FORUM_THREAD_CREATOR',
  ForumThreadEntityPost = 'FORUM_THREAD_ENTITY_POST',
  ForumThreadMention = 'FORUM_THREAD_MENTION',
}

export type OfferAcceptedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'OfferAcceptedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    /** Price for which the NFT was bought */
    price?: Maybe<Scalars['BigInt']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
  }

export type OfferAcceptedEventConnection = {
  __typename: 'OfferAcceptedEventConnection'
  edges: Array<OfferAcceptedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type OfferAcceptedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  price?: InputMaybe<Scalars['String']>
  video: Scalars['ID']
}

export type OfferAcceptedEventEdge = {
  __typename: 'OfferAcceptedEventEdge'
  cursor: Scalars['String']
  node: OfferAcceptedEvent
}

export enum OfferAcceptedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type OfferAcceptedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  price?: InputMaybe<Scalars['String']>
  video?: InputMaybe<Scalars['ID']>
}

export type OfferAcceptedEventWhereInput = {
  AND?: InputMaybe<Array<OfferAcceptedEventWhereInput>>
  NOT?: InputMaybe<Array<OfferAcceptedEventWhereInput>>
  OR?: InputMaybe<Array<OfferAcceptedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  price_eq?: InputMaybe<Scalars['BigInt']>
  price_gt?: InputMaybe<Scalars['BigInt']>
  price_gte?: InputMaybe<Scalars['BigInt']>
  price_in?: InputMaybe<Array<Scalars['BigInt']>>
  price_lt?: InputMaybe<Scalars['BigInt']>
  price_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type OfferAcceptedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type OfferCanceledEvent = BaseGraphQlObject &
  Event & {
    __typename: 'OfferCanceledEvent'
    /** Content actor acting as NFT owner. */
    contentActor: ContentActor
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
  }

export type OfferCanceledEventConnection = {
  __typename: 'OfferCanceledEventConnection'
  edges: Array<OfferCanceledEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type OfferCanceledEventCreateInput = {
  contentActor: Scalars['JSONObject']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video: Scalars['ID']
}

export type OfferCanceledEventEdge = {
  __typename: 'OfferCanceledEventEdge'
  cursor: Scalars['String']
  node: OfferCanceledEvent
}

export enum OfferCanceledEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type OfferCanceledEventUpdateInput = {
  contentActor?: InputMaybe<Scalars['JSONObject']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video?: InputMaybe<Scalars['ID']>
}

export type OfferCanceledEventWhereInput = {
  AND?: InputMaybe<Array<OfferCanceledEventWhereInput>>
  NOT?: InputMaybe<Array<OfferCanceledEventWhereInput>>
  OR?: InputMaybe<Array<OfferCanceledEventWhereInput>>
  contentActor_json?: InputMaybe<Scalars['JSONObject']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type OfferCanceledEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type OfferStartedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'OfferStartedEvent'
    /** Content actor acting as NFT owner. */
    contentActor: ContentActor
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    member: Membership
    memberId: Scalars['String']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    /** Offer's price. */
    price?: Maybe<Scalars['BigInt']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
  }

export type OfferStartedEventConnection = {
  __typename: 'OfferStartedEventConnection'
  edges: Array<OfferStartedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type OfferStartedEventCreateInput = {
  contentActor: Scalars['JSONObject']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  member: Scalars['ID']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  price?: InputMaybe<Scalars['String']>
  video: Scalars['ID']
}

export type OfferStartedEventEdge = {
  __typename: 'OfferStartedEventEdge'
  cursor: Scalars['String']
  node: OfferStartedEvent
}

export enum OfferStartedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type OfferStartedEventUpdateInput = {
  contentActor?: InputMaybe<Scalars['JSONObject']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  member?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  price?: InputMaybe<Scalars['String']>
  video?: InputMaybe<Scalars['ID']>
}

export type OfferStartedEventWhereInput = {
  AND?: InputMaybe<Array<OfferStartedEventWhereInput>>
  NOT?: InputMaybe<Array<OfferStartedEventWhereInput>>
  OR?: InputMaybe<Array<OfferStartedEventWhereInput>>
  contentActor_json?: InputMaybe<Scalars['JSONObject']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  member?: InputMaybe<MembershipWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  price_eq?: InputMaybe<Scalars['BigInt']>
  price_gt?: InputMaybe<Scalars['BigInt']>
  price_gte?: InputMaybe<Scalars['BigInt']>
  price_in?: InputMaybe<Array<Scalars['BigInt']>>
  price_lt?: InputMaybe<Scalars['BigInt']>
  price_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type OfferStartedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type OpenAuctionBidAcceptedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'OpenAuctionBidAcceptedEvent'
    bidders: Array<Membership>
    /** Content actor that accepted the bid. */
    contentActor: ContentActor
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
    winningBid?: Maybe<Bid>
    winningBidId?: Maybe<Scalars['String']>
    winningBidder?: Maybe<Membership>
    winningBidderId?: Maybe<Scalars['String']>
  }

export type OpenAuctionBidAcceptedEventConnection = {
  __typename: 'OpenAuctionBidAcceptedEventConnection'
  edges: Array<OpenAuctionBidAcceptedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type OpenAuctionBidAcceptedEventCreateInput = {
  contentActor: Scalars['JSONObject']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video: Scalars['ID']
  winningBid?: InputMaybe<Scalars['ID']>
  winningBidder?: InputMaybe<Scalars['ID']>
}

export type OpenAuctionBidAcceptedEventEdge = {
  __typename: 'OpenAuctionBidAcceptedEventEdge'
  cursor: Scalars['String']
  node: OpenAuctionBidAcceptedEvent
}

export enum OpenAuctionBidAcceptedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
  WinningBidAsc = 'winningBid_ASC',
  WinningBidDesc = 'winningBid_DESC',
  WinningBidderAsc = 'winningBidder_ASC',
  WinningBidderDesc = 'winningBidder_DESC',
}

export type OpenAuctionBidAcceptedEventUpdateInput = {
  contentActor?: InputMaybe<Scalars['JSONObject']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video?: InputMaybe<Scalars['ID']>
  winningBid?: InputMaybe<Scalars['ID']>
  winningBidder?: InputMaybe<Scalars['ID']>
}

export type OpenAuctionBidAcceptedEventWhereInput = {
  AND?: InputMaybe<Array<OpenAuctionBidAcceptedEventWhereInput>>
  NOT?: InputMaybe<Array<OpenAuctionBidAcceptedEventWhereInput>>
  OR?: InputMaybe<Array<OpenAuctionBidAcceptedEventWhereInput>>
  bidders_every?: InputMaybe<MembershipWhereInput>
  bidders_none?: InputMaybe<MembershipWhereInput>
  bidders_some?: InputMaybe<MembershipWhereInput>
  contentActor_json?: InputMaybe<Scalars['JSONObject']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
  winningBid?: InputMaybe<BidWhereInput>
  winningBidder?: InputMaybe<MembershipWhereInput>
}

export type OpenAuctionBidAcceptedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type OpenAuctionStartedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'OpenAuctionStartedEvent'
    /** Actor that started this auction. */
    actor: ContentActor
    auction: Auction
    auctionId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted. */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in. */
    network: Network
    ownerCuratorGroup?: Maybe<CuratorGroup>
    ownerCuratorGroupId?: Maybe<Scalars['String']>
    ownerMember?: Maybe<Membership>
    ownerMemberId?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
  }

export type OpenAuctionStartedEventConnection = {
  __typename: 'OpenAuctionStartedEventConnection'
  edges: Array<OpenAuctionStartedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type OpenAuctionStartedEventCreateInput = {
  actor: Scalars['JSONObject']
  auction: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video: Scalars['ID']
}

export type OpenAuctionStartedEventEdge = {
  __typename: 'OpenAuctionStartedEventEdge'
  cursor: Scalars['String']
  node: OpenAuctionStartedEvent
}

export enum OpenAuctionStartedEventOrderByInput {
  AuctionAsc = 'auction_ASC',
  AuctionDesc = 'auction_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type OpenAuctionStartedEventUpdateInput = {
  actor?: InputMaybe<Scalars['JSONObject']>
  auction?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  video?: InputMaybe<Scalars['ID']>
}

export type OpenAuctionStartedEventWhereInput = {
  AND?: InputMaybe<Array<OpenAuctionStartedEventWhereInput>>
  NOT?: InputMaybe<Array<OpenAuctionStartedEventWhereInput>>
  OR?: InputMaybe<Array<OpenAuctionStartedEventWhereInput>>
  actor_json?: InputMaybe<Scalars['JSONObject']>
  auction?: InputMaybe<AuctionWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type OpenAuctionStartedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type OpeningAddedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'OpeningAddedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    opening: WorkingGroupOpening
    openingId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type OpeningAddedEventConnection = {
  __typename: 'OpeningAddedEventConnection'
  edges: Array<OpeningAddedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type OpeningAddedEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  opening: Scalars['ID']
}

export type OpeningAddedEventEdge = {
  __typename: 'OpeningAddedEventEdge'
  cursor: Scalars['String']
  node: OpeningAddedEvent
}

export enum OpeningAddedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OpeningAsc = 'opening_ASC',
  OpeningDesc = 'opening_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type OpeningAddedEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  opening?: InputMaybe<Scalars['ID']>
}

export type OpeningAddedEventWhereInput = {
  AND?: InputMaybe<Array<OpeningAddedEventWhereInput>>
  NOT?: InputMaybe<Array<OpeningAddedEventWhereInput>>
  OR?: InputMaybe<Array<OpeningAddedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  opening?: InputMaybe<WorkingGroupOpeningWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type OpeningAddedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type OpeningCanceledEvent = BaseGraphQlObject &
  Event & {
    __typename: 'OpeningCanceledEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    opening: WorkingGroupOpening
    openingId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type OpeningCanceledEventConnection = {
  __typename: 'OpeningCanceledEventConnection'
  edges: Array<OpeningCanceledEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type OpeningCanceledEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  opening: Scalars['ID']
}

export type OpeningCanceledEventEdge = {
  __typename: 'OpeningCanceledEventEdge'
  cursor: Scalars['String']
  node: OpeningCanceledEvent
}

export enum OpeningCanceledEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OpeningAsc = 'opening_ASC',
  OpeningDesc = 'opening_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type OpeningCanceledEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  opening?: InputMaybe<Scalars['ID']>
}

export type OpeningCanceledEventWhereInput = {
  AND?: InputMaybe<Array<OpeningCanceledEventWhereInput>>
  NOT?: InputMaybe<Array<OpeningCanceledEventWhereInput>>
  OR?: InputMaybe<Array<OpeningCanceledEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  opening?: InputMaybe<WorkingGroupOpeningWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type OpeningCanceledEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type OpeningFilledEvent = BaseGraphQlObject &
  Event & {
    __typename: 'OpeningFilledEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    opening: WorkingGroupOpening
    openingId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    workersHired: Array<Worker>
  }

export type OpeningFilledEventConnection = {
  __typename: 'OpeningFilledEventConnection'
  edges: Array<OpeningFilledEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type OpeningFilledEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  opening: Scalars['ID']
}

export type OpeningFilledEventEdge = {
  __typename: 'OpeningFilledEventEdge'
  cursor: Scalars['String']
  node: OpeningFilledEvent
}

export enum OpeningFilledEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OpeningAsc = 'opening_ASC',
  OpeningDesc = 'opening_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type OpeningFilledEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  opening?: InputMaybe<Scalars['ID']>
}

export type OpeningFilledEventWhereInput = {
  AND?: InputMaybe<Array<OpeningFilledEventWhereInput>>
  NOT?: InputMaybe<Array<OpeningFilledEventWhereInput>>
  OR?: InputMaybe<Array<OpeningFilledEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  opening?: InputMaybe<WorkingGroupOpeningWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  workersHired_every?: InputMaybe<WorkerWhereInput>
  workersHired_none?: InputMaybe<WorkerWhereInput>
  workersHired_some?: InputMaybe<WorkerWhereInput>
}

export type OpeningFilledEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type OpeningStatusCancelled = {
  __typename: 'OpeningStatusCancelled'
  /** Related event emitted on opening cancellation */
  openingCanceledEvent?: Maybe<OpeningCanceledEvent>
}

export type OpeningStatusFilled = {
  __typename: 'OpeningStatusFilled'
  /** Related event emitted after filling the opening */
  openingFilledEvent?: Maybe<OpeningFilledEvent>
}

export type OpeningStatusOpen = {
  __typename: 'OpeningStatusOpen'
  phantom?: Maybe<Scalars['Int']>
}

export type OracleJudgmentSubmittedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'OracleJudgmentSubmittedEvent'
    bounty: Bounty
    bountyId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Rationale of the judgment */
    rationale?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type OracleJudgmentSubmittedEventConnection = {
  __typename: 'OracleJudgmentSubmittedEventConnection'
  edges: Array<OracleJudgmentSubmittedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type OracleJudgmentSubmittedEventCreateInput = {
  bounty: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  rationale?: InputMaybe<Scalars['String']>
}

export type OracleJudgmentSubmittedEventEdge = {
  __typename: 'OracleJudgmentSubmittedEventEdge'
  cursor: Scalars['String']
  node: OracleJudgmentSubmittedEvent
}

export enum OracleJudgmentSubmittedEventOrderByInput {
  BountyAsc = 'bounty_ASC',
  BountyDesc = 'bounty_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type OracleJudgmentSubmittedEventUpdateInput = {
  bounty?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  rationale?: InputMaybe<Scalars['String']>
}

export type OracleJudgmentSubmittedEventWhereInput = {
  AND?: InputMaybe<Array<OracleJudgmentSubmittedEventWhereInput>>
  NOT?: InputMaybe<Array<OracleJudgmentSubmittedEventWhereInput>>
  OR?: InputMaybe<Array<OracleJudgmentSubmittedEventWhereInput>>
  bounty?: InputMaybe<BountyWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type OracleJudgmentSubmittedEventWhereUniqueInput = {
  id: Scalars['ID']
}

/** Represents NFT details */
export type OwnedNft = BaseGraphQlObject & {
  __typename: 'OwnedNft'
  auctions: Array<Auction>
  bids: Array<Bid>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  creatorChannel: Channel
  creatorChannelId: Scalars['String']
  /** Creator royalty */
  creatorRoyalty?: Maybe<Scalars['Float']>
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Is NFT owned by channel */
  isOwnedByChannel: Scalars['Boolean']
  /** NFT's last sale date (if any) */
  lastSaleDate?: Maybe<Scalars['DateTime']>
  /** NFT's last sale price (if any) */
  lastSalePrice?: Maybe<Scalars['BigInt']>
  /** NFT's metadata */
  metadata: Scalars['String']
  ownerCuratorGroup?: Maybe<CuratorGroup>
  ownerCuratorGroupId?: Maybe<Scalars['String']>
  ownerMember?: Maybe<Membership>
  ownerMemberId?: Maybe<Scalars['String']>
  /** NFT's non-auction transactional status (if any) */
  transactionalStatus?: Maybe<TransactionalStatus>
  transactionalStatusAuction?: Maybe<Auction>
  transactionalStatusAuctionId?: Maybe<Scalars['String']>
  transactionalStatusUpdates: Array<TransactionalStatusUpdate>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  video: Video
  videoCategory?: Maybe<VideoCategory>
  videoCategoryId?: Maybe<Scalars['String']>
}

export type OwnedNftConnection = {
  __typename: 'OwnedNftConnection'
  edges: Array<OwnedNftEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type OwnedNftCreateInput = {
  creatorChannel: Scalars['ID']
  creatorRoyalty?: InputMaybe<Scalars['Float']>
  isOwnedByChannel: Scalars['Boolean']
  lastSaleDate?: InputMaybe<Scalars['DateTime']>
  lastSalePrice?: InputMaybe<Scalars['String']>
  metadata: Scalars['String']
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  transactionalStatus: Scalars['JSONObject']
  transactionalStatusAuction?: InputMaybe<Scalars['ID']>
  videoCategory?: InputMaybe<Scalars['ID']>
}

export type OwnedNftEdge = {
  __typename: 'OwnedNftEdge'
  cursor: Scalars['String']
  node: OwnedNft
}

export enum OwnedNftOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CreatorChannelAsc = 'creatorChannel_ASC',
  CreatorChannelDesc = 'creatorChannel_DESC',
  CreatorRoyaltyAsc = 'creatorRoyalty_ASC',
  CreatorRoyaltyDesc = 'creatorRoyalty_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IsOwnedByChannelAsc = 'isOwnedByChannel_ASC',
  IsOwnedByChannelDesc = 'isOwnedByChannel_DESC',
  LastSaleDateAsc = 'lastSaleDate_ASC',
  LastSaleDateDesc = 'lastSaleDate_DESC',
  LastSalePriceAsc = 'lastSalePrice_ASC',
  LastSalePriceDesc = 'lastSalePrice_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  OwnerCuratorGroupAsc = 'ownerCuratorGroup_ASC',
  OwnerCuratorGroupDesc = 'ownerCuratorGroup_DESC',
  OwnerMemberAsc = 'ownerMember_ASC',
  OwnerMemberDesc = 'ownerMember_DESC',
  TransactionalStatusAuctionAsc = 'transactionalStatusAuction_ASC',
  TransactionalStatusAuctionDesc = 'transactionalStatusAuction_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoCategoryAsc = 'videoCategory_ASC',
  VideoCategoryDesc = 'videoCategory_DESC',
}

export type OwnedNftUpdateInput = {
  creatorChannel?: InputMaybe<Scalars['ID']>
  creatorRoyalty?: InputMaybe<Scalars['Float']>
  isOwnedByChannel?: InputMaybe<Scalars['Boolean']>
  lastSaleDate?: InputMaybe<Scalars['DateTime']>
  lastSalePrice?: InputMaybe<Scalars['String']>
  metadata?: InputMaybe<Scalars['String']>
  ownerCuratorGroup?: InputMaybe<Scalars['ID']>
  ownerMember?: InputMaybe<Scalars['ID']>
  transactionalStatus?: InputMaybe<Scalars['JSONObject']>
  transactionalStatusAuction?: InputMaybe<Scalars['ID']>
  videoCategory?: InputMaybe<Scalars['ID']>
}

export type OwnedNftWhereInput = {
  AND?: InputMaybe<Array<OwnedNftWhereInput>>
  NOT?: InputMaybe<Array<OwnedNftWhereInput>>
  OR?: InputMaybe<Array<OwnedNftWhereInput>>
  auctions_every?: InputMaybe<AuctionWhereInput>
  auctions_none?: InputMaybe<AuctionWhereInput>
  auctions_some?: InputMaybe<AuctionWhereInput>
  bids_every?: InputMaybe<BidWhereInput>
  bids_none?: InputMaybe<BidWhereInput>
  bids_some?: InputMaybe<BidWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  creatorChannel?: InputMaybe<ChannelWhereInput>
  creatorRoyalty_eq?: InputMaybe<Scalars['Float']>
  creatorRoyalty_gt?: InputMaybe<Scalars['Float']>
  creatorRoyalty_gte?: InputMaybe<Scalars['Float']>
  creatorRoyalty_in?: InputMaybe<Array<Scalars['Float']>>
  creatorRoyalty_lt?: InputMaybe<Scalars['Float']>
  creatorRoyalty_lte?: InputMaybe<Scalars['Float']>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  isOwnedByChannel_eq?: InputMaybe<Scalars['Boolean']>
  isOwnedByChannel_in?: InputMaybe<Array<Scalars['Boolean']>>
  lastSaleDate_eq?: InputMaybe<Scalars['DateTime']>
  lastSaleDate_gt?: InputMaybe<Scalars['DateTime']>
  lastSaleDate_gte?: InputMaybe<Scalars['DateTime']>
  lastSaleDate_lt?: InputMaybe<Scalars['DateTime']>
  lastSaleDate_lte?: InputMaybe<Scalars['DateTime']>
  lastSalePrice_eq?: InputMaybe<Scalars['BigInt']>
  lastSalePrice_gt?: InputMaybe<Scalars['BigInt']>
  lastSalePrice_gte?: InputMaybe<Scalars['BigInt']>
  lastSalePrice_in?: InputMaybe<Array<Scalars['BigInt']>>
  lastSalePrice_lt?: InputMaybe<Scalars['BigInt']>
  lastSalePrice_lte?: InputMaybe<Scalars['BigInt']>
  metadata_contains?: InputMaybe<Scalars['String']>
  metadata_endsWith?: InputMaybe<Scalars['String']>
  metadata_eq?: InputMaybe<Scalars['String']>
  metadata_in?: InputMaybe<Array<Scalars['String']>>
  metadata_startsWith?: InputMaybe<Scalars['String']>
  ownerCuratorGroup?: InputMaybe<CuratorGroupWhereInput>
  ownerMember?: InputMaybe<MembershipWhereInput>
  transactionalStatusAuction?: InputMaybe<AuctionWhereInput>
  transactionalStatusUpdates_every?: InputMaybe<TransactionalStatusUpdateWhereInput>
  transactionalStatusUpdates_none?: InputMaybe<TransactionalStatusUpdateWhereInput>
  transactionalStatusUpdates_some?: InputMaybe<TransactionalStatusUpdateWhereInput>
  transactionalStatus_json?: InputMaybe<Scalars['JSONObject']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
  videoCategory?: InputMaybe<VideoCategoryWhereInput>
}

export type OwnedNftWhereUniqueInput = {
  id: Scalars['ID']
}

export type PageInfo = {
  __typename: 'PageInfo'
  endCursor?: Maybe<Scalars['String']>
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor?: Maybe<Scalars['String']>
}

export type PaymentContext = PaymentContextChannel | PaymentContextVideo

export type PaymentContextChannel = {
  __typename: 'PaymentContextChannel'
  /** Channel for which the payment was made */
  channel?: Maybe<Channel>
}

export type PaymentContextVideo = {
  __typename: 'PaymentContextVideo'
  /** Video for which the payment was made */
  video?: Maybe<Video>
}

export type PostAddedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'PostAddedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Whether the added post is editable */
    isEditable?: Maybe<Scalars['Boolean']>
    /** Network the block was produced in */
    network: Network
    post: ForumPost
    postId: Scalars['String']
    /** Post's original text */
    text: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type PostAddedEventConnection = {
  __typename: 'PostAddedEventConnection'
  edges: Array<PostAddedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type PostAddedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  isEditable?: InputMaybe<Scalars['Boolean']>
  network: Network
  post: Scalars['ID']
  text: Scalars['String']
}

export type PostAddedEventEdge = {
  __typename: 'PostAddedEventEdge'
  cursor: Scalars['String']
  node: PostAddedEvent
}

export enum PostAddedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  IsEditableAsc = 'isEditable_ASC',
  IsEditableDesc = 'isEditable_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PostAsc = 'post_ASC',
  PostDesc = 'post_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type PostAddedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  isEditable?: InputMaybe<Scalars['Boolean']>
  network?: InputMaybe<Network>
  post?: InputMaybe<Scalars['ID']>
  text?: InputMaybe<Scalars['String']>
}

export type PostAddedEventWhereInput = {
  AND?: InputMaybe<Array<PostAddedEventWhereInput>>
  NOT?: InputMaybe<Array<PostAddedEventWhereInput>>
  OR?: InputMaybe<Array<PostAddedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  isEditable_eq?: InputMaybe<Scalars['Boolean']>
  isEditable_in?: InputMaybe<Array<Scalars['Boolean']>>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  post?: InputMaybe<ForumPostWhereInput>
  text_contains?: InputMaybe<Scalars['String']>
  text_endsWith?: InputMaybe<Scalars['String']>
  text_eq?: InputMaybe<Scalars['String']>
  text_in?: InputMaybe<Array<Scalars['String']>>
  text_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type PostAddedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type PostDeletedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'PostDeletedEvent'
    actor: Membership
    actorId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    posts: Array<ForumPost>
    /** Posts deletion rationale */
    rationale: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type PostDeletedEventConnection = {
  __typename: 'PostDeletedEventConnection'
  edges: Array<PostDeletedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type PostDeletedEventCreateInput = {
  actor: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  rationale: Scalars['String']
}

export type PostDeletedEventEdge = {
  __typename: 'PostDeletedEventEdge'
  cursor: Scalars['String']
  node: PostDeletedEvent
}

export enum PostDeletedEventOrderByInput {
  ActorAsc = 'actor_ASC',
  ActorDesc = 'actor_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type PostDeletedEventUpdateInput = {
  actor?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  rationale?: InputMaybe<Scalars['String']>
}

export type PostDeletedEventWhereInput = {
  AND?: InputMaybe<Array<PostDeletedEventWhereInput>>
  NOT?: InputMaybe<Array<PostDeletedEventWhereInput>>
  OR?: InputMaybe<Array<PostDeletedEventWhereInput>>
  actor?: InputMaybe<MembershipWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  posts_every?: InputMaybe<ForumPostWhereInput>
  posts_none?: InputMaybe<ForumPostWhereInput>
  posts_some?: InputMaybe<ForumPostWhereInput>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type PostDeletedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type PostModeratedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'PostModeratedEvent'
    actor: Worker
    actorId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    post: ForumPost
    postId: Scalars['String']
    /** The rationale behind the moderation */
    rationale: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type PostModeratedEventConnection = {
  __typename: 'PostModeratedEventConnection'
  edges: Array<PostModeratedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type PostModeratedEventCreateInput = {
  actor: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  post: Scalars['ID']
  rationale: Scalars['String']
}

export type PostModeratedEventEdge = {
  __typename: 'PostModeratedEventEdge'
  cursor: Scalars['String']
  node: PostModeratedEvent
}

export enum PostModeratedEventOrderByInput {
  ActorAsc = 'actor_ASC',
  ActorDesc = 'actor_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PostAsc = 'post_ASC',
  PostDesc = 'post_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type PostModeratedEventUpdateInput = {
  actor?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  post?: InputMaybe<Scalars['ID']>
  rationale?: InputMaybe<Scalars['String']>
}

export type PostModeratedEventWhereInput = {
  AND?: InputMaybe<Array<PostModeratedEventWhereInput>>
  NOT?: InputMaybe<Array<PostModeratedEventWhereInput>>
  OR?: InputMaybe<Array<PostModeratedEventWhereInput>>
  actor?: InputMaybe<WorkerWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  post?: InputMaybe<ForumPostWhereInput>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type PostModeratedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type PostOrigin = PostOriginThreadInitial | PostOriginThreadReply

export type PostOriginThreadInitial = {
  __typename: 'PostOriginThreadInitial'
  /** Thread creation event */
  threadCreatedEvent?: Maybe<ThreadCreatedEvent>
}

export type PostOriginThreadReply = {
  __typename: 'PostOriginThreadReply'
  /** Related PostAdded event */
  postAddedEvent?: Maybe<PostAddedEvent>
}

export type PostStatus = PostStatusActive | PostStatusLocked | PostStatusModerated | PostStatusRemoved

export type PostStatusActive = {
  __typename: 'PostStatusActive'
  phantom?: Maybe<Scalars['Int']>
}

export type PostStatusLocked = {
  __typename: 'PostStatusLocked'
  /** Post deleted event in case the post became locked through runtime removal */
  postDeletedEvent?: Maybe<PostDeletedEvent>
}

export type PostStatusModerated = {
  __typename: 'PostStatusModerated'
  /** Event the post was moderated in */
  postModeratedEvent?: Maybe<PostModeratedEvent>
}

export type PostStatusRemoved = {
  __typename: 'PostStatusRemoved'
  /** Event the post was removed in */
  postDeletedEvent?: Maybe<PostDeletedEvent>
}

export type PostTextUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'PostTextUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** New post text */
    newText: Scalars['String']
    post: ForumPost
    postId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type PostTextUpdatedEventConnection = {
  __typename: 'PostTextUpdatedEventConnection'
  edges: Array<PostTextUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type PostTextUpdatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newText: Scalars['String']
  post: Scalars['ID']
}

export type PostTextUpdatedEventEdge = {
  __typename: 'PostTextUpdatedEventEdge'
  cursor: Scalars['String']
  node: PostTextUpdatedEvent
}

export enum PostTextUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewTextAsc = 'newText_ASC',
  NewTextDesc = 'newText_DESC',
  PostAsc = 'post_ASC',
  PostDesc = 'post_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type PostTextUpdatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newText?: InputMaybe<Scalars['String']>
  post?: InputMaybe<Scalars['ID']>
}

export type PostTextUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<PostTextUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<PostTextUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<PostTextUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newText_contains?: InputMaybe<Scalars['String']>
  newText_endsWith?: InputMaybe<Scalars['String']>
  newText_eq?: InputMaybe<Scalars['String']>
  newText_in?: InputMaybe<Array<Scalars['String']>>
  newText_startsWith?: InputMaybe<Scalars['String']>
  post?: InputMaybe<ForumPostWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type PostTextUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type PostsByTextFtsOutput = {
  __typename: 'PostsByTextFTSOutput'
  highlight: Scalars['String']
  isTypeOf: Scalars['String']
  item: PostsByTextSearchResult
  rank: Scalars['Float']
}

export type PostsByTextSearchResult = ForumPost

export type ProcessorState = {
  __typename: 'ProcessorState'
  chainHead: Scalars['Float']
  indexerHead: Scalars['Float']
  lastCompleteBlock: Scalars['Float']
  lastProcessedEvent: Scalars['String']
}

export type Proposal = BaseGraphQlObject & {
  __typename: 'Proposal'
  /** How many prior councils have already approved the proposal (starts with 0) */
  councilApprovals: Scalars['Int']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  createdInEvent: ProposalCreatedEvent
  creator: Membership
  creatorId: Scalars['String']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** Proposal description */
  description: Scalars['String']
  /** Proposal details depending on proposal type */
  details: ProposalDetails
  discussionThread: ProposalDiscussionThread
  /** Exact block number the proposal is supposed to be executed at (if specified) */
  exactExecutionBlock?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  /** If true then the proposal status is final and will not change form this point */
  isFinalized?: Maybe<Scalars['Boolean']>
  proposalStatusUpdates: Array<ProposalStatusUpdatedEvent>
  proposalcancelledeventproposal?: Maybe<Array<ProposalCancelledEvent>>
  proposaldecisionmadeeventproposal?: Maybe<Array<ProposalDecisionMadeEvent>>
  proposalexecutedeventproposal?: Maybe<Array<ProposalExecutedEvent>>
  /** Staking account with proposal stake (in case a stake is required) */
  stakingAccount?: Maybe<Scalars['String']>
  /** Current proposal status */
  status: ProposalStatus
  /** Number of the block the current status was set at */
  statusSetAtBlock: Scalars['Int']
  /** Time the current status was set at (based on block timestamp) */
  statusSetAtTime: Scalars['DateTime']
  /** Proposal title */
  title: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  votes: Array<ProposalVotedEvent>
}

export type ProposalCancelledEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ProposalCancelledEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    proposal: Proposal
    proposalId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ProposalCancelledEventConnection = {
  __typename: 'ProposalCancelledEventConnection'
  edges: Array<ProposalCancelledEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProposalCancelledEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  proposal: Scalars['ID']
}

export type ProposalCancelledEventEdge = {
  __typename: 'ProposalCancelledEventEdge'
  cursor: Scalars['String']
  node: ProposalCancelledEvent
}

export enum ProposalCancelledEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ProposalAsc = 'proposal_ASC',
  ProposalDesc = 'proposal_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProposalCancelledEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  proposal?: InputMaybe<Scalars['ID']>
}

export type ProposalCancelledEventWhereInput = {
  AND?: InputMaybe<Array<ProposalCancelledEventWhereInput>>
  NOT?: InputMaybe<Array<ProposalCancelledEventWhereInput>>
  OR?: InputMaybe<Array<ProposalCancelledEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  proposal?: InputMaybe<ProposalWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ProposalCancelledEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ProposalConnection = {
  __typename: 'ProposalConnection'
  edges: Array<ProposalEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProposalCreateInput = {
  councilApprovals: Scalars['Float']
  creator: Scalars['ID']
  description: Scalars['String']
  details: Scalars['JSONObject']
  exactExecutionBlock?: InputMaybe<Scalars['Float']>
  isFinalized?: InputMaybe<Scalars['Boolean']>
  stakingAccount?: InputMaybe<Scalars['String']>
  status: Scalars['JSONObject']
  statusSetAtBlock: Scalars['Float']
  statusSetAtTime: Scalars['DateTime']
  title: Scalars['String']
}

export type ProposalCreatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ProposalCreatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    proposal: Proposal
    proposalId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ProposalCreatedEventConnection = {
  __typename: 'ProposalCreatedEventConnection'
  edges: Array<ProposalCreatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProposalCreatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  proposal: Scalars['ID']
}

export type ProposalCreatedEventEdge = {
  __typename: 'ProposalCreatedEventEdge'
  cursor: Scalars['String']
  node: ProposalCreatedEvent
}

export enum ProposalCreatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ProposalAsc = 'proposal_ASC',
  ProposalDesc = 'proposal_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProposalCreatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  proposal?: InputMaybe<Scalars['ID']>
}

export type ProposalCreatedEventWhereInput = {
  AND?: InputMaybe<Array<ProposalCreatedEventWhereInput>>
  NOT?: InputMaybe<Array<ProposalCreatedEventWhereInput>>
  OR?: InputMaybe<Array<ProposalCreatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  proposal?: InputMaybe<ProposalWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ProposalCreatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ProposalDecisionMadeEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ProposalDecisionMadeEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    /** The voting decision status */
    decisionStatus: ProposalDecisionStatus
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    proposal: Proposal
    proposalId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ProposalDecisionMadeEventConnection = {
  __typename: 'ProposalDecisionMadeEventConnection'
  edges: Array<ProposalDecisionMadeEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProposalDecisionMadeEventCreateInput = {
  decisionStatus: Scalars['JSONObject']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  proposal: Scalars['ID']
}

export type ProposalDecisionMadeEventEdge = {
  __typename: 'ProposalDecisionMadeEventEdge'
  cursor: Scalars['String']
  node: ProposalDecisionMadeEvent
}

export enum ProposalDecisionMadeEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ProposalAsc = 'proposal_ASC',
  ProposalDesc = 'proposal_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProposalDecisionMadeEventUpdateInput = {
  decisionStatus?: InputMaybe<Scalars['JSONObject']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  proposal?: InputMaybe<Scalars['ID']>
}

export type ProposalDecisionMadeEventWhereInput = {
  AND?: InputMaybe<Array<ProposalDecisionMadeEventWhereInput>>
  NOT?: InputMaybe<Array<ProposalDecisionMadeEventWhereInput>>
  OR?: InputMaybe<Array<ProposalDecisionMadeEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  decisionStatus_json?: InputMaybe<Scalars['JSONObject']>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  proposal?: InputMaybe<ProposalWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ProposalDecisionMadeEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ProposalDecisionStatus =
  | ProposalStatusCanceledByRuntime
  | ProposalStatusCancelled
  | ProposalStatusDormant
  | ProposalStatusExpired
  | ProposalStatusGracing
  | ProposalStatusRejected
  | ProposalStatusSlashed
  | ProposalStatusVetoed

export type ProposalDetails =
  | AmendConstitutionProposalDetails
  | CancelWorkingGroupLeadOpeningProposalDetails
  | CreateWorkingGroupLeadOpeningProposalDetails
  | DecreaseWorkingGroupLeadStakeProposalDetails
  | FillWorkingGroupLeadOpeningProposalDetails
  | FundingRequestProposalDetails
  | RuntimeUpgradeProposalDetails
  | SetCouncilBudgetIncrementProposalDetails
  | SetCouncilorRewardProposalDetails
  | SetInitialInvitationBalanceProposalDetails
  | SetInitialInvitationCountProposalDetails
  | SetMaxValidatorCountProposalDetails
  | SetMembershipLeadInvitationQuotaProposalDetails
  | SetMembershipPriceProposalDetails
  | SetReferralCutProposalDetails
  | SetWorkingGroupLeadRewardProposalDetails
  | SignalProposalDetails
  | SlashWorkingGroupLeadProposalDetails
  | TerminateWorkingGroupLeadProposalDetails
  | UpdateChannelPayoutsProposalDetails
  | UpdateWorkingGroupBudgetProposalDetails
  | VetoProposalDetails

export type ProposalDiscussionPost = BaseGraphQlObject & {
  __typename: 'ProposalDiscussionPost'
  author: Membership
  authorId: Scalars['String']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  createdInEvent: ProposalDiscussionPostCreatedEvent
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  discussionThread: ProposalDiscussionThread
  discussionThreadId: Scalars['String']
  id: Scalars['ID']
  /** True if the post is either Active or Locked */
  isVisible: Scalars['Boolean']
  proposaldiscussionpostdeletedeventpost?: Maybe<Array<ProposalDiscussionPostDeletedEvent>>
  proposaldiscussionpostrepliesTo?: Maybe<Array<ProposalDiscussionPost>>
  repliesTo?: Maybe<ProposalDiscussionPost>
  repliesToId?: Maybe<Scalars['String']>
  /** Current post status */
  status: ProposalDiscussionPostStatus
  /** Post's md-formatted text */
  text: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  updates: Array<ProposalDiscussionPostUpdatedEvent>
  version: Scalars['Int']
}

export type ProposalDiscussionPostConnection = {
  __typename: 'ProposalDiscussionPostConnection'
  edges: Array<ProposalDiscussionPostEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProposalDiscussionPostCreateInput = {
  author: Scalars['ID']
  discussionThread: Scalars['ID']
  isVisible: Scalars['Boolean']
  repliesTo?: InputMaybe<Scalars['ID']>
  status: Scalars['JSONObject']
  text: Scalars['String']
}

export type ProposalDiscussionPostCreatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ProposalDiscussionPostCreatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    post: ProposalDiscussionPost
    postId: Scalars['String']
    /** Initial post text */
    text: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ProposalDiscussionPostCreatedEventConnection = {
  __typename: 'ProposalDiscussionPostCreatedEventConnection'
  edges: Array<ProposalDiscussionPostCreatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProposalDiscussionPostCreatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  post: Scalars['ID']
  text: Scalars['String']
}

export type ProposalDiscussionPostCreatedEventEdge = {
  __typename: 'ProposalDiscussionPostCreatedEventEdge'
  cursor: Scalars['String']
  node: ProposalDiscussionPostCreatedEvent
}

export enum ProposalDiscussionPostCreatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PostAsc = 'post_ASC',
  PostDesc = 'post_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProposalDiscussionPostCreatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  post?: InputMaybe<Scalars['ID']>
  text?: InputMaybe<Scalars['String']>
}

export type ProposalDiscussionPostCreatedEventWhereInput = {
  AND?: InputMaybe<Array<ProposalDiscussionPostCreatedEventWhereInput>>
  NOT?: InputMaybe<Array<ProposalDiscussionPostCreatedEventWhereInput>>
  OR?: InputMaybe<Array<ProposalDiscussionPostCreatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  post?: InputMaybe<ProposalDiscussionPostWhereInput>
  text_contains?: InputMaybe<Scalars['String']>
  text_endsWith?: InputMaybe<Scalars['String']>
  text_eq?: InputMaybe<Scalars['String']>
  text_in?: InputMaybe<Array<Scalars['String']>>
  text_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ProposalDiscussionPostCreatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ProposalDiscussionPostDeletedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ProposalDiscussionPostDeletedEvent'
    actor: Membership
    actorId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    post: ProposalDiscussionPost
    postId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ProposalDiscussionPostDeletedEventConnection = {
  __typename: 'ProposalDiscussionPostDeletedEventConnection'
  edges: Array<ProposalDiscussionPostDeletedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProposalDiscussionPostDeletedEventCreateInput = {
  actor: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  post: Scalars['ID']
}

export type ProposalDiscussionPostDeletedEventEdge = {
  __typename: 'ProposalDiscussionPostDeletedEventEdge'
  cursor: Scalars['String']
  node: ProposalDiscussionPostDeletedEvent
}

export enum ProposalDiscussionPostDeletedEventOrderByInput {
  ActorAsc = 'actor_ASC',
  ActorDesc = 'actor_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PostAsc = 'post_ASC',
  PostDesc = 'post_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProposalDiscussionPostDeletedEventUpdateInput = {
  actor?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  post?: InputMaybe<Scalars['ID']>
}

export type ProposalDiscussionPostDeletedEventWhereInput = {
  AND?: InputMaybe<Array<ProposalDiscussionPostDeletedEventWhereInput>>
  NOT?: InputMaybe<Array<ProposalDiscussionPostDeletedEventWhereInput>>
  OR?: InputMaybe<Array<ProposalDiscussionPostDeletedEventWhereInput>>
  actor?: InputMaybe<MembershipWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  post?: InputMaybe<ProposalDiscussionPostWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ProposalDiscussionPostDeletedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ProposalDiscussionPostEdge = {
  __typename: 'ProposalDiscussionPostEdge'
  cursor: Scalars['String']
  node: ProposalDiscussionPost
}

export enum ProposalDiscussionPostOrderByInput {
  AuthorAsc = 'author_ASC',
  AuthorDesc = 'author_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DiscussionThreadAsc = 'discussionThread_ASC',
  DiscussionThreadDesc = 'discussionThread_DESC',
  IsVisibleAsc = 'isVisible_ASC',
  IsVisibleDesc = 'isVisible_DESC',
  RepliesToAsc = 'repliesTo_ASC',
  RepliesToDesc = 'repliesTo_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProposalDiscussionPostStatus =
  | ProposalDiscussionPostStatusActive
  | ProposalDiscussionPostStatusLocked
  | ProposalDiscussionPostStatusRemoved

export type ProposalDiscussionPostStatusActive = {
  __typename: 'ProposalDiscussionPostStatusActive'
  phantom?: Maybe<Scalars['Int']>
}

export type ProposalDiscussionPostStatusLocked = {
  __typename: 'ProposalDiscussionPostStatusLocked'
  /** ProposalDiscussionPostDeletedEvent in case the post became locked through runtime removal */
  deletedInEvent?: Maybe<ProposalDiscussionPostDeletedEvent>
}

export type ProposalDiscussionPostStatusRemoved = {
  __typename: 'ProposalDiscussionPostStatusRemoved'
  /** The event the post was removed in */
  deletedInEvent?: Maybe<ProposalDiscussionPostDeletedEvent>
}

export type ProposalDiscussionPostUpdateInput = {
  author?: InputMaybe<Scalars['ID']>
  discussionThread?: InputMaybe<Scalars['ID']>
  isVisible?: InputMaybe<Scalars['Boolean']>
  repliesTo?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<Scalars['JSONObject']>
  text?: InputMaybe<Scalars['String']>
}

export type ProposalDiscussionPostUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ProposalDiscussionPostUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    post: ProposalDiscussionPost
    postId: Scalars['String']
    /** New post text */
    text: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ProposalDiscussionPostUpdatedEventConnection = {
  __typename: 'ProposalDiscussionPostUpdatedEventConnection'
  edges: Array<ProposalDiscussionPostUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProposalDiscussionPostUpdatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  post: Scalars['ID']
  text: Scalars['String']
}

export type ProposalDiscussionPostUpdatedEventEdge = {
  __typename: 'ProposalDiscussionPostUpdatedEventEdge'
  cursor: Scalars['String']
  node: ProposalDiscussionPostUpdatedEvent
}

export enum ProposalDiscussionPostUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PostAsc = 'post_ASC',
  PostDesc = 'post_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProposalDiscussionPostUpdatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  post?: InputMaybe<Scalars['ID']>
  text?: InputMaybe<Scalars['String']>
}

export type ProposalDiscussionPostUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<ProposalDiscussionPostUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<ProposalDiscussionPostUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<ProposalDiscussionPostUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  post?: InputMaybe<ProposalDiscussionPostWhereInput>
  text_contains?: InputMaybe<Scalars['String']>
  text_endsWith?: InputMaybe<Scalars['String']>
  text_eq?: InputMaybe<Scalars['String']>
  text_in?: InputMaybe<Array<Scalars['String']>>
  text_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ProposalDiscussionPostUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ProposalDiscussionPostWhereInput = {
  AND?: InputMaybe<Array<ProposalDiscussionPostWhereInput>>
  NOT?: InputMaybe<Array<ProposalDiscussionPostWhereInput>>
  OR?: InputMaybe<Array<ProposalDiscussionPostWhereInput>>
  author?: InputMaybe<MembershipWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  createdInEvent?: InputMaybe<ProposalDiscussionPostCreatedEventWhereInput>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  discussionThread?: InputMaybe<ProposalDiscussionThreadWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  isVisible_eq?: InputMaybe<Scalars['Boolean']>
  isVisible_in?: InputMaybe<Array<Scalars['Boolean']>>
  proposaldiscussionpostdeletedeventpost_every?: InputMaybe<ProposalDiscussionPostDeletedEventWhereInput>
  proposaldiscussionpostdeletedeventpost_none?: InputMaybe<ProposalDiscussionPostDeletedEventWhereInput>
  proposaldiscussionpostdeletedeventpost_some?: InputMaybe<ProposalDiscussionPostDeletedEventWhereInput>
  proposaldiscussionpostrepliesTo_every?: InputMaybe<ProposalDiscussionPostWhereInput>
  proposaldiscussionpostrepliesTo_none?: InputMaybe<ProposalDiscussionPostWhereInput>
  proposaldiscussionpostrepliesTo_some?: InputMaybe<ProposalDiscussionPostWhereInput>
  repliesTo?: InputMaybe<ProposalDiscussionPostWhereInput>
  status_json?: InputMaybe<Scalars['JSONObject']>
  text_contains?: InputMaybe<Scalars['String']>
  text_endsWith?: InputMaybe<Scalars['String']>
  text_eq?: InputMaybe<Scalars['String']>
  text_in?: InputMaybe<Array<Scalars['String']>>
  text_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  updates_every?: InputMaybe<ProposalDiscussionPostUpdatedEventWhereInput>
  updates_none?: InputMaybe<ProposalDiscussionPostUpdatedEventWhereInput>
  updates_some?: InputMaybe<ProposalDiscussionPostUpdatedEventWhereInput>
}

export type ProposalDiscussionPostWhereUniqueInput = {
  id: Scalars['ID']
}

export type ProposalDiscussionThread = BaseGraphQlObject & {
  __typename: 'ProposalDiscussionThread'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Current thread mode */
  mode: ProposalDiscussionThreadMode
  modeChanges: Array<ProposalDiscussionThreadModeChangedEvent>
  posts: Array<ProposalDiscussionPost>
  proposal: Proposal
  proposalId: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type ProposalDiscussionThreadConnection = {
  __typename: 'ProposalDiscussionThreadConnection'
  edges: Array<ProposalDiscussionThreadEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProposalDiscussionThreadCreateInput = {
  mode: Scalars['JSONObject']
  proposal: Scalars['ID']
}

export type ProposalDiscussionThreadEdge = {
  __typename: 'ProposalDiscussionThreadEdge'
  cursor: Scalars['String']
  node: ProposalDiscussionThread
}

export type ProposalDiscussionThreadMode = ProposalDiscussionThreadModeClosed | ProposalDiscussionThreadModeOpen

export type ProposalDiscussionThreadModeChangedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ProposalDiscussionThreadModeChangedEvent'
    actor: Membership
    actorId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** The new thread mode */
    newMode: ProposalDiscussionThreadMode
    thread: ProposalDiscussionThread
    threadId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ProposalDiscussionThreadModeChangedEventConnection = {
  __typename: 'ProposalDiscussionThreadModeChangedEventConnection'
  edges: Array<ProposalDiscussionThreadModeChangedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProposalDiscussionThreadModeChangedEventCreateInput = {
  actor: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newMode: Scalars['JSONObject']
  thread: Scalars['ID']
}

export type ProposalDiscussionThreadModeChangedEventEdge = {
  __typename: 'ProposalDiscussionThreadModeChangedEventEdge'
  cursor: Scalars['String']
  node: ProposalDiscussionThreadModeChangedEvent
}

export enum ProposalDiscussionThreadModeChangedEventOrderByInput {
  ActorAsc = 'actor_ASC',
  ActorDesc = 'actor_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ThreadAsc = 'thread_ASC',
  ThreadDesc = 'thread_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProposalDiscussionThreadModeChangedEventUpdateInput = {
  actor?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newMode?: InputMaybe<Scalars['JSONObject']>
  thread?: InputMaybe<Scalars['ID']>
}

export type ProposalDiscussionThreadModeChangedEventWhereInput = {
  AND?: InputMaybe<Array<ProposalDiscussionThreadModeChangedEventWhereInput>>
  NOT?: InputMaybe<Array<ProposalDiscussionThreadModeChangedEventWhereInput>>
  OR?: InputMaybe<Array<ProposalDiscussionThreadModeChangedEventWhereInput>>
  actor?: InputMaybe<MembershipWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newMode_json?: InputMaybe<Scalars['JSONObject']>
  thread?: InputMaybe<ProposalDiscussionThreadWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ProposalDiscussionThreadModeChangedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ProposalDiscussionThreadModeClosed = {
  __typename: 'ProposalDiscussionThreadModeClosed'
  /** Whitelist containing members allowed to participate in the discussion */
  whitelist?: Maybe<ProposalDiscussionWhitelist>
}

export type ProposalDiscussionThreadModeOpen = {
  __typename: 'ProposalDiscussionThreadModeOpen'
  phantom?: Maybe<Scalars['Int']>
}

export enum ProposalDiscussionThreadOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ProposalAsc = 'proposal_ASC',
  ProposalDesc = 'proposal_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProposalDiscussionThreadUpdateInput = {
  mode?: InputMaybe<Scalars['JSONObject']>
  proposal?: InputMaybe<Scalars['ID']>
}

export type ProposalDiscussionThreadWhereInput = {
  AND?: InputMaybe<Array<ProposalDiscussionThreadWhereInput>>
  NOT?: InputMaybe<Array<ProposalDiscussionThreadWhereInput>>
  OR?: InputMaybe<Array<ProposalDiscussionThreadWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  modeChanges_every?: InputMaybe<ProposalDiscussionThreadModeChangedEventWhereInput>
  modeChanges_none?: InputMaybe<ProposalDiscussionThreadModeChangedEventWhereInput>
  modeChanges_some?: InputMaybe<ProposalDiscussionThreadModeChangedEventWhereInput>
  mode_json?: InputMaybe<Scalars['JSONObject']>
  posts_every?: InputMaybe<ProposalDiscussionPostWhereInput>
  posts_none?: InputMaybe<ProposalDiscussionPostWhereInput>
  posts_some?: InputMaybe<ProposalDiscussionPostWhereInput>
  proposal?: InputMaybe<ProposalWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ProposalDiscussionThreadWhereUniqueInput = {
  id: Scalars['ID']
}

export type ProposalDiscussionWhitelist = BaseGraphQlObject & {
  __typename: 'ProposalDiscussionWhitelist'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  members: Array<Membership>
  phantom?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type ProposalDiscussionWhitelistConnection = {
  __typename: 'ProposalDiscussionWhitelistConnection'
  edges: Array<ProposalDiscussionWhitelistEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProposalDiscussionWhitelistCreateInput = {
  phantom?: InputMaybe<Scalars['Float']>
}

export type ProposalDiscussionWhitelistEdge = {
  __typename: 'ProposalDiscussionWhitelistEdge'
  cursor: Scalars['String']
  node: ProposalDiscussionWhitelist
}

export enum ProposalDiscussionWhitelistOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  PhantomAsc = 'phantom_ASC',
  PhantomDesc = 'phantom_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProposalDiscussionWhitelistUpdateInput = {
  phantom?: InputMaybe<Scalars['Float']>
}

export type ProposalDiscussionWhitelistWhereInput = {
  AND?: InputMaybe<Array<ProposalDiscussionWhitelistWhereInput>>
  NOT?: InputMaybe<Array<ProposalDiscussionWhitelistWhereInput>>
  OR?: InputMaybe<Array<ProposalDiscussionWhitelistWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  members_every?: InputMaybe<MembershipWhereInput>
  members_none?: InputMaybe<MembershipWhereInput>
  members_some?: InputMaybe<MembershipWhereInput>
  phantom_eq?: InputMaybe<Scalars['Int']>
  phantom_gt?: InputMaybe<Scalars['Int']>
  phantom_gte?: InputMaybe<Scalars['Int']>
  phantom_in?: InputMaybe<Array<Scalars['Int']>>
  phantom_lt?: InputMaybe<Scalars['Int']>
  phantom_lte?: InputMaybe<Scalars['Int']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ProposalDiscussionWhitelistWhereUniqueInput = {
  id: Scalars['ID']
}

export type ProposalEdge = {
  __typename: 'ProposalEdge'
  cursor: Scalars['String']
  node: Proposal
}

export type ProposalExecutedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ProposalExecutedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    /** The execution status */
    executionStatus: ProposalExecutionStatus
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    proposal: Proposal
    proposalId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ProposalExecutedEventConnection = {
  __typename: 'ProposalExecutedEventConnection'
  edges: Array<ProposalExecutedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProposalExecutedEventCreateInput = {
  executionStatus: Scalars['JSONObject']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  proposal: Scalars['ID']
}

export type ProposalExecutedEventEdge = {
  __typename: 'ProposalExecutedEventEdge'
  cursor: Scalars['String']
  node: ProposalExecutedEvent
}

export enum ProposalExecutedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ProposalAsc = 'proposal_ASC',
  ProposalDesc = 'proposal_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProposalExecutedEventUpdateInput = {
  executionStatus?: InputMaybe<Scalars['JSONObject']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  proposal?: InputMaybe<Scalars['ID']>
}

export type ProposalExecutedEventWhereInput = {
  AND?: InputMaybe<Array<ProposalExecutedEventWhereInput>>
  NOT?: InputMaybe<Array<ProposalExecutedEventWhereInput>>
  OR?: InputMaybe<Array<ProposalExecutedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  executionStatus_json?: InputMaybe<Scalars['JSONObject']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  proposal?: InputMaybe<ProposalWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ProposalExecutedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ProposalExecutionStatus = ProposalStatusExecuted | ProposalStatusExecutionFailed

export type ProposalIntermediateStatus = ProposalStatusDeciding | ProposalStatusDormant | ProposalStatusGracing

export enum ProposalOrderByInput {
  CouncilApprovalsAsc = 'councilApprovals_ASC',
  CouncilApprovalsDesc = 'councilApprovals_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CreatorAsc = 'creator_ASC',
  CreatorDesc = 'creator_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExactExecutionBlockAsc = 'exactExecutionBlock_ASC',
  ExactExecutionBlockDesc = 'exactExecutionBlock_DESC',
  IsFinalizedAsc = 'isFinalized_ASC',
  IsFinalizedDesc = 'isFinalized_DESC',
  StakingAccountAsc = 'stakingAccount_ASC',
  StakingAccountDesc = 'stakingAccount_DESC',
  StatusSetAtBlockAsc = 'statusSetAtBlock_ASC',
  StatusSetAtBlockDesc = 'statusSetAtBlock_DESC',
  StatusSetAtTimeAsc = 'statusSetAtTime_ASC',
  StatusSetAtTimeDesc = 'statusSetAtTime_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProposalStatus =
  | ProposalStatusCanceledByRuntime
  | ProposalStatusCancelled
  | ProposalStatusDeciding
  | ProposalStatusDormant
  | ProposalStatusExecuted
  | ProposalStatusExecutionFailed
  | ProposalStatusExpired
  | ProposalStatusGracing
  | ProposalStatusRejected
  | ProposalStatusSlashed
  | ProposalStatusVetoed

export type ProposalStatusCanceledByRuntime = {
  __typename: 'ProposalStatusCanceledByRuntime'
  /** Related ProposalDecisionMadeEvent */
  proposalDecisionMadeEvent?: Maybe<ProposalDecisionMadeEvent>
}

export type ProposalStatusCancelled = {
  __typename: 'ProposalStatusCancelled'
  /** The related ProposalCancelledEvent */
  cancelledInEvent?: Maybe<ProposalCancelledEvent>
}

export type ProposalStatusDeciding = {
  __typename: 'ProposalStatusDeciding'
  /** Related ProposalStatusUpdatedEvent */
  proposalStatusUpdatedEvent?: Maybe<ProposalStatusUpdatedEvent>
}

export type ProposalStatusDormant = {
  __typename: 'ProposalStatusDormant'
  /** Related ProposalStatusUpdatedEvent */
  proposalStatusUpdatedEvent?: Maybe<ProposalStatusUpdatedEvent>
}

export type ProposalStatusExecuted = {
  __typename: 'ProposalStatusExecuted'
  /** Related ProposalExecutedEvent */
  proposalExecutedEvent?: Maybe<ProposalExecutedEvent>
}

export type ProposalStatusExecutionFailed = {
  __typename: 'ProposalStatusExecutionFailed'
  /** The runtime execution error message */
  errorMessage: Scalars['String']
  /** Related ProposalExecutedEvent */
  proposalExecutedEvent?: Maybe<ProposalExecutedEvent>
}

export type ProposalStatusExpired = {
  __typename: 'ProposalStatusExpired'
  /** Related ProposalDecisionMadeEvent */
  proposalDecisionMadeEvent?: Maybe<ProposalDecisionMadeEvent>
}

export type ProposalStatusGracing = {
  __typename: 'ProposalStatusGracing'
  /** Related ProposalStatusUpdatedEvent */
  proposalStatusUpdatedEvent?: Maybe<ProposalStatusUpdatedEvent>
}

export type ProposalStatusRejected = {
  __typename: 'ProposalStatusRejected'
  /** Related ProposalDecisionMadeEvent */
  proposalDecisionMadeEvent?: Maybe<ProposalDecisionMadeEvent>
}

export type ProposalStatusSlashed = {
  __typename: 'ProposalStatusSlashed'
  /** Related ProposalDecisionMadeEvent */
  proposalDecisionMadeEvent?: Maybe<ProposalDecisionMadeEvent>
}

export type ProposalStatusUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ProposalStatusUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** The new proposal intermediate status (Deciding/Gracing/Dormant) */
    newStatus: ProposalIntermediateStatus
    proposal: Proposal
    proposalId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ProposalStatusUpdatedEventConnection = {
  __typename: 'ProposalStatusUpdatedEventConnection'
  edges: Array<ProposalStatusUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProposalStatusUpdatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newStatus: Scalars['JSONObject']
  proposal: Scalars['ID']
}

export type ProposalStatusUpdatedEventEdge = {
  __typename: 'ProposalStatusUpdatedEventEdge'
  cursor: Scalars['String']
  node: ProposalStatusUpdatedEvent
}

export enum ProposalStatusUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ProposalAsc = 'proposal_ASC',
  ProposalDesc = 'proposal_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProposalStatusUpdatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newStatus?: InputMaybe<Scalars['JSONObject']>
  proposal?: InputMaybe<Scalars['ID']>
}

export type ProposalStatusUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<ProposalStatusUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<ProposalStatusUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<ProposalStatusUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newStatus_json?: InputMaybe<Scalars['JSONObject']>
  proposal?: InputMaybe<ProposalWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ProposalStatusUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ProposalStatusVetoed = {
  __typename: 'ProposalStatusVetoed'
  /** Related ProposalDecisionMadeEvent event */
  proposalDecisionMadeEvent?: Maybe<ProposalDecisionMadeEvent>
}

export type ProposalUpdateInput = {
  councilApprovals?: InputMaybe<Scalars['Float']>
  creator?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  details?: InputMaybe<Scalars['JSONObject']>
  exactExecutionBlock?: InputMaybe<Scalars['Float']>
  isFinalized?: InputMaybe<Scalars['Boolean']>
  stakingAccount?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['JSONObject']>
  statusSetAtBlock?: InputMaybe<Scalars['Float']>
  statusSetAtTime?: InputMaybe<Scalars['DateTime']>
  title?: InputMaybe<Scalars['String']>
}

export enum ProposalVoteKind {
  Abstain = 'ABSTAIN',
  Approve = 'APPROVE',
  Reject = 'REJECT',
  Slash = 'SLASH',
}

export type ProposalVotedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ProposalVotedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    proposal: Proposal
    proposalId: Scalars['String']
    /** The rationale behind the vote */
    rationale: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    /** The kind of the vote that was casted */
    voteKind: ProposalVoteKind
    voter: Membership
    voterId: Scalars['String']
    /** The voting round - number representing which Deciding period the vote was casted in (starting with 1), useful when the proposal must be approved during multiple council terms (constitution > 1) */
    votingRound: Scalars['Int']
  }

export type ProposalVotedEventConnection = {
  __typename: 'ProposalVotedEventConnection'
  edges: Array<ProposalVotedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ProposalVotedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  proposal: Scalars['ID']
  rationale: Scalars['String']
  voteKind: ProposalVoteKind
  voter: Scalars['ID']
  votingRound: Scalars['Float']
}

export type ProposalVotedEventEdge = {
  __typename: 'ProposalVotedEventEdge'
  cursor: Scalars['String']
  node: ProposalVotedEvent
}

export enum ProposalVotedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ProposalAsc = 'proposal_ASC',
  ProposalDesc = 'proposal_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VoteKindAsc = 'voteKind_ASC',
  VoteKindDesc = 'voteKind_DESC',
  VoterAsc = 'voter_ASC',
  VoterDesc = 'voter_DESC',
  VotingRoundAsc = 'votingRound_ASC',
  VotingRoundDesc = 'votingRound_DESC',
}

export type ProposalVotedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  proposal?: InputMaybe<Scalars['ID']>
  rationale?: InputMaybe<Scalars['String']>
  voteKind?: InputMaybe<ProposalVoteKind>
  voter?: InputMaybe<Scalars['ID']>
  votingRound?: InputMaybe<Scalars['Float']>
}

export type ProposalVotedEventWhereInput = {
  AND?: InputMaybe<Array<ProposalVotedEventWhereInput>>
  NOT?: InputMaybe<Array<ProposalVotedEventWhereInput>>
  OR?: InputMaybe<Array<ProposalVotedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  proposal?: InputMaybe<ProposalWhereInput>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  voteKind_eq?: InputMaybe<ProposalVoteKind>
  voteKind_in?: InputMaybe<Array<ProposalVoteKind>>
  voter?: InputMaybe<MembershipWhereInput>
  votingRound_eq?: InputMaybe<Scalars['Int']>
  votingRound_gt?: InputMaybe<Scalars['Int']>
  votingRound_gte?: InputMaybe<Scalars['Int']>
  votingRound_in?: InputMaybe<Array<Scalars['Int']>>
  votingRound_lt?: InputMaybe<Scalars['Int']>
  votingRound_lte?: InputMaybe<Scalars['Int']>
}

export type ProposalVotedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ProposalWhereInput = {
  AND?: InputMaybe<Array<ProposalWhereInput>>
  NOT?: InputMaybe<Array<ProposalWhereInput>>
  OR?: InputMaybe<Array<ProposalWhereInput>>
  councilApprovals_eq?: InputMaybe<Scalars['Int']>
  councilApprovals_gt?: InputMaybe<Scalars['Int']>
  councilApprovals_gte?: InputMaybe<Scalars['Int']>
  councilApprovals_in?: InputMaybe<Array<Scalars['Int']>>
  councilApprovals_lt?: InputMaybe<Scalars['Int']>
  councilApprovals_lte?: InputMaybe<Scalars['Int']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  createdInEvent?: InputMaybe<ProposalCreatedEventWhereInput>
  creator?: InputMaybe<MembershipWhereInput>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  description_contains?: InputMaybe<Scalars['String']>
  description_endsWith?: InputMaybe<Scalars['String']>
  description_eq?: InputMaybe<Scalars['String']>
  description_in?: InputMaybe<Array<Scalars['String']>>
  description_startsWith?: InputMaybe<Scalars['String']>
  details_json?: InputMaybe<Scalars['JSONObject']>
  discussionThread?: InputMaybe<ProposalDiscussionThreadWhereInput>
  exactExecutionBlock_eq?: InputMaybe<Scalars['Int']>
  exactExecutionBlock_gt?: InputMaybe<Scalars['Int']>
  exactExecutionBlock_gte?: InputMaybe<Scalars['Int']>
  exactExecutionBlock_in?: InputMaybe<Array<Scalars['Int']>>
  exactExecutionBlock_lt?: InputMaybe<Scalars['Int']>
  exactExecutionBlock_lte?: InputMaybe<Scalars['Int']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  isFinalized_eq?: InputMaybe<Scalars['Boolean']>
  isFinalized_in?: InputMaybe<Array<Scalars['Boolean']>>
  proposalStatusUpdates_every?: InputMaybe<ProposalStatusUpdatedEventWhereInput>
  proposalStatusUpdates_none?: InputMaybe<ProposalStatusUpdatedEventWhereInput>
  proposalStatusUpdates_some?: InputMaybe<ProposalStatusUpdatedEventWhereInput>
  proposalcancelledeventproposal_every?: InputMaybe<ProposalCancelledEventWhereInput>
  proposalcancelledeventproposal_none?: InputMaybe<ProposalCancelledEventWhereInput>
  proposalcancelledeventproposal_some?: InputMaybe<ProposalCancelledEventWhereInput>
  proposaldecisionmadeeventproposal_every?: InputMaybe<ProposalDecisionMadeEventWhereInput>
  proposaldecisionmadeeventproposal_none?: InputMaybe<ProposalDecisionMadeEventWhereInput>
  proposaldecisionmadeeventproposal_some?: InputMaybe<ProposalDecisionMadeEventWhereInput>
  proposalexecutedeventproposal_every?: InputMaybe<ProposalExecutedEventWhereInput>
  proposalexecutedeventproposal_none?: InputMaybe<ProposalExecutedEventWhereInput>
  proposalexecutedeventproposal_some?: InputMaybe<ProposalExecutedEventWhereInput>
  stakingAccount_contains?: InputMaybe<Scalars['String']>
  stakingAccount_endsWith?: InputMaybe<Scalars['String']>
  stakingAccount_eq?: InputMaybe<Scalars['String']>
  stakingAccount_in?: InputMaybe<Array<Scalars['String']>>
  stakingAccount_startsWith?: InputMaybe<Scalars['String']>
  statusSetAtBlock_eq?: InputMaybe<Scalars['Int']>
  statusSetAtBlock_gt?: InputMaybe<Scalars['Int']>
  statusSetAtBlock_gte?: InputMaybe<Scalars['Int']>
  statusSetAtBlock_in?: InputMaybe<Array<Scalars['Int']>>
  statusSetAtBlock_lt?: InputMaybe<Scalars['Int']>
  statusSetAtBlock_lte?: InputMaybe<Scalars['Int']>
  statusSetAtTime_eq?: InputMaybe<Scalars['DateTime']>
  statusSetAtTime_gt?: InputMaybe<Scalars['DateTime']>
  statusSetAtTime_gte?: InputMaybe<Scalars['DateTime']>
  statusSetAtTime_lt?: InputMaybe<Scalars['DateTime']>
  statusSetAtTime_lte?: InputMaybe<Scalars['DateTime']>
  status_json?: InputMaybe<Scalars['JSONObject']>
  title_contains?: InputMaybe<Scalars['String']>
  title_endsWith?: InputMaybe<Scalars['String']>
  title_eq?: InputMaybe<Scalars['String']>
  title_in?: InputMaybe<Array<Scalars['String']>>
  title_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  votes_every?: InputMaybe<ProposalVotedEventWhereInput>
  votes_none?: InputMaybe<ProposalVotedEventWhereInput>
  votes_some?: InputMaybe<ProposalVotedEventWhereInput>
}

export type ProposalWhereUniqueInput = {
  id: Scalars['ID']
}

export type ProposalsByTitleFtsOutput = {
  __typename: 'ProposalsByTitleFTSOutput'
  highlight: Scalars['String']
  isTypeOf: Scalars['String']
  item: ProposalsByTitleSearchResult
  rank: Scalars['Float']
}

export type ProposalsByTitleSearchResult = Proposal

export type Query = {
  __typename: 'Query'
  announcingPeriodStartedEventByUniqueInput?: Maybe<AnnouncingPeriodStartedEvent>
  announcingPeriodStartedEvents: Array<AnnouncingPeriodStartedEvent>
  announcingPeriodStartedEventsConnection: AnnouncingPeriodStartedEventConnection
  applicationFormQuestionAnswerByUniqueInput?: Maybe<ApplicationFormQuestionAnswer>
  applicationFormQuestionAnswers: Array<ApplicationFormQuestionAnswer>
  applicationFormQuestionAnswersConnection: ApplicationFormQuestionAnswerConnection
  applicationFormQuestionByUniqueInput?: Maybe<ApplicationFormQuestion>
  applicationFormQuestions: Array<ApplicationFormQuestion>
  applicationFormQuestionsConnection: ApplicationFormQuestionConnection
  applicationWithdrawnEventByUniqueInput?: Maybe<ApplicationWithdrawnEvent>
  applicationWithdrawnEvents: Array<ApplicationWithdrawnEvent>
  applicationWithdrawnEventsConnection: ApplicationWithdrawnEventConnection
  appliedOnOpeningEventByUniqueInput?: Maybe<AppliedOnOpeningEvent>
  appliedOnOpeningEvents: Array<AppliedOnOpeningEvent>
  appliedOnOpeningEventsConnection: AppliedOnOpeningEventConnection
  auctionBidCanceledEventByUniqueInput?: Maybe<AuctionBidCanceledEvent>
  auctionBidCanceledEvents: Array<AuctionBidCanceledEvent>
  auctionBidCanceledEventsConnection: AuctionBidCanceledEventConnection
  auctionBidMadeEventByUniqueInput?: Maybe<AuctionBidMadeEvent>
  auctionBidMadeEvents: Array<AuctionBidMadeEvent>
  auctionBidMadeEventsConnection: AuctionBidMadeEventConnection
  auctionByUniqueInput?: Maybe<Auction>
  auctionCanceledEventByUniqueInput?: Maybe<AuctionCanceledEvent>
  auctionCanceledEvents: Array<AuctionCanceledEvent>
  auctionCanceledEventsConnection: AuctionCanceledEventConnection
  auctions: Array<Auction>
  auctionsConnection: AuctionConnection
  bidByUniqueInput?: Maybe<Bid>
  bidMadeCompletingAuctionEventByUniqueInput?: Maybe<BidMadeCompletingAuctionEvent>
  bidMadeCompletingAuctionEvents: Array<BidMadeCompletingAuctionEvent>
  bidMadeCompletingAuctionEventsConnection: BidMadeCompletingAuctionEventConnection
  bids: Array<Bid>
  bidsConnection: BidConnection
  bounties: Array<Bounty>
  bountiesConnection: BountyConnection
  bountyByUniqueInput?: Maybe<Bounty>
  bountyCanceledEventByUniqueInput?: Maybe<BountyCanceledEvent>
  bountyCanceledEvents: Array<BountyCanceledEvent>
  bountyCanceledEventsConnection: BountyCanceledEventConnection
  bountyContributionByUniqueInput?: Maybe<BountyContribution>
  bountyContributions: Array<BountyContribution>
  bountyContributionsConnection: BountyContributionConnection
  bountyCreatedEventByUniqueInput?: Maybe<BountyCreatedEvent>
  bountyCreatedEvents: Array<BountyCreatedEvent>
  bountyCreatedEventsConnection: BountyCreatedEventConnection
  bountyCreatorCherryWithdrawalEventByUniqueInput?: Maybe<BountyCreatorCherryWithdrawalEvent>
  bountyCreatorCherryWithdrawalEvents: Array<BountyCreatorCherryWithdrawalEvent>
  bountyCreatorCherryWithdrawalEventsConnection: BountyCreatorCherryWithdrawalEventConnection
  bountyEntrantWhitelistByUniqueInput?: Maybe<BountyEntrantWhitelist>
  bountyEntrantWhitelists: Array<BountyEntrantWhitelist>
  bountyEntrantWhitelistsConnection: BountyEntrantWhitelistConnection
  bountyEntries: Array<BountyEntry>
  bountyEntriesConnection: BountyEntryConnection
  bountyEntryByUniqueInput?: Maybe<BountyEntry>
  bountyFundedEventByUniqueInput?: Maybe<BountyFundedEvent>
  bountyFundedEvents: Array<BountyFundedEvent>
  bountyFundedEventsConnection: BountyFundedEventConnection
  bountyFundingWithdrawalEventByUniqueInput?: Maybe<BountyFundingWithdrawalEvent>
  bountyFundingWithdrawalEvents: Array<BountyFundingWithdrawalEvent>
  bountyFundingWithdrawalEventsConnection: BountyFundingWithdrawalEventConnection
  bountyMaxFundingReachedEventByUniqueInput?: Maybe<BountyMaxFundingReachedEvent>
  bountyMaxFundingReachedEvents: Array<BountyMaxFundingReachedEvent>
  bountyMaxFundingReachedEventsConnection: BountyMaxFundingReachedEventConnection
  bountyRemovedEventByUniqueInput?: Maybe<BountyRemovedEvent>
  bountyRemovedEvents: Array<BountyRemovedEvent>
  bountyRemovedEventsConnection: BountyRemovedEventConnection
  bountyVetoedEventByUniqueInput?: Maybe<BountyVetoedEvent>
  bountyVetoedEvents: Array<BountyVetoedEvent>
  bountyVetoedEventsConnection: BountyVetoedEventConnection
  budgetBalanceSetEventByUniqueInput?: Maybe<BudgetBalanceSetEvent>
  budgetBalanceSetEvents: Array<BudgetBalanceSetEvent>
  budgetBalanceSetEventsConnection: BudgetBalanceSetEventConnection
  budgetIncrementUpdatedEventByUniqueInput?: Maybe<BudgetIncrementUpdatedEvent>
  budgetIncrementUpdatedEvents: Array<BudgetIncrementUpdatedEvent>
  budgetIncrementUpdatedEventsConnection: BudgetIncrementUpdatedEventConnection
  budgetRefillEventByUniqueInput?: Maybe<BudgetRefillEvent>
  budgetRefillEvents: Array<BudgetRefillEvent>
  budgetRefillEventsConnection: BudgetRefillEventConnection
  budgetRefillPlannedEventByUniqueInput?: Maybe<BudgetRefillPlannedEvent>
  budgetRefillPlannedEvents: Array<BudgetRefillPlannedEvent>
  budgetRefillPlannedEventsConnection: BudgetRefillPlannedEventConnection
  budgetSetEventByUniqueInput?: Maybe<BudgetSetEvent>
  budgetSetEvents: Array<BudgetSetEvent>
  budgetSetEventsConnection: BudgetSetEventConnection
  budgetSpendingEventByUniqueInput?: Maybe<BudgetSpendingEvent>
  budgetSpendingEvents: Array<BudgetSpendingEvent>
  budgetSpendingEventsConnection: BudgetSpendingEventConnection
  budgetUpdatedEventByUniqueInput?: Maybe<BudgetUpdatedEvent>
  budgetUpdatedEvents: Array<BudgetUpdatedEvent>
  budgetUpdatedEventsConnection: BudgetUpdatedEventConnection
  buyNowCanceledEventByUniqueInput?: Maybe<BuyNowCanceledEvent>
  buyNowCanceledEvents: Array<BuyNowCanceledEvent>
  buyNowCanceledEventsConnection: BuyNowCanceledEventConnection
  buyNowPriceUpdatedEventByUniqueInput?: Maybe<BuyNowPriceUpdatedEvent>
  buyNowPriceUpdatedEvents: Array<BuyNowPriceUpdatedEvent>
  buyNowPriceUpdatedEventsConnection: BuyNowPriceUpdatedEventConnection
  candidacyNoteMetadata: Array<CandidacyNoteMetadata>
  candidacyNoteMetadataByUniqueInput?: Maybe<CandidacyNoteMetadata>
  candidacyNoteMetadataConnection: CandidacyNoteMetadataConnection
  candidacyNoteSetEventByUniqueInput?: Maybe<CandidacyNoteSetEvent>
  candidacyNoteSetEvents: Array<CandidacyNoteSetEvent>
  candidacyNoteSetEventsConnection: CandidacyNoteSetEventConnection
  candidacyStakeReleaseEventByUniqueInput?: Maybe<CandidacyStakeReleaseEvent>
  candidacyStakeReleaseEvents: Array<CandidacyStakeReleaseEvent>
  candidacyStakeReleaseEventsConnection: CandidacyStakeReleaseEventConnection
  candidacyWithdrawEventByUniqueInput?: Maybe<CandidacyWithdrawEvent>
  candidacyWithdrawEvents: Array<CandidacyWithdrawEvent>
  candidacyWithdrawEventsConnection: CandidacyWithdrawEventConnection
  candidateByUniqueInput?: Maybe<Candidate>
  candidates: Array<Candidate>
  candidatesConnection: CandidateConnection
  castVoteByUniqueInput?: Maybe<CastVote>
  castVotes: Array<CastVote>
  castVotesConnection: CastVoteConnection
  categoryArchivalStatusUpdatedEventByUniqueInput?: Maybe<CategoryArchivalStatusUpdatedEvent>
  categoryArchivalStatusUpdatedEvents: Array<CategoryArchivalStatusUpdatedEvent>
  categoryArchivalStatusUpdatedEventsConnection: CategoryArchivalStatusUpdatedEventConnection
  categoryCreatedEventByUniqueInput?: Maybe<CategoryCreatedEvent>
  categoryCreatedEvents: Array<CategoryCreatedEvent>
  categoryCreatedEventsConnection: CategoryCreatedEventConnection
  categoryDeletedEventByUniqueInput?: Maybe<CategoryDeletedEvent>
  categoryDeletedEvents: Array<CategoryDeletedEvent>
  categoryDeletedEventsConnection: CategoryDeletedEventConnection
  categoryMembershipOfModeratorUpdatedEventByUniqueInput?: Maybe<CategoryMembershipOfModeratorUpdatedEvent>
  categoryMembershipOfModeratorUpdatedEvents: Array<CategoryMembershipOfModeratorUpdatedEvent>
  categoryMembershipOfModeratorUpdatedEventsConnection: CategoryMembershipOfModeratorUpdatedEventConnection
  categoryStickyThreadUpdateEventByUniqueInput?: Maybe<CategoryStickyThreadUpdateEvent>
  categoryStickyThreadUpdateEvents: Array<CategoryStickyThreadUpdateEvent>
  categoryStickyThreadUpdateEventsConnection: CategoryStickyThreadUpdateEventConnection
  channelAssetsDeletedByModeratorEventByUniqueInput?: Maybe<ChannelAssetsDeletedByModeratorEvent>
  channelAssetsDeletedByModeratorEvents: Array<ChannelAssetsDeletedByModeratorEvent>
  channelAssetsDeletedByModeratorEventsConnection: ChannelAssetsDeletedByModeratorEventConnection
  channelByUniqueInput?: Maybe<Channel>
  channelDeletedByModeratorEventByUniqueInput?: Maybe<ChannelDeletedByModeratorEvent>
  channelDeletedByModeratorEvents: Array<ChannelDeletedByModeratorEvent>
  channelDeletedByModeratorEventsConnection: ChannelDeletedByModeratorEventConnection
  channelFundsWithdrawnEventByUniqueInput?: Maybe<ChannelFundsWithdrawnEvent>
  channelFundsWithdrawnEvents: Array<ChannelFundsWithdrawnEvent>
  channelFundsWithdrawnEventsConnection: ChannelFundsWithdrawnEventConnection
  channelNftCollectors: Array<ChannelNftCollectors>
  channelNftCollectorsByUniqueInput?: Maybe<ChannelNftCollectors>
  channelNftCollectorsConnection: ChannelNftCollectorsConnection
  channelPaymentMadeEventByUniqueInput?: Maybe<ChannelPaymentMadeEvent>
  channelPaymentMadeEvents: Array<ChannelPaymentMadeEvent>
  channelPaymentMadeEventsConnection: ChannelPaymentMadeEventConnection
  channelPayoutsUpdatedEventByUniqueInput?: Maybe<ChannelPayoutsUpdatedEvent>
  channelPayoutsUpdatedEvents: Array<ChannelPayoutsUpdatedEvent>
  channelPayoutsUpdatedEventsConnection: ChannelPayoutsUpdatedEventConnection
  channelRewardClaimedAndWithdrawnEventByUniqueInput?: Maybe<ChannelRewardClaimedAndWithdrawnEvent>
  channelRewardClaimedAndWithdrawnEvents: Array<ChannelRewardClaimedAndWithdrawnEvent>
  channelRewardClaimedAndWithdrawnEventsConnection: ChannelRewardClaimedAndWithdrawnEventConnection
  channelRewardClaimedEventByUniqueInput?: Maybe<ChannelRewardClaimedEvent>
  channelRewardClaimedEvents: Array<ChannelRewardClaimedEvent>
  channelRewardClaimedEventsConnection: ChannelRewardClaimedEventConnection
  channelVisibilitySetByModeratorEventByUniqueInput?: Maybe<ChannelVisibilitySetByModeratorEvent>
  channelVisibilitySetByModeratorEvents: Array<ChannelVisibilitySetByModeratorEvent>
  channelVisibilitySetByModeratorEventsConnection: ChannelVisibilitySetByModeratorEventConnection
  channels: Array<Channel>
  channelsConnection: ChannelConnection
  collaboratorByUniqueInput?: Maybe<Collaborator>
  collaborators: Array<Collaborator>
  collaboratorsConnection: CollaboratorConnection
  commentByUniqueInput?: Maybe<Comment>
  commentCreatedEventByUniqueInput?: Maybe<CommentCreatedEvent>
  commentCreatedEvents: Array<CommentCreatedEvent>
  commentCreatedEventsConnection: CommentCreatedEventConnection
  commentDeletedEventByUniqueInput?: Maybe<CommentDeletedEvent>
  commentDeletedEvents: Array<CommentDeletedEvent>
  commentDeletedEventsConnection: CommentDeletedEventConnection
  commentModeratedEventByUniqueInput?: Maybe<CommentModeratedEvent>
  commentModeratedEvents: Array<CommentModeratedEvent>
  commentModeratedEventsConnection: CommentModeratedEventConnection
  commentPinnedEventByUniqueInput?: Maybe<CommentPinnedEvent>
  commentPinnedEvents: Array<CommentPinnedEvent>
  commentPinnedEventsConnection: CommentPinnedEventConnection
  commentReactedEventByUniqueInput?: Maybe<CommentReactedEvent>
  commentReactedEvents: Array<CommentReactedEvent>
  commentReactedEventsConnection: CommentReactedEventConnection
  commentReactionByUniqueInput?: Maybe<CommentReaction>
  commentReactions: Array<CommentReaction>
  commentReactionsConnection: CommentReactionConnection
  commentReactionsCountByReactionIdByUniqueInput?: Maybe<CommentReactionsCountByReactionId>
  commentReactionsCountByReactionIds: Array<CommentReactionsCountByReactionId>
  commentReactionsCountByReactionIdsConnection: CommentReactionsCountByReactionIdConnection
  commentText: Array<CommentTextFtsOutput>
  commentTextUpdatedEventByUniqueInput?: Maybe<CommentTextUpdatedEvent>
  commentTextUpdatedEvents: Array<CommentTextUpdatedEvent>
  commentTextUpdatedEventsConnection: CommentTextUpdatedEventConnection
  comments: Array<Comment>
  commentsConnection: CommentConnection
  councilBudgetFundedEventByUniqueInput?: Maybe<CouncilBudgetFundedEvent>
  councilBudgetFundedEvents: Array<CouncilBudgetFundedEvent>
  councilBudgetFundedEventsConnection: CouncilBudgetFundedEventConnection
  councilMemberByUniqueInput?: Maybe<CouncilMember>
  councilMembers: Array<CouncilMember>
  councilMembersConnection: CouncilMemberConnection
  councilStageUpdateByUniqueInput?: Maybe<CouncilStageUpdate>
  councilStageUpdates: Array<CouncilStageUpdate>
  councilStageUpdatesConnection: CouncilStageUpdateConnection
  councilorRewardUpdatedEventByUniqueInput?: Maybe<CouncilorRewardUpdatedEvent>
  councilorRewardUpdatedEvents: Array<CouncilorRewardUpdatedEvent>
  councilorRewardUpdatedEventsConnection: CouncilorRewardUpdatedEventConnection
  curatorAgentPermissions: Array<CuratorAgentPermissions>
  curatorAgentPermissionsByUniqueInput?: Maybe<CuratorAgentPermissions>
  curatorAgentPermissionsConnection: CuratorAgentPermissionsConnection
  curatorByUniqueInput?: Maybe<Curator>
  curatorGroupByUniqueInput?: Maybe<CuratorGroup>
  curatorGroups: Array<CuratorGroup>
  curatorGroupsConnection: CuratorGroupConnection
  curators: Array<Curator>
  curatorsConnection: CuratorConnection
  distributionBucketByUniqueInput?: Maybe<DistributionBucket>
  distributionBucketFamilies: Array<DistributionBucketFamily>
  distributionBucketFamiliesConnection: DistributionBucketFamilyConnection
  distributionBucketFamilyByUniqueInput?: Maybe<DistributionBucketFamily>
  distributionBucketFamilyGeographicAreaByUniqueInput?: Maybe<DistributionBucketFamilyGeographicArea>
  distributionBucketFamilyGeographicAreas: Array<DistributionBucketFamilyGeographicArea>
  distributionBucketFamilyGeographicAreasConnection: DistributionBucketFamilyGeographicAreaConnection
  distributionBucketFamilyMetadata: Array<DistributionBucketFamilyMetadata>
  distributionBucketFamilyMetadataByUniqueInput?: Maybe<DistributionBucketFamilyMetadata>
  distributionBucketFamilyMetadataConnection: DistributionBucketFamilyMetadataConnection
  distributionBucketOperatorByUniqueInput?: Maybe<DistributionBucketOperator>
  distributionBucketOperatorMetadata: Array<DistributionBucketOperatorMetadata>
  distributionBucketOperatorMetadataByUniqueInput?: Maybe<DistributionBucketOperatorMetadata>
  distributionBucketOperatorMetadataConnection: DistributionBucketOperatorMetadataConnection
  distributionBucketOperators: Array<DistributionBucketOperator>
  distributionBucketOperatorsConnection: DistributionBucketOperatorConnection
  distributionBuckets: Array<DistributionBucket>
  distributionBucketsConnection: DistributionBucketConnection
  electedCouncilByUniqueInput?: Maybe<ElectedCouncil>
  electedCouncils: Array<ElectedCouncil>
  electedCouncilsConnection: ElectedCouncilConnection
  electionRoundByUniqueInput?: Maybe<ElectionRound>
  electionRounds: Array<ElectionRound>
  electionRoundsConnection: ElectionRoundConnection
  englishAuctionSettledEventByUniqueInput?: Maybe<EnglishAuctionSettledEvent>
  englishAuctionSettledEvents: Array<EnglishAuctionSettledEvent>
  englishAuctionSettledEventsConnection: EnglishAuctionSettledEventConnection
  englishAuctionStartedEventByUniqueInput?: Maybe<EnglishAuctionStartedEvent>
  englishAuctionStartedEvents: Array<EnglishAuctionStartedEvent>
  englishAuctionStartedEventsConnection: EnglishAuctionStartedEventConnection
  entitySubscriptions?: Maybe<Array<Maybe<EntitySubscription>>>
  events: Array<Event>
  forumCategories: Array<ForumCategory>
  forumCategoriesConnection: ForumCategoryConnection
  forumCategoryByUniqueInput?: Maybe<ForumCategory>
  forumPostByUniqueInput?: Maybe<ForumPost>
  forumPosts: Array<ForumPost>
  forumPostsConnection: ForumPostConnection
  forumThreadByUniqueInput?: Maybe<ForumThread>
  forumThreadTagByUniqueInput?: Maybe<ForumThreadTag>
  forumThreadTags: Array<ForumThreadTag>
  forumThreadTagsConnection: ForumThreadTagConnection
  forumThreads: Array<ForumThread>
  forumThreadsConnection: ForumThreadConnection
  fundingRequestDestinationByUniqueInput?: Maybe<FundingRequestDestination>
  fundingRequestDestinations: Array<FundingRequestDestination>
  fundingRequestDestinationsConnection: FundingRequestDestinationConnection
  fundingRequestDestinationsListByUniqueInput?: Maybe<FundingRequestDestinationsList>
  fundingRequestDestinationsLists: Array<FundingRequestDestinationsList>
  fundingRequestDestinationsListsConnection: FundingRequestDestinationsListConnection
  generalSubscriptions?: Maybe<Array<Maybe<GeneralSubscription>>>
  geoCoordinates: Array<GeoCoordinates>
  geoCoordinatesByUniqueInput?: Maybe<GeoCoordinates>
  geoCoordinatesConnection: GeoCoordinatesConnection
  initialInvitationBalanceUpdatedEventByUniqueInput?: Maybe<InitialInvitationBalanceUpdatedEvent>
  initialInvitationBalanceUpdatedEvents: Array<InitialInvitationBalanceUpdatedEvent>
  initialInvitationBalanceUpdatedEventsConnection: InitialInvitationBalanceUpdatedEventConnection
  initialInvitationCountUpdatedEventByUniqueInput?: Maybe<InitialInvitationCountUpdatedEvent>
  initialInvitationCountUpdatedEvents: Array<InitialInvitationCountUpdatedEvent>
  initialInvitationCountUpdatedEventsConnection: InitialInvitationCountUpdatedEventConnection
  invitesTransferredEventByUniqueInput?: Maybe<InvitesTransferredEvent>
  invitesTransferredEvents: Array<InvitesTransferredEvent>
  invitesTransferredEventsConnection: InvitesTransferredEventConnection
  languageByUniqueInput?: Maybe<Language>
  languages: Array<Language>
  languagesConnection: LanguageConnection
  leaderInvitationQuotaUpdatedEventByUniqueInput?: Maybe<LeaderInvitationQuotaUpdatedEvent>
  leaderInvitationQuotaUpdatedEvents: Array<LeaderInvitationQuotaUpdatedEvent>
  leaderInvitationQuotaUpdatedEventsConnection: LeaderInvitationQuotaUpdatedEventConnection
  leaderSetEventByUniqueInput?: Maybe<LeaderSetEvent>
  leaderSetEvents: Array<LeaderSetEvent>
  leaderSetEventsConnection: LeaderSetEventConnection
  leaderUnsetEventByUniqueInput?: Maybe<LeaderUnsetEvent>
  leaderUnsetEvents: Array<LeaderUnsetEvent>
  leaderUnsetEventsConnection: LeaderUnsetEventConnection
  licenseByUniqueInput?: Maybe<License>
  licenses: Array<License>
  licensesConnection: LicenseConnection
  me?: Maybe<Member>
  memberAccountsUpdatedEventByUniqueInput?: Maybe<MemberAccountsUpdatedEvent>
  memberAccountsUpdatedEvents: Array<MemberAccountsUpdatedEvent>
  memberAccountsUpdatedEventsConnection: MemberAccountsUpdatedEventConnection
  memberBannedFromChannelEventByUniqueInput?: Maybe<MemberBannedFromChannelEvent>
  memberBannedFromChannelEvents: Array<MemberBannedFromChannelEvent>
  memberBannedFromChannelEventsConnection: MemberBannedFromChannelEventConnection
  memberCreatedEventByUniqueInput?: Maybe<MemberCreatedEvent>
  memberCreatedEvents: Array<MemberCreatedEvent>
  memberCreatedEventsConnection: MemberCreatedEventConnection
  memberExist?: Maybe<Scalars['Boolean']>
  memberInvitedEventByUniqueInput?: Maybe<MemberInvitedEvent>
  memberInvitedEvents: Array<MemberInvitedEvent>
  memberInvitedEventsConnection: MemberInvitedEventConnection
  memberMetadata: Array<MemberMetadata>
  memberMetadataByUniqueInput?: Maybe<MemberMetadata>
  memberMetadataConnection: MemberMetadataConnection
  memberProfileUpdatedEventByUniqueInput?: Maybe<MemberProfileUpdatedEvent>
  memberProfileUpdatedEvents: Array<MemberProfileUpdatedEvent>
  memberProfileUpdatedEventsConnection: MemberProfileUpdatedEventConnection
  memberVerificationStatusUpdatedEventByUniqueInput?: Maybe<MemberVerificationStatusUpdatedEvent>
  memberVerificationStatusUpdatedEvents: Array<MemberVerificationStatusUpdatedEvent>
  memberVerificationStatusUpdatedEventsConnection: MemberVerificationStatusUpdatedEventConnection
  membersByHandle: Array<MembersByHandleFtsOutput>
  membershipBoughtEventByUniqueInput?: Maybe<MembershipBoughtEvent>
  membershipBoughtEvents: Array<MembershipBoughtEvent>
  membershipBoughtEventsConnection: MembershipBoughtEventConnection
  membershipByUniqueInput?: Maybe<Membership>
  membershipExternalResourceByUniqueInput?: Maybe<MembershipExternalResource>
  membershipExternalResources: Array<MembershipExternalResource>
  membershipExternalResourcesConnection: MembershipExternalResourceConnection
  membershipGiftedEventByUniqueInput?: Maybe<MembershipGiftedEvent>
  membershipGiftedEvents: Array<MembershipGiftedEvent>
  membershipGiftedEventsConnection: MembershipGiftedEventConnection
  membershipPriceUpdatedEventByUniqueInput?: Maybe<MembershipPriceUpdatedEvent>
  membershipPriceUpdatedEvents: Array<MembershipPriceUpdatedEvent>
  membershipPriceUpdatedEventsConnection: MembershipPriceUpdatedEventConnection
  memberships: Array<Membership>
  membershipsConnection: MembershipConnection
  metaprotocolTransactionStatusEventByUniqueInput?: Maybe<MetaprotocolTransactionStatusEvent>
  metaprotocolTransactionStatusEvents: Array<MetaprotocolTransactionStatusEvent>
  metaprotocolTransactionStatusEventsConnection: MetaprotocolTransactionStatusEventConnection
  newCandidateEventByUniqueInput?: Maybe<NewCandidateEvent>
  newCandidateEvents: Array<NewCandidateEvent>
  newCandidateEventsConnection: NewCandidateEventConnection
  newCouncilElectedEventByUniqueInput?: Maybe<NewCouncilElectedEvent>
  newCouncilElectedEvents: Array<NewCouncilElectedEvent>
  newCouncilElectedEventsConnection: NewCouncilElectedEventConnection
  newCouncilNotElectedEventByUniqueInput?: Maybe<NewCouncilNotElectedEvent>
  newCouncilNotElectedEvents: Array<NewCouncilNotElectedEvent>
  newCouncilNotElectedEventsConnection: NewCouncilNotElectedEventConnection
  newMissedRewardLevelReachedEventByUniqueInput?: Maybe<NewMissedRewardLevelReachedEvent>
  newMissedRewardLevelReachedEvents: Array<NewMissedRewardLevelReachedEvent>
  newMissedRewardLevelReachedEventsConnection: NewMissedRewardLevelReachedEventConnection
  nftBoughtEventByUniqueInput?: Maybe<NftBoughtEvent>
  nftBoughtEvents: Array<NftBoughtEvent>
  nftBoughtEventsConnection: NftBoughtEventConnection
  nftIssuedEventByUniqueInput?: Maybe<NftIssuedEvent>
  nftIssuedEvents: Array<NftIssuedEvent>
  nftIssuedEventsConnection: NftIssuedEventConnection
  nftSellOrderMadeEventByUniqueInput?: Maybe<NftSellOrderMadeEvent>
  nftSellOrderMadeEvents: Array<NftSellOrderMadeEvent>
  nftSellOrderMadeEventsConnection: NftSellOrderMadeEventConnection
  nftSlingedBackToTheOriginalArtistEventByUniqueInput?: Maybe<NftSlingedBackToTheOriginalArtistEvent>
  nftSlingedBackToTheOriginalArtistEvents: Array<NftSlingedBackToTheOriginalArtistEvent>
  nftSlingedBackToTheOriginalArtistEventsConnection: NftSlingedBackToTheOriginalArtistEventConnection
  nodeLocationMetadata: Array<NodeLocationMetadata>
  nodeLocationMetadataByUniqueInput?: Maybe<NodeLocationMetadata>
  nodeLocationMetadataConnection: NodeLocationMetadataConnection
  notEnoughCandidatesEventByUniqueInput?: Maybe<NotEnoughCandidatesEvent>
  notEnoughCandidatesEvents: Array<NotEnoughCandidatesEvent>
  notEnoughCandidatesEventsConnection: NotEnoughCandidatesEventConnection
  notifications?: Maybe<Array<Maybe<Notification>>>
  offerAcceptedEventByUniqueInput?: Maybe<OfferAcceptedEvent>
  offerAcceptedEvents: Array<OfferAcceptedEvent>
  offerAcceptedEventsConnection: OfferAcceptedEventConnection
  offerCanceledEventByUniqueInput?: Maybe<OfferCanceledEvent>
  offerCanceledEvents: Array<OfferCanceledEvent>
  offerCanceledEventsConnection: OfferCanceledEventConnection
  offerStartedEventByUniqueInput?: Maybe<OfferStartedEvent>
  offerStartedEvents: Array<OfferStartedEvent>
  offerStartedEventsConnection: OfferStartedEventConnection
  openAuctionBidAcceptedEventByUniqueInput?: Maybe<OpenAuctionBidAcceptedEvent>
  openAuctionBidAcceptedEvents: Array<OpenAuctionBidAcceptedEvent>
  openAuctionBidAcceptedEventsConnection: OpenAuctionBidAcceptedEventConnection
  openAuctionStartedEventByUniqueInput?: Maybe<OpenAuctionStartedEvent>
  openAuctionStartedEvents: Array<OpenAuctionStartedEvent>
  openAuctionStartedEventsConnection: OpenAuctionStartedEventConnection
  openingAddedEventByUniqueInput?: Maybe<OpeningAddedEvent>
  openingAddedEvents: Array<OpeningAddedEvent>
  openingAddedEventsConnection: OpeningAddedEventConnection
  openingCanceledEventByUniqueInput?: Maybe<OpeningCanceledEvent>
  openingCanceledEvents: Array<OpeningCanceledEvent>
  openingCanceledEventsConnection: OpeningCanceledEventConnection
  openingFilledEventByUniqueInput?: Maybe<OpeningFilledEvent>
  openingFilledEvents: Array<OpeningFilledEvent>
  openingFilledEventsConnection: OpeningFilledEventConnection
  oracleJudgmentSubmittedEventByUniqueInput?: Maybe<OracleJudgmentSubmittedEvent>
  oracleJudgmentSubmittedEvents: Array<OracleJudgmentSubmittedEvent>
  oracleJudgmentSubmittedEventsConnection: OracleJudgmentSubmittedEventConnection
  ownedNftByUniqueInput?: Maybe<OwnedNft>
  ownedNfts: Array<OwnedNft>
  ownedNftsConnection: OwnedNftConnection
  postAddedEventByUniqueInput?: Maybe<PostAddedEvent>
  postAddedEvents: Array<PostAddedEvent>
  postAddedEventsConnection: PostAddedEventConnection
  postDeletedEventByUniqueInput?: Maybe<PostDeletedEvent>
  postDeletedEvents: Array<PostDeletedEvent>
  postDeletedEventsConnection: PostDeletedEventConnection
  postModeratedEventByUniqueInput?: Maybe<PostModeratedEvent>
  postModeratedEvents: Array<PostModeratedEvent>
  postModeratedEventsConnection: PostModeratedEventConnection
  postTextUpdatedEventByUniqueInput?: Maybe<PostTextUpdatedEvent>
  postTextUpdatedEvents: Array<PostTextUpdatedEvent>
  postTextUpdatedEventsConnection: PostTextUpdatedEventConnection
  postsByText: Array<PostsByTextFtsOutput>
  proposalByUniqueInput?: Maybe<Proposal>
  proposalCancelledEventByUniqueInput?: Maybe<ProposalCancelledEvent>
  proposalCancelledEvents: Array<ProposalCancelledEvent>
  proposalCancelledEventsConnection: ProposalCancelledEventConnection
  proposalCreatedEventByUniqueInput?: Maybe<ProposalCreatedEvent>
  proposalCreatedEvents: Array<ProposalCreatedEvent>
  proposalCreatedEventsConnection: ProposalCreatedEventConnection
  proposalDecisionMadeEventByUniqueInput?: Maybe<ProposalDecisionMadeEvent>
  proposalDecisionMadeEvents: Array<ProposalDecisionMadeEvent>
  proposalDecisionMadeEventsConnection: ProposalDecisionMadeEventConnection
  proposalDiscussionPostByUniqueInput?: Maybe<ProposalDiscussionPost>
  proposalDiscussionPostCreatedEventByUniqueInput?: Maybe<ProposalDiscussionPostCreatedEvent>
  proposalDiscussionPostCreatedEvents: Array<ProposalDiscussionPostCreatedEvent>
  proposalDiscussionPostCreatedEventsConnection: ProposalDiscussionPostCreatedEventConnection
  proposalDiscussionPostDeletedEventByUniqueInput?: Maybe<ProposalDiscussionPostDeletedEvent>
  proposalDiscussionPostDeletedEvents: Array<ProposalDiscussionPostDeletedEvent>
  proposalDiscussionPostDeletedEventsConnection: ProposalDiscussionPostDeletedEventConnection
  proposalDiscussionPostUpdatedEventByUniqueInput?: Maybe<ProposalDiscussionPostUpdatedEvent>
  proposalDiscussionPostUpdatedEvents: Array<ProposalDiscussionPostUpdatedEvent>
  proposalDiscussionPostUpdatedEventsConnection: ProposalDiscussionPostUpdatedEventConnection
  proposalDiscussionPosts: Array<ProposalDiscussionPost>
  proposalDiscussionPostsConnection: ProposalDiscussionPostConnection
  proposalDiscussionThreadByUniqueInput?: Maybe<ProposalDiscussionThread>
  proposalDiscussionThreadModeChangedEventByUniqueInput?: Maybe<ProposalDiscussionThreadModeChangedEvent>
  proposalDiscussionThreadModeChangedEvents: Array<ProposalDiscussionThreadModeChangedEvent>
  proposalDiscussionThreadModeChangedEventsConnection: ProposalDiscussionThreadModeChangedEventConnection
  proposalDiscussionThreads: Array<ProposalDiscussionThread>
  proposalDiscussionThreadsConnection: ProposalDiscussionThreadConnection
  proposalDiscussionWhitelistByUniqueInput?: Maybe<ProposalDiscussionWhitelist>
  proposalDiscussionWhitelists: Array<ProposalDiscussionWhitelist>
  proposalDiscussionWhitelistsConnection: ProposalDiscussionWhitelistConnection
  proposalExecutedEventByUniqueInput?: Maybe<ProposalExecutedEvent>
  proposalExecutedEvents: Array<ProposalExecutedEvent>
  proposalExecutedEventsConnection: ProposalExecutedEventConnection
  proposalStatusUpdatedEventByUniqueInput?: Maybe<ProposalStatusUpdatedEvent>
  proposalStatusUpdatedEvents: Array<ProposalStatusUpdatedEvent>
  proposalStatusUpdatedEventsConnection: ProposalStatusUpdatedEventConnection
  proposalVotedEventByUniqueInput?: Maybe<ProposalVotedEvent>
  proposalVotedEvents: Array<ProposalVotedEvent>
  proposalVotedEventsConnection: ProposalVotedEventConnection
  proposals: Array<Proposal>
  proposalsByTitle: Array<ProposalsByTitleFtsOutput>
  proposalsConnection: ProposalConnection
  referendumFinishedEventByUniqueInput?: Maybe<ReferendumFinishedEvent>
  referendumFinishedEvents: Array<ReferendumFinishedEvent>
  referendumFinishedEventsConnection: ReferendumFinishedEventConnection
  referendumStageRevealingByUniqueInput?: Maybe<ReferendumStageRevealing>
  referendumStageRevealings: Array<ReferendumStageRevealing>
  referendumStageRevealingsConnection: ReferendumStageRevealingConnection
  referendumStageVotingByUniqueInput?: Maybe<ReferendumStageVoting>
  referendumStageVotings: Array<ReferendumStageVoting>
  referendumStageVotingsConnection: ReferendumStageVotingConnection
  referendumStartedEventByUniqueInput?: Maybe<ReferendumStartedEvent>
  referendumStartedEvents: Array<ReferendumStartedEvent>
  referendumStartedEventsConnection: ReferendumStartedEventConnection
  referendumStartedForcefullyEventByUniqueInput?: Maybe<ReferendumStartedForcefullyEvent>
  referendumStartedForcefullyEvents: Array<ReferendumStartedForcefullyEvent>
  referendumStartedForcefullyEventsConnection: ReferendumStartedForcefullyEventConnection
  referralCutUpdatedEventByUniqueInput?: Maybe<ReferralCutUpdatedEvent>
  referralCutUpdatedEvents: Array<ReferralCutUpdatedEvent>
  referralCutUpdatedEventsConnection: ReferralCutUpdatedEventConnection
  requestFundedEventByUniqueInput?: Maybe<RequestFundedEvent>
  requestFundedEvents: Array<RequestFundedEvent>
  requestFundedEventsConnection: RequestFundedEventConnection
  revealingStageStartedEventByUniqueInput?: Maybe<RevealingStageStartedEvent>
  revealingStageStartedEvents: Array<RevealingStageStartedEvent>
  revealingStageStartedEventsConnection: RevealingStageStartedEventConnection
  rewardPaidEventByUniqueInput?: Maybe<RewardPaidEvent>
  rewardPaidEvents: Array<RewardPaidEvent>
  rewardPaidEventsConnection: RewardPaidEventConnection
  rewardPaymentEventByUniqueInput?: Maybe<RewardPaymentEvent>
  rewardPaymentEvents: Array<RewardPaymentEvent>
  rewardPaymentEventsConnection: RewardPaymentEventConnection
  runtimeWasmBytecodeByUniqueInput?: Maybe<RuntimeWasmBytecode>
  runtimeWasmBytecodes: Array<RuntimeWasmBytecode>
  runtimeWasmBytecodesConnection: RuntimeWasmBytecodeConnection
  search: Array<SearchFtsOutput>
  stakeDecreasedEventByUniqueInput?: Maybe<StakeDecreasedEvent>
  stakeDecreasedEvents: Array<StakeDecreasedEvent>
  stakeDecreasedEventsConnection: StakeDecreasedEventConnection
  stakeIncreasedEventByUniqueInput?: Maybe<StakeIncreasedEvent>
  stakeIncreasedEvents: Array<StakeIncreasedEvent>
  stakeIncreasedEventsConnection: StakeIncreasedEventConnection
  stakeReleasedEventByUniqueInput?: Maybe<StakeReleasedEvent>
  stakeReleasedEvents: Array<StakeReleasedEvent>
  stakeReleasedEventsConnection: StakeReleasedEventConnection
  stakeSlashedEventByUniqueInput?: Maybe<StakeSlashedEvent>
  stakeSlashedEvents: Array<StakeSlashedEvent>
  stakeSlashedEventsConnection: StakeSlashedEventConnection
  stakingAccountAddedEventByUniqueInput?: Maybe<StakingAccountAddedEvent>
  stakingAccountAddedEvents: Array<StakingAccountAddedEvent>
  stakingAccountAddedEventsConnection: StakingAccountAddedEventConnection
  stakingAccountConfirmedEventByUniqueInput?: Maybe<StakingAccountConfirmedEvent>
  stakingAccountConfirmedEvents: Array<StakingAccountConfirmedEvent>
  stakingAccountConfirmedEventsConnection: StakingAccountConfirmedEventConnection
  stakingAccountRemovedEventByUniqueInput?: Maybe<StakingAccountRemovedEvent>
  stakingAccountRemovedEvents: Array<StakingAccountRemovedEvent>
  stakingAccountRemovedEventsConnection: StakingAccountRemovedEventConnection
  statusTextChangedEventByUniqueInput?: Maybe<StatusTextChangedEvent>
  statusTextChangedEvents: Array<StatusTextChangedEvent>
  statusTextChangedEventsConnection: StatusTextChangedEventConnection
  storageBagByUniqueInput?: Maybe<StorageBag>
  storageBags: Array<StorageBag>
  storageBagsConnection: StorageBagConnection
  storageBucketByUniqueInput?: Maybe<StorageBucket>
  storageBucketOperatorMetadata: Array<StorageBucketOperatorMetadata>
  storageBucketOperatorMetadataByUniqueInput?: Maybe<StorageBucketOperatorMetadata>
  storageBucketOperatorMetadataConnection: StorageBucketOperatorMetadataConnection
  storageBuckets: Array<StorageBucket>
  storageBucketsConnection: StorageBucketConnection
  storageDataObjectByUniqueInput?: Maybe<StorageDataObject>
  storageDataObjects: Array<StorageDataObject>
  storageDataObjectsConnection: StorageDataObjectConnection
  terminatedLeaderEventByUniqueInput?: Maybe<TerminatedLeaderEvent>
  terminatedLeaderEvents: Array<TerminatedLeaderEvent>
  terminatedLeaderEventsConnection: TerminatedLeaderEventConnection
  terminatedWorkerEventByUniqueInput?: Maybe<TerminatedWorkerEvent>
  terminatedWorkerEvents: Array<TerminatedWorkerEvent>
  terminatedWorkerEventsConnection: TerminatedWorkerEventConnection
  threadCreatedEventByUniqueInput?: Maybe<ThreadCreatedEvent>
  threadCreatedEvents: Array<ThreadCreatedEvent>
  threadCreatedEventsConnection: ThreadCreatedEventConnection
  threadDeletedEventByUniqueInput?: Maybe<ThreadDeletedEvent>
  threadDeletedEvents: Array<ThreadDeletedEvent>
  threadDeletedEventsConnection: ThreadDeletedEventConnection
  threadMetadataUpdatedEventByUniqueInput?: Maybe<ThreadMetadataUpdatedEvent>
  threadMetadataUpdatedEvents: Array<ThreadMetadataUpdatedEvent>
  threadMetadataUpdatedEventsConnection: ThreadMetadataUpdatedEventConnection
  threadModeratedEventByUniqueInput?: Maybe<ThreadModeratedEvent>
  threadModeratedEvents: Array<ThreadModeratedEvent>
  threadModeratedEventsConnection: ThreadModeratedEventConnection
  threadMovedEventByUniqueInput?: Maybe<ThreadMovedEvent>
  threadMovedEvents: Array<ThreadMovedEvent>
  threadMovedEventsConnection: ThreadMovedEventConnection
  threadsByTitle: Array<ThreadsByTitleFtsOutput>
  transactionalStatusUpdateByUniqueInput?: Maybe<TransactionalStatusUpdate>
  transactionalStatusUpdates: Array<TransactionalStatusUpdate>
  transactionalStatusUpdatesConnection: TransactionalStatusUpdateConnection
  upcomingWorkingGroupOpeningByUniqueInput?: Maybe<UpcomingWorkingGroupOpening>
  upcomingWorkingGroupOpenings: Array<UpcomingWorkingGroupOpening>
  upcomingWorkingGroupOpeningsConnection: UpcomingWorkingGroupOpeningConnection
  videoAssetsDeletedByModeratorEventByUniqueInput?: Maybe<VideoAssetsDeletedByModeratorEvent>
  videoAssetsDeletedByModeratorEvents: Array<VideoAssetsDeletedByModeratorEvent>
  videoAssetsDeletedByModeratorEventsConnection: VideoAssetsDeletedByModeratorEventConnection
  videoByUniqueInput?: Maybe<Video>
  videoCategories: Array<VideoCategory>
  videoCategoriesByName: Array<VideoCategoriesByNameFtsOutput>
  videoCategoriesConnection: VideoCategoryConnection
  videoCategoryByUniqueInput?: Maybe<VideoCategory>
  videoDeletedByModeratorEventByUniqueInput?: Maybe<VideoDeletedByModeratorEvent>
  videoDeletedByModeratorEvents: Array<VideoDeletedByModeratorEvent>
  videoDeletedByModeratorEventsConnection: VideoDeletedByModeratorEventConnection
  videoDeletedEventByUniqueInput?: Maybe<VideoDeletedEvent>
  videoDeletedEvents: Array<VideoDeletedEvent>
  videoDeletedEventsConnection: VideoDeletedEventConnection
  videoMediaEncodingByUniqueInput?: Maybe<VideoMediaEncoding>
  videoMediaEncodings: Array<VideoMediaEncoding>
  videoMediaEncodingsConnection: VideoMediaEncodingConnection
  videoMediaMetadata: Array<VideoMediaMetadata>
  videoMediaMetadataByUniqueInput?: Maybe<VideoMediaMetadata>
  videoMediaMetadataConnection: VideoMediaMetadataConnection
  videoReactedEventByUniqueInput?: Maybe<VideoReactedEvent>
  videoReactedEvents: Array<VideoReactedEvent>
  videoReactedEventsConnection: VideoReactedEventConnection
  videoReactionByUniqueInput?: Maybe<VideoReaction>
  videoReactions: Array<VideoReaction>
  videoReactionsConnection: VideoReactionConnection
  videoReactionsCountByReactionTypeByUniqueInput?: Maybe<VideoReactionsCountByReactionType>
  videoReactionsCountByReactionTypes: Array<VideoReactionsCountByReactionType>
  videoReactionsCountByReactionTypesConnection: VideoReactionsCountByReactionTypeConnection
  videoReactionsPreferenceEventByUniqueInput?: Maybe<VideoReactionsPreferenceEvent>
  videoReactionsPreferenceEvents: Array<VideoReactionsPreferenceEvent>
  videoReactionsPreferenceEventsConnection: VideoReactionsPreferenceEventConnection
  videoSubtitleByUniqueInput?: Maybe<VideoSubtitle>
  videoSubtitles: Array<VideoSubtitle>
  videoSubtitlesConnection: VideoSubtitleConnection
  videoVisibilitySetByModeratorEventByUniqueInput?: Maybe<VideoVisibilitySetByModeratorEvent>
  videoVisibilitySetByModeratorEvents: Array<VideoVisibilitySetByModeratorEvent>
  videoVisibilitySetByModeratorEventsConnection: VideoVisibilitySetByModeratorEventConnection
  videos: Array<Video>
  videosConnection: VideoConnection
  voteCastEventByUniqueInput?: Maybe<VoteCastEvent>
  voteCastEvents: Array<VoteCastEvent>
  voteCastEventsConnection: VoteCastEventConnection
  voteRevealedEventByUniqueInput?: Maybe<VoteRevealedEvent>
  voteRevealedEvents: Array<VoteRevealedEvent>
  voteRevealedEventsConnection: VoteRevealedEventConnection
  votingPeriodStartedEventByUniqueInput?: Maybe<VotingPeriodStartedEvent>
  votingPeriodStartedEvents: Array<VotingPeriodStartedEvent>
  votingPeriodStartedEventsConnection: VotingPeriodStartedEventConnection
  workEntrantFundsWithdrawnEventByUniqueInput?: Maybe<WorkEntrantFundsWithdrawnEvent>
  workEntrantFundsWithdrawnEvents: Array<WorkEntrantFundsWithdrawnEvent>
  workEntrantFundsWithdrawnEventsConnection: WorkEntrantFundsWithdrawnEventConnection
  workEntryAnnouncedEventByUniqueInput?: Maybe<WorkEntryAnnouncedEvent>
  workEntryAnnouncedEvents: Array<WorkEntryAnnouncedEvent>
  workEntryAnnouncedEventsConnection: WorkEntryAnnouncedEventConnection
  workEntrySlashedEventByUniqueInput?: Maybe<WorkEntrySlashedEvent>
  workEntrySlashedEvents: Array<WorkEntrySlashedEvent>
  workEntrySlashedEventsConnection: WorkEntrySlashedEventConnection
  workEntryWithdrawnEventByUniqueInput?: Maybe<WorkEntryWithdrawnEvent>
  workEntryWithdrawnEvents: Array<WorkEntryWithdrawnEvent>
  workEntryWithdrawnEventsConnection: WorkEntryWithdrawnEventConnection
  workSubmittedEventByUniqueInput?: Maybe<WorkSubmittedEvent>
  workSubmittedEvents: Array<WorkSubmittedEvent>
  workSubmittedEventsConnection: WorkSubmittedEventConnection
  workerByUniqueInput?: Maybe<Worker>
  workerExitedEventByUniqueInput?: Maybe<WorkerExitedEvent>
  workerExitedEvents: Array<WorkerExitedEvent>
  workerExitedEventsConnection: WorkerExitedEventConnection
  workerRewardAccountUpdatedEventByUniqueInput?: Maybe<WorkerRewardAccountUpdatedEvent>
  workerRewardAccountUpdatedEvents: Array<WorkerRewardAccountUpdatedEvent>
  workerRewardAccountUpdatedEventsConnection: WorkerRewardAccountUpdatedEventConnection
  workerRewardAmountUpdatedEventByUniqueInput?: Maybe<WorkerRewardAmountUpdatedEvent>
  workerRewardAmountUpdatedEvents: Array<WorkerRewardAmountUpdatedEvent>
  workerRewardAmountUpdatedEventsConnection: WorkerRewardAmountUpdatedEventConnection
  workerRoleAccountUpdatedEventByUniqueInput?: Maybe<WorkerRoleAccountUpdatedEvent>
  workerRoleAccountUpdatedEvents: Array<WorkerRoleAccountUpdatedEvent>
  workerRoleAccountUpdatedEventsConnection: WorkerRoleAccountUpdatedEventConnection
  workerStartedLeavingEventByUniqueInput?: Maybe<WorkerStartedLeavingEvent>
  workerStartedLeavingEvents: Array<WorkerStartedLeavingEvent>
  workerStartedLeavingEventsConnection: WorkerStartedLeavingEventConnection
  workers: Array<Worker>
  workersConnection: WorkerConnection
  workingGroupApplicationByUniqueInput?: Maybe<WorkingGroupApplication>
  workingGroupApplications: Array<WorkingGroupApplication>
  workingGroupApplicationsConnection: WorkingGroupApplicationConnection
  workingGroupByUniqueInput?: Maybe<WorkingGroup>
  workingGroupMetadata: Array<WorkingGroupMetadata>
  workingGroupMetadataByUniqueInput?: Maybe<WorkingGroupMetadata>
  workingGroupMetadataConnection: WorkingGroupMetadataConnection
  workingGroupOpeningByUniqueInput?: Maybe<WorkingGroupOpening>
  workingGroupOpeningMetadata: Array<WorkingGroupOpeningMetadata>
  workingGroupOpeningMetadataByUniqueInput?: Maybe<WorkingGroupOpeningMetadata>
  workingGroupOpeningMetadataConnection: WorkingGroupOpeningMetadataConnection
  workingGroupOpenings: Array<WorkingGroupOpening>
  workingGroupOpeningsConnection: WorkingGroupOpeningConnection
  workingGroups: Array<WorkingGroup>
  workingGroupsConnection: WorkingGroupConnection
}

export type QueryAnnouncingPeriodStartedEventByUniqueInputArgs = {
  where: AnnouncingPeriodStartedEventWhereUniqueInput
}

export type QueryAnnouncingPeriodStartedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<AnnouncingPeriodStartedEventOrderByInput>>
  where?: InputMaybe<AnnouncingPeriodStartedEventWhereInput>
}

export type QueryAnnouncingPeriodStartedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<AnnouncingPeriodStartedEventOrderByInput>>
  where?: InputMaybe<AnnouncingPeriodStartedEventWhereInput>
}

export type QueryApplicationFormQuestionAnswerByUniqueInputArgs = {
  where: ApplicationFormQuestionAnswerWhereUniqueInput
}

export type QueryApplicationFormQuestionAnswersArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ApplicationFormQuestionAnswerOrderByInput>>
  where?: InputMaybe<ApplicationFormQuestionAnswerWhereInput>
}

export type QueryApplicationFormQuestionAnswersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ApplicationFormQuestionAnswerOrderByInput>>
  where?: InputMaybe<ApplicationFormQuestionAnswerWhereInput>
}

export type QueryApplicationFormQuestionByUniqueInputArgs = {
  where: ApplicationFormQuestionWhereUniqueInput
}

export type QueryApplicationFormQuestionsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ApplicationFormQuestionOrderByInput>>
  where?: InputMaybe<ApplicationFormQuestionWhereInput>
}

export type QueryApplicationFormQuestionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ApplicationFormQuestionOrderByInput>>
  where?: InputMaybe<ApplicationFormQuestionWhereInput>
}

export type QueryApplicationWithdrawnEventByUniqueInputArgs = {
  where: ApplicationWithdrawnEventWhereUniqueInput
}

export type QueryApplicationWithdrawnEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ApplicationWithdrawnEventOrderByInput>>
  where?: InputMaybe<ApplicationWithdrawnEventWhereInput>
}

export type QueryApplicationWithdrawnEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ApplicationWithdrawnEventOrderByInput>>
  where?: InputMaybe<ApplicationWithdrawnEventWhereInput>
}

export type QueryAppliedOnOpeningEventByUniqueInputArgs = {
  where: AppliedOnOpeningEventWhereUniqueInput
}

export type QueryAppliedOnOpeningEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<AppliedOnOpeningEventOrderByInput>>
  where?: InputMaybe<AppliedOnOpeningEventWhereInput>
}

export type QueryAppliedOnOpeningEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<AppliedOnOpeningEventOrderByInput>>
  where?: InputMaybe<AppliedOnOpeningEventWhereInput>
}

export type QueryAuctionBidCanceledEventByUniqueInputArgs = {
  where: AuctionBidCanceledEventWhereUniqueInput
}

export type QueryAuctionBidCanceledEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<AuctionBidCanceledEventOrderByInput>>
  where?: InputMaybe<AuctionBidCanceledEventWhereInput>
}

export type QueryAuctionBidCanceledEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<AuctionBidCanceledEventOrderByInput>>
  where?: InputMaybe<AuctionBidCanceledEventWhereInput>
}

export type QueryAuctionBidMadeEventByUniqueInputArgs = {
  where: AuctionBidMadeEventWhereUniqueInput
}

export type QueryAuctionBidMadeEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<AuctionBidMadeEventOrderByInput>>
  where?: InputMaybe<AuctionBidMadeEventWhereInput>
}

export type QueryAuctionBidMadeEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<AuctionBidMadeEventOrderByInput>>
  where?: InputMaybe<AuctionBidMadeEventWhereInput>
}

export type QueryAuctionByUniqueInputArgs = {
  where: AuctionWhereUniqueInput
}

export type QueryAuctionCanceledEventByUniqueInputArgs = {
  where: AuctionCanceledEventWhereUniqueInput
}

export type QueryAuctionCanceledEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<AuctionCanceledEventOrderByInput>>
  where?: InputMaybe<AuctionCanceledEventWhereInput>
}

export type QueryAuctionCanceledEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<AuctionCanceledEventOrderByInput>>
  where?: InputMaybe<AuctionCanceledEventWhereInput>
}

export type QueryAuctionsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<AuctionOrderByInput>>
  where?: InputMaybe<AuctionWhereInput>
}

export type QueryAuctionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<AuctionOrderByInput>>
  where?: InputMaybe<AuctionWhereInput>
}

export type QueryBidByUniqueInputArgs = {
  where: BidWhereUniqueInput
}

export type QueryBidMadeCompletingAuctionEventByUniqueInputArgs = {
  where: BidMadeCompletingAuctionEventWhereUniqueInput
}

export type QueryBidMadeCompletingAuctionEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BidMadeCompletingAuctionEventOrderByInput>>
  where?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
}

export type QueryBidMadeCompletingAuctionEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BidMadeCompletingAuctionEventOrderByInput>>
  where?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
}

export type QueryBidsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BidOrderByInput>>
  where?: InputMaybe<BidWhereInput>
}

export type QueryBidsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BidOrderByInput>>
  where?: InputMaybe<BidWhereInput>
}

export type QueryBountiesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyOrderByInput>>
  where?: InputMaybe<BountyWhereInput>
}

export type QueryBountiesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyOrderByInput>>
  where?: InputMaybe<BountyWhereInput>
}

export type QueryBountyByUniqueInputArgs = {
  where: BountyWhereUniqueInput
}

export type QueryBountyCanceledEventByUniqueInputArgs = {
  where: BountyCanceledEventWhereUniqueInput
}

export type QueryBountyCanceledEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyCanceledEventOrderByInput>>
  where?: InputMaybe<BountyCanceledEventWhereInput>
}

export type QueryBountyCanceledEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyCanceledEventOrderByInput>>
  where?: InputMaybe<BountyCanceledEventWhereInput>
}

export type QueryBountyContributionByUniqueInputArgs = {
  where: BountyContributionWhereUniqueInput
}

export type QueryBountyContributionsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyContributionOrderByInput>>
  where?: InputMaybe<BountyContributionWhereInput>
}

export type QueryBountyContributionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyContributionOrderByInput>>
  where?: InputMaybe<BountyContributionWhereInput>
}

export type QueryBountyCreatedEventByUniqueInputArgs = {
  where: BountyCreatedEventWhereUniqueInput
}

export type QueryBountyCreatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyCreatedEventOrderByInput>>
  where?: InputMaybe<BountyCreatedEventWhereInput>
}

export type QueryBountyCreatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyCreatedEventOrderByInput>>
  where?: InputMaybe<BountyCreatedEventWhereInput>
}

export type QueryBountyCreatorCherryWithdrawalEventByUniqueInputArgs = {
  where: BountyCreatorCherryWithdrawalEventWhereUniqueInput
}

export type QueryBountyCreatorCherryWithdrawalEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyCreatorCherryWithdrawalEventOrderByInput>>
  where?: InputMaybe<BountyCreatorCherryWithdrawalEventWhereInput>
}

export type QueryBountyCreatorCherryWithdrawalEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyCreatorCherryWithdrawalEventOrderByInput>>
  where?: InputMaybe<BountyCreatorCherryWithdrawalEventWhereInput>
}

export type QueryBountyEntrantWhitelistByUniqueInputArgs = {
  where: BountyEntrantWhitelistWhereUniqueInput
}

export type QueryBountyEntrantWhitelistsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyEntrantWhitelistOrderByInput>>
  where?: InputMaybe<BountyEntrantWhitelistWhereInput>
}

export type QueryBountyEntrantWhitelistsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyEntrantWhitelistOrderByInput>>
  where?: InputMaybe<BountyEntrantWhitelistWhereInput>
}

export type QueryBountyEntriesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyEntryOrderByInput>>
  where?: InputMaybe<BountyEntryWhereInput>
}

export type QueryBountyEntriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyEntryOrderByInput>>
  where?: InputMaybe<BountyEntryWhereInput>
}

export type QueryBountyEntryByUniqueInputArgs = {
  where: BountyEntryWhereUniqueInput
}

export type QueryBountyFundedEventByUniqueInputArgs = {
  where: BountyFundedEventWhereUniqueInput
}

export type QueryBountyFundedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyFundedEventOrderByInput>>
  where?: InputMaybe<BountyFundedEventWhereInput>
}

export type QueryBountyFundedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyFundedEventOrderByInput>>
  where?: InputMaybe<BountyFundedEventWhereInput>
}

export type QueryBountyFundingWithdrawalEventByUniqueInputArgs = {
  where: BountyFundingWithdrawalEventWhereUniqueInput
}

export type QueryBountyFundingWithdrawalEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyFundingWithdrawalEventOrderByInput>>
  where?: InputMaybe<BountyFundingWithdrawalEventWhereInput>
}

export type QueryBountyFundingWithdrawalEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyFundingWithdrawalEventOrderByInput>>
  where?: InputMaybe<BountyFundingWithdrawalEventWhereInput>
}

export type QueryBountyMaxFundingReachedEventByUniqueInputArgs = {
  where: BountyMaxFundingReachedEventWhereUniqueInput
}

export type QueryBountyMaxFundingReachedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyMaxFundingReachedEventOrderByInput>>
  where?: InputMaybe<BountyMaxFundingReachedEventWhereInput>
}

export type QueryBountyMaxFundingReachedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyMaxFundingReachedEventOrderByInput>>
  where?: InputMaybe<BountyMaxFundingReachedEventWhereInput>
}

export type QueryBountyRemovedEventByUniqueInputArgs = {
  where: BountyRemovedEventWhereUniqueInput
}

export type QueryBountyRemovedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyRemovedEventOrderByInput>>
  where?: InputMaybe<BountyRemovedEventWhereInput>
}

export type QueryBountyRemovedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyRemovedEventOrderByInput>>
  where?: InputMaybe<BountyRemovedEventWhereInput>
}

export type QueryBountyVetoedEventByUniqueInputArgs = {
  where: BountyVetoedEventWhereUniqueInput
}

export type QueryBountyVetoedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyVetoedEventOrderByInput>>
  where?: InputMaybe<BountyVetoedEventWhereInput>
}

export type QueryBountyVetoedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BountyVetoedEventOrderByInput>>
  where?: InputMaybe<BountyVetoedEventWhereInput>
}

export type QueryBudgetBalanceSetEventByUniqueInputArgs = {
  where: BudgetBalanceSetEventWhereUniqueInput
}

export type QueryBudgetBalanceSetEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BudgetBalanceSetEventOrderByInput>>
  where?: InputMaybe<BudgetBalanceSetEventWhereInput>
}

export type QueryBudgetBalanceSetEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BudgetBalanceSetEventOrderByInput>>
  where?: InputMaybe<BudgetBalanceSetEventWhereInput>
}

export type QueryBudgetIncrementUpdatedEventByUniqueInputArgs = {
  where: BudgetIncrementUpdatedEventWhereUniqueInput
}

export type QueryBudgetIncrementUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BudgetIncrementUpdatedEventOrderByInput>>
  where?: InputMaybe<BudgetIncrementUpdatedEventWhereInput>
}

export type QueryBudgetIncrementUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BudgetIncrementUpdatedEventOrderByInput>>
  where?: InputMaybe<BudgetIncrementUpdatedEventWhereInput>
}

export type QueryBudgetRefillEventByUniqueInputArgs = {
  where: BudgetRefillEventWhereUniqueInput
}

export type QueryBudgetRefillEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BudgetRefillEventOrderByInput>>
  where?: InputMaybe<BudgetRefillEventWhereInput>
}

export type QueryBudgetRefillEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BudgetRefillEventOrderByInput>>
  where?: InputMaybe<BudgetRefillEventWhereInput>
}

export type QueryBudgetRefillPlannedEventByUniqueInputArgs = {
  where: BudgetRefillPlannedEventWhereUniqueInput
}

export type QueryBudgetRefillPlannedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BudgetRefillPlannedEventOrderByInput>>
  where?: InputMaybe<BudgetRefillPlannedEventWhereInput>
}

export type QueryBudgetRefillPlannedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BudgetRefillPlannedEventOrderByInput>>
  where?: InputMaybe<BudgetRefillPlannedEventWhereInput>
}

export type QueryBudgetSetEventByUniqueInputArgs = {
  where: BudgetSetEventWhereUniqueInput
}

export type QueryBudgetSetEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BudgetSetEventOrderByInput>>
  where?: InputMaybe<BudgetSetEventWhereInput>
}

export type QueryBudgetSetEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BudgetSetEventOrderByInput>>
  where?: InputMaybe<BudgetSetEventWhereInput>
}

export type QueryBudgetSpendingEventByUniqueInputArgs = {
  where: BudgetSpendingEventWhereUniqueInput
}

export type QueryBudgetSpendingEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BudgetSpendingEventOrderByInput>>
  where?: InputMaybe<BudgetSpendingEventWhereInput>
}

export type QueryBudgetSpendingEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BudgetSpendingEventOrderByInput>>
  where?: InputMaybe<BudgetSpendingEventWhereInput>
}

export type QueryBudgetUpdatedEventByUniqueInputArgs = {
  where: BudgetUpdatedEventWhereUniqueInput
}

export type QueryBudgetUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BudgetUpdatedEventOrderByInput>>
  where?: InputMaybe<BudgetUpdatedEventWhereInput>
}

export type QueryBudgetUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BudgetUpdatedEventOrderByInput>>
  where?: InputMaybe<BudgetUpdatedEventWhereInput>
}

export type QueryBuyNowCanceledEventByUniqueInputArgs = {
  where: BuyNowCanceledEventWhereUniqueInput
}

export type QueryBuyNowCanceledEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BuyNowCanceledEventOrderByInput>>
  where?: InputMaybe<BuyNowCanceledEventWhereInput>
}

export type QueryBuyNowCanceledEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BuyNowCanceledEventOrderByInput>>
  where?: InputMaybe<BuyNowCanceledEventWhereInput>
}

export type QueryBuyNowPriceUpdatedEventByUniqueInputArgs = {
  where: BuyNowPriceUpdatedEventWhereUniqueInput
}

export type QueryBuyNowPriceUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BuyNowPriceUpdatedEventOrderByInput>>
  where?: InputMaybe<BuyNowPriceUpdatedEventWhereInput>
}

export type QueryBuyNowPriceUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<BuyNowPriceUpdatedEventOrderByInput>>
  where?: InputMaybe<BuyNowPriceUpdatedEventWhereInput>
}

export type QueryCandidacyNoteMetadataArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CandidacyNoteMetadataOrderByInput>>
  where?: InputMaybe<CandidacyNoteMetadataWhereInput>
}

export type QueryCandidacyNoteMetadataByUniqueInputArgs = {
  where: CandidacyNoteMetadataWhereUniqueInput
}

export type QueryCandidacyNoteMetadataConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CandidacyNoteMetadataOrderByInput>>
  where?: InputMaybe<CandidacyNoteMetadataWhereInput>
}

export type QueryCandidacyNoteSetEventByUniqueInputArgs = {
  where: CandidacyNoteSetEventWhereUniqueInput
}

export type QueryCandidacyNoteSetEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CandidacyNoteSetEventOrderByInput>>
  where?: InputMaybe<CandidacyNoteSetEventWhereInput>
}

export type QueryCandidacyNoteSetEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CandidacyNoteSetEventOrderByInput>>
  where?: InputMaybe<CandidacyNoteSetEventWhereInput>
}

export type QueryCandidacyStakeReleaseEventByUniqueInputArgs = {
  where: CandidacyStakeReleaseEventWhereUniqueInput
}

export type QueryCandidacyStakeReleaseEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CandidacyStakeReleaseEventOrderByInput>>
  where?: InputMaybe<CandidacyStakeReleaseEventWhereInput>
}

export type QueryCandidacyStakeReleaseEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CandidacyStakeReleaseEventOrderByInput>>
  where?: InputMaybe<CandidacyStakeReleaseEventWhereInput>
}

export type QueryCandidacyWithdrawEventByUniqueInputArgs = {
  where: CandidacyWithdrawEventWhereUniqueInput
}

export type QueryCandidacyWithdrawEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CandidacyWithdrawEventOrderByInput>>
  where?: InputMaybe<CandidacyWithdrawEventWhereInput>
}

export type QueryCandidacyWithdrawEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CandidacyWithdrawEventOrderByInput>>
  where?: InputMaybe<CandidacyWithdrawEventWhereInput>
}

export type QueryCandidateByUniqueInputArgs = {
  where: CandidateWhereUniqueInput
}

export type QueryCandidatesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CandidateOrderByInput>>
  where?: InputMaybe<CandidateWhereInput>
}

export type QueryCandidatesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CandidateOrderByInput>>
  where?: InputMaybe<CandidateWhereInput>
}

export type QueryCastVoteByUniqueInputArgs = {
  where: CastVoteWhereUniqueInput
}

export type QueryCastVotesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CastVoteOrderByInput>>
  where?: InputMaybe<CastVoteWhereInput>
}

export type QueryCastVotesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CastVoteOrderByInput>>
  where?: InputMaybe<CastVoteWhereInput>
}

export type QueryCategoryArchivalStatusUpdatedEventByUniqueInputArgs = {
  where: CategoryArchivalStatusUpdatedEventWhereUniqueInput
}

export type QueryCategoryArchivalStatusUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CategoryArchivalStatusUpdatedEventOrderByInput>>
  where?: InputMaybe<CategoryArchivalStatusUpdatedEventWhereInput>
}

export type QueryCategoryArchivalStatusUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CategoryArchivalStatusUpdatedEventOrderByInput>>
  where?: InputMaybe<CategoryArchivalStatusUpdatedEventWhereInput>
}

export type QueryCategoryCreatedEventByUniqueInputArgs = {
  where: CategoryCreatedEventWhereUniqueInput
}

export type QueryCategoryCreatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CategoryCreatedEventOrderByInput>>
  where?: InputMaybe<CategoryCreatedEventWhereInput>
}

export type QueryCategoryCreatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CategoryCreatedEventOrderByInput>>
  where?: InputMaybe<CategoryCreatedEventWhereInput>
}

export type QueryCategoryDeletedEventByUniqueInputArgs = {
  where: CategoryDeletedEventWhereUniqueInput
}

export type QueryCategoryDeletedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CategoryDeletedEventOrderByInput>>
  where?: InputMaybe<CategoryDeletedEventWhereInput>
}

export type QueryCategoryDeletedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CategoryDeletedEventOrderByInput>>
  where?: InputMaybe<CategoryDeletedEventWhereInput>
}

export type QueryCategoryMembershipOfModeratorUpdatedEventByUniqueInputArgs = {
  where: CategoryMembershipOfModeratorUpdatedEventWhereUniqueInput
}

export type QueryCategoryMembershipOfModeratorUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CategoryMembershipOfModeratorUpdatedEventOrderByInput>>
  where?: InputMaybe<CategoryMembershipOfModeratorUpdatedEventWhereInput>
}

export type QueryCategoryMembershipOfModeratorUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CategoryMembershipOfModeratorUpdatedEventOrderByInput>>
  where?: InputMaybe<CategoryMembershipOfModeratorUpdatedEventWhereInput>
}

export type QueryCategoryStickyThreadUpdateEventByUniqueInputArgs = {
  where: CategoryStickyThreadUpdateEventWhereUniqueInput
}

export type QueryCategoryStickyThreadUpdateEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CategoryStickyThreadUpdateEventOrderByInput>>
  where?: InputMaybe<CategoryStickyThreadUpdateEventWhereInput>
}

export type QueryCategoryStickyThreadUpdateEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CategoryStickyThreadUpdateEventOrderByInput>>
  where?: InputMaybe<CategoryStickyThreadUpdateEventWhereInput>
}

export type QueryChannelAssetsDeletedByModeratorEventByUniqueInputArgs = {
  where: ChannelAssetsDeletedByModeratorEventWhereUniqueInput
}

export type QueryChannelAssetsDeletedByModeratorEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelAssetsDeletedByModeratorEventOrderByInput>>
  where?: InputMaybe<ChannelAssetsDeletedByModeratorEventWhereInput>
}

export type QueryChannelAssetsDeletedByModeratorEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelAssetsDeletedByModeratorEventOrderByInput>>
  where?: InputMaybe<ChannelAssetsDeletedByModeratorEventWhereInput>
}

export type QueryChannelByUniqueInputArgs = {
  where: ChannelWhereUniqueInput
}

export type QueryChannelDeletedByModeratorEventByUniqueInputArgs = {
  where: ChannelDeletedByModeratorEventWhereUniqueInput
}

export type QueryChannelDeletedByModeratorEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelDeletedByModeratorEventOrderByInput>>
  where?: InputMaybe<ChannelDeletedByModeratorEventWhereInput>
}

export type QueryChannelDeletedByModeratorEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelDeletedByModeratorEventOrderByInput>>
  where?: InputMaybe<ChannelDeletedByModeratorEventWhereInput>
}

export type QueryChannelFundsWithdrawnEventByUniqueInputArgs = {
  where: ChannelFundsWithdrawnEventWhereUniqueInput
}

export type QueryChannelFundsWithdrawnEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelFundsWithdrawnEventOrderByInput>>
  where?: InputMaybe<ChannelFundsWithdrawnEventWhereInput>
}

export type QueryChannelFundsWithdrawnEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelFundsWithdrawnEventOrderByInput>>
  where?: InputMaybe<ChannelFundsWithdrawnEventWhereInput>
}

export type QueryChannelNftCollectorsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelNftCollectorsOrderByInput>>
  where?: InputMaybe<ChannelNftCollectorsWhereInput>
}

export type QueryChannelNftCollectorsByUniqueInputArgs = {
  where: ChannelNftCollectorsWhereUniqueInput
}

export type QueryChannelNftCollectorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelNftCollectorsOrderByInput>>
  where?: InputMaybe<ChannelNftCollectorsWhereInput>
}

export type QueryChannelPaymentMadeEventByUniqueInputArgs = {
  where: ChannelPaymentMadeEventWhereUniqueInput
}

export type QueryChannelPaymentMadeEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelPaymentMadeEventOrderByInput>>
  where?: InputMaybe<ChannelPaymentMadeEventWhereInput>
}

export type QueryChannelPaymentMadeEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelPaymentMadeEventOrderByInput>>
  where?: InputMaybe<ChannelPaymentMadeEventWhereInput>
}

export type QueryChannelPayoutsUpdatedEventByUniqueInputArgs = {
  where: ChannelPayoutsUpdatedEventWhereUniqueInput
}

export type QueryChannelPayoutsUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelPayoutsUpdatedEventOrderByInput>>
  where?: InputMaybe<ChannelPayoutsUpdatedEventWhereInput>
}

export type QueryChannelPayoutsUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelPayoutsUpdatedEventOrderByInput>>
  where?: InputMaybe<ChannelPayoutsUpdatedEventWhereInput>
}

export type QueryChannelRewardClaimedAndWithdrawnEventByUniqueInputArgs = {
  where: ChannelRewardClaimedAndWithdrawnEventWhereUniqueInput
}

export type QueryChannelRewardClaimedAndWithdrawnEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelRewardClaimedAndWithdrawnEventOrderByInput>>
  where?: InputMaybe<ChannelRewardClaimedAndWithdrawnEventWhereInput>
}

export type QueryChannelRewardClaimedAndWithdrawnEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelRewardClaimedAndWithdrawnEventOrderByInput>>
  where?: InputMaybe<ChannelRewardClaimedAndWithdrawnEventWhereInput>
}

export type QueryChannelRewardClaimedEventByUniqueInputArgs = {
  where: ChannelRewardClaimedEventWhereUniqueInput
}

export type QueryChannelRewardClaimedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelRewardClaimedEventOrderByInput>>
  where?: InputMaybe<ChannelRewardClaimedEventWhereInput>
}

export type QueryChannelRewardClaimedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelRewardClaimedEventOrderByInput>>
  where?: InputMaybe<ChannelRewardClaimedEventWhereInput>
}

export type QueryChannelVisibilitySetByModeratorEventByUniqueInputArgs = {
  where: ChannelVisibilitySetByModeratorEventWhereUniqueInput
}

export type QueryChannelVisibilitySetByModeratorEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelVisibilitySetByModeratorEventOrderByInput>>
  where?: InputMaybe<ChannelVisibilitySetByModeratorEventWhereInput>
}

export type QueryChannelVisibilitySetByModeratorEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelVisibilitySetByModeratorEventOrderByInput>>
  where?: InputMaybe<ChannelVisibilitySetByModeratorEventWhereInput>
}

export type QueryChannelsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelOrderByInput>>
  where?: InputMaybe<ChannelWhereInput>
}

export type QueryChannelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ChannelOrderByInput>>
  where?: InputMaybe<ChannelWhereInput>
}

export type QueryCollaboratorByUniqueInputArgs = {
  where: CollaboratorWhereUniqueInput
}

export type QueryCollaboratorsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CollaboratorOrderByInput>>
  where?: InputMaybe<CollaboratorWhereInput>
}

export type QueryCollaboratorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CollaboratorOrderByInput>>
  where?: InputMaybe<CollaboratorWhereInput>
}

export type QueryCommentByUniqueInputArgs = {
  where: CommentWhereUniqueInput
}

export type QueryCommentCreatedEventByUniqueInputArgs = {
  where: CommentCreatedEventWhereUniqueInput
}

export type QueryCommentCreatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentCreatedEventOrderByInput>>
  where?: InputMaybe<CommentCreatedEventWhereInput>
}

export type QueryCommentCreatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentCreatedEventOrderByInput>>
  where?: InputMaybe<CommentCreatedEventWhereInput>
}

export type QueryCommentDeletedEventByUniqueInputArgs = {
  where: CommentDeletedEventWhereUniqueInput
}

export type QueryCommentDeletedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentDeletedEventOrderByInput>>
  where?: InputMaybe<CommentDeletedEventWhereInput>
}

export type QueryCommentDeletedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentDeletedEventOrderByInput>>
  where?: InputMaybe<CommentDeletedEventWhereInput>
}

export type QueryCommentModeratedEventByUniqueInputArgs = {
  where: CommentModeratedEventWhereUniqueInput
}

export type QueryCommentModeratedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentModeratedEventOrderByInput>>
  where?: InputMaybe<CommentModeratedEventWhereInput>
}

export type QueryCommentModeratedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentModeratedEventOrderByInput>>
  where?: InputMaybe<CommentModeratedEventWhereInput>
}

export type QueryCommentPinnedEventByUniqueInputArgs = {
  where: CommentPinnedEventWhereUniqueInput
}

export type QueryCommentPinnedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentPinnedEventOrderByInput>>
  where?: InputMaybe<CommentPinnedEventWhereInput>
}

export type QueryCommentPinnedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentPinnedEventOrderByInput>>
  where?: InputMaybe<CommentPinnedEventWhereInput>
}

export type QueryCommentReactedEventByUniqueInputArgs = {
  where: CommentReactedEventWhereUniqueInput
}

export type QueryCommentReactedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentReactedEventOrderByInput>>
  where?: InputMaybe<CommentReactedEventWhereInput>
}

export type QueryCommentReactedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentReactedEventOrderByInput>>
  where?: InputMaybe<CommentReactedEventWhereInput>
}

export type QueryCommentReactionByUniqueInputArgs = {
  where: CommentReactionWhereUniqueInput
}

export type QueryCommentReactionsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentReactionOrderByInput>>
  where?: InputMaybe<CommentReactionWhereInput>
}

export type QueryCommentReactionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentReactionOrderByInput>>
  where?: InputMaybe<CommentReactionWhereInput>
}

export type QueryCommentReactionsCountByReactionIdByUniqueInputArgs = {
  where: CommentReactionsCountByReactionIdWhereUniqueInput
}

export type QueryCommentReactionsCountByReactionIdsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentReactionsCountByReactionIdOrderByInput>>
  where?: InputMaybe<CommentReactionsCountByReactionIdWhereInput>
}

export type QueryCommentReactionsCountByReactionIdsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentReactionsCountByReactionIdOrderByInput>>
  where?: InputMaybe<CommentReactionsCountByReactionIdWhereInput>
}

export type QueryCommentTextArgs = {
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  text: Scalars['String']
  whereComment?: InputMaybe<CommentWhereInput>
}

export type QueryCommentTextUpdatedEventByUniqueInputArgs = {
  where: CommentTextUpdatedEventWhereUniqueInput
}

export type QueryCommentTextUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentTextUpdatedEventOrderByInput>>
  where?: InputMaybe<CommentTextUpdatedEventWhereInput>
}

export type QueryCommentTextUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentTextUpdatedEventOrderByInput>>
  where?: InputMaybe<CommentTextUpdatedEventWhereInput>
}

export type QueryCommentsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentOrderByInput>>
  where?: InputMaybe<CommentWhereInput>
}

export type QueryCommentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CommentOrderByInput>>
  where?: InputMaybe<CommentWhereInput>
}

export type QueryCouncilBudgetFundedEventByUniqueInputArgs = {
  where: CouncilBudgetFundedEventWhereUniqueInput
}

export type QueryCouncilBudgetFundedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CouncilBudgetFundedEventOrderByInput>>
  where?: InputMaybe<CouncilBudgetFundedEventWhereInput>
}

export type QueryCouncilBudgetFundedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CouncilBudgetFundedEventOrderByInput>>
  where?: InputMaybe<CouncilBudgetFundedEventWhereInput>
}

export type QueryCouncilMemberByUniqueInputArgs = {
  where: CouncilMemberWhereUniqueInput
}

export type QueryCouncilMembersArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CouncilMemberOrderByInput>>
  where?: InputMaybe<CouncilMemberWhereInput>
}

export type QueryCouncilMembersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CouncilMemberOrderByInput>>
  where?: InputMaybe<CouncilMemberWhereInput>
}

export type QueryCouncilStageUpdateByUniqueInputArgs = {
  where: CouncilStageUpdateWhereUniqueInput
}

export type QueryCouncilStageUpdatesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CouncilStageUpdateOrderByInput>>
  where?: InputMaybe<CouncilStageUpdateWhereInput>
}

export type QueryCouncilStageUpdatesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CouncilStageUpdateOrderByInput>>
  where?: InputMaybe<CouncilStageUpdateWhereInput>
}

export type QueryCouncilorRewardUpdatedEventByUniqueInputArgs = {
  where: CouncilorRewardUpdatedEventWhereUniqueInput
}

export type QueryCouncilorRewardUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CouncilorRewardUpdatedEventOrderByInput>>
  where?: InputMaybe<CouncilorRewardUpdatedEventWhereInput>
}

export type QueryCouncilorRewardUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CouncilorRewardUpdatedEventOrderByInput>>
  where?: InputMaybe<CouncilorRewardUpdatedEventWhereInput>
}

export type QueryCuratorAgentPermissionsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CuratorAgentPermissionsOrderByInput>>
  where?: InputMaybe<CuratorAgentPermissionsWhereInput>
}

export type QueryCuratorAgentPermissionsByUniqueInputArgs = {
  where: CuratorAgentPermissionsWhereUniqueInput
}

export type QueryCuratorAgentPermissionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CuratorAgentPermissionsOrderByInput>>
  where?: InputMaybe<CuratorAgentPermissionsWhereInput>
}

export type QueryCuratorByUniqueInputArgs = {
  where: CuratorWhereUniqueInput
}

export type QueryCuratorGroupByUniqueInputArgs = {
  where: CuratorGroupWhereUniqueInput
}

export type QueryCuratorGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CuratorGroupOrderByInput>>
  where?: InputMaybe<CuratorGroupWhereInput>
}

export type QueryCuratorGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CuratorGroupOrderByInput>>
  where?: InputMaybe<CuratorGroupWhereInput>
}

export type QueryCuratorsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CuratorOrderByInput>>
  where?: InputMaybe<CuratorWhereInput>
}

export type QueryCuratorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<CuratorOrderByInput>>
  where?: InputMaybe<CuratorWhereInput>
}

export type QueryDistributionBucketByUniqueInputArgs = {
  where: DistributionBucketWhereUniqueInput
}

export type QueryDistributionBucketFamiliesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DistributionBucketFamilyOrderByInput>>
  where?: InputMaybe<DistributionBucketFamilyWhereInput>
}

export type QueryDistributionBucketFamiliesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DistributionBucketFamilyOrderByInput>>
  where?: InputMaybe<DistributionBucketFamilyWhereInput>
}

export type QueryDistributionBucketFamilyByUniqueInputArgs = {
  where: DistributionBucketFamilyWhereUniqueInput
}

export type QueryDistributionBucketFamilyGeographicAreaByUniqueInputArgs = {
  where: DistributionBucketFamilyGeographicAreaWhereUniqueInput
}

export type QueryDistributionBucketFamilyGeographicAreasArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DistributionBucketFamilyGeographicAreaOrderByInput>>
  where?: InputMaybe<DistributionBucketFamilyGeographicAreaWhereInput>
}

export type QueryDistributionBucketFamilyGeographicAreasConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DistributionBucketFamilyGeographicAreaOrderByInput>>
  where?: InputMaybe<DistributionBucketFamilyGeographicAreaWhereInput>
}

export type QueryDistributionBucketFamilyMetadataArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DistributionBucketFamilyMetadataOrderByInput>>
  where?: InputMaybe<DistributionBucketFamilyMetadataWhereInput>
}

export type QueryDistributionBucketFamilyMetadataByUniqueInputArgs = {
  where: DistributionBucketFamilyMetadataWhereUniqueInput
}

export type QueryDistributionBucketFamilyMetadataConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DistributionBucketFamilyMetadataOrderByInput>>
  where?: InputMaybe<DistributionBucketFamilyMetadataWhereInput>
}

export type QueryDistributionBucketOperatorByUniqueInputArgs = {
  where: DistributionBucketOperatorWhereUniqueInput
}

export type QueryDistributionBucketOperatorMetadataArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DistributionBucketOperatorMetadataOrderByInput>>
  where?: InputMaybe<DistributionBucketOperatorMetadataWhereInput>
}

export type QueryDistributionBucketOperatorMetadataByUniqueInputArgs = {
  where: DistributionBucketOperatorMetadataWhereUniqueInput
}

export type QueryDistributionBucketOperatorMetadataConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DistributionBucketOperatorMetadataOrderByInput>>
  where?: InputMaybe<DistributionBucketOperatorMetadataWhereInput>
}

export type QueryDistributionBucketOperatorsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DistributionBucketOperatorOrderByInput>>
  where?: InputMaybe<DistributionBucketOperatorWhereInput>
}

export type QueryDistributionBucketOperatorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DistributionBucketOperatorOrderByInput>>
  where?: InputMaybe<DistributionBucketOperatorWhereInput>
}

export type QueryDistributionBucketsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DistributionBucketOrderByInput>>
  where?: InputMaybe<DistributionBucketWhereInput>
}

export type QueryDistributionBucketsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<DistributionBucketOrderByInput>>
  where?: InputMaybe<DistributionBucketWhereInput>
}

export type QueryElectedCouncilByUniqueInputArgs = {
  where: ElectedCouncilWhereUniqueInput
}

export type QueryElectedCouncilsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ElectedCouncilOrderByInput>>
  where?: InputMaybe<ElectedCouncilWhereInput>
}

export type QueryElectedCouncilsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ElectedCouncilOrderByInput>>
  where?: InputMaybe<ElectedCouncilWhereInput>
}

export type QueryElectionRoundByUniqueInputArgs = {
  where: ElectionRoundWhereUniqueInput
}

export type QueryElectionRoundsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ElectionRoundOrderByInput>>
  where?: InputMaybe<ElectionRoundWhereInput>
}

export type QueryElectionRoundsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ElectionRoundOrderByInput>>
  where?: InputMaybe<ElectionRoundWhereInput>
}

export type QueryEnglishAuctionSettledEventByUniqueInputArgs = {
  where: EnglishAuctionSettledEventWhereUniqueInput
}

export type QueryEnglishAuctionSettledEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<EnglishAuctionSettledEventOrderByInput>>
  where?: InputMaybe<EnglishAuctionSettledEventWhereInput>
}

export type QueryEnglishAuctionSettledEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<EnglishAuctionSettledEventOrderByInput>>
  where?: InputMaybe<EnglishAuctionSettledEventWhereInput>
}

export type QueryEnglishAuctionStartedEventByUniqueInputArgs = {
  where: EnglishAuctionStartedEventWhereUniqueInput
}

export type QueryEnglishAuctionStartedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<EnglishAuctionStartedEventOrderByInput>>
  where?: InputMaybe<EnglishAuctionStartedEventWhereInput>
}

export type QueryEnglishAuctionStartedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<EnglishAuctionStartedEventOrderByInput>>
  where?: InputMaybe<EnglishAuctionStartedEventWhereInput>
}

export type QueryEntitySubscriptionsArgs = {
  entityId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  kind?: InputMaybe<EntitySubscriptionKind>
  status?: InputMaybe<EntitySubscriptionStatus>
}

export type QueryEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<EventOrderByInput>>
  where?: InputMaybe<EventWhereInput>
}

export type QueryForumCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ForumCategoryOrderByInput>>
  where?: InputMaybe<ForumCategoryWhereInput>
}

export type QueryForumCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ForumCategoryOrderByInput>>
  where?: InputMaybe<ForumCategoryWhereInput>
}

export type QueryForumCategoryByUniqueInputArgs = {
  where: ForumCategoryWhereUniqueInput
}

export type QueryForumPostByUniqueInputArgs = {
  where: ForumPostWhereUniqueInput
}

export type QueryForumPostsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ForumPostOrderByInput>>
  where?: InputMaybe<ForumPostWhereInput>
}

export type QueryForumPostsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ForumPostOrderByInput>>
  where?: InputMaybe<ForumPostWhereInput>
}

export type QueryForumThreadByUniqueInputArgs = {
  where: ForumThreadWhereUniqueInput
}

export type QueryForumThreadTagByUniqueInputArgs = {
  where: ForumThreadTagWhereUniqueInput
}

export type QueryForumThreadTagsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ForumThreadTagOrderByInput>>
  where?: InputMaybe<ForumThreadTagWhereInput>
}

export type QueryForumThreadTagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ForumThreadTagOrderByInput>>
  where?: InputMaybe<ForumThreadTagWhereInput>
}

export type QueryForumThreadsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ForumThreadOrderByInput>>
  where?: InputMaybe<ForumThreadWhereInput>
}

export type QueryForumThreadsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ForumThreadOrderByInput>>
  where?: InputMaybe<ForumThreadWhereInput>
}

export type QueryFundingRequestDestinationByUniqueInputArgs = {
  where: FundingRequestDestinationWhereUniqueInput
}

export type QueryFundingRequestDestinationsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<FundingRequestDestinationOrderByInput>>
  where?: InputMaybe<FundingRequestDestinationWhereInput>
}

export type QueryFundingRequestDestinationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<FundingRequestDestinationOrderByInput>>
  where?: InputMaybe<FundingRequestDestinationWhereInput>
}

export type QueryFundingRequestDestinationsListByUniqueInputArgs = {
  where: FundingRequestDestinationsListWhereUniqueInput
}

export type QueryFundingRequestDestinationsListsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<FundingRequestDestinationsListOrderByInput>>
  where?: InputMaybe<FundingRequestDestinationsListWhereInput>
}

export type QueryFundingRequestDestinationsListsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<FundingRequestDestinationsListOrderByInput>>
  where?: InputMaybe<FundingRequestDestinationsListWhereInput>
}

export type QueryGeneralSubscriptionsArgs = {
  kind?: InputMaybe<GeneralSubscriptionKind>
  shouldNotify?: InputMaybe<Scalars['Boolean']>
  shouldNotifyByEmail?: InputMaybe<Scalars['Boolean']>
}

export type QueryGeoCoordinatesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<GeoCoordinatesOrderByInput>>
  where?: InputMaybe<GeoCoordinatesWhereInput>
}

export type QueryGeoCoordinatesByUniqueInputArgs = {
  where: GeoCoordinatesWhereUniqueInput
}

export type QueryGeoCoordinatesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<GeoCoordinatesOrderByInput>>
  where?: InputMaybe<GeoCoordinatesWhereInput>
}

export type QueryInitialInvitationBalanceUpdatedEventByUniqueInputArgs = {
  where: InitialInvitationBalanceUpdatedEventWhereUniqueInput
}

export type QueryInitialInvitationBalanceUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InitialInvitationBalanceUpdatedEventOrderByInput>>
  where?: InputMaybe<InitialInvitationBalanceUpdatedEventWhereInput>
}

export type QueryInitialInvitationBalanceUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InitialInvitationBalanceUpdatedEventOrderByInput>>
  where?: InputMaybe<InitialInvitationBalanceUpdatedEventWhereInput>
}

export type QueryInitialInvitationCountUpdatedEventByUniqueInputArgs = {
  where: InitialInvitationCountUpdatedEventWhereUniqueInput
}

export type QueryInitialInvitationCountUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InitialInvitationCountUpdatedEventOrderByInput>>
  where?: InputMaybe<InitialInvitationCountUpdatedEventWhereInput>
}

export type QueryInitialInvitationCountUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InitialInvitationCountUpdatedEventOrderByInput>>
  where?: InputMaybe<InitialInvitationCountUpdatedEventWhereInput>
}

export type QueryInvitesTransferredEventByUniqueInputArgs = {
  where: InvitesTransferredEventWhereUniqueInput
}

export type QueryInvitesTransferredEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InvitesTransferredEventOrderByInput>>
  where?: InputMaybe<InvitesTransferredEventWhereInput>
}

export type QueryInvitesTransferredEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InvitesTransferredEventOrderByInput>>
  where?: InputMaybe<InvitesTransferredEventWhereInput>
}

export type QueryLanguageByUniqueInputArgs = {
  where: LanguageWhereUniqueInput
}

export type QueryLanguagesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LanguageOrderByInput>>
  where?: InputMaybe<LanguageWhereInput>
}

export type QueryLanguagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LanguageOrderByInput>>
  where?: InputMaybe<LanguageWhereInput>
}

export type QueryLeaderInvitationQuotaUpdatedEventByUniqueInputArgs = {
  where: LeaderInvitationQuotaUpdatedEventWhereUniqueInput
}

export type QueryLeaderInvitationQuotaUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LeaderInvitationQuotaUpdatedEventOrderByInput>>
  where?: InputMaybe<LeaderInvitationQuotaUpdatedEventWhereInput>
}

export type QueryLeaderInvitationQuotaUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LeaderInvitationQuotaUpdatedEventOrderByInput>>
  where?: InputMaybe<LeaderInvitationQuotaUpdatedEventWhereInput>
}

export type QueryLeaderSetEventByUniqueInputArgs = {
  where: LeaderSetEventWhereUniqueInput
}

export type QueryLeaderSetEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LeaderSetEventOrderByInput>>
  where?: InputMaybe<LeaderSetEventWhereInput>
}

export type QueryLeaderSetEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LeaderSetEventOrderByInput>>
  where?: InputMaybe<LeaderSetEventWhereInput>
}

export type QueryLeaderUnsetEventByUniqueInputArgs = {
  where: LeaderUnsetEventWhereUniqueInput
}

export type QueryLeaderUnsetEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LeaderUnsetEventOrderByInput>>
  where?: InputMaybe<LeaderUnsetEventWhereInput>
}

export type QueryLeaderUnsetEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LeaderUnsetEventOrderByInput>>
  where?: InputMaybe<LeaderUnsetEventWhereInput>
}

export type QueryLicenseByUniqueInputArgs = {
  where: LicenseWhereUniqueInput
}

export type QueryLicensesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LicenseOrderByInput>>
  where?: InputMaybe<LicenseWhereInput>
}

export type QueryLicensesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<LicenseOrderByInput>>
  where?: InputMaybe<LicenseWhereInput>
}

export type QueryMemberAccountsUpdatedEventByUniqueInputArgs = {
  where: MemberAccountsUpdatedEventWhereUniqueInput
}

export type QueryMemberAccountsUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MemberAccountsUpdatedEventOrderByInput>>
  where?: InputMaybe<MemberAccountsUpdatedEventWhereInput>
}

export type QueryMemberAccountsUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MemberAccountsUpdatedEventOrderByInput>>
  where?: InputMaybe<MemberAccountsUpdatedEventWhereInput>
}

export type QueryMemberBannedFromChannelEventByUniqueInputArgs = {
  where: MemberBannedFromChannelEventWhereUniqueInput
}

export type QueryMemberBannedFromChannelEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MemberBannedFromChannelEventOrderByInput>>
  where?: InputMaybe<MemberBannedFromChannelEventWhereInput>
}

export type QueryMemberBannedFromChannelEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MemberBannedFromChannelEventOrderByInput>>
  where?: InputMaybe<MemberBannedFromChannelEventWhereInput>
}

export type QueryMemberCreatedEventByUniqueInputArgs = {
  where: MemberCreatedEventWhereUniqueInput
}

export type QueryMemberCreatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MemberCreatedEventOrderByInput>>
  where?: InputMaybe<MemberCreatedEventWhereInput>
}

export type QueryMemberCreatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MemberCreatedEventOrderByInput>>
  where?: InputMaybe<MemberCreatedEventWhereInput>
}

export type QueryMemberExistArgs = {
  id?: InputMaybe<Scalars['Int']>
}

export type QueryMemberInvitedEventByUniqueInputArgs = {
  where: MemberInvitedEventWhereUniqueInput
}

export type QueryMemberInvitedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MemberInvitedEventOrderByInput>>
  where?: InputMaybe<MemberInvitedEventWhereInput>
}

export type QueryMemberInvitedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MemberInvitedEventOrderByInput>>
  where?: InputMaybe<MemberInvitedEventWhereInput>
}

export type QueryMemberMetadataArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MemberMetadataOrderByInput>>
  where?: InputMaybe<MemberMetadataWhereInput>
}

export type QueryMemberMetadataByUniqueInputArgs = {
  where: MemberMetadataWhereUniqueInput
}

export type QueryMemberMetadataConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MemberMetadataOrderByInput>>
  where?: InputMaybe<MemberMetadataWhereInput>
}

export type QueryMemberProfileUpdatedEventByUniqueInputArgs = {
  where: MemberProfileUpdatedEventWhereUniqueInput
}

export type QueryMemberProfileUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MemberProfileUpdatedEventOrderByInput>>
  where?: InputMaybe<MemberProfileUpdatedEventWhereInput>
}

export type QueryMemberProfileUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MemberProfileUpdatedEventOrderByInput>>
  where?: InputMaybe<MemberProfileUpdatedEventWhereInput>
}

export type QueryMemberVerificationStatusUpdatedEventByUniqueInputArgs = {
  where: MemberVerificationStatusUpdatedEventWhereUniqueInput
}

export type QueryMemberVerificationStatusUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MemberVerificationStatusUpdatedEventOrderByInput>>
  where?: InputMaybe<MemberVerificationStatusUpdatedEventWhereInput>
}

export type QueryMemberVerificationStatusUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MemberVerificationStatusUpdatedEventOrderByInput>>
  where?: InputMaybe<MemberVerificationStatusUpdatedEventWhereInput>
}

export type QueryMembersByHandleArgs = {
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  text: Scalars['String']
  whereMembership?: InputMaybe<MembershipWhereInput>
}

export type QueryMembershipBoughtEventByUniqueInputArgs = {
  where: MembershipBoughtEventWhereUniqueInput
}

export type QueryMembershipBoughtEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MembershipBoughtEventOrderByInput>>
  where?: InputMaybe<MembershipBoughtEventWhereInput>
}

export type QueryMembershipBoughtEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MembershipBoughtEventOrderByInput>>
  where?: InputMaybe<MembershipBoughtEventWhereInput>
}

export type QueryMembershipByUniqueInputArgs = {
  where: MembershipWhereUniqueInput
}

export type QueryMembershipExternalResourceByUniqueInputArgs = {
  where: MembershipExternalResourceWhereUniqueInput
}

export type QueryMembershipExternalResourcesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MembershipExternalResourceOrderByInput>>
  where?: InputMaybe<MembershipExternalResourceWhereInput>
}

export type QueryMembershipExternalResourcesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MembershipExternalResourceOrderByInput>>
  where?: InputMaybe<MembershipExternalResourceWhereInput>
}

export type QueryMembershipGiftedEventByUniqueInputArgs = {
  where: MembershipGiftedEventWhereUniqueInput
}

export type QueryMembershipGiftedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MembershipGiftedEventOrderByInput>>
  where?: InputMaybe<MembershipGiftedEventWhereInput>
}

export type QueryMembershipGiftedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MembershipGiftedEventOrderByInput>>
  where?: InputMaybe<MembershipGiftedEventWhereInput>
}

export type QueryMembershipPriceUpdatedEventByUniqueInputArgs = {
  where: MembershipPriceUpdatedEventWhereUniqueInput
}

export type QueryMembershipPriceUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MembershipPriceUpdatedEventOrderByInput>>
  where?: InputMaybe<MembershipPriceUpdatedEventWhereInput>
}

export type QueryMembershipPriceUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MembershipPriceUpdatedEventOrderByInput>>
  where?: InputMaybe<MembershipPriceUpdatedEventWhereInput>
}

export type QueryMembershipsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MembershipOrderByInput>>
  where?: InputMaybe<MembershipWhereInput>
}

export type QueryMembershipsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MembershipOrderByInput>>
  where?: InputMaybe<MembershipWhereInput>
}

export type QueryMetaprotocolTransactionStatusEventByUniqueInputArgs = {
  where: MetaprotocolTransactionStatusEventWhereUniqueInput
}

export type QueryMetaprotocolTransactionStatusEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MetaprotocolTransactionStatusEventOrderByInput>>
  where?: InputMaybe<MetaprotocolTransactionStatusEventWhereInput>
}

export type QueryMetaprotocolTransactionStatusEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<MetaprotocolTransactionStatusEventOrderByInput>>
  where?: InputMaybe<MetaprotocolTransactionStatusEventWhereInput>
}

export type QueryNewCandidateEventByUniqueInputArgs = {
  where: NewCandidateEventWhereUniqueInput
}

export type QueryNewCandidateEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NewCandidateEventOrderByInput>>
  where?: InputMaybe<NewCandidateEventWhereInput>
}

export type QueryNewCandidateEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NewCandidateEventOrderByInput>>
  where?: InputMaybe<NewCandidateEventWhereInput>
}

export type QueryNewCouncilElectedEventByUniqueInputArgs = {
  where: NewCouncilElectedEventWhereUniqueInput
}

export type QueryNewCouncilElectedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NewCouncilElectedEventOrderByInput>>
  where?: InputMaybe<NewCouncilElectedEventWhereInput>
}

export type QueryNewCouncilElectedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NewCouncilElectedEventOrderByInput>>
  where?: InputMaybe<NewCouncilElectedEventWhereInput>
}

export type QueryNewCouncilNotElectedEventByUniqueInputArgs = {
  where: NewCouncilNotElectedEventWhereUniqueInput
}

export type QueryNewCouncilNotElectedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NewCouncilNotElectedEventOrderByInput>>
  where?: InputMaybe<NewCouncilNotElectedEventWhereInput>
}

export type QueryNewCouncilNotElectedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NewCouncilNotElectedEventOrderByInput>>
  where?: InputMaybe<NewCouncilNotElectedEventWhereInput>
}

export type QueryNewMissedRewardLevelReachedEventByUniqueInputArgs = {
  where: NewMissedRewardLevelReachedEventWhereUniqueInput
}

export type QueryNewMissedRewardLevelReachedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NewMissedRewardLevelReachedEventOrderByInput>>
  where?: InputMaybe<NewMissedRewardLevelReachedEventWhereInput>
}

export type QueryNewMissedRewardLevelReachedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NewMissedRewardLevelReachedEventOrderByInput>>
  where?: InputMaybe<NewMissedRewardLevelReachedEventWhereInput>
}

export type QueryNftBoughtEventByUniqueInputArgs = {
  where: NftBoughtEventWhereUniqueInput
}

export type QueryNftBoughtEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NftBoughtEventOrderByInput>>
  where?: InputMaybe<NftBoughtEventWhereInput>
}

export type QueryNftBoughtEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NftBoughtEventOrderByInput>>
  where?: InputMaybe<NftBoughtEventWhereInput>
}

export type QueryNftIssuedEventByUniqueInputArgs = {
  where: NftIssuedEventWhereUniqueInput
}

export type QueryNftIssuedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NftIssuedEventOrderByInput>>
  where?: InputMaybe<NftIssuedEventWhereInput>
}

export type QueryNftIssuedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NftIssuedEventOrderByInput>>
  where?: InputMaybe<NftIssuedEventWhereInput>
}

export type QueryNftSellOrderMadeEventByUniqueInputArgs = {
  where: NftSellOrderMadeEventWhereUniqueInput
}

export type QueryNftSellOrderMadeEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NftSellOrderMadeEventOrderByInput>>
  where?: InputMaybe<NftSellOrderMadeEventWhereInput>
}

export type QueryNftSellOrderMadeEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NftSellOrderMadeEventOrderByInput>>
  where?: InputMaybe<NftSellOrderMadeEventWhereInput>
}

export type QueryNftSlingedBackToTheOriginalArtistEventByUniqueInputArgs = {
  where: NftSlingedBackToTheOriginalArtistEventWhereUniqueInput
}

export type QueryNftSlingedBackToTheOriginalArtistEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NftSlingedBackToTheOriginalArtistEventOrderByInput>>
  where?: InputMaybe<NftSlingedBackToTheOriginalArtistEventWhereInput>
}

export type QueryNftSlingedBackToTheOriginalArtistEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NftSlingedBackToTheOriginalArtistEventOrderByInput>>
  where?: InputMaybe<NftSlingedBackToTheOriginalArtistEventWhereInput>
}

export type QueryNodeLocationMetadataArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NodeLocationMetadataOrderByInput>>
  where?: InputMaybe<NodeLocationMetadataWhereInput>
}

export type QueryNodeLocationMetadataByUniqueInputArgs = {
  where: NodeLocationMetadataWhereUniqueInput
}

export type QueryNodeLocationMetadataConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NodeLocationMetadataOrderByInput>>
  where?: InputMaybe<NodeLocationMetadataWhereInput>
}

export type QueryNotEnoughCandidatesEventByUniqueInputArgs = {
  where: NotEnoughCandidatesEventWhereUniqueInput
}

export type QueryNotEnoughCandidatesEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NotEnoughCandidatesEventOrderByInput>>
  where?: InputMaybe<NotEnoughCandidatesEventWhereInput>
}

export type QueryNotEnoughCandidatesEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<NotEnoughCandidatesEventOrderByInput>>
  where?: InputMaybe<NotEnoughCandidatesEventWhereInput>
}

export type QueryNotificationsArgs = {
  emailStatus?: InputMaybe<NotificationEmailStatus>
  entityId?: InputMaybe<Scalars['String']>
  eventId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  isRead?: InputMaybe<Scalars['Boolean']>
  kind?: InputMaybe<NotificationKind>
}

export type QueryOfferAcceptedEventByUniqueInputArgs = {
  where: OfferAcceptedEventWhereUniqueInput
}

export type QueryOfferAcceptedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OfferAcceptedEventOrderByInput>>
  where?: InputMaybe<OfferAcceptedEventWhereInput>
}

export type QueryOfferAcceptedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OfferAcceptedEventOrderByInput>>
  where?: InputMaybe<OfferAcceptedEventWhereInput>
}

export type QueryOfferCanceledEventByUniqueInputArgs = {
  where: OfferCanceledEventWhereUniqueInput
}

export type QueryOfferCanceledEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OfferCanceledEventOrderByInput>>
  where?: InputMaybe<OfferCanceledEventWhereInput>
}

export type QueryOfferCanceledEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OfferCanceledEventOrderByInput>>
  where?: InputMaybe<OfferCanceledEventWhereInput>
}

export type QueryOfferStartedEventByUniqueInputArgs = {
  where: OfferStartedEventWhereUniqueInput
}

export type QueryOfferStartedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OfferStartedEventOrderByInput>>
  where?: InputMaybe<OfferStartedEventWhereInput>
}

export type QueryOfferStartedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OfferStartedEventOrderByInput>>
  where?: InputMaybe<OfferStartedEventWhereInput>
}

export type QueryOpenAuctionBidAcceptedEventByUniqueInputArgs = {
  where: OpenAuctionBidAcceptedEventWhereUniqueInput
}

export type QueryOpenAuctionBidAcceptedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OpenAuctionBidAcceptedEventOrderByInput>>
  where?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
}

export type QueryOpenAuctionBidAcceptedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OpenAuctionBidAcceptedEventOrderByInput>>
  where?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
}

export type QueryOpenAuctionStartedEventByUniqueInputArgs = {
  where: OpenAuctionStartedEventWhereUniqueInput
}

export type QueryOpenAuctionStartedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OpenAuctionStartedEventOrderByInput>>
  where?: InputMaybe<OpenAuctionStartedEventWhereInput>
}

export type QueryOpenAuctionStartedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OpenAuctionStartedEventOrderByInput>>
  where?: InputMaybe<OpenAuctionStartedEventWhereInput>
}

export type QueryOpeningAddedEventByUniqueInputArgs = {
  where: OpeningAddedEventWhereUniqueInput
}

export type QueryOpeningAddedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OpeningAddedEventOrderByInput>>
  where?: InputMaybe<OpeningAddedEventWhereInput>
}

export type QueryOpeningAddedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OpeningAddedEventOrderByInput>>
  where?: InputMaybe<OpeningAddedEventWhereInput>
}

export type QueryOpeningCanceledEventByUniqueInputArgs = {
  where: OpeningCanceledEventWhereUniqueInput
}

export type QueryOpeningCanceledEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OpeningCanceledEventOrderByInput>>
  where?: InputMaybe<OpeningCanceledEventWhereInput>
}

export type QueryOpeningCanceledEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OpeningCanceledEventOrderByInput>>
  where?: InputMaybe<OpeningCanceledEventWhereInput>
}

export type QueryOpeningFilledEventByUniqueInputArgs = {
  where: OpeningFilledEventWhereUniqueInput
}

export type QueryOpeningFilledEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OpeningFilledEventOrderByInput>>
  where?: InputMaybe<OpeningFilledEventWhereInput>
}

export type QueryOpeningFilledEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OpeningFilledEventOrderByInput>>
  where?: InputMaybe<OpeningFilledEventWhereInput>
}

export type QueryOracleJudgmentSubmittedEventByUniqueInputArgs = {
  where: OracleJudgmentSubmittedEventWhereUniqueInput
}

export type QueryOracleJudgmentSubmittedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OracleJudgmentSubmittedEventOrderByInput>>
  where?: InputMaybe<OracleJudgmentSubmittedEventWhereInput>
}

export type QueryOracleJudgmentSubmittedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OracleJudgmentSubmittedEventOrderByInput>>
  where?: InputMaybe<OracleJudgmentSubmittedEventWhereInput>
}

export type QueryOwnedNftByUniqueInputArgs = {
  where: OwnedNftWhereUniqueInput
}

export type QueryOwnedNftsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OwnedNftOrderByInput>>
  where?: InputMaybe<OwnedNftWhereInput>
}

export type QueryOwnedNftsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<OwnedNftOrderByInput>>
  where?: InputMaybe<OwnedNftWhereInput>
}

export type QueryPostAddedEventByUniqueInputArgs = {
  where: PostAddedEventWhereUniqueInput
}

export type QueryPostAddedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PostAddedEventOrderByInput>>
  where?: InputMaybe<PostAddedEventWhereInput>
}

export type QueryPostAddedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PostAddedEventOrderByInput>>
  where?: InputMaybe<PostAddedEventWhereInput>
}

export type QueryPostDeletedEventByUniqueInputArgs = {
  where: PostDeletedEventWhereUniqueInput
}

export type QueryPostDeletedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PostDeletedEventOrderByInput>>
  where?: InputMaybe<PostDeletedEventWhereInput>
}

export type QueryPostDeletedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PostDeletedEventOrderByInput>>
  where?: InputMaybe<PostDeletedEventWhereInput>
}

export type QueryPostModeratedEventByUniqueInputArgs = {
  where: PostModeratedEventWhereUniqueInput
}

export type QueryPostModeratedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PostModeratedEventOrderByInput>>
  where?: InputMaybe<PostModeratedEventWhereInput>
}

export type QueryPostModeratedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PostModeratedEventOrderByInput>>
  where?: InputMaybe<PostModeratedEventWhereInput>
}

export type QueryPostTextUpdatedEventByUniqueInputArgs = {
  where: PostTextUpdatedEventWhereUniqueInput
}

export type QueryPostTextUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PostTextUpdatedEventOrderByInput>>
  where?: InputMaybe<PostTextUpdatedEventWhereInput>
}

export type QueryPostTextUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PostTextUpdatedEventOrderByInput>>
  where?: InputMaybe<PostTextUpdatedEventWhereInput>
}

export type QueryPostsByTextArgs = {
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  text: Scalars['String']
  whereForumPost?: InputMaybe<ForumPostWhereInput>
}

export type QueryProposalByUniqueInputArgs = {
  where: ProposalWhereUniqueInput
}

export type QueryProposalCancelledEventByUniqueInputArgs = {
  where: ProposalCancelledEventWhereUniqueInput
}

export type QueryProposalCancelledEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalCancelledEventOrderByInput>>
  where?: InputMaybe<ProposalCancelledEventWhereInput>
}

export type QueryProposalCancelledEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalCancelledEventOrderByInput>>
  where?: InputMaybe<ProposalCancelledEventWhereInput>
}

export type QueryProposalCreatedEventByUniqueInputArgs = {
  where: ProposalCreatedEventWhereUniqueInput
}

export type QueryProposalCreatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalCreatedEventOrderByInput>>
  where?: InputMaybe<ProposalCreatedEventWhereInput>
}

export type QueryProposalCreatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalCreatedEventOrderByInput>>
  where?: InputMaybe<ProposalCreatedEventWhereInput>
}

export type QueryProposalDecisionMadeEventByUniqueInputArgs = {
  where: ProposalDecisionMadeEventWhereUniqueInput
}

export type QueryProposalDecisionMadeEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDecisionMadeEventOrderByInput>>
  where?: InputMaybe<ProposalDecisionMadeEventWhereInput>
}

export type QueryProposalDecisionMadeEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDecisionMadeEventOrderByInput>>
  where?: InputMaybe<ProposalDecisionMadeEventWhereInput>
}

export type QueryProposalDiscussionPostByUniqueInputArgs = {
  where: ProposalDiscussionPostWhereUniqueInput
}

export type QueryProposalDiscussionPostCreatedEventByUniqueInputArgs = {
  where: ProposalDiscussionPostCreatedEventWhereUniqueInput
}

export type QueryProposalDiscussionPostCreatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDiscussionPostCreatedEventOrderByInput>>
  where?: InputMaybe<ProposalDiscussionPostCreatedEventWhereInput>
}

export type QueryProposalDiscussionPostCreatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDiscussionPostCreatedEventOrderByInput>>
  where?: InputMaybe<ProposalDiscussionPostCreatedEventWhereInput>
}

export type QueryProposalDiscussionPostDeletedEventByUniqueInputArgs = {
  where: ProposalDiscussionPostDeletedEventWhereUniqueInput
}

export type QueryProposalDiscussionPostDeletedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDiscussionPostDeletedEventOrderByInput>>
  where?: InputMaybe<ProposalDiscussionPostDeletedEventWhereInput>
}

export type QueryProposalDiscussionPostDeletedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDiscussionPostDeletedEventOrderByInput>>
  where?: InputMaybe<ProposalDiscussionPostDeletedEventWhereInput>
}

export type QueryProposalDiscussionPostUpdatedEventByUniqueInputArgs = {
  where: ProposalDiscussionPostUpdatedEventWhereUniqueInput
}

export type QueryProposalDiscussionPostUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDiscussionPostUpdatedEventOrderByInput>>
  where?: InputMaybe<ProposalDiscussionPostUpdatedEventWhereInput>
}

export type QueryProposalDiscussionPostUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDiscussionPostUpdatedEventOrderByInput>>
  where?: InputMaybe<ProposalDiscussionPostUpdatedEventWhereInput>
}

export type QueryProposalDiscussionPostsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDiscussionPostOrderByInput>>
  where?: InputMaybe<ProposalDiscussionPostWhereInput>
}

export type QueryProposalDiscussionPostsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDiscussionPostOrderByInput>>
  where?: InputMaybe<ProposalDiscussionPostWhereInput>
}

export type QueryProposalDiscussionThreadByUniqueInputArgs = {
  where: ProposalDiscussionThreadWhereUniqueInput
}

export type QueryProposalDiscussionThreadModeChangedEventByUniqueInputArgs = {
  where: ProposalDiscussionThreadModeChangedEventWhereUniqueInput
}

export type QueryProposalDiscussionThreadModeChangedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDiscussionThreadModeChangedEventOrderByInput>>
  where?: InputMaybe<ProposalDiscussionThreadModeChangedEventWhereInput>
}

export type QueryProposalDiscussionThreadModeChangedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDiscussionThreadModeChangedEventOrderByInput>>
  where?: InputMaybe<ProposalDiscussionThreadModeChangedEventWhereInput>
}

export type QueryProposalDiscussionThreadsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDiscussionThreadOrderByInput>>
  where?: InputMaybe<ProposalDiscussionThreadWhereInput>
}

export type QueryProposalDiscussionThreadsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDiscussionThreadOrderByInput>>
  where?: InputMaybe<ProposalDiscussionThreadWhereInput>
}

export type QueryProposalDiscussionWhitelistByUniqueInputArgs = {
  where: ProposalDiscussionWhitelistWhereUniqueInput
}

export type QueryProposalDiscussionWhitelistsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDiscussionWhitelistOrderByInput>>
  where?: InputMaybe<ProposalDiscussionWhitelistWhereInput>
}

export type QueryProposalDiscussionWhitelistsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalDiscussionWhitelistOrderByInput>>
  where?: InputMaybe<ProposalDiscussionWhitelistWhereInput>
}

export type QueryProposalExecutedEventByUniqueInputArgs = {
  where: ProposalExecutedEventWhereUniqueInput
}

export type QueryProposalExecutedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalExecutedEventOrderByInput>>
  where?: InputMaybe<ProposalExecutedEventWhereInput>
}

export type QueryProposalExecutedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalExecutedEventOrderByInput>>
  where?: InputMaybe<ProposalExecutedEventWhereInput>
}

export type QueryProposalStatusUpdatedEventByUniqueInputArgs = {
  where: ProposalStatusUpdatedEventWhereUniqueInput
}

export type QueryProposalStatusUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalStatusUpdatedEventOrderByInput>>
  where?: InputMaybe<ProposalStatusUpdatedEventWhereInput>
}

export type QueryProposalStatusUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalStatusUpdatedEventOrderByInput>>
  where?: InputMaybe<ProposalStatusUpdatedEventWhereInput>
}

export type QueryProposalVotedEventByUniqueInputArgs = {
  where: ProposalVotedEventWhereUniqueInput
}

export type QueryProposalVotedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalVotedEventOrderByInput>>
  where?: InputMaybe<ProposalVotedEventWhereInput>
}

export type QueryProposalVotedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalVotedEventOrderByInput>>
  where?: InputMaybe<ProposalVotedEventWhereInput>
}

export type QueryProposalsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalOrderByInput>>
  where?: InputMaybe<ProposalWhereInput>
}

export type QueryProposalsByTitleArgs = {
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  text: Scalars['String']
  whereProposal?: InputMaybe<ProposalWhereInput>
}

export type QueryProposalsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProposalOrderByInput>>
  where?: InputMaybe<ProposalWhereInput>
}

export type QueryReferendumFinishedEventByUniqueInputArgs = {
  where: ReferendumFinishedEventWhereUniqueInput
}

export type QueryReferendumFinishedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ReferendumFinishedEventOrderByInput>>
  where?: InputMaybe<ReferendumFinishedEventWhereInput>
}

export type QueryReferendumFinishedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ReferendumFinishedEventOrderByInput>>
  where?: InputMaybe<ReferendumFinishedEventWhereInput>
}

export type QueryReferendumStageRevealingByUniqueInputArgs = {
  where: ReferendumStageRevealingWhereUniqueInput
}

export type QueryReferendumStageRevealingsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ReferendumStageRevealingOrderByInput>>
  where?: InputMaybe<ReferendumStageRevealingWhereInput>
}

export type QueryReferendumStageRevealingsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ReferendumStageRevealingOrderByInput>>
  where?: InputMaybe<ReferendumStageRevealingWhereInput>
}

export type QueryReferendumStageVotingByUniqueInputArgs = {
  where: ReferendumStageVotingWhereUniqueInput
}

export type QueryReferendumStageVotingsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ReferendumStageVotingOrderByInput>>
  where?: InputMaybe<ReferendumStageVotingWhereInput>
}

export type QueryReferendumStageVotingsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ReferendumStageVotingOrderByInput>>
  where?: InputMaybe<ReferendumStageVotingWhereInput>
}

export type QueryReferendumStartedEventByUniqueInputArgs = {
  where: ReferendumStartedEventWhereUniqueInput
}

export type QueryReferendumStartedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ReferendumStartedEventOrderByInput>>
  where?: InputMaybe<ReferendumStartedEventWhereInput>
}

export type QueryReferendumStartedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ReferendumStartedEventOrderByInput>>
  where?: InputMaybe<ReferendumStartedEventWhereInput>
}

export type QueryReferendumStartedForcefullyEventByUniqueInputArgs = {
  where: ReferendumStartedForcefullyEventWhereUniqueInput
}

export type QueryReferendumStartedForcefullyEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ReferendumStartedForcefullyEventOrderByInput>>
  where?: InputMaybe<ReferendumStartedForcefullyEventWhereInput>
}

export type QueryReferendumStartedForcefullyEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ReferendumStartedForcefullyEventOrderByInput>>
  where?: InputMaybe<ReferendumStartedForcefullyEventWhereInput>
}

export type QueryReferralCutUpdatedEventByUniqueInputArgs = {
  where: ReferralCutUpdatedEventWhereUniqueInput
}

export type QueryReferralCutUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ReferralCutUpdatedEventOrderByInput>>
  where?: InputMaybe<ReferralCutUpdatedEventWhereInput>
}

export type QueryReferralCutUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ReferralCutUpdatedEventOrderByInput>>
  where?: InputMaybe<ReferralCutUpdatedEventWhereInput>
}

export type QueryRequestFundedEventByUniqueInputArgs = {
  where: RequestFundedEventWhereUniqueInput
}

export type QueryRequestFundedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<RequestFundedEventOrderByInput>>
  where?: InputMaybe<RequestFundedEventWhereInput>
}

export type QueryRequestFundedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<RequestFundedEventOrderByInput>>
  where?: InputMaybe<RequestFundedEventWhereInput>
}

export type QueryRevealingStageStartedEventByUniqueInputArgs = {
  where: RevealingStageStartedEventWhereUniqueInput
}

export type QueryRevealingStageStartedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<RevealingStageStartedEventOrderByInput>>
  where?: InputMaybe<RevealingStageStartedEventWhereInput>
}

export type QueryRevealingStageStartedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<RevealingStageStartedEventOrderByInput>>
  where?: InputMaybe<RevealingStageStartedEventWhereInput>
}

export type QueryRewardPaidEventByUniqueInputArgs = {
  where: RewardPaidEventWhereUniqueInput
}

export type QueryRewardPaidEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<RewardPaidEventOrderByInput>>
  where?: InputMaybe<RewardPaidEventWhereInput>
}

export type QueryRewardPaidEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<RewardPaidEventOrderByInput>>
  where?: InputMaybe<RewardPaidEventWhereInput>
}

export type QueryRewardPaymentEventByUniqueInputArgs = {
  where: RewardPaymentEventWhereUniqueInput
}

export type QueryRewardPaymentEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<RewardPaymentEventOrderByInput>>
  where?: InputMaybe<RewardPaymentEventWhereInput>
}

export type QueryRewardPaymentEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<RewardPaymentEventOrderByInput>>
  where?: InputMaybe<RewardPaymentEventWhereInput>
}

export type QueryRuntimeWasmBytecodeByUniqueInputArgs = {
  where: RuntimeWasmBytecodeWhereUniqueInput
}

export type QueryRuntimeWasmBytecodesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<RuntimeWasmBytecodeOrderByInput>>
  where?: InputMaybe<RuntimeWasmBytecodeWhereInput>
}

export type QueryRuntimeWasmBytecodesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<RuntimeWasmBytecodeOrderByInput>>
  where?: InputMaybe<RuntimeWasmBytecodeWhereInput>
}

export type QuerySearchArgs = {
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  text: Scalars['String']
  whereChannel?: InputMaybe<ChannelWhereInput>
  whereVideo?: InputMaybe<VideoWhereInput>
}

export type QueryStakeDecreasedEventByUniqueInputArgs = {
  where: StakeDecreasedEventWhereUniqueInput
}

export type QueryStakeDecreasedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StakeDecreasedEventOrderByInput>>
  where?: InputMaybe<StakeDecreasedEventWhereInput>
}

export type QueryStakeDecreasedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StakeDecreasedEventOrderByInput>>
  where?: InputMaybe<StakeDecreasedEventWhereInput>
}

export type QueryStakeIncreasedEventByUniqueInputArgs = {
  where: StakeIncreasedEventWhereUniqueInput
}

export type QueryStakeIncreasedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StakeIncreasedEventOrderByInput>>
  where?: InputMaybe<StakeIncreasedEventWhereInput>
}

export type QueryStakeIncreasedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StakeIncreasedEventOrderByInput>>
  where?: InputMaybe<StakeIncreasedEventWhereInput>
}

export type QueryStakeReleasedEventByUniqueInputArgs = {
  where: StakeReleasedEventWhereUniqueInput
}

export type QueryStakeReleasedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StakeReleasedEventOrderByInput>>
  where?: InputMaybe<StakeReleasedEventWhereInput>
}

export type QueryStakeReleasedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StakeReleasedEventOrderByInput>>
  where?: InputMaybe<StakeReleasedEventWhereInput>
}

export type QueryStakeSlashedEventByUniqueInputArgs = {
  where: StakeSlashedEventWhereUniqueInput
}

export type QueryStakeSlashedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StakeSlashedEventOrderByInput>>
  where?: InputMaybe<StakeSlashedEventWhereInput>
}

export type QueryStakeSlashedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StakeSlashedEventOrderByInput>>
  where?: InputMaybe<StakeSlashedEventWhereInput>
}

export type QueryStakingAccountAddedEventByUniqueInputArgs = {
  where: StakingAccountAddedEventWhereUniqueInput
}

export type QueryStakingAccountAddedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StakingAccountAddedEventOrderByInput>>
  where?: InputMaybe<StakingAccountAddedEventWhereInput>
}

export type QueryStakingAccountAddedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StakingAccountAddedEventOrderByInput>>
  where?: InputMaybe<StakingAccountAddedEventWhereInput>
}

export type QueryStakingAccountConfirmedEventByUniqueInputArgs = {
  where: StakingAccountConfirmedEventWhereUniqueInput
}

export type QueryStakingAccountConfirmedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StakingAccountConfirmedEventOrderByInput>>
  where?: InputMaybe<StakingAccountConfirmedEventWhereInput>
}

export type QueryStakingAccountConfirmedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StakingAccountConfirmedEventOrderByInput>>
  where?: InputMaybe<StakingAccountConfirmedEventWhereInput>
}

export type QueryStakingAccountRemovedEventByUniqueInputArgs = {
  where: StakingAccountRemovedEventWhereUniqueInput
}

export type QueryStakingAccountRemovedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StakingAccountRemovedEventOrderByInput>>
  where?: InputMaybe<StakingAccountRemovedEventWhereInput>
}

export type QueryStakingAccountRemovedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StakingAccountRemovedEventOrderByInput>>
  where?: InputMaybe<StakingAccountRemovedEventWhereInput>
}

export type QueryStatusTextChangedEventByUniqueInputArgs = {
  where: StatusTextChangedEventWhereUniqueInput
}

export type QueryStatusTextChangedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StatusTextChangedEventOrderByInput>>
  where?: InputMaybe<StatusTextChangedEventWhereInput>
}

export type QueryStatusTextChangedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StatusTextChangedEventOrderByInput>>
  where?: InputMaybe<StatusTextChangedEventWhereInput>
}

export type QueryStorageBagByUniqueInputArgs = {
  where: StorageBagWhereUniqueInput
}

export type QueryStorageBagsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StorageBagOrderByInput>>
  where?: InputMaybe<StorageBagWhereInput>
}

export type QueryStorageBagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StorageBagOrderByInput>>
  where?: InputMaybe<StorageBagWhereInput>
}

export type QueryStorageBucketByUniqueInputArgs = {
  where: StorageBucketWhereUniqueInput
}

export type QueryStorageBucketOperatorMetadataArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StorageBucketOperatorMetadataOrderByInput>>
  where?: InputMaybe<StorageBucketOperatorMetadataWhereInput>
}

export type QueryStorageBucketOperatorMetadataByUniqueInputArgs = {
  where: StorageBucketOperatorMetadataWhereUniqueInput
}

export type QueryStorageBucketOperatorMetadataConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StorageBucketOperatorMetadataOrderByInput>>
  where?: InputMaybe<StorageBucketOperatorMetadataWhereInput>
}

export type QueryStorageBucketsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StorageBucketOrderByInput>>
  where?: InputMaybe<StorageBucketWhereInput>
}

export type QueryStorageBucketsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StorageBucketOrderByInput>>
  where?: InputMaybe<StorageBucketWhereInput>
}

export type QueryStorageDataObjectByUniqueInputArgs = {
  where: StorageDataObjectWhereUniqueInput
}

export type QueryStorageDataObjectsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StorageDataObjectOrderByInput>>
  where?: InputMaybe<StorageDataObjectWhereInput>
}

export type QueryStorageDataObjectsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<StorageDataObjectOrderByInput>>
  where?: InputMaybe<StorageDataObjectWhereInput>
}

export type QueryTerminatedLeaderEventByUniqueInputArgs = {
  where: TerminatedLeaderEventWhereUniqueInput
}

export type QueryTerminatedLeaderEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<TerminatedLeaderEventOrderByInput>>
  where?: InputMaybe<TerminatedLeaderEventWhereInput>
}

export type QueryTerminatedLeaderEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<TerminatedLeaderEventOrderByInput>>
  where?: InputMaybe<TerminatedLeaderEventWhereInput>
}

export type QueryTerminatedWorkerEventByUniqueInputArgs = {
  where: TerminatedWorkerEventWhereUniqueInput
}

export type QueryTerminatedWorkerEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<TerminatedWorkerEventOrderByInput>>
  where?: InputMaybe<TerminatedWorkerEventWhereInput>
}

export type QueryTerminatedWorkerEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<TerminatedWorkerEventOrderByInput>>
  where?: InputMaybe<TerminatedWorkerEventWhereInput>
}

export type QueryThreadCreatedEventByUniqueInputArgs = {
  where: ThreadCreatedEventWhereUniqueInput
}

export type QueryThreadCreatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ThreadCreatedEventOrderByInput>>
  where?: InputMaybe<ThreadCreatedEventWhereInput>
}

export type QueryThreadCreatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ThreadCreatedEventOrderByInput>>
  where?: InputMaybe<ThreadCreatedEventWhereInput>
}

export type QueryThreadDeletedEventByUniqueInputArgs = {
  where: ThreadDeletedEventWhereUniqueInput
}

export type QueryThreadDeletedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ThreadDeletedEventOrderByInput>>
  where?: InputMaybe<ThreadDeletedEventWhereInput>
}

export type QueryThreadDeletedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ThreadDeletedEventOrderByInput>>
  where?: InputMaybe<ThreadDeletedEventWhereInput>
}

export type QueryThreadMetadataUpdatedEventByUniqueInputArgs = {
  where: ThreadMetadataUpdatedEventWhereUniqueInput
}

export type QueryThreadMetadataUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ThreadMetadataUpdatedEventOrderByInput>>
  where?: InputMaybe<ThreadMetadataUpdatedEventWhereInput>
}

export type QueryThreadMetadataUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ThreadMetadataUpdatedEventOrderByInput>>
  where?: InputMaybe<ThreadMetadataUpdatedEventWhereInput>
}

export type QueryThreadModeratedEventByUniqueInputArgs = {
  where: ThreadModeratedEventWhereUniqueInput
}

export type QueryThreadModeratedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ThreadModeratedEventOrderByInput>>
  where?: InputMaybe<ThreadModeratedEventWhereInput>
}

export type QueryThreadModeratedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ThreadModeratedEventOrderByInput>>
  where?: InputMaybe<ThreadModeratedEventWhereInput>
}

export type QueryThreadMovedEventByUniqueInputArgs = {
  where: ThreadMovedEventWhereUniqueInput
}

export type QueryThreadMovedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ThreadMovedEventOrderByInput>>
  where?: InputMaybe<ThreadMovedEventWhereInput>
}

export type QueryThreadMovedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ThreadMovedEventOrderByInput>>
  where?: InputMaybe<ThreadMovedEventWhereInput>
}

export type QueryThreadsByTitleArgs = {
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  text: Scalars['String']
  whereForumThread?: InputMaybe<ForumThreadWhereInput>
}

export type QueryTransactionalStatusUpdateByUniqueInputArgs = {
  where: TransactionalStatusUpdateWhereUniqueInput
}

export type QueryTransactionalStatusUpdatesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<TransactionalStatusUpdateOrderByInput>>
  where?: InputMaybe<TransactionalStatusUpdateWhereInput>
}

export type QueryTransactionalStatusUpdatesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<TransactionalStatusUpdateOrderByInput>>
  where?: InputMaybe<TransactionalStatusUpdateWhereInput>
}

export type QueryUpcomingWorkingGroupOpeningByUniqueInputArgs = {
  where: UpcomingWorkingGroupOpeningWhereUniqueInput
}

export type QueryUpcomingWorkingGroupOpeningsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<UpcomingWorkingGroupOpeningOrderByInput>>
  where?: InputMaybe<UpcomingWorkingGroupOpeningWhereInput>
}

export type QueryUpcomingWorkingGroupOpeningsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<UpcomingWorkingGroupOpeningOrderByInput>>
  where?: InputMaybe<UpcomingWorkingGroupOpeningWhereInput>
}

export type QueryVideoAssetsDeletedByModeratorEventByUniqueInputArgs = {
  where: VideoAssetsDeletedByModeratorEventWhereUniqueInput
}

export type QueryVideoAssetsDeletedByModeratorEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoAssetsDeletedByModeratorEventOrderByInput>>
  where?: InputMaybe<VideoAssetsDeletedByModeratorEventWhereInput>
}

export type QueryVideoAssetsDeletedByModeratorEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoAssetsDeletedByModeratorEventOrderByInput>>
  where?: InputMaybe<VideoAssetsDeletedByModeratorEventWhereInput>
}

export type QueryVideoByUniqueInputArgs = {
  where: VideoWhereUniqueInput
}

export type QueryVideoCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoCategoryOrderByInput>>
  where?: InputMaybe<VideoCategoryWhereInput>
}

export type QueryVideoCategoriesByNameArgs = {
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  text: Scalars['String']
  whereVideoCategory?: InputMaybe<VideoCategoryWhereInput>
}

export type QueryVideoCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoCategoryOrderByInput>>
  where?: InputMaybe<VideoCategoryWhereInput>
}

export type QueryVideoCategoryByUniqueInputArgs = {
  where: VideoCategoryWhereUniqueInput
}

export type QueryVideoDeletedByModeratorEventByUniqueInputArgs = {
  where: VideoDeletedByModeratorEventWhereUniqueInput
}

export type QueryVideoDeletedByModeratorEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoDeletedByModeratorEventOrderByInput>>
  where?: InputMaybe<VideoDeletedByModeratorEventWhereInput>
}

export type QueryVideoDeletedByModeratorEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoDeletedByModeratorEventOrderByInput>>
  where?: InputMaybe<VideoDeletedByModeratorEventWhereInput>
}

export type QueryVideoDeletedEventByUniqueInputArgs = {
  where: VideoDeletedEventWhereUniqueInput
}

export type QueryVideoDeletedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoDeletedEventOrderByInput>>
  where?: InputMaybe<VideoDeletedEventWhereInput>
}

export type QueryVideoDeletedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoDeletedEventOrderByInput>>
  where?: InputMaybe<VideoDeletedEventWhereInput>
}

export type QueryVideoMediaEncodingByUniqueInputArgs = {
  where: VideoMediaEncodingWhereUniqueInput
}

export type QueryVideoMediaEncodingsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoMediaEncodingOrderByInput>>
  where?: InputMaybe<VideoMediaEncodingWhereInput>
}

export type QueryVideoMediaEncodingsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoMediaEncodingOrderByInput>>
  where?: InputMaybe<VideoMediaEncodingWhereInput>
}

export type QueryVideoMediaMetadataArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoMediaMetadataOrderByInput>>
  where?: InputMaybe<VideoMediaMetadataWhereInput>
}

export type QueryVideoMediaMetadataByUniqueInputArgs = {
  where: VideoMediaMetadataWhereUniqueInput
}

export type QueryVideoMediaMetadataConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoMediaMetadataOrderByInput>>
  where?: InputMaybe<VideoMediaMetadataWhereInput>
}

export type QueryVideoReactedEventByUniqueInputArgs = {
  where: VideoReactedEventWhereUniqueInput
}

export type QueryVideoReactedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoReactedEventOrderByInput>>
  where?: InputMaybe<VideoReactedEventWhereInput>
}

export type QueryVideoReactedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoReactedEventOrderByInput>>
  where?: InputMaybe<VideoReactedEventWhereInput>
}

export type QueryVideoReactionByUniqueInputArgs = {
  where: VideoReactionWhereUniqueInput
}

export type QueryVideoReactionsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoReactionOrderByInput>>
  where?: InputMaybe<VideoReactionWhereInput>
}

export type QueryVideoReactionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoReactionOrderByInput>>
  where?: InputMaybe<VideoReactionWhereInput>
}

export type QueryVideoReactionsCountByReactionTypeByUniqueInputArgs = {
  where: VideoReactionsCountByReactionTypeWhereUniqueInput
}

export type QueryVideoReactionsCountByReactionTypesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoReactionsCountByReactionTypeOrderByInput>>
  where?: InputMaybe<VideoReactionsCountByReactionTypeWhereInput>
}

export type QueryVideoReactionsCountByReactionTypesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoReactionsCountByReactionTypeOrderByInput>>
  where?: InputMaybe<VideoReactionsCountByReactionTypeWhereInput>
}

export type QueryVideoReactionsPreferenceEventByUniqueInputArgs = {
  where: VideoReactionsPreferenceEventWhereUniqueInput
}

export type QueryVideoReactionsPreferenceEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoReactionsPreferenceEventOrderByInput>>
  where?: InputMaybe<VideoReactionsPreferenceEventWhereInput>
}

export type QueryVideoReactionsPreferenceEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoReactionsPreferenceEventOrderByInput>>
  where?: InputMaybe<VideoReactionsPreferenceEventWhereInput>
}

export type QueryVideoSubtitleByUniqueInputArgs = {
  where: VideoSubtitleWhereUniqueInput
}

export type QueryVideoSubtitlesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoSubtitleOrderByInput>>
  where?: InputMaybe<VideoSubtitleWhereInput>
}

export type QueryVideoSubtitlesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoSubtitleOrderByInput>>
  where?: InputMaybe<VideoSubtitleWhereInput>
}

export type QueryVideoVisibilitySetByModeratorEventByUniqueInputArgs = {
  where: VideoVisibilitySetByModeratorEventWhereUniqueInput
}

export type QueryVideoVisibilitySetByModeratorEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoVisibilitySetByModeratorEventOrderByInput>>
  where?: InputMaybe<VideoVisibilitySetByModeratorEventWhereInput>
}

export type QueryVideoVisibilitySetByModeratorEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoVisibilitySetByModeratorEventOrderByInput>>
  where?: InputMaybe<VideoVisibilitySetByModeratorEventWhereInput>
}

export type QueryVideosArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoOrderByInput>>
  where?: InputMaybe<VideoWhereInput>
}

export type QueryVideosConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VideoOrderByInput>>
  where?: InputMaybe<VideoWhereInput>
}

export type QueryVoteCastEventByUniqueInputArgs = {
  where: VoteCastEventWhereUniqueInput
}

export type QueryVoteCastEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VoteCastEventOrderByInput>>
  where?: InputMaybe<VoteCastEventWhereInput>
}

export type QueryVoteCastEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VoteCastEventOrderByInput>>
  where?: InputMaybe<VoteCastEventWhereInput>
}

export type QueryVoteRevealedEventByUniqueInputArgs = {
  where: VoteRevealedEventWhereUniqueInput
}

export type QueryVoteRevealedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VoteRevealedEventOrderByInput>>
  where?: InputMaybe<VoteRevealedEventWhereInput>
}

export type QueryVoteRevealedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VoteRevealedEventOrderByInput>>
  where?: InputMaybe<VoteRevealedEventWhereInput>
}

export type QueryVotingPeriodStartedEventByUniqueInputArgs = {
  where: VotingPeriodStartedEventWhereUniqueInput
}

export type QueryVotingPeriodStartedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VotingPeriodStartedEventOrderByInput>>
  where?: InputMaybe<VotingPeriodStartedEventWhereInput>
}

export type QueryVotingPeriodStartedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<VotingPeriodStartedEventOrderByInput>>
  where?: InputMaybe<VotingPeriodStartedEventWhereInput>
}

export type QueryWorkEntrantFundsWithdrawnEventByUniqueInputArgs = {
  where: WorkEntrantFundsWithdrawnEventWhereUniqueInput
}

export type QueryWorkEntrantFundsWithdrawnEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkEntrantFundsWithdrawnEventOrderByInput>>
  where?: InputMaybe<WorkEntrantFundsWithdrawnEventWhereInput>
}

export type QueryWorkEntrantFundsWithdrawnEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkEntrantFundsWithdrawnEventOrderByInput>>
  where?: InputMaybe<WorkEntrantFundsWithdrawnEventWhereInput>
}

export type QueryWorkEntryAnnouncedEventByUniqueInputArgs = {
  where: WorkEntryAnnouncedEventWhereUniqueInput
}

export type QueryWorkEntryAnnouncedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkEntryAnnouncedEventOrderByInput>>
  where?: InputMaybe<WorkEntryAnnouncedEventWhereInput>
}

export type QueryWorkEntryAnnouncedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkEntryAnnouncedEventOrderByInput>>
  where?: InputMaybe<WorkEntryAnnouncedEventWhereInput>
}

export type QueryWorkEntrySlashedEventByUniqueInputArgs = {
  where: WorkEntrySlashedEventWhereUniqueInput
}

export type QueryWorkEntrySlashedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkEntrySlashedEventOrderByInput>>
  where?: InputMaybe<WorkEntrySlashedEventWhereInput>
}

export type QueryWorkEntrySlashedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkEntrySlashedEventOrderByInput>>
  where?: InputMaybe<WorkEntrySlashedEventWhereInput>
}

export type QueryWorkEntryWithdrawnEventByUniqueInputArgs = {
  where: WorkEntryWithdrawnEventWhereUniqueInput
}

export type QueryWorkEntryWithdrawnEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkEntryWithdrawnEventOrderByInput>>
  where?: InputMaybe<WorkEntryWithdrawnEventWhereInput>
}

export type QueryWorkEntryWithdrawnEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkEntryWithdrawnEventOrderByInput>>
  where?: InputMaybe<WorkEntryWithdrawnEventWhereInput>
}

export type QueryWorkSubmittedEventByUniqueInputArgs = {
  where: WorkSubmittedEventWhereUniqueInput
}

export type QueryWorkSubmittedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkSubmittedEventOrderByInput>>
  where?: InputMaybe<WorkSubmittedEventWhereInput>
}

export type QueryWorkSubmittedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkSubmittedEventOrderByInput>>
  where?: InputMaybe<WorkSubmittedEventWhereInput>
}

export type QueryWorkerByUniqueInputArgs = {
  where: WorkerWhereUniqueInput
}

export type QueryWorkerExitedEventByUniqueInputArgs = {
  where: WorkerExitedEventWhereUniqueInput
}

export type QueryWorkerExitedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkerExitedEventOrderByInput>>
  where?: InputMaybe<WorkerExitedEventWhereInput>
}

export type QueryWorkerExitedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkerExitedEventOrderByInput>>
  where?: InputMaybe<WorkerExitedEventWhereInput>
}

export type QueryWorkerRewardAccountUpdatedEventByUniqueInputArgs = {
  where: WorkerRewardAccountUpdatedEventWhereUniqueInput
}

export type QueryWorkerRewardAccountUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkerRewardAccountUpdatedEventOrderByInput>>
  where?: InputMaybe<WorkerRewardAccountUpdatedEventWhereInput>
}

export type QueryWorkerRewardAccountUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkerRewardAccountUpdatedEventOrderByInput>>
  where?: InputMaybe<WorkerRewardAccountUpdatedEventWhereInput>
}

export type QueryWorkerRewardAmountUpdatedEventByUniqueInputArgs = {
  where: WorkerRewardAmountUpdatedEventWhereUniqueInput
}

export type QueryWorkerRewardAmountUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkerRewardAmountUpdatedEventOrderByInput>>
  where?: InputMaybe<WorkerRewardAmountUpdatedEventWhereInput>
}

export type QueryWorkerRewardAmountUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkerRewardAmountUpdatedEventOrderByInput>>
  where?: InputMaybe<WorkerRewardAmountUpdatedEventWhereInput>
}

export type QueryWorkerRoleAccountUpdatedEventByUniqueInputArgs = {
  where: WorkerRoleAccountUpdatedEventWhereUniqueInput
}

export type QueryWorkerRoleAccountUpdatedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkerRoleAccountUpdatedEventOrderByInput>>
  where?: InputMaybe<WorkerRoleAccountUpdatedEventWhereInput>
}

export type QueryWorkerRoleAccountUpdatedEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkerRoleAccountUpdatedEventOrderByInput>>
  where?: InputMaybe<WorkerRoleAccountUpdatedEventWhereInput>
}

export type QueryWorkerStartedLeavingEventByUniqueInputArgs = {
  where: WorkerStartedLeavingEventWhereUniqueInput
}

export type QueryWorkerStartedLeavingEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkerStartedLeavingEventOrderByInput>>
  where?: InputMaybe<WorkerStartedLeavingEventWhereInput>
}

export type QueryWorkerStartedLeavingEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkerStartedLeavingEventOrderByInput>>
  where?: InputMaybe<WorkerStartedLeavingEventWhereInput>
}

export type QueryWorkersArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkerOrderByInput>>
  where?: InputMaybe<WorkerWhereInput>
}

export type QueryWorkersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkerOrderByInput>>
  where?: InputMaybe<WorkerWhereInput>
}

export type QueryWorkingGroupApplicationByUniqueInputArgs = {
  where: WorkingGroupApplicationWhereUniqueInput
}

export type QueryWorkingGroupApplicationsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkingGroupApplicationOrderByInput>>
  where?: InputMaybe<WorkingGroupApplicationWhereInput>
}

export type QueryWorkingGroupApplicationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkingGroupApplicationOrderByInput>>
  where?: InputMaybe<WorkingGroupApplicationWhereInput>
}

export type QueryWorkingGroupByUniqueInputArgs = {
  where: WorkingGroupWhereUniqueInput
}

export type QueryWorkingGroupMetadataArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkingGroupMetadataOrderByInput>>
  where?: InputMaybe<WorkingGroupMetadataWhereInput>
}

export type QueryWorkingGroupMetadataByUniqueInputArgs = {
  where: WorkingGroupMetadataWhereUniqueInput
}

export type QueryWorkingGroupMetadataConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkingGroupMetadataOrderByInput>>
  where?: InputMaybe<WorkingGroupMetadataWhereInput>
}

export type QueryWorkingGroupOpeningByUniqueInputArgs = {
  where: WorkingGroupOpeningWhereUniqueInput
}

export type QueryWorkingGroupOpeningMetadataArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkingGroupOpeningMetadataOrderByInput>>
  where?: InputMaybe<WorkingGroupOpeningMetadataWhereInput>
}

export type QueryWorkingGroupOpeningMetadataByUniqueInputArgs = {
  where: WorkingGroupOpeningMetadataWhereUniqueInput
}

export type QueryWorkingGroupOpeningMetadataConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkingGroupOpeningMetadataOrderByInput>>
  where?: InputMaybe<WorkingGroupOpeningMetadataWhereInput>
}

export type QueryWorkingGroupOpeningsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkingGroupOpeningOrderByInput>>
  where?: InputMaybe<WorkingGroupOpeningWhereInput>
}

export type QueryWorkingGroupOpeningsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkingGroupOpeningOrderByInput>>
  where?: InputMaybe<WorkingGroupOpeningWhereInput>
}

export type QueryWorkingGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkingGroupOrderByInput>>
  where?: InputMaybe<WorkingGroupWhereInput>
}

export type QueryWorkingGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<WorkingGroupOrderByInput>>
  where?: InputMaybe<WorkingGroupWhereInput>
}

export type ReferendumFinishedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ReferendumFinishedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ReferendumFinishedEventConnection = {
  __typename: 'ReferendumFinishedEventConnection'
  edges: Array<ReferendumFinishedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ReferendumFinishedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type ReferendumFinishedEventEdge = {
  __typename: 'ReferendumFinishedEventEdge'
  cursor: Scalars['String']
  node: ReferendumFinishedEvent
}

export enum ReferendumFinishedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ReferendumFinishedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type ReferendumFinishedEventWhereInput = {
  AND?: InputMaybe<Array<ReferendumFinishedEventWhereInput>>
  NOT?: InputMaybe<Array<ReferendumFinishedEventWhereInput>>
  OR?: InputMaybe<Array<ReferendumFinishedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ReferendumFinishedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ReferendumStageRevealing = BaseGraphQlObject & {
  __typename: 'ReferendumStageRevealing'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  electionRound: ElectionRound
  electionRoundId: Scalars['String']
  /** Block number at which the stage ends */
  endsAt: Scalars['Int']
  id: Scalars['ID']
  /** Block in which referendum started */
  startedAtBlock: Scalars['BigInt']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  /** Target number of winners */
  winningTargetCount: Scalars['BigInt']
}

export type ReferendumStageRevealingConnection = {
  __typename: 'ReferendumStageRevealingConnection'
  edges: Array<ReferendumStageRevealingEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ReferendumStageRevealingCreateInput = {
  electionRound: Scalars['ID']
  endsAt: Scalars['Float']
  startedAtBlock: Scalars['String']
  winningTargetCount: Scalars['String']
}

export type ReferendumStageRevealingEdge = {
  __typename: 'ReferendumStageRevealingEdge'
  cursor: Scalars['String']
  node: ReferendumStageRevealing
}

export enum ReferendumStageRevealingOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ElectionRoundAsc = 'electionRound_ASC',
  ElectionRoundDesc = 'electionRound_DESC',
  EndsAtAsc = 'endsAt_ASC',
  EndsAtDesc = 'endsAt_DESC',
  StartedAtBlockAsc = 'startedAtBlock_ASC',
  StartedAtBlockDesc = 'startedAtBlock_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WinningTargetCountAsc = 'winningTargetCount_ASC',
  WinningTargetCountDesc = 'winningTargetCount_DESC',
}

export type ReferendumStageRevealingUpdateInput = {
  electionRound?: InputMaybe<Scalars['ID']>
  endsAt?: InputMaybe<Scalars['Float']>
  startedAtBlock?: InputMaybe<Scalars['String']>
  winningTargetCount?: InputMaybe<Scalars['String']>
}

export type ReferendumStageRevealingWhereInput = {
  AND?: InputMaybe<Array<ReferendumStageRevealingWhereInput>>
  NOT?: InputMaybe<Array<ReferendumStageRevealingWhereInput>>
  OR?: InputMaybe<Array<ReferendumStageRevealingWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  electionRound?: InputMaybe<ElectionRoundWhereInput>
  endsAt_eq?: InputMaybe<Scalars['Int']>
  endsAt_gt?: InputMaybe<Scalars['Int']>
  endsAt_gte?: InputMaybe<Scalars['Int']>
  endsAt_in?: InputMaybe<Array<Scalars['Int']>>
  endsAt_lt?: InputMaybe<Scalars['Int']>
  endsAt_lte?: InputMaybe<Scalars['Int']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  startedAtBlock_eq?: InputMaybe<Scalars['BigInt']>
  startedAtBlock_gt?: InputMaybe<Scalars['BigInt']>
  startedAtBlock_gte?: InputMaybe<Scalars['BigInt']>
  startedAtBlock_in?: InputMaybe<Array<Scalars['BigInt']>>
  startedAtBlock_lt?: InputMaybe<Scalars['BigInt']>
  startedAtBlock_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  winningTargetCount_eq?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_gt?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_gte?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_in?: InputMaybe<Array<Scalars['BigInt']>>
  winningTargetCount_lt?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_lte?: InputMaybe<Scalars['BigInt']>
}

export type ReferendumStageRevealingWhereUniqueInput = {
  id: Scalars['ID']
}

export type ReferendumStageVoting = BaseGraphQlObject & {
  __typename: 'ReferendumStageVoting'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  electionRound: ElectionRound
  electionRoundId: Scalars['String']
  /** Block number at which the stage ends */
  endsAt: Scalars['Int']
  id: Scalars['ID']
  /** Block in which referendum started. */
  startedAtBlock: Scalars['BigInt']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  /** Target number of winners. */
  winningTargetCount: Scalars['BigInt']
}

export type ReferendumStageVotingConnection = {
  __typename: 'ReferendumStageVotingConnection'
  edges: Array<ReferendumStageVotingEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ReferendumStageVotingCreateInput = {
  electionRound: Scalars['ID']
  endsAt: Scalars['Float']
  startedAtBlock: Scalars['String']
  winningTargetCount: Scalars['String']
}

export type ReferendumStageVotingEdge = {
  __typename: 'ReferendumStageVotingEdge'
  cursor: Scalars['String']
  node: ReferendumStageVoting
}

export enum ReferendumStageVotingOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ElectionRoundAsc = 'electionRound_ASC',
  ElectionRoundDesc = 'electionRound_DESC',
  EndsAtAsc = 'endsAt_ASC',
  EndsAtDesc = 'endsAt_DESC',
  StartedAtBlockAsc = 'startedAtBlock_ASC',
  StartedAtBlockDesc = 'startedAtBlock_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WinningTargetCountAsc = 'winningTargetCount_ASC',
  WinningTargetCountDesc = 'winningTargetCount_DESC',
}

export type ReferendumStageVotingUpdateInput = {
  electionRound?: InputMaybe<Scalars['ID']>
  endsAt?: InputMaybe<Scalars['Float']>
  startedAtBlock?: InputMaybe<Scalars['String']>
  winningTargetCount?: InputMaybe<Scalars['String']>
}

export type ReferendumStageVotingWhereInput = {
  AND?: InputMaybe<Array<ReferendumStageVotingWhereInput>>
  NOT?: InputMaybe<Array<ReferendumStageVotingWhereInput>>
  OR?: InputMaybe<Array<ReferendumStageVotingWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  electionRound?: InputMaybe<ElectionRoundWhereInput>
  endsAt_eq?: InputMaybe<Scalars['Int']>
  endsAt_gt?: InputMaybe<Scalars['Int']>
  endsAt_gte?: InputMaybe<Scalars['Int']>
  endsAt_in?: InputMaybe<Array<Scalars['Int']>>
  endsAt_lt?: InputMaybe<Scalars['Int']>
  endsAt_lte?: InputMaybe<Scalars['Int']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  startedAtBlock_eq?: InputMaybe<Scalars['BigInt']>
  startedAtBlock_gt?: InputMaybe<Scalars['BigInt']>
  startedAtBlock_gte?: InputMaybe<Scalars['BigInt']>
  startedAtBlock_in?: InputMaybe<Array<Scalars['BigInt']>>
  startedAtBlock_lt?: InputMaybe<Scalars['BigInt']>
  startedAtBlock_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  winningTargetCount_eq?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_gt?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_gte?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_in?: InputMaybe<Array<Scalars['BigInt']>>
  winningTargetCount_lt?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_lte?: InputMaybe<Scalars['BigInt']>
}

export type ReferendumStageVotingWhereUniqueInput = {
  id: Scalars['ID']
}

export type ReferendumStartedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ReferendumStartedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    /** Amount of winning referendum options. */
    winningTargetCount: Scalars['BigInt']
  }

export type ReferendumStartedEventConnection = {
  __typename: 'ReferendumStartedEventConnection'
  edges: Array<ReferendumStartedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ReferendumStartedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  winningTargetCount: Scalars['String']
}

export type ReferendumStartedEventEdge = {
  __typename: 'ReferendumStartedEventEdge'
  cursor: Scalars['String']
  node: ReferendumStartedEvent
}

export enum ReferendumStartedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WinningTargetCountAsc = 'winningTargetCount_ASC',
  WinningTargetCountDesc = 'winningTargetCount_DESC',
}

export type ReferendumStartedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  winningTargetCount?: InputMaybe<Scalars['String']>
}

export type ReferendumStartedEventWhereInput = {
  AND?: InputMaybe<Array<ReferendumStartedEventWhereInput>>
  NOT?: InputMaybe<Array<ReferendumStartedEventWhereInput>>
  OR?: InputMaybe<Array<ReferendumStartedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  winningTargetCount_eq?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_gt?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_gte?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_in?: InputMaybe<Array<Scalars['BigInt']>>
  winningTargetCount_lt?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_lte?: InputMaybe<Scalars['BigInt']>
}

export type ReferendumStartedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ReferendumStartedForcefullyEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ReferendumStartedForcefullyEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    /** Amount of winning referendum options. */
    winningTargetCount: Scalars['BigInt']
  }

export type ReferendumStartedForcefullyEventConnection = {
  __typename: 'ReferendumStartedForcefullyEventConnection'
  edges: Array<ReferendumStartedForcefullyEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ReferendumStartedForcefullyEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  winningTargetCount: Scalars['String']
}

export type ReferendumStartedForcefullyEventEdge = {
  __typename: 'ReferendumStartedForcefullyEventEdge'
  cursor: Scalars['String']
  node: ReferendumStartedForcefullyEvent
}

export enum ReferendumStartedForcefullyEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WinningTargetCountAsc = 'winningTargetCount_ASC',
  WinningTargetCountDesc = 'winningTargetCount_DESC',
}

export type ReferendumStartedForcefullyEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  winningTargetCount?: InputMaybe<Scalars['String']>
}

export type ReferendumStartedForcefullyEventWhereInput = {
  AND?: InputMaybe<Array<ReferendumStartedForcefullyEventWhereInput>>
  NOT?: InputMaybe<Array<ReferendumStartedForcefullyEventWhereInput>>
  OR?: InputMaybe<Array<ReferendumStartedForcefullyEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  winningTargetCount_eq?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_gt?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_gte?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_in?: InputMaybe<Array<Scalars['BigInt']>>
  winningTargetCount_lt?: InputMaybe<Scalars['BigInt']>
  winningTargetCount_lte?: InputMaybe<Scalars['BigInt']>
}

export type ReferendumStartedForcefullyEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ReferralCutUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ReferralCutUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** New cut value. */
    newValue: Scalars['Int']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ReferralCutUpdatedEventConnection = {
  __typename: 'ReferralCutUpdatedEventConnection'
  edges: Array<ReferralCutUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ReferralCutUpdatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newValue: Scalars['Float']
}

export type ReferralCutUpdatedEventEdge = {
  __typename: 'ReferralCutUpdatedEventEdge'
  cursor: Scalars['String']
  node: ReferralCutUpdatedEvent
}

export enum ReferralCutUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewValueAsc = 'newValue_ASC',
  NewValueDesc = 'newValue_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ReferralCutUpdatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newValue?: InputMaybe<Scalars['Float']>
}

export type ReferralCutUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<ReferralCutUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<ReferralCutUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<ReferralCutUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newValue_eq?: InputMaybe<Scalars['Int']>
  newValue_gt?: InputMaybe<Scalars['Int']>
  newValue_gte?: InputMaybe<Scalars['Int']>
  newValue_in?: InputMaybe<Array<Scalars['Int']>>
  newValue_lt?: InputMaybe<Scalars['Int']>
  newValue_lte?: InputMaybe<Scalars['Int']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ReferralCutUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type RequestFundedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'RequestFundedEvent'
    /** Target account. */
    account: Scalars['String']
    /** Funding amount. */
    amount: Scalars['BigInt']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type RequestFundedEventConnection = {
  __typename: 'RequestFundedEventConnection'
  edges: Array<RequestFundedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type RequestFundedEventCreateInput = {
  account: Scalars['String']
  amount: Scalars['String']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type RequestFundedEventEdge = {
  __typename: 'RequestFundedEventEdge'
  cursor: Scalars['String']
  node: RequestFundedEvent
}

export enum RequestFundedEventOrderByInput {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type RequestFundedEventUpdateInput = {
  account?: InputMaybe<Scalars['String']>
  amount?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type RequestFundedEventWhereInput = {
  AND?: InputMaybe<Array<RequestFundedEventWhereInput>>
  NOT?: InputMaybe<Array<RequestFundedEventWhereInput>>
  OR?: InputMaybe<Array<RequestFundedEventWhereInput>>
  account_contains?: InputMaybe<Scalars['String']>
  account_endsWith?: InputMaybe<Scalars['String']>
  account_eq?: InputMaybe<Scalars['String']>
  account_in?: InputMaybe<Array<Scalars['String']>>
  account_startsWith?: InputMaybe<Scalars['String']>
  amount_eq?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type RequestFundedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type RevealingStageStartedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'RevealingStageStartedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type RevealingStageStartedEventConnection = {
  __typename: 'RevealingStageStartedEventConnection'
  edges: Array<RevealingStageStartedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type RevealingStageStartedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type RevealingStageStartedEventEdge = {
  __typename: 'RevealingStageStartedEventEdge'
  cursor: Scalars['String']
  node: RevealingStageStartedEvent
}

export enum RevealingStageStartedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type RevealingStageStartedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type RevealingStageStartedEventWhereInput = {
  AND?: InputMaybe<Array<RevealingStageStartedEventWhereInput>>
  NOT?: InputMaybe<Array<RevealingStageStartedEventWhereInput>>
  OR?: InputMaybe<Array<RevealingStageStartedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type RevealingStageStartedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type RewardPaidEvent = BaseGraphQlObject &
  Event & {
    __typename: 'RewardPaidEvent'
    /** Amount recieved */
    amount: Scalars['BigInt']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Type of the payment (REGULAR/MISSED) */
    paymentType: RewardPaymentType
    /** The account that recieved the reward */
    rewardAccount: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    worker: Worker
    workerId: Scalars['String']
  }

export type RewardPaidEventConnection = {
  __typename: 'RewardPaidEventConnection'
  edges: Array<RewardPaidEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type RewardPaidEventCreateInput = {
  amount: Scalars['String']
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  paymentType: RewardPaymentType
  rewardAccount: Scalars['String']
  worker: Scalars['ID']
}

export type RewardPaidEventEdge = {
  __typename: 'RewardPaidEventEdge'
  cursor: Scalars['String']
  node: RewardPaidEvent
}

export enum RewardPaidEventOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PaymentTypeAsc = 'paymentType_ASC',
  PaymentTypeDesc = 'paymentType_DESC',
  RewardAccountAsc = 'rewardAccount_ASC',
  RewardAccountDesc = 'rewardAccount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type RewardPaidEventUpdateInput = {
  amount?: InputMaybe<Scalars['String']>
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  paymentType?: InputMaybe<RewardPaymentType>
  rewardAccount?: InputMaybe<Scalars['String']>
  worker?: InputMaybe<Scalars['ID']>
}

export type RewardPaidEventWhereInput = {
  AND?: InputMaybe<Array<RewardPaidEventWhereInput>>
  NOT?: InputMaybe<Array<RewardPaidEventWhereInput>>
  OR?: InputMaybe<Array<RewardPaidEventWhereInput>>
  amount_eq?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  paymentType_eq?: InputMaybe<RewardPaymentType>
  paymentType_in?: InputMaybe<Array<RewardPaymentType>>
  rewardAccount_contains?: InputMaybe<Scalars['String']>
  rewardAccount_endsWith?: InputMaybe<Scalars['String']>
  rewardAccount_eq?: InputMaybe<Scalars['String']>
  rewardAccount_in?: InputMaybe<Array<Scalars['String']>>
  rewardAccount_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  worker?: InputMaybe<WorkerWhereInput>
}

export type RewardPaidEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type RewardPaymentEvent = BaseGraphQlObject &
  Event & {
    __typename: 'RewardPaymentEvent'
    councilMember: CouncilMember
    councilMemberId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Amount that couldn't be paid and will be paid the next time. */
    missingBalance: Scalars['BigInt']
    /** Network the block was produced in */
    network: Network
    /** Amount paid to the council member */
    paidBalance: Scalars['BigInt']
    /** Candidate's account that will be recieving rewards if candidate's elected. */
    rewardAccount: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type RewardPaymentEventConnection = {
  __typename: 'RewardPaymentEventConnection'
  edges: Array<RewardPaymentEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type RewardPaymentEventCreateInput = {
  councilMember: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  missingBalance: Scalars['String']
  network: Network
  paidBalance: Scalars['String']
  rewardAccount: Scalars['String']
}

export type RewardPaymentEventEdge = {
  __typename: 'RewardPaymentEventEdge'
  cursor: Scalars['String']
  node: RewardPaymentEvent
}

export enum RewardPaymentEventOrderByInput {
  CouncilMemberAsc = 'councilMember_ASC',
  CouncilMemberDesc = 'councilMember_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MissingBalanceAsc = 'missingBalance_ASC',
  MissingBalanceDesc = 'missingBalance_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PaidBalanceAsc = 'paidBalance_ASC',
  PaidBalanceDesc = 'paidBalance_DESC',
  RewardAccountAsc = 'rewardAccount_ASC',
  RewardAccountDesc = 'rewardAccount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type RewardPaymentEventUpdateInput = {
  councilMember?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  missingBalance?: InputMaybe<Scalars['String']>
  network?: InputMaybe<Network>
  paidBalance?: InputMaybe<Scalars['String']>
  rewardAccount?: InputMaybe<Scalars['String']>
}

export type RewardPaymentEventWhereInput = {
  AND?: InputMaybe<Array<RewardPaymentEventWhereInput>>
  NOT?: InputMaybe<Array<RewardPaymentEventWhereInput>>
  OR?: InputMaybe<Array<RewardPaymentEventWhereInput>>
  councilMember?: InputMaybe<CouncilMemberWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  missingBalance_eq?: InputMaybe<Scalars['BigInt']>
  missingBalance_gt?: InputMaybe<Scalars['BigInt']>
  missingBalance_gte?: InputMaybe<Scalars['BigInt']>
  missingBalance_in?: InputMaybe<Array<Scalars['BigInt']>>
  missingBalance_lt?: InputMaybe<Scalars['BigInt']>
  missingBalance_lte?: InputMaybe<Scalars['BigInt']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  paidBalance_eq?: InputMaybe<Scalars['BigInt']>
  paidBalance_gt?: InputMaybe<Scalars['BigInt']>
  paidBalance_gte?: InputMaybe<Scalars['BigInt']>
  paidBalance_in?: InputMaybe<Array<Scalars['BigInt']>>
  paidBalance_lt?: InputMaybe<Scalars['BigInt']>
  paidBalance_lte?: InputMaybe<Scalars['BigInt']>
  rewardAccount_contains?: InputMaybe<Scalars['String']>
  rewardAccount_endsWith?: InputMaybe<Scalars['String']>
  rewardAccount_eq?: InputMaybe<Scalars['String']>
  rewardAccount_in?: InputMaybe<Array<Scalars['String']>>
  rewardAccount_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type RewardPaymentEventWhereUniqueInput = {
  id: Scalars['ID']
}

export enum RewardPaymentType {
  Missed = 'MISSED',
  Regular = 'REGULAR',
}

export type RuntimeUpgradeProposalDetails = {
  __typename: 'RuntimeUpgradeProposalDetails'
  /** Runtime upgrade WASM bytecode */
  newRuntimeBytecode?: Maybe<RuntimeWasmBytecode>
}

export type RuntimeWasmBytecode = BaseGraphQlObject & {
  __typename: 'RuntimeWasmBytecode'
  /** The bytecode itself */
  bytecode: Scalars['Bytes']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type RuntimeWasmBytecodeConnection = {
  __typename: 'RuntimeWasmBytecodeConnection'
  edges: Array<RuntimeWasmBytecodeEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type RuntimeWasmBytecodeCreateInput = {
  bytecode: Scalars['String']
}

export type RuntimeWasmBytecodeEdge = {
  __typename: 'RuntimeWasmBytecodeEdge'
  cursor: Scalars['String']
  node: RuntimeWasmBytecode
}

export enum RuntimeWasmBytecodeOrderByInput {
  BytecodeAsc = 'bytecode_ASC',
  BytecodeDesc = 'bytecode_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type RuntimeWasmBytecodeUpdateInput = {
  bytecode?: InputMaybe<Scalars['String']>
}

export type RuntimeWasmBytecodeWhereInput = {
  AND?: InputMaybe<Array<RuntimeWasmBytecodeWhereInput>>
  NOT?: InputMaybe<Array<RuntimeWasmBytecodeWhereInput>>
  OR?: InputMaybe<Array<RuntimeWasmBytecodeWhereInput>>
  bytecode_eq?: InputMaybe<Scalars['Bytes']>
  bytecode_in?: InputMaybe<Array<Scalars['Bytes']>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type RuntimeWasmBytecodeWhereUniqueInput = {
  id: Scalars['ID']
}

export type SearchFtsOutput = {
  __typename: 'SearchFTSOutput'
  highlight: Scalars['String']
  isTypeOf: Scalars['String']
  item: SearchSearchResult
  rank: Scalars['Float']
}

export type SearchSearchResult = Channel | Video

export type SetCouncilBudgetIncrementProposalDetails = {
  __typename: 'SetCouncilBudgetIncrementProposalDetails'
  /** New (proposed) amount the council budget should be increased by per each budget period */
  newAmount: Scalars['Float']
}

export type SetCouncilorRewardProposalDetails = {
  __typename: 'SetCouncilorRewardProposalDetails'
  /** New (proposed) council members' reward per block */
  newRewardPerBlock: Scalars['Float']
}

export type SetInitialInvitationBalanceProposalDetails = {
  __typename: 'SetInitialInvitationBalanceProposalDetails'
  /** The new (proposed) initial balance credited to controller account of an invitee (locked for transaction fee payments only) */
  newInitialInvitationBalance: Scalars['Float']
}

export type SetInitialInvitationCountProposalDetails = {
  __typename: 'SetInitialInvitationCountProposalDetails'
  /** The new (proposed) initial invitations count for paid memberships */
  newInitialInvitationsCount: Scalars['Int']
}

export type SetMaxValidatorCountProposalDetails = {
  __typename: 'SetMaxValidatorCountProposalDetails'
  /** The new (propsed) max. number of active validators */
  newMaxValidatorCount: Scalars['Int']
}

export type SetMembershipLeadInvitationQuotaProposalDetails = {
  __typename: 'SetMembershipLeadInvitationQuotaProposalDetails'
  /** The new (proposed) membership working group lead invitation quota */
  newLeadInvitationQuota: Scalars['Int']
}

export type SetMembershipPriceProposalDetails = {
  __typename: 'SetMembershipPriceProposalDetails'
  /** New (proposed) membership price */
  newPrice: Scalars['Float']
}

export type SetReferralCutProposalDetails = {
  __typename: 'SetReferralCutProposalDetails'
  /** The new (proposed) percentage of tokens diverted to referrer (from referred member's membership price). */
  newReferralCut: Scalars['Int']
}

export type SetWorkingGroupLeadRewardProposalDetails = {
  __typename: 'SetWorkingGroupLeadRewardProposalDetails'
  /** The lead that should be affected */
  lead?: Maybe<Worker>
  /** Lead's new (proposed) reward per block */
  newRewardPerBlock: Scalars['Float']
}

export type SignalProposalDetails = {
  __typename: 'SignalProposalDetails'
  /** Signal proposal content */
  text: Scalars['String']
}

export type SlashWorkingGroupLeadProposalDetails = {
  __typename: 'SlashWorkingGroupLeadProposalDetails'
  /** Amount to slash the stake by */
  amount: Scalars['Float']
  /** The lead that should be affected */
  lead?: Maybe<Worker>
}

export type StakeDecreasedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'StakeDecreasedEvent'
    /** The amount of JOY the stake was decreased by */
    amount: Scalars['BigInt']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    worker: Worker
    workerId: Scalars['String']
  }

export type StakeDecreasedEventConnection = {
  __typename: 'StakeDecreasedEventConnection'
  edges: Array<StakeDecreasedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type StakeDecreasedEventCreateInput = {
  amount: Scalars['String']
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  worker: Scalars['ID']
}

export type StakeDecreasedEventEdge = {
  __typename: 'StakeDecreasedEventEdge'
  cursor: Scalars['String']
  node: StakeDecreasedEvent
}

export enum StakeDecreasedEventOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type StakeDecreasedEventUpdateInput = {
  amount?: InputMaybe<Scalars['String']>
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  worker?: InputMaybe<Scalars['ID']>
}

export type StakeDecreasedEventWhereInput = {
  AND?: InputMaybe<Array<StakeDecreasedEventWhereInput>>
  NOT?: InputMaybe<Array<StakeDecreasedEventWhereInput>>
  OR?: InputMaybe<Array<StakeDecreasedEventWhereInput>>
  amount_eq?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  worker?: InputMaybe<WorkerWhereInput>
}

export type StakeDecreasedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type StakeIncreasedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'StakeIncreasedEvent'
    /** The amount of JOY the stake was increased by */
    amount: Scalars['BigInt']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    worker: Worker
    workerId: Scalars['String']
  }

export type StakeIncreasedEventConnection = {
  __typename: 'StakeIncreasedEventConnection'
  edges: Array<StakeIncreasedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type StakeIncreasedEventCreateInput = {
  amount: Scalars['String']
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  worker: Scalars['ID']
}

export type StakeIncreasedEventEdge = {
  __typename: 'StakeIncreasedEventEdge'
  cursor: Scalars['String']
  node: StakeIncreasedEvent
}

export enum StakeIncreasedEventOrderByInput {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type StakeIncreasedEventUpdateInput = {
  amount?: InputMaybe<Scalars['String']>
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  worker?: InputMaybe<Scalars['ID']>
}

export type StakeIncreasedEventWhereInput = {
  AND?: InputMaybe<Array<StakeIncreasedEventWhereInput>>
  NOT?: InputMaybe<Array<StakeIncreasedEventWhereInput>>
  OR?: InputMaybe<Array<StakeIncreasedEventWhereInput>>
  amount_eq?: InputMaybe<Scalars['BigInt']>
  amount_gt?: InputMaybe<Scalars['BigInt']>
  amount_gte?: InputMaybe<Scalars['BigInt']>
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>
  amount_lt?: InputMaybe<Scalars['BigInt']>
  amount_lte?: InputMaybe<Scalars['BigInt']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  worker?: InputMaybe<WorkerWhereInput>
}

export type StakeIncreasedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type StakeReleasedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'StakeReleasedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Account used to stake the value. */
    stakingAccount: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type StakeReleasedEventConnection = {
  __typename: 'StakeReleasedEventConnection'
  edges: Array<StakeReleasedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type StakeReleasedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  stakingAccount: Scalars['String']
}

export type StakeReleasedEventEdge = {
  __typename: 'StakeReleasedEventEdge'
  cursor: Scalars['String']
  node: StakeReleasedEvent
}

export enum StakeReleasedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  StakingAccountAsc = 'stakingAccount_ASC',
  StakingAccountDesc = 'stakingAccount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type StakeReleasedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  stakingAccount?: InputMaybe<Scalars['String']>
}

export type StakeReleasedEventWhereInput = {
  AND?: InputMaybe<Array<StakeReleasedEventWhereInput>>
  NOT?: InputMaybe<Array<StakeReleasedEventWhereInput>>
  OR?: InputMaybe<Array<StakeReleasedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  stakingAccount_contains?: InputMaybe<Scalars['String']>
  stakingAccount_endsWith?: InputMaybe<Scalars['String']>
  stakingAccount_eq?: InputMaybe<Scalars['String']>
  stakingAccount_in?: InputMaybe<Array<Scalars['String']>>
  stakingAccount_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type StakeReleasedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type StakeSlashedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'StakeSlashedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Optional rationale */
    rationale?: Maybe<Scalars['String']>
    /** Balance that was requested to be slashed */
    requestedAmount: Scalars['BigInt']
    /** Balance that was actually slashed */
    slashedAmount: Scalars['BigInt']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    worker: Worker
    workerId: Scalars['String']
  }

export type StakeSlashedEventConnection = {
  __typename: 'StakeSlashedEventConnection'
  edges: Array<StakeSlashedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type StakeSlashedEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  rationale?: InputMaybe<Scalars['String']>
  requestedAmount: Scalars['String']
  slashedAmount: Scalars['String']
  worker: Scalars['ID']
}

export type StakeSlashedEventEdge = {
  __typename: 'StakeSlashedEventEdge'
  cursor: Scalars['String']
  node: StakeSlashedEvent
}

export enum StakeSlashedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  RequestedAmountAsc = 'requestedAmount_ASC',
  RequestedAmountDesc = 'requestedAmount_DESC',
  SlashedAmountAsc = 'slashedAmount_ASC',
  SlashedAmountDesc = 'slashedAmount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type StakeSlashedEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  rationale?: InputMaybe<Scalars['String']>
  requestedAmount?: InputMaybe<Scalars['String']>
  slashedAmount?: InputMaybe<Scalars['String']>
  worker?: InputMaybe<Scalars['ID']>
}

export type StakeSlashedEventWhereInput = {
  AND?: InputMaybe<Array<StakeSlashedEventWhereInput>>
  NOT?: InputMaybe<Array<StakeSlashedEventWhereInput>>
  OR?: InputMaybe<Array<StakeSlashedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  requestedAmount_eq?: InputMaybe<Scalars['BigInt']>
  requestedAmount_gt?: InputMaybe<Scalars['BigInt']>
  requestedAmount_gte?: InputMaybe<Scalars['BigInt']>
  requestedAmount_in?: InputMaybe<Array<Scalars['BigInt']>>
  requestedAmount_lt?: InputMaybe<Scalars['BigInt']>
  requestedAmount_lte?: InputMaybe<Scalars['BigInt']>
  slashedAmount_eq?: InputMaybe<Scalars['BigInt']>
  slashedAmount_gt?: InputMaybe<Scalars['BigInt']>
  slashedAmount_gte?: InputMaybe<Scalars['BigInt']>
  slashedAmount_in?: InputMaybe<Array<Scalars['BigInt']>>
  slashedAmount_lt?: InputMaybe<Scalars['BigInt']>
  slashedAmount_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  worker?: InputMaybe<WorkerWhereInput>
}

export type StakeSlashedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type StakingAccountAddedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'StakingAccountAddedEvent'
    /** New staking account in SS58 encoding. */
    account: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    member: Membership
    memberId: Scalars['String']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type StakingAccountAddedEventConnection = {
  __typename: 'StakingAccountAddedEventConnection'
  edges: Array<StakingAccountAddedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type StakingAccountAddedEventCreateInput = {
  account: Scalars['String']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  member: Scalars['ID']
  network: Network
}

export type StakingAccountAddedEventEdge = {
  __typename: 'StakingAccountAddedEventEdge'
  cursor: Scalars['String']
  node: StakingAccountAddedEvent
}

export enum StakingAccountAddedEventOrderByInput {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type StakingAccountAddedEventUpdateInput = {
  account?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  member?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
}

export type StakingAccountAddedEventWhereInput = {
  AND?: InputMaybe<Array<StakingAccountAddedEventWhereInput>>
  NOT?: InputMaybe<Array<StakingAccountAddedEventWhereInput>>
  OR?: InputMaybe<Array<StakingAccountAddedEventWhereInput>>
  account_contains?: InputMaybe<Scalars['String']>
  account_endsWith?: InputMaybe<Scalars['String']>
  account_eq?: InputMaybe<Scalars['String']>
  account_in?: InputMaybe<Array<Scalars['String']>>
  account_startsWith?: InputMaybe<Scalars['String']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  member?: InputMaybe<MembershipWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type StakingAccountAddedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type StakingAccountConfirmedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'StakingAccountConfirmedEvent'
    /** New staking account in SS58 encoding. */
    account: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    member: Membership
    memberId: Scalars['String']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type StakingAccountConfirmedEventConnection = {
  __typename: 'StakingAccountConfirmedEventConnection'
  edges: Array<StakingAccountConfirmedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type StakingAccountConfirmedEventCreateInput = {
  account: Scalars['String']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  member: Scalars['ID']
  network: Network
}

export type StakingAccountConfirmedEventEdge = {
  __typename: 'StakingAccountConfirmedEventEdge'
  cursor: Scalars['String']
  node: StakingAccountConfirmedEvent
}

export enum StakingAccountConfirmedEventOrderByInput {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type StakingAccountConfirmedEventUpdateInput = {
  account?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  member?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
}

export type StakingAccountConfirmedEventWhereInput = {
  AND?: InputMaybe<Array<StakingAccountConfirmedEventWhereInput>>
  NOT?: InputMaybe<Array<StakingAccountConfirmedEventWhereInput>>
  OR?: InputMaybe<Array<StakingAccountConfirmedEventWhereInput>>
  account_contains?: InputMaybe<Scalars['String']>
  account_endsWith?: InputMaybe<Scalars['String']>
  account_eq?: InputMaybe<Scalars['String']>
  account_in?: InputMaybe<Array<Scalars['String']>>
  account_startsWith?: InputMaybe<Scalars['String']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  member?: InputMaybe<MembershipWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type StakingAccountConfirmedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type StakingAccountRemovedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'StakingAccountRemovedEvent'
    /** New staking account in SS58 encoding. */
    account: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    member: Membership
    memberId: Scalars['String']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type StakingAccountRemovedEventConnection = {
  __typename: 'StakingAccountRemovedEventConnection'
  edges: Array<StakingAccountRemovedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type StakingAccountRemovedEventCreateInput = {
  account: Scalars['String']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  member: Scalars['ID']
  network: Network
}

export type StakingAccountRemovedEventEdge = {
  __typename: 'StakingAccountRemovedEventEdge'
  cursor: Scalars['String']
  node: StakingAccountRemovedEvent
}

export enum StakingAccountRemovedEventOrderByInput {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type StakingAccountRemovedEventUpdateInput = {
  account?: InputMaybe<Scalars['String']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  member?: InputMaybe<Scalars['ID']>
  network?: InputMaybe<Network>
}

export type StakingAccountRemovedEventWhereInput = {
  AND?: InputMaybe<Array<StakingAccountRemovedEventWhereInput>>
  NOT?: InputMaybe<Array<StakingAccountRemovedEventWhereInput>>
  OR?: InputMaybe<Array<StakingAccountRemovedEventWhereInput>>
  account_contains?: InputMaybe<Scalars['String']>
  account_endsWith?: InputMaybe<Scalars['String']>
  account_eq?: InputMaybe<Scalars['String']>
  account_in?: InputMaybe<Array<Scalars['String']>>
  account_startsWith?: InputMaybe<Scalars['String']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  member?: InputMaybe<MembershipWhereInput>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type StakingAccountRemovedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type StandardDeleteResponse = {
  __typename: 'StandardDeleteResponse'
  id: Scalars['ID']
}

export type StatusTextChangedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'StatusTextChangedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Original action metadata as hex string */
    metadata?: Maybe<Scalars['String']>
    /** Network the block was produced in */
    network: Network
    /** Event result depeding on the metadata action type */
    result: WorkingGroupMetadataActionResult
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    upcomingworkinggroupopeningcreatedInEvent?: Maybe<Array<UpcomingWorkingGroupOpening>>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    workinggroupmetadatasetInEvent?: Maybe<Array<WorkingGroupMetadata>>
  }

export type StatusTextChangedEventConnection = {
  __typename: 'StatusTextChangedEventConnection'
  edges: Array<StatusTextChangedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type StatusTextChangedEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  metadata?: InputMaybe<Scalars['String']>
  network: Network
  result: Scalars['JSONObject']
}

export type StatusTextChangedEventEdge = {
  __typename: 'StatusTextChangedEventEdge'
  cursor: Scalars['String']
  node: StatusTextChangedEvent
}

export enum StatusTextChangedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type StatusTextChangedEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  metadata?: InputMaybe<Scalars['String']>
  network?: InputMaybe<Network>
  result?: InputMaybe<Scalars['JSONObject']>
}

export type StatusTextChangedEventWhereInput = {
  AND?: InputMaybe<Array<StatusTextChangedEventWhereInput>>
  NOT?: InputMaybe<Array<StatusTextChangedEventWhereInput>>
  OR?: InputMaybe<Array<StatusTextChangedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  metadata_contains?: InputMaybe<Scalars['String']>
  metadata_endsWith?: InputMaybe<Scalars['String']>
  metadata_eq?: InputMaybe<Scalars['String']>
  metadata_in?: InputMaybe<Array<Scalars['String']>>
  metadata_startsWith?: InputMaybe<Scalars['String']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  result_json?: InputMaybe<Scalars['JSONObject']>
  upcomingworkinggroupopeningcreatedInEvent_every?: InputMaybe<UpcomingWorkingGroupOpeningWhereInput>
  upcomingworkinggroupopeningcreatedInEvent_none?: InputMaybe<UpcomingWorkingGroupOpeningWhereInput>
  upcomingworkinggroupopeningcreatedInEvent_some?: InputMaybe<UpcomingWorkingGroupOpeningWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  workinggroupmetadatasetInEvent_every?: InputMaybe<WorkingGroupMetadataWhereInput>
  workinggroupmetadatasetInEvent_none?: InputMaybe<WorkingGroupMetadataWhereInput>
  workinggroupmetadatasetInEvent_some?: InputMaybe<WorkingGroupMetadataWhereInput>
}

export type StatusTextChangedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type StorageBag = BaseGraphQlObject & {
  __typename: 'StorageBag'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  distributionBuckets: Array<DistributionBucket>
  id: Scalars['ID']
  objects: Array<StorageDataObject>
  /** Owner of the storage bag */
  owner: StorageBagOwner
  storageBuckets: Array<StorageBucket>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type StorageBagConnection = {
  __typename: 'StorageBagConnection'
  edges: Array<StorageBagEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type StorageBagCreateInput = {
  owner: Scalars['JSONObject']
}

export type StorageBagEdge = {
  __typename: 'StorageBagEdge'
  cursor: Scalars['String']
  node: StorageBag
}

export enum StorageBagOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type StorageBagOwner =
  | StorageBagOwnerChannel
  | StorageBagOwnerCouncil
  | StorageBagOwnerDao
  | StorageBagOwnerMember
  | StorageBagOwnerWorkingGroup

export type StorageBagOwnerChannel = {
  __typename: 'StorageBagOwnerChannel'
  channelId?: Maybe<Scalars['Int']>
}

export type StorageBagOwnerCouncil = {
  __typename: 'StorageBagOwnerCouncil'
  phantom?: Maybe<Scalars['Int']>
}

export type StorageBagOwnerDao = {
  __typename: 'StorageBagOwnerDAO'
  daoId?: Maybe<Scalars['Int']>
}

export type StorageBagOwnerMember = {
  __typename: 'StorageBagOwnerMember'
  memberId?: Maybe<Scalars['Int']>
}

export type StorageBagOwnerWorkingGroup = {
  __typename: 'StorageBagOwnerWorkingGroup'
  workingGroupId?: Maybe<Scalars['String']>
}

export type StorageBagUpdateInput = {
  owner?: InputMaybe<Scalars['JSONObject']>
}

export type StorageBagWhereInput = {
  AND?: InputMaybe<Array<StorageBagWhereInput>>
  NOT?: InputMaybe<Array<StorageBagWhereInput>>
  OR?: InputMaybe<Array<StorageBagWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  distributionBuckets_every?: InputMaybe<DistributionBucketWhereInput>
  distributionBuckets_none?: InputMaybe<DistributionBucketWhereInput>
  distributionBuckets_some?: InputMaybe<DistributionBucketWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  objects_every?: InputMaybe<StorageDataObjectWhereInput>
  objects_none?: InputMaybe<StorageDataObjectWhereInput>
  objects_some?: InputMaybe<StorageDataObjectWhereInput>
  owner_json?: InputMaybe<Scalars['JSONObject']>
  storageBuckets_every?: InputMaybe<StorageBucketWhereInput>
  storageBuckets_none?: InputMaybe<StorageBucketWhereInput>
  storageBuckets_some?: InputMaybe<StorageBucketWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type StorageBagWhereUniqueInput = {
  id: Scalars['ID']
}

export type StorageBucket = BaseGraphQlObject & {
  __typename: 'StorageBucket'
  /** Whether the bucket is accepting any new storage bags */
  acceptingNewBags: Scalars['Boolean']
  bags: Array<StorageBag>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  /** Bucket's data object count limit */
  dataObjectCountLimit: Scalars['BigInt']
  /** Number of assigned data objects */
  dataObjectsCount: Scalars['BigInt']
  /** Total size of assigned data objects */
  dataObjectsSize: Scalars['BigInt']
  /** Bucket's data object size limit in bytes */
  dataObjectsSizeLimit: Scalars['BigInt']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  operatorMetadata?: Maybe<StorageBucketOperatorMetadata>
  operatorMetadataId?: Maybe<Scalars['String']>
  /** Current bucket operator status */
  operatorStatus: StorageBucketOperatorStatus
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type StorageBucketConnection = {
  __typename: 'StorageBucketConnection'
  edges: Array<StorageBucketEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type StorageBucketCreateInput = {
  acceptingNewBags: Scalars['Boolean']
  dataObjectCountLimit: Scalars['String']
  dataObjectsCount: Scalars['String']
  dataObjectsSize: Scalars['String']
  dataObjectsSizeLimit: Scalars['String']
  operatorMetadata?: InputMaybe<Scalars['ID']>
  operatorStatus: Scalars['JSONObject']
}

export type StorageBucketEdge = {
  __typename: 'StorageBucketEdge'
  cursor: Scalars['String']
  node: StorageBucket
}

export type StorageBucketOperatorMetadata = BaseGraphQlObject & {
  __typename: 'StorageBucketOperatorMetadata'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** Additional information about the node/operator */
  extra?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** Root node endpoint */
  nodeEndpoint?: Maybe<Scalars['String']>
  nodeLocation?: Maybe<NodeLocationMetadata>
  nodeLocationId?: Maybe<Scalars['String']>
  storagebucketoperatorMetadata?: Maybe<Array<StorageBucket>>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type StorageBucketOperatorMetadataConnection = {
  __typename: 'StorageBucketOperatorMetadataConnection'
  edges: Array<StorageBucketOperatorMetadataEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type StorageBucketOperatorMetadataCreateInput = {
  extra?: InputMaybe<Scalars['String']>
  nodeEndpoint?: InputMaybe<Scalars['String']>
  nodeLocation?: InputMaybe<Scalars['ID']>
}

export type StorageBucketOperatorMetadataEdge = {
  __typename: 'StorageBucketOperatorMetadataEdge'
  cursor: Scalars['String']
  node: StorageBucketOperatorMetadata
}

export enum StorageBucketOperatorMetadataOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ExtraAsc = 'extra_ASC',
  ExtraDesc = 'extra_DESC',
  NodeEndpointAsc = 'nodeEndpoint_ASC',
  NodeEndpointDesc = 'nodeEndpoint_DESC',
  NodeLocationAsc = 'nodeLocation_ASC',
  NodeLocationDesc = 'nodeLocation_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type StorageBucketOperatorMetadataUpdateInput = {
  extra?: InputMaybe<Scalars['String']>
  nodeEndpoint?: InputMaybe<Scalars['String']>
  nodeLocation?: InputMaybe<Scalars['ID']>
}

export type StorageBucketOperatorMetadataWhereInput = {
  AND?: InputMaybe<Array<StorageBucketOperatorMetadataWhereInput>>
  NOT?: InputMaybe<Array<StorageBucketOperatorMetadataWhereInput>>
  OR?: InputMaybe<Array<StorageBucketOperatorMetadataWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  extra_contains?: InputMaybe<Scalars['String']>
  extra_endsWith?: InputMaybe<Scalars['String']>
  extra_eq?: InputMaybe<Scalars['String']>
  extra_in?: InputMaybe<Array<Scalars['String']>>
  extra_startsWith?: InputMaybe<Scalars['String']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  nodeEndpoint_contains?: InputMaybe<Scalars['String']>
  nodeEndpoint_endsWith?: InputMaybe<Scalars['String']>
  nodeEndpoint_eq?: InputMaybe<Scalars['String']>
  nodeEndpoint_in?: InputMaybe<Array<Scalars['String']>>
  nodeEndpoint_startsWith?: InputMaybe<Scalars['String']>
  nodeLocation?: InputMaybe<NodeLocationMetadataWhereInput>
  storagebucketoperatorMetadata_every?: InputMaybe<StorageBucketWhereInput>
  storagebucketoperatorMetadata_none?: InputMaybe<StorageBucketWhereInput>
  storagebucketoperatorMetadata_some?: InputMaybe<StorageBucketWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type StorageBucketOperatorMetadataWhereUniqueInput = {
  id: Scalars['ID']
}

export type StorageBucketOperatorStatus =
  | StorageBucketOperatorStatusActive
  | StorageBucketOperatorStatusInvited
  | StorageBucketOperatorStatusMissing

export type StorageBucketOperatorStatusActive = {
  __typename: 'StorageBucketOperatorStatusActive'
  transactorAccountId: Scalars['String']
  workerId: Scalars['Int']
}

export type StorageBucketOperatorStatusInvited = {
  __typename: 'StorageBucketOperatorStatusInvited'
  workerId: Scalars['Int']
}

export type StorageBucketOperatorStatusMissing = {
  __typename: 'StorageBucketOperatorStatusMissing'
  phantom?: Maybe<Scalars['Int']>
}

export enum StorageBucketOrderByInput {
  AcceptingNewBagsAsc = 'acceptingNewBags_ASC',
  AcceptingNewBagsDesc = 'acceptingNewBags_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DataObjectCountLimitAsc = 'dataObjectCountLimit_ASC',
  DataObjectCountLimitDesc = 'dataObjectCountLimit_DESC',
  DataObjectsCountAsc = 'dataObjectsCount_ASC',
  DataObjectsCountDesc = 'dataObjectsCount_DESC',
  DataObjectsSizeLimitAsc = 'dataObjectsSizeLimit_ASC',
  DataObjectsSizeLimitDesc = 'dataObjectsSizeLimit_DESC',
  DataObjectsSizeAsc = 'dataObjectsSize_ASC',
  DataObjectsSizeDesc = 'dataObjectsSize_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  OperatorMetadataAsc = 'operatorMetadata_ASC',
  OperatorMetadataDesc = 'operatorMetadata_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type StorageBucketUpdateInput = {
  acceptingNewBags?: InputMaybe<Scalars['Boolean']>
  dataObjectCountLimit?: InputMaybe<Scalars['String']>
  dataObjectsCount?: InputMaybe<Scalars['String']>
  dataObjectsSize?: InputMaybe<Scalars['String']>
  dataObjectsSizeLimit?: InputMaybe<Scalars['String']>
  operatorMetadata?: InputMaybe<Scalars['ID']>
  operatorStatus?: InputMaybe<Scalars['JSONObject']>
}

export type StorageBucketWhereInput = {
  AND?: InputMaybe<Array<StorageBucketWhereInput>>
  NOT?: InputMaybe<Array<StorageBucketWhereInput>>
  OR?: InputMaybe<Array<StorageBucketWhereInput>>
  acceptingNewBags_eq?: InputMaybe<Scalars['Boolean']>
  acceptingNewBags_in?: InputMaybe<Array<Scalars['Boolean']>>
  bags_every?: InputMaybe<StorageBagWhereInput>
  bags_none?: InputMaybe<StorageBagWhereInput>
  bags_some?: InputMaybe<StorageBagWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  dataObjectCountLimit_eq?: InputMaybe<Scalars['BigInt']>
  dataObjectCountLimit_gt?: InputMaybe<Scalars['BigInt']>
  dataObjectCountLimit_gte?: InputMaybe<Scalars['BigInt']>
  dataObjectCountLimit_in?: InputMaybe<Array<Scalars['BigInt']>>
  dataObjectCountLimit_lt?: InputMaybe<Scalars['BigInt']>
  dataObjectCountLimit_lte?: InputMaybe<Scalars['BigInt']>
  dataObjectsCount_eq?: InputMaybe<Scalars['BigInt']>
  dataObjectsCount_gt?: InputMaybe<Scalars['BigInt']>
  dataObjectsCount_gte?: InputMaybe<Scalars['BigInt']>
  dataObjectsCount_in?: InputMaybe<Array<Scalars['BigInt']>>
  dataObjectsCount_lt?: InputMaybe<Scalars['BigInt']>
  dataObjectsCount_lte?: InputMaybe<Scalars['BigInt']>
  dataObjectsSizeLimit_eq?: InputMaybe<Scalars['BigInt']>
  dataObjectsSizeLimit_gt?: InputMaybe<Scalars['BigInt']>
  dataObjectsSizeLimit_gte?: InputMaybe<Scalars['BigInt']>
  dataObjectsSizeLimit_in?: InputMaybe<Array<Scalars['BigInt']>>
  dataObjectsSizeLimit_lt?: InputMaybe<Scalars['BigInt']>
  dataObjectsSizeLimit_lte?: InputMaybe<Scalars['BigInt']>
  dataObjectsSize_eq?: InputMaybe<Scalars['BigInt']>
  dataObjectsSize_gt?: InputMaybe<Scalars['BigInt']>
  dataObjectsSize_gte?: InputMaybe<Scalars['BigInt']>
  dataObjectsSize_in?: InputMaybe<Array<Scalars['BigInt']>>
  dataObjectsSize_lt?: InputMaybe<Scalars['BigInt']>
  dataObjectsSize_lte?: InputMaybe<Scalars['BigInt']>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  operatorMetadata?: InputMaybe<StorageBucketOperatorMetadataWhereInput>
  operatorStatus_json?: InputMaybe<Scalars['JSONObject']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type StorageBucketWhereUniqueInput = {
  id: Scalars['ID']
}

export type StorageDataObject = BaseGraphQlObject & {
  __typename: 'StorageDataObject'
  channelavatarPhoto?: Maybe<Array<Channel>>
  channelcoverPhoto?: Maybe<Array<Channel>>
  channelpayoutsupdatedeventpayloadDataObject?: Maybe<Array<ChannelPayoutsUpdatedEvent>>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** IPFS content hash */
  ipfsHash: Scalars['String']
  /** Whether the data object was uploaded and accepted by the storage provider */
  isAccepted: Scalars['Boolean']
  /** Data object size in bytes */
  size: Scalars['BigInt']
  /** State Bloat Bond for removing the data object */
  stateBloatBond: Scalars['BigInt']
  storageBag: StorageBag
  storageBagId: Scalars['String']
  /** The type of the asset that the data object represents (if known) */
  type: DataObjectType
  /** If the object is no longer used as an asset - the time at which it was unset (if known) */
  unsetAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  videoMedia?: Maybe<Video>
  videoSubtitle?: Maybe<VideoSubtitle>
  videoThumbnail?: Maybe<Video>
}

export type StorageDataObjectConnection = {
  __typename: 'StorageDataObjectConnection'
  edges: Array<StorageDataObjectEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type StorageDataObjectCreateInput = {
  ipfsHash: Scalars['String']
  isAccepted: Scalars['Boolean']
  size: Scalars['String']
  stateBloatBond: Scalars['String']
  storageBag: Scalars['ID']
  type: Scalars['JSONObject']
  unsetAt?: InputMaybe<Scalars['DateTime']>
}

export type StorageDataObjectEdge = {
  __typename: 'StorageDataObjectEdge'
  cursor: Scalars['String']
  node: StorageDataObject
}

export enum StorageDataObjectOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IpfsHashAsc = 'ipfsHash_ASC',
  IpfsHashDesc = 'ipfsHash_DESC',
  IsAcceptedAsc = 'isAccepted_ASC',
  IsAcceptedDesc = 'isAccepted_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  StateBloatBondAsc = 'stateBloatBond_ASC',
  StateBloatBondDesc = 'stateBloatBond_DESC',
  StorageBagAsc = 'storageBag_ASC',
  StorageBagDesc = 'storageBag_DESC',
  UnsetAtAsc = 'unsetAt_ASC',
  UnsetAtDesc = 'unsetAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type StorageDataObjectUpdateInput = {
  ipfsHash?: InputMaybe<Scalars['String']>
  isAccepted?: InputMaybe<Scalars['Boolean']>
  size?: InputMaybe<Scalars['String']>
  stateBloatBond?: InputMaybe<Scalars['String']>
  storageBag?: InputMaybe<Scalars['ID']>
  type?: InputMaybe<Scalars['JSONObject']>
  unsetAt?: InputMaybe<Scalars['DateTime']>
}

export type StorageDataObjectWhereInput = {
  AND?: InputMaybe<Array<StorageDataObjectWhereInput>>
  NOT?: InputMaybe<Array<StorageDataObjectWhereInput>>
  OR?: InputMaybe<Array<StorageDataObjectWhereInput>>
  channelavatarPhoto_every?: InputMaybe<ChannelWhereInput>
  channelavatarPhoto_none?: InputMaybe<ChannelWhereInput>
  channelavatarPhoto_some?: InputMaybe<ChannelWhereInput>
  channelcoverPhoto_every?: InputMaybe<ChannelWhereInput>
  channelcoverPhoto_none?: InputMaybe<ChannelWhereInput>
  channelcoverPhoto_some?: InputMaybe<ChannelWhereInput>
  channelpayoutsupdatedeventpayloadDataObject_every?: InputMaybe<ChannelPayoutsUpdatedEventWhereInput>
  channelpayoutsupdatedeventpayloadDataObject_none?: InputMaybe<ChannelPayoutsUpdatedEventWhereInput>
  channelpayoutsupdatedeventpayloadDataObject_some?: InputMaybe<ChannelPayoutsUpdatedEventWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  ipfsHash_contains?: InputMaybe<Scalars['String']>
  ipfsHash_endsWith?: InputMaybe<Scalars['String']>
  ipfsHash_eq?: InputMaybe<Scalars['String']>
  ipfsHash_in?: InputMaybe<Array<Scalars['String']>>
  ipfsHash_startsWith?: InputMaybe<Scalars['String']>
  isAccepted_eq?: InputMaybe<Scalars['Boolean']>
  isAccepted_in?: InputMaybe<Array<Scalars['Boolean']>>
  size_eq?: InputMaybe<Scalars['BigInt']>
  size_gt?: InputMaybe<Scalars['BigInt']>
  size_gte?: InputMaybe<Scalars['BigInt']>
  size_in?: InputMaybe<Array<Scalars['BigInt']>>
  size_lt?: InputMaybe<Scalars['BigInt']>
  size_lte?: InputMaybe<Scalars['BigInt']>
  stateBloatBond_eq?: InputMaybe<Scalars['BigInt']>
  stateBloatBond_gt?: InputMaybe<Scalars['BigInt']>
  stateBloatBond_gte?: InputMaybe<Scalars['BigInt']>
  stateBloatBond_in?: InputMaybe<Array<Scalars['BigInt']>>
  stateBloatBond_lt?: InputMaybe<Scalars['BigInt']>
  stateBloatBond_lte?: InputMaybe<Scalars['BigInt']>
  storageBag?: InputMaybe<StorageBagWhereInput>
  type_json?: InputMaybe<Scalars['JSONObject']>
  unsetAt_eq?: InputMaybe<Scalars['DateTime']>
  unsetAt_gt?: InputMaybe<Scalars['DateTime']>
  unsetAt_gte?: InputMaybe<Scalars['DateTime']>
  unsetAt_lt?: InputMaybe<Scalars['DateTime']>
  unsetAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  videoMedia?: InputMaybe<VideoWhereInput>
  videoSubtitle?: InputMaybe<VideoSubtitleWhereInput>
  videoThumbnail?: InputMaybe<VideoWhereInput>
}

export type StorageDataObjectWhereUniqueInput = {
  id: Scalars['ID']
}

export type Subscription = {
  __typename: 'Subscription'
  stateSubscription: ProcessorState
}

export type TerminateWorkingGroupLeadProposalDetails = {
  __typename: 'TerminateWorkingGroupLeadProposalDetails'
  /** Lead that's supposed to be terminated */
  lead?: Maybe<Worker>
  /** Optionally - the amount to slash the lead's stake by */
  slashingAmount?: Maybe<Scalars['Float']>
}

export type TerminatedLeaderEvent = BaseGraphQlObject &
  Event & {
    __typename: 'TerminatedLeaderEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Slash amount (if any) */
    penalty?: Maybe<Scalars['BigInt']>
    /** Optional rationale */
    rationale?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    worker: Worker
    workerId: Scalars['String']
  }

export type TerminatedLeaderEventConnection = {
  __typename: 'TerminatedLeaderEventConnection'
  edges: Array<TerminatedLeaderEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type TerminatedLeaderEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  penalty?: InputMaybe<Scalars['String']>
  rationale?: InputMaybe<Scalars['String']>
  worker: Scalars['ID']
}

export type TerminatedLeaderEventEdge = {
  __typename: 'TerminatedLeaderEventEdge'
  cursor: Scalars['String']
  node: TerminatedLeaderEvent
}

export enum TerminatedLeaderEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PenaltyAsc = 'penalty_ASC',
  PenaltyDesc = 'penalty_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type TerminatedLeaderEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  penalty?: InputMaybe<Scalars['String']>
  rationale?: InputMaybe<Scalars['String']>
  worker?: InputMaybe<Scalars['ID']>
}

export type TerminatedLeaderEventWhereInput = {
  AND?: InputMaybe<Array<TerminatedLeaderEventWhereInput>>
  NOT?: InputMaybe<Array<TerminatedLeaderEventWhereInput>>
  OR?: InputMaybe<Array<TerminatedLeaderEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  penalty_eq?: InputMaybe<Scalars['BigInt']>
  penalty_gt?: InputMaybe<Scalars['BigInt']>
  penalty_gte?: InputMaybe<Scalars['BigInt']>
  penalty_in?: InputMaybe<Array<Scalars['BigInt']>>
  penalty_lt?: InputMaybe<Scalars['BigInt']>
  penalty_lte?: InputMaybe<Scalars['BigInt']>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  worker?: InputMaybe<WorkerWhereInput>
}

export type TerminatedLeaderEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type TerminatedWorkerEvent = BaseGraphQlObject &
  Event & {
    __typename: 'TerminatedWorkerEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Slash amount (if any) */
    penalty?: Maybe<Scalars['BigInt']>
    /** Optional rationale */
    rationale?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    worker: Worker
    workerId: Scalars['String']
  }

export type TerminatedWorkerEventConnection = {
  __typename: 'TerminatedWorkerEventConnection'
  edges: Array<TerminatedWorkerEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type TerminatedWorkerEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  penalty?: InputMaybe<Scalars['String']>
  rationale?: InputMaybe<Scalars['String']>
  worker: Scalars['ID']
}

export type TerminatedWorkerEventEdge = {
  __typename: 'TerminatedWorkerEventEdge'
  cursor: Scalars['String']
  node: TerminatedWorkerEvent
}

export enum TerminatedWorkerEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PenaltyAsc = 'penalty_ASC',
  PenaltyDesc = 'penalty_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type TerminatedWorkerEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  penalty?: InputMaybe<Scalars['String']>
  rationale?: InputMaybe<Scalars['String']>
  worker?: InputMaybe<Scalars['ID']>
}

export type TerminatedWorkerEventWhereInput = {
  AND?: InputMaybe<Array<TerminatedWorkerEventWhereInput>>
  NOT?: InputMaybe<Array<TerminatedWorkerEventWhereInput>>
  OR?: InputMaybe<Array<TerminatedWorkerEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  penalty_eq?: InputMaybe<Scalars['BigInt']>
  penalty_gt?: InputMaybe<Scalars['BigInt']>
  penalty_gte?: InputMaybe<Scalars['BigInt']>
  penalty_in?: InputMaybe<Array<Scalars['BigInt']>>
  penalty_lt?: InputMaybe<Scalars['BigInt']>
  penalty_lte?: InputMaybe<Scalars['BigInt']>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  worker?: InputMaybe<WorkerWhereInput>
}

export type TerminatedWorkerEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ThreadCreatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ThreadCreatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Thread's original text */
    text: Scalars['String']
    thread: ForumThread
    threadId: Scalars['String']
    /** Thread's original title */
    title: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ThreadCreatedEventConnection = {
  __typename: 'ThreadCreatedEventConnection'
  edges: Array<ThreadCreatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ThreadCreatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  text: Scalars['String']
  thread: Scalars['ID']
  title: Scalars['String']
}

export type ThreadCreatedEventEdge = {
  __typename: 'ThreadCreatedEventEdge'
  cursor: Scalars['String']
  node: ThreadCreatedEvent
}

export enum ThreadCreatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  ThreadAsc = 'thread_ASC',
  ThreadDesc = 'thread_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ThreadCreatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  text?: InputMaybe<Scalars['String']>
  thread?: InputMaybe<Scalars['ID']>
  title?: InputMaybe<Scalars['String']>
}

export type ThreadCreatedEventWhereInput = {
  AND?: InputMaybe<Array<ThreadCreatedEventWhereInput>>
  NOT?: InputMaybe<Array<ThreadCreatedEventWhereInput>>
  OR?: InputMaybe<Array<ThreadCreatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  text_contains?: InputMaybe<Scalars['String']>
  text_endsWith?: InputMaybe<Scalars['String']>
  text_eq?: InputMaybe<Scalars['String']>
  text_in?: InputMaybe<Array<Scalars['String']>>
  text_startsWith?: InputMaybe<Scalars['String']>
  thread?: InputMaybe<ForumThreadWhereInput>
  title_contains?: InputMaybe<Scalars['String']>
  title_endsWith?: InputMaybe<Scalars['String']>
  title_eq?: InputMaybe<Scalars['String']>
  title_in?: InputMaybe<Array<Scalars['String']>>
  title_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ThreadCreatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ThreadDeletedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ThreadDeletedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    thread: ForumThread
    threadId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ThreadDeletedEventConnection = {
  __typename: 'ThreadDeletedEventConnection'
  edges: Array<ThreadDeletedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ThreadDeletedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  thread: Scalars['ID']
}

export type ThreadDeletedEventEdge = {
  __typename: 'ThreadDeletedEventEdge'
  cursor: Scalars['String']
  node: ThreadDeletedEvent
}

export enum ThreadDeletedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ThreadAsc = 'thread_ASC',
  ThreadDesc = 'thread_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ThreadDeletedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  thread?: InputMaybe<Scalars['ID']>
}

export type ThreadDeletedEventWhereInput = {
  AND?: InputMaybe<Array<ThreadDeletedEventWhereInput>>
  NOT?: InputMaybe<Array<ThreadDeletedEventWhereInput>>
  OR?: InputMaybe<Array<ThreadDeletedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  thread?: InputMaybe<ForumThreadWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ThreadDeletedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ThreadMetadataUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ThreadMetadataUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** New title of the thread */
    newTitle?: Maybe<Scalars['String']>
    thread: ForumThread
    threadId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ThreadMetadataUpdatedEventConnection = {
  __typename: 'ThreadMetadataUpdatedEventConnection'
  edges: Array<ThreadMetadataUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ThreadMetadataUpdatedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newTitle?: InputMaybe<Scalars['String']>
  thread: Scalars['ID']
}

export type ThreadMetadataUpdatedEventEdge = {
  __typename: 'ThreadMetadataUpdatedEventEdge'
  cursor: Scalars['String']
  node: ThreadMetadataUpdatedEvent
}

export enum ThreadMetadataUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewTitleAsc = 'newTitle_ASC',
  NewTitleDesc = 'newTitle_DESC',
  ThreadAsc = 'thread_ASC',
  ThreadDesc = 'thread_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ThreadMetadataUpdatedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newTitle?: InputMaybe<Scalars['String']>
  thread?: InputMaybe<Scalars['ID']>
}

export type ThreadMetadataUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<ThreadMetadataUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<ThreadMetadataUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<ThreadMetadataUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newTitle_contains?: InputMaybe<Scalars['String']>
  newTitle_endsWith?: InputMaybe<Scalars['String']>
  newTitle_eq?: InputMaybe<Scalars['String']>
  newTitle_in?: InputMaybe<Array<Scalars['String']>>
  newTitle_startsWith?: InputMaybe<Scalars['String']>
  thread?: InputMaybe<ForumThreadWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ThreadMetadataUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ThreadModeratedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ThreadModeratedEvent'
    actor: Worker
    actorId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Rationale behind the moderation */
    rationale: Scalars['String']
    thread: ForumThread
    threadId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ThreadModeratedEventConnection = {
  __typename: 'ThreadModeratedEventConnection'
  edges: Array<ThreadModeratedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ThreadModeratedEventCreateInput = {
  actor: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  rationale: Scalars['String']
  thread: Scalars['ID']
}

export type ThreadModeratedEventEdge = {
  __typename: 'ThreadModeratedEventEdge'
  cursor: Scalars['String']
  node: ThreadModeratedEvent
}

export enum ThreadModeratedEventOrderByInput {
  ActorAsc = 'actor_ASC',
  ActorDesc = 'actor_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  ThreadAsc = 'thread_ASC',
  ThreadDesc = 'thread_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ThreadModeratedEventUpdateInput = {
  actor?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  rationale?: InputMaybe<Scalars['String']>
  thread?: InputMaybe<Scalars['ID']>
}

export type ThreadModeratedEventWhereInput = {
  AND?: InputMaybe<Array<ThreadModeratedEventWhereInput>>
  NOT?: InputMaybe<Array<ThreadModeratedEventWhereInput>>
  OR?: InputMaybe<Array<ThreadModeratedEventWhereInput>>
  actor?: InputMaybe<WorkerWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  thread?: InputMaybe<ForumThreadWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ThreadModeratedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ThreadMovedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'ThreadMovedEvent'
    actor: Worker
    actorId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    newCategory: ForumCategory
    newCategoryId: Scalars['String']
    oldCategory: ForumCategory
    oldCategoryId: Scalars['String']
    thread: ForumThread
    threadId: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type ThreadMovedEventConnection = {
  __typename: 'ThreadMovedEventConnection'
  edges: Array<ThreadMovedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type ThreadMovedEventCreateInput = {
  actor: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newCategory: Scalars['ID']
  oldCategory: Scalars['ID']
  thread: Scalars['ID']
}

export type ThreadMovedEventEdge = {
  __typename: 'ThreadMovedEventEdge'
  cursor: Scalars['String']
  node: ThreadMovedEvent
}

export enum ThreadMovedEventOrderByInput {
  ActorAsc = 'actor_ASC',
  ActorDesc = 'actor_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewCategoryAsc = 'newCategory_ASC',
  NewCategoryDesc = 'newCategory_DESC',
  OldCategoryAsc = 'oldCategory_ASC',
  OldCategoryDesc = 'oldCategory_DESC',
  ThreadAsc = 'thread_ASC',
  ThreadDesc = 'thread_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ThreadMovedEventUpdateInput = {
  actor?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newCategory?: InputMaybe<Scalars['ID']>
  oldCategory?: InputMaybe<Scalars['ID']>
  thread?: InputMaybe<Scalars['ID']>
}

export type ThreadMovedEventWhereInput = {
  AND?: InputMaybe<Array<ThreadMovedEventWhereInput>>
  NOT?: InputMaybe<Array<ThreadMovedEventWhereInput>>
  OR?: InputMaybe<Array<ThreadMovedEventWhereInput>>
  actor?: InputMaybe<WorkerWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newCategory?: InputMaybe<ForumCategoryWhereInput>
  oldCategory?: InputMaybe<ForumCategoryWhereInput>
  thread?: InputMaybe<ForumThreadWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type ThreadMovedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type ThreadStatus = ThreadStatusActive | ThreadStatusLocked | ThreadStatusModerated | ThreadStatusRemoved

export type ThreadStatusActive = {
  __typename: 'ThreadStatusActive'
  phantom?: Maybe<Scalars['Int']>
}

export type ThreadStatusLocked = {
  __typename: 'ThreadStatusLocked'
  /** Event the thread was deleted (locked) in */
  threadDeletedEvent?: Maybe<ThreadDeletedEvent>
}

export type ThreadStatusModerated = {
  __typename: 'ThreadStatusModerated'
  /** Event the thread was moderated in */
  threadModeratedEvent?: Maybe<ThreadModeratedEvent>
}

export type ThreadStatusRemoved = {
  __typename: 'ThreadStatusRemoved'
  /** Event the thread was removed in */
  threadDeletedEvent?: Maybe<ThreadDeletedEvent>
}

export type ThreadsByTitleFtsOutput = {
  __typename: 'ThreadsByTitleFTSOutput'
  highlight: Scalars['String']
  isTypeOf: Scalars['String']
  item: ThreadsByTitleSearchResult
  rank: Scalars['Float']
}

export type ThreadsByTitleSearchResult = ForumThread

export type TransactionalStatus =
  | TransactionalStatusBuyNow
  | TransactionalStatusIdle
  | TransactionalStatusInitiatedOfferToMember

export type TransactionalStatusBuyNow = {
  __typename: 'TransactionalStatusBuyNow'
  price: Scalars['Float']
}

export type TransactionalStatusIdle = {
  __typename: 'TransactionalStatusIdle'
  /** Type needs to have at least one non-relation entity. This value is not used. */
  dummy?: Maybe<Scalars['Int']>
}

export type TransactionalStatusInitiatedOfferToMember = {
  __typename: 'TransactionalStatusInitiatedOfferToMember'
  /** Member identifier */
  memberId: Scalars['Int']
  /** Whether member should pay to accept offer (optional) */
  price?: Maybe<Scalars['Float']>
}

export type TransactionalStatusUpdate = BaseGraphQlObject & {
  __typename: 'TransactionalStatusUpdate'
  /** Block number at which change happened */
  changedAt: Scalars['Int']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  nft: OwnedNft
  nftId: Scalars['String']
  /** NFT's non-auction transactional status (if any) */
  transactionalStatus?: Maybe<TransactionalStatus>
  transactionalStatusAuction?: Maybe<Auction>
  transactionalStatusAuctionId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type TransactionalStatusUpdateConnection = {
  __typename: 'TransactionalStatusUpdateConnection'
  edges: Array<TransactionalStatusUpdateEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type TransactionalStatusUpdateCreateInput = {
  changedAt: Scalars['Float']
  nft: Scalars['ID']
  transactionalStatus: Scalars['JSONObject']
  transactionalStatusAuction?: InputMaybe<Scalars['ID']>
}

export type TransactionalStatusUpdateEdge = {
  __typename: 'TransactionalStatusUpdateEdge'
  cursor: Scalars['String']
  node: TransactionalStatusUpdate
}

export enum TransactionalStatusUpdateOrderByInput {
  ChangedAtAsc = 'changedAt_ASC',
  ChangedAtDesc = 'changedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NftAsc = 'nft_ASC',
  NftDesc = 'nft_DESC',
  TransactionalStatusAuctionAsc = 'transactionalStatusAuction_ASC',
  TransactionalStatusAuctionDesc = 'transactionalStatusAuction_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type TransactionalStatusUpdateUpdateInput = {
  changedAt?: InputMaybe<Scalars['Float']>
  nft?: InputMaybe<Scalars['ID']>
  transactionalStatus?: InputMaybe<Scalars['JSONObject']>
  transactionalStatusAuction?: InputMaybe<Scalars['ID']>
}

export type TransactionalStatusUpdateWhereInput = {
  AND?: InputMaybe<Array<TransactionalStatusUpdateWhereInput>>
  NOT?: InputMaybe<Array<TransactionalStatusUpdateWhereInput>>
  OR?: InputMaybe<Array<TransactionalStatusUpdateWhereInput>>
  changedAt_eq?: InputMaybe<Scalars['Int']>
  changedAt_gt?: InputMaybe<Scalars['Int']>
  changedAt_gte?: InputMaybe<Scalars['Int']>
  changedAt_in?: InputMaybe<Array<Scalars['Int']>>
  changedAt_lt?: InputMaybe<Scalars['Int']>
  changedAt_lte?: InputMaybe<Scalars['Int']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  nft?: InputMaybe<OwnedNftWhereInput>
  transactionalStatusAuction?: InputMaybe<AuctionWhereInput>
  transactionalStatus_json?: InputMaybe<Scalars['JSONObject']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type TransactionalStatusUpdateWhereUniqueInput = {
  id: Scalars['ID']
}

export type UpcomingOpeningAdded = {
  __typename: 'UpcomingOpeningAdded'
  upcomingOpeningId: Scalars['String']
}

export type UpcomingOpeningRemoved = {
  __typename: 'UpcomingOpeningRemoved'
  upcomingOpeningId: Scalars['String']
}

export type UpcomingWorkingGroupOpening = BaseGraphQlObject & {
  __typename: 'UpcomingWorkingGroupOpening'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  createdInEvent: StatusTextChangedEvent
  createdInEventId: Scalars['String']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** Expected opening start time */
  expectedStart?: Maybe<Scalars['DateTime']>
  group: WorkingGroup
  groupId: Scalars['String']
  id: Scalars['ID']
  metadata: WorkingGroupOpeningMetadata
  metadataId: Scalars['String']
  /** Expected reward per block */
  rewardPerBlock?: Maybe<Scalars['BigInt']>
  /** Expected min. application/role stake amount */
  stakeAmount?: Maybe<Scalars['BigInt']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type UpcomingWorkingGroupOpeningConnection = {
  __typename: 'UpcomingWorkingGroupOpeningConnection'
  edges: Array<UpcomingWorkingGroupOpeningEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type UpcomingWorkingGroupOpeningCreateInput = {
  createdInEvent: Scalars['ID']
  expectedStart?: InputMaybe<Scalars['DateTime']>
  group: Scalars['ID']
  metadata: Scalars['ID']
  rewardPerBlock?: InputMaybe<Scalars['String']>
  stakeAmount?: InputMaybe<Scalars['String']>
}

export type UpcomingWorkingGroupOpeningEdge = {
  __typename: 'UpcomingWorkingGroupOpeningEdge'
  cursor: Scalars['String']
  node: UpcomingWorkingGroupOpening
}

export enum UpcomingWorkingGroupOpeningOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CreatedInEventAsc = 'createdInEvent_ASC',
  CreatedInEventDesc = 'createdInEvent_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ExpectedStartAsc = 'expectedStart_ASC',
  ExpectedStartDesc = 'expectedStart_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  RewardPerBlockAsc = 'rewardPerBlock_ASC',
  RewardPerBlockDesc = 'rewardPerBlock_DESC',
  StakeAmountAsc = 'stakeAmount_ASC',
  StakeAmountDesc = 'stakeAmount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type UpcomingWorkingGroupOpeningUpdateInput = {
  createdInEvent?: InputMaybe<Scalars['ID']>
  expectedStart?: InputMaybe<Scalars['DateTime']>
  group?: InputMaybe<Scalars['ID']>
  metadata?: InputMaybe<Scalars['ID']>
  rewardPerBlock?: InputMaybe<Scalars['String']>
  stakeAmount?: InputMaybe<Scalars['String']>
}

export type UpcomingWorkingGroupOpeningWhereInput = {
  AND?: InputMaybe<Array<UpcomingWorkingGroupOpeningWhereInput>>
  NOT?: InputMaybe<Array<UpcomingWorkingGroupOpeningWhereInput>>
  OR?: InputMaybe<Array<UpcomingWorkingGroupOpeningWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  createdInEvent?: InputMaybe<StatusTextChangedEventWhereInput>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  expectedStart_eq?: InputMaybe<Scalars['DateTime']>
  expectedStart_gt?: InputMaybe<Scalars['DateTime']>
  expectedStart_gte?: InputMaybe<Scalars['DateTime']>
  expectedStart_lt?: InputMaybe<Scalars['DateTime']>
  expectedStart_lte?: InputMaybe<Scalars['DateTime']>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  metadata?: InputMaybe<WorkingGroupOpeningMetadataWhereInput>
  rewardPerBlock_eq?: InputMaybe<Scalars['BigInt']>
  rewardPerBlock_gt?: InputMaybe<Scalars['BigInt']>
  rewardPerBlock_gte?: InputMaybe<Scalars['BigInt']>
  rewardPerBlock_in?: InputMaybe<Array<Scalars['BigInt']>>
  rewardPerBlock_lt?: InputMaybe<Scalars['BigInt']>
  rewardPerBlock_lte?: InputMaybe<Scalars['BigInt']>
  stakeAmount_eq?: InputMaybe<Scalars['BigInt']>
  stakeAmount_gt?: InputMaybe<Scalars['BigInt']>
  stakeAmount_gte?: InputMaybe<Scalars['BigInt']>
  stakeAmount_in?: InputMaybe<Array<Scalars['BigInt']>>
  stakeAmount_lt?: InputMaybe<Scalars['BigInt']>
  stakeAmount_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type UpcomingWorkingGroupOpeningWhereUniqueInput = {
  id: Scalars['ID']
}

export type UpdateChannelPayoutsProposalDetails = {
  __typename: 'UpdateChannelPayoutsProposalDetails'
  /** Can channel cashout the rewards */
  channelCashoutsEnabled?: Maybe<Scalars['Boolean']>
  /** Merkle root of the channel payouts */
  commitment?: Maybe<Scalars['String']>
  /** Maximum amount of channel reward cashout allowed at a time */
  maxCashoutAllowed?: Maybe<Scalars['Float']>
  /** Minimum amount of channel reward cashout allowed at a time */
  minCashoutAllowed?: Maybe<Scalars['Float']>
  /** The hash of the channel payout payload file */
  payloadHash?: Maybe<Scalars['String']>
}

export type UpdateWorkingGroupBudgetProposalDetails = {
  __typename: 'UpdateWorkingGroupBudgetProposalDetails'
  /** Amount to increase / decrease the working group budget by (will be decudted from / appended to council budget accordingly) */
  amount: Scalars['Float']
  /** Related working group */
  group?: Maybe<WorkingGroup>
}

export type VariantNone = {
  __typename: 'VariantNone'
  phantom?: Maybe<Scalars['Int']>
}

export type VetoProposalDetails = {
  __typename: 'VetoProposalDetails'
  /** Proposal to be vetoed */
  proposal?: Maybe<Proposal>
}

export type Video = BaseGraphQlObject & {
  __typename: 'Video'
  auctionbidcanceledeventvideo?: Maybe<Array<AuctionBidCanceledEvent>>
  auctionbidmadeeventvideo?: Maybe<Array<AuctionBidMadeEvent>>
  auctioncanceledeventvideo?: Maybe<Array<AuctionCanceledEvent>>
  bidmadecompletingauctioneventvideo?: Maybe<Array<BidMadeCompletingAuctionEvent>>
  buynowcanceledeventvideo?: Maybe<Array<BuyNowCanceledEvent>>
  buynowpriceupdatedeventvideo?: Maybe<Array<BuyNowPriceUpdatedEvent>>
  category?: Maybe<VideoCategory>
  categoryId?: Maybe<Scalars['String']>
  channel: Channel
  channelId: Scalars['String']
  commentcreatedeventvideo?: Maybe<Array<CommentCreatedEvent>>
  commentdeletedeventvideo?: Maybe<Array<CommentDeletedEvent>>
  commentmoderatedeventvideo?: Maybe<Array<CommentModeratedEvent>>
  commentpinnedeventvideo?: Maybe<Array<CommentPinnedEvent>>
  commentreactedeventvideo?: Maybe<Array<CommentReactedEvent>>
  commentreactionscountbyreactionidvideo?: Maybe<Array<CommentReactionsCountByReactionId>>
  commentreactionvideo?: Maybe<Array<CommentReaction>>
  comments: Array<Comment>
  /** Comments count */
  commentsCount: Scalars['Int']
  commenttextupdatedeventvideo?: Maybe<Array<CommentTextUpdatedEvent>>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  createdInBlock: Scalars['Int']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** The description of the Video */
  description?: Maybe<Scalars['String']>
  /** Video duration in seconds */
  duration?: Maybe<Scalars['Int']>
  englishauctionsettledeventvideo?: Maybe<Array<EnglishAuctionSettledEvent>>
  englishauctionstartedeventvideo?: Maybe<Array<EnglishAuctionStartedEvent>>
  /** Whether or not Video contains marketing */
  hasMarketing?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  /** Flag signaling whether a video is censored. */
  isCensored: Scalars['Boolean']
  /** Is comment section enabled (true if enabled) */
  isCommentSectionEnabled: Scalars['Boolean']
  /** Whether the Video contains explicit material. */
  isExplicit?: Maybe<Scalars['Boolean']>
  /** Is video featured or not */
  isFeatured: Scalars['Boolean']
  /** Whether the Video is supposed to be publically displayed */
  isPublic?: Maybe<Scalars['Boolean']>
  /** Is reactions feature enabled on video (true if enabled i.e. video can be reacted) */
  isReactionFeatureEnabled: Scalars['Boolean']
  language?: Maybe<Language>
  languageId?: Maybe<Scalars['String']>
  license?: Maybe<License>
  licenseId?: Maybe<Scalars['String']>
  media?: Maybe<StorageDataObject>
  mediaId?: Maybe<Scalars['String']>
  mediaMetadata?: Maybe<VideoMediaMetadata>
  mediaMetadataId?: Maybe<Scalars['String']>
  nft?: Maybe<OwnedNft>
  nftId?: Maybe<Scalars['String']>
  nftboughteventvideo?: Maybe<Array<NftBoughtEvent>>
  nftissuedeventvideo?: Maybe<Array<NftIssuedEvent>>
  nftsellordermadeeventvideo?: Maybe<Array<NftSellOrderMadeEvent>>
  nftslingedbacktotheoriginalartisteventvideo?: Maybe<Array<NftSlingedBackToTheOriginalArtistEvent>>
  offeracceptedeventvideo?: Maybe<Array<OfferAcceptedEvent>>
  offercanceledeventvideo?: Maybe<Array<OfferCanceledEvent>>
  offerstartedeventvideo?: Maybe<Array<OfferStartedEvent>>
  openauctionbidacceptedeventvideo?: Maybe<Array<OpenAuctionBidAcceptedEvent>>
  openauctionstartedeventvideo?: Maybe<Array<OpenAuctionStartedEvent>>
  pinnedComment?: Maybe<Comment>
  /** If the Video was published on other platform before beeing published on Joystream - the original publication date */
  publishedBeforeJoystream?: Maybe<Scalars['DateTime']>
  reactions: Array<VideoReaction>
  /** Reactions count */
  reactionsCount: Scalars['Int']
  reactionsCountByReactionId: Array<VideoReactionsCountByReactionType>
  subtitles: Array<VideoSubtitle>
  thumbnailPhoto?: Maybe<StorageDataObject>
  thumbnailPhotoId?: Maybe<Scalars['String']>
  /** The title of the video */
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  /** Value of video state bloat bond fee paid by channel owner */
  videoStateBloatBond: Scalars['BigInt']
  videoreactedeventvideo?: Maybe<Array<VideoReactedEvent>>
  videoreactionspreferenceeventvideo?: Maybe<Array<VideoReactionsPreferenceEvent>>
}

export type VideoAssetsDeletedByModeratorEvent = BaseGraphQlObject & {
  __typename: 'VideoAssetsDeletedByModeratorEvent'
  /** Actor that deleted the channel assets. */
  actor: ContentActor
  /** Does deleted video assets belongs to NFT */
  areNftAssets?: Maybe<Scalars['Boolean']>
  /** ID  of the deleted video */
  assetIds: Array<Scalars['Int']>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Blocknumber of the block in which the event was emitted. */
  inBlock: Scalars['Int']
  /** Hash of the extrinsic which caused the event to be emitted. */
  inExtrinsic?: Maybe<Scalars['String']>
  /** Index of event in block from which it was emitted. */
  indexInBlock: Scalars['Int']
  /** Network the block was produced in. */
  network: Network
  /** why the channel assets were deleted */
  rationale: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  /** Video whose assets are being deleted */
  videoId: Scalars['Int']
}

export type VideoAssetsDeletedByModeratorEventConnection = {
  __typename: 'VideoAssetsDeletedByModeratorEventConnection'
  edges: Array<VideoAssetsDeletedByModeratorEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VideoAssetsDeletedByModeratorEventCreateInput = {
  actor: Scalars['JSONObject']
  areNftAssets?: InputMaybe<Scalars['Boolean']>
  assetIds: Array<Scalars['Int']>
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  rationale: Scalars['String']
  videoId: Scalars['Float']
}

export type VideoAssetsDeletedByModeratorEventEdge = {
  __typename: 'VideoAssetsDeletedByModeratorEventEdge'
  cursor: Scalars['String']
  node: VideoAssetsDeletedByModeratorEvent
}

export enum VideoAssetsDeletedByModeratorEventOrderByInput {
  AreNftAssetsAsc = 'areNftAssets_ASC',
  AreNftAssetsDesc = 'areNftAssets_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoIdAsc = 'videoId_ASC',
  VideoIdDesc = 'videoId_DESC',
}

export type VideoAssetsDeletedByModeratorEventUpdateInput = {
  actor?: InputMaybe<Scalars['JSONObject']>
  areNftAssets?: InputMaybe<Scalars['Boolean']>
  assetIds?: InputMaybe<Array<Scalars['Int']>>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  rationale?: InputMaybe<Scalars['String']>
  videoId?: InputMaybe<Scalars['Float']>
}

export type VideoAssetsDeletedByModeratorEventWhereInput = {
  AND?: InputMaybe<Array<VideoAssetsDeletedByModeratorEventWhereInput>>
  NOT?: InputMaybe<Array<VideoAssetsDeletedByModeratorEventWhereInput>>
  OR?: InputMaybe<Array<VideoAssetsDeletedByModeratorEventWhereInput>>
  actor_json?: InputMaybe<Scalars['JSONObject']>
  areNftAssets_eq?: InputMaybe<Scalars['Boolean']>
  areNftAssets_in?: InputMaybe<Array<Scalars['Boolean']>>
  assetIds_containsAll?: InputMaybe<Array<Scalars['Int']>>
  assetIds_containsAny?: InputMaybe<Array<Scalars['Int']>>
  assetIds_containsNone?: InputMaybe<Array<Scalars['Int']>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  videoId_eq?: InputMaybe<Scalars['Int']>
  videoId_gt?: InputMaybe<Scalars['Int']>
  videoId_gte?: InputMaybe<Scalars['Int']>
  videoId_in?: InputMaybe<Array<Scalars['Int']>>
  videoId_lt?: InputMaybe<Scalars['Int']>
  videoId_lte?: InputMaybe<Scalars['Int']>
}

export type VideoAssetsDeletedByModeratorEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type VideoCategoriesByNameFtsOutput = {
  __typename: 'VideoCategoriesByNameFTSOutput'
  highlight: Scalars['String']
  isTypeOf: Scalars['String']
  item: VideoCategoriesByNameSearchResult
  rank: Scalars['Float']
}

export type VideoCategoriesByNameSearchResult = VideoCategory

export type VideoCategory = BaseGraphQlObject & {
  __typename: 'VideoCategory'
  /** Count of category's videos with an uploaded asset that are public and not censored. */
  activeVideosCounter: Scalars['Int']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  createdInBlock: Scalars['Int']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** The description of the category */
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** The name of the category */
  name?: Maybe<Scalars['String']>
  nftissuedeventvideoCategory?: Maybe<Array<NftIssuedEvent>>
  nfts: Array<OwnedNft>
  parentCategory?: Maybe<VideoCategory>
  parentCategoryId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  videocategoryparentCategory?: Maybe<Array<VideoCategory>>
  videos: Array<Video>
}

export type VideoCategoryConnection = {
  __typename: 'VideoCategoryConnection'
  edges: Array<VideoCategoryEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VideoCategoryCreateInput = {
  activeVideosCounter: Scalars['Float']
  createdInBlock: Scalars['Float']
  description?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  parentCategory?: InputMaybe<Scalars['ID']>
}

export type VideoCategoryEdge = {
  __typename: 'VideoCategoryEdge'
  cursor: Scalars['String']
  node: VideoCategory
}

export enum VideoCategoryOrderByInput {
  ActiveVideosCounterAsc = 'activeVideosCounter_ASC',
  ActiveVideosCounterDesc = 'activeVideosCounter_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CreatedInBlockAsc = 'createdInBlock_ASC',
  CreatedInBlockDesc = 'createdInBlock_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ParentCategoryAsc = 'parentCategory_ASC',
  ParentCategoryDesc = 'parentCategory_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type VideoCategoryUpdateInput = {
  activeVideosCounter?: InputMaybe<Scalars['Float']>
  createdInBlock?: InputMaybe<Scalars['Float']>
  description?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  parentCategory?: InputMaybe<Scalars['ID']>
}

export type VideoCategoryWhereInput = {
  AND?: InputMaybe<Array<VideoCategoryWhereInput>>
  NOT?: InputMaybe<Array<VideoCategoryWhereInput>>
  OR?: InputMaybe<Array<VideoCategoryWhereInput>>
  activeVideosCounter_eq?: InputMaybe<Scalars['Int']>
  activeVideosCounter_gt?: InputMaybe<Scalars['Int']>
  activeVideosCounter_gte?: InputMaybe<Scalars['Int']>
  activeVideosCounter_in?: InputMaybe<Array<Scalars['Int']>>
  activeVideosCounter_lt?: InputMaybe<Scalars['Int']>
  activeVideosCounter_lte?: InputMaybe<Scalars['Int']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  createdInBlock_eq?: InputMaybe<Scalars['Int']>
  createdInBlock_gt?: InputMaybe<Scalars['Int']>
  createdInBlock_gte?: InputMaybe<Scalars['Int']>
  createdInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  createdInBlock_lt?: InputMaybe<Scalars['Int']>
  createdInBlock_lte?: InputMaybe<Scalars['Int']>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  description_contains?: InputMaybe<Scalars['String']>
  description_endsWith?: InputMaybe<Scalars['String']>
  description_eq?: InputMaybe<Scalars['String']>
  description_in?: InputMaybe<Array<Scalars['String']>>
  description_startsWith?: InputMaybe<Scalars['String']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  name_contains?: InputMaybe<Scalars['String']>
  name_endsWith?: InputMaybe<Scalars['String']>
  name_eq?: InputMaybe<Scalars['String']>
  name_in?: InputMaybe<Array<Scalars['String']>>
  name_startsWith?: InputMaybe<Scalars['String']>
  nftissuedeventvideoCategory_every?: InputMaybe<NftIssuedEventWhereInput>
  nftissuedeventvideoCategory_none?: InputMaybe<NftIssuedEventWhereInput>
  nftissuedeventvideoCategory_some?: InputMaybe<NftIssuedEventWhereInput>
  nfts_every?: InputMaybe<OwnedNftWhereInput>
  nfts_none?: InputMaybe<OwnedNftWhereInput>
  nfts_some?: InputMaybe<OwnedNftWhereInput>
  parentCategory?: InputMaybe<VideoCategoryWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  videocategoryparentCategory_every?: InputMaybe<VideoCategoryWhereInput>
  videocategoryparentCategory_none?: InputMaybe<VideoCategoryWhereInput>
  videocategoryparentCategory_some?: InputMaybe<VideoCategoryWhereInput>
  videos_every?: InputMaybe<VideoWhereInput>
  videos_none?: InputMaybe<VideoWhereInput>
  videos_some?: InputMaybe<VideoWhereInput>
}

export type VideoCategoryWhereUniqueInput = {
  id: Scalars['ID']
}

export type VideoConnection = {
  __typename: 'VideoConnection'
  edges: Array<VideoEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VideoCreateInput = {
  category?: InputMaybe<Scalars['ID']>
  channel: Scalars['ID']
  commentsCount: Scalars['Float']
  createdInBlock: Scalars['Float']
  description?: InputMaybe<Scalars['String']>
  duration?: InputMaybe<Scalars['Float']>
  hasMarketing?: InputMaybe<Scalars['Boolean']>
  isCensored: Scalars['Boolean']
  isCommentSectionEnabled: Scalars['Boolean']
  isExplicit?: InputMaybe<Scalars['Boolean']>
  isFeatured: Scalars['Boolean']
  isPublic?: InputMaybe<Scalars['Boolean']>
  isReactionFeatureEnabled: Scalars['Boolean']
  language?: InputMaybe<Scalars['ID']>
  license?: InputMaybe<Scalars['ID']>
  media?: InputMaybe<Scalars['ID']>
  mediaMetadata?: InputMaybe<Scalars['ID']>
  nft?: InputMaybe<Scalars['ID']>
  publishedBeforeJoystream?: InputMaybe<Scalars['DateTime']>
  reactionsCount: Scalars['Float']
  thumbnailPhoto?: InputMaybe<Scalars['ID']>
  title?: InputMaybe<Scalars['String']>
  videoStateBloatBond: Scalars['String']
}

export type VideoDeletedByModeratorEvent = BaseGraphQlObject & {
  __typename: 'VideoDeletedByModeratorEvent'
  /** Actor that deleted the video. */
  actor: ContentActor
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Blocknumber of the block in which the event was emitted. */
  inBlock: Scalars['Int']
  /** Hash of the extrinsic which caused the event to be emitted. */
  inExtrinsic?: Maybe<Scalars['String']>
  /** Index of event in block from which it was emitted. */
  indexInBlock: Scalars['Int']
  /** Network the block was produced in. */
  network: Network
  /** Why the video was deleted */
  rationale: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  /** ID  of the deleted video */
  videoId: Scalars['Int']
}

export type VideoDeletedByModeratorEventConnection = {
  __typename: 'VideoDeletedByModeratorEventConnection'
  edges: Array<VideoDeletedByModeratorEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VideoDeletedByModeratorEventCreateInput = {
  actor: Scalars['JSONObject']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  rationale: Scalars['String']
  videoId: Scalars['Float']
}

export type VideoDeletedByModeratorEventEdge = {
  __typename: 'VideoDeletedByModeratorEventEdge'
  cursor: Scalars['String']
  node: VideoDeletedByModeratorEvent
}

export enum VideoDeletedByModeratorEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoIdAsc = 'videoId_ASC',
  VideoIdDesc = 'videoId_DESC',
}

export type VideoDeletedByModeratorEventUpdateInput = {
  actor?: InputMaybe<Scalars['JSONObject']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  rationale?: InputMaybe<Scalars['String']>
  videoId?: InputMaybe<Scalars['Float']>
}

export type VideoDeletedByModeratorEventWhereInput = {
  AND?: InputMaybe<Array<VideoDeletedByModeratorEventWhereInput>>
  NOT?: InputMaybe<Array<VideoDeletedByModeratorEventWhereInput>>
  OR?: InputMaybe<Array<VideoDeletedByModeratorEventWhereInput>>
  actor_json?: InputMaybe<Scalars['JSONObject']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  videoId_eq?: InputMaybe<Scalars['Int']>
  videoId_gt?: InputMaybe<Scalars['Int']>
  videoId_gte?: InputMaybe<Scalars['Int']>
  videoId_in?: InputMaybe<Array<Scalars['Int']>>
  videoId_lt?: InputMaybe<Scalars['Int']>
  videoId_lte?: InputMaybe<Scalars['Int']>
}

export type VideoDeletedByModeratorEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type VideoDeletedEvent = BaseGraphQlObject & {
  __typename: 'VideoDeletedEvent'
  /** Actor that deleted the video. */
  actor: ContentActor
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Blocknumber of the block in which the event was emitted. */
  inBlock: Scalars['Int']
  /** Hash of the extrinsic which caused the event to be emitted. */
  inExtrinsic?: Maybe<Scalars['String']>
  /** Index of event in block from which it was emitted. */
  indexInBlock: Scalars['Int']
  /** Network the block was produced in. */
  network: Network
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  /** ID  of the deleted video */
  videoId: Scalars['Int']
}

export type VideoDeletedEventConnection = {
  __typename: 'VideoDeletedEventConnection'
  edges: Array<VideoDeletedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VideoDeletedEventCreateInput = {
  actor: Scalars['JSONObject']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  videoId: Scalars['Float']
}

export type VideoDeletedEventEdge = {
  __typename: 'VideoDeletedEventEdge'
  cursor: Scalars['String']
  node: VideoDeletedEvent
}

export enum VideoDeletedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoIdAsc = 'videoId_ASC',
  VideoIdDesc = 'videoId_DESC',
}

export type VideoDeletedEventUpdateInput = {
  actor?: InputMaybe<Scalars['JSONObject']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  videoId?: InputMaybe<Scalars['Float']>
}

export type VideoDeletedEventWhereInput = {
  AND?: InputMaybe<Array<VideoDeletedEventWhereInput>>
  NOT?: InputMaybe<Array<VideoDeletedEventWhereInput>>
  OR?: InputMaybe<Array<VideoDeletedEventWhereInput>>
  actor_json?: InputMaybe<Scalars['JSONObject']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  videoId_eq?: InputMaybe<Scalars['Int']>
  videoId_gt?: InputMaybe<Scalars['Int']>
  videoId_gte?: InputMaybe<Scalars['Int']>
  videoId_in?: InputMaybe<Array<Scalars['Int']>>
  videoId_lt?: InputMaybe<Scalars['Int']>
  videoId_lte?: InputMaybe<Scalars['Int']>
}

export type VideoDeletedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type VideoEdge = {
  __typename: 'VideoEdge'
  cursor: Scalars['String']
  node: Video
}

export type VideoMediaEncoding = BaseGraphQlObject & {
  __typename: 'VideoMediaEncoding'
  /** Encoding of the video media object */
  codecName?: Maybe<Scalars['String']>
  /** Media container format */
  container?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Content MIME type */
  mimeMediaType?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  videomediametadataencoding?: Maybe<Array<VideoMediaMetadata>>
}

export type VideoMediaEncodingConnection = {
  __typename: 'VideoMediaEncodingConnection'
  edges: Array<VideoMediaEncodingEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VideoMediaEncodingCreateInput = {
  codecName?: InputMaybe<Scalars['String']>
  container?: InputMaybe<Scalars['String']>
  mimeMediaType?: InputMaybe<Scalars['String']>
}

export type VideoMediaEncodingEdge = {
  __typename: 'VideoMediaEncodingEdge'
  cursor: Scalars['String']
  node: VideoMediaEncoding
}

export enum VideoMediaEncodingOrderByInput {
  CodecNameAsc = 'codecName_ASC',
  CodecNameDesc = 'codecName_DESC',
  ContainerAsc = 'container_ASC',
  ContainerDesc = 'container_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  MimeMediaTypeAsc = 'mimeMediaType_ASC',
  MimeMediaTypeDesc = 'mimeMediaType_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type VideoMediaEncodingUpdateInput = {
  codecName?: InputMaybe<Scalars['String']>
  container?: InputMaybe<Scalars['String']>
  mimeMediaType?: InputMaybe<Scalars['String']>
}

export type VideoMediaEncodingWhereInput = {
  AND?: InputMaybe<Array<VideoMediaEncodingWhereInput>>
  NOT?: InputMaybe<Array<VideoMediaEncodingWhereInput>>
  OR?: InputMaybe<Array<VideoMediaEncodingWhereInput>>
  codecName_contains?: InputMaybe<Scalars['String']>
  codecName_endsWith?: InputMaybe<Scalars['String']>
  codecName_eq?: InputMaybe<Scalars['String']>
  codecName_in?: InputMaybe<Array<Scalars['String']>>
  codecName_startsWith?: InputMaybe<Scalars['String']>
  container_contains?: InputMaybe<Scalars['String']>
  container_endsWith?: InputMaybe<Scalars['String']>
  container_eq?: InputMaybe<Scalars['String']>
  container_in?: InputMaybe<Array<Scalars['String']>>
  container_startsWith?: InputMaybe<Scalars['String']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  mimeMediaType_contains?: InputMaybe<Scalars['String']>
  mimeMediaType_endsWith?: InputMaybe<Scalars['String']>
  mimeMediaType_eq?: InputMaybe<Scalars['String']>
  mimeMediaType_in?: InputMaybe<Array<Scalars['String']>>
  mimeMediaType_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  videomediametadataencoding_every?: InputMaybe<VideoMediaMetadataWhereInput>
  videomediametadataencoding_none?: InputMaybe<VideoMediaMetadataWhereInput>
  videomediametadataencoding_some?: InputMaybe<VideoMediaMetadataWhereInput>
}

export type VideoMediaEncodingWhereUniqueInput = {
  id: Scalars['ID']
}

export type VideoMediaMetadata = BaseGraphQlObject & {
  __typename: 'VideoMediaMetadata'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  createdInBlock: Scalars['Int']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  encoding?: Maybe<VideoMediaEncoding>
  encodingId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** Video media height in pixels */
  pixelHeight?: Maybe<Scalars['Int']>
  /** Video media width in pixels */
  pixelWidth?: Maybe<Scalars['Int']>
  /** Video media size in bytes */
  size?: Maybe<Scalars['BigInt']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  video?: Maybe<Video>
}

export type VideoMediaMetadataConnection = {
  __typename: 'VideoMediaMetadataConnection'
  edges: Array<VideoMediaMetadataEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VideoMediaMetadataCreateInput = {
  createdInBlock: Scalars['Float']
  encoding?: InputMaybe<Scalars['ID']>
  pixelHeight?: InputMaybe<Scalars['Float']>
  pixelWidth?: InputMaybe<Scalars['Float']>
  size?: InputMaybe<Scalars['String']>
}

export type VideoMediaMetadataEdge = {
  __typename: 'VideoMediaMetadataEdge'
  cursor: Scalars['String']
  node: VideoMediaMetadata
}

export enum VideoMediaMetadataOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CreatedInBlockAsc = 'createdInBlock_ASC',
  CreatedInBlockDesc = 'createdInBlock_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EncodingAsc = 'encoding_ASC',
  EncodingDesc = 'encoding_DESC',
  PixelHeightAsc = 'pixelHeight_ASC',
  PixelHeightDesc = 'pixelHeight_DESC',
  PixelWidthAsc = 'pixelWidth_ASC',
  PixelWidthDesc = 'pixelWidth_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type VideoMediaMetadataUpdateInput = {
  createdInBlock?: InputMaybe<Scalars['Float']>
  encoding?: InputMaybe<Scalars['ID']>
  pixelHeight?: InputMaybe<Scalars['Float']>
  pixelWidth?: InputMaybe<Scalars['Float']>
  size?: InputMaybe<Scalars['String']>
}

export type VideoMediaMetadataWhereInput = {
  AND?: InputMaybe<Array<VideoMediaMetadataWhereInput>>
  NOT?: InputMaybe<Array<VideoMediaMetadataWhereInput>>
  OR?: InputMaybe<Array<VideoMediaMetadataWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  createdInBlock_eq?: InputMaybe<Scalars['Int']>
  createdInBlock_gt?: InputMaybe<Scalars['Int']>
  createdInBlock_gte?: InputMaybe<Scalars['Int']>
  createdInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  createdInBlock_lt?: InputMaybe<Scalars['Int']>
  createdInBlock_lte?: InputMaybe<Scalars['Int']>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  encoding?: InputMaybe<VideoMediaEncodingWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  pixelHeight_eq?: InputMaybe<Scalars['Int']>
  pixelHeight_gt?: InputMaybe<Scalars['Int']>
  pixelHeight_gte?: InputMaybe<Scalars['Int']>
  pixelHeight_in?: InputMaybe<Array<Scalars['Int']>>
  pixelHeight_lt?: InputMaybe<Scalars['Int']>
  pixelHeight_lte?: InputMaybe<Scalars['Int']>
  pixelWidth_eq?: InputMaybe<Scalars['Int']>
  pixelWidth_gt?: InputMaybe<Scalars['Int']>
  pixelWidth_gte?: InputMaybe<Scalars['Int']>
  pixelWidth_in?: InputMaybe<Array<Scalars['Int']>>
  pixelWidth_lt?: InputMaybe<Scalars['Int']>
  pixelWidth_lte?: InputMaybe<Scalars['Int']>
  size_eq?: InputMaybe<Scalars['BigInt']>
  size_gt?: InputMaybe<Scalars['BigInt']>
  size_gte?: InputMaybe<Scalars['BigInt']>
  size_in?: InputMaybe<Array<Scalars['BigInt']>>
  size_lt?: InputMaybe<Scalars['BigInt']>
  size_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type VideoMediaMetadataWhereUniqueInput = {
  id: Scalars['ID']
}

export enum VideoOrderByInput {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  ChannelAsc = 'channel_ASC',
  ChannelDesc = 'channel_DESC',
  CommentsCountAsc = 'commentsCount_ASC',
  CommentsCountDesc = 'commentsCount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CreatedInBlockAsc = 'createdInBlock_ASC',
  CreatedInBlockDesc = 'createdInBlock_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  HasMarketingAsc = 'hasMarketing_ASC',
  HasMarketingDesc = 'hasMarketing_DESC',
  IsCensoredAsc = 'isCensored_ASC',
  IsCensoredDesc = 'isCensored_DESC',
  IsCommentSectionEnabledAsc = 'isCommentSectionEnabled_ASC',
  IsCommentSectionEnabledDesc = 'isCommentSectionEnabled_DESC',
  IsExplicitAsc = 'isExplicit_ASC',
  IsExplicitDesc = 'isExplicit_DESC',
  IsFeaturedAsc = 'isFeatured_ASC',
  IsFeaturedDesc = 'isFeatured_DESC',
  IsPublicAsc = 'isPublic_ASC',
  IsPublicDesc = 'isPublic_DESC',
  IsReactionFeatureEnabledAsc = 'isReactionFeatureEnabled_ASC',
  IsReactionFeatureEnabledDesc = 'isReactionFeatureEnabled_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  LicenseAsc = 'license_ASC',
  LicenseDesc = 'license_DESC',
  MediaMetadataAsc = 'mediaMetadata_ASC',
  MediaMetadataDesc = 'mediaMetadata_DESC',
  MediaAsc = 'media_ASC',
  MediaDesc = 'media_DESC',
  NftAsc = 'nft_ASC',
  NftDesc = 'nft_DESC',
  PublishedBeforeJoystreamAsc = 'publishedBeforeJoystream_ASC',
  PublishedBeforeJoystreamDesc = 'publishedBeforeJoystream_DESC',
  ReactionsCountAsc = 'reactionsCount_ASC',
  ReactionsCountDesc = 'reactionsCount_DESC',
  ThumbnailPhotoAsc = 'thumbnailPhoto_ASC',
  ThumbnailPhotoDesc = 'thumbnailPhoto_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoStateBloatBondAsc = 'videoStateBloatBond_ASC',
  VideoStateBloatBondDesc = 'videoStateBloatBond_DESC',
}

export type VideoReactedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'VideoReactedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    reactingMember: Membership
    reactingMemberId: Scalars['String']
    /** The reaction result */
    reactionResult: VideoReactionOptions
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoChannel: Channel
    videoChannelId: Scalars['String']
    videoId: Scalars['String']
  }

export type VideoReactedEventConnection = {
  __typename: 'VideoReactedEventConnection'
  edges: Array<VideoReactedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VideoReactedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  reactingMember: Scalars['ID']
  reactionResult: VideoReactionOptions
  video: Scalars['ID']
  videoChannel: Scalars['ID']
}

export type VideoReactedEventEdge = {
  __typename: 'VideoReactedEventEdge'
  cursor: Scalars['String']
  node: VideoReactedEvent
}

export enum VideoReactedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ReactingMemberAsc = 'reactingMember_ASC',
  ReactingMemberDesc = 'reactingMember_DESC',
  ReactionResultAsc = 'reactionResult_ASC',
  ReactionResultDesc = 'reactionResult_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoChannelAsc = 'videoChannel_ASC',
  VideoChannelDesc = 'videoChannel_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type VideoReactedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  reactingMember?: InputMaybe<Scalars['ID']>
  reactionResult?: InputMaybe<VideoReactionOptions>
  video?: InputMaybe<Scalars['ID']>
  videoChannel?: InputMaybe<Scalars['ID']>
}

export type VideoReactedEventWhereInput = {
  AND?: InputMaybe<Array<VideoReactedEventWhereInput>>
  NOT?: InputMaybe<Array<VideoReactedEventWhereInput>>
  OR?: InputMaybe<Array<VideoReactedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  reactingMember?: InputMaybe<MembershipWhereInput>
  reactionResult_eq?: InputMaybe<VideoReactionOptions>
  reactionResult_in?: InputMaybe<Array<VideoReactionOptions>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
  videoChannel?: InputMaybe<ChannelWhereInput>
}

export type VideoReactedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type VideoReaction = BaseGraphQlObject & {
  __typename: 'VideoReaction'
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  member: Membership
  /** ID of the member that reacted */
  memberId: Scalars['String']
  /** The Reaction */
  reaction: VideoReactionOptions
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  video: Video
  videoId: Scalars['String']
}

export type VideoReactionConnection = {
  __typename: 'VideoReactionConnection'
  edges: Array<VideoReactionEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VideoReactionCreateInput = {
  member: Scalars['ID']
  memberId: Scalars['String']
  reaction: VideoReactionOptions
  video: Scalars['ID']
}

export type VideoReactionEdge = {
  __typename: 'VideoReactionEdge'
  cursor: Scalars['String']
  node: VideoReaction
}

export enum VideoReactionOptions {
  Like = 'LIKE',
  Unlike = 'UNLIKE',
}

export enum VideoReactionOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  MemberIdAsc = 'memberId_ASC',
  MemberIdDesc = 'memberId_DESC',
  MemberAsc = 'member_ASC',
  MemberDesc = 'member_DESC',
  ReactionAsc = 'reaction_ASC',
  ReactionDesc = 'reaction_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type VideoReactionUpdateInput = {
  member?: InputMaybe<Scalars['ID']>
  memberId?: InputMaybe<Scalars['String']>
  reaction?: InputMaybe<VideoReactionOptions>
  video?: InputMaybe<Scalars['ID']>
}

export type VideoReactionWhereInput = {
  AND?: InputMaybe<Array<VideoReactionWhereInput>>
  NOT?: InputMaybe<Array<VideoReactionWhereInput>>
  OR?: InputMaybe<Array<VideoReactionWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  member?: InputMaybe<MembershipWhereInput>
  memberId_contains?: InputMaybe<Scalars['String']>
  memberId_endsWith?: InputMaybe<Scalars['String']>
  memberId_eq?: InputMaybe<Scalars['String']>
  memberId_in?: InputMaybe<Array<Scalars['String']>>
  memberId_startsWith?: InputMaybe<Scalars['String']>
  reaction_eq?: InputMaybe<VideoReactionOptions>
  reaction_in?: InputMaybe<Array<VideoReactionOptions>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type VideoReactionWhereUniqueInput = {
  id: Scalars['ID']
}

export type VideoReactionsCountByReactionType = BaseGraphQlObject & {
  __typename: 'VideoReactionsCountByReactionType'
  /** No of times the video has been reacted with given reaction */
  count: Scalars['Int']
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** The reaction option */
  reaction: VideoReactionOptions
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  video: Video
  videoId: Scalars['String']
}

export type VideoReactionsCountByReactionTypeConnection = {
  __typename: 'VideoReactionsCountByReactionTypeConnection'
  edges: Array<VideoReactionsCountByReactionTypeEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VideoReactionsCountByReactionTypeCreateInput = {
  count: Scalars['Float']
  reaction: VideoReactionOptions
  video: Scalars['ID']
}

export type VideoReactionsCountByReactionTypeEdge = {
  __typename: 'VideoReactionsCountByReactionTypeEdge'
  cursor: Scalars['String']
  node: VideoReactionsCountByReactionType
}

export enum VideoReactionsCountByReactionTypeOrderByInput {
  CountAsc = 'count_ASC',
  CountDesc = 'count_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ReactionAsc = 'reaction_ASC',
  ReactionDesc = 'reaction_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type VideoReactionsCountByReactionTypeUpdateInput = {
  count?: InputMaybe<Scalars['Float']>
  reaction?: InputMaybe<VideoReactionOptions>
  video?: InputMaybe<Scalars['ID']>
}

export type VideoReactionsCountByReactionTypeWhereInput = {
  AND?: InputMaybe<Array<VideoReactionsCountByReactionTypeWhereInput>>
  NOT?: InputMaybe<Array<VideoReactionsCountByReactionTypeWhereInput>>
  OR?: InputMaybe<Array<VideoReactionsCountByReactionTypeWhereInput>>
  count_eq?: InputMaybe<Scalars['Int']>
  count_gt?: InputMaybe<Scalars['Int']>
  count_gte?: InputMaybe<Scalars['Int']>
  count_in?: InputMaybe<Array<Scalars['Int']>>
  count_lt?: InputMaybe<Scalars['Int']>
  count_lte?: InputMaybe<Scalars['Int']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  reaction_eq?: InputMaybe<VideoReactionOptions>
  reaction_in?: InputMaybe<Array<VideoReactionOptions>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type VideoReactionsCountByReactionTypeWhereUniqueInput = {
  id: Scalars['ID']
}

export type VideoReactionsPreferenceEvent = BaseGraphQlObject &
  Event & {
    __typename: 'VideoReactionsPreferenceEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Is comment section enabled (true if enabled) */
    reactionsStatus: Scalars['Boolean']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    video: Video
    videoId: Scalars['String']
  }

export type VideoReactionsPreferenceEventConnection = {
  __typename: 'VideoReactionsPreferenceEventConnection'
  edges: Array<VideoReactionsPreferenceEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VideoReactionsPreferenceEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  reactionsStatus: Scalars['Boolean']
  video: Scalars['ID']
}

export type VideoReactionsPreferenceEventEdge = {
  __typename: 'VideoReactionsPreferenceEventEdge'
  cursor: Scalars['String']
  node: VideoReactionsPreferenceEvent
}

export enum VideoReactionsPreferenceEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ReactionsStatusAsc = 'reactionsStatus_ASC',
  ReactionsStatusDesc = 'reactionsStatus_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type VideoReactionsPreferenceEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  reactionsStatus?: InputMaybe<Scalars['Boolean']>
  video?: InputMaybe<Scalars['ID']>
}

export type VideoReactionsPreferenceEventWhereInput = {
  AND?: InputMaybe<Array<VideoReactionsPreferenceEventWhereInput>>
  NOT?: InputMaybe<Array<VideoReactionsPreferenceEventWhereInput>>
  OR?: InputMaybe<Array<VideoReactionsPreferenceEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  reactionsStatus_eq?: InputMaybe<Scalars['Boolean']>
  reactionsStatus_in?: InputMaybe<Array<Scalars['Boolean']>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type VideoReactionsPreferenceEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type VideoSubtitle = BaseGraphQlObject & {
  __typename: 'VideoSubtitle'
  asset?: Maybe<StorageDataObject>
  assetId?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  language?: Maybe<Language>
  languageId?: Maybe<Scalars['String']>
  /** MIME type description of format used for this subtitle */
  mimeType: Scalars['String']
  /** Subtitle's type */
  type: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  video: Video
  videoId: Scalars['String']
}

export type VideoSubtitleConnection = {
  __typename: 'VideoSubtitleConnection'
  edges: Array<VideoSubtitleEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VideoSubtitleCreateInput = {
  asset?: InputMaybe<Scalars['ID']>
  language?: InputMaybe<Scalars['ID']>
  mimeType: Scalars['String']
  type: Scalars['String']
  video: Scalars['ID']
}

export type VideoSubtitleEdge = {
  __typename: 'VideoSubtitleEdge'
  cursor: Scalars['String']
  node: VideoSubtitle
}

export enum VideoSubtitleOrderByInput {
  AssetAsc = 'asset_ASC',
  AssetDesc = 'asset_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC',
}

export type VideoSubtitleUpdateInput = {
  asset?: InputMaybe<Scalars['ID']>
  language?: InputMaybe<Scalars['ID']>
  mimeType?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
  video?: InputMaybe<Scalars['ID']>
}

export type VideoSubtitleWhereInput = {
  AND?: InputMaybe<Array<VideoSubtitleWhereInput>>
  NOT?: InputMaybe<Array<VideoSubtitleWhereInput>>
  OR?: InputMaybe<Array<VideoSubtitleWhereInput>>
  asset?: InputMaybe<StorageDataObjectWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  language?: InputMaybe<LanguageWhereInput>
  mimeType_contains?: InputMaybe<Scalars['String']>
  mimeType_endsWith?: InputMaybe<Scalars['String']>
  mimeType_eq?: InputMaybe<Scalars['String']>
  mimeType_in?: InputMaybe<Array<Scalars['String']>>
  mimeType_startsWith?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_endsWith?: InputMaybe<Scalars['String']>
  type_eq?: InputMaybe<Scalars['String']>
  type_in?: InputMaybe<Array<Scalars['String']>>
  type_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  video?: InputMaybe<VideoWhereInput>
}

export type VideoSubtitleWhereUniqueInput = {
  id: Scalars['ID']
}

export type VideoUpdateInput = {
  category?: InputMaybe<Scalars['ID']>
  channel?: InputMaybe<Scalars['ID']>
  commentsCount?: InputMaybe<Scalars['Float']>
  createdInBlock?: InputMaybe<Scalars['Float']>
  description?: InputMaybe<Scalars['String']>
  duration?: InputMaybe<Scalars['Float']>
  hasMarketing?: InputMaybe<Scalars['Boolean']>
  isCensored?: InputMaybe<Scalars['Boolean']>
  isCommentSectionEnabled?: InputMaybe<Scalars['Boolean']>
  isExplicit?: InputMaybe<Scalars['Boolean']>
  isFeatured?: InputMaybe<Scalars['Boolean']>
  isPublic?: InputMaybe<Scalars['Boolean']>
  isReactionFeatureEnabled?: InputMaybe<Scalars['Boolean']>
  language?: InputMaybe<Scalars['ID']>
  license?: InputMaybe<Scalars['ID']>
  media?: InputMaybe<Scalars['ID']>
  mediaMetadata?: InputMaybe<Scalars['ID']>
  nft?: InputMaybe<Scalars['ID']>
  publishedBeforeJoystream?: InputMaybe<Scalars['DateTime']>
  reactionsCount?: InputMaybe<Scalars['Float']>
  thumbnailPhoto?: InputMaybe<Scalars['ID']>
  title?: InputMaybe<Scalars['String']>
  videoStateBloatBond?: InputMaybe<Scalars['String']>
}

export type VideoVisibilitySetByModeratorEvent = BaseGraphQlObject & {
  __typename: 'VideoVisibilitySetByModeratorEvent'
  /** Actor that deleted the channel assets. */
  actor: ContentActor
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Blocknumber of the block in which the event was emitted. */
  inBlock: Scalars['Int']
  /** Hash of the extrinsic which caused the event to be emitted. */
  inExtrinsic?: Maybe<Scalars['String']>
  /** Index of event in block from which it was emitted. */
  indexInBlock: Scalars['Int']
  /** Is video being censored/hidden (yes if true) */
  isHidden: Scalars['Boolean']
  /** Network the block was produced in. */
  network: Network
  /** Why video's visibility status was set */
  rationale: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  /** Video whose visibility/censorship status is changed */
  videoId: Scalars['Int']
}

export type VideoVisibilitySetByModeratorEventConnection = {
  __typename: 'VideoVisibilitySetByModeratorEventConnection'
  edges: Array<VideoVisibilitySetByModeratorEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VideoVisibilitySetByModeratorEventCreateInput = {
  actor: Scalars['JSONObject']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  isHidden: Scalars['Boolean']
  network: Network
  rationale: Scalars['String']
  videoId: Scalars['Float']
}

export type VideoVisibilitySetByModeratorEventEdge = {
  __typename: 'VideoVisibilitySetByModeratorEventEdge'
  cursor: Scalars['String']
  node: VideoVisibilitySetByModeratorEvent
}

export enum VideoVisibilitySetByModeratorEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VideoIdAsc = 'videoId_ASC',
  VideoIdDesc = 'videoId_DESC',
}

export type VideoVisibilitySetByModeratorEventUpdateInput = {
  actor?: InputMaybe<Scalars['JSONObject']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  isHidden?: InputMaybe<Scalars['Boolean']>
  network?: InputMaybe<Network>
  rationale?: InputMaybe<Scalars['String']>
  videoId?: InputMaybe<Scalars['Float']>
}

export type VideoVisibilitySetByModeratorEventWhereInput = {
  AND?: InputMaybe<Array<VideoVisibilitySetByModeratorEventWhereInput>>
  NOT?: InputMaybe<Array<VideoVisibilitySetByModeratorEventWhereInput>>
  OR?: InputMaybe<Array<VideoVisibilitySetByModeratorEventWhereInput>>
  actor_json?: InputMaybe<Scalars['JSONObject']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  isHidden_eq?: InputMaybe<Scalars['Boolean']>
  isHidden_in?: InputMaybe<Array<Scalars['Boolean']>>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  videoId_eq?: InputMaybe<Scalars['Int']>
  videoId_gt?: InputMaybe<Scalars['Int']>
  videoId_gte?: InputMaybe<Scalars['Int']>
  videoId_in?: InputMaybe<Array<Scalars['Int']>>
  videoId_lt?: InputMaybe<Scalars['Int']>
  videoId_lte?: InputMaybe<Scalars['Int']>
}

export type VideoVisibilitySetByModeratorEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type VideoWhereInput = {
  AND?: InputMaybe<Array<VideoWhereInput>>
  NOT?: InputMaybe<Array<VideoWhereInput>>
  OR?: InputMaybe<Array<VideoWhereInput>>
  auctionbidcanceledeventvideo_every?: InputMaybe<AuctionBidCanceledEventWhereInput>
  auctionbidcanceledeventvideo_none?: InputMaybe<AuctionBidCanceledEventWhereInput>
  auctionbidcanceledeventvideo_some?: InputMaybe<AuctionBidCanceledEventWhereInput>
  auctionbidmadeeventvideo_every?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctionbidmadeeventvideo_none?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctionbidmadeeventvideo_some?: InputMaybe<AuctionBidMadeEventWhereInput>
  auctioncanceledeventvideo_every?: InputMaybe<AuctionCanceledEventWhereInput>
  auctioncanceledeventvideo_none?: InputMaybe<AuctionCanceledEventWhereInput>
  auctioncanceledeventvideo_some?: InputMaybe<AuctionCanceledEventWhereInput>
  bidmadecompletingauctioneventvideo_every?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventvideo_none?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  bidmadecompletingauctioneventvideo_some?: InputMaybe<BidMadeCompletingAuctionEventWhereInput>
  buynowcanceledeventvideo_every?: InputMaybe<BuyNowCanceledEventWhereInput>
  buynowcanceledeventvideo_none?: InputMaybe<BuyNowCanceledEventWhereInput>
  buynowcanceledeventvideo_some?: InputMaybe<BuyNowCanceledEventWhereInput>
  buynowpriceupdatedeventvideo_every?: InputMaybe<BuyNowPriceUpdatedEventWhereInput>
  buynowpriceupdatedeventvideo_none?: InputMaybe<BuyNowPriceUpdatedEventWhereInput>
  buynowpriceupdatedeventvideo_some?: InputMaybe<BuyNowPriceUpdatedEventWhereInput>
  category?: InputMaybe<VideoCategoryWhereInput>
  channel?: InputMaybe<ChannelWhereInput>
  commentcreatedeventvideo_every?: InputMaybe<CommentCreatedEventWhereInput>
  commentcreatedeventvideo_none?: InputMaybe<CommentCreatedEventWhereInput>
  commentcreatedeventvideo_some?: InputMaybe<CommentCreatedEventWhereInput>
  commentdeletedeventvideo_every?: InputMaybe<CommentDeletedEventWhereInput>
  commentdeletedeventvideo_none?: InputMaybe<CommentDeletedEventWhereInput>
  commentdeletedeventvideo_some?: InputMaybe<CommentDeletedEventWhereInput>
  commentmoderatedeventvideo_every?: InputMaybe<CommentModeratedEventWhereInput>
  commentmoderatedeventvideo_none?: InputMaybe<CommentModeratedEventWhereInput>
  commentmoderatedeventvideo_some?: InputMaybe<CommentModeratedEventWhereInput>
  commentpinnedeventvideo_every?: InputMaybe<CommentPinnedEventWhereInput>
  commentpinnedeventvideo_none?: InputMaybe<CommentPinnedEventWhereInput>
  commentpinnedeventvideo_some?: InputMaybe<CommentPinnedEventWhereInput>
  commentreactedeventvideo_every?: InputMaybe<CommentReactedEventWhereInput>
  commentreactedeventvideo_none?: InputMaybe<CommentReactedEventWhereInput>
  commentreactedeventvideo_some?: InputMaybe<CommentReactedEventWhereInput>
  commentreactionscountbyreactionidvideo_every?: InputMaybe<CommentReactionsCountByReactionIdWhereInput>
  commentreactionscountbyreactionidvideo_none?: InputMaybe<CommentReactionsCountByReactionIdWhereInput>
  commentreactionscountbyreactionidvideo_some?: InputMaybe<CommentReactionsCountByReactionIdWhereInput>
  commentreactionvideo_every?: InputMaybe<CommentReactionWhereInput>
  commentreactionvideo_none?: InputMaybe<CommentReactionWhereInput>
  commentreactionvideo_some?: InputMaybe<CommentReactionWhereInput>
  commentsCount_eq?: InputMaybe<Scalars['Int']>
  commentsCount_gt?: InputMaybe<Scalars['Int']>
  commentsCount_gte?: InputMaybe<Scalars['Int']>
  commentsCount_in?: InputMaybe<Array<Scalars['Int']>>
  commentsCount_lt?: InputMaybe<Scalars['Int']>
  commentsCount_lte?: InputMaybe<Scalars['Int']>
  comments_every?: InputMaybe<CommentWhereInput>
  comments_none?: InputMaybe<CommentWhereInput>
  comments_some?: InputMaybe<CommentWhereInput>
  commenttextupdatedeventvideo_every?: InputMaybe<CommentTextUpdatedEventWhereInput>
  commenttextupdatedeventvideo_none?: InputMaybe<CommentTextUpdatedEventWhereInput>
  commenttextupdatedeventvideo_some?: InputMaybe<CommentTextUpdatedEventWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  createdInBlock_eq?: InputMaybe<Scalars['Int']>
  createdInBlock_gt?: InputMaybe<Scalars['Int']>
  createdInBlock_gte?: InputMaybe<Scalars['Int']>
  createdInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  createdInBlock_lt?: InputMaybe<Scalars['Int']>
  createdInBlock_lte?: InputMaybe<Scalars['Int']>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  description_contains?: InputMaybe<Scalars['String']>
  description_endsWith?: InputMaybe<Scalars['String']>
  description_eq?: InputMaybe<Scalars['String']>
  description_in?: InputMaybe<Array<Scalars['String']>>
  description_startsWith?: InputMaybe<Scalars['String']>
  duration_eq?: InputMaybe<Scalars['Int']>
  duration_gt?: InputMaybe<Scalars['Int']>
  duration_gte?: InputMaybe<Scalars['Int']>
  duration_in?: InputMaybe<Array<Scalars['Int']>>
  duration_lt?: InputMaybe<Scalars['Int']>
  duration_lte?: InputMaybe<Scalars['Int']>
  englishauctionsettledeventvideo_every?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  englishauctionsettledeventvideo_none?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  englishauctionsettledeventvideo_some?: InputMaybe<EnglishAuctionSettledEventWhereInput>
  englishauctionstartedeventvideo_every?: InputMaybe<EnglishAuctionStartedEventWhereInput>
  englishauctionstartedeventvideo_none?: InputMaybe<EnglishAuctionStartedEventWhereInput>
  englishauctionstartedeventvideo_some?: InputMaybe<EnglishAuctionStartedEventWhereInput>
  hasMarketing_eq?: InputMaybe<Scalars['Boolean']>
  hasMarketing_in?: InputMaybe<Array<Scalars['Boolean']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  isCensored_eq?: InputMaybe<Scalars['Boolean']>
  isCensored_in?: InputMaybe<Array<Scalars['Boolean']>>
  isCommentSectionEnabled_eq?: InputMaybe<Scalars['Boolean']>
  isCommentSectionEnabled_in?: InputMaybe<Array<Scalars['Boolean']>>
  isExplicit_eq?: InputMaybe<Scalars['Boolean']>
  isExplicit_in?: InputMaybe<Array<Scalars['Boolean']>>
  isFeatured_eq?: InputMaybe<Scalars['Boolean']>
  isFeatured_in?: InputMaybe<Array<Scalars['Boolean']>>
  isPublic_eq?: InputMaybe<Scalars['Boolean']>
  isPublic_in?: InputMaybe<Array<Scalars['Boolean']>>
  isReactionFeatureEnabled_eq?: InputMaybe<Scalars['Boolean']>
  isReactionFeatureEnabled_in?: InputMaybe<Array<Scalars['Boolean']>>
  language?: InputMaybe<LanguageWhereInput>
  license?: InputMaybe<LicenseWhereInput>
  media?: InputMaybe<StorageDataObjectWhereInput>
  mediaMetadata?: InputMaybe<VideoMediaMetadataWhereInput>
  nft?: InputMaybe<OwnedNftWhereInput>
  nftboughteventvideo_every?: InputMaybe<NftBoughtEventWhereInput>
  nftboughteventvideo_none?: InputMaybe<NftBoughtEventWhereInput>
  nftboughteventvideo_some?: InputMaybe<NftBoughtEventWhereInput>
  nftissuedeventvideo_every?: InputMaybe<NftIssuedEventWhereInput>
  nftissuedeventvideo_none?: InputMaybe<NftIssuedEventWhereInput>
  nftissuedeventvideo_some?: InputMaybe<NftIssuedEventWhereInput>
  nftsellordermadeeventvideo_every?: InputMaybe<NftSellOrderMadeEventWhereInput>
  nftsellordermadeeventvideo_none?: InputMaybe<NftSellOrderMadeEventWhereInput>
  nftsellordermadeeventvideo_some?: InputMaybe<NftSellOrderMadeEventWhereInput>
  nftslingedbacktotheoriginalartisteventvideo_every?: InputMaybe<NftSlingedBackToTheOriginalArtistEventWhereInput>
  nftslingedbacktotheoriginalartisteventvideo_none?: InputMaybe<NftSlingedBackToTheOriginalArtistEventWhereInput>
  nftslingedbacktotheoriginalartisteventvideo_some?: InputMaybe<NftSlingedBackToTheOriginalArtistEventWhereInput>
  offeracceptedeventvideo_every?: InputMaybe<OfferAcceptedEventWhereInput>
  offeracceptedeventvideo_none?: InputMaybe<OfferAcceptedEventWhereInput>
  offeracceptedeventvideo_some?: InputMaybe<OfferAcceptedEventWhereInput>
  offercanceledeventvideo_every?: InputMaybe<OfferCanceledEventWhereInput>
  offercanceledeventvideo_none?: InputMaybe<OfferCanceledEventWhereInput>
  offercanceledeventvideo_some?: InputMaybe<OfferCanceledEventWhereInput>
  offerstartedeventvideo_every?: InputMaybe<OfferStartedEventWhereInput>
  offerstartedeventvideo_none?: InputMaybe<OfferStartedEventWhereInput>
  offerstartedeventvideo_some?: InputMaybe<OfferStartedEventWhereInput>
  openauctionbidacceptedeventvideo_every?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  openauctionbidacceptedeventvideo_none?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  openauctionbidacceptedeventvideo_some?: InputMaybe<OpenAuctionBidAcceptedEventWhereInput>
  openauctionstartedeventvideo_every?: InputMaybe<OpenAuctionStartedEventWhereInput>
  openauctionstartedeventvideo_none?: InputMaybe<OpenAuctionStartedEventWhereInput>
  openauctionstartedeventvideo_some?: InputMaybe<OpenAuctionStartedEventWhereInput>
  pinnedComment?: InputMaybe<CommentWhereInput>
  publishedBeforeJoystream_eq?: InputMaybe<Scalars['DateTime']>
  publishedBeforeJoystream_gt?: InputMaybe<Scalars['DateTime']>
  publishedBeforeJoystream_gte?: InputMaybe<Scalars['DateTime']>
  publishedBeforeJoystream_lt?: InputMaybe<Scalars['DateTime']>
  publishedBeforeJoystream_lte?: InputMaybe<Scalars['DateTime']>
  reactionsCountByReactionId_every?: InputMaybe<VideoReactionsCountByReactionTypeWhereInput>
  reactionsCountByReactionId_none?: InputMaybe<VideoReactionsCountByReactionTypeWhereInput>
  reactionsCountByReactionId_some?: InputMaybe<VideoReactionsCountByReactionTypeWhereInput>
  reactionsCount_eq?: InputMaybe<Scalars['Int']>
  reactionsCount_gt?: InputMaybe<Scalars['Int']>
  reactionsCount_gte?: InputMaybe<Scalars['Int']>
  reactionsCount_in?: InputMaybe<Array<Scalars['Int']>>
  reactionsCount_lt?: InputMaybe<Scalars['Int']>
  reactionsCount_lte?: InputMaybe<Scalars['Int']>
  reactions_every?: InputMaybe<VideoReactionWhereInput>
  reactions_none?: InputMaybe<VideoReactionWhereInput>
  reactions_some?: InputMaybe<VideoReactionWhereInput>
  subtitles_every?: InputMaybe<VideoSubtitleWhereInput>
  subtitles_none?: InputMaybe<VideoSubtitleWhereInput>
  subtitles_some?: InputMaybe<VideoSubtitleWhereInput>
  thumbnailPhoto?: InputMaybe<StorageDataObjectWhereInput>
  title_contains?: InputMaybe<Scalars['String']>
  title_endsWith?: InputMaybe<Scalars['String']>
  title_eq?: InputMaybe<Scalars['String']>
  title_in?: InputMaybe<Array<Scalars['String']>>
  title_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  videoStateBloatBond_eq?: InputMaybe<Scalars['BigInt']>
  videoStateBloatBond_gt?: InputMaybe<Scalars['BigInt']>
  videoStateBloatBond_gte?: InputMaybe<Scalars['BigInt']>
  videoStateBloatBond_in?: InputMaybe<Array<Scalars['BigInt']>>
  videoStateBloatBond_lt?: InputMaybe<Scalars['BigInt']>
  videoStateBloatBond_lte?: InputMaybe<Scalars['BigInt']>
  videoreactedeventvideo_every?: InputMaybe<VideoReactedEventWhereInput>
  videoreactedeventvideo_none?: InputMaybe<VideoReactedEventWhereInput>
  videoreactedeventvideo_some?: InputMaybe<VideoReactedEventWhereInput>
  videoreactionspreferenceeventvideo_every?: InputMaybe<VideoReactionsPreferenceEventWhereInput>
  videoreactionspreferenceeventvideo_none?: InputMaybe<VideoReactionsPreferenceEventWhereInput>
  videoreactionspreferenceeventvideo_some?: InputMaybe<VideoReactionsPreferenceEventWhereInput>
}

export type VideoWhereUniqueInput = {
  id: Scalars['ID']
}

export type VoteCastEvent = BaseGraphQlObject &
  Event & {
    __typename: 'VoteCastEvent'
    castVote: CastVote
    castVoteId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type VoteCastEventConnection = {
  __typename: 'VoteCastEventConnection'
  edges: Array<VoteCastEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VoteCastEventCreateInput = {
  castVote: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type VoteCastEventEdge = {
  __typename: 'VoteCastEventEdge'
  cursor: Scalars['String']
  node: VoteCastEvent
}

export enum VoteCastEventOrderByInput {
  CastVoteAsc = 'castVote_ASC',
  CastVoteDesc = 'castVote_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type VoteCastEventUpdateInput = {
  castVote?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type VoteCastEventWhereInput = {
  AND?: InputMaybe<Array<VoteCastEventWhereInput>>
  NOT?: InputMaybe<Array<VoteCastEventWhereInput>>
  OR?: InputMaybe<Array<VoteCastEventWhereInput>>
  castVote?: InputMaybe<CastVoteWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type VoteCastEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type VoteRevealedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'VoteRevealedEvent'
    castVote: CastVote
    castVoteId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type VoteRevealedEventConnection = {
  __typename: 'VoteRevealedEventConnection'
  edges: Array<VoteRevealedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VoteRevealedEventCreateInput = {
  castVote: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type VoteRevealedEventEdge = {
  __typename: 'VoteRevealedEventEdge'
  cursor: Scalars['String']
  node: VoteRevealedEvent
}

export enum VoteRevealedEventOrderByInput {
  CastVoteAsc = 'castVote_ASC',
  CastVoteDesc = 'castVote_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type VoteRevealedEventUpdateInput = {
  castVote?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type VoteRevealedEventWhereInput = {
  AND?: InputMaybe<Array<VoteRevealedEventWhereInput>>
  NOT?: InputMaybe<Array<VoteRevealedEventWhereInput>>
  OR?: InputMaybe<Array<VoteRevealedEventWhereInput>>
  castVote?: InputMaybe<CastVoteWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type VoteRevealedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type VotingPeriodStartedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'VotingPeriodStartedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Number of candidates in the election. */
    numOfCandidates: Scalars['BigInt']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type VotingPeriodStartedEventConnection = {
  __typename: 'VotingPeriodStartedEventConnection'
  edges: Array<VotingPeriodStartedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type VotingPeriodStartedEventCreateInput = {
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  numOfCandidates: Scalars['String']
}

export type VotingPeriodStartedEventEdge = {
  __typename: 'VotingPeriodStartedEventEdge'
  cursor: Scalars['String']
  node: VotingPeriodStartedEvent
}

export enum VotingPeriodStartedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NumOfCandidatesAsc = 'numOfCandidates_ASC',
  NumOfCandidatesDesc = 'numOfCandidates_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type VotingPeriodStartedEventUpdateInput = {
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  numOfCandidates?: InputMaybe<Scalars['String']>
}

export type VotingPeriodStartedEventWhereInput = {
  AND?: InputMaybe<Array<VotingPeriodStartedEventWhereInput>>
  NOT?: InputMaybe<Array<VotingPeriodStartedEventWhereInput>>
  OR?: InputMaybe<Array<VotingPeriodStartedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  numOfCandidates_eq?: InputMaybe<Scalars['BigInt']>
  numOfCandidates_gt?: InputMaybe<Scalars['BigInt']>
  numOfCandidates_gte?: InputMaybe<Scalars['BigInt']>
  numOfCandidates_in?: InputMaybe<Array<Scalars['BigInt']>>
  numOfCandidates_lt?: InputMaybe<Scalars['BigInt']>
  numOfCandidates_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type VotingPeriodStartedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type WorkEntrantFundsWithdrawnEvent = BaseGraphQlObject &
  Event & {
    __typename: 'WorkEntrantFundsWithdrawnEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    entry: BountyEntry
    entryId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type WorkEntrantFundsWithdrawnEventConnection = {
  __typename: 'WorkEntrantFundsWithdrawnEventConnection'
  edges: Array<WorkEntrantFundsWithdrawnEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkEntrantFundsWithdrawnEventCreateInput = {
  entry: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type WorkEntrantFundsWithdrawnEventEdge = {
  __typename: 'WorkEntrantFundsWithdrawnEventEdge'
  cursor: Scalars['String']
  node: WorkEntrantFundsWithdrawnEvent
}

export enum WorkEntrantFundsWithdrawnEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EntryAsc = 'entry_ASC',
  EntryDesc = 'entry_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type WorkEntrantFundsWithdrawnEventUpdateInput = {
  entry?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type WorkEntrantFundsWithdrawnEventWhereInput = {
  AND?: InputMaybe<Array<WorkEntrantFundsWithdrawnEventWhereInput>>
  NOT?: InputMaybe<Array<WorkEntrantFundsWithdrawnEventWhereInput>>
  OR?: InputMaybe<Array<WorkEntrantFundsWithdrawnEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  entry?: InputMaybe<BountyEntryWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type WorkEntrantFundsWithdrawnEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type WorkEntryAnnouncedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'WorkEntryAnnouncedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    entry: BountyEntry
    entryId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type WorkEntryAnnouncedEventConnection = {
  __typename: 'WorkEntryAnnouncedEventConnection'
  edges: Array<WorkEntryAnnouncedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkEntryAnnouncedEventCreateInput = {
  entry: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type WorkEntryAnnouncedEventEdge = {
  __typename: 'WorkEntryAnnouncedEventEdge'
  cursor: Scalars['String']
  node: WorkEntryAnnouncedEvent
}

export enum WorkEntryAnnouncedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EntryAsc = 'entry_ASC',
  EntryDesc = 'entry_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type WorkEntryAnnouncedEventUpdateInput = {
  entry?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type WorkEntryAnnouncedEventWhereInput = {
  AND?: InputMaybe<Array<WorkEntryAnnouncedEventWhereInput>>
  NOT?: InputMaybe<Array<WorkEntryAnnouncedEventWhereInput>>
  OR?: InputMaybe<Array<WorkEntryAnnouncedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  entry?: InputMaybe<BountyEntryWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type WorkEntryAnnouncedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type WorkEntrySlashedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'WorkEntrySlashedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    entry: BountyEntry
    entryId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type WorkEntrySlashedEventConnection = {
  __typename: 'WorkEntrySlashedEventConnection'
  edges: Array<WorkEntrySlashedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkEntrySlashedEventCreateInput = {
  entry: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type WorkEntrySlashedEventEdge = {
  __typename: 'WorkEntrySlashedEventEdge'
  cursor: Scalars['String']
  node: WorkEntrySlashedEvent
}

export enum WorkEntrySlashedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EntryAsc = 'entry_ASC',
  EntryDesc = 'entry_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type WorkEntrySlashedEventUpdateInput = {
  entry?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type WorkEntrySlashedEventWhereInput = {
  AND?: InputMaybe<Array<WorkEntrySlashedEventWhereInput>>
  NOT?: InputMaybe<Array<WorkEntrySlashedEventWhereInput>>
  OR?: InputMaybe<Array<WorkEntrySlashedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  entry?: InputMaybe<BountyEntryWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type WorkEntrySlashedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type WorkEntryWithdrawnEvent = BaseGraphQlObject &
  Event & {
    __typename: 'WorkEntryWithdrawnEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    entry: BountyEntry
    entryId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type WorkEntryWithdrawnEventConnection = {
  __typename: 'WorkEntryWithdrawnEventConnection'
  edges: Array<WorkEntryWithdrawnEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkEntryWithdrawnEventCreateInput = {
  entry: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
}

export type WorkEntryWithdrawnEventEdge = {
  __typename: 'WorkEntryWithdrawnEventEdge'
  cursor: Scalars['String']
  node: WorkEntryWithdrawnEvent
}

export enum WorkEntryWithdrawnEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EntryAsc = 'entry_ASC',
  EntryDesc = 'entry_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type WorkEntryWithdrawnEventUpdateInput = {
  entry?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
}

export type WorkEntryWithdrawnEventWhereInput = {
  AND?: InputMaybe<Array<WorkEntryWithdrawnEventWhereInput>>
  NOT?: InputMaybe<Array<WorkEntryWithdrawnEventWhereInput>>
  OR?: InputMaybe<Array<WorkEntryWithdrawnEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  entry?: InputMaybe<BountyEntryWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type WorkEntryWithdrawnEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type WorkSubmittedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'WorkSubmittedEvent'
    bounty: Bounty
    bountyId: Scalars['String']
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    /** Description which contains the work itself as a URL, a BLOB, or just text */
    description?: Maybe<Scalars['String']>
    entry: BountyEntry
    entryId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Title of the work */
    title?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
  }

export type WorkSubmittedEventConnection = {
  __typename: 'WorkSubmittedEventConnection'
  edges: Array<WorkSubmittedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkSubmittedEventCreateInput = {
  bounty: Scalars['ID']
  description?: InputMaybe<Scalars['String']>
  entry: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  title?: InputMaybe<Scalars['String']>
}

export type WorkSubmittedEventEdge = {
  __typename: 'WorkSubmittedEventEdge'
  cursor: Scalars['String']
  node: WorkSubmittedEvent
}

export enum WorkSubmittedEventOrderByInput {
  BountyAsc = 'bounty_ASC',
  BountyDesc = 'bounty_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  EntryAsc = 'entry_ASC',
  EntryDesc = 'entry_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type WorkSubmittedEventUpdateInput = {
  bounty?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  entry?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  title?: InputMaybe<Scalars['String']>
}

export type WorkSubmittedEventWhereInput = {
  AND?: InputMaybe<Array<WorkSubmittedEventWhereInput>>
  NOT?: InputMaybe<Array<WorkSubmittedEventWhereInput>>
  OR?: InputMaybe<Array<WorkSubmittedEventWhereInput>>
  bounty?: InputMaybe<BountyWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  description_contains?: InputMaybe<Scalars['String']>
  description_endsWith?: InputMaybe<Scalars['String']>
  description_eq?: InputMaybe<Scalars['String']>
  description_in?: InputMaybe<Array<Scalars['String']>>
  description_startsWith?: InputMaybe<Scalars['String']>
  entry?: InputMaybe<BountyEntryWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  title_contains?: InputMaybe<Scalars['String']>
  title_endsWith?: InputMaybe<Scalars['String']>
  title_eq?: InputMaybe<Scalars['String']>
  title_in?: InputMaybe<Array<Scalars['String']>>
  title_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type WorkSubmittedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type Worker = BaseGraphQlObject & {
  __typename: 'Worker'
  application: WorkingGroupApplication
  applicationId: Scalars['String']
  categoryarchivalstatusupdatedeventactor?: Maybe<Array<CategoryArchivalStatusUpdatedEvent>>
  categorydeletedeventactor?: Maybe<Array<CategoryDeletedEvent>>
  categorymembershipofmoderatorupdatedeventmoderator?: Maybe<Array<CategoryMembershipOfModeratorUpdatedEvent>>
  categorystickythreadupdateeventactor?: Maybe<Array<CategoryStickyThreadUpdateEvent>>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  entry: OpeningFilledEvent
  entryId: Scalars['String']
  group: WorkingGroup
  /** The id the group that the worker belongs to */
  groupId: Scalars['String']
  id: Scalars['ID']
  /** Whether the worker is currently active */
  isActive: Scalars['Boolean']
  /** Whether the worker is also the working group lead */
  isLead: Scalars['Boolean']
  leaderseteventworker?: Maybe<Array<LeaderSetEvent>>
  leaderunseteventleader?: Maybe<Array<LeaderUnsetEvent>>
  managedForumCategories: Array<ForumCategory>
  membership: Membership
  membershipId: Scalars['String']
  memberverificationstatusupdatedeventworker?: Maybe<Array<MemberVerificationStatusUpdatedEvent>>
  /** The reward amount the worker is currently missing (due to empty working group budget) */
  missingRewardAmount?: Maybe<Scalars['BigInt']>
  newmissedrewardlevelreachedeventworker?: Maybe<Array<NewMissedRewardLevelReachedEvent>>
  payouts: Array<RewardPaidEvent>
  postmoderatedeventactor?: Maybe<Array<PostModeratedEvent>>
  /** Worker's reward account */
  rewardAccount: Scalars['String']
  /** Current reward per block */
  rewardPerBlock: Scalars['BigInt']
  /** Worker's role account */
  roleAccount: Scalars['String']
  /** WorkerId in specific working group module */
  runtimeId: Scalars['Int']
  slashes: Array<StakeSlashedEvent>
  /** Current role stake (in JOY) */
  stake: Scalars['BigInt']
  /** Worker's staking account */
  stakeAccount: Scalars['String']
  stakedecreasedeventworker?: Maybe<Array<StakeDecreasedEvent>>
  stakeincreasedeventworker?: Maybe<Array<StakeIncreasedEvent>>
  /** Current worker status */
  status: WorkerStatus
  /** Worker's storage data */
  storage?: Maybe<Scalars['String']>
  terminatedleadereventworker?: Maybe<Array<TerminatedLeaderEvent>>
  terminatedworkereventworker?: Maybe<Array<TerminatedWorkerEvent>>
  threadmoderatedeventactor?: Maybe<Array<ThreadModeratedEvent>>
  threadmovedeventactor?: Maybe<Array<ThreadMovedEvent>>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  workerexitedeventworker?: Maybe<Array<WorkerExitedEvent>>
  workerrewardaccountupdatedeventworker?: Maybe<Array<WorkerRewardAccountUpdatedEvent>>
  workerrewardamountupdatedeventworker?: Maybe<Array<WorkerRewardAmountUpdatedEvent>>
  workerroleaccountupdatedeventworker?: Maybe<Array<WorkerRoleAccountUpdatedEvent>>
  workerstartedleavingeventworker?: Maybe<Array<WorkerStartedLeavingEvent>>
  workinggroupleader?: Maybe<Array<WorkingGroup>>
}

export type WorkerConnection = {
  __typename: 'WorkerConnection'
  edges: Array<WorkerEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkerCreateInput = {
  application: Scalars['ID']
  entry: Scalars['ID']
  group: Scalars['ID']
  groupId: Scalars['String']
  isActive: Scalars['Boolean']
  isLead: Scalars['Boolean']
  membership: Scalars['ID']
  missingRewardAmount?: InputMaybe<Scalars['String']>
  rewardAccount: Scalars['String']
  rewardPerBlock: Scalars['String']
  roleAccount: Scalars['String']
  runtimeId: Scalars['Float']
  stake: Scalars['String']
  stakeAccount: Scalars['String']
  status: Scalars['JSONObject']
  storage?: InputMaybe<Scalars['String']>
}

export type WorkerEdge = {
  __typename: 'WorkerEdge'
  cursor: Scalars['String']
  node: Worker
}

export type WorkerExitedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'WorkerExitedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    worker: Worker
    workerId: Scalars['String']
  }

export type WorkerExitedEventConnection = {
  __typename: 'WorkerExitedEventConnection'
  edges: Array<WorkerExitedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkerExitedEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  worker: Scalars['ID']
}

export type WorkerExitedEventEdge = {
  __typename: 'WorkerExitedEventEdge'
  cursor: Scalars['String']
  node: WorkerExitedEvent
}

export enum WorkerExitedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type WorkerExitedEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  worker?: InputMaybe<Scalars['ID']>
}

export type WorkerExitedEventWhereInput = {
  AND?: InputMaybe<Array<WorkerExitedEventWhereInput>>
  NOT?: InputMaybe<Array<WorkerExitedEventWhereInput>>
  OR?: InputMaybe<Array<WorkerExitedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  worker?: InputMaybe<WorkerWhereInput>
}

export type WorkerExitedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export enum WorkerOrderByInput {
  ApplicationAsc = 'application_ASC',
  ApplicationDesc = 'application_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EntryAsc = 'entry_ASC',
  EntryDesc = 'entry_DESC',
  GroupIdAsc = 'groupId_ASC',
  GroupIdDesc = 'groupId_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  IsLeadAsc = 'isLead_ASC',
  IsLeadDesc = 'isLead_DESC',
  MembershipAsc = 'membership_ASC',
  MembershipDesc = 'membership_DESC',
  MissingRewardAmountAsc = 'missingRewardAmount_ASC',
  MissingRewardAmountDesc = 'missingRewardAmount_DESC',
  RewardAccountAsc = 'rewardAccount_ASC',
  RewardAccountDesc = 'rewardAccount_DESC',
  RewardPerBlockAsc = 'rewardPerBlock_ASC',
  RewardPerBlockDesc = 'rewardPerBlock_DESC',
  RoleAccountAsc = 'roleAccount_ASC',
  RoleAccountDesc = 'roleAccount_DESC',
  RuntimeIdAsc = 'runtimeId_ASC',
  RuntimeIdDesc = 'runtimeId_DESC',
  StakeAccountAsc = 'stakeAccount_ASC',
  StakeAccountDesc = 'stakeAccount_DESC',
  StakeAsc = 'stake_ASC',
  StakeDesc = 'stake_DESC',
  StorageAsc = 'storage_ASC',
  StorageDesc = 'storage_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type WorkerRewardAccountUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'WorkerRewardAccountUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** New reward account */
    newRewardAccount: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    worker: Worker
    workerId: Scalars['String']
  }

export type WorkerRewardAccountUpdatedEventConnection = {
  __typename: 'WorkerRewardAccountUpdatedEventConnection'
  edges: Array<WorkerRewardAccountUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkerRewardAccountUpdatedEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newRewardAccount: Scalars['String']
  worker: Scalars['ID']
}

export type WorkerRewardAccountUpdatedEventEdge = {
  __typename: 'WorkerRewardAccountUpdatedEventEdge'
  cursor: Scalars['String']
  node: WorkerRewardAccountUpdatedEvent
}

export enum WorkerRewardAccountUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewRewardAccountAsc = 'newRewardAccount_ASC',
  NewRewardAccountDesc = 'newRewardAccount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type WorkerRewardAccountUpdatedEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newRewardAccount?: InputMaybe<Scalars['String']>
  worker?: InputMaybe<Scalars['ID']>
}

export type WorkerRewardAccountUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<WorkerRewardAccountUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<WorkerRewardAccountUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<WorkerRewardAccountUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newRewardAccount_contains?: InputMaybe<Scalars['String']>
  newRewardAccount_endsWith?: InputMaybe<Scalars['String']>
  newRewardAccount_eq?: InputMaybe<Scalars['String']>
  newRewardAccount_in?: InputMaybe<Array<Scalars['String']>>
  newRewardAccount_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  worker?: InputMaybe<WorkerWhereInput>
}

export type WorkerRewardAccountUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type WorkerRewardAmountUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'WorkerRewardAmountUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** New worker reward per block */
    newRewardPerBlock: Scalars['BigInt']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    worker: Worker
    workerId: Scalars['String']
  }

export type WorkerRewardAmountUpdatedEventConnection = {
  __typename: 'WorkerRewardAmountUpdatedEventConnection'
  edges: Array<WorkerRewardAmountUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkerRewardAmountUpdatedEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newRewardPerBlock: Scalars['String']
  worker: Scalars['ID']
}

export type WorkerRewardAmountUpdatedEventEdge = {
  __typename: 'WorkerRewardAmountUpdatedEventEdge'
  cursor: Scalars['String']
  node: WorkerRewardAmountUpdatedEvent
}

export enum WorkerRewardAmountUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewRewardPerBlockAsc = 'newRewardPerBlock_ASC',
  NewRewardPerBlockDesc = 'newRewardPerBlock_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type WorkerRewardAmountUpdatedEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newRewardPerBlock?: InputMaybe<Scalars['String']>
  worker?: InputMaybe<Scalars['ID']>
}

export type WorkerRewardAmountUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<WorkerRewardAmountUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<WorkerRewardAmountUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<WorkerRewardAmountUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newRewardPerBlock_eq?: InputMaybe<Scalars['BigInt']>
  newRewardPerBlock_gt?: InputMaybe<Scalars['BigInt']>
  newRewardPerBlock_gte?: InputMaybe<Scalars['BigInt']>
  newRewardPerBlock_in?: InputMaybe<Array<Scalars['BigInt']>>
  newRewardPerBlock_lt?: InputMaybe<Scalars['BigInt']>
  newRewardPerBlock_lte?: InputMaybe<Scalars['BigInt']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  worker?: InputMaybe<WorkerWhereInput>
}

export type WorkerRewardAmountUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type WorkerRoleAccountUpdatedEvent = BaseGraphQlObject &
  Event & {
    __typename: 'WorkerRoleAccountUpdatedEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** New role account */
    newRoleAccount: Scalars['String']
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    worker: Worker
    workerId: Scalars['String']
  }

export type WorkerRoleAccountUpdatedEventConnection = {
  __typename: 'WorkerRoleAccountUpdatedEventConnection'
  edges: Array<WorkerRoleAccountUpdatedEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkerRoleAccountUpdatedEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  newRoleAccount: Scalars['String']
  worker: Scalars['ID']
}

export type WorkerRoleAccountUpdatedEventEdge = {
  __typename: 'WorkerRoleAccountUpdatedEventEdge'
  cursor: Scalars['String']
  node: WorkerRoleAccountUpdatedEvent
}

export enum WorkerRoleAccountUpdatedEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NewRoleAccountAsc = 'newRoleAccount_ASC',
  NewRoleAccountDesc = 'newRoleAccount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type WorkerRoleAccountUpdatedEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  newRoleAccount?: InputMaybe<Scalars['String']>
  worker?: InputMaybe<Scalars['ID']>
}

export type WorkerRoleAccountUpdatedEventWhereInput = {
  AND?: InputMaybe<Array<WorkerRoleAccountUpdatedEventWhereInput>>
  NOT?: InputMaybe<Array<WorkerRoleAccountUpdatedEventWhereInput>>
  OR?: InputMaybe<Array<WorkerRoleAccountUpdatedEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  newRoleAccount_contains?: InputMaybe<Scalars['String']>
  newRoleAccount_endsWith?: InputMaybe<Scalars['String']>
  newRoleAccount_eq?: InputMaybe<Scalars['String']>
  newRoleAccount_in?: InputMaybe<Array<Scalars['String']>>
  newRoleAccount_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  worker?: InputMaybe<WorkerWhereInput>
}

export type WorkerRoleAccountUpdatedEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type WorkerStartedLeavingEvent = BaseGraphQlObject &
  Event & {
    __typename: 'WorkerStartedLeavingEvent'
    createdAt: Scalars['DateTime']
    createdById: Scalars['ID']
    deletedAt?: Maybe<Scalars['DateTime']>
    deletedById?: Maybe<Scalars['ID']>
    group: WorkingGroup
    groupId: Scalars['String']
    id: Scalars['ID']
    /** Blocknumber of the block in which the event was emitted. */
    inBlock: Scalars['Int']
    /** Hash of the extrinsic which caused the event to be emitted */
    inExtrinsic?: Maybe<Scalars['String']>
    /** Index of event in block from which it was emitted. */
    indexInBlock: Scalars['Int']
    /** Network the block was produced in */
    network: Network
    /** Optional rationale */
    rationale?: Maybe<Scalars['String']>
    /** Filtering options for interface implementers */
    type?: Maybe<EventTypeOptions>
    updatedAt?: Maybe<Scalars['DateTime']>
    updatedById?: Maybe<Scalars['ID']>
    version: Scalars['Int']
    worker: Worker
    workerId: Scalars['String']
  }

export type WorkerStartedLeavingEventConnection = {
  __typename: 'WorkerStartedLeavingEventConnection'
  edges: Array<WorkerStartedLeavingEventEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkerStartedLeavingEventCreateInput = {
  group: Scalars['ID']
  inBlock: Scalars['Float']
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock: Scalars['Float']
  network: Network
  rationale?: InputMaybe<Scalars['String']>
  worker: Scalars['ID']
}

export type WorkerStartedLeavingEventEdge = {
  __typename: 'WorkerStartedLeavingEventEdge'
  cursor: Scalars['String']
  node: WorkerStartedLeavingEvent
}

export enum WorkerStartedLeavingEventOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  InBlockAsc = 'inBlock_ASC',
  InBlockDesc = 'inBlock_DESC',
  InExtrinsicAsc = 'inExtrinsic_ASC',
  InExtrinsicDesc = 'inExtrinsic_DESC',
  IndexInBlockAsc = 'indexInBlock_ASC',
  IndexInBlockDesc = 'indexInBlock_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  RationaleAsc = 'rationale_ASC',
  RationaleDesc = 'rationale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WorkerAsc = 'worker_ASC',
  WorkerDesc = 'worker_DESC',
}

export type WorkerStartedLeavingEventUpdateInput = {
  group?: InputMaybe<Scalars['ID']>
  inBlock?: InputMaybe<Scalars['Float']>
  inExtrinsic?: InputMaybe<Scalars['String']>
  indexInBlock?: InputMaybe<Scalars['Float']>
  network?: InputMaybe<Network>
  rationale?: InputMaybe<Scalars['String']>
  worker?: InputMaybe<Scalars['ID']>
}

export type WorkerStartedLeavingEventWhereInput = {
  AND?: InputMaybe<Array<WorkerStartedLeavingEventWhereInput>>
  NOT?: InputMaybe<Array<WorkerStartedLeavingEventWhereInput>>
  OR?: InputMaybe<Array<WorkerStartedLeavingEventWhereInput>>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  inBlock_eq?: InputMaybe<Scalars['Int']>
  inBlock_gt?: InputMaybe<Scalars['Int']>
  inBlock_gte?: InputMaybe<Scalars['Int']>
  inBlock_in?: InputMaybe<Array<Scalars['Int']>>
  inBlock_lt?: InputMaybe<Scalars['Int']>
  inBlock_lte?: InputMaybe<Scalars['Int']>
  inExtrinsic_contains?: InputMaybe<Scalars['String']>
  inExtrinsic_endsWith?: InputMaybe<Scalars['String']>
  inExtrinsic_eq?: InputMaybe<Scalars['String']>
  inExtrinsic_in?: InputMaybe<Array<Scalars['String']>>
  inExtrinsic_startsWith?: InputMaybe<Scalars['String']>
  indexInBlock_eq?: InputMaybe<Scalars['Int']>
  indexInBlock_gt?: InputMaybe<Scalars['Int']>
  indexInBlock_gte?: InputMaybe<Scalars['Int']>
  indexInBlock_in?: InputMaybe<Array<Scalars['Int']>>
  indexInBlock_lt?: InputMaybe<Scalars['Int']>
  indexInBlock_lte?: InputMaybe<Scalars['Int']>
  network_eq?: InputMaybe<Network>
  network_in?: InputMaybe<Array<Network>>
  rationale_contains?: InputMaybe<Scalars['String']>
  rationale_endsWith?: InputMaybe<Scalars['String']>
  rationale_eq?: InputMaybe<Scalars['String']>
  rationale_in?: InputMaybe<Array<Scalars['String']>>
  rationale_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  worker?: InputMaybe<WorkerWhereInput>
}

export type WorkerStartedLeavingEventWhereUniqueInput = {
  id: Scalars['ID']
}

export type WorkerStatus = WorkerStatusActive | WorkerStatusLeaving | WorkerStatusLeft | WorkerStatusTerminated

export type WorkerStatusActive = {
  __typename: 'WorkerStatusActive'
  phantom?: Maybe<Scalars['Int']>
}

export type WorkerStatusLeaving = {
  __typename: 'WorkerStatusLeaving'
  /** Related event emitted on leaving initialization */
  workerStartedLeavingEvent?: Maybe<WorkerStartedLeavingEvent>
}

export type WorkerStatusLeft = {
  __typename: 'WorkerStatusLeft'
  /** Related event emitted once the worker has exited the role (after the unstaking period) */
  workerExitedEvent?: Maybe<WorkerExitedEvent>
  /** Related event emitted on leaving initialization */
  workerStartedLeavingEvent?: Maybe<WorkerStartedLeavingEvent>
}

export type WorkerStatusTerminated = {
  __typename: 'WorkerStatusTerminated'
  /** Related event emitted on worker termination */
  terminatedWorkerEvent?: Maybe<TerminatedWorkerEvent>
}

export type WorkerUpdateInput = {
  application?: InputMaybe<Scalars['ID']>
  entry?: InputMaybe<Scalars['ID']>
  group?: InputMaybe<Scalars['ID']>
  groupId?: InputMaybe<Scalars['String']>
  isActive?: InputMaybe<Scalars['Boolean']>
  isLead?: InputMaybe<Scalars['Boolean']>
  membership?: InputMaybe<Scalars['ID']>
  missingRewardAmount?: InputMaybe<Scalars['String']>
  rewardAccount?: InputMaybe<Scalars['String']>
  rewardPerBlock?: InputMaybe<Scalars['String']>
  roleAccount?: InputMaybe<Scalars['String']>
  runtimeId?: InputMaybe<Scalars['Float']>
  stake?: InputMaybe<Scalars['String']>
  stakeAccount?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['JSONObject']>
  storage?: InputMaybe<Scalars['String']>
}

export type WorkerWhereInput = {
  AND?: InputMaybe<Array<WorkerWhereInput>>
  NOT?: InputMaybe<Array<WorkerWhereInput>>
  OR?: InputMaybe<Array<WorkerWhereInput>>
  application?: InputMaybe<WorkingGroupApplicationWhereInput>
  categoryarchivalstatusupdatedeventactor_every?: InputMaybe<CategoryArchivalStatusUpdatedEventWhereInput>
  categoryarchivalstatusupdatedeventactor_none?: InputMaybe<CategoryArchivalStatusUpdatedEventWhereInput>
  categoryarchivalstatusupdatedeventactor_some?: InputMaybe<CategoryArchivalStatusUpdatedEventWhereInput>
  categorydeletedeventactor_every?: InputMaybe<CategoryDeletedEventWhereInput>
  categorydeletedeventactor_none?: InputMaybe<CategoryDeletedEventWhereInput>
  categorydeletedeventactor_some?: InputMaybe<CategoryDeletedEventWhereInput>
  categorymembershipofmoderatorupdatedeventmoderator_every?: InputMaybe<CategoryMembershipOfModeratorUpdatedEventWhereInput>
  categorymembershipofmoderatorupdatedeventmoderator_none?: InputMaybe<CategoryMembershipOfModeratorUpdatedEventWhereInput>
  categorymembershipofmoderatorupdatedeventmoderator_some?: InputMaybe<CategoryMembershipOfModeratorUpdatedEventWhereInput>
  categorystickythreadupdateeventactor_every?: InputMaybe<CategoryStickyThreadUpdateEventWhereInput>
  categorystickythreadupdateeventactor_none?: InputMaybe<CategoryStickyThreadUpdateEventWhereInput>
  categorystickythreadupdateeventactor_some?: InputMaybe<CategoryStickyThreadUpdateEventWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  entry?: InputMaybe<OpeningFilledEventWhereInput>
  group?: InputMaybe<WorkingGroupWhereInput>
  groupId_contains?: InputMaybe<Scalars['String']>
  groupId_endsWith?: InputMaybe<Scalars['String']>
  groupId_eq?: InputMaybe<Scalars['String']>
  groupId_in?: InputMaybe<Array<Scalars['String']>>
  groupId_startsWith?: InputMaybe<Scalars['String']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  isActive_eq?: InputMaybe<Scalars['Boolean']>
  isActive_in?: InputMaybe<Array<Scalars['Boolean']>>
  isLead_eq?: InputMaybe<Scalars['Boolean']>
  isLead_in?: InputMaybe<Array<Scalars['Boolean']>>
  leaderseteventworker_every?: InputMaybe<LeaderSetEventWhereInput>
  leaderseteventworker_none?: InputMaybe<LeaderSetEventWhereInput>
  leaderseteventworker_some?: InputMaybe<LeaderSetEventWhereInput>
  leaderunseteventleader_every?: InputMaybe<LeaderUnsetEventWhereInput>
  leaderunseteventleader_none?: InputMaybe<LeaderUnsetEventWhereInput>
  leaderunseteventleader_some?: InputMaybe<LeaderUnsetEventWhereInput>
  managedForumCategories_every?: InputMaybe<ForumCategoryWhereInput>
  managedForumCategories_none?: InputMaybe<ForumCategoryWhereInput>
  managedForumCategories_some?: InputMaybe<ForumCategoryWhereInput>
  membership?: InputMaybe<MembershipWhereInput>
  memberverificationstatusupdatedeventworker_every?: InputMaybe<MemberVerificationStatusUpdatedEventWhereInput>
  memberverificationstatusupdatedeventworker_none?: InputMaybe<MemberVerificationStatusUpdatedEventWhereInput>
  memberverificationstatusupdatedeventworker_some?: InputMaybe<MemberVerificationStatusUpdatedEventWhereInput>
  missingRewardAmount_eq?: InputMaybe<Scalars['BigInt']>
  missingRewardAmount_gt?: InputMaybe<Scalars['BigInt']>
  missingRewardAmount_gte?: InputMaybe<Scalars['BigInt']>
  missingRewardAmount_in?: InputMaybe<Array<Scalars['BigInt']>>
  missingRewardAmount_lt?: InputMaybe<Scalars['BigInt']>
  missingRewardAmount_lte?: InputMaybe<Scalars['BigInt']>
  newmissedrewardlevelreachedeventworker_every?: InputMaybe<NewMissedRewardLevelReachedEventWhereInput>
  newmissedrewardlevelreachedeventworker_none?: InputMaybe<NewMissedRewardLevelReachedEventWhereInput>
  newmissedrewardlevelreachedeventworker_some?: InputMaybe<NewMissedRewardLevelReachedEventWhereInput>
  payouts_every?: InputMaybe<RewardPaidEventWhereInput>
  payouts_none?: InputMaybe<RewardPaidEventWhereInput>
  payouts_some?: InputMaybe<RewardPaidEventWhereInput>
  postmoderatedeventactor_every?: InputMaybe<PostModeratedEventWhereInput>
  postmoderatedeventactor_none?: InputMaybe<PostModeratedEventWhereInput>
  postmoderatedeventactor_some?: InputMaybe<PostModeratedEventWhereInput>
  rewardAccount_contains?: InputMaybe<Scalars['String']>
  rewardAccount_endsWith?: InputMaybe<Scalars['String']>
  rewardAccount_eq?: InputMaybe<Scalars['String']>
  rewardAccount_in?: InputMaybe<Array<Scalars['String']>>
  rewardAccount_startsWith?: InputMaybe<Scalars['String']>
  rewardPerBlock_eq?: InputMaybe<Scalars['BigInt']>
  rewardPerBlock_gt?: InputMaybe<Scalars['BigInt']>
  rewardPerBlock_gte?: InputMaybe<Scalars['BigInt']>
  rewardPerBlock_in?: InputMaybe<Array<Scalars['BigInt']>>
  rewardPerBlock_lt?: InputMaybe<Scalars['BigInt']>
  rewardPerBlock_lte?: InputMaybe<Scalars['BigInt']>
  roleAccount_contains?: InputMaybe<Scalars['String']>
  roleAccount_endsWith?: InputMaybe<Scalars['String']>
  roleAccount_eq?: InputMaybe<Scalars['String']>
  roleAccount_in?: InputMaybe<Array<Scalars['String']>>
  roleAccount_startsWith?: InputMaybe<Scalars['String']>
  runtimeId_eq?: InputMaybe<Scalars['Int']>
  runtimeId_gt?: InputMaybe<Scalars['Int']>
  runtimeId_gte?: InputMaybe<Scalars['Int']>
  runtimeId_in?: InputMaybe<Array<Scalars['Int']>>
  runtimeId_lt?: InputMaybe<Scalars['Int']>
  runtimeId_lte?: InputMaybe<Scalars['Int']>
  slashes_every?: InputMaybe<StakeSlashedEventWhereInput>
  slashes_none?: InputMaybe<StakeSlashedEventWhereInput>
  slashes_some?: InputMaybe<StakeSlashedEventWhereInput>
  stakeAccount_contains?: InputMaybe<Scalars['String']>
  stakeAccount_endsWith?: InputMaybe<Scalars['String']>
  stakeAccount_eq?: InputMaybe<Scalars['String']>
  stakeAccount_in?: InputMaybe<Array<Scalars['String']>>
  stakeAccount_startsWith?: InputMaybe<Scalars['String']>
  stake_eq?: InputMaybe<Scalars['BigInt']>
  stake_gt?: InputMaybe<Scalars['BigInt']>
  stake_gte?: InputMaybe<Scalars['BigInt']>
  stake_in?: InputMaybe<Array<Scalars['BigInt']>>
  stake_lt?: InputMaybe<Scalars['BigInt']>
  stake_lte?: InputMaybe<Scalars['BigInt']>
  stakedecreasedeventworker_every?: InputMaybe<StakeDecreasedEventWhereInput>
  stakedecreasedeventworker_none?: InputMaybe<StakeDecreasedEventWhereInput>
  stakedecreasedeventworker_some?: InputMaybe<StakeDecreasedEventWhereInput>
  stakeincreasedeventworker_every?: InputMaybe<StakeIncreasedEventWhereInput>
  stakeincreasedeventworker_none?: InputMaybe<StakeIncreasedEventWhereInput>
  stakeincreasedeventworker_some?: InputMaybe<StakeIncreasedEventWhereInput>
  status_json?: InputMaybe<Scalars['JSONObject']>
  storage_contains?: InputMaybe<Scalars['String']>
  storage_endsWith?: InputMaybe<Scalars['String']>
  storage_eq?: InputMaybe<Scalars['String']>
  storage_in?: InputMaybe<Array<Scalars['String']>>
  storage_startsWith?: InputMaybe<Scalars['String']>
  terminatedleadereventworker_every?: InputMaybe<TerminatedLeaderEventWhereInput>
  terminatedleadereventworker_none?: InputMaybe<TerminatedLeaderEventWhereInput>
  terminatedleadereventworker_some?: InputMaybe<TerminatedLeaderEventWhereInput>
  terminatedworkereventworker_every?: InputMaybe<TerminatedWorkerEventWhereInput>
  terminatedworkereventworker_none?: InputMaybe<TerminatedWorkerEventWhereInput>
  terminatedworkereventworker_some?: InputMaybe<TerminatedWorkerEventWhereInput>
  threadmoderatedeventactor_every?: InputMaybe<ThreadModeratedEventWhereInput>
  threadmoderatedeventactor_none?: InputMaybe<ThreadModeratedEventWhereInput>
  threadmoderatedeventactor_some?: InputMaybe<ThreadModeratedEventWhereInput>
  threadmovedeventactor_every?: InputMaybe<ThreadMovedEventWhereInput>
  threadmovedeventactor_none?: InputMaybe<ThreadMovedEventWhereInput>
  threadmovedeventactor_some?: InputMaybe<ThreadMovedEventWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  workerexitedeventworker_every?: InputMaybe<WorkerExitedEventWhereInput>
  workerexitedeventworker_none?: InputMaybe<WorkerExitedEventWhereInput>
  workerexitedeventworker_some?: InputMaybe<WorkerExitedEventWhereInput>
  workerrewardaccountupdatedeventworker_every?: InputMaybe<WorkerRewardAccountUpdatedEventWhereInput>
  workerrewardaccountupdatedeventworker_none?: InputMaybe<WorkerRewardAccountUpdatedEventWhereInput>
  workerrewardaccountupdatedeventworker_some?: InputMaybe<WorkerRewardAccountUpdatedEventWhereInput>
  workerrewardamountupdatedeventworker_every?: InputMaybe<WorkerRewardAmountUpdatedEventWhereInput>
  workerrewardamountupdatedeventworker_none?: InputMaybe<WorkerRewardAmountUpdatedEventWhereInput>
  workerrewardamountupdatedeventworker_some?: InputMaybe<WorkerRewardAmountUpdatedEventWhereInput>
  workerroleaccountupdatedeventworker_every?: InputMaybe<WorkerRoleAccountUpdatedEventWhereInput>
  workerroleaccountupdatedeventworker_none?: InputMaybe<WorkerRoleAccountUpdatedEventWhereInput>
  workerroleaccountupdatedeventworker_some?: InputMaybe<WorkerRoleAccountUpdatedEventWhereInput>
  workerstartedleavingeventworker_every?: InputMaybe<WorkerStartedLeavingEventWhereInput>
  workerstartedleavingeventworker_none?: InputMaybe<WorkerStartedLeavingEventWhereInput>
  workerstartedleavingeventworker_some?: InputMaybe<WorkerStartedLeavingEventWhereInput>
  workinggroupleader_every?: InputMaybe<WorkingGroupWhereInput>
  workinggroupleader_none?: InputMaybe<WorkingGroupWhereInput>
  workinggroupleader_some?: InputMaybe<WorkingGroupWhereInput>
}

export type WorkerWhereUniqueInput = {
  id: Scalars['ID']
}

export type WorkingGroup = BaseGraphQlObject & {
  __typename: 'WorkingGroup'
  applicationwithdrawneventgroup?: Maybe<Array<ApplicationWithdrawnEvent>>
  appliedonopeningeventgroup?: Maybe<Array<AppliedOnOpeningEvent>>
  /** Current working group budget (JOY) */
  budget: Scalars['BigInt']
  budgetseteventgroup?: Maybe<Array<BudgetSetEvent>>
  budgetspendingeventgroup?: Maybe<Array<BudgetSpendingEvent>>
  budgetupdatedeventgroup?: Maybe<Array<BudgetUpdatedEvent>>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  leader?: Maybe<Worker>
  leaderId?: Maybe<Scalars['String']>
  leaderseteventgroup?: Maybe<Array<LeaderSetEvent>>
  leaderunseteventgroup?: Maybe<Array<LeaderUnsetEvent>>
  metadata?: Maybe<WorkingGroupMetadata>
  metadataId?: Maybe<Scalars['String']>
  /** Working group name */
  name: Scalars['String']
  newmissedrewardlevelreachedeventgroup?: Maybe<Array<NewMissedRewardLevelReachedEvent>>
  openingaddedeventgroup?: Maybe<Array<OpeningAddedEvent>>
  openingcanceledeventgroup?: Maybe<Array<OpeningCanceledEvent>>
  openingfilledeventgroup?: Maybe<Array<OpeningFilledEvent>>
  openings: Array<WorkingGroupOpening>
  rewardpaideventgroup?: Maybe<Array<RewardPaidEvent>>
  stakedecreasedeventgroup?: Maybe<Array<StakeDecreasedEvent>>
  stakeincreasedeventgroup?: Maybe<Array<StakeIncreasedEvent>>
  stakeslashedeventgroup?: Maybe<Array<StakeSlashedEvent>>
  statustextchangedeventgroup?: Maybe<Array<StatusTextChangedEvent>>
  terminatedleadereventgroup?: Maybe<Array<TerminatedLeaderEvent>>
  terminatedworkereventgroup?: Maybe<Array<TerminatedWorkerEvent>>
  upcomingworkinggroupopeninggroup?: Maybe<Array<UpcomingWorkingGroupOpening>>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  workerexitedeventgroup?: Maybe<Array<WorkerExitedEvent>>
  workerrewardaccountupdatedeventgroup?: Maybe<Array<WorkerRewardAccountUpdatedEvent>>
  workerrewardamountupdatedeventgroup?: Maybe<Array<WorkerRewardAmountUpdatedEvent>>
  workerroleaccountupdatedeventgroup?: Maybe<Array<WorkerRoleAccountUpdatedEvent>>
  workers: Array<Worker>
  workerstartedleavingeventgroup?: Maybe<Array<WorkerStartedLeavingEvent>>
  workinggroupmetadatagroup?: Maybe<Array<WorkingGroupMetadata>>
}

export type WorkingGroupApplication = BaseGraphQlObject & {
  __typename: 'WorkingGroupApplication'
  answers: Array<ApplicationFormQuestionAnswer>
  applicant: Membership
  applicantId: Scalars['String']
  applicationwithdrawneventapplication?: Maybe<Array<ApplicationWithdrawnEvent>>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  createdInEvent: AppliedOnOpeningEvent
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  opening: WorkingGroupOpening
  openingId: Scalars['String']
  /** Applicant's initial reward account */
  rewardAccount: Scalars['String']
  /** Applicant's initial role account */
  roleAccount: Scalars['String']
  /** ApplicationId in specific working group module */
  runtimeId: Scalars['Int']
  /** Application stake */
  stake: Scalars['BigInt']
  /** Applicant's initial staking account */
  stakingAccount: Scalars['String']
  /** Current application status */
  status: WorkingGroupApplicationStatus
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  workerapplication?: Maybe<Array<Worker>>
}

export type WorkingGroupApplicationConnection = {
  __typename: 'WorkingGroupApplicationConnection'
  edges: Array<WorkingGroupApplicationEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkingGroupApplicationCreateInput = {
  applicant: Scalars['ID']
  opening: Scalars['ID']
  rewardAccount: Scalars['String']
  roleAccount: Scalars['String']
  runtimeId: Scalars['Float']
  stake: Scalars['String']
  stakingAccount: Scalars['String']
  status: Scalars['JSONObject']
}

export type WorkingGroupApplicationEdge = {
  __typename: 'WorkingGroupApplicationEdge'
  cursor: Scalars['String']
  node: WorkingGroupApplication
}

export enum WorkingGroupApplicationOrderByInput {
  ApplicantAsc = 'applicant_ASC',
  ApplicantDesc = 'applicant_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  OpeningAsc = 'opening_ASC',
  OpeningDesc = 'opening_DESC',
  RewardAccountAsc = 'rewardAccount_ASC',
  RewardAccountDesc = 'rewardAccount_DESC',
  RoleAccountAsc = 'roleAccount_ASC',
  RoleAccountDesc = 'roleAccount_DESC',
  RuntimeIdAsc = 'runtimeId_ASC',
  RuntimeIdDesc = 'runtimeId_DESC',
  StakeAsc = 'stake_ASC',
  StakeDesc = 'stake_DESC',
  StakingAccountAsc = 'stakingAccount_ASC',
  StakingAccountDesc = 'stakingAccount_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type WorkingGroupApplicationStatus =
  | ApplicationStatusAccepted
  | ApplicationStatusCancelled
  | ApplicationStatusPending
  | ApplicationStatusRejected
  | ApplicationStatusWithdrawn

export type WorkingGroupApplicationUpdateInput = {
  applicant?: InputMaybe<Scalars['ID']>
  opening?: InputMaybe<Scalars['ID']>
  rewardAccount?: InputMaybe<Scalars['String']>
  roleAccount?: InputMaybe<Scalars['String']>
  runtimeId?: InputMaybe<Scalars['Float']>
  stake?: InputMaybe<Scalars['String']>
  stakingAccount?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['JSONObject']>
}

export type WorkingGroupApplicationWhereInput = {
  AND?: InputMaybe<Array<WorkingGroupApplicationWhereInput>>
  NOT?: InputMaybe<Array<WorkingGroupApplicationWhereInput>>
  OR?: InputMaybe<Array<WorkingGroupApplicationWhereInput>>
  answers_every?: InputMaybe<ApplicationFormQuestionAnswerWhereInput>
  answers_none?: InputMaybe<ApplicationFormQuestionAnswerWhereInput>
  answers_some?: InputMaybe<ApplicationFormQuestionAnswerWhereInput>
  applicant?: InputMaybe<MembershipWhereInput>
  applicationwithdrawneventapplication_every?: InputMaybe<ApplicationWithdrawnEventWhereInput>
  applicationwithdrawneventapplication_none?: InputMaybe<ApplicationWithdrawnEventWhereInput>
  applicationwithdrawneventapplication_some?: InputMaybe<ApplicationWithdrawnEventWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  createdInEvent?: InputMaybe<AppliedOnOpeningEventWhereInput>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  opening?: InputMaybe<WorkingGroupOpeningWhereInput>
  rewardAccount_contains?: InputMaybe<Scalars['String']>
  rewardAccount_endsWith?: InputMaybe<Scalars['String']>
  rewardAccount_eq?: InputMaybe<Scalars['String']>
  rewardAccount_in?: InputMaybe<Array<Scalars['String']>>
  rewardAccount_startsWith?: InputMaybe<Scalars['String']>
  roleAccount_contains?: InputMaybe<Scalars['String']>
  roleAccount_endsWith?: InputMaybe<Scalars['String']>
  roleAccount_eq?: InputMaybe<Scalars['String']>
  roleAccount_in?: InputMaybe<Array<Scalars['String']>>
  roleAccount_startsWith?: InputMaybe<Scalars['String']>
  runtimeId_eq?: InputMaybe<Scalars['Int']>
  runtimeId_gt?: InputMaybe<Scalars['Int']>
  runtimeId_gte?: InputMaybe<Scalars['Int']>
  runtimeId_in?: InputMaybe<Array<Scalars['Int']>>
  runtimeId_lt?: InputMaybe<Scalars['Int']>
  runtimeId_lte?: InputMaybe<Scalars['Int']>
  stake_eq?: InputMaybe<Scalars['BigInt']>
  stake_gt?: InputMaybe<Scalars['BigInt']>
  stake_gte?: InputMaybe<Scalars['BigInt']>
  stake_in?: InputMaybe<Array<Scalars['BigInt']>>
  stake_lt?: InputMaybe<Scalars['BigInt']>
  stake_lte?: InputMaybe<Scalars['BigInt']>
  stakingAccount_contains?: InputMaybe<Scalars['String']>
  stakingAccount_endsWith?: InputMaybe<Scalars['String']>
  stakingAccount_eq?: InputMaybe<Scalars['String']>
  stakingAccount_in?: InputMaybe<Array<Scalars['String']>>
  stakingAccount_startsWith?: InputMaybe<Scalars['String']>
  status_json?: InputMaybe<Scalars['JSONObject']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  workerapplication_every?: InputMaybe<WorkerWhereInput>
  workerapplication_none?: InputMaybe<WorkerWhereInput>
  workerapplication_some?: InputMaybe<WorkerWhereInput>
}

export type WorkingGroupApplicationWhereUniqueInput = {
  id: Scalars['ID']
}

export type WorkingGroupConnection = {
  __typename: 'WorkingGroupConnection'
  edges: Array<WorkingGroupEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkingGroupCreateInput = {
  budget: Scalars['String']
  leader?: InputMaybe<Scalars['ID']>
  metadata?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
}

export type WorkingGroupEdge = {
  __typename: 'WorkingGroupEdge'
  cursor: Scalars['String']
  node: WorkingGroup
}

export type WorkingGroupMetadata = BaseGraphQlObject & {
  __typename: 'WorkingGroupMetadata'
  /** Working group about text */
  about?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** Working group description text */
  description?: Maybe<Scalars['String']>
  group: WorkingGroup
  groupId: Scalars['String']
  id: Scalars['ID']
  setInEvent: StatusTextChangedEvent
  setInEventId: Scalars['String']
  /** Working group status */
  status?: Maybe<Scalars['String']>
  /** Working group status message */
  statusMessage?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  workinggroupmetadata?: Maybe<Array<WorkingGroup>>
}

export type WorkingGroupMetadataActionResult =
  | InvalidActionMetadata
  | UpcomingOpeningAdded
  | UpcomingOpeningRemoved
  | WorkingGroupMetadataSet

export type WorkingGroupMetadataConnection = {
  __typename: 'WorkingGroupMetadataConnection'
  edges: Array<WorkingGroupMetadataEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkingGroupMetadataCreateInput = {
  about?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  group: Scalars['ID']
  setInEvent: Scalars['ID']
  status?: InputMaybe<Scalars['String']>
  statusMessage?: InputMaybe<Scalars['String']>
}

export type WorkingGroupMetadataEdge = {
  __typename: 'WorkingGroupMetadataEdge'
  cursor: Scalars['String']
  node: WorkingGroupMetadata
}

export enum WorkingGroupMetadataOrderByInput {
  AboutAsc = 'about_ASC',
  AboutDesc = 'about_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  SetInEventAsc = 'setInEvent_ASC',
  SetInEventDesc = 'setInEvent_DESC',
  StatusMessageAsc = 'statusMessage_ASC',
  StatusMessageDesc = 'statusMessage_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type WorkingGroupMetadataSet = {
  __typename: 'WorkingGroupMetadataSet'
  /** The new metadata snapshot resulting from the update */
  metadata?: Maybe<WorkingGroupMetadata>
}

export type WorkingGroupMetadataUpdateInput = {
  about?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  group?: InputMaybe<Scalars['ID']>
  setInEvent?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<Scalars['String']>
  statusMessage?: InputMaybe<Scalars['String']>
}

export type WorkingGroupMetadataWhereInput = {
  AND?: InputMaybe<Array<WorkingGroupMetadataWhereInput>>
  NOT?: InputMaybe<Array<WorkingGroupMetadataWhereInput>>
  OR?: InputMaybe<Array<WorkingGroupMetadataWhereInput>>
  about_contains?: InputMaybe<Scalars['String']>
  about_endsWith?: InputMaybe<Scalars['String']>
  about_eq?: InputMaybe<Scalars['String']>
  about_in?: InputMaybe<Array<Scalars['String']>>
  about_startsWith?: InputMaybe<Scalars['String']>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  description_contains?: InputMaybe<Scalars['String']>
  description_endsWith?: InputMaybe<Scalars['String']>
  description_eq?: InputMaybe<Scalars['String']>
  description_in?: InputMaybe<Array<Scalars['String']>>
  description_startsWith?: InputMaybe<Scalars['String']>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  setInEvent?: InputMaybe<StatusTextChangedEventWhereInput>
  statusMessage_contains?: InputMaybe<Scalars['String']>
  statusMessage_endsWith?: InputMaybe<Scalars['String']>
  statusMessage_eq?: InputMaybe<Scalars['String']>
  statusMessage_in?: InputMaybe<Array<Scalars['String']>>
  statusMessage_startsWith?: InputMaybe<Scalars['String']>
  status_contains?: InputMaybe<Scalars['String']>
  status_endsWith?: InputMaybe<Scalars['String']>
  status_eq?: InputMaybe<Scalars['String']>
  status_in?: InputMaybe<Array<Scalars['String']>>
  status_startsWith?: InputMaybe<Scalars['String']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  workinggroupmetadata_every?: InputMaybe<WorkingGroupWhereInput>
  workinggroupmetadata_none?: InputMaybe<WorkingGroupWhereInput>
  workinggroupmetadata_some?: InputMaybe<WorkingGroupWhereInput>
}

export type WorkingGroupMetadataWhereUniqueInput = {
  id: Scalars['ID']
}

export type WorkingGroupOpening = BaseGraphQlObject & {
  __typename: 'WorkingGroupOpening'
  applications: Array<WorkingGroupApplication>
  appliedonopeningeventopening?: Maybe<Array<AppliedOnOpeningEvent>>
  /** Time of opening creation */
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  createdInEvent: OpeningAddedEvent
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  group: WorkingGroup
  groupId: Scalars['String']
  id: Scalars['ID']
  metadata: WorkingGroupOpeningMetadata
  metadataId: Scalars['String']
  openingcanceledeventopening?: Maybe<Array<OpeningCanceledEvent>>
  openingfilledeventopening?: Maybe<Array<OpeningFilledEvent>>
  /** Initial workers' reward per block */
  rewardPerBlock: Scalars['BigInt']
  /** OpeningId in specific working group module */
  runtimeId: Scalars['Int']
  /** Min. application/role stake amount */
  stakeAmount: Scalars['BigInt']
  /** Current opening status */
  status: WorkingGroupOpeningStatus
  /** Type of the opening (Leader/Regular) */
  type: WorkingGroupOpeningType
  /** Role stake unstaking period in blocks */
  unstakingPeriod: Scalars['Int']
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
}

export type WorkingGroupOpeningConnection = {
  __typename: 'WorkingGroupOpeningConnection'
  edges: Array<WorkingGroupOpeningEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkingGroupOpeningCreateInput = {
  createdAt: Scalars['DateTime']
  group: Scalars['ID']
  metadata: Scalars['ID']
  rewardPerBlock: Scalars['String']
  runtimeId: Scalars['Float']
  stakeAmount: Scalars['String']
  status: Scalars['JSONObject']
  type: WorkingGroupOpeningType
  unstakingPeriod: Scalars['Float']
}

export type WorkingGroupOpeningEdge = {
  __typename: 'WorkingGroupOpeningEdge'
  cursor: Scalars['String']
  node: WorkingGroupOpening
}

export type WorkingGroupOpeningMetadata = BaseGraphQlObject & {
  __typename: 'WorkingGroupOpeningMetadata'
  /** Md-formatted text explaining the application process */
  applicationDetails?: Maybe<Scalars['String']>
  applicationFormQuestions: Array<ApplicationFormQuestion>
  createdAt: Scalars['DateTime']
  createdById: Scalars['ID']
  deletedAt?: Maybe<Scalars['DateTime']>
  deletedById?: Maybe<Scalars['ID']>
  /** Opening description (md-formatted) */
  description?: Maybe<Scalars['String']>
  /** Expected time when the opening will close */
  expectedEnding?: Maybe<Scalars['DateTime']>
  /** Expected max. number of applicants that will be hired */
  hiringLimit?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  /** Whether the originally provided metadata was valid */
  originallyValid: Scalars['Boolean']
  /** Opening short description */
  shortDescription?: Maybe<Scalars['String']>
  /** Opening title */
  title?: Maybe<Scalars['String']>
  upcomingworkinggroupopeningmetadata?: Maybe<Array<UpcomingWorkingGroupOpening>>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedById?: Maybe<Scalars['ID']>
  version: Scalars['Int']
  workinggroupopeningmetadata?: Maybe<Array<WorkingGroupOpening>>
}

export type WorkingGroupOpeningMetadataConnection = {
  __typename: 'WorkingGroupOpeningMetadataConnection'
  edges: Array<WorkingGroupOpeningMetadataEdge>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

export type WorkingGroupOpeningMetadataCreateInput = {
  applicationDetails?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  expectedEnding?: InputMaybe<Scalars['DateTime']>
  hiringLimit?: InputMaybe<Scalars['Float']>
  originallyValid: Scalars['Boolean']
  shortDescription?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type WorkingGroupOpeningMetadataEdge = {
  __typename: 'WorkingGroupOpeningMetadataEdge'
  cursor: Scalars['String']
  node: WorkingGroupOpeningMetadata
}

export enum WorkingGroupOpeningMetadataOrderByInput {
  ApplicationDetailsAsc = 'applicationDetails_ASC',
  ApplicationDetailsDesc = 'applicationDetails_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExpectedEndingAsc = 'expectedEnding_ASC',
  ExpectedEndingDesc = 'expectedEnding_DESC',
  HiringLimitAsc = 'hiringLimit_ASC',
  HiringLimitDesc = 'hiringLimit_DESC',
  OriginallyValidAsc = 'originallyValid_ASC',
  OriginallyValidDesc = 'originallyValid_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type WorkingGroupOpeningMetadataUpdateInput = {
  applicationDetails?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  expectedEnding?: InputMaybe<Scalars['DateTime']>
  hiringLimit?: InputMaybe<Scalars['Float']>
  originallyValid?: InputMaybe<Scalars['Boolean']>
  shortDescription?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type WorkingGroupOpeningMetadataWhereInput = {
  AND?: InputMaybe<Array<WorkingGroupOpeningMetadataWhereInput>>
  NOT?: InputMaybe<Array<WorkingGroupOpeningMetadataWhereInput>>
  OR?: InputMaybe<Array<WorkingGroupOpeningMetadataWhereInput>>
  applicationDetails_contains?: InputMaybe<Scalars['String']>
  applicationDetails_endsWith?: InputMaybe<Scalars['String']>
  applicationDetails_eq?: InputMaybe<Scalars['String']>
  applicationDetails_in?: InputMaybe<Array<Scalars['String']>>
  applicationDetails_startsWith?: InputMaybe<Scalars['String']>
  applicationFormQuestions_every?: InputMaybe<ApplicationFormQuestionWhereInput>
  applicationFormQuestions_none?: InputMaybe<ApplicationFormQuestionWhereInput>
  applicationFormQuestions_some?: InputMaybe<ApplicationFormQuestionWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  description_contains?: InputMaybe<Scalars['String']>
  description_endsWith?: InputMaybe<Scalars['String']>
  description_eq?: InputMaybe<Scalars['String']>
  description_in?: InputMaybe<Array<Scalars['String']>>
  description_startsWith?: InputMaybe<Scalars['String']>
  expectedEnding_eq?: InputMaybe<Scalars['DateTime']>
  expectedEnding_gt?: InputMaybe<Scalars['DateTime']>
  expectedEnding_gte?: InputMaybe<Scalars['DateTime']>
  expectedEnding_lt?: InputMaybe<Scalars['DateTime']>
  expectedEnding_lte?: InputMaybe<Scalars['DateTime']>
  hiringLimit_eq?: InputMaybe<Scalars['Int']>
  hiringLimit_gt?: InputMaybe<Scalars['Int']>
  hiringLimit_gte?: InputMaybe<Scalars['Int']>
  hiringLimit_in?: InputMaybe<Array<Scalars['Int']>>
  hiringLimit_lt?: InputMaybe<Scalars['Int']>
  hiringLimit_lte?: InputMaybe<Scalars['Int']>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  originallyValid_eq?: InputMaybe<Scalars['Boolean']>
  originallyValid_in?: InputMaybe<Array<Scalars['Boolean']>>
  shortDescription_contains?: InputMaybe<Scalars['String']>
  shortDescription_endsWith?: InputMaybe<Scalars['String']>
  shortDescription_eq?: InputMaybe<Scalars['String']>
  shortDescription_in?: InputMaybe<Array<Scalars['String']>>
  shortDescription_startsWith?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_endsWith?: InputMaybe<Scalars['String']>
  title_eq?: InputMaybe<Scalars['String']>
  title_in?: InputMaybe<Array<Scalars['String']>>
  title_startsWith?: InputMaybe<Scalars['String']>
  upcomingworkinggroupopeningmetadata_every?: InputMaybe<UpcomingWorkingGroupOpeningWhereInput>
  upcomingworkinggroupopeningmetadata_none?: InputMaybe<UpcomingWorkingGroupOpeningWhereInput>
  upcomingworkinggroupopeningmetadata_some?: InputMaybe<UpcomingWorkingGroupOpeningWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  workinggroupopeningmetadata_every?: InputMaybe<WorkingGroupOpeningWhereInput>
  workinggroupopeningmetadata_none?: InputMaybe<WorkingGroupOpeningWhereInput>
  workinggroupopeningmetadata_some?: InputMaybe<WorkingGroupOpeningWhereInput>
}

export type WorkingGroupOpeningMetadataWhereUniqueInput = {
  id: Scalars['ID']
}

export enum WorkingGroupOpeningOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  RewardPerBlockAsc = 'rewardPerBlock_ASC',
  RewardPerBlockDesc = 'rewardPerBlock_DESC',
  RuntimeIdAsc = 'runtimeId_ASC',
  RuntimeIdDesc = 'runtimeId_DESC',
  StakeAmountAsc = 'stakeAmount_ASC',
  StakeAmountDesc = 'stakeAmount_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UnstakingPeriodAsc = 'unstakingPeriod_ASC',
  UnstakingPeriodDesc = 'unstakingPeriod_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type WorkingGroupOpeningStatus = OpeningStatusCancelled | OpeningStatusFilled | OpeningStatusOpen

export enum WorkingGroupOpeningType {
  Leader = 'LEADER',
  Regular = 'REGULAR',
}

export type WorkingGroupOpeningUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>
  group?: InputMaybe<Scalars['ID']>
  metadata?: InputMaybe<Scalars['ID']>
  rewardPerBlock?: InputMaybe<Scalars['String']>
  runtimeId?: InputMaybe<Scalars['Float']>
  stakeAmount?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['JSONObject']>
  type?: InputMaybe<WorkingGroupOpeningType>
  unstakingPeriod?: InputMaybe<Scalars['Float']>
}

export type WorkingGroupOpeningWhereInput = {
  AND?: InputMaybe<Array<WorkingGroupOpeningWhereInput>>
  NOT?: InputMaybe<Array<WorkingGroupOpeningWhereInput>>
  OR?: InputMaybe<Array<WorkingGroupOpeningWhereInput>>
  applications_every?: InputMaybe<WorkingGroupApplicationWhereInput>
  applications_none?: InputMaybe<WorkingGroupApplicationWhereInput>
  applications_some?: InputMaybe<WorkingGroupApplicationWhereInput>
  appliedonopeningeventopening_every?: InputMaybe<AppliedOnOpeningEventWhereInput>
  appliedonopeningeventopening_none?: InputMaybe<AppliedOnOpeningEventWhereInput>
  appliedonopeningeventopening_some?: InputMaybe<AppliedOnOpeningEventWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  createdInEvent?: InputMaybe<OpeningAddedEventWhereInput>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  group?: InputMaybe<WorkingGroupWhereInput>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  metadata?: InputMaybe<WorkingGroupOpeningMetadataWhereInput>
  openingcanceledeventopening_every?: InputMaybe<OpeningCanceledEventWhereInput>
  openingcanceledeventopening_none?: InputMaybe<OpeningCanceledEventWhereInput>
  openingcanceledeventopening_some?: InputMaybe<OpeningCanceledEventWhereInput>
  openingfilledeventopening_every?: InputMaybe<OpeningFilledEventWhereInput>
  openingfilledeventopening_none?: InputMaybe<OpeningFilledEventWhereInput>
  openingfilledeventopening_some?: InputMaybe<OpeningFilledEventWhereInput>
  rewardPerBlock_eq?: InputMaybe<Scalars['BigInt']>
  rewardPerBlock_gt?: InputMaybe<Scalars['BigInt']>
  rewardPerBlock_gte?: InputMaybe<Scalars['BigInt']>
  rewardPerBlock_in?: InputMaybe<Array<Scalars['BigInt']>>
  rewardPerBlock_lt?: InputMaybe<Scalars['BigInt']>
  rewardPerBlock_lte?: InputMaybe<Scalars['BigInt']>
  runtimeId_eq?: InputMaybe<Scalars['Int']>
  runtimeId_gt?: InputMaybe<Scalars['Int']>
  runtimeId_gte?: InputMaybe<Scalars['Int']>
  runtimeId_in?: InputMaybe<Array<Scalars['Int']>>
  runtimeId_lt?: InputMaybe<Scalars['Int']>
  runtimeId_lte?: InputMaybe<Scalars['Int']>
  stakeAmount_eq?: InputMaybe<Scalars['BigInt']>
  stakeAmount_gt?: InputMaybe<Scalars['BigInt']>
  stakeAmount_gte?: InputMaybe<Scalars['BigInt']>
  stakeAmount_in?: InputMaybe<Array<Scalars['BigInt']>>
  stakeAmount_lt?: InputMaybe<Scalars['BigInt']>
  stakeAmount_lte?: InputMaybe<Scalars['BigInt']>
  status_json?: InputMaybe<Scalars['JSONObject']>
  type_eq?: InputMaybe<WorkingGroupOpeningType>
  type_in?: InputMaybe<Array<WorkingGroupOpeningType>>
  unstakingPeriod_eq?: InputMaybe<Scalars['Int']>
  unstakingPeriod_gt?: InputMaybe<Scalars['Int']>
  unstakingPeriod_gte?: InputMaybe<Scalars['Int']>
  unstakingPeriod_in?: InputMaybe<Array<Scalars['Int']>>
  unstakingPeriod_lt?: InputMaybe<Scalars['Int']>
  unstakingPeriod_lte?: InputMaybe<Scalars['Int']>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
}

export type WorkingGroupOpeningWhereUniqueInput = {
  id: Scalars['ID']
}

export enum WorkingGroupOrderByInput {
  BudgetAsc = 'budget_ASC',
  BudgetDesc = 'budget_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  LeaderAsc = 'leader_ASC',
  LeaderDesc = 'leader_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type WorkingGroupUpdateInput = {
  budget?: InputMaybe<Scalars['String']>
  leader?: InputMaybe<Scalars['ID']>
  metadata?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
}

export type WorkingGroupWhereInput = {
  AND?: InputMaybe<Array<WorkingGroupWhereInput>>
  NOT?: InputMaybe<Array<WorkingGroupWhereInput>>
  OR?: InputMaybe<Array<WorkingGroupWhereInput>>
  applicationwithdrawneventgroup_every?: InputMaybe<ApplicationWithdrawnEventWhereInput>
  applicationwithdrawneventgroup_none?: InputMaybe<ApplicationWithdrawnEventWhereInput>
  applicationwithdrawneventgroup_some?: InputMaybe<ApplicationWithdrawnEventWhereInput>
  appliedonopeningeventgroup_every?: InputMaybe<AppliedOnOpeningEventWhereInput>
  appliedonopeningeventgroup_none?: InputMaybe<AppliedOnOpeningEventWhereInput>
  appliedonopeningeventgroup_some?: InputMaybe<AppliedOnOpeningEventWhereInput>
  budget_eq?: InputMaybe<Scalars['BigInt']>
  budget_gt?: InputMaybe<Scalars['BigInt']>
  budget_gte?: InputMaybe<Scalars['BigInt']>
  budget_in?: InputMaybe<Array<Scalars['BigInt']>>
  budget_lt?: InputMaybe<Scalars['BigInt']>
  budget_lte?: InputMaybe<Scalars['BigInt']>
  budgetseteventgroup_every?: InputMaybe<BudgetSetEventWhereInput>
  budgetseteventgroup_none?: InputMaybe<BudgetSetEventWhereInput>
  budgetseteventgroup_some?: InputMaybe<BudgetSetEventWhereInput>
  budgetspendingeventgroup_every?: InputMaybe<BudgetSpendingEventWhereInput>
  budgetspendingeventgroup_none?: InputMaybe<BudgetSpendingEventWhereInput>
  budgetspendingeventgroup_some?: InputMaybe<BudgetSpendingEventWhereInput>
  budgetupdatedeventgroup_every?: InputMaybe<BudgetUpdatedEventWhereInput>
  budgetupdatedeventgroup_none?: InputMaybe<BudgetUpdatedEventWhereInput>
  budgetupdatedeventgroup_some?: InputMaybe<BudgetUpdatedEventWhereInput>
  createdAt_eq?: InputMaybe<Scalars['DateTime']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']>
  createdAt_lt?: InputMaybe<Scalars['DateTime']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']>
  createdById_eq?: InputMaybe<Scalars['ID']>
  createdById_in?: InputMaybe<Array<Scalars['ID']>>
  deletedAt_all?: InputMaybe<Scalars['Boolean']>
  deletedAt_eq?: InputMaybe<Scalars['DateTime']>
  deletedAt_gt?: InputMaybe<Scalars['DateTime']>
  deletedAt_gte?: InputMaybe<Scalars['DateTime']>
  deletedAt_lt?: InputMaybe<Scalars['DateTime']>
  deletedAt_lte?: InputMaybe<Scalars['DateTime']>
  deletedById_eq?: InputMaybe<Scalars['ID']>
  deletedById_in?: InputMaybe<Array<Scalars['ID']>>
  id_eq?: InputMaybe<Scalars['ID']>
  id_in?: InputMaybe<Array<Scalars['ID']>>
  leader?: InputMaybe<WorkerWhereInput>
  leaderseteventgroup_every?: InputMaybe<LeaderSetEventWhereInput>
  leaderseteventgroup_none?: InputMaybe<LeaderSetEventWhereInput>
  leaderseteventgroup_some?: InputMaybe<LeaderSetEventWhereInput>
  leaderunseteventgroup_every?: InputMaybe<LeaderUnsetEventWhereInput>
  leaderunseteventgroup_none?: InputMaybe<LeaderUnsetEventWhereInput>
  leaderunseteventgroup_some?: InputMaybe<LeaderUnsetEventWhereInput>
  metadata?: InputMaybe<WorkingGroupMetadataWhereInput>
  name_contains?: InputMaybe<Scalars['String']>
  name_endsWith?: InputMaybe<Scalars['String']>
  name_eq?: InputMaybe<Scalars['String']>
  name_in?: InputMaybe<Array<Scalars['String']>>
  name_startsWith?: InputMaybe<Scalars['String']>
  newmissedrewardlevelreachedeventgroup_every?: InputMaybe<NewMissedRewardLevelReachedEventWhereInput>
  newmissedrewardlevelreachedeventgroup_none?: InputMaybe<NewMissedRewardLevelReachedEventWhereInput>
  newmissedrewardlevelreachedeventgroup_some?: InputMaybe<NewMissedRewardLevelReachedEventWhereInput>
  openingaddedeventgroup_every?: InputMaybe<OpeningAddedEventWhereInput>
  openingaddedeventgroup_none?: InputMaybe<OpeningAddedEventWhereInput>
  openingaddedeventgroup_some?: InputMaybe<OpeningAddedEventWhereInput>
  openingcanceledeventgroup_every?: InputMaybe<OpeningCanceledEventWhereInput>
  openingcanceledeventgroup_none?: InputMaybe<OpeningCanceledEventWhereInput>
  openingcanceledeventgroup_some?: InputMaybe<OpeningCanceledEventWhereInput>
  openingfilledeventgroup_every?: InputMaybe<OpeningFilledEventWhereInput>
  openingfilledeventgroup_none?: InputMaybe<OpeningFilledEventWhereInput>
  openingfilledeventgroup_some?: InputMaybe<OpeningFilledEventWhereInput>
  openings_every?: InputMaybe<WorkingGroupOpeningWhereInput>
  openings_none?: InputMaybe<WorkingGroupOpeningWhereInput>
  openings_some?: InputMaybe<WorkingGroupOpeningWhereInput>
  rewardpaideventgroup_every?: InputMaybe<RewardPaidEventWhereInput>
  rewardpaideventgroup_none?: InputMaybe<RewardPaidEventWhereInput>
  rewardpaideventgroup_some?: InputMaybe<RewardPaidEventWhereInput>
  stakedecreasedeventgroup_every?: InputMaybe<StakeDecreasedEventWhereInput>
  stakedecreasedeventgroup_none?: InputMaybe<StakeDecreasedEventWhereInput>
  stakedecreasedeventgroup_some?: InputMaybe<StakeDecreasedEventWhereInput>
  stakeincreasedeventgroup_every?: InputMaybe<StakeIncreasedEventWhereInput>
  stakeincreasedeventgroup_none?: InputMaybe<StakeIncreasedEventWhereInput>
  stakeincreasedeventgroup_some?: InputMaybe<StakeIncreasedEventWhereInput>
  stakeslashedeventgroup_every?: InputMaybe<StakeSlashedEventWhereInput>
  stakeslashedeventgroup_none?: InputMaybe<StakeSlashedEventWhereInput>
  stakeslashedeventgroup_some?: InputMaybe<StakeSlashedEventWhereInput>
  statustextchangedeventgroup_every?: InputMaybe<StatusTextChangedEventWhereInput>
  statustextchangedeventgroup_none?: InputMaybe<StatusTextChangedEventWhereInput>
  statustextchangedeventgroup_some?: InputMaybe<StatusTextChangedEventWhereInput>
  terminatedleadereventgroup_every?: InputMaybe<TerminatedLeaderEventWhereInput>
  terminatedleadereventgroup_none?: InputMaybe<TerminatedLeaderEventWhereInput>
  terminatedleadereventgroup_some?: InputMaybe<TerminatedLeaderEventWhereInput>
  terminatedworkereventgroup_every?: InputMaybe<TerminatedWorkerEventWhereInput>
  terminatedworkereventgroup_none?: InputMaybe<TerminatedWorkerEventWhereInput>
  terminatedworkereventgroup_some?: InputMaybe<TerminatedWorkerEventWhereInput>
  upcomingworkinggroupopeninggroup_every?: InputMaybe<UpcomingWorkingGroupOpeningWhereInput>
  upcomingworkinggroupopeninggroup_none?: InputMaybe<UpcomingWorkingGroupOpeningWhereInput>
  upcomingworkinggroupopeninggroup_some?: InputMaybe<UpcomingWorkingGroupOpeningWhereInput>
  updatedAt_eq?: InputMaybe<Scalars['DateTime']>
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>
  updatedById_eq?: InputMaybe<Scalars['ID']>
  updatedById_in?: InputMaybe<Array<Scalars['ID']>>
  workerexitedeventgroup_every?: InputMaybe<WorkerExitedEventWhereInput>
  workerexitedeventgroup_none?: InputMaybe<WorkerExitedEventWhereInput>
  workerexitedeventgroup_some?: InputMaybe<WorkerExitedEventWhereInput>
  workerrewardaccountupdatedeventgroup_every?: InputMaybe<WorkerRewardAccountUpdatedEventWhereInput>
  workerrewardaccountupdatedeventgroup_none?: InputMaybe<WorkerRewardAccountUpdatedEventWhereInput>
  workerrewardaccountupdatedeventgroup_some?: InputMaybe<WorkerRewardAccountUpdatedEventWhereInput>
  workerrewardamountupdatedeventgroup_every?: InputMaybe<WorkerRewardAmountUpdatedEventWhereInput>
  workerrewardamountupdatedeventgroup_none?: InputMaybe<WorkerRewardAmountUpdatedEventWhereInput>
  workerrewardamountupdatedeventgroup_some?: InputMaybe<WorkerRewardAmountUpdatedEventWhereInput>
  workerroleaccountupdatedeventgroup_every?: InputMaybe<WorkerRoleAccountUpdatedEventWhereInput>
  workerroleaccountupdatedeventgroup_none?: InputMaybe<WorkerRoleAccountUpdatedEventWhereInput>
  workerroleaccountupdatedeventgroup_some?: InputMaybe<WorkerRoleAccountUpdatedEventWhereInput>
  workers_every?: InputMaybe<WorkerWhereInput>
  workers_none?: InputMaybe<WorkerWhereInput>
  workers_some?: InputMaybe<WorkerWhereInput>
  workerstartedleavingeventgroup_every?: InputMaybe<WorkerStartedLeavingEventWhereInput>
  workerstartedleavingeventgroup_none?: InputMaybe<WorkerStartedLeavingEventWhereInput>
  workerstartedleavingeventgroup_some?: InputMaybe<WorkerStartedLeavingEventWhereInput>
  workinggroupmetadatagroup_every?: InputMaybe<WorkingGroupMetadataWhereInput>
  workinggroupmetadatagroup_none?: InputMaybe<WorkingGroupMetadataWhereInput>
  workinggroupmetadatagroup_some?: InputMaybe<WorkingGroupMetadataWhereInput>
}

export type WorkingGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
}
