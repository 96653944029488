import React from 'react'

import { Icon } from '../../../icons'

export const CouncilIcon = () => (
  <Icon
    size="20"
    viewBox="0 0 20 20"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    color="currentColor"
    className="nav-icon"
  >
    <path
      d="M5.86643 9.77023L6.58037 9.25H13.4194L14.1334 9.77023L16.5472 17.2702L15.1193 17.7298C10.8815 18.5802 6.52769 18.0841 4.88051 17.7298L3.45264 17.2702L5.86643 9.77023Z"
      fill="currentColor"
    />
    <path
      d="M12.242 3.06335C12.242 3.81426 11.8689 4.47849 11.297 4.8826C11.908 5.12052 12.3608 5.52224 12.6796 5.98388C13.1733 6.69868 13.3334 7.53397 13.3334 8.08097V8.4074L13.0399 8.55338C12.5952 8.77455 11.4306 9.16665 10.0001 9.16665C8.56957 9.16665 7.40496 8.77455 6.96026 8.55338L6.66675 8.4074V8.08097C6.66675 7.53397 6.82687 6.69868 7.32055 5.98388C7.63938 5.52222 8.09221 5.12048 8.70321 4.88257C8.13135 4.47846 7.75825 3.81424 7.75825 3.06335C7.75825 1.83173 8.76198 0.833313 10.0001 0.833313C11.2383 0.833313 12.242 1.83173 12.242 3.06335Z"
      fill="currentColor"
    />
    <path
      d="M5.57528 7.23004C5.57528 7.98095 5.20216 8.64518 4.63028 9.04929C5.24124 9.2872 5.69404 9.68893 6.01287 10.1506C6.50654 10.8654 6.66667 11.7007 6.66667 12.2477V12.5741L6.37315 12.7201C5.92846 12.9412 4.76384 13.3333 3.33333 13.3333C1.90282 13.3333 0.738211 12.9412 0.293515 12.7201L0 12.5741V12.2477C0 11.7007 0.160124 10.8654 0.653797 10.1506C0.972636 9.68891 1.42546 9.28717 2.03646 9.04926C1.4646 8.64515 1.0915 7.98093 1.0915 7.23004C1.0915 5.99842 2.09523 5 3.33339 5C4.57155 5 5.57528 5.99842 5.57528 7.23004Z"
      fill="currentColor"
    />
    <path
      d="M18.9085 7.23004C18.9085 7.98095 18.5354 8.64518 17.9635 9.04929C18.5745 9.2872 19.0273 9.68893 19.3461 10.1506C19.8398 10.8654 19.9999 11.7007 19.9999 12.2477V12.5741L19.7064 12.7201C19.2617 12.9412 18.0971 13.3333 16.6666 13.3333C15.2361 13.3333 14.0715 12.9412 13.6268 12.7201L13.3333 12.5741V12.2477C13.3333 11.7007 13.4934 10.8654 13.987 10.1506C14.3059 9.68891 14.7587 9.28717 15.3697 9.04926C14.7979 8.64515 14.4248 7.98093 14.4248 7.23004C14.4248 5.99842 15.4285 5 16.6666 5C17.9048 5 18.9085 5.99842 18.9085 7.23004Z"
      fill="currentColor"
    />
  </Icon>
)
