import React from 'react'

import { Icon, StyledIcon } from '../Icon'

export const AppliedIcon = ({ className }: StyledIcon) => (
  <Icon size="20" viewBox="0 0 20 20" fill="none" color="currentColor" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 1.54165L3.20833 0.833313H16.5417L17.25 1.54165V18.2083L16.5417 18.9166H3.20833L2.5 18.2083V1.54165ZM14.0417 12.3751V10.7084H5.70841V12.3751H14.0417ZM14.0417 15.7084V14.0417H5.70841V15.7084H14.0417ZM9.15258 5.36404C9.15258 5.78468 8.95049 6.15945 8.63553 6.40164C9.00043 6.54855 9.27368 6.78804 9.46769 7.06251C9.77563 7.49816 9.87508 8.00573 9.87508 8.33925V8.5691L9.66356 8.67189C9.3856 8.80696 8.6696 9.04173 7.79175 9.04173C6.91389 9.04173 6.1979 8.80696 5.91994 8.67189L5.70841 8.5691V8.33925C5.70841 8.00573 5.80787 7.49816 6.11581 7.06251C6.30989 6.78794 6.58326 6.54838 6.94835 6.40148C6.6335 6.15929 6.43149 5.7846 6.43149 5.36404C6.43149 4.63375 7.04063 4.04173 7.79204 4.04173C8.54345 4.04173 9.15258 4.63375 9.15258 5.36404Z"
      fill="currentColor"
    />
  </Icon>
)
