import React from 'react'
import styled from 'styled-components'

import { Colors } from '../../constants'

import { Icon } from './Icon'

export function WaitingIcon() {
  return (
    <WaitIcon width="132" height="108" viewBox="0 0 132 108" fill="none">
      <rect x="11" width="108" height="108" rx="54" fill={Colors.Black[700]} />
      <path
        d="M67.9276 79.837C65.9311 79.8425 63.9346 79.8468 61.9381 79.85C61.2671 79.8511 60.5961 79.852 59.9252 79.8524C58.7093 79.8533 57.8901 79.6044 57.6177 78.2404C57.3874 77.0871 55.7878 77.2421 55.4769 78.2404C54.7088 80.7065 53.4383 79.7403 52.7266 78.0814C52.0166 76.4265 51.8346 74.5743 51.5245 72.8131C51.2966 71.5189 49.3775 71.8817 49.344 73.1141C49.2999 74.737 49.1513 76.3341 48.7474 77.9081C48.3308 79.5321 47.7063 81.0928 47.0864 82.6445C46.5461 83.9972 48.6949 84.5791 49.2272 83.2465C49.7765 81.8716 50.3265 80.4892 50.7359 79.0629C51.4967 80.9287 53.0706 83.0391 55.3134 81.7954C55.7306 81.564 56.1203 81.235 56.4624 80.8514C57.6287 82.226 59.3647 82.1177 60.9891 82.1157C63.302 82.1127 65.615 82.1075 67.9279 82.1014C69.3561 82.0976 69.3587 79.8332 67.9276 79.837Z"
        fill={Colors.White}
      />
      <path d="M38.0703 104L50.4233 91.6268" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M34.1177 104L46.4706 91.6268" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M42.0234 104L54.3764 91.6268" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M45.9766 104L58.3295 91.6268" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M49.9297 104L62.2826 91.6268" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M53.8828 104L66.2358 91.6268" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M57.835 104L70.1879 91.6268" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M61.7881 104L74.141 91.6268" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M65.7412 104L78.0942 91.6268" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M69.6943 104L82.0473 91.6268" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M73.647 104L85.9999 91.6268" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M77.6001 104L89.953 91.6268" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M81.5527 104L93.9057 91.6268" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M15.8359 12.439L5.68408 22.6074" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M11.8824 12.439L2 22.3375" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M17.1096 15.1222L9.54346 22.7007" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path d="M18.8 17.3882L13.3647 22.8324" stroke={Colors.Blue[500]} strokeWidth="0.5" />
      <path
        d="M10.8941 12.439C4.17412 12.439 2.16471 19.038 2 22.3375H19.7882M10.8941 12.439C17.6141 12.439 54.2118 12.439 71.6706 12.439C74.3059 12.6039 79.5765 14.8146 79.5765 22.3375C79.5765 24.6393 79.5765 30.1197 79.5765 37.1852M10.8941 12.439C18.0094 12.439 19.7882 19.038 19.7882 22.3375M79.5765 91.132H93.9059C93.2471 95.4213 89.4588 104 79.5765 104C71.2504 104 46.4394 104 29.6706 104M79.5765 91.132C79.5765 84.817 79.5765 77.2469 79.5765 68.3261M79.5765 91.132H40.5412C40.5412 102.614 32.6353 104 29.6706 104M19.7882 22.3375V45.5989C19.7882 45.5989 19.7882 55.2515 19.7882 61.4365C19.7882 71.8736 19.7882 88.1624 19.7882 88.1624V91.132C19.7882 101.426 26.3765 104 29.6706 104M32.6353 28.7715H67.2235M32.6353 40.1547H67.2235M32.6353 51.0431H67.2235M32.6353 62.4263H53.3882M79.5765 37.1852L75.6235 44.1141L77.1059 55.0025L72.6588 51.0431C69.5294 55.4974 65.1482 65.7918 72.6588 71.335M79.5765 37.1852L91.4353 16.8933C96.0471 9.46943 109.816 -2.60673 128 8.47958C119.699 19.5659 98.1882 47.7436 88.4706 60.4466C86.6588 63.0862 80.96 68.9593 72.6588 71.335M72.6588 71.335C69.4965 77.2741 68.0471 80.4086 67.7176 81.2335L72.6588 71.335ZM72.6588 71.335C83.5294 55.0025 106.654 21.0507 112.188 15.9034"
        stroke={Colors.Blue[500]}
        strokeWidth="3"
      />
    </WaitIcon>
  )
}

const WaitIcon = styled(Icon)`
  margin-bottom: 8px;
`
