import React from 'react'

export const TelegramIcon = ({ className }: { className?: string }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" className={className}>
      <g clipPath="url(#clip0_9559_15936)">
        <path
          d="M24.2844 2.41427C24.2651 2.3257 24.2227 2.24383 24.1614 2.17703C24.1001 2.11022 24.0222 2.06088 23.9356 2.03402C23.6205 1.97187 23.2945 1.99517 22.9914 2.10152C22.9914 2.10152 1.96365 9.65927 0.7629 10.4963C0.5034 10.6763 0.41715 10.7805 0.3744 10.9043C0.16665 11.4998 0.81315 11.7623 0.81315 11.7623L6.23265 13.5285C6.32419 13.5443 6.41816 13.5387 6.50715 13.512C7.74015 12.7335 18.9114 5.67902 19.5571 5.44202C19.6584 5.41127 19.7334 5.44202 19.7169 5.51702C19.4529 6.42152 9.80565 14.9948 9.7524 15.0473C9.72651 15.0685 9.70617 15.0957 9.69311 15.1265C9.68004 15.1574 9.67465 15.1909 9.6774 15.2243L9.1734 20.5125C9.1734 20.5125 8.9619 22.1625 10.6089 20.5125C11.7766 19.3433 12.8971 18.3758 13.4589 17.9055C15.3226 19.1918 17.3281 20.6145 18.1929 21.3555C18.338 21.4965 18.51 21.6067 18.6987 21.6796C18.8873 21.7526 19.0887 21.7867 19.2909 21.78C20.1159 21.7485 20.3409 20.8463 20.3409 20.8463C20.3409 20.8463 24.1719 5.43002 24.3009 3.36377C24.3129 3.16127 24.3301 3.03152 24.3316 2.89277C24.3385 2.73184 24.3226 2.57075 24.2844 2.41427Z"
          fill="#C4CAD6"
        />
      </g>
      <defs>
        <clipPath id="clip0_9559_15936">
          <rect width="24" height="24" fill="white" transform="translate(0.333252)" />
        </clipPath>
      </defs>
    </svg>
  )
}
