import React from 'react'

import { Icon } from '../../../icons'

export const FinancialsIcon = () => (
  <Icon
    size="20"
    viewBox="0 0 20 20"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    color="currentColor"
    className="nav-icon"
  >
    <path
      d="M19.4959 11.5698H13.142C12.8643 11.5698 12.6379 11.7962 12.6379 12.0738C12.6379 12.2152 12.698 12.3426 12.7918 12.4345L17.2658 16.9086C17.3577 17.0076 17.4905 17.0696 17.6355 17.0696C17.8106 17.0696 17.9644 16.9812 18.0546 16.8468C19.1546 15.5699 19.8619 13.9483 19.9893 12.164C19.9964 12.1339 19.9999 12.1039 19.9999 12.0738C20 11.7962 19.7753 11.5698 19.4959 11.5698Z"
      fill="currentColor"
    />
    <path
      d="M11.2232 2.43604C10.9455 2.43773 10.7209 2.66239 10.7209 2.94002V10.0013C10.7209 10.2808 10.9455 10.5071 11.2232 10.5071H18.2863C18.564 10.5071 18.7903 10.2808 18.7903 10.0013C18.5409 5.93757 15.2887 2.68543 11.2232 2.43604Z"
      fill="currentColor"
    />
    <path
      d="M15.1049 16.4966L9.89515 11.2868C9.80676 11.1966 9.75373 11.0711 9.75373 10.9349L9.75187 3.57645C9.75187 3.29882 9.52732 3.07422 9.24963 3.07422C5.01781 3.33244 1.66663 6.84801 1.66663 11.1436C1.66663 15.6089 5.28666 19.2306 9.75187 19.2306C11.7891 19.2306 13.6495 18.4773 15.0713 17.234C15.181 17.142 15.2499 17.0041 15.2499 16.8503C15.2499 16.7141 15.195 16.5885 15.1049 16.4966Z"
      fill="currentColor"
    />
  </Icon>
)
