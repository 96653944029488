import React from 'react'
import styled from 'styled-components'

import { Colors } from '../../constants'

import { Icon } from './Icon'

export function FailureIcon() {
  return (
    <FailIcon width="80" height="82" viewBox="0 0 80 82" fill="none">
      <rect y="0.75" width="80" height="80" rx="40" fill={Colors.Black[100]} />
      <g clipPath="url(#clip0)">
        <path
          d="M43.0837 47.9011C42.2724 47.0489 40.8063 47.048 39.9938 47.8991L33.3112 54.6177C32.7033 55.2299 31.8828 55.5051 31.0801 55.4217C33.7426 53.1277 33.9923 51.1119 31.2461 48.7388C29.1178 46.8157 27.0866 48.0727 25.5141 49.9287C25.3827 49.0693 25.6515 48.1672 26.2925 47.5198L32.9881 40.7893C33.8282 39.9449 33.8294 38.5685 32.9904 37.7208C31.5732 36.4673 29.6051 33.2171 27.4661 35.2637C21.819 41.294 12.6395 46.9549 19.258 56.1845L14.5348 60.9077C13.6647 61.7548 15.0117 63.0997 15.8575 62.2303L28.0265 50.0613C28.5257 49.5408 29.4242 49.5407 29.9233 50.0614C30.6948 50.774 31.9702 51.8917 30.9108 52.9458L13.428 70.4289C12.3731 71.4882 11.2561 70.2123 10.5436 69.4412C9.10208 68.0525 11.9714 66.3362 12.6834 65.4046C13.5562 64.5589 12.2048 63.2119 11.361 64.0821C9.45044 65.9221 6.7947 68.0554 9.22105 70.764C10.0804 71.6649 11.1165 72.7803 12.4797 72.692C14.4549 73.3255 23.3366 62.6849 24.7688 61.7332C33.9145 68.3558 39.5877 59.0187 45.5143 53.4224C47.532 51.3283 44.3114 49.3048 43.0837 47.9011ZM21.268 44.1519L28.7924 36.5826C28.9644 36.4542 29.0565 36.4544 29.2285 36.5826L31.6618 39.0372C31.7805 39.1572 31.7807 39.3514 31.6623 39.4705L24.9652 46.2026C23.5891 47.5922 23.2418 49.6829 24.0224 51.4207L20.5913 54.8517C18.1561 51.6724 18.3857 47.0568 21.268 44.1519ZM44.188 52.104C39.1037 56.5795 33.9437 65.9696 26.1011 60.4008L29.5629 56.9389C31.2675 57.6553 33.3006 57.2828 34.6377 55.9362L41.3196 49.2182C41.4885 49.0878 41.5944 49.0925 41.7558 49.2182L44.1886 51.6724C44.3066 51.7914 44.3065 51.9849 44.188 52.104Z"
          fill={Colors.Black[500]}
        />
        <path
          d="M72.1978 12.9739C72.2823 11.6153 71.1761 10.5686 70.2694 9.71528C69.0739 8.46942 66.923 8.46942 65.7274 9.71528L55.6903 19.7525C46.4529 13.133 40.7989 22.3179 34.769 27.9611C32.724 30.094 35.9729 32.0722 37.2271 33.4855C38.0737 34.3237 39.45 34.3228 40.2949 33.4826L47.024 26.7884C47.6724 26.1462 48.5746 25.8772 49.4344 26.0086C47.5778 27.5827 46.3213 29.6113 48.2444 31.7406C50.618 34.4879 52.6359 34.2351 54.9272 31.5747C55.0105 32.3773 54.7353 33.1978 54.1228 33.8061L47.4046 40.4882C45.3593 42.6208 48.6066 44.5977 49.8608 46.0108C50.7069 46.8503 52.0829 46.8493 52.928 46.0089L60.5093 38.4726C63.0035 36.0006 64.0028 32.3596 63.1173 28.9705C62.9867 28.4708 62.4758 28.1714 61.9761 28.302C61.4764 28.4326 61.1771 28.9436 61.3076 29.4432C63.4381 35.4704 54.8605 40.9098 51.6094 44.6828C51.4904 44.801 51.2969 44.801 51.1778 44.6829L48.7242 42.2506C48.5981 42.089 48.5933 41.9835 48.7237 41.8142L55.4413 35.1328C56.7884 33.7951 57.1608 31.762 56.4445 30.0575C57.2067 28.7785 73.2783 14.5659 72.1978 12.9739ZM45.7066 25.4608L38.976 32.1564C38.8569 32.2748 38.6625 32.2747 38.5433 32.1566L36.0885 29.7228C35.96 29.551 35.9599 29.4588 36.0879 29.2869L43.6569 21.7627C46.5623 18.8797 51.1781 18.6502 54.3572 21.0854L50.9262 24.5165C49.1884 23.7358 47.0975 24.0833 45.7066 25.4608ZM69.9345 13.9223L52.4513 31.4055C52.1981 31.6589 51.8611 31.7984 51.5028 31.7984C50.7494 31.8835 50.0577 30.8369 49.5668 30.4179C49.0467 29.9185 49.0461 29.0204 49.5669 28.5211L67.05 11.038C67.549 10.5175 68.4478 10.5175 68.9467 11.038C69.7184 11.7505 70.9936 12.8681 69.9345 13.9223Z"
          fill={Colors.Black[500]}
        />
        <path
          d="M20.2025 31.5112L22.9347 32.5447C23.405 32.7265 23.9579 32.4889 24.1401 32.001C24.3228 31.518 24.0794 30.9782 23.5964 30.7955L20.8642 29.762C19.7369 29.316 19.0605 31.0985 20.2025 31.5112Z"
          fill={Colors.Red[400]}
        />
        <path
          d="M26.4211 27.1377C27.2559 28.0175 28.6201 26.6869 27.7592 25.8308C27.7594 25.8308 25.6346 23.655 25.6346 23.655C25.2738 23.2855 24.6817 23.2784 24.312 23.6393C23.9426 24.0002 23.9355 24.5922 24.2963 24.9618L26.4211 27.1377Z"
          fill={Colors.Red[400]}
        />
        <path
          d="M31.5609 23.5214C31.8595 24.7041 33.6946 24.2097 33.3693 23.0448L32.613 20.1752C32.4814 19.6758 31.9699 19.3776 31.4705 19.5093C30.9711 19.6409 30.6729 20.1524 30.8046 20.6517L31.5609 23.5214Z"
          fill={Colors.Red[400]}
        />
      </g>
      <rect x="48" y="49.25" width="32" height="32" rx="16" fill={Colors.Red[400]} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.8461 65.25C53.8461 59.6422 58.3922 55.0961 64 55.0961C69.6078 55.0961 74.1538 59.6422 74.1538 65.25C74.1538 66.8763 73.7714 68.4133 73.0917 69.776L74.5134 71.0397C75.4608 69.323 75.9999 67.3494 75.9999 65.25C75.9999 58.6226 70.6274 53.25 64 53.25C57.3726 53.25 52 58.6226 52 65.25C52 71.8774 57.3726 77.2499 64 77.2499C65.3687 77.2499 66.6839 77.0208 67.9092 76.5987L66.934 74.9734C66.0051 75.2533 65.0201 75.4038 64 75.4038C58.3922 75.4038 53.8461 70.8578 53.8461 65.25Z"
        fill={Colors.White}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.3164 74.4434C69.9745 73.6636 71.3873 72.4485 72.4076 70.9453L73.7975 72.1808C72.6397 73.8145 71.0835 75.1459 69.2706 76.0336L68.3164 74.4434Z"
        fill={Colors.White}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.4036 68.4026L65.2512 65.2502L68.4037 62.0976L67.1523 60.8462L63.9998 63.9987L60.6255 60.6243L59.374 61.8758L62.7484 65.2502L59.3742 68.6244L60.6256 69.8759L63.9998 66.5016L67.1521 69.654L68.4036 68.4026Z"
        fill={Colors.White}
      />
      <defs>
        <clipPath id="clip0">
          <rect width="64" height="64" fill={Colors.White} transform="translate(8.25 8.75)" />
        </clipPath>
      </defs>
    </FailIcon>
  )
}

const FailIcon = styled(Icon)`
  margin-bottom: 8px;
`
