import React from 'react'

import { Icon } from './Icon'

export const FounderMemberIcon = React.memo(() => (
  <Icon size="16" viewBox="0 0 16 16" preserveAspectRatio="xMidYMid meet" fill="none" color="currentColor">
    <path
      className="memberInner"
      d="M11.9026 5.15556L10.6558 6.66667L11.9026 8.17778C11.9569 8.24384 11.99 8.32234 11.9981 8.4045C12.0061 8.48666 11.9889 8.56925 11.9483 8.64303C11.9078 8.71682 11.8454 8.77889 11.7682 8.8223C11.6911 8.86572 11.6022 8.88877 11.5114 8.88889H5.64449V12H4.66666V4.44444C4.66666 4.32657 4.71817 4.21352 4.80986 4.13017C4.90155 4.04683 5.02591 4 5.15558 4C5.28524 4 5.4096 4.04683 5.50129 4.13017C5.59298 4.21352 5.64449 4.32657 5.64449 4.44444H11.5114C11.6022 4.44456 11.6911 4.46761 11.7682 4.51103C11.8454 4.55445 11.9078 4.61652 11.9483 4.6903C11.9889 4.76408 12.0061 4.84667 11.9981 4.92883C11.99 5.01099 11.9569 5.08949 11.9026 5.15556Z"
      fill="currentColor"
    />
  </Icon>
))
