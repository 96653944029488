import React from 'react'

import { Colors } from '@/common/constants'

import { Icon, StyledIcon } from '../Icon'

export const BuilderWorkerIcon = React.memo(({ className }: StyledIcon) => (
  <Icon
    size="20"
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    color="currentColor"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.15801 18.3309C7.055 18.7049 7 19.0961 7 19.5C7 19.9043 7.055 20.2956 7.15751 20.6687C7.43387 20.6549 7.70892 20.7129 7.9522 20.8361C8.19549 20.9593 8.39753 21.1429 8.53598 21.3667C8.67443 21.5904 8.74389 21.8456 8.73667 22.1039C8.72945 22.3623 8.64584 22.6137 8.49507 22.8304C9.09958 23.3858 9.84554 23.7883 10.6627 24C10.7882 23.7696 10.9795 23.5761 11.2155 23.441C11.4515 23.3058 11.7229 23.2343 11.9997 23.2343C12.2766 23.2343 12.548 23.3058 12.784 23.441C13.02 23.5761 13.2113 23.7696 13.3368 24C14.154 23.7883 14.8999 23.3858 15.5044 22.8304C15.3538 22.6137 15.2703 22.3623 15.2632 22.1041C15.256 21.8458 15.3255 21.5907 15.4639 21.367C15.6024 21.1434 15.8043 20.9598 16.0475 20.8366C16.2908 20.7134 16.5657 20.6555 16.842 20.6691C16.945 20.2951 17 19.9039 17 19.5C17 19.0961 16.945 18.7049 16.842 18.3313C16.5656 18.3451 16.2906 18.2871 16.0473 18.1639C15.804 18.0407 15.602 17.8571 15.4635 17.6333C15.3251 17.4096 15.2556 17.1544 15.2628 16.8961C15.27 16.6377 15.3537 16.3863 15.5044 16.1696C14.8999 15.6142 14.154 15.2117 13.3368 15C13.2113 15.2304 13.02 15.4239 12.784 15.559C12.548 15.6942 12.2766 15.7657 11.9997 15.7657C11.7229 15.7657 11.4515 15.6942 11.2155 15.559C10.9795 15.4239 10.7882 15.2304 10.6627 15C9.84554 15.2117 9.09958 15.6142 8.49507 16.1696C8.64566 16.3863 8.72916 16.6376 8.73632 16.8958C8.74349 17.154 8.67405 17.4091 8.53568 17.6327C8.39731 17.8564 8.1954 18.04 7.95227 18.1632C7.70914 18.2864 7.43425 18.3444 7.15801 18.3309ZM12.0002 20.9763C12.8839 20.9763 13.6003 20.3074 13.6003 19.4823C13.6003 18.6571 12.8839 17.9882 12.0002 17.9882C11.1165 17.9882 10.4002 18.6571 10.4002 19.4823C10.4002 20.3074 11.1165 20.9763 12.0002 20.9763Z"
      fill={Colors.Blue[500]}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.02521 3.92617C3.02521 1.75781 4.78597 0 6.95798 0V1.81208C5.78844 1.81208 4.84034 2.75859 4.84034 3.92617C4.84034 5.09376 5.78844 6.04027 6.95798 6.04027V7.85235C4.78597 7.85235 3.02521 6.09454 3.02521 3.92617ZM12 1.81208C10.8305 1.81208 9.88235 2.75859 9.88235 3.92617C9.88235 5.09376 10.8305 6.04027 12 6.04027C13.1695 6.04027 14.1176 5.09376 14.1176 3.92617C14.1176 2.75859 13.1695 1.81208 12 1.81208ZM8.06723 3.92617C8.06723 1.75781 9.82799 0 12 0C14.172 0 15.9328 1.75781 15.9328 3.92617C15.9328 6.09454 14.172 7.85235 12 7.85235C9.82799 7.85235 8.06723 6.09454 8.06723 3.92617ZM19.1597 3.92617C19.1597 2.75859 18.2116 1.81208 17.042 1.81208V0C19.214 0 20.9748 1.75781 20.9748 3.92617C20.9748 6.09454 19.214 7.85235 17.042 7.85235V6.04027C18.2116 6.04027 19.1597 5.09376 19.1597 3.92617ZM4.94118 10.8725C3.21471 10.8725 1.81513 12.2697 1.81513 13.9933V15H0V13.9933C0 11.2689 2.21224 9.0604 4.94118 9.0604H5.44538V10.8725H4.94118ZM5.04202 13.9933C5.04202 11.2689 7.25426 9.0604 9.98319 9.0604H14.0168C16.7457 9.0604 18.958 11.2689 18.958 13.9933V15H17.1429V13.9933C17.1429 12.2697 15.7433 10.8725 14.0168 10.8725H9.98319C8.25672 10.8725 6.85714 12.2697 6.85714 13.9933V15H5.04202V13.9933ZM19.0588 10.8725H18.5546V9.0604H19.0588C21.7878 9.0604 24 11.2689 24 13.9933V15H22.1849V13.9933C22.1849 12.2697 20.7853 10.8725 19.0588 10.8725Z"
      fill={Colors.Black[900]}
    />
  </Icon>
))
