import React from 'react'

export const YoutubeIcon = ({ className }: { className?: string }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" className={className}>
      <g clipPath="url(#clip0_9559_16883)">
        <path
          d="M24.1333 7.20156C24.1333 7.20156 23.9333 5.50156 23.1333 4.80156C22.2333 3.80156 21.2333 3.80156 20.7333 3.80156C17.3333 3.60156 12.3333 3.60156 12.3333 3.60156C12.3333 3.60156 7.33331 3.60156 3.93331 3.80156C3.43331 3.90156 2.43331 3.90156 1.53331 4.80156C0.833313 5.50156 0.533313 7.20156 0.533313 7.20156C0.533313 7.20156 0.333313 9.10156 0.333313 11.1016V12.9016C0.333313 14.8016 0.533313 16.8016 0.533313 16.8016C0.533313 16.8016 0.733313 18.5016 1.53331 19.2016C2.43331 20.2016 3.63331 20.1016 4.13331 20.2016C6.03331 20.4016 12.3333 20.4016 12.3333 20.4016C12.3333 20.4016 17.3333 20.4016 20.7333 20.1016C21.2333 20.0016 22.2333 20.0016 23.1333 19.1016C23.8333 18.4016 24.1333 16.7016 24.1333 16.7016C24.1333 16.7016 24.3333 14.8016 24.3333 12.8016V11.0016C24.3333 9.10156 24.1333 7.20156 24.1333 7.20156ZM9.83331 15.1016V8.40156L16.3333 11.8016L9.83331 15.1016Z"
          fill="#C4CAD6"
        />
      </g>
      <defs>
        <clipPath id="clip0_9559_16883">
          <rect width="24" height="24" fill="white" transform="translate(0.333313)" />
        </clipPath>
      </defs>
    </svg>
  )
}
