import React from 'react'

import { Icon } from './Icon'

export function QuestionIcon() {
  return (
    <Icon size="16" viewBox="0 0 16 16" preserveAspectRatio="xMidYMid meet" fill="none" color="currentColor">
      <path
        d="M9.55266 8.11325L10.1527 7.49991C10.5327 7.11991 10.7727 6.58658 10.7727 5.99992C10.7727 4.52658 9.57933 3.33325 8.106 3.33325C6.63266 3.33325 5.43933 4.52658 5.43933 5.99992H6.77266C6.77266 5.26658 7.37266 4.66658 8.106 4.66658C8.83933 4.66658 9.43933 5.26658 9.43933 5.99992C9.43933 6.36658 9.29266 6.69991 9.046 6.93991L8.21933 7.77991C7.73933 8.26658 7.43933 8.93324 7.43933 9.66658V9.99991H8.77266C8.77266 8.99991 9.07266 8.59991 9.55266 8.11325ZM7.43911 12.6666H8.77245V11.3333H7.43911V12.6666Z"
        fill="currentColor"
      />
    </Icon>
  )
}
