import React from 'react'

import { Icon } from './Icon'

interface LinkIconProps {
  className?: any
}

export const LinkIcon = React.memo(({ className }: LinkIconProps) => (
  <Icon
    size="16"
    viewBox="0 0 16 16"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    color="currentColor"
    className={className}
  >
    <path
      d="M6.6961 4.30836L8.16793 2.83653C9.55935 1.4451 11.8153 1.4451 13.2067 2.83653C14.5981 4.22795 14.5981 6.48389 13.2067 7.87532L11.7349 9.34715L10.8864 8.49862L12.3582 7.02679C13.281 6.10399 13.281 4.60785 12.3582 3.68506C11.4354 2.76226 9.93925 2.76226 9.01646 3.68506L7.54463 5.15688L6.6961 4.30836ZM3.77863 8.92288C2.85583 9.84568 2.85583 11.3418 3.77863 12.2646C4.70142 13.1874 6.19757 13.1874 7.12036 12.2646L8.59219 10.7928L9.44072 11.6413L7.96889 13.1131C6.57747 14.5046 4.32152 14.5046 2.9301 13.1131C1.53868 11.7217 1.53868 9.46578 2.9301 8.07436L4.40193 6.60253L5.25046 7.45105L3.77863 8.92288ZM5.12475 10.07L10.1635 5.03118L11.0121 5.8797L5.97328 10.9185L5.12475 10.07Z"
      fill="currentColor"
    />
  </Icon>
))
