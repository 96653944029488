import React from 'react'

import { Colors } from '@/common/constants'

import { Icon, StyledIcon } from '../Icon'

export const AppsWorkerIcon = React.memo(({ className }: StyledIcon) => (
  <Icon
    size="20"
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    color="currentColor"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4208 9.16547L14.1279 9.87257C16.481 12.2257 16.481 16.0399 14.1279 18.3931L11.2853 21.2356C8.93221 23.5887 5.118 23.5887 2.76486 21.2356C0.411714 18.8824 0.411714 15.0682 2.76486 12.7151L2.77519 12.7049L5.98932 9.58271L7.38287 11.0173L4.17422 14.1342C2.60698 15.7066 2.60859 18.2509 4.17907 19.8214C5.75116 21.3935 8.29904 21.3935 9.87113 19.8214L12.7136 16.9788C14.2857 15.4068 14.2857 12.8589 12.7136 11.2868L12.0065 10.5797L13.4208 9.16547Z"
      fill={Colors.Black[900]}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8203 4.17956C18.2482 2.60746 15.7004 2.60746 14.1283 4.17956L11.2858 7.02207C9.71367 8.59416 9.71367 11.142 11.2858 12.7141L11.9929 13.4212L10.5787 14.8355L9.87155 14.1283C7.5184 11.7752 7.5184 7.961 9.87155 5.60786L12.7141 2.76535C15.0672 0.412203 18.8814 0.412203 21.2345 2.76535C23.5877 5.11849 23.5877 8.93269 21.2345 11.2858L21.2242 11.296L18.0101 14.4182L16.6165 12.9836L19.8252 9.86677C21.3924 8.29429 21.3908 5.75003 19.8203 4.17956Z"
      fill={Colors.Blue[500]}
    />
  </Icon>
))
