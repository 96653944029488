import React from 'react'

import { Icon } from './Icon'

interface Props {
  className?: string
  onClick?: () => void
}

export const GithubLogo = React.memo((props: Props) => (
  <Icon {...props} size="16" viewBox="0 0 16 16" preserveAspectRatio="xMidYMid meet" fill="none" color="currentColor">
    <g clipPath="url(#clip0_11034_51047)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.68652 0C4.26652 0 0.686523 3.58 0.686523 8C0.686523 11.54 2.97652 14.53 6.15652 15.59C6.55652 15.66 6.70652 15.42 6.70652 15.21C6.70652 15.02 6.69652 14.39 6.69652 13.72C4.68652 14.09 4.16652 13.23 4.00652 12.78C3.91652 12.55 3.52652 11.84 3.18652 11.65C2.90652 11.5 2.50652 11.13 3.17652 11.12C3.80652 11.11 4.25652 11.7 4.40652 11.94C5.12652 13.15 6.27652 12.81 6.73652 12.6C6.80652 12.08 7.01652 11.73 7.24652 11.53C5.46652 11.33 3.60652 10.64 3.60652 7.58C3.60652 6.71 3.91652 5.99 4.42652 5.43C4.34652 5.23 4.06652 4.41 4.50652 3.31C4.50652 3.31 5.17652 3.1 6.70652 4.13C7.34652 3.95 8.02652 3.86 8.70652 3.86C9.38652 3.86 10.0665 3.95 10.7065 4.13C12.2365 3.09 12.9065 3.31 12.9065 3.31C13.3465 4.41 13.0665 5.23 12.9865 5.43C13.4965 5.99 13.8065 6.7 13.8065 7.58C13.8065 10.65 11.9365 11.33 10.1565 11.53C10.4465 11.78 10.6965 12.26 10.6965 13.01C10.6965 14.08 10.6865 14.94 10.6865 15.21C10.6865 15.42 10.8365 15.67 11.2365 15.59C12.8247 15.0539 14.2047 14.0332 15.1824 12.6716C16.16 11.3101 16.6861 9.6762 16.6865 8C16.6865 3.58 13.1065 0 8.68652 0Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_11034_51047">
        <rect width="16" height="16" fill="white" transform="translate(0.686523)" />
      </clipPath>
    </defs>
  </Icon>
))
