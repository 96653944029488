import React from 'react'

export const IRCIcon = ({ className }: { className?: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
      <path
        d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM13.3838 10.591C13.2392 11.1333 13.1669 11.7379 13.1669 12.4047C13.1669 13.0354 13.2352 13.6179 13.3718 14.1522C13.5124 14.6824 13.7273 15.1404 14.0165 15.526C14.3058 15.9117 14.6693 16.215 15.1072 16.4359C15.545 16.6528 16.0693 16.7613 16.6799 16.7613C17.1177 16.7613 17.5255 16.707 17.9031 16.5986C18.2807 16.4901 18.6141 16.3254 18.9033 16.1045C19.1926 15.8835 19.4316 15.6003 19.6204 15.2549C19.8092 14.9094 19.9357 14.4936 20 14.0076H18.3972C18.3168 14.5017 18.1401 14.8572 17.8669 15.0741C17.5938 15.291 17.2061 15.3995 16.704 15.3995C16.4509 15.3995 16.2139 15.3573 15.9929 15.2729C15.776 15.1846 15.5852 15.0641 15.4205 14.9114C15.2558 14.7547 15.1253 14.57 15.0288 14.357C14.9365 14.1401 14.8903 13.9051 14.8903 13.652V11.0851C14.8903 10.82 14.9324 10.581 15.0168 10.3681C15.1052 10.1552 15.2277 9.9744 15.3844 9.82577C15.541 9.67714 15.7278 9.56265 15.9447 9.48231C16.1657 9.39795 16.4027 9.35577 16.6558 9.35577C17.1338 9.35577 17.5094 9.46624 17.7826 9.68718C18.0597 9.90812 18.2184 10.2415 18.2586 10.6874H19.8674C19.8433 10.2777 19.7489 9.90611 19.5842 9.57269C19.4195 9.23927 19.2006 8.95607 18.9274 8.72308C18.6543 8.48607 18.3329 8.30731 17.9633 8.1868C17.5938 8.06227 17.1961 8 16.7703 8C16.1597 8 15.6274 8.10444 15.1735 8.31333C14.7235 8.52222 14.35 8.81949 14.0527 9.20513C13.7554 9.58675 13.5325 10.0487 13.3838 10.591ZM4 16.5745V8.18077H5.60282V16.5745H4ZM6.6884 16.5745V8.18077H10.1049C11.0007 8.18077 11.6636 8.39367 12.0934 8.81949C12.5232 9.24128 12.7381 9.89205 12.7381 10.7718C12.7381 11.4386 12.5795 11.9749 12.2621 12.3806C11.9448 12.7823 11.5029 13.0615 10.9365 13.2182L12.8587 16.5745H11.0811L9.31558 13.3508H8.29122V16.5745H6.6884ZM8.29122 12.0312H10.3158C10.818 12.0312 11.069 11.752 11.069 11.1936V10.3319C11.069 10.0507 11.0128 9.84987 10.9003 9.72936C10.7919 9.60483 10.597 9.54256 10.3158 9.54256H8.29122V12.0312Z"
        fill="#C4CAD6"
      />
    </svg>
  )
}
