import React from 'react'

import { Icon, StyledIcon } from '../Icon'

export const ExiteRoleIcon = ({ className }: StyledIcon) => (
  <Icon size="20" viewBox="0 0 20 20" fill="none" color="currentColor" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.58344 1.66667L3.66885 0.99585L4.94622 1.63454L5.83408 1.04263H6.66613L7.5001 1.59862L8.33408 1.04263H9.16613L10.0001 1.59862L10.8341 1.04263H11.6661L12.5001 1.59862L13.3341 1.04263H14.1661L15.054 1.63454L16.3314 0.99585L17.4168 1.66667V14.1667L16.6668 14.9167H12.5001V12.5H7.5001V14.9167H3.33344L2.58344 14.1667V1.66667ZM11.0809 7.40218C11.5574 7.06542 11.8684 6.5119 11.8684 5.88613C11.8684 4.85979 11.0319 4.02777 10.0001 4.02777C8.96834 4.02777 8.1319 4.85979 8.1319 5.88613C8.1319 6.51188 8.44281 7.06539 8.91936 7.40215C8.41019 7.60041 8.03284 7.9352 7.76714 8.31991C7.35575 8.91558 7.22231 9.61165 7.22231 10.0675V10.3395L7.46691 10.4612C7.83749 10.6455 8.808 10.9722 10.0001 10.9722C11.1922 10.9722 12.1627 10.6455 12.5333 10.4612L12.7779 10.3395V10.0675C12.7779 9.61165 12.6444 8.91558 12.233 8.31991C11.9673 7.93521 11.59 7.60044 11.0809 7.40218Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.24995 16.9393V14.1666H10.75V16.9393L11.553 16.1363L12.6136 17.197L9.99995 19.8106L7.38629 17.197L8.44695 16.1363L9.24995 16.9393Z"
      fill="currentColor"
    />
  </Icon>
)
