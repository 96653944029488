import React from 'react'

export const EmailIcon = ({ className }: { className?: string }) => {
  return (
    <svg fill="none" color="currentColor" width="25" height="24" viewBox="0 0 25 24" className={className}>
      <path
        d="M13.5932 15.0509C13.3082 15.2028 12.9902 15.2822 12.6673 15.2822C12.3443 15.2822 12.0263 15.2028 11.7413 15.0509L2.16678 9.94531V17.9075C2.163 18.2532 2.22832 18.5963 2.3589 18.9164C2.48948 19.2366 2.68269 19.5275 2.92719 19.772C3.1717 20.0165 3.46258 20.2097 3.78276 20.3403C4.10294 20.4709 4.44597 20.5362 4.79174 20.5324H20.5415C20.8872 20.5362 21.2303 20.4709 21.5505 20.3403C21.8706 20.2097 22.1615 20.0165 22.406 19.772C22.6505 19.5275 22.8437 19.2366 22.9743 18.9164C23.1049 18.5963 23.1702 18.2532 23.1664 17.9075V9.94531L13.5932 15.0509Z"
        fill="#C4CAD6"
      />
      <path
        d="M20.5415 3.46891H4.79174C4.44597 3.46513 4.10294 3.53044 3.78276 3.66102C3.46258 3.7916 3.1717 3.98481 2.92719 4.22932C2.68269 4.47382 2.48948 4.7647 2.3589 5.08488C2.22832 5.40506 2.163 5.7481 2.16678 6.09386V7.06258C2.16668 7.18156 2.19893 7.29834 2.26007 7.40041C2.32122 7.50247 2.40897 7.586 2.51393 7.64204L12.3575 12.892C12.4528 12.9423 12.5589 12.9687 12.6666 12.9687C12.7744 12.9687 12.8805 12.9423 12.9757 12.892L22.8193 7.64204C22.9242 7.586 23.012 7.50247 23.0731 7.40041C23.1343 7.29834 23.1665 7.18156 23.1664 7.06258V6.09386C23.1702 5.7481 23.1049 5.40506 22.9743 5.08488C22.8437 4.7647 22.6505 4.47382 22.406 4.22932C22.1615 3.98481 21.8706 3.7916 21.5505 3.66102C21.2303 3.53044 20.8872 3.46513 20.5415 3.46891Z"
        fill="#C4CAD6"
      />
    </svg>
  )
}
