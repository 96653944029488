import React from 'react'

import { Icon, StyledIcon } from '../Icon'

export const RewardIcon = ({ className }: StyledIcon) => (
  <Icon size="20" viewBox="0 0 20 20" fill="none" color="currentColor" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 13.9319C17.5 10.9659 16.5922 8.32371 14.7155 6.45697L16.0044 2.67506L15.2437 1.6667L12.0501 2.06827C11.5401 1.84849 10.8532 1.70321 10.1633 1.67268C9.37452 1.63777 8.45683 1.7469 7.71317 2.1833C7.36827 2.3857 6.82224 2.40102 6.13568 2.25247C5.60862 2.13843 5.11739 1.95763 4.71476 1.80944C4.60806 1.77017 4.50758 1.73319 4.4143 1.70035L3.53939 2.7462L5.24402 6.4975C3.39476 8.36184 2.5 10.9873 2.5 13.9319C2.5 15.785 3.57017 17.1195 4.98832 17.9518C6.39482 18.7771 8.21916 19.1666 10 19.1666C11.7808 19.1666 13.6052 18.7771 15.0117 17.9518C16.4298 17.1195 17.5 15.785 17.5 13.9319ZM11.4189 12.5C11.4189 11.4163 10.6637 10.8333 9.70927 10.8333C8.74802 10.8333 7.99962 11.4163 7.99962 12.5C7.99962 13.5773 8.74802 14.1666 9.70927 14.1666C10.6637 14.1666 11.4189 13.5836 11.4189 12.5ZM10.4371 12.5C10.4371 13.083 10.183 13.3998 9.70927 13.3998C9.23552 13.3998 8.98147 13.083 8.98147 12.5C8.98147 11.9169 9.23552 11.6001 9.70927 11.6001C10.183 11.6001 10.4371 11.9169 10.4371 12.5ZM6.62041 10.8777V13.102C6.61697 13.3523 6.50368 13.4822 6.29083 13.4822C6.08142 13.4822 5.9647 13.3539 5.9544 13.1083H5.00002C4.99658 13.8561 5.55273 14.1666 6.22904 14.1666C7.00834 14.1666 7.55762 13.7357 7.56106 13.102V10.8777H6.62041ZM12.7617 13.102L11.4708 10.8777H12.5351L13.2217 12.1958H13.2492L13.9358 10.8777H15L13.7092 13.102V14.1223H12.7617V13.102Z"
      fill="currentColor"
    />
  </Icon>
)
