import React from 'react'

export const CustomLinkIcon = ({ className }: { className?: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
      <g clipPath="url(#clip0_9559_14337)">
        <path
          d="M3.99988 0C1.79074 0 -0.00012207 1.79086 -0.00012207 4V20C-0.00012207 22.2091 1.79074 24 3.99988 24H19.9999C22.209 24 23.9999 22.2091 23.9999 20V4C23.9999 1.79086 22.209 0 19.9999 0H3.99988ZM18.6825 5.31814C16.9265 3.56218 14.0808 3.56062 12.323 5.31346L10.3022 7.28436C9.90678 7.66997 9.89886 8.30309 10.2845 8.69846C10.6701 9.09384 11.3032 9.10175 11.6986 8.71614L13.7236 6.74114L13.7236 6.7412L13.7325 6.73236C14.7089 5.75588 16.2918 5.75588 17.2683 6.73236C18.2447 7.70883 18.2447 9.29167 17.2683 10.2681L17.2682 10.2681L17.2595 10.277L15.2845 12.302C14.8989 12.6974 14.9068 13.3305 15.3022 13.7161C15.6975 14.1018 16.3306 14.0938 16.7163 13.6985L18.6872 11.6776C20.44 9.91975 20.4384 7.0741 18.6825 5.31814ZM8.69834 10.2844C9.09372 10.67 9.10163 11.3031 8.71602 11.6985L6.74102 13.7235L6.73229 13.7324L6.73223 13.7324C5.75576 14.7088 5.75576 16.2917 6.73223 17.2681C7.70871 18.2446 9.29155 18.2446 10.268 17.2681L10.2769 17.2593L10.2769 17.2594L12.3019 15.2844C12.6973 14.8987 13.3304 14.9067 13.716 15.302C14.1016 15.6974 14.0937 16.3305 13.6983 16.7161L11.6775 18.6871C9.91963 20.4399 7.07398 20.4383 5.31802 18.6824C3.56206 16.9264 3.5605 14.0807 5.31334 12.3228L7.28424 10.302C7.66985 9.90666 8.30297 9.89875 8.69834 10.2844ZM15.7075 9.70735C16.098 9.31683 16.098 8.68366 15.7075 8.29314C15.3169 7.90261 14.6838 7.90261 14.2933 8.29314L8.29326 14.2931C7.90274 14.6837 7.90274 15.3168 8.29326 15.7074C8.68378 16.0979 9.31695 16.0979 9.70747 15.7074L15.7075 9.70735Z"
          fill="#C4CAD6"
        />
      </g>
      <defs>
        <clipPath id="clip0_9559_14337">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
