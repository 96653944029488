import React from 'react'

import { Icon } from '../../../icons'

export const MembersIcon = () => (
  <Icon
    size="20"
    viewBox="0 0 20 20"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    color="currentColor"
    className="nav-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6667 14.9597V14.9296C16.6667 13.8129 16.3725 12.142 15.4184 10.6684C15.138 10.2354 14.7994 9.81901 14.3978 9.44353C14.8025 8.68598 15.0296 7.8172 15.0296 6.90137C15.0296 5.64786 14.5957 4.4664 13.8584 3.54423C14.2149 3.40772 14.5993 3.33331 15.0001 3.33331C16.8574 3.33331 18.363 4.93079 18.363 6.90137C18.363 8.10283 17.8033 9.1656 16.9455 9.81217C17.8619 10.1928 18.5411 10.8356 19.0194 11.5742C19.3915 12.1489 19.6372 12.7723 19.7897 13.3614C18.9125 14.0048 17.8551 14.5479 16.6667 14.9597Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.64797 14.9597V14.9296C3.64797 13.8129 3.94218 12.142 4.89632 10.6684C5.1767 10.2354 5.51532 9.81901 5.91688 9.44353C5.51222 8.68598 5.28505 7.8172 5.28505 6.90137C5.28505 5.64786 5.71902 4.4664 6.4563 3.54423C6.09983 3.40772 5.71538 3.33331 5.31455 3.33331C3.45731 3.33331 1.95172 4.93079 1.95172 6.90137C1.95172 8.10283 2.5114 9.1656 3.36922 9.81217C2.45277 10.1928 1.77357 10.8356 1.29534 11.5742C0.923218 12.1489 0.677447 12.7723 0.525004 13.3614C1.40219 14.0048 2.45961 14.5479 3.64797 14.9597Z"
      fill="currentColor"
    />
    <path
      d="M13.3629 6.90137C13.3629 8.10283 12.8032 9.1656 11.9454 9.81217C12.8619 10.1928 13.5411 10.8356 14.0193 11.5742C14.7598 12.7179 15 14.0544 15 14.9296V15.4518L14.5597 15.6854C13.8927 16.0393 12.1458 16.6666 10 16.6666C7.85424 16.6666 6.10732 16.0393 5.44027 15.6854L5 15.4518V14.9296C5 14.0544 5.24019 12.7179 5.9807 11.5742C6.45895 10.8356 7.13819 10.1928 8.05469 9.81212C7.19691 9.16555 6.63725 8.1028 6.63725 6.90137C6.63725 4.93079 8.14285 3.33331 10.0001 3.33331C11.8573 3.33331 13.3629 4.93079 13.3629 6.90137Z"
      fill="currentColor"
    />
  </Icon>
)
