import React from 'react'

import { Icon } from './Icon'

export const WatchIcon = React.memo(() => (
  <Icon size="16" viewBox="0 0 16 16" fill="none" color="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.18185 4.94346C6.62683 4.94346 5.36187 6.20841 5.36187 7.76343C5.36187 9.31844 6.62683 10.5834 8.18185 10.5834C9.73686 10.5834 11.0018 9.31844 11.0018 7.76343C11.0018 6.20841 9.73686 4.94346 8.18185 4.94346ZM14.9399 7.44899C14.503 6.74506 12.0895 3.21657 7.99681 3.33494C4.21092 3.42357 2.0091 6.50641 1.42423 7.44899C1.30301 7.64396 1.30301 7.88388 1.42423 8.07885C1.855 8.77328 4.18352 12.1948 8.19885 12.1948C8.25501 12.1948 8.31116 12.1942 8.36732 12.1929C12.1525 12.1036 14.355 9.02143 14.9399 8.07885C15.0604 7.88388 15.0604 7.64396 14.9399 7.44899Z"
      fill="currentColor"
    />
  </Icon>
))
