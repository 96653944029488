import React from 'react'

import { Icon } from '../../../icons'

export const ElectionIcon = () => (
  <Icon
    size="20"
    viewBox="0 0 20 20"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    color="currentColor"
    className="nav-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9679 3.31194C13.9679 4.42716 13.3768 5.41364 12.4709 6.01381C13.4387 6.36715 14.156 6.96377 14.661 7.64937C15.2256 8.4158 15.5148 9.27549 15.6319 10H10.0461V7.9893L11.2522 7.31581C11.6533 7.09189 11.7861 6.60335 11.549 6.22463C11.3119 5.84592 10.7946 5.72043 10.3935 5.94435L8.74762 6.86338C8.34658 7.0873 8.2137 7.57583 8.45082 7.95455C8.6435 8.2623 9.02122 8.40283 9.37119 8.32455V10H5.20116C5.31832 9.27549 5.6075 8.4158 6.17207 7.64937C6.67712 6.96375 7.3944 6.3671 8.36225 6.01376C7.45641 5.4136 6.86541 4.42714 6.86541 3.31194C6.86541 1.48281 8.45534 0 10.4166 0C12.3779 0 13.9679 1.48281 13.9679 3.31194ZM15.6967 10.8333H17.4999L17.2651 12.5H16.4393L16.6666 20H4.16659L4.39386 12.5H3.56806L3.33325 10.8333H5.13643H15.6967ZM7.49992 15H13.3333V17.5H7.49992V15Z"
      fill="currentColor"
    />
  </Icon>
)
