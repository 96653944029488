import React from 'react'

import { Icon, StyledIcon } from '../Icon'

export const BudgetIcon = ({ className }: StyledIcon) => (
  <Icon size="20" viewBox="0 0 20 20" fill="none" color="currentColor" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83344 0.916626C4.88449 0.916626 4.03503 1.15437 3.4281 1.7613C2.82118 2.36822 2.58344 3.21768 2.58344 4.16663V18.75L3.78343 19.35L5.0001 18.4375L6.21677 19.35H7.11677L8.33344 18.4375L9.5501 19.35H10.4501L11.6668 18.4375L12.8834 19.35H13.7834L14.9297 18.4902L15.7198 19.2803L17.0001 18.75V4.91663H18.7501L19.5001 4.16663C19.5001 3.68721 19.3395 2.90773 18.8655 2.22745C18.364 1.50772 17.5185 0.916626 16.2501 0.916626H5.83344ZM5.83335 4.99998H10.8334V6.66665H5.83335V4.99998ZM5.83335 8.33331H10.8334V9.99998H5.83335V8.33331ZM14.1667 4.99998H11.6667V6.66665H14.1667V4.99998ZM14.1667 8.33331H11.6667V9.99998H14.1667V8.33331ZM11.6667 13.3333H14.1667V15H11.6667V13.3333Z"
      fill="currentColor"
    />
  </Icon>
)
