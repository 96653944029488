import React from 'react'

import { Icon, StyledIcon } from '../Icon'

export const HiredIcon = ({ className }: StyledIcon) => (
  <Icon size="20" viewBox="0 0 20 20" fill="none" color="currentColor" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.08333 5.02532L5 2.94199L5.88388 2.05811L7.96722 4.14144L7.08333 5.02532ZM12.9673 5.02532L15.0506 2.94199L14.1668 2.05811L12.0834 4.14144L12.9673 5.02532Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.625 0.833328V3.74999H9.375V0.833328H10.625Z"
      fill="currentColor"
    />
    <g clipPath="url(#clip0)">
      <path
        d="M15.2629 8.89474L12.9884 6.62017C12.5488 6.1806 11.9527 5.93369 11.331 5.93369H7.77324C6.88633 5.93369 6.05254 6.27908 5.42543 6.90618L5.23816 7.09341L5.22605 7.10552H0.585938C0.262344 7.10552 0 7.36786 0 7.69146V13.9415C0 14.2651 0.262344 14.5274 0.585938 14.5274H3.5073L4.8982 15.9183C5.00809 16.0281 5.15711 16.0899 5.3125 16.0899H5.84305C5.64668 15.7859 5.52027 15.4464 5.47078 15.091C5.03273 14.9485 4.63855 14.6854 4.33496 14.3236C3.45238 13.2717 3.59008 11.6979 4.64191 10.8152L6.86895 8.94658H11.3156C11.471 8.94658 11.62 9.00829 11.7299 9.11822L13.3659 10.7542C13.8914 11.2797 14.7625 11.2909 15.279 10.7565C15.7807 10.2375 15.7753 9.40712 15.2629 8.89474V8.89474Z"
        fill="currentColor"
      />
      <path
        d="M20.0001 8.90244C20.0001 8.57876 19.7377 8.31646 19.4141 8.3165H16.31C17.0592 9.29583 16.9868 10.7063 16.0916 11.6016C15.6198 12.0734 14.992 12.3332 14.3239 12.3332C13.6557 12.3332 13.0279 12.0734 12.5561 11.6016L11.073 10.1185H7.18589L6.72562 10.5047L5.23909 11.752C4.68132 12.2201 4.60858 13.0516 5.07659 13.6094C5.5446 14.1672 6.37616 14.2399 6.93394 13.7719C6.37616 14.2399 6.30343 15.0715 6.77144 15.6292C7.23944 16.187 8.07101 16.2597 8.62878 15.7917C8.07101 16.2597 7.99827 17.0913 8.46628 17.6491C8.93429 18.2068 9.76585 18.2796 10.3236 17.8116L11.3613 16.9408L11.2214 17.0583C10.6636 17.5263 10.5909 18.3579 11.0589 18.9156C11.5269 19.4734 12.3584 19.5461 12.9162 19.0781L16.8964 15.7383H19.4142C19.7378 15.7383 20.0001 15.476 20.0001 15.1524L20.0001 8.90244Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </Icon>
)
