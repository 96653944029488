import React from 'react'

import { Icon } from './Icon'

export function BellIcon() {
  return (
    <Icon size="16" viewBox="0 0 16 16" preserveAspectRatio="xMidYMid meet" fill="none" color="currentColor">
      <path
        d="M3.33336 5.33329C3.33336 2.75596 5.4227 0.666626 8.00003 0.666626C10.5774 0.666626 12.6667 2.75596 12.6667 5.33329C12.6667 7.5684 13.1447 8.95638 13.5853 9.76406C13.8065 10.1696 14.0219 10.4356 14.1715 10.5935C14.2465 10.6727 14.3054 10.7252 14.3409 10.7547C14.3586 10.7695 14.3705 10.7786 14.3757 10.7824C14.3782 10.7843 14.3791 10.7849 14.3782 10.7843L14.3754 10.7824L14.373 10.7807L14.3715 10.7797L14.3707 10.7792C14.3706 10.7791 14.3705 10.7791 14.369 10.7812L14 12H2.00003L1.63101 10.7812C1.62951 10.7791 1.62944 10.7791 1.62937 10.7792L1.62857 10.7797L1.62709 10.7807L1.62466 10.7824L1.62188 10.7843C1.62095 10.7849 1.62183 10.7843 1.62439 10.7824C1.62953 10.7786 1.64143 10.7695 1.65918 10.7547C1.69465 10.7252 1.75358 10.6727 1.82856 10.5935C1.97818 10.4356 2.19357 10.1696 2.41476 9.76406C2.85532 8.95638 3.33336 7.5684 3.33336 5.33329ZM3.43291 10.6666H12.5671C12.5164 10.5835 12.4655 10.4955 12.4148 10.4025C11.8553 9.37687 11.3334 7.76485 11.3334 5.33329C11.3334 3.49234 9.84098 1.99996 8.00003 1.99996C6.15908 1.99996 4.66669 3.49234 4.66669 5.33329C4.66669 7.76485 4.14474 9.37687 3.58529 10.4025C3.53456 10.4955 3.48363 10.5835 3.43291 10.6666Z"
        fill="currentColor"
      />
      <path
        d="M8 15.3333C6.89543 15.3333 6 14.4378 6 13.3333H7.33333C7.33333 13.7014 7.63181 13.9999 8 13.9999C8.36819 13.9999 8.66667 13.7014 8.66667 13.3333H10C10 14.4378 9.10457 15.3333 8 15.3333Z"
        fill="currentColor"
      />
    </Icon>
  )
}
