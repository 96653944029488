export enum Steps {
  SELECT_ACCOUNT = 'SELECT_ACCOUNT',
  SIGN_TRANSACTION = 'SIGN_TRANSACTION',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum ModalTypes {
  CHANGE_ROLE_ACCOUNT = 'CHANGE_ROLE_ACCOUNT',
  CHANGE_REWARD_ACCOUNT = 'CHANGE_REWARD_ACCOUNT',
}
